import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'x-form-api-error',
  templateUrl: 'x-form-api-error.component.html'
})
export class XFormApiErrorComponent implements OnInit, OnChanges {

  @Input() httpErrorResponse: HttpErrorResponse;
  @Input() group: FormGroup;

  public globalErrorMessage: string;

  constructor(
  ) {
  }

  ngOnInit() {
    //
    // if (typeof this.httpErrorResponse !== 'undefined') {
    //
    //   if (typeof this.httpErrorResponse.error !== 'undefined' && this.httpErrorResponse.error !== null) {
    //
    //     // error string
    //     if (typeof this.httpErrorResponse.error === 'string') {
    //       if (this.httpErrorResponse.error !== '') {
    //         this.globalErrorMessage = this.translateService.instant(this.httpErrorResponse.error);
    //       } else {
    //         this.globalErrorMessage = this.translateService.instant('E_UNKNOWN_ERROR');
    //       }
    //
    //     } else { // error object
    //       if (typeof this.httpErrorResponse.error.message !== 'undefined' && this.httpErrorResponse.error.message !== null && this.httpErrorResponse.error.message !== '') {
    //         this.globalErrorMessage = this.translateService.instant(this.httpErrorResponse.error.message);
    //       } else if (typeof this.httpErrorResponse.error.error_description !== 'undefined'
    //         && this.httpErrorResponse.error.error_description !== null && this.httpErrorResponse.error.error_description !== '') {
    //         this.globalErrorMessage = this.translateService.instant(this.httpErrorResponse.error.error_description);
    //       } else if (typeof this.httpErrorResponse.error.error !== 'undefined' && this.httpErrorResponse.error.error !== null && this.httpErrorResponse.error.error !== '') {
    //         this.globalErrorMessage = this.translateService.instant(this.httpErrorResponse.error.error);
    //       }
    //     }
    //
    //   } else {
    //     if (this.httpErrorResponse.status === 401) {
    //       this.globalErrorMessage = this.translateService.instant('E_UNAUTHORIZED_ERROR');
    //     } else if (this.httpErrorResponse.status === 403) {
    //       this.globalErrorMessage = this.translateService.instant('E_FORBIDDEN_ERROR');
    //     } else if (this.httpErrorResponse.status === 404) {
    //       this.globalErrorMessage = this.translateService.instant('E_NOT_FOUND_ERROR');
    //     } else {
    //       this.globalErrorMessage = this.translateService.instant('E_UNKNOWN_ERROR');
    //     }
    //   }
    //
    // } else {
    //   this.globalErrorMessage = '';
    // }

  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (typeof simpleChanges.httpErrorResponse !== 'undefined' || typeof simpleChanges.controls !== 'undefined') {
      this.ngOnInit();
    }
  }

}
