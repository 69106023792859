<!--<button mat-icon-button class="dots-button" [matMenuTriggerFor]="dotsMenu">-->
<!--    <mat-icon>more_vert</mat-icon>-->
<!--</button>-->
<!--<mat-menu class="dotsMatMenu" xPosition="'before'" #dotsMenu="matMenu">-->
    <div class="feDateSelector">
        <div>
            <fe-input [value]="dateStartString" [width]="'100%'" (valueChange)="dateStartStringChange($event)"
                      [icon]="IconType.calendar" [color]="ColorType.darkGrey"/>
            <mat-calendar #dateStartCalendar (selectedChange)="startDateChanged($event)" [(selected)]="dateStartSelected" [maxDate]="maxStartDate" [minDate]="minStartDate > minDate ? minStartDate : minDate" style="width: 300px; margin: 5px;border: 1px outset black"></mat-calendar>
        </div>
        <div>
            <fe-input [value]="dateEndString" [width]="'100%'" (valueChange)="dateEndStringChange($event)"
                      [icon]="IconType.calendar" [color]="ColorType.darkGrey"/>
            <mat-calendar #dateEndtCalendar (selectedChange)="endDateChanged($event)" [(selected)]="dateEndSelected" [maxDate]="maxEndDate" [minDate]="minEndDate" style="width: 300px; margin: 5px;border: 1px outset black"></mat-calendar>
        </div>
        <div class="dateBtnContainer">
            <fe-button fullWidth="true" text="Depuis 1 semaine" (click)="selectPeriod(7)"
                       [color]="periodSelected === 7 ? 'PRIMARY' : 'GREY'"/>
            <fe-button fullWidth="true" text="Depuis 1 mois" (click)="selectPeriod(30)"
                       [color]="periodSelected === 30 ? 'PRIMARY' : 'GREY'"/>
            <fe-button fullWidth="true" text="Depuis 3 mois" (click)="selectPeriod(90)"
                       [color]="periodSelected === 90 ? 'PRIMARY' : 'GREY'"/>
            <fe-button *ngIf="!decouverteDates" fullWidth="true" text="Depuis 6 mois" (click)="selectPeriod(180)"
                       [color]="periodSelected === 180 ? 'PRIMARY' : 'GREY'"/>
            <fe-button *ngIf="!decouverteDates" fullWidth="true" text="Depuis 1 an" (click)="selectPeriod(365)"
                       [color]="periodSelected === 365 ? 'PRIMARY' : 'GREY'"/>
            <fe-button *ngIf="!decouverteDates" fullWidth="true" text="Depuis 2 ans" (click)="selectPeriod(730)"
                       [color]="periodSelected === 730 ? 'PRIMARY' : 'GREY'"/>
            <fe-button *ngIf="!decouverteDates" fullWidth="true" text="Depuis 3 ans" (click)="selectPeriod(1095)"
                       [color]="periodSelected === 1095 ? 'PRIMARY' : 'GREY'"/>
            <p *ngIf="decouverteDates" class="periodeLimitedText">L'historique des signaux business est limité à 3 mois en période de découverte.</p>
            <fe-button fullWidth="true" text="Valider" (click)="validateDates()" color="PRIMARY"
                       class="validateDateButton" textCenter="true"/>
        </div>
    </div>
<!--</mat-menu>-->
