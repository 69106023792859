import { Component } from '@angular/core';
import {SearchesInterface} from "../../../interfaces/searches/searches.interface";
import {SearchesService} from "../../../services/searches/searches.service";
import {SearchInterface} from "../../../interfaces/search/search.interface";
import {SearchCreationComponent} from '../../my-searches/search-creation/search-creation.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NavigationOptions} from "@ionic/angular/common/providers/nav-controller";
import {Utils} from "../../../classes/Utils";
import {ModalController, NavController} from "@ionic/angular";
import {filter} from 'rxjs/operators';
import {IconType} from '../../parts/fe-icon/fe-icon.component';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {GlobalService} from '../../../services/global/global.service';
import introJs from "intro.js";

@Component({
  selector: 'fe-gestion-recherches-modal',
  templateUrl: './fe-gestion-recherches-modal.component.html',
  styleUrls: ['./fe-gestion-recherches-modal.component.scss']
})
export class FeGestionRecherchesModalComponent {
  protected mySearchList: SearchInterface[] = [];
  protected showLoader = true;
  filter: string;

  constructor(private mySearchesService: SearchesService,
              private navController: NavController,
              public dialogRef: MatDialogRef<FeGestionRecherchesModalComponent>,
              private globalService: GlobalService,
              public dialog: MatDialog) {
    this.showLoader = true;
    this.mySearchesService.mySearchList$.subscribe(data => {
        this.mySearchList = data;
        this.showLoader = false;
    });
    // this.mySearchesService.getMySearches().subscribe(
    //     (mySearches: SearchesInterface) => {
    //       this.mySearchList = mySearches.searches;
    //       this.showLoader = false;
    //     }
    // );
  }

    async createNewSearch() {
        const dial = this.dialog.open(SearchCreationComponent,
            {hasBackdrop: true, disableClose: true, height: 'calc(100% - 73px)', width: '100%', maxWidth: '100%', position: {bottom: '0', top: '73px', right: '0', left: '0'}});
    }

    protected loadSearch(search: SearchInterface, index: number): void {
        this.mySearchesService.emitEventToBusinessSignals(search.searchId);
        this.globalService.updateMenuLink('businessSignals');

        const params: NavigationOptions = {
            queryParams: {
                search: search.searchId
            }};

        Utils.navigate('/app/business-signals', this.navController, params);

        this.close();
    }

    public launchIntroFeatureTour(tourName: string) {
        let steps = [];

        if(tourName !== undefined) {
            if(tourName === 'search_modal') {
                steps = [
                    {
                        title: 'Gestion de vos recherches',
                        intro: 'Cette fenêtre vous permet d\'avoir une vue d\'ensemble sur vos recherches, de les rechercher et de les déplacer.'
                    },
                    {
                        title: 'Filtrer par nom',
                        element: document.querySelector<HTMLElement>('.searchModalSearchBarFilterTour'),
                        intro: 'Ce champ permet de filtrer par nom quand vous avez beaucoup de recherches.',
                        position: 'bottom'
                    },
                    {
                        title: 'Nouvelle recherche',
                        element: document.querySelector<HTMLElement>('.searchModalCreateSearchBtnTour'),
                        intro: 'Ce bouton vous permet de créer une nouvelle recherche.',
                        position: 'bottom'
                    },
                    {
                        title: 'Liste des recherches',
                        element: document.querySelector<HTMLElement>('.rechercheLinesContainer'),
                        intro: 'Retrouvez ici toutes vos recherches enregistrées. Cliquez sur le nom d\'une recherche pour voir les résultats associés.',
                        position: 'top'
                    },
                    {
                        title: 'Actions sur une recherche',
                        element: document.querySelector<HTMLElement>('.rechercheLinesContainer'),
                        intro: 'En cliquant sur l\'icône <img src="https://firsteco.fr/resources/image/3dots_action_menu.png" /> à droite d\'une recherche un menu s\'ouvre pour effectuer différentes actions : voir un résumé des critères, activer les alertes emails, modifier, dupliquer, renommer ou supprimer.',
                        position: 'top'
                    },
                    {
                        title: 'Recherche principale',
                        element: document.querySelector<HTMLElement>('.firstResearch'),
                        intro: 'La recherche principale est celle qui se charge en premier. Si les alertes de recherche sont activées dessus, le mail d\'alertes affiche les 10 premiers résultats pour cette recherche. Les autres recherches avec alertes auront les 5 premiers résultats affichés.',
                        position: 'top'
                    },
                    {
                        title: 'Recherche en lecture seule',
                        element: document.querySelector<HTMLElement>('.rechercheLinesContainer'),
                        intro: 'Les recherches en lecture seule ont été créées par un conseiller et ne peuvent être éditées ou supprimées. Vous pouvez les dupliquer.',
                        position: 'top'
                    },
                    {
                        title: 'Résultats depuis la dernière connexion',
                        element: document.querySelector<HTMLElement>('.rechercheLinesContainer'),
                        intro: 'Pour les recherches avec alertes actives, un texte <i>X nouveaux résultats depuis la dernière connexion</i> s\'affiche afin de permettre de voir rapidement les recherches ayant généré des résultats depuis votre dernière connexion.',
                        position: 'top'
                    },
                    {
                        title: 'Déplacement d\'une recherche',
                        element: document.querySelector<HTMLElement>('.rechercheLinesContainer'),
                        intro: 'En cliquant sur l\'icône <img src="https://firsteco.fr/resources/image/6dots_move_search.png" /> et en gardant le clic vous pouvez faire glisser une recherche pour la déplacer et changer sa position.',
                        position: 'top'
                    },
                ];
            }
        }

        let a = this;
        introJs().setOptions({
            nextLabel: '>>',
            prevLabel: '<<',
            doneLabel: 'Commencer',
            disableInteraction: true,
            steps: steps
        }).start();
    }

    protected close(): void {
      this.dialogRef.close();
    }

    filterChange($event: string) {
        this.filter = $event;
    }

    protected readonly IconType = IconType;

    drop(event: CdkDragDrop<SearchInterface[], any>) {
        // CDK function to reorder the list
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

        // Then we send the result
        this.mySearchesService.updateSearchesOrder(this.mySearchList).subscribe(
            () => {
            },
            (error: Error) => {
            }
        );
    }
}
