import {AfterViewInit, Component, OnInit, OnDestroy, Inject, LOCALE_ID, ViewChild, Input} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import { MediaQueryService } from 'src/app/services/media-query/media-query.service';
import { FavoritesService } from 'src/app/services/favorites/favorites.service';
import { FavoritesInterface } from 'src/app/interfaces/favorites/favorites.interface';
import { PaginatedListComponent } from '../paginated-list/paginated-list.component';
import { ArticleInterface } from 'src/app/interfaces/articles/article.interface';
import {ToastService} from '../../services/toast/toast.service';
import {AlertController, IonContent, ModalController, NavController, Platform} from '@ionic/angular';
import {Geolocation} from '@awesome-cordova-plugins/geolocation/ngx';
import {FavorisFolder} from '../../interfaces/favoritesFolders/favorites-folders.interface';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {UserService} from '../../services/user/user.service';
import {ArticlesService} from '../../services/articles/articles.service';
import {SelectionModel} from '@angular/cdk/collections';
import {AddFavoriteFolderModalComponent} from '../modals/add-favorite-folder-modal/add-favorite-folder-modal.component';
import {Utils} from '../../classes/Utils';
import {SearchesService} from '../../services/searches/searches.service';
import {NewsletterTemplateComponent} from '../modals/newsletter-template/newsletter-template.component';
import {MailsService} from '../../services/mails/mails.service';
import {IconType} from "../parts/fe-icon/fe-icon.component";
import {AppPreferencesService} from "../../services/app-preferences/app-preferences.service";
import {MatSidenavContainer} from "@angular/material/sidenav";
import {ContactLineInterface} from "../modals/fe-carnet-adresses-modal/fe-carnet-adresses-modal.component";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {filter, takeUntil} from 'rxjs/operators';
import {PrintInterface} from "../../interfaces/print/print.interface";
import {Printer, PrintOptions} from "@awesome-cordova-plugins/printer/ngx";
import {SnackbarService} from "../../services/snackbar/snackbar.service";
import {FeMailModalComponent} from "../modals/fe-mail-modal/fe-mail-modal.component";
import {ModalService} from "../../services/modal/modal.service";
import {FileOpener} from "@awesome-cordova-plugins/file-opener/ngx";
import {File} from "@awesome-cordova-plugins/file/ngx";
import {saveAs} from 'file-saver';
import {AddFavoriteModalComponent} from "../modals/add-favorite-modal/add-favorite-modal.component";
import LatLngLiteral = google.maps.LatLngLiteral;
import {MapInfoWindow, MapMarker} from '@angular/google-maps';
import {MarkerInterface} from '../../interfaces/marker/marker.interface';
import {FeArticleModalComponent} from '../modals/fe-article-modal/fe-article-modal.component';
import Icon = google.maps.Icon;
import {SessionInterface} from '../../interfaces/session/session.interface';
import {MatDialog} from '@angular/material/dialog';
import {GeolocModalComponent} from '../modals/geoloc-modal/geoloc-modal.component';
import introJs from "intro.js";
import {AuthService} from "../../services/auth/auth.service";
import {GlobalService} from '../../services/global/global.service';
import {FolderService} from "../../services/folder/folder.service";
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'my-favorites',
  templateUrl: 'my-favorites.component.html',
  styleUrls: ['my-favorites.component.scss'],
})
export class MyFavoritesComponent implements OnInit, OnDestroy, AfterViewInit {

  /*
    ----------------------------- RESTE A FARE -----------------------------
  TODO Affichage du champ commentaire
  TODO Affichage du mode Carte
   */

  @ViewChild('sidenavContainer', { static: false }) sideNavContainer: MatSidenavContainer;
  @ViewChild('paginator') paginator: MatPaginator;
  currentFolder: FavorisFolder = null;
  public menuIconActions: string = 'keyboard_arrow_down';
  public menuOpenActions: boolean = false;
  public smallSidenavDesktop: boolean = false;
  public loadingInProgress: boolean = false;
  public isSideNavOpen: boolean = true;
  public sideNavIcon: string = 'keyboard_double_arrow_left';
  protected unsubscribe$: Subject<any> = new Subject<any>();
  protected selection: SelectionModel<number> = new SelectionModel<any>(true, []);
  protected dataSource: MatTableDataSource<ArticleInterface> = new MatTableDataSource<ArticleInterface>();
  private file = new File();
  protected page: number = 0;
  protected pageSize: number = 25;

  public listOrMaps: string;

  public zoom: number = 8;
  public mapCenter: LatLngLiteral = {
    lat: 47.3167,
    lng: 5.0167
  };
  public markerIcon: Icon = {
    url: './assets/images/marker_fe.png',
    scaledSize: {
      width: 34,
      height: 60,
      equals(other: google.maps.Size | null): boolean {
        return false;
      }
    }
  };
  public markers: Array<MarkerInterface>;
  currentIW: MapInfoWindow;
  previousIW: MapInfoWindow;

  // Wheel event on scroll
  @ViewChild('tabGroup') tabGroup;
  @Input()  public article: ArticleInterface;
  @ViewChild('scrollArea') scrollArea: IonContent;

  public selectedIndex: number = 0;

  private _favoriteFolders: FavorisFolder[];
  protected currentIndex: number = 0;
  @Input () public currentFolderId: number = 0;
  public userToken: string;
  public newsLetterAccess: boolean;
  public get favoriteFolders() {
    return this._favoriteFolders;
  }
  public set favoriteFolders(favoriteFolders: FavorisFolder[]) {
    this._favoriteFolders = favoriteFolders;
  }
  @Input() public style: string;
  @Input() public selectionModel2: SelectionModel<string>;
  public folderID: string;
  protected listStyle: string = 'big';

  protected introJsBusinessSignalInit: boolean = false;
  protected introJsTourId: number;
  private routerSubscription: Subscription;
  private noArticleErrMsg = 'Vous devez sélectionner au moins un article pour effectuer cette opération.';

  /**
   * Override query from PaginatedListComponent
   */
  protected get query(): any {
    const query: any = {
      page: this.page + 1,
      pageSize: this.pageSize
    };

    return query;
  }

  constructor(
    public mediaQueryService: MediaQueryService
    , private favoritesService: FavoritesService
      , public toastService: ToastService
      , @Inject(LOCALE_ID) public locale: string
      , public modalController: ModalController
      , public geolocation: Geolocation
      , public dialog: MatDialog
      // , public spinnerService: NgxSpinnerService
      , private alertController: AlertController
      , public userService: UserService
      , public articlesService: ArticlesService
      , private authService: AuthService
      , public mySearchesService: SearchesService
      , public navController: NavController
      , private platform: Platform
      , private printer: Printer
      , private fileOpener: FileOpener
      , private modalService: ModalService
      , private appPreferencesService: AppPreferencesService
      , private mailService: MailsService
      , private snackBarService: SnackbarService
      , private globalService: GlobalService
      , private folderService: FolderService
      , private router: Router
  ) {
    this.userToken = Utils.userToken;
    this.userService.getSessionDatas().pipe(
    ).subscribe((sessionData: SessionInterface) => {
      if (sessionData !== undefined) {
        this.newsLetterAccess = sessionData.customerDetails.hasNewsletterAccess;
      }
    });
  }

  public ngOnInit(): void {
      this.favoritesService.getFavoritesFolders().pipe(
          takeUntil(this.unsubscribe$)
      ).subscribe((data: Array<FavorisFolder>) => {
        this.favoriteFolders = data;
        if (this.getParameterByName('folderID') !== undefined) {
          this.globalService.updateMenuLink('myFavorites');
          this.folderID = this.getParameterByName('folderID');
          const index = this.favoriteFolders.findIndex(x => x.folderID.toString() === this.folderID);
          this.currentIndex = index;
          this.currentFolder = this.favoriteFolders[index];
          this.loadFolderArticles(index);
        } else {
          if (this.currentFolder === null) {
            this.currentFolder = data[0];
            this.currentIndex = 0;
            this.loadFolderArticles(0);
          }
        }
      });

      if (this.routerSubscription == null) {
        this.routerSubscription = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
          // Vérifiez si c'est la route actuelle et non juste une navigation dans l'application
          if (this.router.url.indexOf('/my-favorites') > 0) {
            if (this.getParameterByName('folderID') !== undefined) {
              this.globalService.updateMenuLink('myFavorites');
              this.folderID = this.getParameterByName('folderID');
              const index = this.favoriteFolders.findIndex(x => x.folderID.toString() === this.folderID);
              this.currentIndex = index;
              this.currentFolder = this.favoriteFolders[index];
              this.loadFolderArticles(index);
            }
          }
        });
      }

    // this.favoritesService.needRefresh.subscribe(value => {
    //   // if (value) {
    //   //   this.init<FavoritesInterface>();
    //   // }
    // });
    // Cas où on vient dans les favoris depuis clic sur le toast

    this.mySearchesService.myEventToFavorites.subscribe((eventData: number) => {
      this.currentIndex = eventData;
      this.scrollArea.scrollToTop(0);
    });

    this.userService.getSessionDatas()
        .pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(
        (session: SessionInterface) => {
          if (session !== undefined) {
            // Feature tours for automatic launch
            if (!this.authService.roleCom) {
              try {
                if (session.featureTours !== undefined && session.featureTours.length > 0) {
                  for (const featureTour of session.featureTours) {
                    if (featureTour.tourComponent === 'favorites') {
                      if(!this.introJsBusinessSignalInit) {
                        this.introJsTourId = featureTour.tourId;
                        this.introJsBusinessSignalInit = true;
                        console.log("tour id = " + this.introJsTourId);
                      }
                    }
                  }
                }
              } catch (e) {
              }
            }

          }
        }
    );

  }

  public launchIntroFeatureTour(tourId: number) {
    console.log('intro.js favorites start for tourId = ' + tourId)

    let steps = [];

    if(tourId !== undefined && tourId === 2) {
      steps = [
        {
          title: 'Bienvenue sur vos favoris',
          intro: 'Retrouvez ici tous vos signaux business classés dans des dossiers de favoris.'
        },
        {
          title: 'Ajouter un article aux favoris',
          intro: 'Vous pouvez ajouter des articles à un dossier de favoris depuis vos signaux business.'
        },
        {
          title: 'Création d\'un dossier',
          element: document.querySelector<HTMLElement>('.createFolderBtnTour'),
          intro: 'Cliquez sur cette icône pour créer un nouveau dossier pour classer vos favoris.',
          position: 'right'
        },
        {
          title: 'Dossier par défaut',
          element: document.querySelector<HTMLElement>('.unClassifiedFavoris'),
          intro: 'Ce dossier de favoris est celui par défaut.',
          position: 'right'
        },
        {
          title: 'Liste des dossiers',
          element: document.querySelector<HTMLElement>('.favoriteListItems'),
          intro: 'Retrouvez ici tous vos autres dossiers de favoris.',
          position: 'right'
        },
      ];
    }

    if(tourId !== undefined && steps.length > 0) {
      let a = this;
      introJs().setOptions({
        nextLabel: '>>',
        prevLabel: '<<',
        doneLabel: 'Commencer',
        disableInteraction: true,
        steps: steps
      }).onexit(function() {
        a.userService.doneFeatureTour(tourId);
        a.introJsTourId = undefined;
      })
      .start();
    }

  }

  public ngAfterViewInit() {
    this.launchIntroFeatureTour(this.introJsTourId);
  }

  getParameterByName(name: any) {
    const url = window.location.href;
    name = name.replace(/[[]]/g, '\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) {
      return undefined;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace('/+/g', ' '));
  }

  public ionViewWillEnter(): void {
    // this.favoritesService.getFavoritesFolders().subscribe((data: Array<FavorisFolder>) => {
    //   this.favoriteFolders = data;
    // });
  }

  /**
   * Load data. Used by PaginatedListComponent
   */
  protected load(): Observable<FavoritesInterface> {
    // this.spinnerService.show();
    if (this.currentIndex !== undefined)
    return this.loadFavoriteFolder(this.currentIndex);
    return this.favoritesService.getFavorites(this.query);
  }

  /**
   * get data and count. Used by PaginatedListComponent
   * @param favorites FavoritesInterface
   */
  protected getDataTypedAndCountAfterLoad(favorites: FavoritesInterface): { data: Array<ArticleInterface>, count: number } {
    return { data: favorites.articles_, count: favorites.count }; // articles_ : custom key to store populated articles.
  }

  public scrollTabs(event) {
    const children = this.tabGroup._tabHeader._elementRef.nativeElement.children;

    // get the tabGroup pagination buttons
    const back = children[0];
    const forward = children[2];

    // depending on scroll direction click forward or back
    if (event.deltaY > 0) {
      forward.click();
    } else {
      back.click();
    }
  }

  /**
   * Listener for tab change
   * @param $event MatTabChangeEvent
   */
  public selectedTabChange($event: MatTabChangeEvent): void {
    // this.disableTabs = true;
    this.currentIndex = $event.index;
    this.scrollArea.scrollToTop(0);
  }

  private loadFavoriteFolder(index: number): Observable<FavoritesInterface> {
    const folder = this.favoriteFolders[index];
    this.currentFolderId = folder.folderID;
    return this.favoritesService.getFavoritesFromFolder(folder.folderID, this.query);
  }

  private loadFavoriteFolderByFolderIndex(index: number) {
    this.selectedIndex = index;
    this.currentIndex = index;
    const folder = this.favoriteFolders[index];
    this.currentFolderId = folder.folderID;
  }

  async createNewsletter(index: number) {
    const dial = this.dialog.open(NewsletterTemplateComponent, {
      height: '600x',
      width: '1000px',
      data: {nlName: this.favoriteFolders[index].libelle}
    });
    dial.afterClosed().subscribe(value => {
      if (value) {
        this.confirmNlCreation(value);
      }
    });
    // const modal = await this.modalController.create({
    //   component: NewsletterTemplateComponent,
    //   cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'templateDesktopDialog',
    //   componentProps: {
    //     nlName: this.favoriteFolders[index].libelle
    //   },
    //   backdropDismiss: true
    // });
    //
    //
    //
    // modal.onDidDismiss().then(value => {
    //   if (value && value.data) {
    //     this.confirmNlCreation(value.data);
    //   }
    // });

    // return await modal.present();
  }

  async confirmNlCreation(data: any) {
    const alert = await this.alertController.create({
      header: 'Confirmation',
      cssClass: 'desktopDialog',
      message: 'Une newsletter va être créée avec le template et les articles sélectionnés. Voulez-vous continuer ?',
      buttons: [
        {
          text: 'Oui',
          handler: () => {
            this.mailService.setTemplateSelection(data, this.favoriteFolders[this.currentIndex]);

            Utils.navigate('/app/newsletter', this.navController);
          }
        }, {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => { }
        }
      ]
    });
    await alert.present();
  }

  // ------------ NEO

  protected readonly IconType = IconType;

  public toogleSidenavSize(): void {
    this.isSideNavOpen = !this.isSideNavOpen;
    if (this.isSideNavOpen) {
      this.sideNavIcon = 'keyboard_double_arrow_left';
    } else {
      this.sideNavIcon = 'keyboard_double_arrow_right';
    }

    this.smallSidenavDesktop = !this.smallSidenavDesktop;
    this.appPreferencesService.set('smallSidenavDesktop', this.smallSidenavDesktop.toString());

    setTimeout(() => {
      this.sideNavContainer.updateContentMargins();
    }, 250);
  }

  onDropDownMenuOpenActions($event) {
    $event.stopPropagation();
    this.menuOpenActions = !this.menuOpenActions;
    if (this.menuOpenActions) {
      this.menuIconActions = 'keyboard_arrow_up';
    }
  }

  onMenuActionsClosed() {
    this.menuOpenActions = false;
    this.menuIconActions = 'keyboard_arrow_down';
  }

  protected loadFolderArticles(index: number): void {
    this.page = 0;
    if (this.paginator != null) {
      this.paginator.pageIndex = 0;
    }
    this.currentFolder = this.favoriteFolders[index];
    this.currentIndex = index;
    this.reloadArticles();
    // this.favoritesService.
  }

  protected reloadArticles(): void {
    this.checkIfPaginatorHasToUpdate();
    this.selection.clear();
    this.loadingInProgress = true;
    this.loadFavoriteFolder(this.currentIndex).subscribe(value => {
      this.loadingInProgress = false;
      this.dataSource = new MatTableDataSource<ArticleInterface>(value.articles_);
      this.markers = this.getMarkers(value.articles_);
    });
  }

  protected checkAllArticles($event: MatCheckboxChange) {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach(value => {
        this.selection.select(value.id);
      });
    }
  }

  protected isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows && numSelected > 0;
  }

  protected getArticlesSelected(): ArticleInterface[] {
    let articlesToReturn: ArticleInterface[] = [];

    this.dataSource.data.forEach(value => {
      if (this.selection.isSelected(value.id)) {
        articlesToReturn.push(value);
      }
    });

    return articlesToReturn;
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  protected listStyleChanged(listStyle: string): void {
    this.listStyle = listStyle;
  }


  // ------------ Il faudrait factoriser cette partie avec celle de business signals ------------

  protected printArticles() {
    if (this.selection.selected.length < 1) {
      this.snackBarService.showDangerSnackbar('Opération impossible', this.noArticleErrMsg);
    // } else if (this.selection.selected.length > 10) {
    //   this.snackBarService.showDangerSnackbar('Impression impossible', 'L\'impression d\'article ' +
    //       'est imité à un maximum de 10 articles à la fois.');
    } else {
      this.articlesService.printArticlesList(this.selection.selected).pipe(
          takeUntil(this.unsubscribe$)
      ).subscribe(
          (res: PrintInterface) => {
            // Mobile printing
            if (this.platform.is('ios') || this.platform.is('android')) {
              this.printer.isAvailable()
                  .then(onSuccess => {
                      },
                      onError => {
                      }
                  );

              const options: PrintOptions = {
                name: 'FirstECO_Profil_print',
                orientation: 'portrait'
              };

              this.printer.print(res.html, options)
                  .then(onSuccess => {
                      },
                      onError => {
                      }
                  );
            }
            // Desktop printing
            else {
              const popupWin = window.open('', '_blank');
              popupWin.document.open();
              popupWin.document.write(res.html);
              setTimeout(() => popupWin.print(), 1000);
            }
          }
      );
    }
  }

  protected openEmailModal() {
    if (this.selection.selected.length < 1) {
      this.snackBarService.showDangerSnackbar('Opération impossible', this.noArticleErrMsg);
    } else if (this.selection.selected.length > 10) {
      this.snackBarService.showDangerSnackbar('Envoi impossible', 'Le partage d\'article est ' +
          'limité à un maximum de 10 articles à la fois.');
    } else {
      this.modalService.showCustomModalWithActionOnClose(FeMailModalComponent, {data: {
          articles: this.getArticlesSelected()
        }}, (response: Array<string>) => {
        this.getArticlesSelected().forEach(article => {
          response.forEach(value => {
            if (article.sharedTo == null)
              article.sharedTo = [];
            if (article.sharedTo.indexOf(value) < 0)
              article.sharedTo.push(value);
          });
        });
      });
    }
  }

  protected markAsRead(read: boolean) {
    if (this.selection.selected.length < 1) {
      this.snackBarService.showDangerSnackbar('Opération impossible', this.noArticleErrMsg);
    } else {
      for (const a of this.dataSource.data) {
        if (this.selection.selected.includes(a.id)) {
          a.read = read;
        }
      }

      this.articlesService.markArticlesAsRead(this.selection.selected, read).pipe(
          takeUntil(this.unsubscribe$)
      ).subscribe(
          res => {
            // If online is 200, update the local data by sending article ID
            for (const articleId of this.selection.selected) {
              this.articlesService.updateReadArticle(articleId, read);
            }
          }
      );
    }
  }

  protected exportArticlesList(format: string, selectionModel: any) {
    if (this.selection.selected.length < 1) {
      this.snackBarService.showDangerSnackbar('Opération impossible', this.noArticleErrMsg);
    } else if (this.selection.selected.length > 50) {
      this.snackBarService.showDangerSnackbar('Export impossible', 'L\'export d\'article est ' +
          'limité à un maximum de 50 articles à la fois.');
    } else {
      this.articlesService.exportArticlesList(format, selectionModel)
          .pipe(
              takeUntil(this.unsubscribe$)
          )
          .subscribe(
              response => {
                // TODO : Récupérer dynamiquement le nom du fichier (Content-Disposition introuvable dans le header de la response)
                let fileName = 'FirstECO_export_contacts';

                // TODO NE FONCTIONNE PAS MAIS PISTE DE SOLUTION
                const contentDisposition = response.headers.get('Content-Disposition');
                if (contentDisposition) {
                  const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                  const matches = fileNameRegex.exec(contentDisposition);

                  if (matches != null && matches[1]) {
                    fileName = matches[1].replace(/['"]/g, '');
                  }
                } else {
                  if (format === 'excel') {
                    fileName += '.xlsx';
                  } else {
                    fileName += '.' + format;
                  }
                }

                const fileContent = response.body;

                // Save as for mobile version
                if (this.platform.is('ios') || this.platform.is('android')) {

                  this.file.writeFile(this.file.dataDirectory, fileName, fileContent)
                      .then(
                          _ => {

                            this.fileOpener.showOpenWithDialog(this.file.dataDirectory + fileName, 'text/csv')
                                .then(() => console.log('File is opened'))
                                .catch(e => console.log('Error opening file', e));
                          }
                      )
                      .catch(
                          err => {
                            this.file.writeExistingFile(this.file.dataDirectory, fileName, fileContent)
                                .then(
                                    _ => {
                                      console.log(this.file.dataDirectory);

                                      this.fileOpener.showOpenWithDialog(this.file.dataDirectory + fileName, 'text/csv')
                                          .then(() => console.log('File is opened'))
                                          .catch(e => console.log('Error opening file', e));
                                    }
                                )
                                .catch(
                                    err => {
                                      alert('Failure');
                                    }
                                );
                          }
                      );
                }
                // Save as for desktop version
                else {
                  saveAs.saveAs(fileContent, fileName);
                }
              }
          );
    }
  }

  protected changeArticlesFolder(): void {
    if (this.selection.selected.length < 1) {
      this.snackBarService.showDangerSnackbar('Opération impossible', this.noArticleErrMsg);
    } else {
      this.modalService.showCustomModalWithActionOnClose(AddFavoriteModalComponent,
          {data: {articles: this.selection.selected, actualForlderId: this.currentFolder.folderID}},
          (response) => {
            this.currentFolder.folderArticlesCount -= this.selection.selected.length;
            let text: string = 'L';
            if (this.selection.selected.length > 1) {
              text += 'es articles ont été déplacés';
            } else {
              const article: ArticleInterface = this.getArticle(this.selection.selected[0]);
              text += '\'article <b>\'' + this.troncateText(article.title, 50) + '\'</b> a été déplacé';
            }
            this.getFolderFromId(response.folderID).folderArticlesCount += this.selection.selected.length;
            this.favoritesService.needRefresh.next(true);
            this.reloadArticles();
            this.snackBarService.showSuccessSnackbar('Déplacement réussi', text +
                ' dans le dossier \'' + this.getFavoriteFolderLink(response.folderID, response.libelle) + '\' avec succès.');
          });
    }
  }

  protected removeArticlesFromFolder(): void {
    if (this.selection.selected.length < 1) {
      this.snackBarService.showDangerSnackbar('Opération impossible', this.noArticleErrMsg);
    } else {
      let text: string = 'Confirmez vous le retrait de';
      let confirmText: string = '';
      let errorText: string = 'Une erreur s\'est produite lors du retrait de';
      if (this.selection.selected.length > 1) {
        text += 's articles sélectionnés du dossier ?';
        confirmText = 'Les articles sélectionnés ont été retirés du dossier avec succès.';
        errorText += 's articles sélectionnés.';
      } else {
        const article: ArticleInterface = this.getArticle(this.selection.selected[0]);
        text += ' l\'article <b>\'' + this.troncateText(article.title, 50) + '\'</b> du dossier ?';
        confirmText = 'L\'article <b>\'' + this.troncateText(article.title, 50)
            + '\'</b> a été retiré du dossier avec succès.';
        errorText += ' l\'article <b>\'' + this.troncateText(article.title, 50) + '\'</b>';
      }

      this.modalService.confirmModal(this.selection.selected.length > 1 ? 'Retrait des articles'
          : 'Retrait de l\'article', text, () => {
        this.favoritesService.removeArticleFromFavorites(this.selection.selected).pipe().subscribe(
            () => {
              this.snackBarService.showSuccessSnackbar(this.selection.selected.length > 1
                  ? 'Articles retirés avec succès' : 'Article retiré avec succès', confirmText);
              this.currentFolder.folderArticlesCount -= this.selection.selected.length;
              this.reloadArticles();
            },
            (error: Error) => {
              this.snackBarService.showDangerSnackbar('Opération impossible', errorText);
            }
        );
      });
    }
  }

  protected removeAnArticleFromFolder(): void {
    this.currentFolder.folderArticlesCount --;
    this.reloadArticles();
  }

  protected changeAnArticleFromFolder(fodlerId: number): void {
    this.currentFolder.folderArticlesCount --;
    this.getFolderFromId(fodlerId).folderArticlesCount ++;
    this.reloadArticles();
  }

  private getFolderFromId(folderId: number): FavorisFolder {
    let folder = null;
    this.favoriteFolders.forEach(value => {
      if (value.folderID === folderId) {
        folder = value;
      }
    });
    return folder;
  }

  private getArticle(articleId): ArticleInterface {
    let article: ArticleInterface = null;
    this.dataSource.data.forEach(value => {
      if (value.id == articleId)
        article = value;
    });
    return article;
  }

  private troncateText(text: string, length: number): string {
    let troncateText: string = text;
    if (troncateText.length > length + 2) {
      troncateText = troncateText.substring(0, length) + '...';
    }
    return troncateText;
  }

  protected readonly alert = alert;

  protected handlePageEvent($event: PageEvent) {
    this.pageSize = $event.pageSize;
    this.page = $event.pageIndex;
    this.reloadArticles();
  }

  protected renameFolder(index: number): void {
    if (index === 0) {
      this.snackBarService.showDangerSnackbar('Opération impossible', 'Vous ne pouvez pas renommer le dossier par défaut.');
    } else {
      const oldTitle = this._favoriteFolders[index].libelle;
      this.modalService.inputModal('Renommer le dossier', 'Indiquer un nouveau nom au dossier \'' + oldTitle + '\'.',
          '', '', oldTitle, args => {
            if (!args || args.length <= 0) {
              this.snackBarService.showDangerSnackbar('Renommer le dossier', 'Votre dossier doit avoir un nom pour pouvoir être renommée.');
            }
            else {
              this._favoriteFolders[index].libelle = args;
              this.favoritesService.editFavoritesFolder(this._favoriteFolders[index].folderID, this._favoriteFolders[index])
                  .subscribe(value => {
                    this.snackBarService.showSuccessSnackbar('Opération réussie', 'Le dossier \'' + oldTitle
                        + '\' a bien été renommé en \'' + args + '\'');
                  });
            }

      });
    }
  }

  protected createFolder(): void {
    this.modalService.inputModal('Création d\'un nouveau dossier', 'Indiquer un nom au nouveau dossier.',
        '', '', '',args => {
          if (!args || args.length <= 0) {
            this.snackBarService.showDangerSnackbar('Création d\'un nouveau dossier', 'Vous devez indiquer un nom au dossier à créer.');
          }
          else {
              const folder: FavorisFolder = {
                libelle: args
              };
              this.favoritesService.addFavoritesFolder(folder).subscribe(value => {
                this.favoritesService.getFavoritesFolders();
                this.snackBarService.showSuccessSnackbar('Opération réussie', 'Le dossier \''
                    + this.getFavoriteFolderLink(folder.folderID, folder.libelle) + '\' a été créé avec succès.');
              });
          }
    });
  }

  private getFavoriteFolderLink(folderId: number, folderLabel: string): string {
    return '<b><a (click)=\'' + this.folderService.updateFolderId(folderId) + '\' ' +
        'class="greenC" href="/#/app/my-favorites?folderID='
        + folderId + '">' + this.troncateText(folderLabel, 40) + '</a></>';
  }

  protected removeFolder(index: number): void {
    if (index === 0) {
     this.snackBarService.showDangerSnackbar('Opération impossible', 'Vous ne pouvez pas supprimer le dossier par défaut.');
    } else {
      const folderTitle: string = this._favoriteFolders[index].libelle;
      let message: string = 'Attention, vous allez supprimer le dossier \'' + folderTitle + '\'.';
      if (this._favoriteFolders[index].folderArticlesCount > 1) {
        message += '<br>Les articles dans ce dossier seront également retirés des favoris.';
      } else if (this._favoriteFolders[index].folderArticlesCount > 0) {
        message += '<br>L\'article dans ce dossier sera également retiré des favoris.';
      }
      this.modalService.confirmModal('Retrait du dossier', message, args => {
        this.favoritesService.deleteFavoritesFolder(this.favoriteFolders[index].folderID).subscribe(value => {
          if (this.favoriteFolders[index].folderID === this.currentFolderId) {
            this.loadFolderArticles(0);
          }
          this.snackBarService.showSuccessSnackbar('Dossier retiré avec succès', 'le dossier <b>\''
              + folderTitle + '\'</b> a été retiré avec succès.');
        });
      });
    }
  }

  protected navigateToUrl(url: string) {
    if (Utils.userToken != null) {
      Utils.navigate(url, this.navController, {queryParams: {
          token: Utils.userToken
        }});
    } else {
      Utils.navigate(url, this.navController);
    }
  }

  private checkIfPaginatorHasToUpdate(): void {
    if (this.page !== 0 && (this.pageSize * this.page) >= this.currentFolder.folderArticlesCount) {
      this.page --;
    }
  }

  public mapClick() {
    if (this.previousIW) {
      this.previousIW.close();
    }
  }

  public getClusterStyle(markers, count: number) {
    return {
      text: markers.length,
      index: 3
    };
  }

  openInfoWindow(mapMarker: MapMarker, infoWindow: MapInfoWindow) {
    if (this.previousIW) {
      this.currentIW = infoWindow;
      this.previousIW.close();
    }
    this.previousIW = infoWindow;
    infoWindow.open(mapMarker);
  }

  /**
   * Open article from Maps Info Window
   * @param article ArticleInterface
   */
  public async openReadingModalFromMarker(article: ArticleInterface): Promise<void> {
    this.modalService.showCustomModal(FeArticleModalComponent, {
      data: {
        article: article,
        keywords: []
      }});
  }

  /**
   * get marker from articles list.
   * @param data Array<ArticleInterface>
   */
  public getMarkers(data: Array<ArticleInterface>): Array<MarkerInterface> {
    const markers: Array<MarkerInterface> = [];
    if (data != null) {
      data.forEach((article: ArticleInterface) => {
        let latitude: number;
        let longitude: number;
        if (article.entreprises !== undefined && article.entreprises.length > 0) {
          article.entreprises.forEach(value => {
            if (longitude !== undefined && latitude !== undefined) {
              return;
            }
            if (value.longitude !== undefined && value.latitude !== undefined) {
              latitude = value.latitude;
              longitude = value.longitude;
            }
          });
        }
        if (longitude !== undefined && latitude !== undefined) {
          // check for doubloons and slightly move them if needed
          markers.forEach(value => {
            if (value.markerOption.position.lat === latitude && value.markerOption.position.lng === longitude) {
              const a: number = 360.0 / markers.length;
              latitude = value.markerOption.position.lat + -.00004 * Math.cos((+a * markers.length) / 180 * Math.PI);
              longitude = value.markerOption.position.lng + -.00004 * Math.cos((+a * markers.length) / 180 * Math.PI);
            }
          });

          let pushMarker = false;
          if (this.selection.isEmpty() || this.selection.isSelected(article.id)) {
            pushMarker = true;
          }
          if (pushMarker) {
            markers.push({
              data: article,
              markerOption: {
                position: {
                  lat: latitude,
                  lng: longitude
                },
                draggable: false,
                icon: this.markerIcon
              }
            });
          }
        }
      });

      if (markers.length > 1) {
        this.findMapCenter(markers);
      } else if (markers.length === 1) {
        this.mapCenter.lat = (markers[0].markerOption.position.lat as number);
        this.mapCenter.lng = (markers[0].markerOption.position.lng as number);
      }
    }
    return markers;
  }

  firstCenterCalc = true;

  public findMapCenter(data: Array<MarkerInterface>) {
    if (!this.firstCenterCalc) {
      return;
    }
    this.firstCenterCalc = false;
    if (!(data.length > 0)) {
      return false;
    }

    const num_coords: number = data.length;

    let X: number = 0.0;
    let Y: number = 0.0;
    let Z: number = 0.0;

    let maxLat: number;
    let maxLong: number;
    let minLat: number;
    let minLong: number;

    data.forEach(value => {
      const lati = (value.markerOption.position.lat as number) * Math.PI / 180;
      const long = (value.markerOption.position.lng as number) * Math.PI / 180;

      const a = Math.cos(lati) * Math.cos(long);
      const b = Math.cos(lati) * Math.sin(long);
      const c = Math.sin(lati);

      X += a;
      Y += b;
      Z += c;

      // for zoom
      if (maxLat === undefined || maxLat < (value.markerOption.position.lat as number)) {
        maxLat = (value.markerOption.position.lat as number);
      }
      if (maxLong === undefined || maxLong < (value.markerOption.position.lng as number)) {
        maxLong = (value.markerOption.position.lng as number);
      }
      if (minLat === undefined || minLat > (value.markerOption.position.lat as number)) {
        minLat = (value.markerOption.position.lat as number);
      }
      if (minLong === undefined || minLong > (value.markerOption.position.lng as number)) {
        minLong = (value.markerOption.position.lng as number);
      }
    });

    X /= num_coords;
    Y /= num_coords;
    Z /= num_coords;

    const lon = Math.atan2(Y, X);
    const hyp = Math.sqrt(X * X + Y * Y);
    const lat = Math.atan2(Z, hyp);

    this.mapCenter.lat = (lat * 180 / Math.PI);
    this.mapCenter.lng = (lon * 180 / Math.PI);

    let mapWidth: number;
    let mapHeight: number;

    if (this.mediaQueryService.mobileQuery.matches) {
      mapWidth = window.innerWidth;
      mapHeight = window.innerHeight - (60 + 56 + 112);
    } else {
      mapWidth = window.innerWidth - 260 - (60 * (window.innerWidth - 260) / 100);
      mapHeight = window.innerHeight - (60 + 56 + 112);
    }

    this.zoom = this.getBoundsZoomLevel({maxLat: maxLat, minLat: minLat, maxLong: maxLong, minLong: minLong}, {width: mapWidth, height: mapHeight});
    // if window.innerWidth;
  }

  getBoundsZoomLevel(bounds, mapDim) {
    const WORLD_DIM = { height: 256, width: 256 };
    const ZOOM_MAX = 21;

    function latRad(lat) {
      const sin = Math.sin(lat * Math.PI / 180);
      const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx, worldPx, fraction) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    const latFraction = (latRad(bounds.maxLat) - latRad(bounds.minLat)) / Math.PI;

    const lngDiff = bounds.maxLong - bounds.minLong;
    const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  }
}
