<div class="feGestionRecherchesModal feModal">
    <div class="feModalHeader">
        <span class="feModalTitle">Géolocalisation</span>
        <div class="feModalCloseButton">
            <span mat-dialog-close>Fermer</span>
            <mat-icon mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="feModalContent">
        <mat-label>Sélectionner les critères de localisation : </mat-label>
        <mat-form-field>
            <mat-label>Localisation</mat-label>
            <input matInput
                   placeholder="Choisissez une ville"
                   [formControl]="control"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let city of filteredCities | async;" (click)="changeSelection(city)" [value]="city.name + ' (' + city.zip + ')'">{{city.name + ' (' + city.zip + ')'}}</mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Rayon</mat-label>
            <mat-select [(value)]="rayon" formControlName="select">
                <mat-option value="10">10km</mat-option>
                <mat-option value="20">20km</mat-option>
                <mat-option value="50">50km</mat-option>
                <mat-option value="100">100km</mat-option>
                <mat-option value="200">200km</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div style="height: 50px; display: flex; flex-direction: row; justify-content: flex-end; align-items: center; padding-right: 10px;">
        <fe-button (click)="validateGeoloc()" [text]="'Valider'"/>
        <fe-button (click)="closeDialog()" [text]="'Annuler'" [type]="'TERTIARY'" [color]="'DANGER'" style="margin-left: 5px"></fe-button>
    </div>
</div>
