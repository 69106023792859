import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Observable, combineLatest } from 'rxjs';
import { MediaQueryService } from 'src/app/services/media-query/media-query.service';
import { SelectionModel } from '@angular/cdk/collections';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { AddEditAddressModal } from '../modals/address-add-edit/address-add-edit.modal';
import { ModalController, AlertController } from '@ionic/angular';
import { AddressesService } from 'src/app/services/addresses/addresses.service';
import { AddressesInterface } from 'src/app/interfaces/addresses/addresses';
import { ToastService } from 'src/app/services/toast/toast.service';
import { AddressInterface } from 'src/app/interfaces/address/address';
import { ErrorStatus } from 'src/app/classes/ErrorStatus.class';

@Component({
  selector: 'addresses',
  templateUrl: 'addresses.component.html',
  styleUrls: ['addresses.component.scss'],
})
export class AddressesComponent implements OnInit, OnDestroy {

  protected unsubscribe$: Subject<any> = new Subject<any>();
  public selectionModel: SelectionModel<AddressInterface> = new SelectionModel<AddressInterface>(true, []);
  // public addresses: AddressesInterface;
  public addresses$: Observable<AddressesInterface>;

  constructor(
    public mediaQueryService: MediaQueryService
    , private modalController: ModalController
    , private addressesService: AddressesService
    , private toastService: ToastService
    , private alertController: AlertController
  ) {
  }

  public ngOnInit(): void {
    this.load();
  }

  /**
   * Load or reload datas
   */
  protected load(): void {
    this.addresses$ = this.addressesService.getAddresses();
  }

  public ngOnDestroy(): void {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public async openAddressFormModal(type: string, address?: AddressInterface): Promise<any> {
    const modal = await this.modalController.create(
      {
        component: AddEditAddressModal,
        componentProps: {
          type: type, // add or edit
          address: address
        }
      }
    );

    // TODO: Action quand on ferme la modale (ex : raffraichir les données de la liste locale)
    modal.onWillDismiss().then(
      (data: any) => {
        if (data.data) {
          this.load(); // refresh data
        }
      }
    );
    return await modal.present();
  }

  /**
   * Call by template. Ask user before delete.
   * @param address AddressInterface / Adresse
   */
  public async askBeforeRemoveAddress(address: AddressInterface): Promise<any> {
    const alert = await this.alertController.create({
      header: 'Attention',
      message: '<b>Voulez-vous vraiment supprimer cette adresse ? </b> <br /><br /> ({{data.email}})',
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => { }
        }, {
          text: 'Oui',
          handler: () => this.removeAddress(address.addressId)
        }
      ]
    });
    await alert.present();
  }

  /**
   * Remove one adresse from adresse
   * @param id number
   */
  private removeAddress(id: number): void {
    this.addressesService.removeAddress(id).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      () => {
        this.toastService.simple('Adresse supprimée avec succès');
        // juste refresh data
        this.load(); // refresh data
      },
      (error: Error | ErrorStatus) => {
        let errorStr: string = 'Une erreur est survenue lors de la suppression de cette adresse';
        // if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
        //   errorStr += `(${this.translateService.instant(error.message)})`;
        // }
        this.toastService.simple(errorStr, { color: 'toasterror' });
      }
    );
  }

  /**
   * Call by template. Ask user before multiple delete.
   * @param addresses Array<AddressInterface>
   */
  public async askBeforeRemoveAddresses(addresses: Array<AddressInterface>): Promise<any> {

    const ids: Array<number> = addresses.map((address: AddressInterface) => {
      return address.addressId;
    });


    let message: string;
    // if one address
    if (addresses.length === 1) {
      message = '<b>Voulez-vous vraiment supprimer cette adresse ? </b> <br /><br />';
    } else {
      // if more than one addresses : generate things like that : (adress1@test.com, adress2@test.com, adress3@test.com)
      const addressesStr: string = addresses.reduce((accumulator: string, currentValue: AddressInterface, currentIndex: number, array: Array<AddressInterface> ) => {
        return (currentIndex < array.length - 1) ? accumulator + currentValue.data.email + ', ' : accumulator + currentValue.data.email;
      }, '');
      // generate message with email addresses
      message = '<b>Voulez-vous vraiment supprimer ces {{count}} adresses ? </b> <br /><br />';
    }

    const alert = await this.alertController.create({
      header: 'Attention',
      message: message,
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => { }
        }, {
          text: 'Oui',
          handler: () => this.removeMultipleAddresses(ids)
        }
      ]
    });
    await alert.present();
  }

  public removeMultipleAddresses(ids: Array<number>): void {

    const arrayAddressesToDelete: Array<Observable<any>> = ids.map((id: number) => {
      return this.addressesService.removeAddress(id);
    });

    combineLatest(arrayAddressesToDelete).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      () => {
        this.toastService.simple('Adresses supprimées avec succès');
        // clear selectionModel to active disabled button when selectionModel is set
        this.selectionModel.clear();
        // juste refresh data
        this.load(); // refresh data
      },
      (error: Error | ErrorStatus) => {
        let errorStr: string = 'Une erreur est survenue lors de la suppression de ces adresses';
        // if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
        //   errorStr += `(${this.translateService.instant(error.message)})`;
        // }
        this.toastService.simple(errorStr, { color: 'toasterror' });
      }
    );
  }
}
