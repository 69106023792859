<div class="feGestionRecherchesModal feModal">
  <div class="feModalHeader">
    <span class="feModalTitle">Résumé des critères sélectionnés pour la recherche "{{search.data.label}}"</span>
      <button  mat-icon-button aria-label="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
  </div>
</div>

<ion-content class="ion-padding">
  <mat-accordion [multi]="true">
    <mat-expansion-panel *ngFor="let criterionKey of getLibellesKeys(); first as isFirst" [expanded]="true">
      <mat-expansion-panel-header class="critetionHeader">
        <mat-panel-title class="critetionTitle">
          {{getCriterionTypeLabel(criterionKey)}}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="criterion" *ngFor="let tag of search.data.libelles[criterionKey]"> &bull; {{tag}}</div>
    </mat-expansion-panel>
    <ng-container *ngIf="(search.data.geolocFrom !== undefined || search.data.cityFrom !== undefined)">
      <div class="geolocDiv">Où : {{search.data.geolocFrom !== undefined ? 'Ma position' : (searchesService.getCityById(search.data.cityFrom) | async).name}} - Rayon : {{search.data.geolocDistanceRadius}}Km</div>
    </ng-container>
  </mat-accordion>
</ion-content>
