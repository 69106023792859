import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Route, Router, ActivatedRoute } from '@angular/router';
import { Observable, of, from, throwError, Subject} from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NavController } from '@ionic/angular';
import { AppPreferencesService } from 'src/app/services/app-preferences/app-preferences.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { map, catchError, switchMap, tap, takeUntil} from 'rxjs/operators';
import { ApiService } from 'src/app/services/api/api.service';
import {NavigationOptions} from '@ionic/angular/common/providers/nav-controller';
import { Utils } from 'src/app/classes/Utils';
import {LoginComponent} from '../../components/login/login.component';
import {SessionInterface} from '../../interfaces/session/session.interface';
import {UserService} from '../../services/user/user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {
    private unsubscribe$: Subject<any> = new Subject<any>();

    constructor(
        private authService: AuthService
        , private navController: NavController
        , private appPreferencesService: AppPreferencesService
        , private toastService: ToastService
        , private apiService: ApiService
        , private userService: UserService
        , private router: Router
        , private route: ActivatedRoute
    ) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        const url: string = state.url;
        // const url2 = window.location.href;
        //
        LoginComponent.url = state.url;
        return of(this.authService.authOk);
        // if we get a token if url (autoconnect via token in url), we connect user and after checkAuth
        // if (typeof state.root.queryParams !== 'undefined' && typeof state.root.queryParams.token !== 'undefined' && state.root.queryParams.token !== null && state.root.queryParams.token !== '') {
        //     return this.checkAuth$(state.root.queryParams);
        // } else if (this.getParameterByName('token') !== undefined) {
        //     return this.checkAuth$({'token' : this.getParameterByName('token')});
        // } else {
        //     return this.checkAuth(url);
        // }
    }

    getParameterByName(name: any) {
        const url = window.location.href;
        name = name.replace(/[[]]/g, '\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) { return undefined; }
        if (!results[2]) { return ''; }
        return decodeURIComponent(results[2].replace('/+/g', ' '));
    }

    canLoad(route: Route): Observable<boolean> {
        const url = `/${route.path}`;
        return this.checkAuth(url);
    }

    canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(next, state);
    }

    /**
     * check if token is valid. If invalid, trying to refresh token if token already saved exist. If no token, toast error and redirect to login.
     * @param url : string
     */
    checkAuth(url: string): Observable<boolean> {
        const hasValidAccessToken: boolean = this.authService.hasValidAccessToken();
        // console.log('checkAuth (hasValidAccessToken) ' + hasValidAccessToken);
        // invalid token
        if (!hasValidAccessToken) {

            // get saved token.
            return from(this.appPreferencesService.get('token')).pipe(
                switchMap((token: string) => {
                    // if token, trying to refresh token
                    if (typeof token !== 'undefined' && token !== null && token !== '') {
                        // console.log('refreshToken');
                        return this.authService.refreshToken();
                    } else {
                        // console.log(new Error().stack);
                        throw new Error('E_NO_TOKEN');
                    }
                }),
                catchError((error: Error) => {
                    console.log(error);
                    // toast
                    this.toastService.simple('Vous n\'êtes plus connecté. Vous allez être redirigé vers la page d\'authentification.', { duration: 2500, position: 'bottom', color: 'toasterror' });
                    // delete token and loginData from pref
                    this.appPreferencesService.remove('token')
                        .then(() => this.appPreferencesService.remove('loginData'))
                        .then(() => {
                            // redirect to login page.
                            this.navController.navigateRoot('login');
                        });

                    return of(false);
                })
            );

        } else {
            return of(true);
        }
    }

    checkAuth$(params): Observable<boolean> {
        // get validity of token.
        LoginComponent.url = window.location.href;
        let initPasswordValue = Utils.getParameterByName('initPassword', window.location.href);
        const hasValidAccessToken: boolean = this.authService.hasValidAccessToken();
        const navOptions: NavigationOptions = {
            queryParams: params
        };
        // console.log('checkAuth$ (hasValidAccessToken) ' + hasValidAccessToken);
        Utils.userToken = params.token;
        // console.log('checkAuth$  param token ' + params.token);
        if (hasValidAccessToken) {
            if (this.appPreferencesService.userToken !== params.token) {
                const id = {
                    username: params.token,
                    client_id: 'bypass',
                    bypass_token: '$2a$10$FqzzvQLfIBfqR8qySDOy9evK6jpgPjstdWgdMDXcATTYM3F7NKxd2',
                    checkIsClient: false,
                    stoken: ''
                };
                // console.log('bypass');
                const headers = {};
                if (this.getParameterByName('role') === 'com') {
                    headers['X-Role-Com'] = 'OO64QW6LBS3NG8Z0HZAO';
                } else if (this.getParameterByName('mode') === 'client' && this.getParameterByName('stoken') !== undefined) {
                    id.checkIsClient = true;
                    id.stoken = this.getParameterByName('stoken');
                }

                this.apiService.securePost(`authentification/bypass`, id, headers).subscribe(
                    (response: any) => {
                        // response.body.password = undefined;
                        if (response.body.username !== undefined && response.body.password !== undefined && response.body.password !== '' && response.body.username !== null) {
                            // console.log('fetchTokenViaLink');
                            this.authService.fetchTokenViaLink(response.body.username, response.body.password).subscribe(
                                (res) => {
                                    // If we are connected, we are redirected to the home page
                                    this.authService.authOk = true;
                                    console.log(params);
                                    this.appPreferencesService.setValue('userToken', params.token);

                                    this.userService.clean();
                                    this.userService.init();
                                    this.userService.getSessionDatas().pipe(
                                        takeUntil(this.unsubscribe$)
                                    )
                                    .subscribe(
                                        (sessionData: SessionInterface) => {
                                            if (sessionData !== undefined) {
                                                // console.log(sessionData.customerDetails);
                                                // console.log('mandatoryAuth via classic auth : ' + sessionData.customerDetails.mandatoryAuth);
                                                // console.log('initPasswordValue via classic auth : ' + initPasswordValue);
                                                if (!sessionData.customerDetails.mandatoryAuth) {
                                                    if (initPasswordValue === 'true') {
                                                        delete navOptions.queryParams['initPassword'];
                                                    } else {
                                                        navOptions.queryParams['initPassword'] = true;
                                                    }
                                                    // console.log('redirect to login page with initPassword = ' + navOptions.queryParams['initPassword']);
                                                    this.navController.navigateRoot('login', navOptions);
                                                } else {
                                                    if (initPasswordValue !== undefined) {
                                                        delete navOptions.queryParams['initPassword'];
                                                    }
                                                    this.navController.navigateRoot('app', navOptions);
                                                }
                                            }
                                        }
                                    );
                                },
                                error => {
                                    console.log(error);
                                    // console.log(new Error().stack);
                                    return of(false);
                                }
                            );
                        } else if (response.body.username !== undefined) {
                            if (id.checkIsClient === true && !response.body.isClient) {
                                this.navController.navigateRoot('noProduct', navOptions);
                            } else {
                                this.navController.navigateRoot('login', navOptions);
                            }
                            // console.log('no login/pass');
                        }
                    },
                    error => {
                        console.log(error);
                        // console.log(new Error().stack);
                        this.toastService.simple('Vous n\'êtes plus connecté. Vous allez être redirigé vers la page d\'authentification.', { duration: 2500, position: 'bottom', color: 'toasterror' });
                        // delete token and loginData from pref
                        this.appPreferencesService.remove('token')
                            .then(() => this.appPreferencesService.remove('loginData'))
                            .then(() => {
                                // redirect to login page.
                                this.navController.navigateRoot('login', navOptions);
                            });

                        return of(false);
                    });
            } else {
                return of(true);
            }
        } else {
            const id = {
                username: params.token,
                client_id: 'bypass',
                bypass_token: '$2a$10$FqzzvQLfIBfqR8qySDOy9evK6jpgPjstdWgdMDXcATTYM3F7NKxd2',
                checkIsClient: false,
                stoken: ''
            };
            // console.log('bypass');
            const headers = {};
            if (this.getParameterByName('role') === 'com') {
                headers['X-Role-Com'] = 'OO64QW6LBS3NG8Z0HZAO';
            } else if (this.getParameterByName('mode') === 'client' && this.getParameterByName('stoken') !== undefined) {
                id.checkIsClient = true;
                id.stoken = this.getParameterByName('stoken');
            }
            this.apiService.securePost(`authentification/bypass`, id, headers).subscribe(
                (response: any) => {
                    if (response.body.username !== undefined && response.body.password !== undefined && response.body.password !== '' && response.body.username !== null) {
                        // console.log('fetchTokenViaLink');
                        this.authService.fetchTokenViaLink(response.body.username, response.body.password).subscribe(
                            (res) => {
                                // If we are connected, we are redirected to the home page
                                this.authService.authOk = true;
                                // console.log(params);
                                // console.log('auth OK via bypass');
                                this.appPreferencesService.setValue('userToken', params.token);

                                this.userService.init();
                                this.userService.getSessionDatas().pipe(
                                    takeUntil(this.unsubscribe$)
                                )
                                .subscribe(
                                    (sessionData: SessionInterface) => {
                                        if (sessionData !== undefined) {
                                            // console.log(sessionData.customerDetails);
                                            // console.log('mandatoryAuth via bypass : ' + sessionData.customerDetails.mandatoryAuth);
                                            // console.log('initPasswordValue via bypass : ' + initPasswordValue);
                                            if (!sessionData.customerDetails.mandatoryAuth) {
                                                if (initPasswordValue === 'true') {
                                                    delete navOptions.queryParams['initPassword'];
                                                } else {
                                                    navOptions.queryParams['initPassword'] = true;
                                                }
                                                // console.log('redirect to login page with initPassword = ' + navOptions.queryParams['initPassword']);
                                                this.navController.navigateRoot('login', navOptions);
                                            } else {
                                                this.navController.navigateRoot('app', navOptions);
                                            }
                                        }
                                    }
                                );

                            },
                            error => {
                                console.log(error);
                                return of(false);
                            }
                        );
                    } else if (response.body.username !== undefined) {
                        if (id.checkIsClient === true && !response.body.isClient) {
                            this.navController.navigateRoot('noProduct', navOptions);
                        } else {
                            this.navController.navigateRoot('login', navOptions);
                            // console.log('no login/pass');
                        }
                    }
                },
                error => {
                    console.log(error);
                    this.toastService.simple('Vous n\'êtes plus connecté. Vous allez être redirigé vers la page d\'authentification.', { duration: 2500, position: 'bottom', color: 'toasterror' });
                    // delete token and loginData from pref
                    this.appPreferencesService.remove('token')
                        .then(() => this.appPreferencesService.remove('loginData'))
                        .then(() => {
                            // redirect to login page.
                            this.navController.navigateRoot('login', navOptions);
                        });

                    return of(false);
                });
        }
    }

}
