import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { XFormComponent } from '../../x-form/x-form.component';
import { FormFieldInterface } from 'src/app/interfaces/form-field/form-field.interface';
import { MediaQueryService } from 'src/app/services/media-query/media-query.service';
import { FormGroup } from '@angular/forms';
import { tap, takeUntil } from 'rxjs/operators';
import { ToastService } from 'src/app/services/toast/toast.service';
import { AddressesService } from 'src/app/services/addresses/addresses.service';
import { AddressInterface } from 'src/app/interfaces/address/address';

@Component({
  selector: 'address-add-edit',
  templateUrl: 'address-add-edit.modal.html',
  styleUrls: ['address-add-edit.modal.scss'],
})
export class AddEditAddressModal implements OnInit, OnDestroy {

  protected unsubscribe$: Subject<any> = new Subject<any>();

  public displayForm: boolean = false;

  @ViewChild(XFormComponent, { static: true }) public formComponent: XFormComponent;

  public modalTitle: string;
  public type: string;
  public address: AddressInterface;

  public fields: Array<FormFieldInterface> = [
    {
      type: 'text',
      template: 'default',
      placeholder: 'Nom Complet',
      label: 'Nom Complet',
      default: '',
      name: 'label',
      matLeadingIcon: 'person',
      validators: {
        required: false
      },
      readonly: false
    },

    {
      type: 'email',
      template: 'default',
      placeholder: 'Email' + ' *',
      label: 'Email' + ' *',
      name: 'email',
      default: '',
      matLeadingIcon: 'mail',
      validators: {
        required: true,
        email: true
      },
      readonly: false
    }
  ];

  public formSubmitMethod: Function;

  constructor(
    private modalController: ModalController
    , private navParams: NavParams
    , public mediaQueryService: MediaQueryService
    , public toastController: ToastController
    , public toastService: ToastService
    , private addressesService: AddressesService
  ) { }

  public ngOnInit(): void {

    // Type de la modal ('add' ou 'edit')
    this.type = this.navParams.get('type');
    this.address = this.navParams.get('address');

    // Get the title of the modal
    if (this.type === 'add') {
      this.modalTitle = 'ADDRESS_ADD';
    } else if (this.type === 'edit') {
      this.modalTitle = 'ADDRESS_EDIT';
    }

    // Récupération des données existantes (si on est en mode édition)
    if (typeof this.address !== 'undefined') {

      this.fields.forEach((field: FormFieldInterface) => {
        Object.keys(this.address.data).forEach((key: string) => {
          if (field.name === key) {
            field.default = this.address.data[key];
          }
        });
      });
    }

    // Action au submit du formulaire
    this.formSubmitMethod = (formGroup: FormGroup) => {
      let fctToCall: Observable<any>;
      let message: string;

      if (this.type === 'add') {
        fctToCall = this.addressesService.addAddress(formGroup.value);
        message = 'Adresse ajoutée avec succès';
      } else if (this.type === 'edit') {
        fctToCall = this.addressesService.updateAddress(this.address.addressId, formGroup.value);
        message = 'Adresse mise à jour avec succès';
      }

      return fctToCall.pipe(
        takeUntil(this.unsubscribe$),
        tap(() => {
          this.toastService.simple(message);

          // pass true to refresh data in AddressComponent
          this.close(true);
        })
      );
    };
  }

  /**
  * Submit the form
  */
  public send(): void {
    this.formComponent.submitFromOutside();
  }

  /**
  * Close the modal
  */
  public close(success: boolean = false): void {
    this.modalController.dismiss(success);
  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
