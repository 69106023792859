import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {CustomerDetailsInterface} from '../../interfaces/session/customer-details.interface';
import {ModalController} from '@ionic/angular';
import {ArticleMonitoringService} from '../../services/article-monitoring/article-monitoring.service';
import {UserService} from '../../services/user/user.service';
import {ErrorStatus} from '../../classes/ErrorStatus.class';
import {ToastService} from '../../services/toast/toast.service';
import {LoadingDisplayerComponent} from '../modals/loading-displayer/loading-displayer.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from "../../services/snackbar/snackbar.service";

@Component({
    selector: 'app-monitoring-comment-sharing-modal',
    templateUrl: './monitoring-comment-sharing-modal.component.html',
    styleUrls: ['./monitoring-comment-sharing-modal.component.scss'],
})
export class MonitoringCommentSharingModalComponent implements OnInit {

    @Output() confirm = new EventEmitter<any>();

    protected unsubscribe$: Subject<any> = new Subject<any>();
    customerToken = '';
    companyCustomers: { user_id: string, name: string, email: string }[] = [];
    commentShared: boolean;

    public selectionModel: SelectionModel<CustomerDetailsInterface> = new SelectionModel<CustomerDetailsInterface>(true, []);
    @Input() public articleId;
    @Input() public articleMonitoringId;
    @Input() public comment;

    loadingModal;

    constructor(
        public dialog: MatDialog,
        private articleMonitoringService: ArticleMonitoringService,
        private snackBarService: SnackbarService,
        public dialogRef: MatDialogRef<MonitoringCommentSharingModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.articleId = data.articleId;
        this.articleMonitoringId = data.articleMonitoringId;
        this.comment = data.comment;

    }
    ngOnInit() {
        // Initialiser les company customers avec les clients suivant l'article monitoré
        this.articleMonitoringService.getCustomersMonitoringArticle({articleId: '' + this.articleId + ''})
            .subscribe(
                (entrepriseCustomers) => {
                    if (entrepriseCustomers !== undefined) {
                        for (let i = 0; i < entrepriseCustomers.customers.length; i++) {
                            const userid = entrepriseCustomers.customers[i].user_id;
                            const name = entrepriseCustomers.customers[i].firstname + ' ' + entrepriseCustomers.customers[i].lastname;
                            const email = entrepriseCustomers.customers[i].email;
                            this.companyCustomers.push({'user_id': userid, 'name': name, 'email': email});
                        }
                    }
                }
            );
        this.commentShared = false;
    }

    public shareComment(): void {
        this.commentShared = true;
        const customers = [];
        for (let i = 0; i < this.selectionModel.selected.length; i++) {
            // customers += 'customers=' + this.selectionModel.selected[i].user_id + '&';
            customers.push(this.selectionModel.selected[i].user_id);
        }
        this.loadingModal = this.dialog.open(LoadingDisplayerComponent, {disableClose: true});
        const monitoringCommentAddingData = {
            articleId: this.articleId,
            articleMonitoringId: this.articleMonitoringId,
            comment: this.comment,
            customers: customers,
            privateComment: false
        };
        this.articleMonitoringService.addMonitoredArticleComment(monitoringCommentAddingData).subscribe(next => {
            this.loadingModal.close();
            this.snackBarService.showSuccessSnackbar('Ajout d\'un commentaire', 'Commentaire ajouté avec succès');
            this.close();
        }, (error: Error | ErrorStatus) => {
            const errorStr: string = 'Une erreur est survenue lors de l\'ajout de ce commentaire';
            this.snackBarService.showDangerSnackbar('Opération impossible',
                errorStr);
        });

    }

    public close(): void {
        this.confirm.emit({commentShared: this.commentShared});
        this.dialogRef.close({commentShared: this.commentShared});
    }

}
