import { FormControl } from '@angular/forms';
import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js';

export class PhoneNumberValidator {

  /**
   * [isValidEmail description]
   * @param  {FormControl} formControl [description]
   * @return {[type]}                  [description]
   */
  public static isValidPhoneNumber(formControl: FormControl) {

    // field not required but phone validation => if value = '' and not required error => no error
    if (formControl.value === '') {
      if (typeof formControl.errors !== 'undefined' && formControl.errors && formControl.errors.invalidPhoneNumber &&
        (typeof formControl.errors.required === 'undefined' || formControl.errors.required === null || !formControl.errors.required)) {
        return null;
      }
    } else {
      // get country code from $$countryCode => set by x-input-phone.
      const countryCode: CountryCode = formControl['$$countryCode'];
      const phoneNumber = parsePhoneNumberFromString(formControl.value, countryCode);

      if (phoneNumber && phoneNumber.isValid() && phoneNumber.country === countryCode) {
        return null;
      } else {
        return { invalidPhoneNumber: true };
      }
    }
  }
}
