import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams, Platform} from '@ionic/angular';
import {FEEntrepriseContactInterface} from '../../../interfaces/fe-entreprise-contact/fe-entreprise-contact.interface';

declare var cordova: any;

@Component({
  selector: 'contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss'],
})
export class ContactDetailComponent implements OnInit {
  public contact: FEEntrepriseContactInterface;

  constructor(private modalController: ModalController, private platform: Platform, private navParams: NavParams) {
    this.contact = this.navParams.get('contact');

    if (this.contact.civility === undefined || this.contact.civility === null || this.contact.civility.trim().length === 0) {
      this.contact.civility = 'NC';
    }

    if (this.contact.firstName === undefined || this.contact.firstName === null || this.contact.firstName.trim().length === 0) {
      this.contact.firstName = 'NC';
    }

    if (this.contact.lastName === undefined || this.contact.lastName === null || this.contact.lastName.trim().length === 0) {
      this.contact.lastName = 'NC';
    }

    if (this.contact.role === undefined || this.contact.role === null || this.contact.role.trim().length === 0) {
      this.contact.role = 'NC';
    }

    if (this.contact.domain_label === undefined || this.contact.domain_label === null || this.contact.domain_label.trim().length === 0) {
      this.contact.domain_label = 'NC';
    }

    if (this.contact.level_label === undefined || this.contact.level_label === null || this.contact.level_label.trim().length === 0) {
      this.contact.level_label = 'NC';
    }
  }

  ngOnInit() {}

  public close(): void {
    this.modalController.dismiss();
  }

  /**
   * Ouverture du lien et Log de l'action
   * @param url
   * @param type
   */
  public openUrl(url: string, type?: string) {
    if (!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
      // if (!this.platform.url().startsWith('http')) {
      cordova.InAppBrowser.open(url, '_system', 'hidden=yes,location=no');
      // window.open(this.entreprise.website, '_system');
    } else {
      window.open(url, '_blank');
    }
  }
}
