import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { ModalController } from '@ionic/angular';
import { ArticleInterface } from 'src/app/interfaces/articles/article.interface';
import { EmailArticleModal } from '../../modals/email-article/email-article.modal';
import { MediaQueryService } from 'src/app/services/media-query/media-query.service';

@Component({
  selector: 'article-send-mail-button',
  templateUrl: 'article-send-mail-button.component.html',
  styleUrls: ['article-send-mail-button.component.scss'],
})
export class ArticleSendMailButtonComponent implements OnInit, OnDestroy {

  @Input() public selectionModel: SelectionModel<string>;
  @Input() public isFromActionList: boolean;

  private unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private modalController: ModalController
    , public mediaQueryService: MediaQueryService
  ) {}

  public ngOnInit(): void {

  }

  public async openEmailModal(articles: Array<any>) {
    const modal = await this.modalController.create({
      component: EmailArticleModal,
      cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog',
      componentProps: {
        articles: articles
      }
    });

    return await modal.present();
  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
