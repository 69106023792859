<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="end">
      <button mat-icon-button aria-label="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <ion-title *ngIf="mailHistory">{{mailHistory.subject}}</ion-title>

  <div>
    <mat-card class="loginCard" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
      <div class="mailContainer" [innerHTML]="adaptTableWidth(mailHistory.html) | safe: 'html'"></div>
    </mat-card>
  </div>

</ion-content>
