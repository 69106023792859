import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { share, map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { HttpResponse } from '@angular/common/http';
import { HelpInterface } from 'src/app/interfaces/help/help.interface';

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  private _helpData$: BehaviorSubject<HelpInterface> = new BehaviorSubject(undefined);
  public get helpData$(): Observable<HelpInterface> {
    return this._helpData$;
  }

  constructor(
    private apiService: ApiService
  ) {}

  /**
   * Get the help
   */
  public getHelp(): Observable<HelpInterface> {
    return this.apiService.get<HelpInterface>('v1/help').pipe(
      map((response: HttpResponse<HelpInterface>) => {
        return response.body;
      })
    );
  }
}
