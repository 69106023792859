import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { AppInstallationInterface } from 'src/app/interfaces/app-installation/app-installation.interface';
import { Observable } from 'rxjs/internal/Observable';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { from, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { Firebase } from '@awesome-cordova-plugins/firebase/ngx';
import { DeeplinkService } from '../deeplink/deeplink.service';

const APPINSTALLATION_ENDPOINT: string = 'appinstallations';

@Injectable({
  providedIn: 'root'
})
export class PushService {

  constructor(private apiService: ApiService
    , private device: Device
    , private deeplinkService: DeeplinkService
  ) {

  }

  public init(): void {
    // from(this.firebaseX.hasPermission()).pipe(
    //   switchMap((permission: boolean) => {
    //     if (permission) {
    //       return of(true);
    //     } else {
    //       return from(this.firebaseX.grantPermission());
    //     }
    //   }),
    //   switchMap(() => from(this.resetBadge())),
    //   switchMap(() => from(this.firebaseX.getToken())),
    //   switchMap((token: string) => this.sendAppInstallation(token))
    // ).subscribe((token: string) => {
    // });
    //
    // this.firebaseX.onMessageReceived()
    // .subscribe((data: any) => {
    //   if (data.tap && typeof data.deeplink !== 'undefined') {
    //     let deeplink: any;
    //     try {
    //       deeplink = JSON.parse(data.deeplink);
    //     } catch (error) {
    //     }
    //
    //     if (typeof deeplink !== 'undefined') {
    //       this.deeplinkService.redirect(deeplink);
    //     }
    //   }
    // });

    // this.firebaseX.onTokenRefresh().pipe(
    //   switchMap((token: string) => this.sendAppInstallation(token))
    // ).subscribe((token: string) => {
    // });

  }

  // public resetBadge(): Promise<any> {
  //   return this.firebaseX.getBadgeNumber()
  //   .then((number: any) => {
  //     if (parseInt(number, 10) > 0) {
  //       return this.firebaseX.setBadgeNumber(0);
  //     } else {
  //       return true;
  //     }
  //   });
  // }

  // private sendAppInstallation(token: string): Observable<string> {
  //   const appInstallationData: AppInstallationInterface = {
  //     deviceUUID: this.device.uuid,
  //     deviceOS: this.device.platform,
  //     deviceOSVersion: this.device.version,
  //     deviceNotificationToken: token,
  //   };
  //   return from(this.appVersion.getVersionNumber()).pipe(
  //     switchMap((appVersion: string) => {
  //       appInstallationData.appVersion = appVersion;
  //       return this.apiService.post(APPINSTALLATION_ENDPOINT, appInstallationData);
  //     }),
  //     map(() => token)
  //   );
  // }

}
