import {Component, Input} from '@angular/core';

@Component({
  selector: 'fe-text-area',
  templateUrl: './fe-text-area.component.html',
  styleUrls: ['./fe-text-area.component.scss']
})
export class FeTextAreaComponent {
  @Input('placeHolder') placeHolder = '';

}
