import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SearchInterface} from '../../interfaces/search/search.interface';
import {ModalService} from '../../services/modal/modal.service';
import {FeGestionRecherchesModalComponent} from '../modals/fe-gestion-recherches-modal/fe-gestion-recherches-modal.component';
import {FeCarnetAdressesModalComponent} from '../modals/fe-carnet-adresses-modal/fe-carnet-adresses-modal.component';
import {SearchCreationComponent} from '../my-searches/search-creation/search-creation.component';
import {SearchesInterface} from '../../interfaces/searches/searches.interface';
import {MatDialog} from '@angular/material/dialog';
import {SearchesService} from '../../services/searches/searches.service';
import {IconType} from '../parts/fe-icon/fe-icon.component';
import {FeSearchbarComponent} from "../parts/fe-searchbar/fe-searchbar.component";

@Component({
  selector: 'search-list-container',
  templateUrl: './search-list-container.component.html',
  styleUrls: ['./search-list-container.component.scss'],
})
export class SearchListContainerComponent implements AfterViewInit {
  @ViewChild('feSearchBarBusiness') feSearchBarBusiness: FeSearchbarComponent;
  @Input() public _searchList: SearchInterface[];
  @Input() protected isSmallMode = false;
  @Output() emitter = new EventEmitter();
  protected currentSearch: SearchInterface;
  protected filter: string = '';

  @Input() set searchList(value: SearchInterface[]) {
    // WTF angular ???
    if (!value) {
      return;
    }
    this._searchList = value;
    if (!this.currentSearch) {
      this.currentSearch = this._searchList[0];
    }
  }

  constructor(public modalService: ModalService, public dialog: MatDialog, public mySearchesService: SearchesService) { }

  ngAfterViewInit() {

  }

  public ngOnInit(): void {
    this.mySearchesService.refreshSearchId.subscribe((searchId: number) => {
      try {
        this.currentSearch = this._searchList[searchId];
      } catch (e) {
      }
    });
  }

  setCurrentSearch(selectedSearch: SearchInterface) {
    this.currentSearch = selectedSearch;
    this.emitter.emit(selectedSearch);
  }

  public getMySearches(loadLastSearch?: boolean): void {

    this.mySearchesService.getMySearches().subscribe(
        (mySearches: SearchesInterface) => {
          this.searchList = mySearches.searches;
          if (loadLastSearch) {
            this.mySearchesService.emitEventToBusinessSignals(mySearches.searches[mySearches.searches.length - 1].searchId);
          }
          this.mySearchesService.mySearchList$.subscribe(value => {
            this.searchList = value;
          });
        }
    );
  }

  async createNewSearch() {
    // Utils.navigate('/app/my-searches-add', this.navController);

    const dial = this.dialog.open(SearchCreationComponent, {
      disableClose: true,
      height: 'calc(100% - 73px)',
      width: '100%',
      maxWidth: '100%',
      position: {bottom: '0', top: '73px', right: '0', left: '0'},
      hasBackdrop: true
    });
    dial.afterClosed().subscribe(value => {
      if (value) {
        this.getMySearches(true);
      }
    });
  }

  public showGestionRecherchesModal(): void {
    this.modalService.showCustomModal(FeGestionRecherchesModalComponent, {});
  }

    showTest() {
        this.modalService.showCustomModal(FeCarnetAdressesModalComponent, {});
    }

    protected readonly IconType = IconType;

  filterChange($event: string) {
    this.filter = $event;
  }

  hideSearch(label: string) {
    return !label.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        .includes(this.filter.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
  }

  public clearSearchBar():void {
    this.feSearchBarBusiness.clear();
  }
}
