import {Component, Input, OnInit} from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import {ArticleMonitoringService} from '../../../services/article-monitoring/article-monitoring.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UserService} from '../../../services/user/user.service';
import {DatePipe, formatDate} from '@angular/common';
import {ToastService} from '../../../services/toast/toast.service';
import {SearchInterface} from '../../../interfaces/search/search.interface';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {SearchesService} from '../../../services/searches/searches.service';
import {MediaQueryService} from '../../../services/media-query/media-query.service';
import {NavigationOptions} from '@ionic/angular/common/providers/nav-controller';
import {Utils} from '../../../classes/Utils';

@Component({
  selector: 'app-manage-searches-modal',
  templateUrl: './manage-searches-modal.component.html',
  styleUrls: ['./manage-searches-modal.component.scss'],
})
export class ManageSearchesModalComponent implements OnInit {

  @Input() public searchList: SearchInterface[];

  itemStates: boolean[] = [];
  public displayChips: boolean = false;
  public currentSearch: SearchInterface;
  public currentSearchId: number;
  public currentIndex: number;

  constructor(private modalController: ModalController,
              private navController: NavController,
              public mySearchesService: SearchesService,
              public mediaQueryService: MediaQueryService) { }

  ngOnInit() {
  }


  close() {
    this.modalController.dismiss();
  }

  public drop(event: CdkDragDrop<string[]>) {

    // CDK function to reorder the list
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

    // Then we send the result
    this.mySearchesService.updateSearchesOrder(this.searchList).subscribe(
        () => {
        },
        (error: Error) => {
        }
    );
  }

  setItemState(index: number, state: boolean) {
    this.itemStates[index] = state;
  }

  openBusinessSignals(search: SearchInterface, index: number) {
    search.dataRecentResults.isView = true;
    this.emitEventBusinessSignals(index);

    const params: NavigationOptions = {
      queryParams: {
        search: search.searchId
      }
    };
    Utils.navigate('/app/business-signals', this.navController, params);
    this.close();
  }

  emitEventBusinessSignals(index: number) {
    this.mySearchesService.emitEventToBusinessSignals(index);
  }

  getBadgeLabel(i: number) {
    this.displayChips = false;
    // Results since last connexion
    if (this.searchList[i].dataRecentResults.isView !== undefined && this.searchList[i].dataRecentResults.nbResults !== undefined && this.searchList[i].dataRecentResults.nbResults > 0) {
      this.displayChips = true;
      const nbResult = this.searchList[i].dataRecentResults.nbResults;
      return '+' + nbResult;
    }
    return null;
  }

  isToolTipActive(i: number): boolean {
    this.displayChips = false;
    const nbResults: number = this.searchList[i].dataRecentResults.nbResults;
    const isView: boolean = this.searchList[i].dataRecentResults.isView;
    if (isView !== undefined && !isView && nbResults !== undefined) {
      this.displayChips = true;
    }
    return !this.displayChips;
  }

  getToolTipLabel(i: number) {
    const nbResults: number = this.searchList[i].dataRecentResults.nbResults;
    if (this.displayChips) {
      return nbResults;
    }
    return undefined;
  }

  updateCurrentSearch(search: SearchInterface, index: number, $event) {
    $event.stopPropagation();
    this.currentSearch = search;
    this.currentSearchId = search.searchId;
    this.currentIndex = index;
  }

}
