import { Component, Input } from '@angular/core';
import {EntrepriseInterface} from '../../../interfaces/entreprise/entreprise.interface';
import {ModalService} from '../../../services/modal/modal.service';
import {
  FeGestionRecherchesModalComponent
} from "../../modals/fe-gestion-recherches-modal/fe-gestion-recherches-modal.component";
import {
  FeFicheEntrepriseModalComponent
} from "../../modals/fe-fiche-entreprise-modal/fe-fiche-entreprise-modal.component";
import {ContactInterface} from '../../../interfaces/contact/contact.interface';
import {ArticleInterface} from '../../../interfaces/articles/article.interface';
import {HttpErrorResponse} from '@angular/common/http';
import {SnackbarService} from '../../../services/snackbar/snackbar.service';
import {ArticlesService} from '../../../services/articles/articles.service';
import {Utils} from '../../../classes/Utils';
import {SessionInterface} from '../../../interfaces/session/session.interface';
import {UserService} from '../../../services/user/user.service';
import {IconType} from '../fe-icon/fe-icon.component';

@Component({
  selector: 'fe-entreprise-item',
  templateUrl: './fe-entreprise-item.component.html',
  styleUrls: ['./fe-entreprise-item.component.scss']
})
export class FeEntrepriseItemComponent {
  @Input({required: true}) protected entreprise: EntrepriseInterface;
  @Input({required: true}) protected article: ArticleInterface;

  public webToLead: boolean;
  public webToLeadCompanies: Array<String> = Utils.webToLeadCompanies;
  public companyId: string;

  constructor(private _modalService: ModalService,
              protected snackbarService: SnackbarService,
              private articlesService: ArticlesService,
              protected userService: UserService) {
    this.userService.getSessionDatas().pipe().subscribe(
        (sessionData: SessionInterface) => {
          if (sessionData !== undefined) {
            sessionData.appConfig.viewConfigs.forEach(value => {
              this.companyId = sessionData.customerDetails.companyId;
              if (value.component === 'WEBTOLEAD') {
                if (value.state === 'visible') {
                  this.webToLead = true;
                }
              }
            });
          }
        }
    );
  }

  protected showFicheEntreprise(): void {
    this._modalService.showCustomModal(FeFicheEntrepriseModalComponent, {
      data: {
        entreprise: this.entreprise,
        articleId: this.article.id
      }, disableClose: true});
  }

  exportEnterpriseWebToLead($event: MouseEvent) {
    $event.stopPropagation();
    /* TODO adapter code pour créer le contact selon 2 cas, - Cas classique avec les contacts complets
    * TODO - Cas de contact créés depuis ancien article, traitement à faire pour séparer fonction et nom
    * */
    const self = this;
    const contactWTL = new class {
      entrepriseId: number = self.entreprise.entrepriseId;

    };
    this.articlesService.exportArticleWebToLead(this.article.id, contactWTL)
        .pipe().subscribe(
        () => {
          this.snackbarService.showSuccessSnackbar('WebtoLead exporté avec succès', 'WebtoLead exporté avec succès');
        },
        (her: HttpErrorResponse) => {
          let errorStr: string;
          if (typeof her !== 'undefined' && typeof her.error.message !== 'undefined' && her.error.message !== null && her.error.message !== '') {
            errorStr = her.error.message;
          } else {

          }
          this.snackbarService.showDangerSnackbar('Erreur lors de l\'export WebToLead', errorStr);
        }
    );
  }

    protected readonly IconType = IconType;
}
