<ion-header xmlns="http://www.w3.org/1999/html" no-border>
    <ion-toolbar color="secondary" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}" style="--ion-color-base: unset !important;">
        <div class="toolbarRecherche">
            <div style="width: fit-content; margin-right: auto; display: flex; align-items: center; gap: 8px">
                <mat-form-field class="dateShittyFormField" appearance="legacy" floatLabel="never">
                    <mat-label>Date de début</mat-label>
                    <input style="caret-color: white;" matInput [matDatepicker]="pickerStartDate"
                           (focus)="pickerStartDate.open()"
                           (dateChange)="updateStartDate($event)">
                    <mat-datepicker #pickerStartDate></mat-datepicker>
                </mat-form-field>
                <button class="dropdownBtn" (click)="pickerStartDate.open()" style="padding: 8px 13px 8px 16px;">
                    <fe-icon [color]="ColorType.lightBlack" [iconType]="IconType.calendar"/>
                    {{startDateLabel}}
                </button>

                <mat-form-field class="dateShittyFormField" appearance="legacy" floatLabel="never">
                    <mat-label>Date de fin</mat-label>
                    <input style="caret-color: white;" matInput [matDatepicker]="pickerEndDate"
                           (dateChange)="updateEndDate($event)" (focus)="pickerEndDate.open()">
<!--                    <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>-->
                    <mat-datepicker #pickerEndDate></mat-datepicker>
                </mat-form-field>
                <button class="dropdownBtn" (click)="pickerEndDate.open()" style="padding: 8px 13px 8px 16px;">
                    <fe-icon [color]="ColorType.lightBlack" [iconType]="IconType.calendar"/>
                    {{endDateLabel}}
                </button>

                <button class="dropdownBtn" (click)="onDropDownMenuOpenAffichage($event)" [matMenuTriggerFor]="menuPriority">
                    Tri Priorité {{displayModePriority}}
                    <mat-icon >{{menuIconAffichage}}</mat-icon>
                </button>

                <button class="dropdownBtn"  (click)="onDropDownMenuOpenDate($event)" [matMenuTriggerFor]="menuDate">
                    Tri Ancienneté {{displayModeDate}}
                    <mat-icon >{{menuIconDate}}</mat-icon>
                </button>

                <fe-toggle text="Afficher les articles archivés" (valueChange)="onShowArchivedArticlesChange($event)"/>

                <fe-button text="Réinitialiser les filtres" (click)="checkDeleteFilters(pickerStartDate,pickerEndDate)" icon="true"
                           type="SECONDARY" [iconType]="IconType.delete" smallButton="true" bold="true"/>
            </div>


            <button class="dropdownBtn" style="margin: initial;"  (click)="onDropDownMenuOpenKanban($event)"
                    [matMenuTriggerFor]="menuKanban" *ngIf="isSupervisor">
                {{displayAKanban}}
                <mat-icon >{{menuIconKanban}}</mat-icon>
            </button>

            <mat-menu style="position: absolute" #menuPriority="matMenu" (close)="onMenuAffichageClosed()">
                <fe-menu-item (click)="sortingPriority('no')" color="PRIMARY" text="Pas de tri"
                              [class.selectedOption]="displayModePriority == ''"/>
                <fe-menu-item (click)="sortingPriority('ascending')" color="PRIMARY" text="Croissante"
                              [class.selectedOption]="displayModePriority == 'Croissante'"/>
                <fe-menu-item (click)="sortingPriority('descending')" color="PRIMARY"
                              text="Décroissante" [class.selectedOption]="displayModePriority == 'Décroissante'"/>
            </mat-menu>

            <mat-menu style="position: absolute" #menuDate="matMenu" (close)="onMenuDateClosed()">
                <fe-menu-item (click)="sortingAddDate('no')" color="PRIMARY" text="Pas de tri"
                              [class.selectedOption]="displayModeDate == ''"/>
                <fe-menu-item (click)="sortingAddDate('ascending')" color="PRIMARY" text="Croissante"
                              [class.selectedOption]="displayModeDate == 'Croissante'"/>
                <fe-menu-item (click)="sortingAddDate('descending')" color="PRIMARY"
                              text="Décroissante" [class.selectedOption]="displayModeDate == 'Décroissante'"/>
            </mat-menu>

            <mat-menu style="position: absolute" #menuKanban="matMenu" (close)="onMenuKanbanClosed()">
                <fe-menu-item *ngFor="let customer of companyCustomers" (click)="visualiseOtherKanban(customer)"
                              [class.selectedOption]="displayAKanban == customer.name" color="PRIMARY" [text]="customer.name"/>
            </mat-menu>
        </div>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="container-wrapper">
        <div class="container">
            <div class="header-box" style="position: relative">
                <div class="titleContainer">
                    <span class="veryBigTextBold">À Traiter <span class="number-circle">{{globalCountToProcess}}</span></span>
                    <fe-button *ngIf="!isVisualisation && !showArchivedArticles" hideText="true" icon="true" [iconType]="IconType.add" smallButton="true"
                               type="TERTIARY" matTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="Créer un lead externe" (click)="openLeadCreationModal()"/>
                </div>

                <div style="display: flex; width: 100%; margin: auto; justify-content: center; padding-bottom: 3px;">
                    <span class="badge1" [attr.data-badge]="nbPrio1ToProcess">Priorité A</span>
                    <span class="badge1" [attr.data-badge]="nbPrio2ToProcess">Priorité B</span>
                    <span class="badge1" [attr.data-badge]="nbPrio3ToProcess">Priorité C</span>
                </div>
            </div>
            <mat-card
                    id="todoBox"
                    cdkDropList
                    #todoList="cdkDropList"
                    [cdkDropListData]="todoArticles"
                    [cdkDropListConnectedTo]="[inProgressList, qualifiedList, closedList]"
                    (cdkDropListDropped)="drop($event)"
                    class="list">
                <p class="empty-label" *ngIf="!this.loadingArticle && todoArticles.length === 0">Aucun article</p>
                <app-monitored-article
                        (monitoringPriority)="editMonitoredArticlePriority('todoArticles', $event)"
                        (monitoringIsArchived)="editMonitoredArticleIsArchived('todoArticles', $event)"
                        *ngFor="let monitoredArticle of todoArticles"
                        [monitoredArticle]="monitoredArticle"
                        [showShareButton]="this.showShareButton"
                        [cdkDragDisabled]="monitoredArticle.isArchived || isVisualisation" cdkDrag></app-monitored-article>
            </mat-card>
        </div>
        <div class="container">
            <div class="header-box">
                <div class="titleContainer">
                    <span class="veryBigTextBold">{{ (companyToken === '0010Y00000t5cHBQAY')
                        ? 'Qualifié First ECO' : 'En Cours' }} <span class="number-circle">{{globalCountInProcess}}</span>
                    </span>
                </div>
                <div style="display: flex; width: 100%; margin: auto; justify-content: center; padding-bottom: 3px;">
                    <span class="badge1" [attr.data-badge]="nbPrio1InProcess">Priorité A</span>
                    <span class="badge1" [attr.data-badge]="nbPrio2InProcess">Priorité B</span>
                    <span class="badge1" [attr.data-badge]="nbPrio3InProcess">Priorité C</span>
                </div>
            </div>
            <mat-card
                    id="inProgressBox"
                    cdkDropList
                    #inProgressList="cdkDropList"
                    [cdkDropListData]="inProgressArticles"
                    [cdkDropListConnectedTo]="[todoList, qualifiedList, closedList]"
                    (cdkDropListDropped)="drop($event)"
                    class="list">
                <p class="empty-label" *ngIf="inProgressArticles.length === 0">Aucun article</p>
                <app-monitored-article
                        (monitoringPriority)="editMonitoredArticlePriority('inProgressArticles', $event)"
                        (monitoringIsArchived)="editMonitoredArticleIsArchived('inProgressArticles', $event)"
                        *ngFor="let monitoredArticle of inProgressArticles"
                        [monitoredArticle]="monitoredArticle"
                        [showShareButton]="this.showShareButton"
                        [cdkDragDisabled]="monitoredArticle.isArchived || isVisualisation" cdkDrag></app-monitored-article>
            </mat-card>
        </div>
        <div class="container">
            <div class="header-box">
                <div class="titleContainer">
                    <span class="veryBigTextBold">{{ (companyToken === '0010Y00000t5cHBQAY') ? 'Qualifié PREVOST'
                        : 'Qualifié' }} <span class="number-circle">{{globalCountInQualified}}</span></span>
                </div>
                <div style="display: flex; width: 100%; margin: auto; justify-content: center; padding-bottom: 3px;">
                    <span class="badge1" [attr.data-badge]="nbPrio1InQualified">Priorité A</span>
                    <span class="badge1" [attr.data-badge]="nbPrio2InQualified">Priorité B</span>
                    <span class="badge1" [attr.data-badge]="nbPrio3InQualified">Priorité C</span>
                </div>
            </div>
            <mat-card
                    id="qualifiedBox"
                    cdkDropList
                    #qualifiedList="cdkDropList"
                    [cdkDropListData]="qualifiedArticles"
                    [cdkDropListConnectedTo]="[todoList, inProgressList, closedList]"
                    (cdkDropListDropped)="drop($event)"
                    class="list">
                <p class="empty-label" *ngIf="qualifiedArticles.length === 0">Aucun article</p>
                <app-monitored-article
                        (monitoringPriority)="editMonitoredArticlePriority('qualifiedArticles', $event)"
                        (monitoringIsArchived)="editMonitoredArticleIsArchived('qualifiedArticles', $event)"
                        *ngFor="let monitoredArticle of qualifiedArticles"
                        [monitoredArticle]="monitoredArticle"
                        [showShareButton]="this.showShareButton"
                        [cdkDragDisabled]="monitoredArticle.isArchived || isVisualisation" cdkDrag></app-monitored-article>
            </mat-card>
        </div>
        <div class="container">
            <div class="header-box">
                <div class="titleContainer">
                    <span class="veryBigTextBold">
                        Clôturé <span class="number-circle">{{globalCountClosed}}</span>
                    </span>
                </div>
                <div style="display: flex; width: 100%; margin: auto; justify-content: center; padding-bottom: 3px;">
                    <span class="badge1" [attr.data-badge]="nbPrio1Closed">Priorité A</span>
                    <span class="badge1" [attr.data-badge]="nbPrio2Closed">Priorité B</span>
                    <span class="badge1" [attr.data-badge]="nbPrio3Closed">Priorité C</span>
                </div>
            </div>
            <mat-card
                    id="closedBox"
                    cdkDropList
                    #closedList="cdkDropList"
                    [cdkDropListData]="closedArticles"
                    [cdkDropListConnectedTo]="[todoList, inProgressList, qualifiedList]"
                    (cdkDropListDropped)="drop($event)"
                    class="list">
                <p class="empty-label" *ngIf="closedArticles.length === 0">Aucun article</p>
                <app-monitored-article
                        (monitoringPriority)="editMonitoredArticlePriority('closedArticles', $event)"
                        (monitoringIsArchived)="editMonitoredArticleIsArchived('closedArticles', $event)"
                        *ngFor="let monitoredArticle of closedArticles"
                        [monitoredArticle]="monitoredArticle"
                        [showShareButton]="this.showShareButton"
                        [cdkDragDisabled]="monitoredArticle.isArchived || isVisualisation" cdkDrag></app-monitored-article>
            </mat-card>
        </div>
        <fe-loader *ngIf="loadingArticle" isOverflowing="true" loaderText="Chargement en cours" noBackground="true"/>
    </div>
</ion-content>
