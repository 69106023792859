import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith, switchMap} from 'rxjs/operators';
import {CitySuggestion} from '../../../interfaces/search/CitySuggestion';
import {SearchesService} from '../../../services/searches/searches.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-geoloc-modal',
  templateUrl: './geoloc-modal.component.html',
  styleUrls: ['./geoloc-modal.component.css']
})
export class GeolocModalComponent implements OnInit {

  public rayon = 10;
  public filteredCities;
  control = new FormControl('');

  public selectedCity: CitySuggestion;

  constructor(public searchesService: SearchesService,
              public dialogRef: MatDialogRef<GeolocModalComponent>) {
  }

  ngOnInit() {
    this.filteredCities = this.control.valueChanges.pipe(
        // startWith(''),
        // debounceTime(400),
        distinctUntilChanged(),
        switchMap(val => {
          return this.filter(val || '');
        })
    );
  }

  filter(val: string): Observable<any[]> {
    // call the service which makes the http-request
    return this.searchesService.autocompleteCities(val)
        .pipe(
            map(response => {
              return response;
            }));
  }

    validateGeoloc() {
      this.dialogRef.close({city: this.selectedCity, rayon: this.rayon});
    }

    closeDialog() {
        this.dialogRef.close();
    }

  changeSelection(city: CitySuggestion) {
        this.selectedCity = city;
    }
}
