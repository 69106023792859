import {Injectable} from '@angular/core';
import {Observable, Subscriber, throwError} from 'rxjs';
import {ApiService} from '../api/api.service';
import {HttpResponse} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {EntrepriseApiInterface} from '../../interfaces/entreprise-api/entreprise-api.interface';
import {CustomerDetailsInterface} from '../../interfaces/session/customer-details.interface';
import {EntrepriseCustomersInterface} from '../../interfaces/entreprise-customers/entreprise-customers.interface';
import {ErrorStatus} from '../../classes/ErrorStatus.class';
import {FEEntrepriseContactsInterface} from '../../interfaces/fe-entreprise-contacts/fe-entreprise-contact.interface';

@Injectable({
    providedIn: 'root'
})
export class EntreprisesService {

    constructor(
        private apiService: ApiService
    ) {
    }

    /**
     * Récupérer les informations d'une entreprise
     * @param entrepriseId number : id de l'entreprise
     * @param search SearchInterface : the updated search datas
     */
    public getEntreprise(entrepriseId: string): Observable<EntrepriseApiInterface> {
        return this.apiService.get<EntrepriseApiInterface>(`v1/entreprise/${entrepriseId}`).pipe(
            map((response: HttpResponse<EntrepriseApiInterface>) => {
                return response.body;
            })
        );
    }

    /**
     * Récupérer les informations d'une entreprise sur sa fiche
     * @param entrepriseId number : id de l'entreprise
     * @param search SearchInterface : the updated search datas
     */
    public getEntrepriseFiche(entrepriseId: string, token: string): Observable<EntrepriseApiInterface> {
        return this.apiService.getWithoutAuth<EntrepriseApiInterface>(`v1/entreprise/${entrepriseId}?token=${token}`).pipe(
            map((response: HttpResponse<EntrepriseApiInterface>) => {
                return response.body;
            })
        );
    }

    /**
     * Récupération des contacts enrichis d’une entreprise
     * @param entrepriseId number : L’identifiant d’une entreprise
     */
    public getEntrepriseAllContacts(entrepriseId: number): Observable<FEEntrepriseContactsInterface> {
        return this.apiService.get<FEEntrepriseContactsInterface>(`v1/entreprise/${entrepriseId}/contacts`).pipe(
            map((response: HttpResponse<FEEntrepriseContactsInterface>) => {
                return response.body;
            })
        );
    }

    /**
     * Récupération d’un contact enrichi d’une entreprise
     * @param entrepriseId number : L’identifiant d’une entreprise
     * @param contactId number : L’identifiant d’un contact
     */
    public getFullDataForAContact(entrepriseId: number, contactId: string): Observable<FEEntrepriseContactsInterface> {
        return this.apiService.get<FEEntrepriseContactsInterface>(`v1/entreprise/${entrepriseId}/contacts/${contactId}`).pipe(
            map((response: HttpResponse<FEEntrepriseContactsInterface>) => {
                return response.body;
            })
        );
    }

    /**
     * Récupère les clients d'une entreprise
     * @param entrepriseToken : token de l'entreprise
     */
    public getEntrepriseCustomers(entrepriseToken: string): Observable<EntrepriseCustomersInterface> {
        return this.apiService.get<EntrepriseCustomersInterface>(`v1/entreprise/customers/${entrepriseToken}`).pipe(
            map((response: HttpResponse<EntrepriseCustomersInterface>) => {
                return response.body;
            })
        );
    }

    /**
     * Requête API  Partage des recherches
     * @param query
     */
    public shareSearches(query: { [key: string]: any } = {}): Observable<HttpResponse<any>> {
        return this.apiService.post(`v1/share/searches`, query).pipe(
            tap((response: HttpResponse<any>) => {
                if (response.status === 200 || response.status === 201) {
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

}
