import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MonitoredArticleActivityInterface} from '../../interfaces/monitored-article-comments/monitored-article-activity.interface';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {ArticleMonitoringService} from '../../services/article-monitoring/article-monitoring.service';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from "../../services/snackbar/snackbar.service";

@Component({
    selector: 'app-monitoring-edit-comment-modal',
    templateUrl: './monitoring-edit-comment-modal.component.html',
    styleUrls: ['./monitoring-edit-comment-modal.component.scss'],
})
export class MonitoringEditCommentModalComponent implements OnInit {
    @Input() comment: MonitoredArticleActivityInterface | null = null;
    @Input() articleId;
    @Input() customer;
    // @Output() edit = new EventEmitter<MonitoredArticleActivityInterface>();
    @Output() confirm = new EventEmitter<any>();

    newComment = '';
    protected unsubscribe$: Subject<any> = new Subject<any>();

    config: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '9rem',
        minHeight: '5rem',
        placeholder: 'Entrer votre commentaire...',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        toolbarHiddenButtons: [
            ['fontName', 'subscript', 'superscript', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent'],
            ['textColor', 'backgroundColor', 'link', 'unlink', 'insertImage', 'insertVideo', 'removeFormat', 'insertHorizontalRule', 'toggleEditorMode']
        ]
    };

    constructor(
        private articleMonitoringService: ArticleMonitoringService,
        private snackbarService: SnackbarService,
        public dialogRef: MatDialogRef<MonitoringEditCommentModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.articleId = data.articleId;
        this.customer = data.customer;
        this.comment = data.comment;
    }

    ngOnInit() {
        if (this.comment != null) {
            this.newComment = this.comment.activity;
        }
    }

    public close(): void {
        this.confirm.emit({commentEdited: this.newComment !== this.comment.activity});
        this.dialogRef.close({commentEdited: this.newComment !== this.comment.activity});
    }

    public editComment(): void {
        if(this.newComment.length === 0 || this.newComment.trim() === '') {
            this.snackbarService.showDangerSnackbar('Opération impossible',
                'Vous devez saisir un commentaire.');
        }
        else {
            const monitoringCommentEditingData = {
                monitoringCommentId: this.comment.activityId,
                articleId: this.articleId,
                customer: this.customer,
                monitoringComment: this.newComment
            };
            this.articleMonitoringService.updateMonitoredArticleComment(monitoringCommentEditingData).subscribe(
                () => {
                    this.close();
                    this.snackbarService.showSuccessSnackbar('Edition d\'un commentaire', 'Commentaire édité avec succès');
                }
            );
        }
    }
}
