import { Injectable } from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {FeSnackbarComponent} from '../../components/fe-snackbar/fe-snackbar.component';
import {config} from "rxjs";
import {ComponentType} from '@angular/cdk/overlay';
import {MatDialogConfig} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackBar: MatSnackBar) { }

  showSnackbar(title: string, text: string, duration?: number, callbackFunction?: (args: any) => void, acceptBtnLabel?: string): void {
    this.snackBar.openFromComponent(FeSnackbarComponent,
        this.getConfig(title, text, 'DEFAULT', duration, callbackFunction, acceptBtnLabel));
  }

  showSuccessSnackbar(title: string, text: string, duration?: number, callbackFunction?: (args: any) => void, acceptBtnLabel?: string): void {
    this.snackBar.openFromComponent(FeSnackbarComponent,
        this.getConfig(title, text, 'SUCCESS', duration, callbackFunction, acceptBtnLabel));
  }

  showDangerSnackbar(title: string, text: string, duration?: number, callbackFunction?: (args: any) => void, acceptBtnLabel?: string): void {
    this.snackBar.openFromComponent(FeSnackbarComponent,
        this.getConfig(title, text, 'DANGER', duration, callbackFunction, acceptBtnLabel));
  }

  private getConfig(title: string, text: string, type: string, duration: number,
                    callbackFunction?: (args: any) => void, acceptBtnLabel?: string): MatSnackBarConfig {
    const config: MatSnackBarConfig = {
      horizontalPosition: 'right',
      data: {
        title: title,
        text: text,
      },
    };

    if (duration !== 0) {
      if (duration === undefined) {
        config.duration = 15000;
        config.data.duration = 15;
      } else {
        config.duration = duration * 1000;
        config.data.duration = duration;
      }
    }

    if (callbackFunction != null) {
      config.data.actionToDo = callbackFunction;
    }

    if (acceptBtnLabel != null) {
      config.data.acceptBtnLabel = acceptBtnLabel;
    }

    switch (type) {
      case 'DANGER':
        config.panelClass = 'danger-snackbar';
        config.data.color = 'DANGER';
        break;
      case 'SUCCESS':
        config.panelClass = 'confirm-snackbar';
        config.data.color = 'SUCCESS';
        break;
      default:
        config.panelClass = 'default-snackbar';
        config.data.color = 'DEFAULT';
        break;
    }

    return config;
  }
}
