import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { ArticlesService } from 'src/app/services/articles/articles.service';
import { takeUntil } from 'rxjs/operators';
import {ArticlesInterface} from '../../../interfaces/articles/articles.interface';

@Component({
  selector: 'article-read-unread-button',
  templateUrl: 'article-read-unread-button.component.html',
  styleUrls: ['article-read-unread-button.component.scss'],
})
export class ArticleReadUnreadButtonComponent implements OnInit, OnDestroy {

  @Input() public selectionModel: SelectionModel<string>;
  @Input() public articles: ArticlesInterface;
  @Input() public isFromActionList: boolean;
  articleRead: boolean;

  private unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private articlesService: ArticlesService
  ) {}

  public ngOnInit(): void {}

  public markAsRead(selectionModel: any) {
    // let read = false;
    // for (const a of this.articles.articles_) {
    //   if (selectionModel.includes(a.id) && !a.read) {
    //     read = true;
    //     break;
    //   }
    // }

    this.articlesService.markArticlesAsRead(selectionModel, true).pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        res => {
          // If online is 200, update the local data by sending article ID
          for (const articleId of selectionModel) {
            this.articlesService.updateReadArticle(articleId, true);
          }
        }
      );

    // for (const articleId of selectionModel) {
    //
    //   this.articlesService.markArticleAsRead(articleId)
    //     .pipe(
    //       takeUntil(this.unsubscribe$)
    //     )
    //     .subscribe(
    //       res => {
    //         // If online is 200, update the local data by sending article ID
    //         this.articlesService.updateReadArticle(articleId);
    //       }
    //     );
    // }
  }

  public markAsUnread(selectionModel: any) {
    // let read = false;
    // for (const a of this.articles.articles_) {
    //   if (selectionModel.includes(a.id) && !a.read) {
    //     read = true;
    //     break;
    //   }
    // }

    this.articlesService.markArticlesAsRead(selectionModel, false).pipe(
        takeUntil(this.unsubscribe$)
    )
        .subscribe(
            res => {
              // If online is 200, update the local data by sending article ID
              for (const articleId of selectionModel) {
                this.articlesService.updateReadArticle(articleId, false);
              }
            }
        );
  }
  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
