export const environment = {
  production: true,

  debug: false,

  oauth: {
    // tokenEndpoint: 'http://localhost:8585/authentification/token',
    tokenEndpoint: 'https://secure.firsteco.fr/authentification/token',
    clientId: 'dev-sfc',
    scope: 'SFC',
    dummyClientSecret: 'actualis',
    sessionChecksEnabled: true
  },

  api: {
    // url: 'http://localhost:8181/',
    // secure: 'http://localhost:8585/',
    url: 'https://api.firsteco.fr/',
    secure: 'https://secure.firsteco.fr/',
    username: 'bypass_auth',
    password: 'KQSgqzVOCW'
  },

  google: {
    mapsApiKey: 'AIzaSyAQ7wO-H-EGff6QjUawS9aHPHVdgbAX1zc'
  },

  cryptoJsSecretKey: '704B0067-7272-494D-8D83-F76D8C155788'
};
