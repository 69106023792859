<ion-header no-border>
  <ion-toolbar>
    <ion-buttons>
      <img class="batimentIcon" style="width: 40px; margin-left: 5px;" src="./assets/icon/entreprise_noir.png">
      <div class="ficEntHeaderBtnBar" style="flex-grow: 1; display: flex;">
        <span style="margin: auto 5px auto 10px; font-size: 20px; color: #254354; word-break: break-word; hyphens: auto;"
              *ngIf="entreprise">{{entreprise.name}}</span>
        <button class="fieModalHeadImgContainerWebsite" *ngIf="entreprise.website"
                (click)="openUrl(entreprise.website)">
          <img class="batimentIcon" matTooltip="{{entreprise.website}}" src="./assets/icon/globe_noir_large.png">
        </button>
        <button class="fieModalHeadImgContainerLinkedin" *ngIf="entreprise.linkedin"
                (click)="openUrl(entreprise.linkedin , 'RS')">
          <img class="batimentIcon" matTooltip="{{entreprise.linkedin}}" src="./assets/icon/linkedin.png">
        </button>
        <button class="fieModalHeadImgContainerTwitter" *ngIf="entreprise.twitter"
                (click)="openUrl(entreprise.twitter, 'RS')">
          <img class="batimentIcon" matTooltip="{{entreprise.twitter}}" src="./assets/icon/twitter.png">
        </button>
        <button class="fieModalHeadImgContainerTwitter"
                *ngIf="webToLead && webToLeadCompanies.includes(companyId) "
                (click)="exportEnterpriseWebToLead()">
          <mat-icon fontSet="material-icons-outlined"
                    style="margin-left: 0px; width: 28px; height:28px;font-size: 28px;"
                    class="greyColor curPoint contact-details-icon">cloud_upload
          </mat-icon>
        </button>

      </div>

      <span *ngIf="entreprise.lastUpdate" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}"
            class="majLabel" >Mise à jour le {{entreprise.lastUpdate}}</span>
      <ion-button style="height: 40px; --border-radius: 20px; width: 40px; margin-right: 10px;" mat-icon-button aria-label="close" slot="end" (click)="close()">
        <mat-icon style="font-size: 35px; width: 35px; height: 35px">close</mat-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="ficheEBody">
<!--    <div style="display: flex; width: 100%;">-->
<!--      <div style="flex-grow: 1;">-->
<!--        <span *ngIf="entreprise.businessName" style="flex-grow: 1;">-->
<!--          {{entreprise.businessName}}-->
<!--        </span>-->
<!--        <div *ngIf="entreprise.ape">-->
<!--          <span>Code APE - {{entreprise.ape}}</span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->



    <ion-row>
      <ion-col class="responsiveFiEGrid">
        <ion-row>
          <ion-card class="feCard">
            <ion-card-header class="feCardHeader">
              <ion-card-title>Identité juridique</ion-card-title>
            </ion-card-header>

            <ion-card-content class="feCardContent">
              <ion-row>
                <ion-col class="col100InMobile" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
                  <ion-row *ngIf="entreprise">
                    <span *ngIf="entreprise.siren"><span style="font-weight: 500;">SIREN : </span>{{entreprise.siren}}</span>
                    <span *ngIf="!entreprise.siren"><span style="font-weight: 500;">SIREN : </span>NC</span>
                  </ion-row>
                  <ion-row *ngIf="entreprise" style="padding-top: 10px;">
                    <span *ngIf="entreprise.siret"><span style="font-weight: 500;">SIRET : </span>{{entreprise.siret}}</span>
                    <span *ngIf="!entreprise.siret"><span style="font-weight: 500;">SIRET : </span>NC</span>
                  </ion-row>
                </ion-col>

                <ion-col class="col100InMobile" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
                  <ion-row *ngIf="entreprise">
                    <span *ngIf="entreprise.legalStatus; else noLegalState">
                      <span style="font-weight: 500;">Statut juridique : </span>{{entreprise.legalStatus}}</span>
                    <ng-template #noLegalState>
                      <span><span style="font-weight: 500;">Statut juridique : </span>NC</span>
                    </ng-template>
                  </ion-row>
                  <ion-row *ngIf="entreprise" style="padding-top: 10px;">
                    <span *ngIf="entreprise.vatNumber"><span style="font-weight: 500;">N° TVA UE : </span>{{entreprise.vatNumber}}</span>
                    <!--<span *ngIf="!entreprise.vatNumber"><span style="font-weight: 500;">N° TVA UE : </span>NC</span>-->
                  </ion-row>
                </ion-col>
              </ion-row>

              <ion-row>
                <ion-col class="col100InMobile" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
                  <span *ngIf="entreprise.dateCreation; else noCreationDate">
                    <span style="font-weight: 500;">Date de création : </span>{{entreprise.dateCreation}}</span>
                  <ng-template #noCreationDate>
                    <span style="font-weight: 500;">Date de création : </span><span>NC</span>
                  </ng-template>
                </ion-col>
                <ion-col class="col100InMobile" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
                  <span *ngIf="entreprise.capital; else noCapital">
                    <span style="font-weight: 500;">Capital social : </span>{{entreprise.capital}}</span>
                  <ng-template #noCapital>
                    <span style="font-weight: 500;">Capital social : </span><span>NC</span>
                  </ng-template>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col class="col100InMobile" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
                  <span *ngIf="entreprise.ape; else noAPE">
                    <span style="font-weight: 500;">Code APE : </span>{{entreprise.ape}} - {{entreprise.ape_label}}</span>
                  <ng-template #noAPE>
                    <span style="font-weight: 500;">Code APE : </span><span>NC</span>
                  </ng-template>
                </ion-col>
              </ion-row>
            </ion-card-content>
          </ion-card>
        </ion-row>

        <ion-row *ngIf="entreprise.website">
          <span style="text-align: center; width: 100%;">
            Site web : <a href="{{entreprise.website}}" (click)="logWebsiteClick()" target="_blank"
                          style="color: #c31619;">{{entreprise.website}}</a>
          </span>
        </ion-row>

        <ion-item-divider *ngIf="entreprise.website && entreprise.cp && entreprise.ville" class="feDivider"></ion-item-divider>

        <ion-row *ngIf="entreprise.cp && entreprise.ville">
          <div class="ficheEMapBlocChildren">
            <ion-row>{{entreprise.adresse}}</ion-row>
            <ion-row>{{entreprise.cp}} {{entreprise.ville}}</ion-row>
            <ion-row>{{entreprise.pays}}</ion-row>
          </div>
          <div class="ficheEMapBlocChildren">
<!--            <agm-map-->
<!--                    #agmMap-->
<!--                    *ngIf="loadMap && entreprise.latitude != null && entreprise.longitude != null"-->
<!--                    style="height: 150px;width: 100%;"-->
<!--                    [latitude]="entreprise.latitude"-->
<!--                    [longitude]="entreprise.longitude"-->
<!--                    [zoom]="9">-->
<!--              <agm-marker [latitude]="entreprise.latitude" [longitude]="entreprise.longitude" [iconUrl]="markerIcon">-->
<!--              </agm-marker>-->
<!--            </agm-map>-->
          </div>
        </ion-row>

      </ion-col>

      <ion-col class="responsiveFiEGrid">
        <ion-card class="feCard" *ngIf="displayingContactBloc()">
          <ion-card-header class="feCardHeader">
            <ion-card-title style="display: flex;">
              <span style="margin: auto 0;">Contacts</span>
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-row>
              <button class="showAllContactsBtn" (click)="openAllContactsModal()">Afficher tous les contacts</button>
            </ion-row>
            <ion-row *ngIf="entreprise.mail || entreprise.telephone" style="margin: 7px auto; width: fit-content;">
              <div *ngIf="entreprise.telephone?.length > 0" style="display: flex;">
                <mat-icon style="font-size: 21px; height: 21px;" >phone</mat-icon>
                <a style="color: #c31619; text-decoration-line: none; margin-left: 3px;" href="tel:{{entreprise.telephone}}">{{entreprise.telephone}}</a>
              </div>

              <div *ngIf="entreprise.mail && entreprise.telephone" style="display: flex;">
                <span style="margin: 0 5px"> - </span>
              </div>

              <div *ngIf="entreprise.mail" style="display: flex;">
                <mat-icon style="font-size: 21px; height: 21px;">alternate_email</mat-icon>
                <a style="color: #c31619; text-decoration-line: none; margin-left: 3px;" (click)="logMailClick()"
                   href="mailto:{{entreprise.mail}}">{{entreprise.mail}}</a>
              </div>
            </ion-row>

            <ion-card style="margin: 5px -5px 0; box-shadow: none;" *ngIf="(articleContacts && articleContacts.length > 0)">
              <ion-card-header style="border-bottom: solid #b3b3b3 1px; padding: 2px 6px;">
                <ion-card-title style="color: #124457;">
                  <ion-icon style="vertical-align: middle; color: #c31619;" name="chevron-down-outline"></ion-icon>
                  <span style="font-size: 16px;"> Contacts clés</span>
                </ion-card-title>
              </ion-card-header>
              <ion-card-content style="padding: 6px 10px 0;">
                <ion-row style="width: 100%; margin-bottom: 6px; line-height: 16px; font-weight: bold;display: flex;flex-direction: row;" *ngFor="let contact of articleContacts">
                  <div>
                    <div *ngIf="contact.name || contact.function" style="display: block; width: 100%; height: auto;flex: 1;">
                      <span style="color: #124457;">- {{contact.name}}</span>
                      <span style="color: #124457;">, {{contact.function}}</span>
                    </div>
                    <div style="display: block; width: 100%; margin-left: 8px;">
                      <span *ngIf="!contact.name && !contact.function && (contact.mail || contact.phone)"
                            style="color: #124457; margin-left: -8px;">- Standard - </span>
                      <a *ngIf="contact.phone" style="color: #124457; text-decoration-line: none;"
                         href="tel:{{contact.phone}}">{{contact.phone}}</a>
                      <span style="color: #124457;" *ngIf="contact.mail && contact.phone"> - </span>
                      <a *ngIf="contact.mail" style="color: #124457; text-decoration-line: none;"
                         href="mailto:{{contact.mail}}">{{contact.mail}}</a>
                    </div>
                  </div>
                  <div style="display: block; width: 20px; margin-left: 8px;flex: 1;"
                  >
                    <button *ngIf="contact.linkedin"
                            class="snButton"
                            (click)="openUrl(contact.linkedin, 'RS')">
                      <img style="width: 20px; height: 20px;" src="./assets/icon/linkedin2.png">
                    </button>
                    <button style="margin-left: 5px;" *ngIf="contact.twitter" class="snButton"
                            (click)="openUrl(contact.twitter, 'RS')">
                      <img style="width: 20px; height: 16px; margin-bottom: 2px;" src="./assets/icon/twitter-bird.png">
                    </button>
                    <button style="margin-left: 5px;" *ngIf="contact.facebook" class="snButton"
                            (click)="openUrl(contact.facebook, 'RS')">
                      <img style="width: 20px; height: 20px;" src="./assets/icon/facebook-icon.png">
                    </button>
                    <span *ngIf="webToLead && webToLeadCompanies.includes(companyId)">
                    <mat-icon *ngIf="webToLead" fontSet="material-icons-outlined"
                              style="margin-left: 5px; width: 20px; height:20px;font-size: 20px;"
                              class="greyColor curPoint contact-details-icon"
                              (click)="exportArticleWebToLead(contact, $event)">cloud_upload
                    </mat-icon>
                    </span>
                  </div>
                </ion-row>
              </ion-card-content>
            </ion-card>

            <ion-card style="margin: 0 -5px; box-shadow: none;" *ngIf="(entreprise.contacts && entreprise.contacts.length > 0)">
              <ion-card-header style="border-bottom: solid #b3b3b3 1px; padding: 2px 6px;">
                <ion-card-title style="color: #124457;">
                  <ion-icon style="vertical-align: middle; color: #c31619;" name="chevron-down-outline"></ion-icon>
                  <span style="font-size: 16px;"> Contacts clés issus d'articles précédents</span>
                </ion-card-title>
              </ion-card-header>
              <ion-card-content style="padding: 6px 10px 0;">
                <ion-row style="width: 100%; margin-bottom: 6px; line-height: 16px;" *ngFor="let contact of entreprise.contacts | slice: 0: contactIncrementor;">
                  <div style="display: block; width: 100%; height: auto;" *ngIf="contact.name || contact.function">
                    <span style="color: #124457;" *ngIf="contact.name">- {{contact.name}}</span>
                    <span style="color: #124457;" *ngIf="contact.function">, {{contact.function}}</span>
                  </div>

                  <div style="display: block; width: 100%; height: auto;" *ngIf="!contact.name && !contact.function
                  && (contact.mail || contact.phone)">
                    <span style="color: #124457;">- Standard</span>
                  </div>

                  <div style="display: block; width: 100%; margin-left: 8px;" *ngIf="contact.phone || contact.mail">
                    <a *ngIf="contact.phone" style="color: #124457; text-decoration-line: none;"
                       href="tel:{{contact.phone}}">{{contact.phone}}</a>
                    <span style="color: #124457;" *ngIf="contact.mail && contact.phone"> - </span>
                    <a *ngIf="contact.mail" style="color: #124457; text-decoration-line: none;"
                       href="mailto:{{contact.mail}}">{{contact.mail}}</a>
                  </div>
                </ion-row>

                <ion-row>
                  <button *ngIf="entreprise.contacts.length > contactIncrementor" (click)="upContactsIncrementor()"
                              class="seeMoreContactBtn">Voir plus</button>
                </ion-row>
              </ion-card-content>
            </ion-card>

            <ion-card style="margin: 0 -5px; box-shadow: none;" *ngIf="entreprise.legal_officiers && entreprise.legal_officiers.length > 0">
              <ion-card-header style="border-bottom: solid #b3b3b3 1px; padding: 2px 6px;">
                <ion-card-title style="color: #124457;">
                  <ion-icon style="vertical-align: middle; color: #c31619;" name="chevron-down-outline"></ion-icon>
                  <span style="font-size: 16px;"> Mandataires légaux</span>
                </ion-card-title>
              </ion-card-header>
              <ion-card-content style="padding: 6px 10px 0;">
                <ion-row style="width: 100%;" *ngFor="let legalOfficier of entreprise.legal_officiers | slice: 0: mandataireIncrementor;">
                  <span style="color: #124457;">- {{legalOfficier.name}}</span>
                  <span style="color: #124457;">, {{legalOfficier.role}}</span>
                  <span *ngIf="legalOfficier.role_start_date" style="color: #124457; margin-left: 4px;">
                    depuis le {{legalOfficier.role_start_date}}
                  </span>
                </ion-row>

                <ion-row>
                  <button *ngIf="entreprise.legal_officiers.length > mandataireIncrementor" (click)="upMandatairesIncrementor()"
                          class="seeMoreContactBtn">Voir plus</button>
                </ion-row>
              </ion-card-content>
            </ion-card>

          </ion-card-content>
        </ion-card>

        <ion-row style="margin-top: 5px;">
          <ion-col class="col100InMobile keyNumberTitle" size-xs="4" style="font-size: 20px"
                   [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">Chiffres clés</ion-col>
          <ion-col *ngIf="entreprise.closing_date && entreprise.closing_date.length > 0; else noClosingDate"
                   size-xs="8" style="font-size: 12px; margin: auto; text-align: right;"
                   [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}" class="col100InMobile">
            Date de clôture des comptes : {{entreprise.closing_date}}
          </ion-col>
          <ng-template #noClosingDate>
            <ion-col size-xs="8" style="font-size: 12px; margin: auto; text-align: right;">Date de clôture des comptes : NC</ion-col>
          </ng-template>

        </ion-row>

        <ion-row>

          <ion-col class="col100InMobile keyNumberContainer" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
            <ion-row style="margin-bottom: 5px;">
              <span style="margin: auto; color: white; font-weight: 400; font-size: 20px;"
                    *ngIf="entreprise.effectif; else noEffectif" >{{entreprise.effectif}}</span>
              <ng-template #noEffectif>
                <span style="margin: auto; color: white; font-weight: 400; font-size: 20px;">NC</span>
              </ng-template>
            </ion-row>
            <ion-row style="text-align: center;">
              <span style="margin: auto; background: white; width: 100%;">Effectif {{entreprise.effectifAnnee ? '- ' + entreprise.effectifAnnee : ''}}</span>
            </ion-row>
          </ion-col>

          <ion-col class="col100InMobile keyNumberContainer" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
            <ion-row style="margin-bottom: 5px;">
              <span style="margin: auto; color: white; font-weight: 400; font-size: 20px;"
                    *ngIf="entreprise.chiffreAffaire; else noCA" >{{entreprise.chiffreAffaire}}</span>
              <ng-template #noCA>
                <span style="margin: auto; color: white; font-weight: 400; font-size: 20px;">NC</span>
              </ng-template>
            </ion-row>
            <ion-row style="text-align: center;">
              <span style="margin: auto; background: white; width: 100%;">Chiffre d'affaires {{entreprise.chiffreAffaireAnnee ? '- ' + entreprise.chiffreAffaireAnnee : ''}}</span>
            </ion-row>
          </ion-col>

          <ion-col class="col100InMobile keyNumberContainer" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
            <ion-row style="margin-bottom: 5px;">
              <span style="margin: auto; color: white; font-weight: 400; font-size: 20px;"
                    *ngIf="entreprise.resultatNet; else noRN" >{{entreprise.resultatNet}}</span>
              <ng-template #noRN>
                <span style="margin: auto; color: white; font-weight: 400; font-size: 20px;">NC</span>
              </ng-template>
            </ion-row>
            <ion-row style="text-align: center;">
              <span style="margin: auto; background: white; width: 100%;">Résultat net {{entreprise.resultatNetAnnee ? '- ' + entreprise.resultatNetAnnee : ''}}</span>
            </ion-row>
          </ion-col>

        </ion-row>
        <ion-row>
          <button class="showAllContactsBtn" (click)="openStatDetails()">Voir plus de détails</button>
        </ion-row>
      </ion-col>

    </ion-row>

    <ion-item-divider class="feDivider" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}"></ion-item-divider>

    <ion-row *ngIf="entrepriseArticles" style="padding: 0 5px;">
      <ion-card class="feCard">
        <ion-card-header class="feCardHeader" style="padding: 5px;">
          <ion-card-title style="display: flex;">
            <img class="batimentIcon" style="width: 33px; height: 33px; margin: 3px 0;" src="./assets/icon/firsteco_logo_sb.png">
            <span style="margin: auto 0 auto 5px;">Signaux business</span>
          </ion-card-title>
        </ion-card-header>

        <ion-card-content style="padding: 0;">
          <ion-list style="padding: 0;" lines="none">
            <ion-item color="#fff" text-wrap [class.articleBlocked]="!entrepriseArticle.enable" [button]="entrepriseArticle.enable" *ngFor="let entrepriseArticle of entrepriseArticles | slice: 0: articleIncrementor;"
            (click)="openReadingModal(entrepriseArticle.articleId, entrepriseArticle.enable)" matTooltipClass="txtCenter" class="entrepriseArticleLine"
                      style="margin-left: -16px; border-bottom: 1px solid #dedede;"
            [matTooltipDisabled]="entrepriseArticle.enable"  [matTooltip]="prospect ? 'L\'historique des signaux business est limité à 3 mois en période de découverte.' : !moreThanThreeMonths(entrepriseArticle) ? 'L\'historique des signaux business est limité à 3 mois en période de découverte.' : 'L\'historique des signaux business est limité à 3 ans.'">
              <ion-label style="padding-left: 16px; margin-top: 5px; margin-bottom: 5px;">
                <p style="margin-bottom: 0; color: #585858;">{{entrepriseArticle.date | date: 'dd/MM/yyyy'}}</p>
                <h3 class="entrepriseArticleTitle">{{entrepriseArticle.title}}</h3>
              </ion-label>
            </ion-item>
          </ion-list>
          <ion-row>
            <ion-button *ngIf="entrepriseArticles.length > articleIncrementor" (click)="upArticlesIncrementor()"
                        class="seeMoreBtn">Voir plus</ion-button>
          </ion-row>
        </ion-card-content>
      </ion-card>
    </ion-row>

    <ion-item-divider *ngIf="entreprise.etablissements" class="feDivider"></ion-item-divider>

    <ion-row *ngIf="entreprise.etablissements">
      <ion-card class="feCard">
        <ion-card-header class="feCardHeader">
          <ion-card-title>
            <span [ngPlural]="entreprise.etablissements.length">
              <ng-template ngPluralCase="=1">Etablissement</ng-template>
              <ng-template ngPluralCase="other">Etablissements</ng-template>
            </span>
            <span *ngIf="articleEtablissements; else noArticleEtablissements">
              <span [ngPlural]="entreprise.etablissements.length + articleEtablissements.length">
                 ({{entreprise.etablissements.length + articleEtablissements.length}}
                <ng-template ngPluralCase="=1"> établissement actif,</ng-template>
                <ng-template ngPluralCase="other"> établissements actifs,</ng-template>
              </span>
              <span [ngPlural]="articleEtablissements.length">
                {{articleEtablissements.length}}
                <ng-template ngPluralCase="=1"> lié à l'article)</ng-template>
                <ng-template ngPluralCase="other"> liés à l'article)</ng-template>
              </span>
            </span>
            <ng-template #noArticleEtablissements>
              <span [ngPlural]="entreprise.etablissements.length">
                ({{entreprise.etablissements.length}}
                <ng-template ngPluralCase="=1">établissement actif)</ng-template>
                <ng-template ngPluralCase="other">établissements actifs)</ng-template>
              </span>
            </ng-template>
          </ion-card-title>
        </ion-card-header>

        <ion-card-content style="padding: 0; overflow: auto; max-height: 350px;">
          <ion-item style="margin-left: -16px;">
            <ion-row class="etabFieldTitleContainer" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
              <div class="etabFieldTitle" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">NOM</div>
              <div class="etabFieldTitle" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">ADRESSE</div>
              <div class="etabFieldTitleLastPart" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
                <div class="etabFieldTitleSiret" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">SIRET</div>
                <div class="etabFieldTitleApe" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">APE</div>
              </div>
            </ion-row>
          </ion-item>
          <ion-list style="padding: 0;">
            <div *ngIf="articleEtablissements; else otherEtablissements">
              <ion-item style="margin-left: -16px;" *ngFor="let etablissement of articleEtablissements">
                <etablissement-modal-line style="width: 100%;" [etablissement]="etablissement"></etablissement-modal-line>
              </ion-item>
              <ion-item style="margin-left: -16px;" *ngFor="let etablissement of entreprise.etablissements | slice:0:etablissementIncrementor">
                <etablissement-modal-line style="width: 100%;" [etablissement]="etablissement"></etablissement-modal-line>
              </ion-item>
            </div>
            <ng-template #otherEtablissements>
              <ion-item style="margin-left: -16px;" *ngFor="let etablissement of entreprise.etablissements | slice:0:etablissementIncrementor">
                  <etablissement-modal-line style="width: 100%;" [etablissement]="etablissement"></etablissement-modal-line>
              </ion-item>
            </ng-template>
          </ion-list>

          <ion-row>
            <ion-button *ngIf="etablissementIncrementor == 0; else noArticleEtablissementsButton"
                        class="seeMoreBtn" (click)="upEtablissementsIncrementor()">
              Voir les autres établissements
            </ion-button>
            <ng-template #noArticleEtablissementsButton>
              <ion-button *ngIf="entreprise.etablissements.length > etablissementIncrementor" class="seeMoreBtn"
              (click)="upEtablissementsIncrementor()">
                Voir plus
              </ion-button>
            </ng-template>
          </ion-row>
        </ion-card-content>
      </ion-card>
    </ion-row>
  </div>
</ion-content>
