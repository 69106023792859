import {Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {SearchesService} from '../../../services/searches/searches.service';
import {CriterionsTypesInterface} from '../../../interfaces/criterions/criterions-types.interface';
import {CriterionInterface} from '../../../interfaces/criterions/criterion.interface';
import {MatListItem} from '@angular/material/list';
import {CriterionItemPanelComponent} from './criterion-item-panel/criterion-item-panel.component';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipEditedEvent, MatChipInputEvent} from '@angular/material/chips';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {SearchSummaryComponent} from '../../modals/search/search-summary/search-summary.component';
import {ModalController} from '@ionic/angular';
import {GeolocModalComponent} from '../../modals/geoloc-modal/geoloc-modal.component';
import {SearchCreationComponent} from '../search-creation/search-creation.component';
import {MatDialog} from '@angular/material/dialog';
import {FeSearchbarComponent} from '../../parts/fe-searchbar/fe-searchbar.component';
import {UserService} from '../../../services/user/user.service';
import {takeUntil} from 'rxjs/operators';
import {SessionInterface} from '../../../interfaces/session/session.interface';
import {IconType} from "../../parts/fe-icon/fe-icon.component";

@Component({
  selector: 'app-search-list-criterions',
  templateUrl: './search-list-criterions.component.html',
  styleUrls: ['./search-list-criterions.component.scss']
})
export class SearchListCriterionsComponent implements OnInit {

  public currentTypeCriterion: string;
  public criterionsTypes: CriterionsTypesInterface;
  public currentCriterions: Array<CriterionInterface> = [];
  public currentFilter: string = '';
  public keywords = {must: [], should: [], mustNot: [], all: []};
  public geoloc: {cityFrom?: number, geolocDistanceRadius?: number, geolocFrom?: string, cityName?: string};

  @Output() emitter = new EventEmitter();
  @ViewChildren(CriterionItemPanelComponent) criterionItemPanels: QueryList<CriterionItemPanelComponent>;
  @ViewChild(FeSearchbarComponent) searchBar: FeSearchbarComponent;
  @Output() launchTour = new EventEmitter();

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  public geolocSearchAvailable: boolean;
  public leadsAvailable: boolean;

  constructor(public searchesService: SearchesService
      , private modalController: ModalController
      , public dialog: MatDialog
      , public userService: UserService) {
  }

  public ngOnInit(): void {
    // this.searchesService.getCriterions().pipe().subscribe((criterionsTypes: CriterionsTypesInterface) => {
    //   this.criterionsTypes = criterionsTypes;
    // });

    this.userService.getSessionDatas().pipe().subscribe(
            (sessionData: SessionInterface) => {
              if (sessionData !== undefined) {
                sessionData.appConfig.viewConfigs.forEach(value => {
                  if (value.component === "GEOLOCATION_SEARCH") {
                    if (value.state === "visible") {
                      this.geolocSearchAvailable = true;
                    }
                  }
                  if (value.component === 'LEADS_TOPICS_CHOICE') {
                    if (value.state === 'visible') {
                      this.leadsAvailable = true;
                    }
                  }
                });
              }
            }
        );
  }

  filterCriterions(value: any) {
    this.currentFilter = value;
    this.searchBar.value = this.currentFilter;
    this.criterionItemPanels.forEach(itemPanel => {
      itemPanel.filter = this.currentFilter;
    });
  }

  selectDeselectAll($event: MatCheckboxChange) {
    for (let criterion of this.currentCriterions) {
      if (!(criterion.subType === 'temporal') && !(criterion.subType === 'predictive')) {
        criterion.checked_ = $event.checked;
        this.selectDeselectAllChildren(criterion.childCriterions, $event.checked);
      }
    }
    this.emitter.emit();
  }

  selectDeselectAllChildren(criterions: Array<CriterionInterface>, check: boolean) {
    for (let criterion of criterions) {
      if (!(criterion.subType === 'temporal') && !(criterion.subType === 'predictive')) {
        criterion.checked_ = check;
        this.selectDeselectAllChildren(criterion.childCriterions, check);
      }
    }
  }

  allChecked() {
    let allSelected = true;
    this.criterionItemPanels.forEach(itemPanel => {
      if (!itemPanel._criterion.checked_) {
        allSelected = false;
        return;
      }
    });
    return allSelected;
  }

  loadCriterions(typeCriterion: any) {
    this.currentFilter = '';
    this.currentTypeCriterion = typeCriterion;
    try {
      this.currentCriterions = this.criterionsTypes[typeCriterion].values;
    } catch (e) {
    }
  }

  getCriterionTypeTitle() {
    switch (this.currentTypeCriterion) {
      case 'sectors':
        return 'Secteurs d\'activité';
      case 'topics':
        return 'Evénements économiques';
      case 'locations':
        return 'Localisation';
      case 'companySizes':
        return 'Taille d\'entreprise';
      case 'activityTypes':
        return 'Nature des locaux';
      case 'keywords':
        return 'Mots-clés';
    }

    return '';
  }

  getCriterionTypeSubTitle() {
    switch (this.currentTypeCriterion) {
      case 'sectors':
        return 'Sélection des secteurs d\'activités que vous ciblez';
      case 'topics':
        return 'Événement économique relaté dans l\'article';
      case 'locations':
        return 'Sélection des régions, départements ou pays limitrophes que vous ciblez';
      case 'companySizes':
        return 'Sélection des tailles d\'entreprises que vous ciblez';
      case 'activityTypes':
        return 'Nature des locaux de l\'entité concernée';
      case 'keywords':
        return 'Rechercher par mots clés ou expressions';
    }

    return '';
  }

  getCriterions() {
    return this.criterionsTypes;
  }

  getKeywords() {
    this.keywords.all = [...this.keywords.must, ...this.keywords.should];
    return this.keywords;
  }

  initCriterions(criterionsTypes: CriterionsTypesInterface) {
    this.criterionsTypes = criterionsTypes;
  }

  changeCriterionSelection() {
    this.emitter.emit();
  }

  isCriterionType() {
    return this.currentTypeCriterion === 'sectors' || this.currentTypeCriterion === 'topics' || this.currentTypeCriterion === 'locations' || this.currentTypeCriterion === 'companySizes' || this.currentTypeCriterion === 'activityTypes';
  }

  add(event: MatChipInputEvent, type: string): void {
    const value = (event.value || '').trim();

    if (value && type === 'must') {
      this.keywords.must.push(value);
    }
    else if (value && type === 'should') {
      this.keywords.should.push(value);
    }
    else if (value && type === 'mustNot') {
      this.keywords.mustNot.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
    this.emitter.emit();
  }

  remove(value: string, type: string): void {
    if (value && type === 'must') {
      const index = this.keywords.must.indexOf(value);
      if (index >= 0) {
        this.keywords.must.splice(index, 1);
      }
    }
    else if (value && type === 'should') {
      const index = this.keywords.should.indexOf(value);
      if (index >= 0) {
        this.keywords.should.splice(index, 1);
      }
    }
    else if (value && type === 'mustNot') {
      const index = this.keywords.mustNot.indexOf(value);
      if (index >= 0) {
        this.keywords.mustNot.splice(index, 1);
      }
    }
    this.emitter.emit();
  }

  edit(keyword: string, event: MatChipEditedEvent, type: string) {
    const value = event.value.trim();

    if (!value) {
      this.remove(keyword, type);
      return;
    }

    if (type === 'must') {
      const index = this.keywords.must.indexOf(keyword);
      if (index >= 0) {
        this.keywords.must[index] = value;
      }
    }
    else if (type === 'should') {
      const index = this.keywords.should.indexOf(keyword);
      if (index >= 0) {
        this.keywords.should[index] = value;
      }
    }
    else if (type === 'mustNot') {
      const index = this.keywords.mustNot.indexOf(keyword);
      if (index >= 0) {
        this.keywords.mustNot[index] = value;
      }
    }
    this.emitter.emit();
  }

  initKeywordToEdit(param: { mustNot: any; should: any; must: any; all: any; }) {
    this.keywords = param;
  }

  async openGeoloc() {
    const dial = this.dialog.open(GeolocModalComponent, {
      height: '310px',
      width: '600px',
      data: {geoloc: this.geoloc}
    });
    dial.afterClosed().subscribe(value => {
      if (value) {
        this.geoloc =  {
          cityFrom: value.city.id,
          geolocFrom: '',
          geolocDistanceRadius: value.rayon,
          cityName: value.city.name + ' (' + value.city.zip + ')'
        };
        this.emitter.emit();
      }
    });
  }

  initGeoloc(geoloc: any) {
    this.geoloc = geoloc;
  }

  removeGeoloc() {
    this.geoloc =  null;
    this.emitter.emit();
  }

  protected launchTemporalTour(): void {
    let tourToEmmit = 'topic_temporal_or_leads';
    if (!this.leadsAvailable) {
      tourToEmmit = 'discover_topic_temporal_or_leads';
    }
    this.launchTour.emit(tourToEmmit);
  }

  protected readonly IconType = IconType;

  protected getGuideLeadBtnText(): string {
    if (this.leadsAvailable) {
      return 'Guide sur l\'utilisation des temporalités et des leads prédictifs';
    } else {
      return 'Découvrir les temporalités et les leads prédictifs';
    }
  }
}
