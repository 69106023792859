import {AfterViewInit, Component, ElementRef, Inject, Input, LOCALE_ID, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {catchError, filter, map, takeUntil} from 'rxjs/operators';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {ArticlesInterface} from 'src/app/interfaces/articles/articles.interface';
import {MediaQueryService} from 'src/app/services/media-query/media-query.service';
import {ArticlesService} from 'src/app/services/articles/articles.service';
import {DateArticleService} from 'src/app/services/date-article/date-article.service';
import {DateArticleInterface} from 'src/app/interfaces/date-article/date-article.interface';
import {formatDate} from '@angular/common';
import {PaginatedListComponent} from '../paginated-list/paginated-list.component';
import {ArticleInterface} from 'src/app/interfaces/articles/article.interface';
import {Utils} from 'src/app/classes/Utils';
import {IonContent, ModalController, NavController, Platform} from '@ionic/angular';
import {ReadingArticleModal} from '../modals/reading-article/reading-article.modal';
import {Geolocation} from '@awesome-cordova-plugins/geolocation/ngx';
import {ToastService} from 'src/app/services/toast/toast.service';
import {ArticleQueryInterface} from 'src/app/interfaces/articles/article-query.interface';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {SearchesService} from 'src/app/services/searches/searches.service';
import {SearchesInterface} from 'src/app/interfaces/searches/searches.interface';
import {SearchInterface} from 'src/app/interfaces/search/search.interface';
import {SessionInterface} from 'src/app/interfaces/session/session.interface';
import {DatesSelectorButtonComponent} from '../dates-selector-button/dates-selector-button.component';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {NavigationOptions} from '@ionic/angular/common/providers/nav-controller';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {AuthService} from '../../services/auth/auth.service';
import {AppPreferencesService} from 'src/app/services/app-preferences/app-preferences.service';
// import {NgxSpinnerService} from 'ngx-spinner';
import {FormControl} from '@angular/forms';
import {UserService} from '../../services/user/user.service';
import {DomSanitizer} from '@angular/platform-browser';
import {DatePipe} from '@angular/common';
import {FicheEntrepriseStatsComponent} from '../modals/fiche-entreprise/fiche-entreprise-stats/fiche-entreprise-stats.component';
import {FavoritesService} from '../../services/favorites/favorites.service';
import {FicheEntrepriseModal} from '../modals/fiche-entreprise/fiche-entreprise.modal';
import {EntreprisesService} from '../../services/entreprises/entreprises.service';
import {EntrepriseInterface} from '../../interfaces/entreprise/entreprise.interface';
import {File} from '@awesome-cordova-plugins/file/ngx';
import {FileOpener} from '@awesome-cordova-plugins/file-opener/ngx';
import { saveAs } from 'file-saver';
import {
  SearchSelectedCriterionsComponent
} from '../my-searches/search-selected-criterions/search-selected-criterions.component';
import {MatSidenavContainer} from '@angular/material/sidenav';
import {AddFavoriteModalComponent} from '../modals/add-favorite-modal/add-favorite-modal.component';
import {SnackbarService} from '../../services/snackbar/snackbar.service';
import {EmailArticleModal} from '../modals/email-article/email-article.modal';
import {PrintInterface} from '../../interfaces/print/print.interface';
import {Printer, PrintOptions} from '@awesome-cordova-plugins/printer/ngx';
import {FeMailModalComponent} from '../modals/fe-mail-modal/fe-mail-modal.component';
import {ModalService} from '../../services/modal/modal.service';
import {SearchCreationComponent} from '../my-searches/search-creation/search-creation.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SearchDataInterface} from '../../interfaces/search/search-data.interface';
import {ColorType, IconType} from '../parts/fe-icon/fe-icon.component';
import {HttpClient} from '@angular/common/http';
import {MapInfoWindow, MapMarker} from '@angular/google-maps';
import {FeArticleModalComponent} from '../modals/fe-article-modal/fe-article-modal.component';
import {FeFicheEntrepriseModalComponent} from '../modals/fe-fiche-entreprise-modal/fe-fiche-entreprise-modal.component';
import introJs from "intro.js";
import {BottomSheetCommunication} from "../layout/layout.component";
import {FavorisFolder} from "../../interfaces/favoritesFolders/favorites-folders.interface";
import {SearchListContainerComponent} from "../search-list-container/search-list-container.component";

@Component({
  selector: 'business-signals',
  templateUrl: 'business-signals.component.html',
  styleUrls: ['business-signals.component.scss'],
})

export class BusinessSignalsComponent extends PaginatedListComponent implements OnInit, OnDestroy, AfterViewInit {

  // Wheel event on scroll
  @ViewChild('tabGroup') tabGroup;
  @ViewChild('searchListContainer') searchListContainer: SearchListContainerComponent;

  @ViewChild('dateSelector') dateSelector: DatesSelectorButtonComponent;
  @ViewChild('keywordInput') keywordInput: ElementRef;

  @ViewChild('scrollArea') scrollArea: IonContent;

  @ViewChild('sidenavContainer', { static: false })
  sideNavContainer: MatSidenavContainer;

  @ViewChild(SearchSelectedCriterionsComponent) selectedCriterionsComponent: SearchSelectedCriterionsComponent;

  public smallSidenavDesktop: boolean = false;
  public smallRightSidenavDesktop: boolean = false;
  public searchList: SearchInterface[];

  protected unsubscribe$: Subject<any> = new Subject<any>();

  // Active map or not.
  public ACTIVE_MAPS: boolean = true;

  private _geolocFrom: string;
  private _cityFrom: number;
  private _geolocDistance: number;

  private sessionData: SessionInterface;
  public entreprise: EntrepriseInterface;
  public isLettrePersoMC = false;

  // Maps docs : https://github.com/SebastianM/angular-google-maps
  // Clustering docs : https://github.com/SebastianM/angular-google-maps/tree/master/packages/js-marker-clusterer

  public allTopicsSelected: boolean;
  public allLocationsSelected: boolean;
  public allSectorsSelected: boolean;
  public allCompanySizesSelected: boolean;
  public allActivityTypesSelected: boolean;

  public hasSectorsInSearch: boolean = false;
  public hasTopicsInSearch: boolean = false;
  public hasLeadsInSearch: boolean = false;
  public smallDesktop: boolean = false;
  public selectedSearch: string = 'Mes signaux préférés';
  public modeTri: string;
  public menuIconTri: string = 'keyboard_arrow_down';
  public menuIconActions: string = 'keyboard_arrow_down';
  public menuIconFiltre: string = 'keyboard_arrow_down';
  public menuIconAffichage: string = 'keyboard_arrow_down';
  public menuOpenAffichage: boolean = false;
  public menuOpenFiltre: boolean = false;
  public mapAvailable: boolean = false;
  public modeAffichage: string = 'Extraits';
  public menuOpenTri: boolean = false;
  public menuOpenActions: boolean = false;
  private file = new File();

  public warnMessage: string;

  // list or maps define by ToggleListMapsComponent
  public listOrMaps: string;
  public listStyle: string;
  public apiLoaded: Observable<boolean>;

  @Input() public _disableTabs: boolean;
  public stype: string = 'business_signal';
  private paramDate: boolean = false;
  public currentSearch: SearchInterface;
  public resultFilter: string = 'default';
  public exportContactMessage: string;
  public noSearches: boolean = false;
  public isPerformance: boolean;

  private noArticleErrMsg = 'Vous devez sélectionner au moins un article pour effectuer cette opération.';

  public set disableTabs(disableTabs: boolean) {
    this._disableTabs = false;
  }

  public get disableTabs(): boolean {
    return false;
  }

  // Articles Count
  public _articlesCount: number;
  public set articlesCount(articlesCount: number) {
    this._articlesCount = articlesCount;
  }

  @Input()
  public get articlesCount(): number {
    return this._articlesCount;
  }

  // Articles List
  public articlesObj$: Observable<ArticlesInterface>;

  public articlesSortingMethod: string = 'publicationDateDesc';

  // Sort Options (by revelance / by date)
  public _sortingOption: string;
  public sortingOptionForm = new FormControl();

  public get articlesSortingOption(): string {
    if (this._sortingOption === 'sortByRelevance') {
      this.modeTri = 'Pertinence';
    } else {
      if (this.articlesSortingMethod === 'publicationDateDesc') {
        this.modeTri = 'Décroissant';
      }
      if (this.articlesSortingMethod === 'publicationDateAsc') {
        this.modeTri = 'Croissant';
      }
    }
    return this._sortingOption;
  }

  // // Sort Options (by revelance / by date)
  // public _displayOption: string;
  // public displayOptionForm = new FormControl();
  //
  // public get articlesDisplayOption(): string {
  //   if (this._displayOption === 'sortByRelevance') {
  //     this.modeTri = 'pertinence';
  //   } else {
  //     this.modeTri = 'date';
  //   }
  //   return this._sortingOption;
  // }

  /**
   * Switch Button Option de tri
   */
  onSortingOptionChange(sortingOption: string, sortingLabel: string) {
    if (sortingOption === 'sortByDateAsc') {
      this._sortingOption = 'sortByDate';
      this.articlesSortingMethod = 'publicationDateAsc';
    }
    else if (sortingOption === 'sortByDateDesc') {
      this._sortingOption = 'sortByDate';
      this.articlesSortingMethod = 'publicationDateDesc';
    }
    else {
      this._sortingOption = sortingOption;
    }

    this.modeTri = sortingLabel;
    this.sessionData.userPreferences.sortingOption = this._sortingOption;
    this.userService.patchUserPreferences(this.sessionData.userPreferences).pipe(
        takeUntil(this.unsubscribe$)
    ).subscribe();
    this.articlesService.setQuery(this.query);
  }

  // onDisplayOptionChange(displayOption: string, displayLabel: string): void {
  //   this._displayOption = displayOption;
  //   this.modeAffichage = displayLabel;
  //   this.sessionData.userPreferences.
  //   if (this.mapAvailable || listStyle !== 'listmap') {
  //     this.listStyle = listStyle;
  //     this.appPreferencesService.set('articleListeStyle', this.listStyle);
  //     this.listStyleChanged.emit(this.listStyle);
  //   }
  // }

  // Search Id
  private _search_id: number;
  protected set search_id(search_id: number) {
    this._search_id = search_id;
  }

  protected get search_id(): number {
    return this._search_id;
  }

  // Get criterions
  private _criterions: Array<number> = [];
  protected set criterions(criterions: Array<number>) {
    this._criterions = criterions;
  }

  protected get criterions(): Array<number> {
    return this._criterions;
  }

  // Get offCriterions
  private _offCriterions: Array<number> = [];
  protected set offCriterions(offCriterions: Array<number>) {
    Utils.offCriterions = offCriterions;
    this._offCriterions = offCriterions;
  }

  protected get offCriterions(): Array<number> {
    return this._offCriterions;
  }

  // Criterions List
  public keywordsCount: Array<any> = [];
  public locationsCount: Array<any> = [];
  public sectorsCount: Array<any> = [];
  public topicsCount: Array<any> = [];
  public activity_typesCount: Array<any> = [];
  public company_sizesCount: Array<any> = [];

  // Get keywords
  private _keywords: Array<string> = [];
  public set keywords(keywords: Array<string>) {
    this._keywords = keywords;
  }

  public get keywords(): Array<string> {
    return this._keywords;
  }

  // Get keywords default operator
  private _keywords_default_operator: string;
  public set keywords_default_operator(keywords_default_operator: string) {
    this._keywords_default_operator = keywords_default_operator;
  }

  public get keywords_default_operator(): string {
    return this._keywords_default_operator;
  }

  // Get keywords  exact expressions
  private _keywords_must_exact: Array<string> = [];
  public set keywords_must_exact(keywords_must_exact: Array<string>) {
    this._keywords_must_exact = keywords_must_exact;
  }

  public get keywords_must_exact(): Array<string> {
    return this._keywords_must_exact;
  }

  // Get keywords must
  private _keywords_must: Array<string> = [];
  public set keywords_must(keywords_must: Array<string>) {
    this._keywords_must = keywords_must;
  }

  public get keywords_must(): Array<string> {
    return this._keywords_must;
  }

  // Get keywords Must not
  private _keywords_must_not: Array<string> = [];
  public set keywords_must_not(keywords_must_not: Array<string>) {
    this._keywords_must_not = keywords_must_not;
  }

  public get keywords_must_not(): Array<string> {
    return this._keywords_must_not;
  }

  // Get keywords should
  private _keywords_should: Array<string> = [];
  public set keywords_should(keywords_should: Array<string>) {
    this._keywords_should = keywords_should;
  }

  public get keywords_should(): Array<string> {
    return this._keywords_should;
  }

  // Get OffKeywords
  private _offKeywords: Array<string> = [];
  protected set offKeywords(offKeywords: Array<string>) {
    this._offKeywords = offKeywords;
  }

  protected get offKeywords(): Array<string> {
    return this._offKeywords;
  }

  // Start date
  private _sdate: string = undefined;
  protected set sdate(sdate: string) {
    this._sdate = sdate;
  }
  protected get sdate(): string {
    return this._sdate;
  }

  // End date
  private _edate: string = undefined;
  protected set edate(edate: string) {
    this._edate = edate;
  }
  protected get edate(): string {
    return this._edate;
  }

  // Search Tabs
  public mySearches$: Observable<SearchesInterface>;
  public mySearches: SearchesInterface;

  public mySearchList$: Observable<SearchInterface[]>;
  private _mySearchList: SearchInterface[];
  public get mySearchList() {
    return this._mySearchList;
  }
  public set mySearchList(mySearchList: SearchInterface[]) {
    this._mySearchList = mySearchList;
  }

  // Mat Slide Toggles
  public matSlideToggleDefaultValue: boolean = true;

  protected introJsBusinessSignalInit: boolean = false;
  protected introJsTourId: number;

  // Index tab 1 par defaut
  public selectedIndex: number = 0;

  private searchFromUrl: number;
  private routerSubscription: Subscription;

  /**
   * Override query from PaginatedListComponent
   */
  protected get query(): ArticleQueryInterface {
    const query: ArticleQueryInterface = {
      // TODO mettre le searchId
      searchId: this.search_id,
      articlesSortingMethod: this.articlesSortingMethod,
      articlesSortingOption: this.articlesSortingOption,
      criterions: this.criterions,
      offCriterions: this.offCriterions,
      keywords: this.keywords,
      keywords_must_exact: this.keywords_must_exact,
      keywords_must: this.keywords_must,
      keywords_must_not: this.keywords_must_not,
      keywords_should: this.keywords_should,
      keywords_default_operator: this.keywords_default_operator,
      offKeywords: this.offKeywords,
      page: this.page,
      pageSize: this.matPaginator.pageSize,
      // sdate: this.sdate,
      // edate: this.edate,
      sdate: this.dateArticleService.startDate,
      edate: this.dateArticleService.endDate,
      stype: this.stype,
      geoloc_from: this.geolocFrom,
      city_from: this.cityFrom,
      geoloc_distance_radius: this.geolocDistanceRadius,
      resultFilter: this.resultFilter
      // searchId: this.mySearchList[this.selectedIndex]?.searchId,
    };
    return query;
  }

  constructor(
      public mediaQueryService: MediaQueryService
      , private articlesService: ArticlesService
      , private entreprisesService: EntreprisesService
      , private searchesService: SearchesService
      , private dateArticleService: DateArticleService
      , public userService: UserService
      , public navController: NavController
      , public modalController: ModalController
      , public geolocation: Geolocation
      , public toastService: ToastService
      , @Inject(LOCALE_ID) public locale: string
      , private route: ActivatedRoute
      , private authService: AuthService
      // , public spinnerService: NgxSpinnerService
      , private appPreferencesService: AppPreferencesService
      , private sanitizer: DomSanitizer
      , private favoriteService: FavoritesService
      , public mySearchesService: SearchesService
      , private platform: Platform
      , private fileOpener: FileOpener
      , private snackBarService: SnackbarService
      , private printer: Printer
      , private _modalService: ModalService
      , public dialog: MatDialog
      , httpClient: HttpClient
      , private router: Router
  ) {
    super(mediaQueryService, geolocation, toastService, modalController, userService);

    // this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyAQ7wO-H-EGff6QjUawS9aHPHVdgbAX1zc', 'callback')
    //     .pipe(
    //         map(() => {console.log('map true'); return true; }),
    //         catchError(err => {console.log(err); return of(false); }),
    //     );

  }
  public launchIntroFeatureTour(tourId: number) {
    // console.log('intro.js business signals start for tourId = ' + tourId)

    let steps = [];

    if(tourId !== undefined && tourId === 1) {
      steps = [
        {
          title: 'Bienvenue sur First ECO',
          intro: 'Voici une présentation de votre nouvelle plateforme First ECO.'
        },
        {
          title: 'Relancer le guide d\'utilisation',
          element: document.querySelector<HTMLElement>('.tourBtnTour'),
          intro: 'Vous pouvez relancer ce guide en cliquant sur cette icône. Elle apparaît également dans différentes parties de la plateforme pour vous guider dans l\'utilisation des fonctionnalités.',
          position: 'bottom'
        },
        {
          title: 'Menu de navigation',
          element: document.querySelector<HTMLElement>('.menuButtonsContainer'),
          intro: 'Retrouvez dans la barre ci-dessus le menu de navigation de votre plateforme.',
          position: 'bottom'
        },
        {
          title: 'Ouverture des sous-menus',
          element: document.querySelector<HTMLElement>('.arrowBtnMenuTour'),
          intro: 'Ces flèches vous permettent d\'accéder aux sous-menus.<br /><br />' +
          'Vous pouvez, via ces derniers, accéder/gérer/créer, à tout instant, à vos recherches ou vos dossiers de favoris.',
          position: 'bottom'
        },
        {
          title: 'Accès à votre compte',
          element: document.querySelector<HTMLElement>('.feUserAccountBtn'),
          intro: 'Accédez en cliquant ici au détail de votre offre ou pour contacter votre commercial dédié.'
        },
        {
          title: 'Gestion des recherches',
          element: document.querySelector<HTMLElement>('.manageSearchBtnTour'),
          intro: 'Ce bouton ouvre une fenêtre pour gérer vos recherches, les déplacer, les filtrer, etc..',
          position: 'right'
        },
        {
          title: 'Création d\'une recherche',
          element: document.querySelector<HTMLElement>('.createSearchBtnTour'),
          intro: 'Ce bouton vous permet de créer une nouvelle recherche.',
          position: 'right'
        },
        {
          title: 'Liste des recherches',
          element: document.querySelector<HTMLElement>('.searchListItems'),
          intro: 'Retrouvez ici toutes vos recherches enregistrées.',
          position: 'right'
        },
        {
          title: 'Actions sur un élément',
          element: document.querySelector<HTMLElement>('.dotsMenuSearchBtnTour'),
          intro: 'Chaque fonctionnalité (recherche, article, etc.) possède ce menu vous permettant d\'effectuer des actions.',
          position: 'left'
        },
        {
          title: 'Options visuelles et actions multiples',
          element: document.querySelector<HTMLElement>('.divSearchActionTour'),
          intro: 'Sur cette barre, vous pouvez gérer le mode d\'affichage, de tri, de filtre et effectuer des actions multiples sur les articles cochés.',
          position: 'bottom'
        },
        {
          title: 'Modifier une recherche',
          element: document.querySelector<HTMLElement>('.editSearchBtnTour'),
          intro: 'Ce bouton vous permet d\'éditer les critères de la recherche en cours.',
          position: 'left'
        },
        {
          title: 'Filtrer les critères d\'une recherche',
          element: document.querySelector<HTMLElement>('.searchSelectedCriterions'),
          intro: 'Ici apparaissent tous les critères de votre recherche. Filtrer les résultats en cochant certains critères.',
          position: 'left'
        }
      ];
    }

    if(tourId !== undefined && steps.length > 0) {
      let a = this;
      introJs().setOptions({
        nextLabel: '>>',
        prevLabel: '<<',
        doneLabel: 'Commencer',
        disableInteraction: true,
        steps: steps
      }).onexit(function() {
        a.userService.doneFeatureTour(tourId);
        a.introJsTourId = undefined;
      })
      .start();
    }

  }

  public ngOnInit() {

    this.loadingInProgress = true;

    this.mySearchesService.getMySearches().subscribe(
        (mySearches: SearchesInterface) => {
          this.searchList = mySearches.searches;
          // if (this.search?.data.libelles !== undefined && this.search?.data.libelles.keywords !== undefined && this.search?.data.libelles.keywords.length === 0) {
          //   this.search.data.libelles.keywords = undefined;
          // }
          this.mySearchesService.mySearchList$.subscribe(value => {
            this.searchList = value;
            this.mySearchList = value;
          });
        }
    );
      if (this.mediaQueryService.mobileQuery.matches) {
        this.listStyle = 'small';
        this.appPreferencesService.set('articleListeStyle', this.listStyle);
      } else {
        this.listStyle = 'big';
        this.appPreferencesService.set('articleListeStyle', this.listStyle);
      }

      setTimeout(callback => {
        let particle;
        let pentreprise = undefined;
        let psdate = undefined;
        let pedate = undefined;
        let psearch = undefined;
        if (Utils.hasBranchParameters()) {
          particle = Utils.branchParams['article'];
          psdate = Utils.branchParams['sdate'];
          pedate = Utils.branchParams['edate'];
          psearch = Utils.branchParams['search'];
          Utils.branchParams = [];
        } else if (this.getParameterByName('article') !== undefined && this.getParameterByName('entreprise') === undefined) {
          particle = this.getParameterByName('article');
          psdate = this.getParameterByName('sdate');
          pedate = this.getParameterByName('edate');
            if (this.getParameterByName('search') !== undefined) {
              psearch = this.getParameterByName('search');
            }
        } else if (this.getParameterByName('article') === undefined && this.getParameterByName('entreprise') !== undefined) {
          pentreprise = this.getParameterByName('entreprise');
          psdate = this.getParameterByName('sdate');
          pedate = this.getParameterByName('edate');
            if (this.getParameterByName('search') !== undefined) {
              psearch = this.getParameterByName('search');
            }
        }

        if (particle !== undefined) {
          const Query = {
            articles: particle
          };
          // this.articlesService.getArticlesContent([particle]).subscribe(
          //     (res) => {
          //       this.openReadingModalFromMarker(res[0]);
          //     }
          // );
          this.articlesService.getArticlesContent(Query).subscribe(
              (res) => {
                this.openReadingModalFromMarker(res[0]);
              }
          );
        }

        if (pentreprise !== undefined) {
          this.entreprisesService.getEntreprise(pentreprise).subscribe(
              entrepriseFromApi => {
                this.entreprise = entrepriseFromApi.entrepriseData;

                this.entreprise.financials?.forEach(value =>  {
                  if (value.annualSalesNumber) {
                    this.entreprise.chiffreAffaire = value.annualSales;
                    this.entreprise.chiffreAffaireAnnee = value.year;
                  }
                  if (value.netProfilNumber) {
                    this.entreprise.resultatNet = value.netProfit;
                    this.entreprise.resultatNetAnnee = value.year;
                  }
                  if (value.effectif) {
                    this.entreprise.effectif = value.effectif + '';
                    this.entreprise.effectifAnnee = value.year;
                  }
                });
                this.openArticleFicheEntreprise(pentreprise, this.entreprise);
              });
        }
        if (psearch !== undefined) {
          // for (const s of this.mySearchList) {
          //   if (s.searchId.toString() === this.getParameterByName('search')) {
          if (psdate !== undefined) {
            this.paramDate = true;
            const sdateParts = psdate.split('-');
            const edateParts = pedate.split('-');
            // this.sdate = formatDate(this.getParameterByName('sdate'), 'dd-MM-yyyy', this.locale);
            // this.edate = formatDate(this.getParameterByName('edate'), 'dd-MM-yyyy', this.locale);
            this.dateArticleService.startDate = formatDate(sdateParts[2] + '-' + sdateParts[1] + '-' + sdateParts[0], 'yyyy-MM-dd', this.locale);
            this.dateArticleService.endDate = formatDate(edateParts[2] + '-' + edateParts[1] + '-' + edateParts[0], 'yyyy-MM-dd', this.locale);
            this.getDates();
          } else {
            // this.getDates();
          }
          this.searchFromUrl = Number(psearch);
          // this.disableTabs = true;
          // this.setTabCriterions(this.mySearchList.indexOf(s));

          // break;
          //   }
          // }
        } else {
          this.articlesObj$ = this.articlesService.articlesObj$;

          // Get default dates
          this.getDates();
        }
      }, 1000);

    //  Récupération des préférences utilisateur
    this.userService.getSessionDatas()
        .pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(
        (session: SessionInterface) => {
          if (session !== undefined) {
            // Init Session Data
            this.sessionData = session;
            if (this.sessionData.productType === 'Lettre_MotsCles') {
              this.isLettrePersoMC = true;
            }
            if (this.sessionData.userPreferences.sortingOption !== undefined) {
              this._sortingOption = this.sessionData.userPreferences.sortingOption;
            }
            session.appConfig.viewConfigs.forEach(value => {
              if (value.component === "EXPORT_CONTACT") {
                if (value.state === "limited") {
                  this.exportContactMessage = value.message;
                }
              }
            });
            if (session.productOfferCode === 'OFFRE_3') {
              this.isPerformance = true;
            }

            // Feature tours for automatic launch
            if (!this.authService.roleCom) {
              try {
                if (session.featureTours !== undefined && session.featureTours.length > 0) {
                  for (const featureTour of session.featureTours) {
                    if (featureTour.tourComponent === 'business_signals') {
                      if(!this.introJsBusinessSignalInit) {
                        this.introJsTourId = featureTour.tourId;
                        this.introJsBusinessSignalInit = true;
                        console.log("tour id = " + this.introJsTourId);
                      }
                    }
                  }
                }
              } catch (e) {
              }
            }

          }
        }
    );

    // gestion du clic sur une recherche dans le menu mes signaux business
    this.mySearchesService.myEventToBusinessSignals.subscribe((eventData: number) => {

      let index = 0;
      for (let search of this.mySearchList) {
        if (search.searchId === eventData) {
          break;
        }
        index++;
      }

      if (eventData === -1) {
        index = 0;
      }

      if (this.mySearchList?.length > 0) {
        this.disableTabs = true;
        if (this.currentSearch?.searchId === eventData) {
          Utils.skip = true;
        }
        this.setTabCriterions(index);
        this.scrollArea.scrollToTop(0);
      }
      else {
        this.noSearches = true;
        this.selectedCriterionsComponent.clearParameters();
      }
    });
    if (this.routerSubscription == null) {
      this.routerSubscription = this.router.events.pipe(
          filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        // Vérifiez si c'est la route actuelle et non juste une navigation dans l'application
        if (this.router.url.indexOf('/business-signals') > 0) {
          this.searchListContainer.clearSearchBar();
        }
      });
    }
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      this.sideNavContainer.updateContentMargins();
    }, 0);

    if (this.getParameterByName('search') !== undefined) {
      if (this.getParameterByName('sdate') !== undefined) {

        const sdateParts = this.getParameterByName('sdate').split('-');
        const edateParts = this.getParameterByName('edate').split('-');
        // this.sdate = formatDate(this.getParameterByName('sdate'), 'dd-MM-yyyy', this.locale);
        // this.edate = formatDate(this.getParameterByName('edate'), 'dd-MM-yyyy', this.locale);
        this.sdate = formatDate(sdateParts[2] + '-' + sdateParts[1] + '-' + sdateParts[0], 'yyyy-MM-dd', this.locale);
        this.edate = formatDate(edateParts[2] + '-' + edateParts[1] + '-' + edateParts[0], 'yyyy-MM-dd', this.locale);
      }
      this.searchFromUrl = Number(this.getParameterByName('search'));
    }
    if (this.searchFromUrl !== undefined) {
      if (this.sdate !== undefined) {
        this.dateSelector.setDates(this.sdate, this.edate);
      }
    }
  }

  getParameterByName(name: any) {
    const url = window.location.href;
    name = name.replace(/[[]]/g, '\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) { return undefined; }
    if (!results[2]) { return ''; }
    return decodeURIComponent(results[2].replace('/+/g', ' '));
  }

  private previousQuery: ArticleQueryInterface;

  /**
   * Load data. Used by PaginatedListComponent
   */
  protected load(): Observable<ArticlesInterface> {
    const currentQuery = this.query;
    if (currentQuery.sdate && currentQuery.edate) {
      this.dateSelector.setDates(currentQuery.sdate, currentQuery.edate);
      if (this.selectedIndex !== 0) {
          if (this.previousQuery !== undefined && this.equals(this.previousQuery, currentQuery )
              || (currentQuery.criterions === undefined || currentQuery.criterions.length === 0)
              && (currentQuery.keywords === undefined || currentQuery.keywords.length === 0)
              && (currentQuery.keywords_must === undefined || currentQuery.keywords_must.length === 0)
              && (currentQuery.keywords_should === undefined || currentQuery.keywords_should.length === 0)
              && (currentQuery.keywords_must_exact === undefined || currentQuery.keywords_must_exact.length === 0)
              && (currentQuery.keywords_must_not === undefined || currentQuery.keywords_must_not.length === 0)
              && (currentQuery.city_from === undefined || currentQuery.city_from === 0)
              && (currentQuery.geoloc_from === undefined || currentQuery.geoloc_from === '')) {
            return this.articlesService.articlesObj$;
          }
      }

      // this.spinnerService.show();
      if (this.sdate && this.edate) {
        this.previousQuery = currentQuery;
        this.articlesService.setQuery(currentQuery);
        if (!Utils.equalPrevious) {
          this.loadingInProgress = true;
        }
      } else {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();

        this.sdate = yyyy + '-' + mm + '-' + '-' + dd;
        this.edate = yyyy + '-' + mm + '-' + '-' + dd;
      }
    }

    return this.articlesService.articlesObj$;
  }

  equals( x, y ) {
    if ( x === y ) return true;
    // if both x and y are null or undefined and exactly the same

    if ( ! ( x instanceof Object ) || ! ( y instanceof Object ) ) return false;
    // if they are not strictly equal, they both need to be Objects

    if ( x.constructor !== y.constructor ) return false;
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

    for ( var p in x ) {
      if ( ! x.hasOwnProperty( p ) ) continue;
      // other properties were tested using x.constructor === y.constructor

      if ( ! y.hasOwnProperty( p ) ) return false;
      // allows to compare x[ p ] and y[ p ] when set to undefined

      if ( x[ p ] === y[ p ] ) continue;
      // if they have the same strict value or identity then they are equal

      if ( typeof( x[ p ] ) !== 'object' ) return false;
      // Numbers, Strings, Functions, Booleans must be strictly equal

      if ( ! this.equals( x[ p ],  y[ p ] ) ) return false;
      // Objects and Arrays must be tested recursively
    }

    for ( p in y ) {
      if ( y.hasOwnProperty( p ) && ! x.hasOwnProperty( p ) ) return false;
      // allows x[ p ] to be set to undefined
    }
    return true;
  }

  /**
   * Get data and count. Used by PaginatedListComponent
   * @param articles ArticlesInterface
   */
  protected getDataTypedAndCountAfterLoad(articles: ArticlesInterface): { data: Array<ArticleInterface>, count: number } {
    this.disableTabs = false;

    if (articles && typeof (articles) !== 'undefined') {
      this.articlesCount = articles.articlesCount;
      this.selectedCriterionsComponent.setSearchResultCounts(articles);

      if (articles.keywordsCount.length > 0) {
        this.keywordsCount = articles.keywordsCount;
        this.keywordsCount.sort((a, b) => a.keyword.localeCompare(b.keyword));
      } else {
        this.keywordsCount = [];
      }

      if (articles.locationsCount.length > 0) {
        this.locationsCount = articles.locationsCount;
        // this.locationsCount.sort((a, b) => a.label.localeCompare(b.label));
        this.allLocationsSelected = this.isAllKeysSelected(this.locationsCount);
      }

      if (articles.sectorsCount.length > 0) {
        this.sectorsCount = articles.sectorsCount;
        // this.sectorsCount.sort((a, b) => a.label.localeCompare(b.label));
        this.allSectorsSelected = this.isAllKeysSelected(this.sectorsCount);
      }

      if (articles.topicsCount.length > 0) {
        this.topicsCount = articles.topicsCount;
        // this.topicsCount.sort((a, b) => a.label.localeCompare(b.label));
        this.allTopicsSelected = this.isAllKeysSelected(this.topicsCount);
      }

      if (articles.activity_typesCount.length > 0) {
        this.activity_typesCount = articles.activity_typesCount;
        // this.activity_typesCount.sort((a, b) => a.label.localeCompare(b.label));
        this.allActivityTypesSelected = this.isAllKeysSelected(this.activity_typesCount);
      }

      if (articles.company_sizesCount.length > 0) {
        this.company_sizesCount = articles.company_sizesCount;
        // this.company_sizesCount.sort((a, b) => a.label.localeCompare(b.label));
        this.allCompanySizesSelected = this.isAllKeysSelected(this.company_sizesCount);
      }
      this.matPaginator.length = this.articlesCount;
      return { data: articles.articles_, count: this.articlesCount };
    } else {
      return { data: [], count: 0 };
    }
  }

  private firstGetDate: boolean = true;

  public async getDates(): Promise<any> {
    this.dateArticleService.dateArticle$.pipe(
      takeUntil(this.unsubscribe$),
      filter(ev => window.location.href.includes('app/business-signals'))
    ).subscribe(
      (dateArticle: DateArticleInterface) => {
        if (dateArticle) {
          if (!this.paramDate || (this.paramDate && !this.firstGetDate)) {
            if (typeof dateArticle.period !== 'undefined') {
              this.sdate = this.dateArticleService.parsePeriodDate(dateArticle.period);
              this.edate = formatDate(new Date(), 'yyyy-MM-dd', this.locale);
            } else {
              this.sdate = formatDate(dateArticle.from, 'yyyy-MM-dd', this.locale);
              this.edate = formatDate(dateArticle.to, 'yyyy-MM-dd', this.locale);
            }
            const currentQuery = this.query;

            if (!((currentQuery.criterions === undefined || currentQuery.criterions.length === 0)
                && (currentQuery.keywords === undefined || currentQuery.keywords.length === 0)
                && (currentQuery.city_from === undefined || currentQuery.city_from === 0)
                && (currentQuery.geoloc_from === undefined || currentQuery.geoloc_from === ''))) {
              this.articlesService.setQuery(currentQuery);
            }
            this.selectionModel.clear();

           }
          else {
            this.dateSelector.setDates(this.dateArticleService.startDate, this.dateArticleService.endDate);
            const currentQuery = this.query;

            // if (!((currentQuery.criterions === undefined || currentQuery.criterions.length === 0)
            //     && (currentQuery.keywords === undefined || currentQuery.keywords.length === 0)
            //     && (currentQuery.city_from === undefined || currentQuery.city_from === 0)
            //     && (currentQuery.geoloc_from === undefined || currentQuery.geoloc_from === ''))) {
              this.articlesService.setQuery(currentQuery);
          }
          this.firstGetDate = false;
        }
      }
    );
  }

  public scrollTabs(event) {
    const children = this.tabGroup._tabHeader._elementRef.nativeElement.children;

    // get the tabGroup pagination buttons
    const back = children[0];
    const forward = children[2];

    // depending on scroll direction click forward or back
    if (event.deltaY > 0) {
      forward.click();
    } else {
      back.click();
    }
  }

  /**
   * Get the searches list
   */
  public async loadTabSearches(): Promise<any> {
    this.mySearches$ = this.searchesService.getMySearches();

    this.mySearches$.pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe(
      (mySearches: SearchesInterface) => {
        this.mySearchList = mySearches.searches;

        if (this.mySearchList.length > 0) {
          if (this.searchFromUrl !== undefined) {
            for (const s of this.mySearchList) {
              if (s.searchId === this.searchFromUrl) {
                this.selectedIndex = this.mySearchList.indexOf(s);
                this.setTabCriterions(this.selectedIndex);
                this.searchFromUrl = undefined;
              }
            }
          } else {
            this.setTabCriterions(0);
            if (this.mySearchList[0].data.sectors !== undefined && this.mySearchList[0].data.sectors.length > 0) {
              this.hasSectorsInSearch = true;
            }
            if (this.mySearchList[0].data.topics !== undefined && this.mySearchList[0].data.topics.length > 0) {
              this.hasTopicsInSearch = true;
            }
            this.hasLeadsInSearch = this.mySearchList[0].data.hasLeadCriterion;
          }
        } else {
          // this.navigateToUrl('/app/my-searches');
          this.noSearches = true;
        }
      }
    );
  }

  async createNewSearch() {
    // Utils.navigate('/app/my-searches-add', this.navController);

    const dial = this.dialog.open(SearchCreationComponent, {
      disableClose: true,
      height: 'calc(100% - 73px)',
      width: '100%',
      maxWidth: '100%',
      position: {bottom: '0', top: '73px', right: '0', left: '0'},
      hasBackdrop: true
    });
    dial.afterClosed().subscribe(value => {
      if (value) {
        this.noSearches = false;
        // this.mySearchesService.emitEventToBusinessSignals();
        this.mySearchesService.getMySearches().subscribe((mySearches: SearchesInterface) => {
          this.mySearchesService.emitEventToBusinessSignals(-1);
        });
      }
    });
  }

  protected get geolocFrom(): string {
    return this._geolocFrom;
  }
  protected get cityFrom(): number {
    return this._cityFrom;
  }
  protected get geolocDistanceRadius(): number {
    return this._geolocDistance;
  }

  /**
   * Listener for tab change
   * @param $event MatTabChangeEvent
   */
  public setTabCriterions(index: number): void {
    this.selectedSearch = this.mySearchList[index].data.label;
    this.currentSearch = this.mySearchList[index];
    //FIXME
    this.selectedCriterionsComponent.setCurrentSearch(this.mySearchList[index].data);

    this.emitRefreshSearchId(index);

    // Reset page index
    this.page = 1;
    this.matPaginator.pageIndex = 0;

    if (this.mySearchList[index] === undefined) {
      return;
    }

    this.criterions = [];
    this.offCriterions = [];
    this.keywords = [];
    this.offKeywords = [];
    this.hasSectorsInSearch = false;
    this.hasTopicsInSearch = false;
    this.hasLeadsInSearch = false;
    this.mySearchList[index].dataRecentResults.isView = true;
    if (this.mySearchList[index].searchId !== undefined) {
      this.search_id = this.mySearchList[index].searchId;
    }
    if (this.mySearchList[index].data.locations !== undefined && this.mySearchList[index].data.locations.length > 0) {
      this.criterions = this.criterions.concat(this.mySearchList[index].data.locations);
    }
    if (this.mySearchList[index].data.sectors !== undefined && this.mySearchList[index].data.sectors.length > 0) {
      this.criterions = this.criterions.concat(this.mySearchList[index].data.sectors);
      this.hasSectorsInSearch = true;
    }
    if (this.mySearchList[index].data.topics !== undefined && this.mySearchList[index].data.topics.length > 0) {
      this.criterions = this.criterions.concat(this.mySearchList[index].data.topics);
      this.hasTopicsInSearch = true;
    }
    this.hasLeadsInSearch = this.mySearchList[index].data.hasLeadCriterion;
    if (this.mySearchList[index].data.activityTypes !== undefined && this.mySearchList[index].data.activityTypes.length > 0) {
      this.criterions = this.criterions.concat(this.mySearchList[index].data.activityTypes);
    }
    if (this.mySearchList[index].data.companySizes !== undefined && this.mySearchList[index].data.companySizes.length > 0) {
      this.criterions = this.criterions.concat(this.mySearchList[index].data.companySizes);
    }
    this._cityFrom = this.mySearchList[index].data.cityFrom;
    this._geolocFrom = this.mySearchList[index].data.geolocFrom;
    this._geolocDistance = this.mySearchList[index].data.geolocDistanceRadius;
    /*     if (this.mySearchList[index].data.keywords.length > 0) {
          this.criterions = this.criterions.concat(this.mySearchList[index].data.keywords);
        } */
    if (this.mySearchList[index].data.keywords !== undefined && this.mySearchList[index].data.keywords.length > 0) {
      this.keywords = this.keywords.concat(this.mySearchList[index].data.keywords);
    } else {
      this.keywords = [];
    }

    // Keywords Default operator
    this.keywords_default_operator = this.mySearchList[index].data.keywords_default_operator;

    // Keywords Must
    this.keywords_must = [];
    if (this.mySearchList[index].data.keywords_must !== undefined && this.mySearchList[index].data.keywords_must.length > 0) {
      this.keywords_must = this.keywords_must.concat(this.mySearchList[index].data.keywords_must);
    } else {
      this.keywords_must = [];
    }

    // Keywords should
    this.keywords_should = [];
    if (this.mySearchList[index].data.keywords_should !== undefined && this.mySearchList[index].data.keywords_should.length > 0) {
      this.keywords_should = this.keywords_should.concat(this.mySearchList[index].data.keywords_should);
    } else {
      this.keywords_should = [];
    }

    // Keywords Must Exact
    this.keywords_must_exact = [];
    if (this.mySearchList[index].data.keywords_must_exact !== undefined && this.mySearchList[index].data.keywords_must_exact.length > 0) {
      this.keywords_must_exact = this.keywords_must_exact.concat(this.mySearchList[index].data.keywords_must_exact);
    } else {
      this.keywords_must_exact = [];
    }

    // Keywords Must not
    this.keywords_must_not = [];
    if (this.mySearchList[index].data.keywords_must_not !== undefined && this.mySearchList[index].data.keywords_must_not.length > 0) {
      this.keywords_must_not = this.keywords_must_not.concat(this.mySearchList[index].data.keywords_must_not);
    } else {
      this.keywords_must_not = [];
    }

    this.stype = this.mySearchList[index].data.searchType;

    this.selectionModel.clear();

    this.disableTabs = true;

    this.init<ArticlesInterface>();

    this.launchIntroFeatureTour(this.introJsTourId);
  }

  /**
   * Listener for tab change
   * @param $event MatTabChangeEvent
   */
  public selectedTabChange($event: MatTabChangeEvent ): void {
    this.disableTabs = true;
    this.setTabCriterions($event.index);
    this.scrollArea.scrollToTop(0);
  }

  public matSlideToggleChange(tabIndex: number, event: MatSlideToggleChange, criterion: any) {

    this.matSlideToggleDefaultValue = event.checked;

    // If event is checked, we add the id, else we remove it
    if (!event.checked) {
      if ('id' in criterion) {
        this.offCriterions.push(criterion.id);
        const index = this.criterions.indexOf(criterion.id);
        if (index > -1) {
          this.criterions.splice(index, 1);
        }
      } else if ('keyword' in criterion) {
        this.offKeywords.push(criterion.keyword);
        const index = this.keywords.indexOf(criterion.keyword);
        if (index > -1) {
          this.keywords.splice(index, 1);
        }
      }
    } else {
      if ('id' in criterion) {
        const index = this.offCriterions.indexOf(criterion.id);
        if (index > -1) {
          this.offCriterions.splice(index, 1);
        }
        this.criterions.push(criterion.id);
      } else if ('keyword' in criterion) {
        const index = this.offKeywords.indexOf(criterion.keyword);
        if (index > -1) {
          this.offKeywords.splice(index, 1);
        }
        this.keywords.push(criterion.keyword);
      }
    }

    // Reset page index
    this.page = 1;
    this.matPaginator.pageIndex = 0;

    this.disableTabs = true;

    this.articlesService.setQuery(this.query);
  }

  public isCriterionOn(criterionId: number): boolean {
    return !this.offCriterions.some(offCriterion => JSON.stringify(offCriterion) === JSON.stringify(criterionId));
  }

  public isKeywordOn(keyword: string): boolean {
    return !this.offKeywords.some(offKeyword => JSON.stringify(offKeyword) === JSON.stringify(keyword));
  }

  /**
   * Open article from Maps Info Window
   * @param article ArticleInterface
   */
  public async openReadingModalFromMarker(article: ArticleInterface): Promise<void> {
    this._modalService.showCustomModal(FeArticleModalComponent, {
      data: {
        article: article,
        keywords: this._keywords
      }});
  }

  public async openArticleFicheEntreprise(entrepriseId: number, entreprise: EntrepriseInterface): Promise<void> {
    this._modalService.showCustomModal(FeFicheEntrepriseModalComponent, {
      data: {
        entreprise: entreprise,
        // articleId: this.article.id
      }, disableClose: true});
    // const modal = await this.modalController.create({
    //   component: FeFicheEntrepriseModalComponent,
    //   cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog ficheEModal',
    //   componentProps: {
    //     entreprise: entreprise,
    //     entrepriseId: entrepriseId
    //   }
    // });
    // return await modal.present();
  }

  public navigateToUrl(url: string, direction: string = 'forward'): void {
    if (typeof url !== 'undefined' && url !== null && url !== '') {
      if (direction === 'forward') {
        Utils.navigate(url, this.navController);
        // this.navController.navigateForward(url);
      } else if (direction === 'back') {
        this.navController.navigateBack(url);
      } else if (direction === 'root') {
        this.navController.navigateRoot(url);
      }
    }
  }

  public firstTime = true;

  public ionViewWillEnter(): void {
    if (this.getParameterByName('search') !== undefined) {
      if (this.getParameterByName('sdate') !== undefined) {

        const sdateParts = this.getParameterByName('sdate').split('-');
        const edateParts = this.getParameterByName('edate').split('-');
        // this.sdate = formatDate(this.getParameterByName('sdate'), 'dd-MM-yyyy', this.locale);
        // this.edate = formatDate(this.getParameterByName('edate'), 'dd-MM-yyyy', this.locale);
        this.sdate = formatDate(sdateParts[2] + '-' + sdateParts[1] + '-' + sdateParts[0], 'yyyy-MM-dd', this.locale);
        this.edate = formatDate(edateParts[2] + '-' + edateParts[1] + '-' + edateParts[0], 'yyyy-MM-dd', this.locale);
      }
      this.searchFromUrl = Number(this.getParameterByName('search'));
    }
    if (this.searchFromUrl !== undefined) {
      if (this.sdate !== undefined) {
        this.dateSelector.setDates(this.sdate, this.edate);
      }
      this.loadTabSearches();
    } else {
      if (this.firstTime) {
        this.firstTime = false;
        this.selectedIndex = 0;
        this.loadTabSearches();
      }
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(0);
    this.unsubscribe$.complete();
  }

  getBadgeLabel(i: number) {
    if (i === this.selectedIndex) {
      return this._articlesCount;
    }
    // Results since last connexion
    if (this.mySearchList[i].dataRecentResults.isView !== undefined && this.mySearchList[i].dataRecentResults.nbResults !== undefined) {
      return !this.mySearchList[i].dataRecentResults.isView && this.mySearchList[i].dataRecentResults.nbResults !== 0 ? '+' + this.mySearchList[i].dataRecentResults.nbResults : '';
    }
    return '';
  }

  /**
   * Date Parser
   * @param value string date
   */
  parseDate(value: any): Date | null {
    if ((typeof value === 'string') && (value.includes('/'))) {
      const str = value.split('/');

      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);

      return new Date(year, month, date);
    } else if ((typeof value === 'string') && value === '') {
      return new Date();
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  /**
   * Tooltip Message for Searches with new articles since last connection
   * @param i Mat Tab Index
   */
  getToolTipLabel(i: number) {
    const nbResults: number = this.mySearchList[i].dataRecentResults.nbResults;
    const isView: boolean = this.mySearchList[i].dataRecentResults.isView;
    const startDate = this.parseDate(this.mySearchList[i].dataRecentResults.startDate);
    if (i !== this.selectedIndex && isView !== undefined && nbResults !== undefined) {
      return !isView && nbResults !== 0 ? '' + nbResults + (nbResults === 1 ? ' nouvel' : ' nouveaux') + ' article' + (nbResults === 1 ? '' : 's') + ' depuis le ' + formatDate(startDate, 'dd/MM/yyyy', 'fr') : '';
    }
    return '';
  }

  /**
   * Mat Tab Style Class if new articles since last connexion
   * @param i Mat Tab Index
   */
  getMatTabLabelClass(i: number) {
    const nbResults: number = this.mySearchList[i].dataRecentResults.nbResults;
    const isView: boolean = this.mySearchList[i].dataRecentResults.isView;
    if (i !== this.selectedIndex && isView !== undefined && nbResults !== undefined) {
      return !isView ? 'new-mat-tab-label' : '';
    }
    return '';
  }

  async editSearch(search: SearchInterface) {
    if (search.readOnly && !this.authService.roleCom) {
      this.snackBarService.showDangerSnackbar('Édition impossible', 'Cette recherche est en lecture ' +
          'seule et n’est pas éditable.');
    } else {
      const dialogRef = this.dialog.open(SearchCreationComponent, {
        height: 'calc(100% - 73px)',
        width: '100%',
        maxWidth: '100%',
        position: {bottom: '0', top: '73px', right: '0', left: '0'},
        data: {search: search},
        hasBackdrop: true,
        disableClose: true
      });
      dialogRef.beforeClosed().subscribe(value => {
        if (value) {
          this.mySearchesService.getMySearches().subscribe((mySearches: SearchesInterface) => {
            this.mySearchesService.emitEventToBusinessSignals(search.searchId);
          });
        }
      });
    }
  }

  editCurrentSearch() {
    const params: NavigationOptions = {
      queryParams: {
        searchedit: this.selectedIndex
      }
    };
    Utils.navigate('/app/my-searches-add', this.navController, params);
    // this.navController.navigateForward('/app/my-searches-add', params);
  }

  isReadOnlySearch() {
    if (this.mySearchList !== undefined && this.mySearchList.length > 0) {
      return this.mySearchList[this.selectedIndex].readOnly;
    }
    return true;
  }

  public isRoleCom() {
    return this.authService.roleCom;
  }

  openNewSearch() {
    if (this.keywordInput !== undefined && this.keywordInput.nativeElement.value !== undefined && this.keywordInput.nativeElement.value.length > 0) {
      const params: NavigationOptions = {
        queryParams: {
          keyword: this.keywordInput.nativeElement.value
        }
      };
      Utils.navigate('/app/my-searches-add', this.navController, params);
      // this.navController.navigateForward('/app/my-searches-add', params);
    } else {
      // this.navigateToUrl('/app/my-searches-add');
    }
  }


  getKeywordsSearched() {
    const keywordSearched = [];
    for (const ks of this.keywordsCount) {
      const searched = ks.searched;
      keywordSearched.push(...searched);
    }
    return keywordSearched;
  }

  arrayToStringWithLineBreaks(keywords_must: Array<string>) {
    return keywords_must.join('<br>');
  }

  /**
   * Sélection Désélection de tous les éléments d'un critère donné
   * @param keys
   * @param allKeysSelected
   */
  checkUncheckAllKeys(keys: Array<any>, allKeysSelected: boolean) {

    for (var i = 0; i < keys.length; i++) {
      const key = keys[i];
      // Unselect all
      if (!allKeysSelected) {
        if ('id' in key) {
          this.offCriterions.push(key.id);
          const index = this.criterions.indexOf(key.id);
          if (index > -1) {
            this.criterions.splice(index, 1);
          }
        } else if ('keyword' in key) {
          this.offKeywords.push(key.keyword);
          const index = this.keywords.indexOf(key.keyword);
          if (index > -1) {
            this.keywords.splice(index, 1);
          }
        }
      }
      // Select all
      else {
        if ('id' in key) {
          const index = this.offCriterions.indexOf(key.id);
          if (index > -1) {
            this.offCriterions.splice(index, 1);
          }
          this.criterions.push(key.id);
        } else if ('keyword' in key) {
          const index = this.offKeywords.indexOf(key.keyword);
          if (index > -1) {
            this.offKeywords.splice(index, 1);
          }
          this.keywords.push(key.keyword);
        }
      }
    }
    // Reset page index
    this.page = 1;
    this.matPaginator.pageIndex = 0;

    this.disableTabs = true;

    this.articlesService.setQuery(this.query);
  }


  /**
   * Retourne true si tous les éléments d'un critère sont sélectionnés
   * @param keys les éléments du critère
   */
  isAllKeysSelected(keys: Array<any>): boolean {
    for (var i = 0; i < keys.length; i++) {
      if (!this.isCriterionOn(keys[i].id)) {
        return false;
      }
    }
    return true;
  }

  onDropDownMenuOpenTri($event) {
    $event.stopPropagation();
    this.menuOpenTri = !this.menuOpenTri;
    if (this.menuOpenTri) {
      this.menuIconTri = 'keyboard_arrow_up';
    }
  }

  onDropDownMenuOpenAffichage($event) {
    $event.stopPropagation();
    this.menuOpenAffichage = !this.menuOpenAffichage;
    if (this.menuOpenAffichage) {
      this.menuIconAffichage = 'keyboard_arrow_up';
    }
  }

  onDropDownMenuOpenFiltre($event) {
    $event.stopPropagation();
    this.menuOpenFiltre = !this.menuOpenFiltre;
    if (this.menuOpenFiltre) {
      this.menuIconFiltre = 'keyboard_arrow_up';
    }
  }

  onDropDownMenuOpenActions($event) {
    $event.stopPropagation();
    this.menuOpenActions = !this.menuOpenActions;
    if (this.menuOpenActions) {
      this.menuIconActions = 'keyboard_arrow_up';
    }
  }


  onMenuTriClosed() {
    this.menuOpenTri = false;
    this.menuIconTri = 'keyboard_arrow_down';
  }

  onMenuAffichageClosed() {
    this.menuOpenAffichage = false;
    this.menuIconAffichage = 'keyboard_arrow_down';
  }

  onMenuActionsClosed() {
    this.menuOpenActions = false;
    this.menuIconActions = 'keyboard_arrow_down';
  }

  onMenuFiltreClosed() {
    this.menuOpenFiltre = false;
    this.menuIconFiltre = 'keyboard_arrow_down';
  }

  public exportArticlesList(format: string, selectionModel: any) {
    if (this.getSelectedArticles().length < 1) {
      this.snackBarService.showDangerSnackbar('Opération impossible', this.noArticleErrMsg);
    } else if (this.getSelectedArticles().length > 50) {
      this.snackBarService.showDangerSnackbar('Export impossible', 'L\'export d\'article est ' +
          'limité à un maximum de 50 articles à la fois.');
    } else {
      this.articlesService.exportArticlesList(format, selectionModel)
          .pipe(
              takeUntil(this.unsubscribe$)
          )
          .subscribe(
              response => {
                // TODO : Récupérer dynamiquement le nom du fichier (Content-Disposition introuvable dans le header de la response)
                let fileName = 'FirstECO_export_contacts';

                // TODO NE FONCTIONNE PAS MAIS PISTE DE SOLUTION
                const contentDisposition = response.headers.get('Content-Disposition');
                if (contentDisposition) {
                  const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                  const matches = fileNameRegex.exec(contentDisposition);

                  if (matches != null && matches[1]) {
                    fileName = matches[1].replace(/['"]/g, '');
                  }
                } else {
                  if (format === 'excel') {
                    fileName += '.xlsx';
                  } else {
                    fileName += '.' + format;
                  }
                }

                const fileContent = response.body;

                // Save as for mobile version
                if (this.platform.is('ios') || this.platform.is('android')) {

                  this.file.writeFile(this.file.dataDirectory, fileName, fileContent)
                      .then(
                          _ => {

                            this.fileOpener.showOpenWithDialog(this.file.dataDirectory + fileName, 'text/csv')
                                .then(() => console.log('File is opened'))
                                .catch(e => console.log('Error opening file', e));
                          }
                      )
                      .catch(
                          err => {
                            this.file.writeExistingFile(this.file.dataDirectory, fileName, fileContent)
                                .then(
                                    _ => {
                                      console.log(this.file.dataDirectory);

                                      this.fileOpener.showOpenWithDialog(this.file.dataDirectory + fileName, 'text/csv')
                                          .then(() => console.log('File is opened'))
                                          .catch(e => console.log('Error opening file', e));
                                    }
                                )
                                .catch(
                                    err => {
                                      alert('Failure');
                                    }
                                );
                          }
                      );
                }
                // Save as for desktop version
                else {
                  saveAs.saveAs(fileContent, fileName);
                }
              }
          );
    }
  }

  emitRefreshSearchId(index: number) {
    this.mySearchesService.emitEventToRefreshSearchId(index);
  }

  protected feMenuItemAction(): void {
    alert('C\'est l\'action !');
  };

  protected readonly alert = alert;
  public isSideNavOpen: boolean = true;
  public sideNavIcon: string = 'keyboard_double_arrow_left';
  public toogleSidenavSize(): void {
    this.isSideNavOpen = !this.isSideNavOpen;
    if (this.isSideNavOpen) {
      this.sideNavIcon = 'keyboard_double_arrow_left';
    } else {
      this.sideNavIcon = 'keyboard_double_arrow_right';
    }

    this.smallSidenavDesktop = !this.smallSidenavDesktop;
    this.appPreferencesService.set('smallSidenavDesktop', this.smallSidenavDesktop.toString());

    setTimeout(() => {
      this.sideNavContainer.updateContentMargins();
    }, 250);
  }

  changeSearchSelection($event: any) {

    let index = 0;
    this.mySearchList.forEach(s => {
      if (this.mySearchList[index].searchId === $event.searchId) {
        return;
      }
      index = index + 1;
    });
    this.setTabCriterions(index);
  }

  findSelectedCriterionType(criterionId, search: SearchDataInterface) {
    if (search.locations && search.locations.includes(criterionId)) {
      return 'locations';
    }
    else if (search.sectors && search.sectors.includes(criterionId)) {
      return 'sectors';
    }
    else if (search.topics && search.topics.includes(criterionId)) {
      return 'topics';
    }
    else if (search.companySizes && search.companySizes.includes(criterionId)) {
      return 'companySizes';
    }
    else if (search.activityTypes && search.activityTypes.includes(criterionId)) {
      return 'activityTypes';
    }
    return '';
  }

  removeElementsFromArray(arr1: any[], arr2: any[]): any[] {
    return arr1.filter(value => !arr2.includes(value));
  }

  changeSelectedCriterion($event: any) {

    const search: SearchDataInterface = this.currentSearch.data;

    const criterionType: string = this.findSelectedCriterionType($event.criterionId, search);

    // Can be the date
    if (criterionType && criterionType.length > 0) {
      // if no selection, change all on to off
      if ($event.checked &&  !this._offCriterions.some(value => search[criterionType].includes(value))) {
      // if ($event.checked && this._offCriterions.length <= 0) {
        let arrayType = Utils.deepCopy(search[criterionType]);
        Utils.transferAllItems(arrayType, this._offCriterions);
        this._criterions = this.removeElementsFromArray(this._criterions, search[criterionType]);
      }

      if ($event.checked) {
        this._offCriterions.splice(this._offCriterions.indexOf($event.criterionId), 1);
        this._criterions.push($event.criterionId);
      }

      let typeWithoutCurrent = Utils.deepCopy(search[criterionType]);
      typeWithoutCurrent.splice(typeWithoutCurrent.indexOf($event.criterionId), 1);

      if (!$event.checked && !this._criterions.some(value => typeWithoutCurrent.includes(value))) {
      // if (!$event.checked && this._criterions.length <= 0) {
        let arrayType = Utils.deepCopy(search[criterionType]);
        Utils.transferAllItems(arrayType, this._criterions);
        this._offCriterions = this.removeElementsFromArray(this._offCriterions, search[criterionType]);
      }
      else if (!$event.checked) {
        this._criterions.splice(this._criterions.indexOf($event.criterionId), 1);
        this._offCriterions.push($event.criterionId);
      }
    }

    Utils.offCriterions = this._offCriterions;

    // Reexecute query
    this.page = 1;
    this.matPaginator.pageIndex = 0;

    this.disableTabs = true;

    this.articlesService.setQuery(this.query);
    this.loadingInProgress = true;
  }

  public listStyleChanged(listStyle: string) {
    super.listStyleChanged(listStyle);
    if (listStyle === 'map' && !this.smallRightSidenavDesktop) {
      this.rightsidenav();
    }
  }

  rightsidenav() {
    this.smallRightSidenavDesktop = !this.smallRightSidenavDesktop;
    setTimeout(() => {
      this.sideNavContainer.updateContentMargins();
    }, 250);
  }

  async addToFavorite() {
    const modal = await this.modalController.create({
      component: AddFavoriteModalComponent,
      cssClass: 'addFavoriteDialog',
      componentProps: {
        articles: this.selectionModel.selected
      }
    });
    modal.onWillDismiss().then(
        (data: any) => {
          if (data.data.isAdd) {

            for (let article of this.getSelectedArticles()) {
              article.favorite = true;
            }

            // this.toastService.simple(this.translateService.instant('ADD_ARTICLE_TO_FAVORITE_SUCCESS'));
            // this.toastService.clickableLink('Favori ajouté avec succès', data.data.libelle,  data.data.folderID, { color: 'toastinfo', });
            this.snackBarService.showSuccessSnackbar('Favoris ajoutés avec succès', 'Favoris ajoutés avec succès');
            this.favoriteService.needRefresh.next(true);
          }
        }
    );
    return await modal.present();

    // this._modalService.showCustomModalWithActionOnClose(AddFavoriteModalComponent, {data: {articles: [this.article.id]}}, (response) => {
    //   if (response != null) {
    //     if (response.isAdd) {
    //       this.article.favorite = true;
    //       this.article.favoriteFolder = response.folderID;
    //       // this.toastService.simple(this.translateService.instant('ADD_ARTICLE_TO_FAVORITE_SUCCESS'));
    //       // this.toastService.clickableLink('Favori ajouté avec succès', data.data.libelle,  data.data.folderID, { color: 'toastinfo', });
    //       this.snackBarService.showSuccessSnackbar('Favori ajouté avec succès',
    //           'L\'article a bien été ajouté au dossier \'' + response.libelle + '\'.');
    //       this.favoritesService.needRefresh.next(true);
    //     }
    //   }
    // });
  }

  protected addArticlesToFavorite(): void {
    if (this.getSelectedArticles().length < 1) {
      this.snackBarService.showDangerSnackbar('Opération impossible', this.noArticleErrMsg);
    } else {
      let articleIds = new Array<number>();
      this.getSelectedArticles().forEach(value => {
        articleIds.push(value.id);
      });
      let data: MatDialogConfig = {data: {articles: articleIds}}
      let title: string = '';
      if (articleIds.length == 1) {
        this.tableDataSource.data.forEach(value => {
          if (value.id == articleIds[0]) {
            title = value.title;
            data.data.title = value.title;
          }
        });
      }
      this._modalService.showCustomModalWithActionOnClose(AddFavoriteModalComponent, data,
          (response) => {
            if (response != null) {
              if (response.isAdd) {
                this.getSelectedArticles().forEach(value => {
                  value.favorite = true;
                  value.favoriteFolder = response.folderID;
                });
                let mess = 'L\'article <b>\'' + this.troncateText(title, 50) + '\'</b>a bien été ajouté';
                if (articleIds.length > 1) {
                  mess = 'Les articles ont bien été ajoutés';
                }
                this.snackBarService.showSuccessSnackbar('Favori ajouté avec succès',
                    mess + ' au dossier <b>\'' +
                    this.getFavoriteFolderLink(response.folderID, response.libelle) + '\'</b>.');
                this.favoriteService.needRefresh.next(true);
                this.selectionModel.clear();
              }
            }
          });
    }
  }

  public getSelectedArticles(): ArticleInterface[] {
    const selectedArticles = [];
    for (let id of this.selectionModel.selected) {
      for (let article of this.tableDataSource.data) {
        if (id === article.id) {
          selectedArticles.push(article);
        }
      }
    }
    return selectedArticles;
  }
  public openEmailModal() {
    if (this.getSelectedArticles().length < 1) {
      this.snackBarService.showDangerSnackbar('Opération impossible', this.noArticleErrMsg);
    } else if (this.getSelectedArticles().length > 10) {
      this.snackBarService.showDangerSnackbar('Envoi impossible', 'Le partage d\'article est ' +
          'limité à un maximum de 10 articles à la fois.');
    } else {
      this._modalService.showCustomModalWithActionOnClose(FeMailModalComponent, {data: {
          articles: this.getSelectedArticles()
        }}, (response: Array<string>) => {
        this.getSelectedArticles().forEach(article => {
          response.forEach(value => {
            if (article.sharedTo == null)
              article.sharedTo = [];
            if (article.sharedTo.indexOf(value) < 0)
              article.sharedTo.push(value);
          });
        });
      });
    }
  }

  public markAsRead(read: boolean) {
    if (this.getSelectedArticles().length < 1) {
      this.snackBarService.showDangerSnackbar('Opération impossible', this.noArticleErrMsg);
    } else {
      for (const a of this.tableDataSource.data) {
        if (this.selectionModel.selected.includes(a.id)) {
          a.read = read;
        }
      }

      this.articlesService.markArticlesAsRead(this.selectionModel.selected, read).pipe(
          takeUntil(this.unsubscribe$)
      ).subscribe(
          res => {
            // If online is 200, update the local data by sending article ID
            for (const articleId of this.selectionModel.selected) {
              this.articlesService.updateReadArticle(articleId, read);
            }
          }
      );
    }
  }

  private troncateText(text: string, length: number): string {
    let troncateText: string = text;
    if (troncateText.length > length + 2) {
      troncateText = troncateText.substring(0, length) + '...';
    }
    return troncateText;
  }

  private getFavoriteFolderLink(folderId: number, folderLabel: string): string {
    return '<b><a class="greenC" href="/#/app/my-favorites?folderID='
        + folderId + '">' + this.troncateText(folderLabel, 40) + '</a></>';
  }

  printArticles() {
    if (this.getSelectedArticles().length < 1) {
      this.snackBarService.showDangerSnackbar('Opération impossible', this.noArticleErrMsg);
    // } else if (this.getSelectedArticles().length > 10) {
    //   this.snackBarService.showDangerSnackbar('Impression impossible', 'L\'impression d\'article ' +
    //       'est imité à un maximum de 10 articles à la fois.');
    } else {
      this.articlesService.printArticlesList(this.selectionModel.selected).pipe(
          takeUntil(this.unsubscribe$)
      ).subscribe(
          (res: PrintInterface) => {
            // Mobile printing
            if (this.platform.is('ios') || this.platform.is('android')) {
              this.printer.isAvailable()
                  .then(onSuccess => {
                      },
                      onError => {
                      }
                  );

              const options: PrintOptions = {
                name: 'FirstECO_print',
                orientation: 'portrait'
              };

              this.printer.print(res.html, options)
                  .then(onSuccess => {
                      },
                      onError => {
                      }
                  );
            }
            // Desktop printing
            else {
              const popupWin = window.open('', '_blank');
              popupWin.document.open();
              popupWin.document.write(res.html);
              setTimeout(() => popupWin.print(), 1000);
            }
          }
      );
    }
  }

  protected readonly IconType = IconType;

  openInfoWindow(mapMarker: MapMarker, infoWindow: MapInfoWindow) {
    if (this.previousIW) {
      this.currentIW = infoWindow;
      this.previousIW.close();
    }
    this.previousIW = infoWindow;
    infoWindow.open(mapMarker);
  }

  getResultFilterLabel(resultFilter: string) {
    switch (resultFilter) {
      case 'default': return 'Aucun';
      case 'isMonitored': return 'Suivis';
      case 'isNotMonitored': return 'Non suivis';
      case 'isFavorite': return 'Favoris';
      case 'isNotFavorite': return 'Non favoris';
      case 'isRead': return 'Lus';
      case 'isNotRead': return 'Non lus';
    }
    return 'Aucun filtre';
  }

  onFilterChange(resultFilter: string) {
    this.resultFilter = resultFilter;
    this.articlesService.setQuery(this.query);
  }

  protected readonly ColorType = ColorType;
}
