<div class="allContactHeader">
    <div class="ficEntHeaderBtnBar" style="flex-grow: 1; display: flex;">
        <span mat-dialog-title>Attention</span>
    </div>

    <button class="allContactCloseBtn" aria-label="close" [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography">
    <p>{{message}}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="confirmCloseContactsBtn" mat-button [mat-dialog-close]="true" mat-dialog-close>Confirmer</button>
    <button class="cancelCloseContactsBtn" mat-button [mat-dialog-close]="false" cdkFocusInitial>Annuler</button>
</mat-dialog-actions>
