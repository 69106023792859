<div class="feContactEnrichiModal feModal">
    <div class="feModalHeader">
        <span class="feModalTitle">{{contact.firstName}} {{contact.lastName}}</span>
        <div class="feModalCloseButton">
            <span mat-dialog-close>Fermer</span>
            <mat-icon mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="feModalContent">
        <p class="bigTitleBold">{{contact.civility}} {{contact.firstName}} {{contact.lastName}}</p>
        <p>{{contact.role}}</p>

        <h1 class="bigTitle">Informations générales</h1>
        <div style="display: flex;flex-direction: column; margin-bottom: 30px;">
            <div class="contactInformationLine">
                <span class="contactInfoLabel">Civilité :</span>
                <span class="contactInfoText">{{contact.civility}}</span>
            </div>
            <div class="contactInformationLine">
                <span class="contactInfoLabel">Prénom :</span>
                <span class="contactInfoText">{{contact.firstName}}</span>
            </div>
            <div class="contactInformationLine">
                <span class="contactInfoLabel">Nom :</span>
                <span class="contactInfoText">{{contact.lastName}}</span>
            </div>
        </div>

        <h1 class="bigTitle">Informations administratives</h1>
        <div style="display: flex;flex-direction: column; margin-bottom: 30px;">
            <div class="contactInformationLine">
                <span class="contactInfoLabel">Intitulé du poste :</span>
                <span class="contactInfoText">{{contact.role}}</span>
            </div>
            <div class="contactInformationLine">
                <span class="contactInfoLabel">Domaine :</span>
                <span class="contactInfoText">{{contact.domain_label}}</span>
            </div>
            <div class="contactInformationLine">
                <span class="contactInfoLabel">Niveau :</span>
                <span class="contactInfoText">{{contact.level_label}}</span>
            </div>
        </div>

        <h1 class="bigTitle">Informations de contacts</h1>
        <div style="display: flex;flex-direction: column; margin-bottom: 30px;">
            <div class="contactInformationLine">
                <span class="contactInfoLabel">Email :</span>
                <span class="contactInfoText" *ngIf="contact.email === null || contact.email === undefined || contact.email.trim().length === 0">NC</span>
                <a class="contactInfoText" href="mailto:{{contact.email}}" *ngIf="contact.email?.trim().length > 0"
                   title="{{contact.email}}">{{contact.email}}</a>
            </div>
            <!--    <div class="contactInformationLine">
                  <span class="contactInfoLabel">Score email :</span>
                  <span class="contactInfoText">{{contact.email_score}}</span>
                </div>-->
            <div class="contactInformationLine">
                <span class="contactInfoLabel" style="height: 26px; line-height: 26px;">LinkedIn :</span>
                <span class="contactInfoText" *ngIf="contact.linkedin_url === null || contact.linkedin_url === undefined
      || contact.linkedin_url.trim().length === 0">NC</span>
                <div class="fe-icon contactInfoText" *ngIf="contact.linkedin_url && contact.linkedin_url != null">
                    <img alt="linkedin" (click)="openUrl(contact.linkedin_url)"
                         src="assets/icon/fe-icons/linkedin.svg" style="margin: initial;"/>
                </div>
            </div>
        </div>

        <fe-button style="margin-left: auto;" mat-dialog-close (click)="close()" text="Fermer la fenêtre"/>
    </div>
</div>
