import { Component, forwardRef, OnInit, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormGroup } from '@angular/forms';
import { XFieldComponent } from '../x-field/x-field.component';
import { FormFieldInterface } from '../../../interfaces/form-field/form-field.interface';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'x-json',
  templateUrl: 'x-json.component.html',
  styleUrls: ['./x-json.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => XJsonComponent), multi: true }
  ]
})
export class XJsonComponent extends XFieldComponent implements OnInit  {

  @Output() public deleteFromXJson: EventEmitter<any> = new EventEmitter<any>();

  public jsonFields: Array<FormFieldInterface>;

  constructor(
    protected matSnackBar: MatSnackBar
  ) {
    super(matSnackBar);
  }

  public ngOnInit(): void {
    this.jsonFields = this.field.schema;

    this.formControl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  public deleteThisJsonField($event: any, field: FormFieldInterface): void {
    $event.stopPropagation();
    this.deleteFromXJson.emit(field);
  }

  public formDataChanged(formGroup: FormGroup): void {
    // TODO: not sure this is very good....
    const timer: any = setTimeout(() => {
      clearTimeout(timer);
      if (formGroup.valid) {
        this.writeValue(formGroup.value);
        this.propagateChange(this.formFieldValue);
      } else {
        this.writeValue(null);
        this.propagateChange(null);
      }
    });

  }


}
