import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {ApiService} from '../api/api.service';
import {FavoritesInterface} from 'src/app/interfaces/favorites/favorites.interface';
import {HttpResponse} from '@angular/common/http';
import {ErrorStatus} from 'src/app/classes/ErrorStatus.class';
import {ArticlesService} from '../articles/articles.service';
import {FavoriteInterface} from 'src/app/interfaces/favorite/favorite.interface';
import {ArticleInterface} from 'src/app/interfaces/articles/article.interface';
import {UserService} from '../user/user.service';
import {FavorisFolder} from '../../interfaces/favoritesFolders/favorites-folders.interface';

@Injectable({
    providedIn: 'root'
})
export class FavoritesService {

    // private needToRefreshFolders = true;
    private folderList: BehaviorSubject<FavorisFolder[]> = new BehaviorSubject(undefined);
    public needRefresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private allFolders: FavorisFolder[] = [];

    constructor(
        private apiService: ApiService
        , private articlesService: ArticlesService
        , private userService: UserService
    ) {
        this.initFolderList();
    }

    public initFolderList() {
        this.getFavoritesFoldersInit().subscribe(value => {
            this.folderList.next(value);
            this.allFolders = value;
        });
    }

    /**
     * Get articles in favorites online
     *
     */
    public getFavorites(query: { [key: string]: string } = {}): Observable<FavoritesInterface> {
        let favorites: FavoritesInterface;
        return this.apiService.get<FavoritesInterface>('v1/favorites', query).pipe(
            switchMap((response: HttpResponse<FavoritesInterface>) => {
                favorites = response.body;
                // v1/favorites return an array of article id in favoritesArticles key.
                // we store this ids to make a request on article endpoint.
                const articlesId: Array<number> = favorites.favoritesArticles.map((favorite: FavoriteInterface) => {
                    return favorite.articleId;
                });

                // request to get article by ids.
                const Query = {
                    articles: articlesId
                };
                return this.articlesService.getArticlesContent(Query);
            }),
            map((articles: Array<ArticleInterface>) => {

                // we had a custom key on FavoriteInterface to store populated articles. (articles_ is define by front. Not a real api key.)
                // we use this "hack" to use paginated list.
                favorites.articles_ = articles;

                // Put data (comment + public or private) in the article
                favorites.articles_.map((article: ArticleInterface, index) => {
                    article.data = favorites.favoritesArticles[index].data;
                });

                // we return favorites with real article populated inside.
                return favorites;
            })
        );
    }

    /**
     * Add article to user favorites
     * @param articleId number : id of the article
     */
    public addArticleToFavorites(article: ArticleInterface): Observable<HttpResponse<any>> {
        // add article to favorites through api
        this.userService.log('ARTICLE_ADD_SELECTION', [article]);

        return this.apiService.post<any>(`v1/favorites`, [article.id]).pipe(
            map((response: HttpResponse<any>) => {
                if (response.status === 200 || response.status === 201) {
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    /**
     * Patch a favorite with public or private comment
     * @param articleId number : id of the article
     */
    public addCommentToFavorite(articleId: number, favoriteData): Observable<HttpResponse<any>> {
        // add comment to favorite through api
        return this.apiService.patch<any>(`v1/favorite/${articleId}`, favoriteData).pipe(
            map((response: HttpResponse<any>) => {
                if (response.status === 200 || response.status === 201) {
                    // Log de l'ouverture d'article
                    this.userService.log('SELECTION_NOTES_EDIT');
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    /**
     * Remove article from user favorites
     * @param articleId number : id of the article
     */
    public removeArticleFromFavorites(articleId: any): Observable<HttpResponse<any>> {
        // remove article to favorites through api
        return this.apiService.deleteBody(`v1/favorites`, articleId).pipe(
            map((response: HttpResponse<any>) => {
                if (response.status === 200) {
                    // update local data with data returned by api
                    this.refreshFolderList();
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }


    /**
     * Méthodes Dossiers de favoris
     */
    public getFavoritesFromFolder(folderID: number, query: { [key: string]: string } = {}): Observable<FavoritesInterface> {
        let favorites: FavoritesInterface;
        return this.apiService.get<FavoritesInterface>(`v1/favorites/folder/${folderID}`, query).pipe(
            switchMap((response: HttpResponse<FavoritesInterface>) => {
                favorites = response.body;
                // v1/favorites return an array of article id in favoritesArticles key.
                // we store this ids to make a request on article endpoint.
                const articlesId: Array<number> = favorites.favoritesArticles.map((favorite: FavoriteInterface) => {
                    return favorite.articleId;
                });
                
                // request to get article by ids.
                const Query = {
                    articles: articlesId
                };
                return this.articlesService.getArticlesContent(Query);
            }),
            map((articles: Array<ArticleInterface>) => {

                // we had a custom key on FavoriteInterface to store populated articles. (articles_ is define by front. Not a real api key.)
                // we use this "hack" to use paginated list.
                favorites.articles_ = articles;

                // Put data (comment + public or private) in the article
                favorites.articles_.map((article: ArticleInterface, index) => {
                    article.data = favorites.favoritesArticles[index].data;
                });
                // we return favorites with real article populated inside.
                return favorites;
            })
        );
    }

    public addArticleInFavoritesFolder(folderID: number, articleId: number): Observable<HttpResponse<any>> {
        return this.apiService.post<HttpResponse<any>>(`v1/favorites/folder/${folderID}/article/${articleId}`).pipe(
            map((response: HttpResponse<HttpResponse<any>>) => {
                if (response.status === 200 || response.status === 201) {
                    this.refreshFolderList();
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }
    public addArticlesInFavoritesFolder(folderID: number, articleIds: Array<number>): Observable<HttpResponse<any>> {
        return this.apiService.post<HttpResponse<any>>(`v1/favorites/add/folder/${folderID}/articles`, articleIds).pipe(
            map((response: HttpResponse<HttpResponse<any>>) => {
                if (response.status === 200 || response.status === 201) {
                    this.refreshFolderList();
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    public removeArticleFromFavoritesFolder(folderID: number, articleId: number): Observable<HttpResponse<any>> {
        return this.apiService.delete<any>(`v1/favorites/folder/${folderID}/article/${articleId}`).pipe(
            map((response: HttpResponse<any>) => {
                if (response.status === 200 || response.status === 201) {
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    public editArticleDataFromFavoris(articleId: number, query: { [key: string]: string } = {}): Observable<HttpResponse<any>> {
        return this.apiService.put<any>(`v1/favorites/article/${articleId}`, query).pipe(
            map((response: HttpResponse<any>) => {
                if (response.status === 200 || response.status === 201) {
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    public addFavoritesFolder(favorisFolder: FavorisFolder): Observable<HttpResponse<FavorisFolder>> {
        const self = this;
        return this.apiService.post<any>(`v1/favorites/folders`, favorisFolder).pipe(
            map((response: HttpResponse<any>) => {
                if (response.status === 200 || response.status === 201) {
                    // self.needToRefreshFolders = true;
                    this.refreshFolderList();
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    public deleteFavoritesFolder(folderID: number): Observable<HttpResponse<any>> {
        // remove article to favorites folder through api
        return this.apiService.deleteBody(`v1/favorites/folder/${folderID}`).pipe(
            map((response: HttpResponse<any>) => {
                if (response.status === 200) {
                    // self.needToRefreshFolders = true;
                    this.refreshFolderList();
                    // update local data with data returned by api
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    public editFavoritesFolder(folderID: number, favorisFolder: FavorisFolder): Observable<FavorisFolder> {
        return this.apiService.put<FavorisFolder>(`v1/favorites/folder/${folderID}`, favorisFolder).pipe(
            map((response: HttpResponse<FavorisFolder>) => {
                if (response.status === 200 || response.status === 201) {
                    // self.needToRefreshFolders = true;
                    this.refreshFolderList();
                    return response.body;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    public getFavoritesFolders(): BehaviorSubject<FavorisFolder[]> {
        // if (this.needToRefreshFolders) {
        //     this.initFolderList();
        // }
        return this.folderList;
    }

    public getFavoritesFoldersInit(): Observable<Array<FavorisFolder>> {
        // if (this.needToRefreshFolders) {
        //     this.needToRefreshFolders = false;
        let res: Array<FavorisFolder>;
        return this.apiService.get<Array<FavorisFolder>>('v1/favorites/folders').pipe(
            map((response: HttpResponse<Array<FavorisFolder>>) => {
                if (response.status === 200 || response.status === 201) {
                    res = response.body;
                    return res;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
        // }
    }

    public moveArticleInFavoritesToOtherFolder(favorisFolderID: number, articleID: number): Observable<HttpResponse<any>> {
        return this.apiService.put<any>(`v1/favorites/folder/article/${articleID}?favorisFolderID=${favorisFolderID}`, {}).pipe(
            map((response: HttpResponse<any>) => {
                if (response.status === 200) {
                    // update local data with data returned by api
                    this.refreshFolderList();
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    public moveArticlesInFavoritesToOtherFolder(favorisFolderID: number, articleID: number): Observable<HttpResponse<any>> {
        return this.apiService.put<any>(`v1/favorites/folder/articles/${articleID}?favorisFolderID=${favorisFolderID}`, {}).pipe(
            map((response: HttpResponse<any>) => {
                if (response.status === 200) {
                    // update local data with data returned by api
                    this.refreshFolderList();
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    public putFolderInScope(folderId: number): Observable<HttpResponse<number>> {

        return this.apiService.post<any>(`v1/scope/${folderId}`).pipe(
            map((response: HttpResponse<any>) => {
                if (response.status === 200 || response.status === 201) {
                    return response.body;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    private refreshFolderList(): void {
        this.getFavoritesFoldersInit().subscribe(value => {
            this.folderList.next(value);
        });
    }

}
