<div class="feMailModal feModal">
    <div class="feModalHeader">
        <span class="feModalTitle">{{modalTitle}}</span>
        <div class="feModalCloseButton">
            <span mat-dialog-close>Fermer</span>
            <mat-icon mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="feModalContent">
        <div class="shadowBloc">
            <div class="shadowBlocTitle">
                <span class="bigTextBold">Carnet d'adresses</span>
            </div>
            <div class="shadowBlocContent">
                <div class="searchDestinataireBloc">
                    <div class="feSearchBar">
                        <mat-icon>search</mat-icon>
                        <input placeholder="Rechercher un destinataire..." [formControl]="contactControl"
                               [matAutocomplete]="autoCompAddresses" [autofocus]="false">
                    </div>

                    <mat-autocomplete #autoCompAddresses="matAutocomplete" class="searchContactAutoComp">
                        <mat-option *ngFor="let contact of filteredContacts | async" class="contactOption"
                                    (click)="addContactToMail(contact)" [title]="getContactTitle(contact)">
                            <span [innerHTML]="getContactFormatLabel(contact)" class="contactName"></span>
                        </mat-option>
                    </mat-autocomplete>
                    <fe-button text="Carnet d'adresses" type="SECONDARY" bold="true" (click)="openCarnetAdresses()"/>
                </div>
                <div class="destinatairesContainer">
                    <mat-chip-grid>
                        <mat-chip (removed)="removeAddress(address.addressId)" class="destinataireAdded"
                        *ngFor="let address of contactsArray">
                            <span class="bigTextBold">{{address.data.label}}</span>
                            <span class="bigText">({{address.data.email}})</span>
<!--                  TODO METTRE PLUS TARD          <mat-icon>forward_to_inbox</mat-icon>-->
                            <mat-icon matChipRemove>close</mat-icon>
                        </mat-chip>
                    </mat-chip-grid>
                </div>
            </div>
        </div>
        <div class="shadowBloc">
            <div class="shadowBlocTitle">
                <fe-toggle [(value)]="showUnknownDestinataireContainer"/>
                <span class="bigTextBold">Ajouter un destinataire non présent dans la liste</span>
            </div>
            <div *ngIf="showUnknownDestinataireContainer" class="shadowBlocContent unknowDestinataires">
                <div class="unknowDestinataire" *ngFor="let unknowDestinataire of unknowDestinataires;">
                    <div class="unknowDestinataireText">
                        <fe-input (keydown)="onEditUnknownDestinataire(unknowDestinataire)"
                                  [(value)]="unknowDestinataire.data.email"
                                  placeHolder="Adresse email" width="350px"
                                  (focusout)="updateMailErrorMsg(unknowDestinataire)"/>
                        <fe-checkbox (valueChange)="unknowDestinataire.data.saveContact = $event"
                                     bold="true" text="Ajouter au carnet d’adresses"/>
                        <mat-icon *ngIf="isLastDestinataire(unknowDestinataire)"
                                  (click)="removeUnknownDestinataire(unknowDestinataire.addressId)">close</mat-icon>
                    </div>
                    <span *ngIf="unknowDestinataire?.haveErrors == true && isLastDestinataire(unknowDestinataire)"
                          class="moderateText dangerColor">
                        Le format de l'adresse mail n'est pas correct.
                    </span>
                </div>
            </div>
        </div>
        <div class="shadowBloc">
            <div class="shadowBlocTitle">
                <span class="bigTextBold">Objet de l’e-mail</span>
            </div>
            <div class="shadowBlocContent">
                <fe-input width="100%" [(value)]="object" placeHolder="Article partagé par First ECO"/>
            </div>
        </div>
        <div class="shadowBloc">
            <div class="shadowBlocTitle">
                <span class="bigTextBold">Liste des articles partagés</span>
            </div>
            <div class="shadowBlocContent">
                <div class="mailArticleItem" *ngFor="let article of articles; let i = index;">
                    <div class="mailArticleItemHeader">
                        <div class="fe-icon">
                            <img src="assets/icon/fe-icons/fe_arrow.svg">
                        </div>
                        <span class="bigText">{{article.title}}</span>
                        <div class="mailArticleItemHeaderOptions">
<!--                       TODO METTRE PLUS TARD     <mat-icon>forward_to_inbox</mat-icon>-->
                            <mat-icon *ngIf="articles.length > 1" (click)="removeArticle(article)"
                                      class="deleteIcon">close</mat-icon>
                        </div>
                    </div>
                    <fe-input inputId="feArtComment{{article.id}}" placeHolder="Ajouter un commentaire..." width="100%"/>
                </div>
            </div>
        </div>
        <fe-toggle [(value)]="showMailApercu" class="showApercuToogle" text="Afficher l'aperçu" textDirection="LEFT"/>
        <div class="apercuContainer" [class.hideApercu]="!showMailApercu">
            <div class="stickyButtons">
                <fe-button hideText="false" icon="true" bigIcon="true" color="DANGER" [iconType]="IconType.highlight"
                           (click)="highlightSelection()" matTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="Surligner"/>
                <fe-button hideText="false" icon="true" bigIcon="true" color="DANGER" [iconType]="IconType.erase"
                           (click)="removeHighlightSelection()" matTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="Retirer le surlignage"/>
            </div>
            <div #htmlDiv class="htmlMailContainer" [innerHTML]="html | safe: 'html'"></div>

            <fe-loader *ngIf="loadingPreview" isOverflowing="true"/>
        </div>
    </div>
    <div class="feModalFooter">
        <div class="getACopyContainer">
            <mat-checkbox [(ngModel)]="receiveACopy" class="fe-checkbox"/>
            <span (click)="receiveACopy = !receiveACopy" class="bigTextBold">Recevoir une copie de l’email</span>
        </div>
        <span class="bigText marginLeftAuto">{{getResumeCountLabel()}}</span>
        <fe-button (click)="sendMail()" text="Envoyer"/>
    </div>
</div>
