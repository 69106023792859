
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ModalController, NavParams, ToastController} from '@ionic/angular';
import {MediaQueryService} from '../../../services/media-query/media-query.service';
import {ToastService} from '../../../services/toast/toast.service';
import {FavoritesService} from '../../../services/favorites/favorites.service';
import {FavorisFolder} from '../../../interfaces/favoritesFolders/favorites-folders.interface';
import {MatRadioChange} from '@angular/material/radio';
import {ArticleInterface} from '../../../interfaces/articles/article.interface';

@Component({
  selector: 'app-add-favorite-folder-modal',
  templateUrl: './add-favorite-folder-modal.component.html',
  styleUrls: ['./add-favorite-folder-modal.component.scss'],
})
export class AddFavoriteFolderModalComponent implements OnInit {

  public isCreateFolder = false;
  @ViewChild('selectedFolder', {static: true}) selectedFolder: ElementRef<HTMLSelectElement>;
  @ViewChild('createdFolder', {static: true}) createdFolder: ElementRef<HTMLInputElement>;
  public _favoriteFolders: FavorisFolder[];
  private article: ArticleInterface;
  public get favoriteFolders() {
    return this._favoriteFolders;
  }
  public set favoriteFolders(favoriteFolders: FavorisFolder[]) {
    this._favoriteFolders = favoriteFolders;
  }

  constructor(
      private modalController: ModalController
      , private navParams: NavParams
      , public mediaQueryService: MediaQueryService
      , public toastController: ToastController
      , public toastService: ToastService
      , public favoriteService: FavoritesService
  ) {
    this.favoriteService.getFavoritesFolders().subscribe((data: Array<FavorisFolder>) => {
      this._favoriteFolders = data;
    });
  }

  ngOnInit() {
    this.article = this.navParams.get('article');
  }
  /**
   * Close the modal
   */
  public close(): void {
    this.modalController.dismiss();
  }

  addFavorite() {
      const folder: FavorisFolder = {
        libelle : this.createdFolder.nativeElement.value
      };
      this.favoriteService.addFavoritesFolder(folder).subscribe(value => {
        this.close();
      });
  }

  checkSelectFolder($event: MatRadioChange) {
    this.isCreateFolder = !$event.source.checked;
  }

  checkCreateFolder($event: MatRadioChange) {
    this.isCreateFolder = $event.source.checked;
  }

}
