import {Component, OnInit, ViewChild} from '@angular/core';
import {AlertController, ModalController, NavController, Platform} from '@ionic/angular';
import {AuthService} from '../../../services/auth/auth.service';
import {ToastService} from '../../../services/toast/toast.service';
import {AppPreferencesService} from '../../../services/app-preferences/app-preferences.service';
import {MediaQueryService} from '../../../services/media-query/media-query.service';
import {ApiService} from '../../../services/api/api.service';
import {UserService} from '../../../services/user/user.service';
import {ActivatedRoute} from '@angular/router';
import {Observable, Subject, throwError} from 'rxjs';
import {Utils} from '../../../classes/Utils';
import {XFormComponent} from '../../x-form/x-form.component';
import {FormFieldInterface} from '../../../interfaces/form-field/form-field.interface';
import {FormGroup} from '@angular/forms';
import {LoginInterface} from '../../../interfaces/login/login.interface';
import {catchError, takeUntil, tap} from 'rxjs/operators';
import {ReinitPasswordComponent} from '../reinit-password/reinit-password.component';
import {SessionInterface} from '../../../interfaces/session/session.interface';
import {ModalService} from "../../../services/modal/modal.service";
import {SnackbarService} from "../../../services/snackbar/snackbar.service";

@Component({
  selector: 'app-external-login',
  templateUrl: './external-login.component.html',
  styleUrls: ['./external-login.component.scss'],
})
export class ExternalLoginComponent implements OnInit {

  constructor(
      private navController: NavController
      , private authService: AuthService
      , private toastService: ToastService
      , private snackbarService: SnackbarService
      , private platform: Platform
      , private appPreferencesService: AppPreferencesService
      , public mediaQueryService: MediaQueryService
      , private apiService: ApiService
      , private userService: UserService
      , private modalController: ModalController
      , private alertController: AlertController
      , private route: ActivatedRoute
      , protected modalService: ModalService
  ) {}

  public static url: string;
  private overwatch: NodeJS.Timeout;

  private unsubscribe$: Subject<any> = new Subject<any>();

  public isMobile: boolean = false;

  public token = Utils.userToken;

    public email: string = '';

    public password: string = '';

  // @ViewChild(XFormComponent, {static: false}) public formComponent: XFormComponent;
  //
  // public fields: Array<FormFieldInterface> = [
  //   {
  //     type: 'email',
  //     template: 'default',
  //     placeholder: 'Identifiant *',
  //     label: 'Identifiant *',
  //     name: 'identifier',
  //     default: '',
  //     matLeadingIcon: 'person',
  //     validators: {
  //       required: true,
  //     },
  //     readonly: false
  //   },
  //   {
  //     type: 'password',
  //     template: 'default',
  //     placeholder: 'Mot de passe *',
  //     label: 'Mot de passe *',
  //     default: '',
  //     name: 'password',
  //     matLeadingIcon: 'lock',
  //     validators: {
  //       required: true
  //     },
  //     readonly: false
  //   }
  // ];

  public formSubmitMethod: Function;

  public displayAutoConnectLoader: boolean = false;

  ngOnInit(): void {
    // this.formSubmitMethod = (formGroup: FormGroup): Observable<any> => {
    //   const loginData: LoginInterface = formGroup.value;
    //   // loginData.identifier = loginData.identifier.toLowerCase();
    //   return this.login(loginData);
    // };

    setTimeout(() => {
      this.appPreferencesService.get('loginData')
          .then((loginDataStr: string) => {
            if (typeof loginDataStr !== 'undefined' && loginDataStr !== null) {
              const loginData: LoginInterface = JSON.parse(loginDataStr);
              // loginData.identifier = loginData.identifier.toLowerCase();
              this.appPreferencesService.getValue('userToken').then((token: string) => {
                if (token === Utils.userToken || this.isMobile) {
                  this.displayAutoConnectLoader = true;
                  this.login(loginData).pipe(
                      takeUntil(this.unsubscribe$)
                  )
                      .subscribe(
                          () => {this.displayAutoConnectLoader = false; },
                          (error: Error) => {
                            this.displayAutoConnectLoader = false;
                            this.toastService.simple('Identifiant ou mot de passe incorrect', {duration: 2500, color: 'toasterror'});
                            // Log de l'erreur
                            this.userService.log('ERROR_ACCESS');
                          }
                      );
                }  else {
                  // Cas où le token n'est pas défini ou vide Cas ou n'est pas le bon
                  this.userService.log('UNKNOWN_TOKEN_ACCESS');
                }
              });
            }
          })
          .catch((error: Error) => {
            alert('error getting storage ' + error);
          });

      this.formSubmitMethod = (formGroup: FormGroup): Observable<any> => {
        const loginData: LoginInterface = formGroup.value;
        // loginData.identifier = loginData.identifier.toLowerCase();
        return this.login(loginData);
      };
    }, 1000);
  }
    loginClick() {
        const loginData: LoginInterface = {
            identifier: this.email,
            password: this.password,
            external: false
        };
        return this.login(loginData).subscribe();
    }

  async reinitPassword() {
    // // this.navController.navigateForward('/app/business-signals?search=' + search.searchId);
    // const modal = await this.modalController.create({
    //   component: ReinitPasswordComponent,
    //   componentProps: {
    //     mail: this.formComponent.formGroup.value['identifier']
    //   },
    // });
    // return modal.present();

      this.modalService.inputModal('Réinitialiser votre mot de passe', ' Indiquez votre adresse email pour recevoir un nouveau mot de passe.',
          'Email', '', this.email,
          (value) => {
              if (!value || value.length <= 0) {
                  this.snackbarService.showDangerSnackbar('Réinitialiser votre mot de passe', 'Vous devez indiquer une adresse email de connexion.');
              }
              else {
                  this.reinitPasswordSend(value);
              }
          });
  }
    reinitPasswordSend(email: String) {
        this.apiService.secureGet('authentification/reinitPassword?email=' + email).subscribe((response: any) => {
            if (response.response.body.success) {
                this.snackbarService.showSuccessSnackbar('Réinitialiser votre mot de passe', 'Un mail vous a été envoyé avec vos nouveaux identifiants.');
            }
            else {
                this.snackbarService.showDangerSnackbar('Réinitialiser votre mot de passe', 'Une erreur s\'est produite durant la réinitialisation du mot de passe.');
            }
        });
    }

  private login(loginData: LoginInterface): Observable<any> {
    const self = this;
    loginData.external = true;
    return this.authService.fetchTokenUsingCredential(loginData).pipe(
        takeUntil(this.unsubscribe$),
        tap(() => {
          // this.toastService.simple(this.translateService.instant('LOGIN_SUCCESS_REDIRECT_TO_HOME'), { duration: 2500 });
          self.authService.authOk = true;
          this.appPreferencesService.setValue('userToken', Utils.userToken);
          // const params = Utils.toParamMap(window.location.href.substring(window.location.href.indexOf('?')));
          // const navOptions: NavigationOptions = {
          //   queryParams: params,
          // };
          this.userService.init();
          Utils.connected = true;
          this.userService.getSessionDatas().pipe(
              takeUntil(this.unsubscribe$)
          )
          .subscribe(
              (sessionData: SessionInterface) => {
                if (sessionData !== undefined) {
                  Utils.userLogin = sessionData.customerDetails.firstname + ' ' + sessionData.customerDetails.lastname;
                }
              }
          );
          this.overwatch = setInterval(() => {
            this.apiService.post('v1/session/ping', {
              'connectFrom': self.isMobile ? 'mobile' : 'web',
              'fingerprint': this.authService.fingerprint
            }).subscribe();
          }, 60000);
          this.authService.setOverwatch(this.overwatch);
          this.modalController.dismiss('true');

        }),
        catchError((error) => {
          try {
            if (!(error.error.error_description === 'User account is locked') && !(error.error.error_description === 'User account is disabled')) {
              this.toastService.simple('Identifiant ou mot de passe incorrect', {duration: 2500, color: 'toasterror'});
            }
            return throwError(error);
          } catch (e) {
            this.toastService.simple('Identifiant ou mot de passe incorrect', {duration: 2500, color: 'toasterror'});
          }
          // Log de l'erreur
          this.userService.log('ERROR_ACCESS');
        })
    );
  }

  public close(): void {
    this.modalController.dismiss();
  }

}
