import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FolderService {

  private _folderId: BehaviorSubject<number> = new BehaviorSubject(undefined);

  public get folderId$(): Observable<number> {
    return this._folderId;
  }

  constructor() { }

  public updateFolderId(folderId: number): void {
    this._folderId.next(folderId);
    // alert('WAZZA !');
  }
}
