<span>{{"Sources : "}}</span>
<span *ngFor="let source of article.sources.sourcesArray; index as i">
  <span *ngIf="i > 0"> // </span>
  <span>
    <a [href]="source.directUrl || source.website" *ngIf="source.directUrl || source.website" target="_blank" (click)="logSourceClick(source, $event)">{{source.name}}</a>
    <a *ngIf="!source.directUrl && !source.website">{{source.name}}</a>
    , {{source.dateOrNumber}} </span>
  <span *ngIf="source.author">, {{source.author}}</span>
</span>
<span> // {{article.sources.signature}}</span>

