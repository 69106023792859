<div class="feFicheEntrepriseModal feModal">
    <div class="feModalHeader">
        <div class="leftPart">
            <div class="firstTitleLine">
                <div style="flex: 1; display: flex">
                    <span class="feModalTitle" title="{{entreprise.name}}">
                        {{entreprise.name}}
                    </span>
                    <div class="fe-icon" title="WebToLead" *ngIf="webToLead && webToLeadCompanies.includes(companyId)"
                             style="margin-right: auto;">
                        <img alt="webtolead" (click)="exportEnterpriseWebToLead()" class="svgBlue"
                         src="assets/icon/fe-icons/web_to_lead.svg"/>
                    </div>
                </div>


                <div class="fe-icon" *ngIf="entreprise.linkedin != null">
                    <img alt="linkedin" (click)="openUrl(entreprise.linkedin)" class="svgBlue"
                         src="assets/icon/fe-icons/linkedin.svg"/>
                </div>
                <div class="fe-icon" *ngIf="entreprise.facebook != null">
                    <img alt="facebook" (click)="openUrl(entreprise.facebook)" class="svgBlue"
                         src="assets/icon/fe-icons/facebook.svg"/>
                </div>
                <div class="fe-icon" *ngIf="entreprise.twitter != null">
                    <img alt="twitter" (click)="openUrl(entreprise.twitter)" class="svgBlue"
                         src="assets/icon/fe-icons/twitter.svg"/>
                </div>
            </div>

            <div class="secondTitleLine">
                <span class="addressLine littleText">
                    {{entreprise.cp}} {{entreprise.ville}}
                </span>
                <div class="websiteLine" *ngIf="entreprise.website != null">
                    <mat-icon>language</mat-icon>
                    <a target="_blank" href="{{entreprise.website}}" (click)="logWebsiteClick()" >{{entreprise.website}}</a>
                </div>
            </div>
        </div>

<!--        TODO REMETTRE Ca quand le scroll fonctionnera correctement-->
        <div class="rightPart">
            <div class="anchorBtn" [class.selectedAnchor]="visibleElementIndex === 0"
                 (click)="scrollToSection('infosJuridiquesBloc')">Infos juridiques</div>
            <div class="anchorBtn" [class.selectedAnchor]="visibleElementIndex === 1"
                 (click)="scrollToSection('contactsBloc')">Contacts</div>
            <div class="anchorBtn" [class.selectedAnchor]="visibleElementIndex === 2"
                 (click)="scrollToSection('signauxBloc')">Signaux</div>
            <div class="anchorBtn" [class.selectedAnchor]="visibleElementIndex === 3"
                 (click)="scrollToSection('etablissementsBloc')">Etablissements</div>
        </div>

        <div class="feModalCloseButton">
            <mat-icon (click)="close()">close</mat-icon>
        </div>
    </div>
    <div class="feModalContent" id="feModalContentId" (scroll)="onFicheScroll($event)">
        <div class="entrepriseBloc blocToScroll" id="infosJuridiquesBloc">
            <div class="entrepriseBlocHeader">
                <span class="bigTextBold">Informations légales</span>
                <span class="updateDate moderateText">Mise à jour le {{entreprise.lastUpdate}}</span>
            </div>
            <div class="entrepriseBlocContent infosLegale">
                <div class="flex1" style="flex-direction: column; gap: 12px; display: flex; overflow: hidden;">
                    <div style="display: flex; overflow: hidden;">
                        <div class="flex1" style="overflow: hidden;">
                            <div class="infoLegaleLine">
                                <span class="moderateTextBold">SIREN :</span>
                                <span class="moderateText">{{entreprise.siren}}</span>
                            </div>
                            <div class="infoLegaleLine">
                                <span class="moderateTextBold">SIRET :</span>
                                <span class="moderateText">{{entreprise.siret}}</span>
                            </div>
                            <div class="infoLegaleLine">
                                <span class="moderateTextBold">Date de création :</span>
                                <span class="moderateText">{{entreprise.dateCreation}}</span>
                            </div>
                            <div class="infoLegaleLine">
                                <span class="moderateTextBold">Code APE :</span>
                                <span class="moderateText">{{getApeText()}}</span>
                            </div>
                            <div class="infoLegaleLine socialNetwork">
                                <span class="moderateTextBold">Réseaux sociaux :</span>
                                <!--                        <div class="fe-icon">-->
                                <!--                            <img alt="youtube" (click)="openUrl(entreprise.facebook)" src="assets/icon/fe-icons/youtube.svg"/>-->
                                <!--                        </div>-->
                                <div class="fe-icon" *ngIf="entreprise.linkedin != null">
                                    <img alt="linkedin" (click)="openUrl(entreprise.linkedin)" class="svgBlue"
                                         src="assets/icon/fe-icons/linkedin.svg"/>
                                </div>
                                <div class="fe-icon" *ngIf="entreprise.facebook != null">
                                    <img alt="facebook" (click)="openUrl(entreprise.facebook)" class="svgBlue"
                                         src="assets/icon/fe-icons/facebook.svg"/>
                                </div>
                                <div class="fe-icon" *ngIf="entreprise.twitter != null">
                                    <img alt="twitter" (click)="openUrl(entreprise.twitter)" class="svgBlue"
                                         src="assets/icon/fe-icons/twitter.svg"/>
                                </div>
                                <span *ngIf="entreprise.linkedin == null && entreprise.facebook == null
                        && entreprise.twitter == null" class="moderateText">NC</span>
                                <!--                        <fe-icon [iconType]="IconType.creditInfo"/>-->
                                <!--                        <fe-icon iconType="creditInfo"/>-->
                            </div>
                            <div class="infoLegaleLine">
                                <span class="moderateTextBold">Téléphone :</span>
                                <a [href]="'tel:' + entreprise.telephone" target="_blank" class="moderateText">
                                    {{entreprise.telephone}}</a>
                            </div>
                            <div class="infoLegaleLine">
                                <span class="moderateTextBold">Mail :</span>
                                <a [href]="'mailto:' + entreprise.mail" target="_blank" class="moderateText"
                                   style="overflow: hidden; text-wrap: nowrap; text-overflow: ellipsis;">
                                    {{entreprise.mail}}</a>
                            </div>
                        </div>
                        <div class="flex1" style="overflow: hidden;">
                            <div class="infoLegaleLine">
                                <span class="moderateTextBold">Statut juridique :</span>
                                <span class="moderateText">{{entreprise.legalStatus}}</span>
                            </div>
                            <div class="infoLegaleLine">
                                <span class="moderateTextBold">N° TVA UE :</span>
                                <span class="moderateText">{{entreprise.vatNumber}}</span>
                            </div>
                            <div class="infoLegaleLine">
                                <span class="moderateTextBold">Capital social :</span>
                                <span class="moderateText">{{entreprise.capital}}</span>
                            </div>
                            <div class="infoLegaleLine">
                                <span class="moderateTextBold">Siège social :</span>
                                <div *ngIf="hasAddress" class="moderateText addressContainer">
                                    <span>{{entreprise.adresse}}</span>
                                    <span>{{entreprise.cp}} {{entreprise.ville}}</span>
                                    <span>{{entreprise.pays}}</span>
                                </div>
                                <span *ngIf="!hasAddress" class="moderateText">NC</span>
                            </div>
                            <div class="infoLegaleLine">
                                <span class="moderateTextBold">Site web :</span>
                                <a [href]="entreprise.website" target="_blank" class="moderateText">{{entreprise.website}}</a>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="entreprise.siren === 'NC'" style="font-weight: bold; font-size: 15px;  text-align: center;">
                        Aucune information supplémentaire n'est disponible pour cette entreprise actuellement.
                    </div>
                </div>


                <div style="min-width: 300px; height: 260px; background: #b2c2b7; display: flex">
                    <google-map [center]="mapCenter" [zoom]="9">
                        <map-marker #mapMarker="mapMarker" [options]="geoloc"></map-marker>
                    </google-map>
<!--                    <span style="margin: auto;">Emplacement carte</span>-->
                    <!--                <agm-map-->
                    <!--                        #agmMap-->
                    <!--                        *ngIf="loadMap && entreprise.latitude != null && entreprise.longitude != null"-->
                    <!--                        style="height: 150px;width: 100%;"-->
                    <!--                        [latitude]="entreprise.latitude"-->
                    <!--                        [longitude]="entreprise.longitude"-->
                    <!--                        [zoom]="9">-->
                    <!--                  <agm-marker [latitude]="entreprise.latitude" [longitude]="entreprise.longitude" [iconUrl]="markerIcon">-->
                    <!--                  </agm-marker>-->
                    <!--                </agm-map>-->

                </div>
            </div>
        </div>

        <mat-accordion class="entrepriseBlocAccordion">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title class="bigTextBold">
                        Chiffres clés{{doesCCHasData()}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="entrepriseBlocContent chiffreCle">
                    <div class="chiffreCleContainer">
                        <div class="chiffreCleLine">
                            <span class="moderateTextBold">Date de clôture des comptes : </span>
                            <span class="moderateText">{{entreprise?.closing_date}}</span>
                        </div>
                        <div class="chiffreCleLine">
                            <span class="moderateTextBold">Effectif {{getEntrepriseEffectifLabel()}} :</span>
                            <span class="moderateText">{{entreprise?.effectif}}</span>
                        </div>
                        <div class="chiffreCleLine">
                            <span class="moderateTextBold">
                                Chiffre d'affaires {{getEntrepriseCALabel()}} :
                            </span>
                            <span class="moderateText">{{entreprise?.chiffreAffaire}}</span>
                        </div>
                        <div class="chiffreCleLine">
                            <span class="moderateTextBold">Résultat net {{getEntrepriseResultatNetLabel()}} :</span>
                            <span class="moderateText">{{entreprise?.resultatNet}}</span>
                        </div>
                    </div>
                    <fe-button (click)="openFinancialGraph()" text="Voir les graphiques"/>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion class="entrepriseBlocAccordion" id="contactsBloc" multi #contactEnrichisAccordion="matAccordion">
            <mat-expansion-panel [expanded]="userService.sessionData.productOfferCode === 'OFFRE_3'">
                <mat-expansion-panel-header>
                    <mat-panel-title class="bigTextBold">
                        {{getContactsEnrichisBlocTitle()}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div [class.displayNone]="contactsEnrichis == null || contactsEnrichis.length == 0 || isLoading"
                     [class.smallAccordion]="userService.sessionData.productOfferCode !== 'OFFRE_3'">
                    <div class="entrepriseBlocContent feTableContainer">
                        <div class="noMoreCreditContainer" *ngIf=" userService.sessionData.productOfferCode === 'OFFRE_3'
                        &&  userService.sessionData.productStatus === 'customer' && creditCount < 201 && creditCount > -1"
                             style="text-align: right; padding: 0 10px; font-style: italic;">
                            <span *ngIf="creditCount > 0">Il vous reste {{creditCount}} crédits.</span>
                            <span *ngIf="creditCount === 0">Vous n'avez plus de crédits.</span>
                            <span> Pensez à prendre contact avec votre chargé d'affaires pour recharger votre solde.</span>
                        </div>

                        <fe-searchbar placeHolder="Rechercher un contact..." (valueChange)="applyContactEnrichisFilter($event)"
                                      style="width: 300px;" thinSearchBar="true"/>
                        <div class="paginationContainer compactPaginator">
                        <span class="paginationCount">Nous avons trouvé {{contactsEnrichis.length}}
                            contact{{contactsEnrichis.length > 1 ? 's': ''}} pour cette organisation.</span>
                            <span style="margin: auto 0; font-size: 13px;">Contacts</span>
                            <mat-paginator [length]="contactsEnrichis.length" [pageSizeOptions]="[10, 25, 50 ,100]"
                                           aria-label="Select page" pageSize="25"  #contactsEnrichisPaginator>
                            </mat-paginator>
                        </div>
                        <div class="feTable" style="max-height: 400px;">
                            <table mat-table [dataSource]="contactsEnrichisDataSource" class="mat-elevation-z8 tableShadow"
                                   matSort #contactEnrichiMatSort="matSort">

                                <ng-container matColumnDef="role">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef>RÔLE</th>
                                    <td mat-cell *matCellDef="let contactEnrichi">
                                        <span class="moderateTextBold">{{contactEnrichi.role}}</span>
                                        <span class="moderateText">{{contactEnrichi.domain_label}}</span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="name">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef>NOM</th>
                                    <td mat-cell *matCellDef="let contactEnrichi" [class.blured]="contactEnrichi.anonymized">
                                        {{contactEnrichi.firstName}} {{contactEnrichi.lastName}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="linkedin">
                                    <th mat-header-cell *matHeaderCellDef>LINKEDIN</th>
                                    <td mat-cell *matCellDef="let contactEnrichi" [class.blured]="contactEnrichi.anonymized">
                                        <div class="fe-icon" *ngIf="contactEnrichi.linkedin_url != null">
                                            <img alt="linkedin" (click)="openContactEnrichiLinkedin(contactEnrichi)" src="assets/icon/fe-icons/linkedin.svg"/>
                                        </div>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="mail">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef>MAIL</th>
                                    <td mat-cell *matCellDef="let contactEnrichi" [class.blured]="contactEnrichi.anonymized">
                                        {{contactEnrichi.email}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="pertinence">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <img alt="linkedin" class="mailPertinenceLogo" [matMenuTriggerFor]="mailPertinenceInfo"
                                             src="assets/icon/fe-icons/credit_info.svg"/>
                                        <mat-menu #mailPertinenceInfo="matMenu" xPosition="before"
                                                  class="mailPertinenceMenu">
                                            <div class="mailPertinencePopup">
                                                <div class="mailPertinencePopupHeader">
                                                    <span>La couleur de la pastille indique la pertinence de l'email</span>
                                                </div>
                                                <span style="display:block;" class="fe-divider"></span>
                                                <div class="mailPertinencePopupContent">
                                                    <div class="mailLedLine">
                                                        <div class="mailLed mailLed-green"></div>
                                                        <span>Email validé</span>
                                                    </div>
                                                    <div class="mailLedLine">
                                                        <div class="mailLed mailLed-yellow"></div>
                                                        <span>Email détecté sur le web</span>
                                                    </div>
                                                    <div class="mailLedLine">
                                                        <div class="mailLed mailLed-orange"></div>
                                                        <span>Email non validé</span>
                                                    </div>
                                                    <div class="mailLedLine">
                                                        <div class="mailLed mailLed-grey"></div>
                                                        <span>Pas d'email détecté</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-menu>
                                    </th>
                                    <td mat-cell *matCellDef="let contactEnrichi">
                                        <div [ngSwitch]="contactEnrichi.color">
                                            <div *ngSwitchCase="'green'">
                                                <div class="mailLed mailLed-green"></div>
                                            </div>
                                            <div *ngSwitchCase="'yellow'">
                                                <div class="mailLed mailLed-yellow"></div>
                                            </div>
                                            <div *ngSwitchCase="'orange'">
                                                <div class="mailLed mailLed-orange"></div>
                                            </div>
                                            <div *ngSwitchDefault>
                                                <div class="mailLed mailLed-grey"></div>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>


                                <ng-container matColumnDef="credit">
                                    <th mat-header-cell *matHeaderCellDef style="font-weight: bold;">{{getCreditCount()}}</th>
                                    <td mat-cell *matCellDef="let contactEnrichi">
                                        <fe-button (click)="askForloadOneContactEnrichi(contactEnrichi)" type="TERTIARY"
                                                   *ngIf="contactEnrichi.anonymized &&
                                               userService.sessionData.productOfferCode === 'OFFRE_3'"
                                                   text="Voir pour 1 crédit" [smallButton]="true"/>
                                        <fe-button *ngIf="!contactEnrichi.anonymized &&
                                    userService.sessionData.productOfferCode === 'OFFRE_3'"
                                                   text="Voir la fiche" [smallButton]="true"
                                                   (click)="showContactFiche(contactEnrichi)"/>
                                        <fe-button *ngIf="contactEnrichi.anonymized &&
                                    userService.sessionData.productOfferCode !== 'OFFRE_3'"
                                                   text="Voir le contact" [smallButton]="true"/>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="contactEnrichiColumn; sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: contactEnrichiColumn;"></tr>

                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell noContactEnrichiTd" colspan="6">{{getNoContactEnrichiMsg()}}</td>
                                </tr>
                            </table>
                            <div class="contactEnrichisDisabledFilter"
                                 *ngIf="userService.sessionData.productOfferCode !== 'OFFRE_3'"></div>
                        </div>
                    </div>
                    <div class="unAllowedWindow" *ngIf="userService.sessionData.productOfferCode !== 'OFFRE_3'">
                        <div class="unAllowedModal">
                            <span class="mainTitle">Fonctionnalité indisponible</span>
                            <span class="fe-divider"></span>
                            <span class="bigText">
                            La fonctionnalité “Contacts enrichis” n’est pas disponible avec votre offre actuelle.
                            </span>
                            <fe-button text="Contacter mon conseiller dédié" (click)="askForContact()"/>
                        </div>
                    </div>
                </div>
                <div [class.displayNone]="contactsEnrichis?.length > 0 || isLoading" class="noArticleContactsContainer">
                    <span class="moderateText">Aucun contact enrichi trouvé pour cet entreprise</span>
                </div>
                <fe-loader *ngIf="isLoading" loaderText="Chargement en cours"/>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion class="entrepriseBlocAccordion" #contactAccordion="matAccordion" multi>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title class="bigTextBold">
                        {{getArticleContactsBlocTitle()}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="entrepriseBlocContent feTableContainer"
                     [class.displayNone]="articleContacts == null || articleContacts.length == 0">
                    <div class="feTable">
                        <table mat-table [dataSource]="articleContactsDataSource" class="mat-elevation-z8 tableShadow"
                               matSort #articleContactMatSort="matSort">

                            <ng-container matColumnDef="nameAndFunctions">
                                <th class="moderateText" mat-sort-header mat-header-cell *matHeaderCellDef>NOM ET FONCTIONS</th>
                                <td class="moderateText" mat-cell *matCellDef="let contact">
                                    <div style="display: flex;">
                                        <fe-icon *ngIf="webToLead && webToLeadCompanies.includes(companyId)"
                                                 [iconType]="IconType.web_to_lead" style="cursor: pointer; margin-right: 4px;"
                                                 (click)="exportContactWebToLead(contact, $event)" matTooltip="WebToLead"/>
                                        <span>{{(contact.nameAndFunctions)}}</span>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="mail">
                                <th class="moderateText" mat-sort-header mat-header-cell *matHeaderCellDef>MAIL</th>
                                <td class="moderateText" mat-cell *matCellDef="let contact"> {{contact.mail}} </td>
                            </ng-container>

                            <ng-container matColumnDef="phone">
                                <th class="moderateText" mat-sort-header mat-header-cell *matHeaderCellDef>TÉLÉPHONE</th>
                                <td class="moderateText" mat-cell *matCellDef="let contact"> {{contact.phone}} </td>
                            </ng-container>

                            <ng-container matColumnDef="networks">
                                <th class="moderateText" mat-header-cell *matHeaderCellDef>RÉSEAUX SOCIAUX</th>
                                <td class="moderateText" mat-cell *matCellDef="let contact">
                                    <div class="fe-icon" *ngIf="contact.linkedin != null">
                                        <img alt="linkedin" (click)="openUrl(contact.linkedin)" src="assets/icon/fe-icons/linkedin.svg"/>
                                    </div>
                                    <div class="fe-icon" *ngIf="contact.twitter != null">
                                        <img alt="linkedin" (click)="openUrl(contact.twitter)" src="assets/icon/fe-icons/twitter.svg"/>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="articleContactColumn; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: articleContactColumn;"></tr>
                        </table>
                    </div>
                </div>
                <div [class.displayNone]="articleContacts?.length > 0" class="noArticleContactsContainer">
                    <span class="moderateText">Aucun contact n’est associé à cet article</span>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion class="entrepriseBlocAccordion">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title class="bigTextBold">
                        {{getPreviousContactsBlocTitle()}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div [class.displayNone]="contacts == null || contacts.length == 0"
                     class="entrepriseBlocContent feTableContainer">
                    <fe-searchbar placeHolder="Rechercher un contact..." (valueChange)="applyPreviousContactsFilter($event)"
                                  style="width: 300px;" thinSearchBar="true"/>
                    <div class="paginationContainer compactPaginator">
                        <span class="paginationCount">
                            Nous avons trouvé {{contacts.length}}
                            contact{{contacts.length > 1 ? 's': ''}} issus d'articles précédents pour cette organisation.
                        </span>
                        <span style="margin: auto 0; font-size: 13px;">Contacts</span>
                        <mat-paginator pageSize="5" [length]="contacts.length" [pageSizeOptions]="[10, 25, 50 ,100]"
                                       aria-label="Select page" #previousContactPaginator>
                        </mat-paginator>
                    </div>
                    <div class="feTable">
                        <table mat-table [dataSource]="contactDataSource" class="mat-elevation-z8 tableShadow"
                               matSort #contactMatSort="matSort">

                            <ng-container matColumnDef="nameAndFunctions">
                                <th class="moderateText" mat-sort-header mat-header-cell *matHeaderCellDef>NOM ET FONCTIONS</th>
                                <td class="moderateText" mat-cell *matCellDef="let contact">{{contact.nameAndFunctions}}</td>
                            </ng-container>

                            <ng-container matColumnDef="mail">
                                <th class="moderateText" mat-sort-header mat-header-cell *matHeaderCellDef>MAIL</th>
                                <td class="moderateText" mat-cell *matCellDef="let contact"> {{contact.mail}} </td>
                            </ng-container>

                            <ng-container matColumnDef="phone">
                                <th class="moderateText" mat-sort-header mat-header-cell *matHeaderCellDef>TÉLÉPHONE</th>
                                <td class="moderateText" mat-cell *matCellDef="let contact"> {{contact.phone}} </td>
                            </ng-container>

                            <ng-container matColumnDef="networks">
                                <th class="moderateText" mat-header-cell *matHeaderCellDef>RÉSEAUX SOCIAUX</th>
                                <td class="moderateText" mat-cell *matCellDef="let contact">
                                    <div class="fe-icon" *ngIf="contact.linkedin != null">
                                        <img alt="linkedin" (click)="openUrl(contact.linkedin)" src="assets/icon/fe-icons/linkedin.svg"/>
                                    </div>
                                    <div class="fe-icon" *ngIf="contact.twitter != null">
                                        <img alt="linkedin" (click)="openUrl(contact.twitter)" src="assets/icon/fe-icons/twitter.svg"/>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="contactColumn; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: contactColumn;"></tr>
                        </table>
                    </div>

                </div>
                <div [class.displayNone]="contacts?.length > 0" class="noArticleContactsContainer">
                    <span class="moderateText">Aucun contact issu d’articles précédents</span>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion class="entrepriseBlocAccordion" id="signauxBloc">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title class="bigTextBold">
                        {{getPreviousArticlesBlocTitle()}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="entrepriseBlocContent feTableContainer">
                    <fe-searchbar placeHolder="Rechercher un signal d'affaires..." (valueChange)="applyBusinessSignalsFilter($event)"
                                  style="width: 300px;" thinSearchBar="true"/>
                    <div class="paginationContainer compactPaginator">
                    <span class="paginationCount">
                        Nous avons trouvé {{entrepriseArticles.length}}
                        signa{{entrepriseArticles.length > 1 ? 'ux': 'l'}} d’affaires pour cette organisation.
                    </span>
                        <mat-paginator pageSize="10" [pageIndex]="pageIndex" [pageSizeOptions]="[10, 25, 50 ,100]"
                                       [length]="getFilteredBusinessSignals.length" (page)="handlePageEvent($event)"
                                       aria-label="Select page" #prevSignauxpaginator/>
                    </div>
                    <div class="previousArticleContainer">
                        <fe-previous-article-line [article]="previousArticle" *ngFor="let previousArticle of
                        getFilteredBusinessSignals | slice: startIndex:endIndex" [prospect]="this.userService.sessionData.productStatus === 'prospect'"/>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion class="entrepriseBlocAccordion">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title class="bigTextBold">
                        {{getMandatairesBlocTitle()}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="entrepriseBlocContent feTableContainer"
                     [class.displayNone]="legal_officiers == null || legal_officiers.length == 0">
                    <fe-searchbar placeHolder="Rechercher un mandataire..." (valueChange)="applyMandatairesFilter($event)"
                                  style="width: 300px;" thinSearchBar="true"/>
                    <div class="paginationContainer compactPaginator">
                        <span class="paginationCount">
                            Nous avons trouvé {{legal_officiers.length}}
                            mandataire{{legal_officiers.length > 1 ? 's': ''}} pour cette organisation.
                        </span>
                        <span style="margin: auto 0; font-size: 13px;">Mandataires</span>
                        <mat-paginator pageSize="25" [length]="legal_officiers.length" [pageSizeOptions]="[10, 25, 50 ,100]"
                                       aria-label="Select page" #legalOfficiersPaginator>
                        </mat-paginator>
                    </div>
                    <div class="feTable">
                        <table mat-table [dataSource]="legalOfficierDataSource" class="mat-elevation-z8 tableShadow"
                               matSort #legalOfficierMatSort="matSort">
                            <ng-container matColumnDef="role">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>ROLE</th>
                                <td mat-cell *matCellDef="let mandataire"> {{mandataire.role}} </td>
                            </ng-container>

                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>NOM</th>
                                <td mat-cell *matCellDef="let mandataire"> {{mandataire.name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="startDate">
                                <th mat-header-cell *matHeaderCellDef>DEPUIS LE</th>
                                <td mat-cell *matCellDef="let mandataire"> {{mandataire.role_start_date}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="mandataireColumn; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: mandataireColumn;"></tr>
                        </table>
                    </div>
                </div>
                <div [class.displayNone]="legal_officiers?.length > 0" class="noArticleContactsContainer">
                    <span class="moderateText">Aucun mandataire trouvé pour cet entreprise</span>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion class="entrepriseBlocAccordion" id="etablissementsBloc">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title class="bigTextBold">
                        {{getEtablissementBlocTitle()}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="entrepriseBlocContent feTableContainer"
                     [class.displayNone]="etablissements == null || etablissements.length == 0">
                    <fe-searchbar placeHolder="Rechercher un établissement..." (valueChange)="applyEtablissemntsFilter($event)"
                                  style="width: 300px;" thinSearchBar="true"/>
                    <div class="paginationContainer compactPaginator">
                    <span class="paginationCount">Nous avons trouvé {{etablissements.length}}
                        établissement{{etablissements.length > 1 ? 's': ''}} pour cette organisation.</span>
                        <span style="margin: auto 0; font-size: 13px;">Établissements</span>
                        <mat-paginator pageSize="25" [length]="etablissements.length" [pageSizeOptions]="[10, 25, 50 ,100]"
                                       aria-label="Select page" #etablissementsPaginator>
                        </mat-paginator>
                    </div>
                    <div class="feTable">
                        <table mat-table [dataSource]="etablissementsDataSource" class="mat-elevation-z8 tableShadow"
                               matSort #etablissementsMatSort="matSort">

                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>NOM</th>
                                <td mat-cell *matCellDef="let etablissement"> {{etablissement.name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="adresse">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>ADRESSE</th>
                                <td mat-cell *matCellDef="let etablissement"> {{etablissement.adresse}} </td>
                            </ng-container>

                            <ng-container matColumnDef="siret">
                                <th mat-header-cell *matHeaderCellDef>SIRET</th>
                                <td mat-cell *matCellDef="let etablissement"> {{etablissement.siret}} </td>
                            </ng-container>

                            <ng-container matColumnDef="APE">
                                <th mat-header-cell *matHeaderCellDef>APE</th>
                                <td mat-cell *matCellDef="let etablissement"> {{etablissement.ape}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="etabColumn; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: etabColumn;"></tr>
                        </table>
                    </div>
                </div>
                <div [class.displayNone]="etablissements?.length > 0" class="noArticleContactsContainer">
                    <span class="moderateText">Aucun établissement trouvé pour cet entreprise</span>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
