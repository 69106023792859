<div class="contacts-container" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">

  <div class="entrepriseButtonContainer" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
    <div (click)="openArticleFicheEntreprise()" class="entrepriseButton" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches, 'disableEntreprise' : entreprise.name === 'FirstAO'}">
      <img class="buildingIcon curPoint" src="./assets/icon/entreprise_noir.png">
      <span [innerHTML]="getHighlightedText(entreprise.name)"></span>
<!--      <span>{{getHighlightedText(entreprise.name)}}</span>-->
    </div>
<!--    <button *ngIf="entreprise.website" (click)="openOrganisationWebsite()" class="webMarkerIconContainer">-->
<!--      <img src="./assets/icon/globe_noir_large.png" class="websiteIcon">-->
<!--    </button>-->
<!--    <button *ngIf="entreprise.latitude != null && entreprise.longitude != null" (click)="openEntrepriseLocalisation()"-->
<!--            class="webMarkerIconContainer">-->
<!--      <img src="./assets/icon/marker_noir.png" class="markerIcon">-->
<!--    </button>-->
    <button *ngIf=" webToLeadCompanies.includes(companyId) && false" (click)="exportEntrepriseWebTolead(entreprise, $event)"
            class="webMarkerIconContainer">
      <mat-icon fontSet="material-icons-outlined" style="margin-left: 5px; width: 20px; height:20px;font-size: 20px;"
                class="greyColor curPoint contact-details-icon">cloud_upload
      </mat-icon>
    </button>
  </div>

  <div *ngIf="false">
    <div *ngFor="let etablissement of entreprise.etablissements | slice: 0: sliceLimit;" style="margin-top: 10px;">
      <article-etablissement [entreprise]="entreprise" [etablissement]="etablissement" class="list contacts"
                             [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}"></article-etablissement>
    </div>
  </div>


  <div *ngIf="displayContactBloc(entreprise.contacts) && false"
       style="display: block; margin-left: 20px;">
      <div *ngFor="let contact of entreprise.contacts | slice:0: sliceLimit;" style="display: block; cursor: pointer;"
      (click)="openEntrepriseContacts()">
        <div style="font-size: 14px;">
          <div style="display: inline; vertical-align: super; margin-right: 2px;">
            <div style="display: inline;" *ngIf="contact.name" [innerHTML]="getHighlightedText(contact.name)"></div>
            <div style="display: inline;" *ngIf="contact.function" [innerHTML]="', ' + getHighlightedText(contact.function)"></div>
            <span *ngIf="!contact.name && !contact.function && (contact.phone || contact.mail)">Standard</span>
          </div>
          <mat-icon style="font-size: 21px; height: 21px; width: 21px;" *ngIf="contact.phone">phone</mat-icon>
          <mat-icon style="font-size: 21px; height: 21px; width: 21px;" *ngIf="contact.mail">alternate_email</mat-icon>
          <img style="margin-left: 5px; width: 20px;" class="curPoint" *ngIf="contact.linkedin"
               src="./assets/icon/linkedin2.png">
            <mat-icon *ngIf="webToLead" fontSet="material-icons-outlined" style="margin-left: 5px; width: 20px; height:20px;font-size: 20px;" class="greyColor curPoint contact-details-icon"
                      (click)="exportArticleWebToLead(contact, $event)">cloud_upload</mat-icon>
        </div>
      </div>

      <ion-chip (click)="openEntrepriseContacts()" *ngIf="entreprise.contacts && entreprise.contacts.length > 2 && !condensedMode"
                class="seeMoreContactIcon" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
        <ion-label>...</ion-label>
      </ion-chip>
  </div>

</div>
