<div class="feChangePasswordModal feModal">
    <div class="feModalHeader">
        <span class="feModalTitle">{{reinit ? 'Modifier mon mot de passe' : 'Définir un mot de passe' }}</span>
        <div class="feModalCloseButton" *ngIf="reinit">
            <span mat-dialog-close>Fermer</span>
            <mat-icon mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="feModalContent">
        <div *ngIf="!reinit" class="fePasswordBlock" style="text-align: justify;">
            <p style="font-size: 16px;">
                Nous tenons à vous informer qu'il est désormais obligatoire de définir un mot de passe d'accès à notre application afin de garantir la sécurité de l'accès à nos services. Votre sécurité et la confidentialité de vos informations sont au cœur de nos préoccupations.
            </p>
            <p style="font-size: 16px;">
                Nous vous recommandons vivement de choisir un mot de passe fort, unique et de le conserver en toute sécurité. N'oubliez pas que votre mot de passe est personnel et ne doit pas être partagé. Il est également recommandé de le changer régulièrement pour renforcer la sécurité de votre compte.
            </p>
            <p>
                Si vous rencontrez des difficultés lors de la définition de votre mot de passe ou si vous avez des questions supplémentaires, n'hésitez pas à nous contacter à <a href="mailto:contact@firsteco.fr">contact@firsteco.fr</a> ou au 06 44 64 71 64.
            </p>
            <p style="font-size: 16px;">
                <strong>Pour rappel, votre identifiant de connexion est votre adresse email.</strong>
            </p>
        </div>
        <!--           value=""-->
        <div *ngIf="reinit" class="fePasswordBlock">
            <label>Mot de passe actuel</label><br/>
            <fe-input (keyup)="checkPasswordValidation()" [(value)]="oldPassword" type="password"/>
        </div>
        <div class="fePasswordBlock">
            <label>{{reinit ? 'Nouveau mot de passe' : 'Mot de passe' }}</label><br/>
            <fe-input (keyup)="checkPasswordValidation()" [(value)]="newPassword" type="password"/>
        </div>
        <div class="fePasswordBlock">
            <label>{{ reinit ? 'Confirmer le nouveau mot de passe' : 'Confirmer le mot de passe' }}</label><br/>
            <fe-input (keyup)="checkPasswordValidation()" [(value)]="newPasswordConfirm" type="password"/>
        </div>
        <div id="submit_message">{{this.submitControlMessage}}</div>
    </div>
    <div class="feModalFooter">
        <fe-button text="Fermer" color="DANGER" (click)="close()" *ngIf="reinit"/>
        <fe-button text="Valider" (click)="validate()"/>
    </div>
</div>
