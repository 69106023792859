import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { XFormComponent } from '../../x-form/x-form.component';
import { MediaQueryService } from 'src/app/services/media-query/media-query.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { MailHistoryInterface } from 'src/app/interfaces/mail-history/mail-history.interface';

@Component({
  selector: 'sending-history-detail',
  templateUrl: 'sending-history-detail.modal.html',
  styleUrls: ['sending-history-detail.modal.scss'],
})
export class SendingHistoryDetailModal implements OnInit, OnDestroy {

  protected unsubscribe$: Subject<any> = new Subject<any>();

  @ViewChild(XFormComponent, { static: true }) public formComponent: XFormComponent;

  public mailHistory: MailHistoryInterface;

  public formSubmitMethod: Function;

  constructor(
    private modalController: ModalController
    , private navParams: NavParams
    , public mediaQueryService: MediaQueryService
    , public toastController: ToastController
    , public toastService: ToastService
  ) { }

  public ngOnInit(): void {

    // Get the content with type MailHistoryInterface
    this.mailHistory = this.navParams.get('mail');
  }

  /**
  * Close the modal
  */
  public close(): void {
    this.modalController.dismiss();
  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  adaptTableWidth(html: string) {
    let htmlToReturn: string = html;
    htmlToReturn = htmlToReturn.replace(/width="600"/g, 'width="100%;"');
    htmlToReturn = htmlToReturn.replace(/width="560"/g, 'width="95%;"');
    return htmlToReturn;
  }
}
