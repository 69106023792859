import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { UserService } from 'src/app/services/user/user.service';
import { takeUntil } from 'rxjs/operators';
import { SessionInterface } from 'src/app/interfaces/session/session.interface';

@Component({
  selector: 'user-last-connexion',
  templateUrl: 'user-last-connexion.component.html',
  styleUrls: ['user-last-connexion.component.scss'],
})
export class UserLastConnexionComponent implements OnInit, OnDestroy {

  private unsubscribe$: Subject<any> = new Subject<any>();

  public date: string;

  public locale: any; // prevent build error

  constructor(
    private userService: UserService
  ) {}

  public ngOnInit(): void {
    this.userService.getSessionDatas().pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe((sessionData: SessionInterface) => {
      if (sessionData !== undefined) {
        this.date = (typeof sessionData !== undefined && sessionData !== null && sessionData.lastConnection) ? sessionData.lastConnection : undefined;
      }
    });
  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
