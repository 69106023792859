import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { SearchInterface } from 'src/app/interfaces/search/search.interface';
import { SearchesService } from 'src/app/services/searches/searches.service';

@Component({
  selector: 'toggle-notification-button',
  templateUrl: 'toggle-notification-button.component.html',
  styleUrls: ['toggle-notification-button.component.scss'],
})
export class ToggleNotificationButtonComponent implements OnInit, OnDestroy {

  @Input() public search: SearchInterface;

  // In the my-searches list, ask before deactivate notifications
  @Input() public askBeforeDeactivate: boolean = false;

  private unsubscribe$: Subject<any> = new Subject<any>();

  @Input() public hasNotifications: boolean = false;

  constructor(
    private mySearchesService: SearchesService
  ) { }

  public ngOnInit(): void {
    this.checkNotifications();
  }

  public checkNotifications(): void {
    this.hasNotifications = this.search?.data.alertState;
  }

  public toggleNotifications(): void {
    this.search.data.alertState = !this.hasNotifications;

    this.mySearchesService.editMySearch(this.search.searchId, this.search.data).subscribe(
      (editedSearch: SearchInterface) => {
        this.checkNotifications();
      }
    );
  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
