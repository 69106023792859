<ng-container *ngIf="(currentLevel < 3 && criterionKey == 'sectors') || criterionKey != 'sectors'" >
  <div *ngIf="!mediaQueryService.mobileQuery.matches && criterionKey === 'topics'" style="display: flex;flex-direction: row;">
    <div *ngIf="!mediaQueryService.mobileQuery.matches" style="margin-bottom: 5px;border: 2px solid #E3E3E3;padding:5px;">
      <div>Temporalités First ECO</div>
  <!--    margin-top: -18px;margin-bottom: 5px;">-->
      <div style="display: flex;">
        <ng-container *ngFor="let child of parentCriterion.childCriterions" style="display: inline">
          <div *ngIf="parentCriterion.label == child.label && child.lead !== undefined && child.lead.visible && child.subType == 'temporal'">
            <div [ngClass]="{'leads' : true, 'leadsDisabled' : (child.lead.disabled)}" [title]="child.lead.disabled ? 'La fonctionnalité Leads prédictifs vous permet de sélectionner les événements par temporalité (passé, présent ou à venir), mais aussi des prédictions d\'événements, avec nos préconisations pour passer à l\'action.' : ''">
              <mat-slide-toggle style="width:40px" [checked]="child.checked_ || child.lead.checked" (change)="onSelectedLeads(child, $event)"></mat-slide-toggle>
              <mat-label *ngIf="child.subType == 'temporal'" style="line-height: 24px">{{child.subSubType}}</mat-label>
    <!--          <mat-label *ngIf="child.subType != 'temporal'" style="line-height: 24px">{{child.subType | translate}}</mat-label>-->
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="!mediaQueryService.mobileQuery.matches" style="margin-bottom: 5px;border: 2px solid #c20015;margin-left: 5px;padding:5px;" [title]="leadTitle">
      <div style="color:#c20015;">Leads Prédictifs®</div>
      <div style="display: flex;">
        <ng-container *ngFor="let child of parentCriterion.childCriterions" style="display: inline">
          <div *ngIf="parentCriterion.label == child.label && child.lead !== undefined && child.lead.visible && child.subType != 'temporal'">
            <div [ngClass]="{'leads' : true, 'leadsDisabled' : (child.lead.disabled)}" [title]="child.lead.disabled ? 'La fonctionnalité Leads prédictifs vous permet de sélectionner les événements par temporalité (passé, présent ou à venir), mais aussi des prédictions d\'événements, avec nos préconisations pour passer à l\'action.' : ''">
              <mat-slide-toggle style="width:40px" [checked]="child.checked_ || child.lead.checked" (change)="onSelectedLeads(child, $event)"></mat-slide-toggle>
              <!--          <mat-label *ngIf="child.subType == 'temporal'" style="line-height: 24px">{{child.subSubType | translate}}</mat-label>-->
              <mat-label *ngIf="child.subType != 'temporal'" style="line-height: 24px">{{child.subType}}</mat-label>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="!mediaQueryService.mobileQuery.matches && predictive" style="margin-bottom: 5px;border: 2px solid #E3E3E3;margin-left: 5px;padding:5px;max-width: 750px;display: flex;justify-content: center;align-items: center; ">
      <div >Pour plus de pertinence dans vos recherches, la temporalité “Leads Prédictifs®” ne peut pas être associée aux autres temporalités. Nous vous invitons à créer une recherche supplémentaire portant sur les autres temporalités</div>
    </div>
  </div>
  <div *ngIf="mediaQueryService.mobileQuery.matches && criterionKey === 'topics'" style="display: flex;margin-bottom: 5px;">
    <button style="width: 160px;" mat-icon-button (click)="$event.stopPropagation();" [matMenuTriggerFor]="menu">
      <mat-icon class="greyColor">timeline</mat-icon>
      Prédictions et leads
    </button>
    <mat-menu #menu="matMenu" class="customMenu">
      <div style="display: flex;margin: 5px;">
        <ng-container *ngFor="let child of parentCriterion.childCriterions" style="display: inline">
          <ng-container *ngIf="parentCriterion.label == child.label && child.lead !== undefined && child.lead.visible && child.subType == 'temporal'">
            <div [ngClass]="{'leads' : true, 'leadsDisabled' : (child.lead.disabled)}" [title]="child.lead.disabled ? 'La fonctionnalité Leads prédictifs vous permet de sélectionner les événements par temporalité (passé, présent ou à venir), mais aussi des prédictions d\'événements, avec nos préconisations pour passer à l\'action.' : ''">
              <mat-slide-toggle style="width:40px" [checked]="child.checked_ || child.lead.checked" (change)="onSelectedLeads(child, $event)"></mat-slide-toggle>
              <mat-label *ngIf="child.subType == 'temporal'" style="line-height: 24px">{{child.subSubType}}</mat-label>
<!--              <mat-label *ngIf="child.subType != 'temporal'" style="line-height: 24px">{{child.subType | translate}}</mat-label>-->
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div style="display: flex;margin: 5px;">
        <ng-container *ngFor="let child of parentCriterion.childCriterions" style="display: inline">
          <ng-container *ngIf="parentCriterion.label == child.label && child.lead !== undefined && child.lead.visible && child.subType != 'temporal'">
            <div [ngClass]="{'leads' : true, 'leadsDisabled' : (child.lead.disabled)}" [title]="child.lead.disabled ? 'La fonctionnalité Leads prédictifs vous permet de sélectionner les événements par temporalité (passé, présent ou à venir), mais aussi des prédictions d\'événements, avec nos préconisations pour passer à l\'action.' : ''">
              <mat-slide-toggle style="width:40px" [checked]="child.checked_ || child.lead.checked" (change)="onSelectedLeads(child, $event)"></mat-slide-toggle>
<!--              <mat-label *ngIf="child.subType == 'temporal'" style="line-height: 24px">{{child.subSubType | translate}}</mat-label>-->
              <mat-label *ngIf="child.subType != 'temporal'" style="line-height: 24px">{{child.subType}}</mat-label>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </mat-menu>
  </div>
  
  <ng-container *ngFor="let child of parentCriterion.childCriterions;trackBy: trackByFn">
    <mat-expansion-panel *ngIf="(parentCriterion.label != child.label)" [expanded]="_toExpand.has(child)" [ngClass]="{'highlightItem' : (parentCriterion.label != child.label) && ((_filterCriterion !== undefined && _filterCriterion !== '') && (child.label.toLowerCase().includes(_filterCriterion.toLowerCase()) || filterChildren(child, currentLevel)))}" [disabled]="child.childCriterions?.length > 0 && ((currentLevel < 2  && criterionKey == 'sectors') || criterionKey != 'sectors') ? false : true" hideToggle="false">
      <mat-expansion-panel-header class="expansionHeader">
        <mat-panel-title>
          <mat-checkbox (click)="$event.stopPropagation()"
                        [indeterminate]="descendantsPartiallySelected(child, 0)"
                        (change)="onSelectedChild(child, $event.checked)"
                        [checked]="child.checked_ || isChecked(child)"
                        class="children-list-item">
            {{child.label}}
          </mat-checkbox>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <my-searches-add-edit-children  [expanded]="_toExpand" [filterCriterion]="filterCriterion"
                                        (emitSelection)="onSelectedChildEmit($event, child)"
                                        [criterionKey]="criterionKey" [parentCriterion]="child"
                                        [currentLevel]="currentLevel + 1"
                                        *ngIf="child.childCriterions?.length > 0 && ((currentLevel < 2
                                        && criterionKey == 'sectors') || criterionKey != 'sectors')">
        </my-searches-add-edit-children>
      </ng-template>
    </mat-expansion-panel>
  </ng-container>
</ng-container>
