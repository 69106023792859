import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { Printer, PrintOptions } from '@awesome-cordova-plugins/printer/ngx';
import { ArticleInterface } from 'src/app/interfaces/articles/article.interface';
import { ArticlesService } from 'src/app/services/articles/articles.service';
import { takeUntil } from 'rxjs/operators';
import { PrintInterface } from 'src/app/interfaces/print/print.interface';
import { EmailArticleModal } from '../email-article/email-article.modal';
import { MediaQueryService } from 'src/app/services/media-query/media-query.service';
import {EntrepriseInterface} from '../../../interfaces/entreprise/entreprise.interface';
import {UserService} from '../../../services/user/user.service';

declare var cordova: any;

@Component({
  selector: 'entreprise-contacts',
  templateUrl: 'entreprise-contacts.modal.html',
  styleUrls: ['entreprise-contacts.modal.scss'],
})
export class EntrepriseContactsModal implements OnInit, OnDestroy {

  protected unsubscribe$: Subject<any> = new Subject<any>();

  public article: any; // TODO: Article interface
  // TODO: WARNING different display if we come from article or favorites. Not developped yet.
  public from: string; // article or favorite // display modal for article or favorite

  public entreprise: EntrepriseInterface;

  public locale: any; // prevent build error

  constructor(
    private modalController: ModalController
    , public mediaQueryService: MediaQueryService
    , private platform: Platform
    , private navParams: NavParams
    , private printer: Printer
    , private articlesService: ArticlesService
    , private userService: UserService
  ) { }

  public ngOnInit(): void {
    this.article = this.navParams.get('article');
    this.from = this.navParams.get('from');

    this.userService.log('CLICK_PHONENUMBER', [this.article]);
  }

  public close(): void {
    this.modalController.dismiss();
  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public async openEmailModal(article: ArticleInterface) {
    const modal = await this.modalController.create({
      component: EmailArticleModal,
      cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog',
      componentProps: {
        article: article
      }
    });

    modal.onWillDismiss()
      .then(
        (data: any) => {
          console.log('data', data);
        }
      );

    return await modal.present();
  }

  public openPrintModal(article: ArticleInterface) {
    this.articlesService.printArticlesList([article.id]).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      (res: PrintInterface) => {
        // Mobile printing
        if (this.platform.is('ios') || this.platform.is('android')) {
          this.printer.isAvailable()
            .then(onSuccess => {
              console.log(onSuccess);
            },
              onError => {
                console.log(onError);
              }
            );

          const options: PrintOptions = {
            name: 'FirstECO_Profil_print',
            orientation: 'portrait'
          };

          this.printer.print(res.html, options)
            .then(onSuccess => {
              console.log(onSuccess);
            },
              onError => {
                console.log(onError);
              }
            );
        }
        // Desktop printing
        else {
          const popupWin = window.open('', '_blank');
          popupWin.document.open();
          popupWin.document.write(res.html);
          popupWin.document.close();
          setTimeout(() => popupWin.print(), 1000);
        }
      }
    );
  }

  public openRsLink(rsUrl: string) {
    if (!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
      // if (!this.platform.url().startsWith('http')) {
      cordova.InAppBrowser.open(rsUrl, '_system', 'hidden=yes,location=no');
      // window.open(this.entreprise.website, '_system');
    }
    else {
      window.open(rsUrl, '_blank');
    }
    //  Log - CLICK_RS
    this.userService.log('CLICK_RS');
  }
}
