<main-toolbar  *ngIf="mediaQueryService.mobileQuery.matches"></main-toolbar>
<main-toolbar [sideNav]="snav"  *ngIf="!mediaQueryService.mobileQuery.matches" style="min-height: 71px;"></main-toolbar>

<mat-sidenav-container class="sidenav-container appledebug sidenavBackDrop">

<!--    <mat-sidenav #snav2 style="overflow: visible;" class="left-side-bar"-->
<!--                 [ngClass]="{'small': smallSidenavDesktop && !mediaQueryService.mobileQuery.matches,-->
<!--                  'mobile': mediaQueryService.mobileQuery.matches}"-->
<!--                 [mode]="mediaQueryService.mobileQuery.matches ? 'over' : 'side'"-->
<!--                 [opened]="!mediaQueryService.mobileQuery.matches"-->
<!--                 [disableClose]="!mediaQueryService.mobileQuery.matches"-->
<!--                 [fixedInViewport]="mediaQueryService.mobileQuery.matches">-->
<!--        <div style="text-align: right; width: 100%;">-->
<!--            <fe-button (click)="toogleSidenavSize()" [ngClass]="{'small': smallSidenavDesktop}" type="TERTIARY"-->
<!--                       color="PRIMARY" bold="true" icon="true" iconType="menu_open" [hideText]="smallSidenavDesktop"-->
<!--                       text="Réduire" iconDirection="LEFT" [reverseIcon]="smallSidenavDesktop"-->
<!--                       bigIcon="true" noUnderline="true"></fe-button>-->
<!--        </div>-->
<!--        <search-list-container class="search-list-container" [isSmallMode]="smallSidenavDesktop"-->
<!--                               [searchList]="searchList"></search-list-container>-->
<!--    </mat-sidenav>-->

<!--    <mat-sidenav-content-->
<!--            [ngClass]="{'small': smallSidenavDesktop && !mediaQueryService.mobileQuery.matches,-->
<!--            'smallRight': smallRightSidenavDesktop && !mediaQueryService.mobileQuery.matches,-->
<!--            'mobile': mediaQueryService.mobileQuery.matches}" >-->
        <ion-router-outlet></ion-router-outlet>
<!--    </mat-sidenav-content>-->

<!--    <mat-sidenav position="end" mode="side" opened class="right-side-bar"-->
<!--                 [ngClass]="{'small': smallRightSidenavDesktop && !mediaQueryService.mobileQuery.matches,-->
<!--                  'mobile': mediaQueryService.mobileQuery.matches}">-->
<!--        <div style="width: 100%; display: flex;">-->
<!--            <fe-button (click)="smallRightSidenavDesktop = !smallRightSidenavDesktop"-->
<!--                       [ngClass]="{'small': smallRightSidenavDesktop}" type="TERTIARY"-->
<!--                       color="PRIMARY" bold="true" icon="true" iconType="menu_open" [hideText]="smallRightSidenavDesktop"-->
<!--                       text="Réduire" iconDirection="LEFT" [reverseIcon]="!smallRightSidenavDesktop"-->
<!--                       bigIcon="true" noUnderline="true"></fe-button>-->
<!--            <fe-button *ngIf="!smallRightSidenavDesktop" style="margin-left: auto" text="Modifier ma recherche"-->
<!--            type="SECONDARY" bold="true"></fe-button>-->
<!--        </div>-->
<!--        <app-search-selected-criterions></app-search-selected-criterions>-->
<!--&lt;!&ndash;        <div class="commonContainer">&ndash;&gt;-->
<!--&lt;!&ndash;            <span class="commonContainerTitle">Filtrer par date</span>&ndash;&gt;-->
<!--&lt;!&ndash;            <span class="fe-divider"></span>&ndash;&gt;-->
<!--&lt;!&ndash;            <span>WIDGET DE MARC</span>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="commonContainer">&ndash;&gt;-->
<!--&lt;!&ndash;            <span class="commonContainerTitle">Filtrer les résultats</span>&ndash;&gt;-->
<!--&lt;!&ndash;            <span class="commonContainerSubTitle">&ndash;&gt;-->
<!--&lt;!&ndash;                Ajuster vos paramètres de recherches et affinez vos résultats.&ndash;&gt;-->
<!--&lt;!&ndash;            </span>&ndash;&gt;-->
<!--&lt;!&ndash;            <span class="fe-divider"></span>&ndash;&gt;-->
<!--&lt;!&ndash;            <mat-accordion>&ndash;&gt;-->
<!--&lt;!&ndash;                <mat-expansion-panel *ngFor="let themeElement of themesElements" class="noBoxShadow" hideToggle>&ndash;&gt;-->
<!--&lt;!&ndash;                    <mat-expansion-panel-header>&ndash;&gt;-->
<!--&lt;!&ndash;                        <mat-panel-title>&ndash;&gt;-->
<!--&lt;!&ndash;                            <mat-icon class="themeIcon">{{themeElement.icon}}</mat-icon>&ndash;&gt;-->
<!--&lt;!&ndash;                            <span class="themeName">{{themeElement.name}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                            <span>({{themeElement.count}})</span>&ndash;&gt;-->
<!--&lt;!&ndash;                            <mat-icon class="expandIcon">expand_more</mat-icon>&ndash;&gt;-->
<!--&lt;!&ndash;                        </mat-panel-title>&ndash;&gt;-->
<!--&lt;!&ndash;                    </mat-expansion-panel-header>&ndash;&gt;-->
<!--&lt;!&ndash;                    <p>This is the primary content of the panel.</p>&ndash;&gt;-->
<!--&lt;!&ndash;                </mat-expansion-panel>&ndash;&gt;-->
<!--&lt;!&ndash;            </mat-accordion>&ndash;&gt;-->
<!--&lt;!&ndash;            <div style="display: flex; flex-direction: column;">&ndash;&gt;-->
<!--&lt;!&ndash;                <div>&ndash;&gt;-->
<!--&lt;!&ndash;                    <mat-icon>home</mat-icon>&ndash;&gt;-->
<!--&lt;!&ndash;                    <span>Nom de la section</span>&ndash;&gt;-->
<!--&lt;!&ndash;                    <span style="margin-left: 4px; margin-right: auto;">(X)</span>&ndash;&gt;-->
<!--&lt;!&ndash;                    <fe-button hideText="true" iconType="home"></fe-button>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div style="border-left: 2px solid var(&#45;&#45;main-blue);">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <fe-checkbox></fe-checkbox>&ndash;&gt;-->
<!--&lt;!&ndash;                        <span>Sélectionner tout/Déselectionner tout</span>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div style="margin-left: 16px;">&ndash;&gt;-->
<!--&lt;!&ndash;                        <div>&ndash;&gt;-->
<!--&lt;!&ndash;                            <fe-checkbox></fe-checkbox>&ndash;&gt;-->
<!--&lt;!&ndash;                            <span>Sélectionner tout/Déselectionner tout</span>&ndash;&gt;-->
<!--&lt;!&ndash;                            <span style="padding: 4px; background: var(&#45;&#45;white-grey); border-radius: 50px;&ndash;&gt;-->
<!--&lt;!&ndash;                            font-weight: bold; color: var(&#45;&#45;light-black);">XX</span>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--            <span class="fe-divider"></span>-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--    </mat-sidenav>-->

        <mat-sidenav #snav
                     [ngClass]="{'small': smallSidenavDesktop && !mediaQueryService.mobileQuery.matches, 'mobile': mediaQueryService.mobileQuery.matches}"
                     [mode]="'over'"
                     [opened]="false"
                     [disableClose]="false"
                     [fixedInViewport]="true"
                     position="end" class=sideNavProfil>

                <ion-header no-border style="text-align: center">
                        <ion-toolbar
                                [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
                                <div style="display: flex; width: fit-content; margin: auto; gap: 16px;">
                                        <span class="mainTitle" style="margin: auto">Mon compte</span>

                                        <fe-button text="Déconnecter" color="DANGER" [iconType]="IconType.log_out"
                                                   icon="true" (click)="confirmLogout()" smallButton="true"/>
                                </div>




                        </ion-toolbar>
                </ion-header>
                <div class="sideNavProfilContainer">
                        <div class="sideNavProfilBloc">
                                <span class="veryBigTitleBold">Mes informations personnelles</span>
                                <div class="sideNavProfilBlocContent">
                                        <div class="sideNavProfilBlocSection">
                                                <span class="bigTextBold">Nom</span>
                                                <span>{{sessionData?.customerDetails.civility}} {{sessionData?.customerDetails.firstname}} {{sessionData?.customerDetails.lastname}}</span>
                                        </div>
                                        <div class="sideNavProfilBlocSection">
                                                <span class="bigTextBold">Société</span>
                                                <span>{{sessionData?.customerDetails.companyName}}</span>
                                        </div>
                                        <div class="sideNavProfilBlocSection">
                                                <span class="bigTextBold">Email</span>
                                                <span>{{sessionData?.customerDetails.email}}</span>
                                        </div>
                                </div>
                        </div>
                        <div style="display:flex; flex-direction: column; gap: 8px; margin: 28px 0;">
<!--                        <div class="sideNavProfilBloc">-->
<!--                                <div class="sideNavProfilBlocContent" style="align-items: center;">-->
                                        <fe-button (click)="getMobileLoginInfos()" bold="true"
                                                   *ngIf="!isLettrePersoMC" type="SECONDARY" smallButton="true"
                                                   text="Recevoir mes identifiants pour l'application mobile"/>
                                        <fe-button bold="true" type="SECONDARY" smallButton="true"
                                                   *ngIf="sessionData?.customerDetails.mandatoryAuth"
                                                   (click)="changePassword(sessionData.customerDetails.mandatoryAuth)"
                                                   [text]="sessionData.customerDetails.mandatoryAuth ? 'Modifier ' +
                                                    'mon mot de passe' : 'Modifier votre mot de passe d\'accès'"/>
<!--                                </div>-->
<!--                        </div>-->
                        </div>
                        <div class="sideNavProfilBloc">
                                <div style="display: flex; align-items: center;">
                                        <span class="veryBigTitleBold">Mon conseiller</span>
                                        <fe-button text="Aide" color="DANGER" icon="true" [iconType]="IconType.creditInfo"
                                                   (click)="openZendesk()" style="margin-left: auto;" smallButton="true"/>
                                </div>
                                <div class="sideNavProfilBlocContent">
                                        <div class="sideNavProfilBlocSection">
                                                <span class="bigTextBold">Nom</span>
                                                <span>{{sessionData?.salesPersonDetails.salesPersonFirstname}} {{sessionData?.salesPersonDetails.salesPersonName}}</span>
                                        </div>
                                        <div class="sideNavProfilBlocSection">
                                                <span class="bigTextBold">Email</span>
                                                <span>{{sessionData?.salesPersonDetails.salesPersonEmail}}</span>
                                        </div>
                                        <div class="sideNavProfilBlocSection">
                                                <span class="bigTextBold">Téléphone</span>
                                                <span>{{sessionData?.salesPersonDetails.salesPersonPhone}}</span>
                                        </div>
                                        <div class="sideNavProfilBlocSection">
                                                <fe-button (click)="contactMyCommercial()"
                                                           *ngIf="!isLettrePersoMC" smallButton="true"
                                                           text="Contacter mon conseiller"/>
                                        </div>
                                </div>
                        </div>
                        <div class="sideNavProfilBloc">
                                <div style="display: flex; align-items: center;">
                                        <span class="veryBigTitleBold">Ma formule</span>
                                        <fe-button text="Modifier mon offre" type="SECONDARY" smallButton="true" bold="true"
                                                   (click)="showLocationDetails()" style="margin-left: auto;"/>
                                </div>
                                <div class="sideNavProfilBlocContent">
                                        <div class="sideNavProfilBlocSection">
                                                <span class="bigTextBold">Offre</span>
                                                <span>{{sessionData?.productOfferLabel}}</span>
                                        </div>
                                        <div class="sideNavProfilBlocSection">
                                                <span class="bigTextBold">Profil</span>
                                                <span>{{departements?.length + ' critères inclus'}}</span>
                                        </div>
                                        <div class="sideNavProfilBlocSection">
                                                <fe-button type="TERTIARY" text="Voir plus" bold="true"
                                                           (click)="showLocationDetails()" smallButton="true"/>
                                        </div>
                                </div>
                        </div>
                </div>

        </mat-sidenav>

</mat-sidenav-container>
