import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { XFieldComponent } from '../x-field/x-field.component';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'x-input-text',
  templateUrl: 'x-input-text.component.html',
  styleUrls: ['./x-input-text.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => XInputTextComponent), multi: true }
  ]
})
export class XInputTextComponent extends XFieldComponent {

  constructor(
    protected matSnackBar: MatSnackBar
  ) {
    super(matSnackBar);
  }

}
