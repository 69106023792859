<ion-content>
    <mat-sidenav-container #sidenavContainer autosize="true">
        <mat-sidenav-content style="display: flex; flex-direction: column;">
            <ion-toolbar style="z-index: 1;">
                <div class="toolbarRecherche">
                    <div class="divTitre">
                        <div class="divTitreLine" style="margin: 12px 0 16px;">
                            <span class="titre">Signaux partagés</span>
                        </div>
                        <div class="divTitreLine" style="height: 50px;">
                            <div class="divActions flex1">
                                <fe-button (click)="exportMailHistory()" color="PRIMARY"
                                           text="Exporter tous les résultats"/>
                            </div>

                            <mat-paginator [length]="historyLength" [pageSizeOptions]="[25, 50, 100, 250]"
                                           #paginator class="sendingPaginator" [pageIndex]="page" [pageSize]="pageSize"
                                           (page)="handlePageEvent($event)"/>
                        </div>
                    </div>
                </div>
            </ion-toolbar>
            <div class="mailHistoryContainer" [class.displayNone]="loadingInProgress">
                <div class="mailHistoryLine" *ngFor="let mailSended of mailsSended">
                    <div class="mailHistoryLineFL">
                        <span class="mailHistoryDate">{{formatDate(mailSended.date, 'dd/MM/yyyy à HH:mm', 'fr_FR')}}</span>
                        <span class="mailHistoryTitle bigText">{{mailSended.subject}}</span>
                    </div>
                    <div class="mailHistoryLineSL">
                        <span>{{mailSended.articleCounts}} {{mailSended.articleCounts > 1 ? 'articles partagés'
                            : 'article partagé'}} </span>
                        <span>{{mailSended.recipients.length}} {{mailSended.recipients.length > 1
                            ? 'destinataires' : 'destinataire'}}</span>
                        <fe-button smallButton="true" text="Voir plus" type="TERTIARY" bold="true"
                                   color="PRIMARY" (click)="openSendedMailModal(mailSended)"/>
                    </div>
                </div>
            </div>
            <fe-loader [class.displayNone]="!loadingInProgress" loaderText="Chargement de l'historique..."/>
        </mat-sidenav-content>

        <mat-sidenav style="overflow: visible; padding: 8px 0;" class="right-side-bar" mode="side" opened
                     disableClose="true" position="end" [class.small]="smallSidenavDesktop">
            <div [ngClass]="{'smallBtnContainer': smallSidenavDesktop}"
                 style="text-align: right; width: 100%; display: flex; align-items: center; padding-right: 8px;">
                <fe-button (click)="toogleRightSidenavSize()" [ngClass]="{'small': smallSidenavDesktop}" type="TERTIARY"
                           color="PRIMARY" bold="true" icon="true" [iconType]="IconType.menu_open" [hideText]="smallSidenavDesktop"
                           text="Réduire" iconDirection="LEFT" [reverseIcon]="!smallSidenavDesktop" [class.leftSideBarBtn]="!smallSidenavDesktop"
                           bigIcon="true" noUnderline="true" [class.marginAuto]="smallSidenavDesktop"/>
                <fe-button text="Réinitialiser les filtres" type="SECONDARY" (click)="reinitCriteres()" smallButton="true"
                           [class.displayNone]="smallSidenavDesktop" bold="true"/>
            </div>

            <div class="sendingLeftBar" [class.displayNone]="smallSidenavDesktop">
                <div class="dateContainer">
                    <p style="font-size: 16px;font-weight: 700;">Filtrer par date</p>
                    <div style="display: flex;flex-direction: row;cursor:pointer; position: relative; align-items: center;" (click)="openDateSelector($event)">
                        <fe-input [value]="dateInputText" [readonly]="true" style="flex: 1;" [width]="'100%'" [cursor]="'pointer'"></fe-input>
                        <mat-icon class="cleanDateButton" (click)="clearDate($event)">close</mat-icon>
                        <fe-button color="PRIMARY" type="TERTIARY" [hideText]="true" [icon]="true"
                                   [iconType]="IconType.calendar"></fe-button>
                    </div>
                </div>

                <div class="dateContainer">
                    <p style="font-size: 16px;font-weight: 700;">Filtrer les résultats</p>
                    <!--        <app-fe-date-picker #datePicker (emitter)="dateChanged($event)"></app-fe-date-picker>-->
                    <!--                <div style="display: flex;flex-direction: row;cursor:pointer;" (click)="openDateSelector($event)">-->
                    <!--                    <fe-input [value]="dateInputText" [readonly]="true" style="flex: 1;" [width]="'100%'" [cursor]="'pointer'"></fe-input>-->
                    <!--                    <fe-button color="PRIMARY" type="TERTIARY" [hideText]="true" [icon]="true"-->
                    <!--                               [iconType]="IconType.calendar"></fe-button>-->
                    <!--                </div>-->
                    <fe-searchbar #searchBarInput (valueChange)="searchValueChanged($event)" placeHolder="Rechercher un terme..."/>
                </div>

                <div class="dateContainer">
                    <p style="font-size: 16px;font-weight: 700;">Filtrer les destinataires</p>
                    <!--        <app-fe-date-picker #datePicker (emitter)="dateChanged($event)"></app-fe-date-picker>-->
                    <!--                <div style="display: flex;flex-direction: row;cursor:pointer;" (click)="openDateSelector($event)">-->
                    <!--                    <fe-input [value]="dateInputText" [readonly]="true" style="flex: 1;" [width]="'100%'" [cursor]="'pointer'"></fe-input>-->
                    <!--                    <fe-button color="PRIMARY" type="TERTIARY" [hideText]="true" [icon]="true"-->
                    <!--                               [iconType]="IconType.calendar"></fe-button>-->
                    <!--                </div>-->
                    <!--                    <fe-searchbar placeHolder="Rechercher un destinataire..." (valueChange)="filterChange($event)"-->
                    <!--                                  [formControl]="control" [matAutocomplete]="auto"/>-->
                    <div class="contactsContainer">
                        <div class="feSearchBar">
                            <mat-icon class="greyC">search</mat-icon>
                            <input placeholder="Rechercher un destinataire..." [formControl]="control"
                                   [matAutocomplete]="auto" [autofocus]="false" (click)="initAutoComplete()">
                            <mat-icon class="cleanSearchBarIcon" (click)="clearSBDestinataires()">close</mat-icon>
                        </div>

<!--                        <mat-autocomplete #autoCompAddresses="matAutocomplete" class="searchContactAutoComp">-->
<!--                            <mat-option *ngFor="let contact of filteredContacts | async" class="contactOption"-->
<!--                                        (click)="addContactToMail(contact)" [title]="getContactTitle(contact)">-->
<!--                                <span [innerHTML]="getContactFormatLabel(contact)" class="contactName"></span>-->
<!--                            </mat-option>-->
<!--                        </mat-autocomplete>-->

                        <mat-autocomplete #auto="matAutocomplete" class="searchDestinataireAutoComp">
                            <mat-option *ngFor="let address of filteredAddress | async" [title]="address.label"
                                        (click)="addContactFilter(address)" class="contactOption" [value]="''">
                            <span style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                {{address.label}}
                            </span>
                            </mat-option>
                        </mat-autocomplete>

                        <div class="selectedContactsContainer" [class.displayNone]="this.selectedContacts.length < 1">
                            <div *ngFor="let contact of selectedContacts" class="selectedContactLine">
                                <span style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;"
                                      class="moderateText" [title]="contact.label">{{contact.label}}</span>
                                <mat-icon class="cleanSearchBarIcon" (click)="removeContactFilter(contact)">
                                    close
                                </mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-sidenav>
    </mat-sidenav-container>
</ion-content>
