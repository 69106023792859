import { Directive, forwardRef, Attribute, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[compareTo][formControlName],[compareTo][formControl],[compareTo][ngModel]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => CompareToDirective), multi: true}
  ]
})
export class CompareToDirective implements Validator {

  @Input() public compareToField: string;

  constructor() { }

  validate(control: AbstractControl): { [key: string]: any } {
    const compared = control.root.get(this.compareToField);
    if (compared && control.value !== compared.value && compared.value !== '' ) {
      return { notEqual: true };
    }
    if (compared && control.value === compared.value) {
      if (control !== null && control.errors !== null && control.errors['notEqual'] !== null) {
        delete control.errors['notEqual'];
      }
      if (compared !== null && compared.errors !== null && compared.errors['notEqual'] !== null) {
        delete compared.errors['notEqual'];
      }

      if (typeof compared.errors === 'undefined' || compared.errors === null) {
        compared.setErrors(null);
      } else if (!Object.keys(compared.errors).length) {
        compared.setErrors(null);
      }

      if (typeof control.errors === 'undefined' || control.errors === null) {
        control.setErrors(null);
      } else if (!Object.keys(control.errors).length) {
        control.setErrors(null);
      }
    }
    return null;
  }

}
