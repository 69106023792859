import {Component, OnInit, OnDestroy, Input, SimpleChanges, OnChanges} from '@angular/core';
import { Subject } from 'rxjs';
import { TagsInterface } from 'src/app/interfaces/tags/tags';
import {UserService} from '../../../services/user/user.service';
import {takeUntil} from 'rxjs/operators';
import {SessionInterface} from '../../../interfaces/session/session.interface';
import {PredictionInterface} from '../../../interfaces/tag/prediction';
import { MediaQueryService } from 'src/app/services/media-query/media-query.service';
import {Utils} from '../../../classes/Utils';

@Component({
  selector: 'article-tags',
  templateUrl: 'article-tags.component.html',
  styleUrls: ['article-tags.component.scss'],
})
export class ArticleTagsComponent implements OnInit, OnDestroy, OnChanges {

  @Input() public tags: TagsInterface;
  @Input() public isFromBusinessSignals: Boolean;
  @Input() public hasSectorsInSearch: Boolean;
  @Input() public hasTopicsInSearch: Boolean;
  @Input() public predictions: Array<PredictionInterface>;
  public leadsAvailable: boolean = true;

  private unsubscribe$: Subject<any> = new Subject<any>();
  @Input() public nonMatching: number = -1;
  private nonMatchingToKeep: Array<PredictionInterface> = [];
  public notMatching: boolean = false;
  public _keywords: Array<string>;
  public isMobile: boolean;
  public leadsOpened: boolean;
  @Input() public set keywords(value: Array<string>) {
    this._keywords = value;
  }
  public get keywords(): Array<string> {
    return this._keywords;
  }

  constructor(
    public mediaQueryService: MediaQueryService
    , private userService: UserService
  ) {
  }

  public ngOnInit(): void {

    this.isMobile = this.mediaQueryService.mobileQuery.matches;

    this.userService.getSessionDatas().pipe(
        takeUntil(this.unsubscribe$)
    )
    .subscribe(
        (sessionData: SessionInterface) => {
          if (sessionData !== undefined) {
            sessionData.appConfig.viewConfigs.forEach(value => {
              if (value.component === 'LEADS_TOPICS_CHOICE') {
                if (value.state === 'visible') {
                  this.leadsAvailable = true;
                }
              }
            });
          }
        }
    );
  }

  public getHighlightedText(text: string): string {
    return Utils.getHighlightedText(text, this._keywords);
  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.nonMatchingToKeep = [];
    // this.notMatching = false;
  }

  checkMatching(tag: PredictionInterface) {
    if (this.nonMatching < 0) {
      return true;
    }
    if (!tag.matchSearch) {
      if (this.nonMatchingToKeep.length >= this.nonMatching) {
        if (this.nonMatchingToKeep.includes(tag)) {
          return true;
        }
        this.notMatching = true;
        return false;
      } else {
        this.nonMatchingToKeep.push(tag);
      }
    }
    return true;
  }

  showLeads($event: MouseEvent) {
    $event.stopPropagation();
    this.leadsOpened = !this.leadsOpened;
  }
}
