<ng-container *ngIf="(token !== undefined && token !== '') || isMobile">
  <div style="display: flex; justify-content: flex-end; color:white">
    <button mat-icon-button aria-label="close" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-card class="modalCard" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}" style="width: 400px; margin:auto; margin-top:0px; padding:5px;">
    <h1 class="primaryColor center" style="text-align: center;">Connexion</h1>
    <h2 style="font-weight: bold; color: #273C4E; text-align: center;">Accéder aux contacts enrichis de cette entreprise</h2>

    <div style="margin:auto; width:90%;">
<!--      <x-form [fields]="fields" [submitMethod]="formSubmitMethod" submitButtonColor="accent"-->
<!--              [submitButtonLabel]="'Me connecter'"></x-form>-->
      <fe-input [(value)]="email" width="100%" placeHolder="Email" [name]="'login'"/>
      <fe-input [(value)]="password" width="100%" placeHolder="Mot de passe" [type]="'password'"  [name]="'password'" />
      <fe-button class="loginButton" text="Connexion" type="PRIMARY" color="PRIMARY" bold="true" (click)="loginClick()"/>

      <button mat-flat-button (click)="reinitPassword()">Mot de passe oublié ?</button>
    </div>

    <p style="margin-top:10px; font-size:15px; padding:0 15px; 0 15px;">
      La fonctionnalité <a href="https://www.firsteco.fr/revue/plaquette_contacts_enrichis.pdf" target="_blank"> contacts enrichis</a> vous permet de rechercher les interlocuteurs les plus pertinents est disponible en option.<br /><br />
      <strong>Contactez votre chargé d’affaires sur <a href="mailto:contact@firsteco.fr">contact@firsteco.fr</a> ou au 03 80 59 90 56.</strong><br /><br />
    </p>
  </mat-card>
</ng-container>
<ng-container *ngIf="!isMobile && (token === undefined || token === '')">
  <mat-card class="modalCard">
    <h1 class="primaryColor center">Echec de l'authentification</h1>
    <h2 class="accentColor center">Votre accès First ECO a échoué en raison d'un problème d'authentification.</h2>

    <div>
      <p>Merci de bien utiliser les identifiants reçus par email ou tenter de réinitialiser votre mot de passe.</p>
      <p>Si le problème persiste, n'hésitez pas à nous contacter :</p>
      <p>03 80 59 90 56 - profil@firsteco.fr</p>
    </div>
  </mat-card>
</ng-container>

<loading-overlay *ngIf="displayAutoConnectLoader">Connexion Automatique</loading-overlay>
