import {Component, Input, OnInit} from '@angular/core';
import {EntrepriseContactInterface} from '../../../../interfaces/entreprise-contact/entreprise-contact.interface';
import {EntrepriseEtablissementInterface} from '../../../../interfaces/entreprise-etablissement/entreprise-etablissement.interface';
import {MediaQueryService} from '../../../../services/media-query/media-query.service';

@Component({
  selector: 'etablissement-modal-line',
  templateUrl: './etablissement-modal-line.component.html',
  styleUrls: ['./etablissement-modal-line.component.scss'],
})
export class EtablissementModalLineComponent implements OnInit {

  @Input() public etablissement: EntrepriseEtablissementInterface;

  constructor(public mediaQueryService: MediaQueryService) { }

  ngOnInit() {}

}
