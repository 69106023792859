// Angular import
import {forwardRef, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

// ionic
import {IonicModule} from '@ionic/angular';

// Other Module import
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule, MatSpinner} from '@angular/material/progress-spinner';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCardModule} from '@angular/material/card';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatNativeDateModule} from '@angular/material/core';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatPaginatorIntlCustom} from './services/mat-paginator-intl-custom/mat-paginator-intl-custom.service';
import {MatBadgeModule} from '@angular/material/badge';
import {MatSliderModule} from '@angular/material/slider';
import {MatTabsModule} from '@angular/material/tabs';
import {MatMenuModule} from '@angular/material/menu';

// Xform
import {XFormComponent} from './components/x-form/x-form.component';
import {XFieldComponent} from './components/x-form/x-field/x-field.component';
import {XFormApiErrorComponent} from './components/x-form/x-form-api-error/x-form-api-error.component';
import {XCheckboxComponent} from './components/x-form/x-checkbox/x-checkbox.component';
import {XInputDateComponent} from './components/x-form/x-input-date/x-input-date.component';
import {XInputHiddenComponent} from './components/x-form/x-input-hidden/x-input-hidden.component';
import {XInputPasswordComponent} from './components/x-form/x-input-password/x-input-password.component';
import {XInputPhoneComponent} from './components/x-form/x-input-phone/x-input-phone.component';
import {XInputTextComponent} from './components/x-form/x-input-text/x-input-text.component';
import {XJsonComponent} from './components/x-form/x-json/x-json.component';
import {XRadioComponent} from './components/x-form/x-radio/x-radio.component';
import {XSelectComponent} from './components/x-form/x-select/x-select.component';
import {XSelectValueComponent} from './components/x-form/x-select-value/x-select-value.component';
import {XTextareaComponent} from './components/x-form/x-textarea/x-textarea.component';
import {XToggleComponent} from './components/x-form/x-toggle/x-toggle.component';
import {XAddressComponent} from './components/x-form/x-address/x-address.component';
import {XSliderComponent} from './components/x-form/x-slider/x-slider.component';

// App
import {BusinessSignalsComponent} from './components/business-signals/business-signals.component';
import {BusinessSignalsMonitoringComponent} from './components/business-signals-monitoring/business-signals-monitoring.component';
import {MySearchesListComponent} from './components/my-searches/my-searches-list/my-searches-list.component';
import {MySearchesAddComponent} from './components/my-searches/my-searches-add/my-searches-add.component';
import {MySearchesAddEditChildrenComponent} from './components/my-searches/my-searches-add-edit-children/my-searches-add-edit-children.component';
import {MyFavoritesComponent} from './components/my-favorites/my-favorites.component';
import {AddressesComponent} from './components/addresses/addresses.component';
import {SendingHistoryComponent} from './components/sending-history/sending-history.component';
import {MyAccountComponent} from './components/my-account/my-account.component';
import {HelpComponent} from './components/help/help.component';
import {CompareToDirective} from './directives/compare-to/compare-to.directive';
import {LoadingOverlayComponent} from './components/loading-overlay/loading-overlay.component';
import {UserInsetComponent} from './components/user-inset/user-inset.component';
import {MainToolbarComponent} from './components/main-toolbar/main-toolbar.component';
import {UserLastConnexionComponent} from './components/user-last-connexion/user-last-connexion.component';
import {HideTollbarOnScrollDirective} from './directives/hide-toolbar-on-scroll/hide-toolbar-on-scroll.directive';
import {ArticleListItemComponent} from './components/article/article-list-item/article-list-item.component';
import {ToggleListStyleComponent} from './components/toggle-list-style/toggle-list-style.component';
import {ToggleListMapsComponent} from './components/toggle-list-maps/toggle-list-maps.component';
import {LoadingComponent} from './components/loading/loading.component';
import {NotDataComponent} from './components/no-data/no-data.component';
import {ArticleTagsComponent} from './components/article/article-tags/article-tags.component';
import {ArticleToggleFavoriteButtonComponent} from './components/article/article-toggle-favorite-button/article-toggle-favorite-button.component';
import {ArticleSourcesComponent} from './components/article/article-sources/article-sources.component';
import {ArticleContactsComponent} from './components/article/article-contacts/article-contacts.component';
import {ArticleContactsDetailsComponent} from './components/article/article-contacts-details/article-contacts-details.component';
import {ArticleSendMailButtonComponent} from './components/article/article-send-mail-button/article-send-mail-button.component';
import {ArticleReadUnreadButtonComponent} from './components/article/article-read-unread-button/article-read-unread-button.component';
import {ArticleDeleteFavButtonComponent} from './components/article/article-delete-fav-button/article-delete-fav-button.component';
import {ArticleExportButtonComponent} from './components/article/article-export-button/article-export-button.component';
import {ArticlePrintButtonComponent} from './components/article/article-print-button/article-print-button.component';
import {DatesSelectorButtonComponent} from './components/dates-selector-button/dates-selector-button.component';
import {BadgeCustomComponent} from './components/badge-custom/badge-custom.component';
import {ToggleNotificationButtonComponent} from './components/toggle-notification-button/toggle-notification-button.component';
import {PaginatedListComponent} from './components/paginated-list/paginated-list.component';

// Modals
import {AddEditAddressModal} from './components/modals/address-add-edit/address-add-edit.modal';
import {ReadingArticleModal} from './components/modals/reading-article/reading-article.modal';
import {DatesSelectorModal} from './components/modals/dates-selector/dates-selector.modal';
import {EmailArticleModal} from './components/modals/email-article/email-article.modal';
import {SendingHistoryDetailModal} from './components/modals/sending-history-detail/sending-history-detail.modal';
// Pipes
import {Safe} from './pipes/safe-html/safe-html.pipe';

// env
import {environment} from 'src/environments/environment';
import {ArticleEntrepriseComponent} from './components/article/article-entreprise/article-entreprise.component';
import {ArticleEtablissementComponent} from './components/article/article-etablissement/article-etablissement.component';
import {FicheEntrepriseModal} from './components/modals/fiche-entreprise/fiche-entreprise.modal';
import {EntrepriseContactsModal} from './components/modals/entreprise-contacts/entreprise-contacts.modal';
import {SearchSummaryComponent} from './components/modals/search/search-summary/search-summary.component';
import {CommunicationModalComponent} from './components/modals/communication-modal/communication-modal.component';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {BottomSheetCommunication, LayoutComponent} from './components/layout/layout.component';
import {MatTreeModule} from '@angular/material/tree';
import {EtablissementModalLineComponent} from './components/modals/fiche-entreprise/etablissement-modal-line/etablissement-modal-line.component';
import {ReinitPasswordComponent} from './components/login/reinit-password/reinit-password.component';
import {ShareSearchesComponent} from './components/modals/share-searches/share-searches.component';
import {AddFavoriteModalComponent} from './components/modals/add-favorite-modal/add-favorite-modal.component';
import {AddFavoriteFolderModalComponent} from './components/modals/add-favorite-folder-modal/add-favorite-folder-modal.component';
import {MySearchesHelpDialogComponent} from './components/my-searches/my-searches-help-dialog/my-searches-help-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ArticleMoveFolderButtonComponent} from './components/article/article-move-folder-button/article-move-folder-button.component';
// import {SelectAutocompleteModule} from "mat-select-autocomplete";
import {MonitoredArticleComponent} from './components/monitored-article/monitored-article.component';
import {SupervisorMonitoringModalComponent} from './components/supervisor-monitoring-modal/supervisor-monitoring-modal.component';
import {MonitoringEditCommentModalComponent} from './components/monitoring-edit-comment-modal/monitoring-edit-comment-modal.component';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {MonitoringCommentSharingModalComponent} from './components/monitoring-comment-sharing-modal/monitoring-comment-sharing-modal.component';
import {MonitoringAttachmentSharingModalComponent} from './components/monitoring-attachment-sharing-modal/monitoring-attachment-sharing-modal.component';
import {LoadingDisplayerComponent} from './components/modals/loading-displayer/loading-displayer.component';
import {MonitoringConfirmClotureModalComponent} from './components/monitoring-confirm-cloture-modal/monitoring-confirm-cloture-modal.component';
import {FicheEntrepriseStatsComponent} from './components/modals/fiche-entreprise/fiche-entreprise-stats/fiche-entreprise-stats.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {AllContactsModal, PreventCloseAllContactDialog} from './components/modals/all-contacts/all-contacts.modal';
import {ContactDetailComponent} from './components/modals/contact-detail/contact-detail.component';
import {CreateLeadModalComponent} from './components/modals/create-lead-modal/create-lead-modal.component';
import {ArticleAddFavoriteButtonComponent} from './components/article/article-add-favorite-button/article-add-favorite-button.component';
import {RouterModule} from '@angular/router';
import {ManageSearchesModalComponent} from './components/modals/manage-searches-modal/manage-searches-modal.component';
import {ManageSearchesComponent} from './components/my-searches/manage-searches/manage-searches.component';
import {SearchCreationComponent} from './components/my-searches/search-creation/search-creation.component';
import {SearchLeftPartComponent} from './components/my-searches/search-left-part/search-left-part.component';
import {
    SearchSelectedCriterionsComponent
} from './components/my-searches/search-selected-criterions/search-selected-criterions.component';
import {
    SearchListCriterionsComponent
} from './components/my-searches/search-list-criterions/search-list-criterions.component';
import {
    CriterionItemPanelComponent
} from './components/my-searches/search-list-criterions/criterion-item-panel/criterion-item-panel.component';
import {SearchListContainerComponent} from './components/search-list-container/search-list-container.component';
import {SearchListItemComponent} from './components/search-list-container/search-list-item/search-list-item.component';
import {FeButtonComponent} from './components/parts/fe-button/fe-button.component';
import {FeCheckboxComponent} from './components/parts/fe-checkbox/fe-checkbox.component';
import {FeSnackbarComponent} from './components/fe-snackbar/fe-snackbar.component';
import {FeProTipsComponent} from './components/parts/fe-pro-tips/fe-pro-tips.component';
import {FeModalComponent} from './components/fe-modal/fe-modal.component';
import {FeInputComponent} from './components/parts/fe-input/fe-input.component';
import {FeDotsMenuComponent} from './components/fe-dots-menu/fe-dots-menu.component';
import {FeMenuItemComponent} from './components/fe-dots-menu/fe-menu-item/fe-menu-item.component';
import {FeSubMenuComponent} from './components/fe-dots-menu/fe-sub-menu/fe-sub-menu.component';
import {FeToggleComponent} from './components/parts/fe-toggle/fe-toggle.component';
import {FeDatePickerComponent} from './components/parts/fe-date-picker/fe-date-picker.component';
import {FeArticleItemComponent} from './components/fe-article-item/fe-article-item.component';
import {FeTagComponent} from './components/parts/fe-tag/fe-tag.component';
import {FeEntrepriseItemComponent} from './components/parts/fe-entreprise-item/fe-entreprise-item.component';
import {FeMailModalComponent} from './components/modals/fe-mail-modal/fe-mail-modal.component';
import {FeArticleModalComponent} from './components/modals/fe-article-modal/fe-article-modal.component';
import {FeFicheEntrepriseModalComponent} from './components/modals/fe-fiche-entreprise-modal/fe-fiche-entreprise-modal.component';
import {FeGestionRecherchesModalComponent} from './components/modals/fe-gestion-recherches-modal/fe-gestion-recherches-modal.component';
import {FeRechercheLineComponent} from './components/modals/fe-gestion-recherches-modal/fe-recherche-line/fe-recherche-line.component';
import {FeSearchbarComponent} from "./components/parts/fe-searchbar/fe-searchbar.component";
import {
    FePreviousArticleLineComponent
} from "./components/modals/fe-fiche-entreprise-modal/fe-previous-article-line/fe-previous-article-line.component";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {
    FeFinancialsGraphModalComponent
} from "./components/modals/fe-fiche-entreprise-modal/fe-financials-graph-modal/fe-financials-graph-modal.component";
import {FeContactEnrichiModalComponent} from './components/modals/fe-fiche-entreprise-modal/fe-contact-enrichi-modal/fe-contact-enrichi-modal.component';
import {FeLoaderComponent} from "./components/fe-loader/fe-loader.component";
import {FeCarnetAdressesModalComponent} from './components/modals/fe-carnet-adresses-modal/fe-carnet-adresses-modal.component';
import {FeCarnetContactModalComponent} from './components/modals/fe-carnet-adresses-modal/fe-carnet-contact-modal/fe-carnet-contact-modal.component';
import {FeTextAreaComponent} from './components/parts/fe-text-area/fe-text-area.component';
import {FeIconComponent} from "./components/parts/fe-icon/fe-icon.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {
    FeCustomerDepartementsModalComponent
} from "./components/modals/fe-customer-departements-modal/fe-customer-departements-modal.component";
import {GeolocModalComponent} from './components/modals/geoloc-modal/geoloc-modal.component';
import {NewsletterEditorComponent} from './components/newsletter-editor/newsletter-editor.component';
import {NewsletterTemplateComponent} from './components/modals/newsletter-template/newsletter-template.component';
import {
    NewsletterPreviewModalComponent
} from './components/modals/newsletter-preview-modal/newsletter-preview-modal.component';
import {NewsletterSaveAsComponent} from './components/modals/newsletter-save-as/newsletter-save-as.component';
import {FeDateSelectorComponent} from './components/parts/fe-date-selector/fe-date-selector.component';
import {
    FeChangePasswordModalComponent
} from "./components/modals/fe-change-password-modal/fe-change-password-modal.component";
import {GoogleMapsModule} from '@angular/google-maps';
import {HttpClientJsonpModule} from '@angular/common/http';
import {FeFavoriteItemComponent} from "./components/parts/fe-favorite-item/fe-favorite-item.component";
import {FeSendedMailModalComponent} from "./components/modals/fe-sended-mail-modal/fe-sended-mail-modal.component";
import {ExternalLoginComponent} from './components/login/external-login/external-login.component';
import {FeAskContactsEnrichisModalComponent} from './components/modals/fe-ask-contacts-enrichis-modal/fe-ask-contacts-enrichis-modal.component';
import {FeLeadsComponent} from './components/parts/fe-leads/fe-leads.component';
import {FeContactCommercialModalComponent} from './components/modals/fe-contact-commercial-modal/fe-contact-commercial-modal.component';

// import {GaugeChartModule} from 'angular-gauge-chart';

@NgModule({
    imports: [
        CommonModule,
        HttpClientJsonpModule,
        GoogleMapsModule,
        FormsModule,
        ReactiveFormsModule,
        MatBottomSheetModule,
        IonicModule,
        MatSidenavModule,
        MatListModule,
        MatToolbarModule,
        MatButtonModule,
        MatChipsModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatExpansionModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatCardModule,
        MatButtonToggleModule,
        MatNativeDateModule,
        DragDropModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatBadgeModule,
        MatSliderModule,
        MatTabsModule,
        ScrollingModule,
        MatMenuModule,
        NgxChartsModule,
        MatTreeModule,
        MatDialogModule,
        AngularEditorModule,
        RouterModule, // , GaugeChartModule
        MatProgressBarModule,
        GoogleMapsModule
    ],
    declarations: [
        BusinessSignalsComponent,
        FicheEntrepriseStatsComponent,
        BusinessSignalsMonitoringComponent,
        MySearchesListComponent,
        MyFavoritesComponent,
        AddressesComponent,
        SendingHistoryComponent,
        MyAccountComponent,
        HelpComponent,
        PaginatedListComponent,
        MySearchesAddComponent,
        SearchCreationComponent,
        SearchLeftPartComponent,
        SearchListCriterionsComponent,
        NewsletterEditorComponent,
        NewsletterTemplateComponent,
        NewsletterPreviewModalComponent,
        ExternalLoginComponent,
        NewsletterSaveAsComponent,
        CriterionItemPanelComponent,
        SearchSelectedCriterionsComponent,
        ManageSearchesComponent,
        GeolocModalComponent,
        AddEditAddressModal,
        AllContactsModal,
        PreventCloseAllContactDialog,
        ContactDetailComponent,
        ReadingArticleModal,
        FicheEntrepriseModal,
        EntrepriseContactsModal,
        SearchSummaryComponent,
        CreateLeadModalComponent,
        ManageSearchesModalComponent,
        ReinitPasswordComponent,
        CommunicationModalComponent,
        DatesSelectorModal,
        EmailArticleModal,
        AddFavoriteModalComponent,
        AddFavoriteFolderModalComponent,
        SendingHistoryDetailModal,
        MySearchesAddEditChildrenComponent,
        BottomSheetCommunication,
        XFormComponent,
        XFieldComponent,
        XFormApiErrorComponent,
        XCheckboxComponent,
        XInputDateComponent,
        XInputHiddenComponent,
        XInputPasswordComponent,
        XInputPhoneComponent,
        XInputTextComponent,
        XJsonComponent,
        XRadioComponent,
        XSelectComponent,
        XSelectValueComponent,
        XTextareaComponent,
        XToggleComponent,
        XAddressComponent,
        XSliderComponent,
        CompareToDirective,
        LoadingOverlayComponent,
        UserInsetComponent,
        MainToolbarComponent,
        UserLastConnexionComponent,
        ArticleListItemComponent,
        EtablissementModalLineComponent,
        MonitoredArticleComponent,
        SupervisorMonitoringModalComponent,
        MonitoringEditCommentModalComponent,
        MonitoringCommentSharingModalComponent,
        MonitoringConfirmClotureModalComponent,
        MonitoringAttachmentSharingModalComponent,
        LoadingDisplayerComponent,
        ToggleListStyleComponent,
        ToggleListMapsComponent,
        LoadingComponent,
        NotDataComponent,
        ArticleTagsComponent,
        ArticleToggleFavoriteButtonComponent,
        ArticleSourcesComponent,
        ArticleContactsComponent,
        ArticleContactsDetailsComponent,
        ArticleSendMailButtonComponent,
        ArticleAddFavoriteButtonComponent,
        ArticleReadUnreadButtonComponent,
        ArticleExportButtonComponent,
        ArticlePrintButtonComponent,
        DatesSelectorButtonComponent,
        BadgeCustomComponent,
        ToggleNotificationButtonComponent,
        HideTollbarOnScrollDirective,
        Safe, ArticleEntrepriseComponent, ArticleEtablissementComponent,
        ShareSearchesComponent, ArticleDeleteFavButtonComponent, ArticleMoveFolderButtonComponent,
        MySearchesHelpDialogComponent, SearchListContainerComponent, SearchListItemComponent,
        FeButtonComponent,
        FeDatePickerComponent,
        FeDateSelectorComponent,
        FeInputComponent,
        FeCheckboxComponent,
        FeSnackbarComponent,
        FeProTipsComponent,
        FeModalComponent,
        FeDotsMenuComponent,
        FeMenuItemComponent,
        FeSubMenuComponent,
        FeToggleComponent,
        FeArticleItemComponent,
        FeTagComponent,
        FeEntrepriseItemComponent,
        FeMailModalComponent,
        FeArticleModalComponent,
        FeFicheEntrepriseModalComponent,
        FeGestionRecherchesModalComponent,
        FeRechercheLineComponent,
        FeSearchbarComponent,
        FePreviousArticleLineComponent,
        FeFinancialsGraphModalComponent,
        FeContactEnrichiModalComponent,
        FeLoaderComponent,
        FeCarnetAdressesModalComponent,
        FeCarnetContactModalComponent,
        FeTextAreaComponent,
        FeIconComponent,
        FeCustomerDepartementsModalComponent,
        FeChangePasswordModalComponent,
        FeFavoriteItemComponent,
        FeSendedMailModalComponent,
        FeAskContactsEnrichisModalComponent,
        FeLeadsComponent,
        FeContactCommercialModalComponent
    ],
    providers: [
        {
            provide: MatPaginatorIntl,
            useClass: forwardRef(() => MatPaginatorIntlCustom)
        }
    ],
    exports: [
        CommonModule,
        HttpClientJsonpModule,
        GoogleMapsModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        MatBottomSheetModule,
        MatSidenavModule,
        MatListModule,
        MatToolbarModule,
        MatButtonModule,
        MatChipsModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatExpansionModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatCardModule,
        MatButtonToggleModule,
        MatNativeDateModule,
        DragDropModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatBadgeModule,
        MatSliderModule,
        MatTabsModule,
        MatMenuModule,
        NgxChartsModule,
        FicheEntrepriseStatsComponent,
        BusinessSignalsComponent,
        BusinessSignalsMonitoringComponent,
        MySearchesListComponent,
        MySearchesAddComponent,
        SearchCreationComponent,
        SearchLeftPartComponent,
        SearchListCriterionsComponent,
        ExternalLoginComponent,
        NewsletterEditorComponent,
        NewsletterTemplateComponent,
        NewsletterPreviewModalComponent,
        CriterionItemPanelComponent,
        SearchSelectedCriterionsComponent,
        ManageSearchesComponent,
        GeolocModalComponent,
        MySearchesHelpDialogComponent,
        MySearchesAddEditChildrenComponent,
        MyFavoritesComponent,
        AddressesComponent,
        SendingHistoryComponent,
        MyAccountComponent,
        HelpComponent,
        PaginatedListComponent,
        EtablissementModalLineComponent,
        MonitoredArticleComponent,
        SupervisorMonitoringModalComponent,
        MonitoringEditCommentModalComponent,
        MonitoringCommentSharingModalComponent,
        MonitoringConfirmClotureModalComponent,
        MonitoringAttachmentSharingModalComponent,
        AddEditAddressModal,
        AllContactsModal,
        ContactDetailComponent,
        ReadingArticleModal,
        FicheEntrepriseModal,
        EntrepriseContactsModal,
        SearchSummaryComponent,
        CreateLeadModalComponent,
        ManageSearchesModalComponent,
        ReinitPasswordComponent,
        CommunicationModalComponent,
        DatesSelectorModal,
        EmailArticleModal,
        AddFavoriteModalComponent,
        AddFavoriteFolderModalComponent,
        SendingHistoryDetailModal,
        XFormComponent,
        XFieldComponent,
        XFormApiErrorComponent,
        XCheckboxComponent,
        XInputDateComponent,
        XInputHiddenComponent,
        XInputPasswordComponent,
        XInputPhoneComponent,
        XInputTextComponent,
        XJsonComponent,
        XRadioComponent,
        XSelectComponent,
        XSelectValueComponent,
        XTextareaComponent,
        XToggleComponent,
        XAddressComponent,
        XSliderComponent,
        CompareToDirective,
        LoadingOverlayComponent,
        UserInsetComponent,
        MainToolbarComponent,
        UserLastConnexionComponent,
        ArticleListItemComponent,
        ToggleListStyleComponent,
        ToggleListMapsComponent,
        LoadingComponent,
        NotDataComponent,
        ArticleTagsComponent,
        ArticleToggleFavoriteButtonComponent,
        ArticleSourcesComponent,
        ArticleContactsComponent,
        ArticleContactsDetailsComponent,
        ArticleSendMailButtonComponent,
        ArticleAddFavoriteButtonComponent,
        ArticleReadUnreadButtonComponent,
        ArticleExportButtonComponent,
        ArticlePrintButtonComponent,
        DatesSelectorButtonComponent,
        BadgeCustomComponent,
        ToggleNotificationButtonComponent,
        ArticleDeleteFavButtonComponent,
        HideTollbarOnScrollDirective,
        Safe,
        SearchListContainerComponent,
        MatProgressBarModule,
        FeButtonComponent,
        FeDatePickerComponent,
        FeDateSelectorComponent,
        FeInputComponent,
        FeProTipsComponent,
        FeCheckboxComponent,
        FeSnackbarComponent,
        FeModalComponent,
        FeDotsMenuComponent,
        FeMenuItemComponent,
        FeSubMenuComponent,
        FeToggleComponent,
        FeArticleItemComponent,
        FeTagComponent,
        FeEntrepriseItemComponent,
        FeMailModalComponent,
        FeArticleModalComponent,
        FeFicheEntrepriseModalComponent,
        FeGestionRecherchesModalComponent,
        FeRechercheLineComponent,
        FeSearchbarComponent,
        FePreviousArticleLineComponent,
        ScrollingModule,
        FeFinancialsGraphModalComponent,
        FeContactEnrichiModalComponent,
        FeLoaderComponent,
        FeCarnetAdressesModalComponent,
        FeCarnetContactModalComponent,
        FeTextAreaComponent,
        FeIconComponent,
        FeCustomerDepartementsModalComponent,
        FeChangePasswordModalComponent,
        FeFavoriteItemComponent,
        FeSendedMailModalComponent,
        FeAskContactsEnrichisModalComponent,
        FeLeadsComponent,
        FeContactCommercialModalComponent
    ]
})
export class AppSharedModule {
}
