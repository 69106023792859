import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  AfterViewChecked,
  ElementRef,
  AfterViewInit, Output, EventEmitter
} from '@angular/core';
import {Subject, Observable, ReplaySubject} from 'rxjs';

import { MediaQueryService } from 'src/app/services/media-query/media-query.service';

import { MailsService } from 'src/app/services/mails/mails.service';
import { MailsHistoryInterface } from 'src/app/interfaces/mails-history/mails-history.interface';
import {IonSearchbar, ModalController, Platform} from '@ionic/angular';
import { SendingHistoryDetailModal } from '../modals/sending-history-detail/sending-history-detail.modal';
import { MailHistoryInterface } from 'src/app/interfaces/mail-history/mail-history.interface';
import { PaginatedListComponent } from '../paginated-list/paginated-list.component';
import {ToastService} from '../../services/toast/toast.service';
import {UserService} from '../../services/user/user.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ArticlesService} from '../../services/articles/articles.service';
import { SelectionModel } from '@angular/cdk/collections';
import { saveAs } from 'file-saver';
import {map, startWith, take, takeUntil} from 'rxjs/operators';
import {File} from '@awesome-cordova-plugins/file/ngx';
import {FileOpener} from '@awesome-cordova-plugins/file-opener/ngx';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDatepicker} from '@angular/material/datepicker';
import {MatSelect} from '@angular/material/select';
import {IconType} from '../parts/fe-icon/fe-icon.component';
import {MatTableDataSource} from '@angular/material/table';
import {ArticleInterface} from '../../interfaces/articles/article.interface';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {FeDateSelectorComponent} from '../parts/fe-date-selector/fe-date-selector.component';
import {formatDate} from '@angular/common';
import {DateArticleService} from '../../services/date-article/date-article.service';
import {AddressesInterface} from '../../interfaces/addresses/addresses';
import {AddressesService} from '../../services/addresses/addresses.service';
import {ContactLineInterface} from '../modals/fe-carnet-adresses-modal/fe-carnet-adresses-modal.component';
import {SnackbarService} from "../../services/snackbar/snackbar.service";
import {MatSidenavContainer} from "@angular/material/sidenav";
import {ModalService} from "../../services/modal/modal.service";
import {FeSendedMailModalComponent} from "../modals/fe-sended-mail-modal/fe-sended-mail-modal.component";
import {FeSearchbarComponent} from '../parts/fe-searchbar/fe-searchbar.component';

@Component({
  selector: 'sending-history',
  templateUrl: 'sending-history.component.html',
  styleUrls: ['sending-history.component.scss'],
})
export class SendingHistoryComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {

  @ViewChild('sidenavContainer', { static: false }) sideNavContainer: MatSidenavContainer;
  @ViewChild('searchBarInput', { static: false }) searchBarInput: FeSearchbarComponent;
  protected control = new FormControl('');
  protected smallSidenavDesktop: boolean = false;
  protected readonly IconType = IconType;
  protected menuIconActions: string = 'keyboard_arrow_down';
  protected menuOpenActions: boolean = false;
  protected historyLength: number = 0;
  protected dateInputText: string = '';
  protected contactsLines: {id: number, label: string}[] = [];
  protected filteredAddress: Observable<{id: number, label: string}[]>;
  protected selectedContacts: {id: number, label: string}[] = [];
  protected selectedAdresses: string[] = [];
  protected searchValue: string = '';
  // start date
  private _searchQuery: string = undefined;
  private isSideNavOpen: boolean = true;
  protected sideNavIcon: string = 'keyboard_double_arrow_right';
  protected mailsSended: MailHistoryInterface[] = []
  @ViewChild('paginator') paginator: MatPaginator;
  protected pageSize: number = 25;
  protected page: number = 0;
  protected loadingInProgress: boolean = false;
  protected set searchQuery(searchQuery: string) {
    this._searchQuery = searchQuery;
  }
  protected get searchQuery(): string {
    return this._searchQuery;
  }
  private firstLoadAutoComplete = true;


  /**
 * Override query from PaginatedListComponent
 */
  protected get query(): any {
    const query: any = {
      page: this.page + 1,
      pageSize: this.pageSize,
      query: this.searchValue,
      startDate: this.startDate,
      endDate: this.endDate,
      idContacts: this.getSelectedContactIds(),
      mailContactsExternes: this.getSelectedContactAdresses()
    };
    return query;
  }

  protected get queryExport(): any {
    const query: any = {
      page: this.page + 1,
      pageSize: this.pageSize,
      query: this.searchValue,
      startDate: this.startDate,
      endDate: this.endDate,
      idContacts: this.getSelectedContactIds(),
      mailContactsExternes: this.getSelectedContactAdresses()
    };
    return query;
  }
  search: string = '';
  isInputFocused: boolean;
  contacts = new FormControl();
  @Input() public selectionModel: SelectionModel<string>;
  private file = new File();
  keywords: Array<string>;
  dropdownList = [];
  dropdownListMultiSelect = [];
  tabContactsSelected = [];
  mailClientsExternes = [];
  public startDatePicker: Date;
  public endDatePicker: Date;
  protected endDate: String = '';
  protected startDate: String = '';
  private firstLoad: boolean = true;

  protected destinataires: {idContact: number, nomContact: string}[];
  public destinatairesMultiFilterCtrl: FormControl = new FormControl();
  public filteredMultiDestinataires: ReplaySubject<{idContact: number, nomContact: string}[]> = new ReplaySubject<{idContact: number, nomContact: string}[]>(1);
  @ViewChild('multiSelect') multiSelect: MatSelect;
  protected _onDestroy = new Subject<void>();


  constructor(
    public mediaQueryService: MediaQueryService
    , public modalController: ModalController
    , public mailsService: MailsService
    , public toastService: ToastService
    , public userService: UserService
    , private dateArticleService: DateArticleService
    , private addressesService: AddressesService
    , private snackbarService: SnackbarService
    , private modalService: ModalService
    , private dialog: MatDialog,
    private articlesService: ArticlesService,
    private platform: Platform,
    private fileOpener: FileOpener
  ) {
    this.dateInputText = '';
   // addressesService.getAddresses().subscribe(res =>  {
   //   res.address.forEach(value => {
   //     this.contactsLines.push({
   //       id: value.addressId,
   //       checked: false,
   //       label: value.data.label,
   //       email: value.data.email
   //     });
   //   });
   // });
  }

  public ngOnInit(): void {

    this.filteredAddress = this.control.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
    );

    this.isInputFocused = false;
    // this.keywords.push('test');
    this.resetPaginator();
    this.reloadHistory();
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  protected setInitialValue() {
    this.filteredMultiDestinataires
        .pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(() => {
          this.multiSelect.compareWith = (a: {idContact: number, nomContact: string}, b: {idContact: number, nomContact: string}) => a && b && a.idContact === b.idContact;
        });
  }

  protected filterDestinatairesMulti() {
    if (!this.destinataires) {
      return;
    }
    // get the search keyword
    let search = this.destinatairesMultiFilterCtrl.value;
    if (!search) {
      this.filteredMultiDestinataires.next(this.destinataires.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredMultiDestinataires.next(
        this.destinataires.filter(destinataire => destinataire.nomContact.toLowerCase().indexOf(search) > -1)
    );
  }

  public deleteFilters(pickerStart: MatDatepicker<any>, pickerEnd: MatDatepicker<any>, ionSearchBar: IonSearchbar) {
    this.startDate = null;
    this.endDate = null;
    pickerStart.select(null);
    pickerEnd.select(null);
    this.contacts.setValue(null);
    this.search = null;
    this.tabContactsSelected = [];
    this.mailClientsExternes = [];
  }

  private inputFocusProcess(matFormField: ElementRef): void {
    if (matFormField && !this.isInputFocused) {
      this.isInputFocused = true;
    }

    if (!matFormField && this.isInputFocused) {
      this.isInputFocused = false;

      setTimeout(
          () => {
            //
            //
          });
    }
  }

  public onDestinataireSelect(event) {
    if (event.isUserInput === true) {
      const indexNom: number = this.mailClientsExternes.indexOf(event.source.value.nomContact);
      const indexID: number = this.tabContactsSelected.indexOf(event.source.value.idContact);
      if (event.source.value.idContact <= 0) {
        if (indexNom === -1) {
          this.mailClientsExternes.push(event.source.value.nomContact);
        } else {
          this.mailClientsExternes.splice(indexNom, 1);
        }
      } else {
        if (indexID === -1) {
          this.tabContactsSelected.push(event.source.value.idContact);
        } else {
          this.tabContactsSelected.splice(indexID, 1);
        }
      }
    }
  }

  public exportArticlesListHistory() {
    this.articlesService.exportArticlesListHistory(this.queryExport).subscribe(
            response => {
              // TODO : Récupérer dynamiquement le nom du fichier (Content-Disposition introuvable dans le header de la response)
              let fileName = 'FirstECO_Profil_export_contacts';

              // TODO NE FONCTIONNE PAS MAIS PISTE DE SOLUTION
              const contentDisposition = response.headers.get('Content-Disposition');
              if (contentDisposition) {
                const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = fileNameRegex.exec(contentDisposition);

                if (matches != null && matches[1]) {
                  fileName = matches[1].replace(/['"]/g, '');
                }
              }
              const fileContent = response.body;

              // Save as for mobile version
              if (this.platform.is('ios') || this.platform.is('android')) {

                this.file.writeFile(this.file.dataDirectory, fileName, fileContent)
                    .then(
                        _ => {

                          this.fileOpener.showOpenWithDialog(this.file.dataDirectory + fileName, 'text/csv')
                              .then(() => console.log('File is opened'))
                              .catch(e => console.log('Error opening file', e));
                        }
                    )
                    .catch(
                        err => {
                          this.file.writeExistingFile(this.file.dataDirectory, fileName, fileContent)
                              .then(
                                  _ => {
                                    this.fileOpener.showOpenWithDialog(this.file.dataDirectory + fileName, 'text/csv')
                                        .then(() => console.log('File is opened'))
                                        .catch(e => console.log('Error opening file', e));
                                  }
                              )
                              .catch(
                                  err => {
                                    alert('Failure');
                                  }
                              );
                        }
                    );
              } else {
                saveAs.saveAs(fileContent, fileName);
              }
            }
        );
  }

  public updateStartDate(dateObject) {
    if (dateObject.value != null) {
      this.startDatePicker = new Date(dateObject.value);
      const year = this.startDatePicker.getFullYear();
      const month = this.startDatePicker.getMonth() + 1;
      const day = this.startDatePicker.getDate().toString();
      this.startDate = year + '-' + month + '-' + day ;
      // this.refreshData();
    } else {
      this.startDate = null;
      // this.refreshData();
    }
  }

  public updateEndDate(dateObject) {
    if (dateObject.value != null) {
      this.endDatePicker = new Date(dateObject.value);
      const year = this.endDatePicker.getFullYear();
      const month = this.endDatePicker.getMonth() + 1;
      const day = this.endDatePicker.getDate().toString();
      this.endDate = year + '-' + month + '-' + day ;
      // this.refreshData();
    } else {
      this.endDate = null;
      // this.refreshData();
    }
  }

  public getMailsHistory(query: {[key: string]: string} = {}): void {
    // this.mailsHistory$ = this.mailsService.getHistory(query);
  }

  /**
   * Load data. Used by PaginatedListComponent
   */
  protected load(): Observable<MailsHistoryInterface> {
    // check if query parameter define by this.watchQueryProperties has changed
    return this.mailsService.getHistory(this.query);
  }

  /**
   * get data and count. Used by PaginatedListComponent
   * @param mailsHistory MailsHistoryInterface
   */
  protected getDataTypedAndCountAfterLoad(mailsHistory: MailsHistoryInterface): { data: Array<MailHistoryInterface>, count: number } {
    return { data: mailsHistory.emails, count: mailsHistory.count };
  }

/**
 * Call by template. Filter mails depending on the field's value.
 * @param event CustomEvent
 */
  public ionChange(event: CustomEvent): void {
    this.searchQuery = event.detail.value;
    // this.getMailsHistory({ query: event.detail.value});
  }


  public async openSendingHistoryDetailModal(item: MailHistoryInterface): Promise<any> {
    const modal = await this.modalController.create(
      {

        component: SendingHistoryDetailModal,
        cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog',
        componentProps: {
          mail: item
        }
      }
    );

    // TODO: Action quand on ferme la modale (ex : raffraichir les données de la liste locale)
    modal.onWillDismiss().then(
      (data: any) => {

      }
    );
    //  Log - READ_MAIL_HISTORY
    this.userService.log('READ_MAIL_HISTORY');
    return await modal.present();
  }

  protected openSendedMailModal(mail: MailHistoryInterface): void {
    this.modalService.showCustomModal(FeSendedMailModalComponent, {data: {mail: mail}})
  }

  public ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngAfterViewChecked(): void {
  }

  protected toogleSidenavSize(): void {

  }

  onDropDownMenuOpenActions($event) {
    $event.stopPropagation();
    this.menuOpenActions = !this.menuOpenActions;
    if (this.menuOpenActions) {
      this.menuIconActions = 'keyboard_arrow_up';
    }
  }

  onMenuActionsClosed() {
    this.menuOpenActions = false;
    this.menuIconActions = 'keyboard_arrow_down';
  }

  protected exportArticlesList(excel: string, selected: number[]): void {

  }

  protected printArticles(): void {

  }

  protected handlePageEvent($event: PageEvent) {
    this.pageSize = $event.pageSize;
    this.page = $event.pageIndex;
    this.reloadHistory();
  }

  protected openDateSelector($event: MouseEvent) {
    const targetAttr = ($event.target as HTMLElement).getBoundingClientRect();
    const dialogConfig = new MatDialogConfig();

    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.backdropClass = 'cdk-overlay-transparent-backdrop';

    let leftDialog = targetAttr.x - targetAttr.width - 20 - 700 + 'px';

    // if input clicked
    if (targetAttr.width > 50) {
      leftDialog = targetAttr.x - targetAttr.width - 200 + 'px';
    }

    dialogConfig.position = {
      top: targetAttr.y + targetAttr.height + 'px',
      left: leftDialog
    };
    dialogConfig.data = {
      dateStart: this.dateArticleService.getDateStartFormatted(),
      dateEnd: this.dateArticleService.getDateEndFormatted()
    };

    const dialogRef = this.dialog.open(FeDateSelectorComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data != null) {
        this.dateArticleService.startDate = formatDate(data.dateStart, 'yyyy-MM-dd', 'fr_FR');
        this.dateArticleService.endDate = formatDate(data.dateEnd, 'yyyy-MM-dd', 'fr_FR');

        const start = formatDate(data.dateStart, 'dd/MM/yyyy', 'fr_FR');
        const end = formatDate(data.dateEnd, 'dd/MM/yyyy', 'fr_FR');
        this.dateInputText = start + ' => ' + end;

        this.startDate = formatDate(data.dateStart, 'yyyy-MM-dd', 'fr_FR');
        this.endDate = formatDate(data.dateEnd, 'yyyy-MM-dd', 'fr_FR');
        this.resetPaginator();
        this.reloadHistory();
      }
    });
  }

  filterChange($event: string) {

  }

  private _filter(value: string): {id: number, label: string}[] {
    const filterValue = this._normalizeValue(value);
    return this.contactsLines.filter(contactLine =>
        this._normalizeValue(contactLine.label).includes(filterValue));
    // return this.contactsLines.filter(contactLine =>
    //     this._normalizeValue(contactLine.label).includes(filterValue) ||
    //     this._normalizeValue(contactLine.email).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  protected clearSBDestinataires(): void {
    this.control.setValue('');
  }

  protected addContactFilter(address: {id: number, label: string}): void {
    let addContact = true;
    this.selectedContacts.forEach(value => {
      if ((address.id > 0 && value.id == address.id) || (address.id == 0 && value.label == address.label)) {
        addContact = false;
      }
    });
    if (addContact) {
      this.selectedContacts.push(address);
      this.resetPaginator();
      this.reloadHistory();
    }
  }

  protected removeContactFilter(address: ContactLineInterface): void {
    for (let i = 0; i < this.selectedContacts.length; i++) {
      if ((address.id > 0 && this.selectedContacts[i].id == address.id)
          || (address.id == 0 && this.selectedContacts[i].label == address.label)) {
        this.selectedContacts.splice(i, 1);
        this.resetPaginator();
        this.reloadHistory();
        return;
      }
    }
  }

  protected exportMailHistory(): void {
    if (this.historyLength > 0) {
      this.articlesService.exportArticlesListHistory(this.queryExport).subscribe(
          response => {
            // TODO : Récupérer dynamiquement le nom du fichier (Content-Disposition introuvable dans le header de la response)
            let fileName = 'FirstECO_export_contacts';

            // TODO NE FONCTIONNE PAS MAIS PISTE DE SOLUTION
            const contentDisposition = response.headers.get('Content-Disposition');
            if (contentDisposition) {
              const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              const matches = fileNameRegex.exec(contentDisposition);

              if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, '');
              }
            }
            const fileContent = response.body;

            // Save as for mobile version
            if (this.platform.is('ios') || this.platform.is('android')) {

              this.file.writeFile(this.file.dataDirectory, fileName, fileContent)
                  .then(
                      _ => {

                        this.fileOpener.showOpenWithDialog(this.file.dataDirectory + fileName, 'text/csv')
                            .then(() => console.log('File is opened'))
                            .catch(e => console.log('Error opening file', e));
                      }
                  )
                  .catch(
                      err => {
                        this.file.writeExistingFile(this.file.dataDirectory, fileName, fileContent)
                            .then(
                                _ => {
                                  this.fileOpener.showOpenWithDialog(this.file.dataDirectory + fileName, 'text/csv')
                                      .then(() => console.log('File is opened'))
                                      .catch(e => console.log('Error opening file', e));
                                }
                            )
                            .catch(
                                err => {
                                  alert('Failure');
                                }
                            );
                      }
                  );
            } else {
              saveAs.saveAs(fileContent, fileName);
            }
          });
    } else {
      this.snackbarService.showDangerSnackbar('Opération impossible',
          'Il n\'y a aucun résultat à exporter.');
    }
  }

  toogleRightSidenavSize() {
    this.isSideNavOpen = !this.isSideNavOpen;
    if (this.isSideNavOpen) {
      this.sideNavIcon = 'keyboard_double_arrow_right';
    } else {
      this.sideNavIcon = 'keyboard_double_arrow_left';
    }
    this.smallSidenavDesktop = !this.smallSidenavDesktop;

    setTimeout(() => {
      this.sideNavContainer.updateContentMargins();
    }, 250);
  }

  protected readonly formatDate = formatDate;

  private reloadHistory() {
    this.loadingInProgress = true;
    let cpt = 0;
    this.mailsService.getHistory(this.query).subscribe(getContacts => {
      for (let i = 0 ; i < getContacts.contacts.length; i++) {

        let idContact = getContacts.contacts[i].id;
        if (idContact === 0) {
          idContact = cpt;
        }
        cpt --;
        this.dropdownList.push({'idContact': idContact, 'nomContact': getContacts.contacts[i].label} );
      }

      if (this.firstLoad) {
        this.contactsLines = getContacts.contacts;
        this.firstLoad = false;
      }

      this.mailsSended = getContacts.emails;
      this.historyLength = getContacts.count;
      // this.dropdownList.forEach(test => console.log(test));
      // for ( let i = 0 ; i < getContacts.nom_contacts_externes.length; i++) {
      //    this.dropdownList.push({'idContact': getContacts.nom_contacts_externes[i], 'nomContact': getContacts.nom_contacts_externes[i]} );
      //  }
      this.destinataires = this.dropdownList;
      this.filteredMultiDestinataires.next(this.destinataires.slice());
      this.destinatairesMultiFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterDestinatairesMulti();
          });
      this.loadingInProgress = false;
    });
  }

  private getSelectedContactIds(): number[] {
    let contactIds: number[] = []
    this.selectedContacts.forEach(value => {
      if (value.id > 0) {
        contactIds.push(value.id);
      }
    });
    return contactIds;
  }

  private getSelectedContactAdresses(): string[] {
    let contactAdresses: string[] = []
    this.selectedContacts.forEach(value => {
      if (value.id == 0) {
        contactAdresses.push(value.label);
      }
    });
    return contactAdresses;
  }

  protected searchValueChanged($event: string): void {
    if ($event.trim().length > 2 || $event.trim() == '') {
      if ($event.trim() != this.searchValue.trim()) {
        this.searchValue = $event;
        this.resetPaginator();
        this.reloadHistory();
      }
    }
  }

  protected clearDate($event: MouseEvent): void {
    if ($event != null) {
      $event.stopPropagation();
    }
    this.startDate = '';
    this.endDate = '';
    this.dateInputText = '';
    this.reloadHistory();
    this.resetPaginator();
  }

  private resetPaginator() {
    this.page = 0;
  }

  protected reinitCriteres(): void {
    this.searchValue = '';
    this.selectedContacts = [];
    this.searchBarInput.clear();
    this.clearDate(null);
  }

  initAutoComplete() {
    if (this.firstLoadAutoComplete) {
      this.firstLoadAutoComplete = false;
      this.control.setValue('');
    }
  }
}
