import {AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {AlertController, ModalController, Platform} from '@ionic/angular';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {EntreprisesService} from '../../../services/entreprises/entreprises.service';
import {FEEntrepriseContactInterface} from '../../../interfaces/fe-entreprise-contact/fe-entreprise-contact.interface';
import {EntrepriseContactInterface} from '../../../interfaces/entreprise-contact/entreprise-contact.interface';
import {UserService} from '../../../services/user/user.service';
import {ContactDetailComponent} from '../contact-detail/contact-detail.component';
import {MediaQueryService} from '../../../services/media-query/media-query.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {SessionInterface} from '../../../interfaces/session/session.interface';
import {Subject} from 'rxjs';

declare var cordova: any;

export interface PeriodicElement {
    role: string;
    nom: string;
    linkedIn: string;
    mail: string;
    mailPertinence: string;
}

export interface FicheEntrepriseContact {
    id: string;
    civility: string;
    firstname: string;
    lastName: string;
    domain_code: string;
    domain_label: string;
    level_code: string;
    level_label: string;
    role: string;
    email: string;
    email_score: string;
    email_test_result: string;
    email_test_reason: string;
    email_test_date: string;
    linkedin_url: string;
    extraction_date: string;
    anonymized: boolean;
    color: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
    {role: 'Président', nom: 'Jean DUPONT', linkedIn: 'Lien linkedin', mail: 'jd@entreprise.fr', mailPertinence: 'bonne'},
    {role: 'Développeur', nom: 'Jacques DUBOIS', linkedIn: 'Lien linkedin', mail: 'jd@entreprise.fr', mailPertinence: 'bonne'},
    {role: 'Commercial', nom: 'Jeanne BOISSON', linkedIn: 'Lien linkedin', mail: 'jd@entreprise.fr', mailPertinence: 'bonne'},
    {role: 'PDG', nom: 'Georges KALOK', linkedIn: 'Lien linkedin', mail: 'jd@entreprise.fr', mailPertinence: 'bonne'},
    {role: 'Vendeur', nom: 'Marc LAMEP', linkedIn: 'Lien linkedin', mail: 'jd@entreprise.fr', mailPertinence: 'bonne'},
    {role: 'Alternant', nom: 'Sylvère SOLRA', linkedIn: 'Lien linkedin', mail: 'jd@entreprise.fr', mailPertinence: 'bonne'},
    {role: 'Stagiaire', nom: 'Nathalie PRAKIL', linkedIn: 'Lien linkedin', mail: 'jd@entreprise.fr', mailPertinence: 'bonne'},
    {role: 'Salarié', nom: 'Xavier RORTE', linkedIn: 'Lien linkedin', mail: 'jd@entreprise.fr', mailPertinence: 'bonne'},
    {role: 'Employé', nom: 'Alexandre MINOR', linkedIn: 'Lien linkedin', mail: 'jd@entreprise.fr', mailPertinence: 'bonne'},
    {role: 'Ouvrier', nom: 'Isabelle GROKA', linkedIn: 'Lien linkedin', mail: 'ig@entreprise.fr', mailPertinence: 'bonne'},
];

@Component({
    selector: 'all-contact',
    templateUrl: 'all-contacts.modal.html',
    styleUrls: ['all-contacts.modal.scss'],
})
export class AllContactsModal implements OnInit, AfterViewInit {

    private unsubscribe$: Subject<any> = new Subject<any>();
    @Input() public sessionData: SessionInterface;

    constructor(private modalController: ModalController,
                private _liveAnnouncer: LiveAnnouncer,
                private entreprisesService: EntreprisesService,
                private platform: Platform,
                private userService: UserService,
                public mediaQueryService: MediaQueryService,
                private alertController: AlertController,
                public dialog: MatDialog) {
        this.userService.getSessionDatas().pipe(
            takeUntil(this.unsubscribe$)
        )
            .subscribe(
                (sessionData: SessionInterface) => {
                    if (sessionData !== undefined) {
                        this.sessionData = sessionData;
                    }
                }
            );
    }
    displayedColumns: string[] = ['role', 'nom', 'linkedIn', 'mail', 'mailPertinence', 'credit'];
    articleContactsDC: string[] = ['firstName', 'mail', 'telephone', 'reseaux'];
    othersContactsDC: string[] = ['firstName', 'mail', 'telephone', 'reseaux'];
    dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
    allContacts: MatTableDataSource<FEEntrepriseContactInterface>;
    articleContactsDS: MatTableDataSource<EntrepriseContactInterface>;
    othersContactsDS: MatTableDataSource<EntrepriseContactInterface>;
    entrepriseName: string;

    public articleContacts: EntrepriseContactInterface[];
    public othersContacts: EntrepriseContactInterface[];
    public entrepriseId: number;
    public allContactLoaded: boolean = false;
    public creditCount;
    private creditUsed: boolean = false;

    @ViewChild('allContactPaginator', { static: true }) allContactPaginator: MatPaginator;
    @ViewChild('allContactSort') allContactSort: MatSort;
    @ViewChild('otherContactSort') otherContactSort: MatSort;
    @ViewChild('allContactPaginatorContainer') allContactPaginatorContainer: ElementRef;

    private static convertContact(contactSource: FEEntrepriseContactInterface, contactAPI: FEEntrepriseContactInterface) {
        const contactConverted: FEEntrepriseContactInterface = contactSource;
        contactConverted.firstName = contactAPI.firstName;
        contactConverted.lastName = contactAPI.lastName;
        contactConverted.civility = contactAPI.civility;
        contactConverted.domain_code = contactAPI.domain_code;
        contactConverted.domain_label = contactAPI.domain_label;
        contactConverted.level_code = contactAPI.level_code;
        contactConverted.email = contactAPI.email;
        contactConverted.email_score = contactAPI.email_score;
        contactConverted.linkedin_url = contactAPI.linkedin_url;
        contactConverted.extraction_date = contactAPI.extraction_date;
        contactConverted.anonymized = contactAPI.anonymized;
        contactConverted.email_test_date = contactAPI.email_test_date;

        return contactConverted;
    }

    private static removeAccent(rawText: string): string {
        let traitedText: string = rawText.toLowerCase();
        traitedText = traitedText.replace(new RegExp('[àáâãäå]', 'g'), 'a');
        traitedText = traitedText.replace(new RegExp('æ', 'g'), 'ae');
        traitedText = traitedText.replace(new RegExp('ç', 'g'), 'c');
        traitedText = traitedText.replace(new RegExp('[èéêë]', 'g'), 'e');
        traitedText = traitedText.replace(new RegExp('[ìíîï]', 'g'), 'i');
        traitedText = traitedText.replace(new RegExp('ñ', 'g'), 'n');
        traitedText = traitedText.replace(new RegExp('[òóôõö]', 'g'), 'o');
        traitedText = traitedText.replace(new RegExp('œ', 'g'), 'oe');
        traitedText = traitedText.replace(new RegExp('[ùúûü]', 'g'), 'u');
        traitedText = traitedText.replace(new RegExp('[ýÿ]', 'g'), 'y');
        return traitedText;
    }

    ngAfterViewInit() {
        this.entreprisesService.getEntrepriseAllContacts(this.entrepriseId).subscribe(
            entrepriseFromApi => {
                this.creditCount = entrepriseFromApi.credits;
                this.allContactLoaded = true;
                this.allContacts = new MatTableDataSource<FEEntrepriseContactInterface>(entrepriseFromApi.contacts);
                this.allContacts.filterPredicate = (data: FEEntrepriseContactInterface, filter: string) => {
                    const textToSearch = (data['role'] && AllContactsModal.removeAccent(data['role'].toLowerCase()))
                        || (data['domain_label'] && AllContactsModal.removeAccent(data['domain_label'].toLowerCase()))
                        || (data['level_label'] && AllContactsModal.removeAccent(data['level_label'].toLowerCase()))
                        || '';
                    return textToSearch.indexOf(filter) !== -1;
                };
                this.allContacts.paginator = this.allContactPaginator;
                this.allContacts.sort = this.allContactSort;
                if (entrepriseFromApi.contact_count > 0) {
                    this.allContactPaginatorContainer.nativeElement.className = 'displayPaginator';
                }
            });
        this.othersContactsDS.sort = this.otherContactSort;
    }

    ngOnInit() {
        this.allContactLoaded = false;
        this.creditUsed = false;
        this.allContactPaginator._intl.itemsPerPageLabel = 'Contacts';
        this.articleContactsDS = new MatTableDataSource<EntrepriseContactInterface>(this.articleContacts);
        this.othersContactsDS = new MatTableDataSource<EntrepriseContactInterface>(this.othersContacts);
    }

    public close(): void {
        let message: string;
        if (this.creditCount < 0) {
            message = 'Si vous fermer cette fenêtre, vous allez perdre les contacts visualisés.';
        } else {
            message = 'Si vous fermer cette fenêtre, les informations seront perdues et vous devrez réutiliser des\n' +
                'crédits pour consulter à nouveau les informations détaillées des contacts.';
        }
        if (this.creditUsed) {
            const dialogRef = this.dialog.open(PreventCloseAllContactDialog, {
                panelClass: 'closeContactsModal',
                data : {
                    message: message
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result === true) {
                    this.modalController.dismiss();
                }
            });
        } else {
            this.modalController.dismiss();
        }
    }

    public async checkLoadFullDAtaForAContact(element: FEEntrepriseContactInterface) {
        const alert = await this.alertController.create({
            header: 'Attention',
            message: 'Confirmez-vous l\'utilisation d\'un crédit pour voir ce contact ?',
            buttons: [
                {
                    text: 'Annuler',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => { }
                }, {
                    text: 'Oui',
                    handler: () => this.loadFullDAtaForAContact(element)
                }
            ]
        });
        await alert.present();
    }

    public async loadFullDAtaForAContact(element: FEEntrepriseContactInterface) {
        document.getElementById('contactBtn-' + element.id).setAttribute('disabled', '');
        this.entreprisesService.getFullDataForAContact(this.entrepriseId, element.id).subscribe(
            value => {
                this.creditUsed = true;
                this.creditCount = value.credits;
                let inProgress: boolean = true;
                let i: number = 0;
                while (inProgress) {
                    if (this.allContacts.data[i].id === element.id) {
                        this.allContacts.data[i] = AllContactsModal.convertContact(this.allContacts.data[i], value.contacts[0]);
                        inProgress = false;
                    }
                    i++;
                    if (i >= this.allContacts.data.length) {
                        inProgress = false;
                    }
                }
                this.allContacts.data = this.allContacts.data;
                // this.allContacts.data.forEach(value1 => {
                //   if (value1.id === element.id) {
                //     value1 = value.contacts[0];
                //   }
                // });
            });
    }

    public async openContactSheet(element: FEEntrepriseContactInterface) {
        const modal = await this.modalController.create({
            component: ContactDetailComponent,
            cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'smallDesktopDialog',
            componentProps: {
                contact: element
            },
            backdropDismiss: true
        });
        return await modal.present();
    }

    /** Announce the change in sort state for assistive technology. */
    announceSortChange($event: Sort) {
        if ($event.direction) {
            this._liveAnnouncer.announce(`Sorted ${$event.direction}ending`);
        } else {
            this._liveAnnouncer.announce('Sorting cleared');
        }
    }

    /**
     * Ouverture du lien et Log de l'action
     * @param url
     * @param type
     */
    public openUrl(url: string, type?: string) {
        if (!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
            // if (!this.platform.url().startsWith('http')) {
            cordova.InAppBrowser.open(url, '_system', 'hidden=yes,location=no');
            // window.open(this.entreprise.website, '_system');
        } else {
            window.open(url, '_blank');
        }

        if (type !== undefined) {
            this.logSocialNetworkClick();
        } else {
            this.logWebsiteClick();
        }
    }

    /**
     * Log de l'action CLICK_WEBSITE
     */
    public logWebsiteClick(): void {
        //  Log - CLICK_WEBSITE
        this.userService.log('CLICK_WEBSITE');
    }

    /**
     * Log de l'action CLICK_MAIL
     */
    public logMailClick(): void {
        //  Log - CLICK_MAIL
        this.userService.log('CLICK_MAIL');
    }

    /**
     * Log de l'action CLICK_RS
     */
    public logSocialNetworkClick(): void {
        //  Log - CLICK_RS
        this.userService.log('CLICK_RS');
    }

    async ionViewDidEnter() {
        const modal = await this.modalController.getTop();
        const backdrop = modal.querySelector('ion-backdrop') as HTMLIonBackdropElement;
        backdrop.classList.add('clickable');
        backdrop.onclick = () => {
            alert('a');
        };
    }

    applyContactFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.allContacts.filter = AllContactsModal.removeAccent(filterValue.trim().toLowerCase());
    }
}

@Component({
    selector: 'prevent-close-all-contact-dialog',
    templateUrl: 'prevent-close-all-contact-dialog.html',
    styleUrls: ['all-contacts.modal.scss'],
})
export class PreventCloseAllContactDialog {

    public message: string;

    constructor(private preventModalController: ModalController,
                @Inject(MAT_DIALOG_DATA) public data) {
        this.message = this.data.message;
    }

    public closePreventModal(): void {
        this.preventModalController.dismiss();
    }
}
