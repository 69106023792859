<!-- pour le *ngFor -->
<div class="tagsContainerMargin" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
  <div class="tags">
    <ng-container *ngIf="tags.locations && tags.locations.length > 0">
      <div *ngFor="let tag of tags.locations">
        <div [ngClass]="{'primaryBgColor': tag.matchSearch, 'greyLightBgColor': !tag.matchSearch }" class="tag"[innerHTML]="getHighlightedText(tag.label)">

        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="tags.sectors && tags.sectors.length > 0 && isFromBusinessSignals && hasSectorsInSearch">
      <div *ngFor="let sector of tags.sectors">
        <div *ngIf="sector.matchSearch" [ngClass]="{'primaryBgColor': sector.matchSearch}" class="tag"[innerHTML]="getHighlightedText(sector.label)">

        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="tags.sectors && tags.sectors.length > 0 && isFromBusinessSignals && !hasSectorsInSearch">
      <div *ngFor="let sector of tags.sectors">
        <div [ngClass]="{'primaryBgColor': sector.matchSearch, 'greyLightBgColor': !sector.matchSearch }" class="tag"[innerHTML]="getHighlightedText(sector.label)">

        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="tags.sectors && tags.sectors.length > 0 && !isFromBusinessSignals">
      <div *ngFor="let sector of tags.sectors">
        <div [ngClass]="{'primaryBgColor': sector.matchSearch, 'greyLightBgColor': !sector.matchSearch }" class="tag"[innerHTML]="getHighlightedText(sector.label)">

        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="tags.topics && tags.topics.length > 0 && isFromBusinessSignals && hasTopicsInSearch">
      <div *ngFor="let tag of tags.topics">
        <div *ngIf="tag.matchSearch" [ngClass]="{'primaryBgColor': tag.matchSearch}" class="tag"[innerHTML]="getHighlightedText(tag.label)">

        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="tags.topics && tags.topics.length > 0 && isFromBusinessSignals && !hasTopicsInSearch">
      <div *ngFor="let tag of tags.topics">
        <div [ngClass]="{'primaryBgColor': tag.matchSearch, 'greyLightBgColor': !tag.matchSearch }" class="tag"[innerHTML]="getHighlightedText(tag.label)">

        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="tags.topics && tags.topics.length > 0 && !isFromBusinessSignals">
      <div *ngFor="let tag of tags.topics">
        <div [ngClass]="{'primaryBgColor': tag.matchSearch, 'greyLightBgColor': !tag.matchSearch }" class="tag"[innerHTML]="getHighlightedText(tag.label)">

        </div>
      </div>
    </ng-container>
  </div>
  <div class="tags" *ngIf="leadsAvailable && predictions !== undefined && predictions.length > 0">
    <!--  <ng-container *ngIf="leadsAvailable && tags.topics != null && tags.topics.length > 0">-->
    <!--    <div *ngFor="let tag of tags.topics">-->
    <!--      <div [ngClass]="{'redLightBgColor': tag.matchSearch, 'redBgColor': !tag.matchSearch }" class="tag">-->
    <!--        {{tag.label}}-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </ng-container>-->
    <mat-label class="predictionHeader">Leads Prédictifs®</mat-label>
    <div *ngIf="isMobile" class="tag redBgColor" style="cursor: pointer;" (click)="showLeads($event)">...</div>
    <ng-container *ngIf="(leadsOpened || !isMobile) && leadsAvailable && predictions !== undefined && predictions.length > 0">
      <div *ngFor="let tag of predictions;">
        <div [ngClass]="{'redLightBgColor': tag.matchSearch, 'redBgColor': !tag.matchSearch }" class="tag" *ngIf="checkMatching(tag)" [innerHTML]="getHighlightedText(tag.label)">
        
        </div>
      </div>
    </ng-container>
  </div>
</div>

