<div class="loaderDuringSubmit" *ngIf="isSending">
  <mat-progress-spinner class="" color="accent" mode="indeterminate" diameter="40"></mat-progress-spinner>
</div>
<!-- <form [formGroup]="formGroup" novalidate (ngSubmit)="onSubmit()" enctype="multipart/form-data"> -->
<form [formGroup]="formGroup" novalidate enctype="multipart/form-data" (keydown.enter)="onSubmit()" >
  <div *ngFor="let field of fields" [class]="field.type">

    <!-- text / email / tel -->
    <x-input-text *ngIf="(field.type === 'text' || field.type === 'email' || field.type === 'tel' || field.type === 'number') && !field.hideThisConditionField " 
      [formControlName]="field.name" [formControl]="formGroup.controls[field.name]"
      [field]="field"></x-input-text>

    <!-- hidden -->
    <x-input-hidden *ngIf="field.type === 'hidden' && !field.hideThisConditionField " 
      [formControlName]="field.name" [formControl]="formGroup.controls[field.name]"
      [field]="field"></x-input-hidden>

    <!-- address -->
    <x-address *ngIf="field.type === 'address' && !field.hideThisConditionField "
      [formControlName]="field.name" [formControl]="formGroup.controls[field.name]" [field]="field"></x-address>

    <!-- phone -->
    <x-input-phone *ngIf="field.type === 'phonenumber' && !field.hideThisConditionField "
      [formControlName]="field.name" [formControl]="formGroup.controls[field.name]" [field]="field"></x-input-phone>
    
    <!-- json -->
    <x-json *ngIf="field.type === 'json' && !field.hideThisConditionField " (deleteFromXJson)="deleteFromXJson($event)"
      [formControlName]="field.name" [formControl]="formGroup.controls[field.name]" [field]="field"></x-json>

    <!-- password  -->
    <x-input-password *ngIf="field.type === 'password' && !field.hideThisConditionField "
      [formControlName]="field.name" [formControl]="formGroup.controls[field.name]" [field]="field"></x-input-password>

    <!-- date -->
    <x-input-date *ngIf="(field.type === 'date') && !field.hideThisConditionField "
    [formControlName]="field.name" [formControl]="formGroup.controls[field.name]" [field]="field"></x-input-date>

    <!-- select  -->
    <x-select *ngIf="field.type === 'select' && !field.hideThisConditionField "
    [formControlName]="field.name" [formControl]="formGroup.controls[field.name]"
      [field]="field"></x-select>
    
      <!-- select_value -->
    <x-select-value *ngIf="field.type === 'select_values' && !field.hideThisConditionField "
    [formControlName]="field.name" [formControl]="formGroup.controls[field.name]"
      [field]="field"></x-select-value>

    <!-- checkbox -->
    <x-checkbox *ngIf="field.type === 'checkbox' && !field.hideThisConditionField "
    [formControlName]="field.name" [formControl]="formGroup.controls[field.name]"
      [field]="field"></x-checkbox>

    <!-- radio -->
    <x-radio *ngIf="field.type === 'radio' && !field.hideThisConditionField " 
    [formControlName]="field.name" [formControl]="formGroup.controls[field.name]"
      [field]="field"></x-radio>
  
    <!-- toggle -->
    <x-toggle *ngIf="field.type === 'boolean' && !field.hideThisConditionField " 
    [formControlName]="field.name" [formControl]="formGroup.controls[field.name]"
      [field]="field"></x-toggle>


    <!-- slider -->
    <x-slider *ngIf="field.type === 'slider' && !field.hideThisConditionField " [formControlName]="field.name"
      [formControl]="formGroup.controls[field.name]" [field]="field"></x-slider>


    <!-- textarea -->
    <x-textarea *ngIf="field.type === 'textarea' && !field.hideThisConditionField " [formControlName]="field.name" [formControl]="formGroup.controls[field.name]"
      [field]="field"></x-textarea>

    
    <!-- static_text -->
    <ng-container *ngIf="field.type === 'static_text' && !field.hideThisConditionField  ">
      <div class="staticText" [ngClass]="field.template">{{field.default}}</div>
    </ng-container>



  </div>

  <x-form-api-error [httpErrorResponse]="httpErrorResponse" [group]="formGroup"></x-form-api-error>
  
  <div class="submitButtonContainer" *ngIf="!hideSubmitButton && fields && fields.length !== 0 ">
    <button #submitButton class="submitButton ttu" mat-raised-button [color]="submitButtonColor" type="button" (click)="onSubmit()" [disabled]="!formGroup.valid">{{submitButtonLabel}}</button>
  </div>

</form>