import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { AppPreferencesService } from 'src/app/services/app-preferences/app-preferences.service';
import {takeUntil} from 'rxjs/operators';
import {SessionInterface} from '../../interfaces/session/session.interface';
import {UserService} from '../../services/user/user.service';

@Component({
  selector: 'toggle-list-maps',
  templateUrl: 'toggle-list-maps.component.html',
  styleUrls: ['toggle-list-maps.component.scss'],
})
export class ToggleListMapsComponent implements OnInit, OnDestroy {

  @Output() public listOrMapsChanged: EventEmitter<any> = new EventEmitter<any>();

  private unsubscribe$: Subject<any> = new Subject<any>();

  public listOrMaps: string;

  public mapAvailable: boolean = false;
  public mapAvailableMessage: string = '';

  constructor(
    private appPreferencesService: AppPreferencesService
      , private userService: UserService
  ) {}

  public ngOnInit(): void {
    this.appPreferencesService.get('listOrMaps')
    .then((listOrMaps: string) => {
      this.listOrMaps = listOrMaps ? listOrMaps : 'list';
      this.listOrMapsChanged.emit(this.listOrMaps);
    });

    this.userService.getSessionDatas().pipe(
        takeUntil(this.unsubscribe$)
    )
        .subscribe(
            (sessionData: SessionInterface) => {
              if (sessionData !== undefined) {
                sessionData.appConfig.viewConfigs.forEach(value => {
                  if (value.component === 'GEOLOCATION_MAP') {
                    if (value.state === 'visible') {
                      this.mapAvailable = true;
                    }
                    this.mapAvailableMessage = value.message !== undefined && value.message !== '' ? value.message : 'Carte';
                  }
                });
              }
            }
        );
  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public toggleListOrMaps(value: string): void {
    this.listOrMaps = value;
    this.appPreferencesService.set('listOrMaps', this.listOrMaps);
    this.listOrMapsChanged.emit(value);
  }

}
