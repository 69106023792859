import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { XFieldComponent } from '../x-field/x-field.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'x-slider',
  templateUrl: 'x-slider.component.html',
  styleUrls: ['./x-slider.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => XSliderComponent), multi: true }
  ]
})
export class XSliderComponent extends XFieldComponent {

  private test: string;

  constructor(
    protected matSnackBar: MatSnackBar
  ) {
    super(matSnackBar);
  }

}
