import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {ModalController, NavParams, Platform} from '@ionic/angular';
import {Printer, PrintOptions} from '@awesome-cordova-plugins/printer/ngx';
import {ArticleInterface} from 'src/app/interfaces/articles/article.interface';
import {ArticlesService} from 'src/app/services/articles/articles.service';
import {takeUntil} from 'rxjs/operators';
import {PrintInterface} from 'src/app/interfaces/print/print.interface';
import {EmailArticleModal} from '../email-article/email-article.modal';
import {AllContactsModal} from '../all-contacts/all-contacts.modal';
import {MediaQueryService} from 'src/app/services/media-query/media-query.service';
import {EntrepriseInterface} from '../../../interfaces/entreprise/entreprise.interface';
import {MarkerIconInterface} from '../../../interfaces/marker-icon/marker-icon.interface';
import {EntreprisesService} from '../../../services/entreprises/entreprises.service';
import {EntrepriseArticleInterface} from '../../../interfaces/entreprise-article/entreprise-article.interface';
import {ReadingArticleModal} from '../reading-article/reading-article.modal';
import {EntrepriseEtablissementInterface} from '../../../interfaces/entreprise-etablissement/entreprise-etablissement.interface';
import {EntrepriseContactInterface} from '../../../interfaces/entreprise-contact/entreprise-contact.interface';
import {UserService} from '../../../services/user/user.service';
import {ContactInterface} from '../../../interfaces/contact/contact.interface';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastService} from '../../../services/toast/toast.service';
import {SessionInterface} from '../../../interfaces/session/session.interface';
import {Utils} from '../../../classes/Utils';
import {FicheEntrepriseStatsComponent} from './fiche-entreprise-stats/fiche-entreprise-stats.component';
import {CommunicationModalComponent} from '../communication-modal/communication-modal.component';
declare var cordova: any;

@Component({
  selector: 'fiche-entreprise',
  templateUrl: 'fiche-entreprise.modal.html',
  styleUrls: ['fiche-entreprise.modal.scss'],
})
export class FicheEntrepriseModal implements OnInit, OnDestroy, AfterViewInit {

  protected unsubscribe$: Subject<any> = new Subject<any>();
  // @ViewChild('agmMap') agmMap: AgmMap;

  public article: any; // TODO: Article interface
  // TODO: WARNING different display if we come from article or favorites. Not developped yet.
  public from: string; // article or favorite // display modal for article or favorite

  public entreprise: EntrepriseInterface;
  public entrepriseId: number;
  public entrepriseArticles: Array<EntrepriseArticleInterface>;
  public articleEtablissements: Array<EntrepriseEtablissementInterface>;
  public allEtablissements: Array<EntrepriseEtablissementInterface>;
  public articleContacts: Array<EntrepriseContactInterface>;
  public allContacts: Array<EntrepriseEtablissementInterface>;
  public etablissementIncrementor: number = 0;
  public articleIncrementor: number = 5;
  public contactIncrementor: number = 3;
  public mandataireIncrementor: number = 3;
  public loadMap;
  public locale: any; // prevent build error
  public webToLeadCompanies: Array<String> = Utils.webToLeadCompanies;
  public markerIcon: MarkerIconInterface = {
    url: './assets/images/marker_current.png',
    scaledSize: {
      width: 30,
      height: 45
    }
  };
  public companyId: string;
  public webToLead: boolean;
  public prospect: boolean;
  private contactEnrichis: boolean;

  constructor(
    private modalController: ModalController
    , public mediaQueryService: MediaQueryService
    , private platform: Platform
    , private navParams: NavParams
    , private printer: Printer
    , public toastService: ToastService
    , private articlesService: ArticlesService
    , private entreprisesService: EntreprisesService
    , private userService: UserService
  ) { }

  public ngOnInit(): void {
    this.article = this.navParams.get('article');

    this.userService.getSessionDatas().pipe(
        takeUntil(this.unsubscribe$)
    )
        .subscribe(
            (sessionData: SessionInterface) => {
              if (sessionData !== undefined) {
                this.companyId = sessionData.customerDetails.companyId;
                sessionData.appConfig.viewConfigs.forEach(value => {
                  if (value.component === 'WEBTOLEAD') {
                    if (value.state === 'visible') {
                      this.webToLead = true;
                    }
                  }
                  if (value.component === 'FICHE_ENTREPRISE_CONTACTS_ENRICHIS') {
                    if (value.state === 'visible') {
                      this.contactEnrichis = true;
                    }
                  }
                });
                this.prospect = sessionData.productStatus === 'prospect';
              }
            }
        );

    if (this.entreprise != null && this.entreprise.etablissements != null) {
      this.articleEtablissements = this.entreprise.etablissements;
    }
    if (this.entreprise != null && this.entreprise.contacts != null) {
      this.articleContacts = this.entreprise.contacts;
    }
    if (this.entrepriseId) {
      this.userService.log('CLICK_FICHE', [this.article], this.entrepriseId);

      this.entreprisesService.getEntreprise(this.entrepriseId + '').subscribe(
          entrepriseFromApi => {
            this.entreprise = entrepriseFromApi.entrepriseData;

            this.entreprise.financials?.forEach(value =>  {
              if (value.annualSalesNumber) {
                this.entreprise.chiffreAffaire = value.annualSales;
                this.entreprise.chiffreAffaireAnnee = value.year;
              }
              if (value.netProfilNumber) {
                this.entreprise.resultatNet = value.netProfit;
                this.entreprise.resultatNetAnnee = value.year;
              }
              if (value.effectif) {
                this.entreprise.effectif = value.effectif + '';
                this.entreprise.effectifAnnee = value.year;
              }
            });
            // if (this.entreprise.financials && this.entreprise.financials.length > 0) {
            //   this.entreprise.chiffreAffaire = this.entreprise.financials[this.entreprise.financials.length - 1].annualSales;
            //   this.entreprise.resultatNet = this.entreprise.financials[this.entreprise.financials.length - 1].netProfit;
            // }
            this.entrepriseArticles = entrepriseFromApi.articles;

            if (this.articleEtablissements) {
              this.articleEtablissements.forEach(articleEtablissement => {
                let inProgress: boolean = true;
                let i: number = 0;
                while (inProgress) {
                  if (this.entreprise.etablissements[i].etablissementId === articleEtablissement.etablissementId) {
                    this.entreprise.etablissements.splice(i, 1);
                    inProgress = false;
                  }
                  i++;
                  if (i >= this.entreprise.etablissements.length) {
                    inProgress = false;
                  }
                }
              });
            } else {
              this.etablissementIncrementor = 5;
            }

            if (this.articleContacts) {
              this.articleContacts.forEach(articleContact => {
                let inProgress: boolean = true;
                let i: number = 0;
                while (inProgress) {
                  if (this.entreprise.contacts[i].contactId === articleContact.contactId) {
                    this.entreprise.contacts.splice(i, 1);
                    inProgress = false;
                  }
                  i++;
                  if (i >= this.entreprise.contacts.length) {
                    inProgress = false;
                  }
                }
              });
            }
          });
    } else {
      this.articleContacts = [];
    }
    this.from = this.navParams.get('from');

    // this.entreprise = this.navParams.get('from');

  }


  ngAfterViewInit() {
    // pour la map qui ne veut pas se charger. Ne fonctionne pas sans le timeout...
    setTimeout(() => this.loadMap = true, 0);
  }

  public close(): void {
    this.modalController.dismiss();
  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public async openEmailModal(article: ArticleInterface) {
    const modal = await this.modalController.create({
      component: EmailArticleModal,
      cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog',
      componentProps: {
        article: article
      }
    });


    return await modal.present();
  }

  public async openAllContactsModal() {
    if (this.contactEnrichis) {
      const modal = await this.modalController.create({
        component: AllContactsModal,
        cssClass: [this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog',
                   'allContactModal'],
        backdropDismiss: false,
        componentProps: {
          entrepriseId: this.entreprise.entrepriseId,
          articleContacts: this.articleContacts,
          othersContacts: this.entreprise.contacts,
          entrepriseName: this.entreprise.name
        }
      });
      return await modal.present();
    } else {
      this.userService.accessFeature('contacts_enrichis').subscribe();
      const communication = {
        message: '<div align="center"><a href="https://www.firsteco.fr/revue/plaquette_contacts_enrichis.pdf" target="_blank"><img src="https://www.firsteco.fr/revue/images/contacts_enrichis_efficience.jpg" /></a></div>\n',
        title: 'Contacts de l\'entreprise ' + this.entreprise.name,
        hideButton: true
      };
      const modal = await this.modalController.create({
        component: CommunicationModalComponent,
        cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog',
        componentProps: {
          communications: communication
        }
      });
      return await modal.present();
    }
  }

  public openPrintModal(article: ArticleInterface) {
    this.articlesService.printArticlesList([article.id]).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      (res: PrintInterface) => {
        // Mobile printing
        if (this.platform.is('ios') || this.platform.is('android')) {
          this.printer.isAvailable()
            .then(onSuccess => {
            },
              onError => {
              }
            );

          const options: PrintOptions = {
            name: 'FirstECO_Profil_print',
            orientation: 'portrait'
          };

          this.printer.print(res.html, options)
            .then(onSuccess => {
            },
              onError => {
              }
            );
        } else {
          const popupWin = window.open('', '_blank');
          popupWin.document.open();
          popupWin.document.write(res.html);
          popupWin.document.close();
          setTimeout(() => popupWin.print(), 1000);
        }
      }
    );
  }

  public upEtablissementsIncrementor() {
    this.etablissementIncrementor += 5;
  }

  public upArticlesIncrementor() {
    this.articleIncrementor += 5;
  }

  public upContactsIncrementor() {
    this.contactIncrementor += 3;
  }

  public upMandatairesIncrementor() {
    this.mandataireIncrementor += 3;
  }

  public openReadingModal(articleId: number, enable: boolean) {
    if (enable) {
      let articleToShow: ArticleInterface;

      const Query = {
        articles: articleId
      };
      // this.articlesService.getArticlesContent([articleId]).subscribe(
      //     articleFromApi => {
      //       articleToShow = articleFromApi[0];
      //       this.openArticleModal(articleToShow);
      //       // Log de l'ouverture d'article
      //       this.userService.log('OPEN_SINGLE_ARTICLE');
      //     }
      // );
      this.articlesService.getArticlesContent(Query).subscribe(
          articleFromApi => {
            articleToShow = articleFromApi[0];
            this.openArticleModal(articleToShow);
            // Log de l'ouverture d'article
            this.userService.log('OPEN_SINGLE_ARTICLE');
          }
      );
    }
  }

  public async openArticleModal(article: ArticleInterface) {
    const modal = await this.modalController.create({
      component: ReadingArticleModal,
      cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog',
      componentProps: {
        article: article,
        from: this.from
      },
      backdropDismiss: false
    });

    return await modal.present();
  }

  public openLink(contact: EntrepriseContactInterface) {
    if (!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
      // if (!this.platform.url().startsWith('http')) {
      cordova.InAppBrowser.open(contact.linkedin, '_system', 'hidden=yes,location=no');
      // window.open(this.entreprise.website, '_system');
    } else {
      window.open(contact.linkedin, '_blank');
    }
  }

  /**
   * Ouverture du lien et Log de l'action
   * @param url
   * @param type
   */
  public openUrl(url: string, type?: string) {
    if (!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
      // if (!this.platform.url().startsWith('http')) {
      cordova.InAppBrowser.open(url, '_system', 'hidden=yes,location=no');
      // window.open(this.entreprise.website, '_system');
    } else {
      window.open(url, '_blank');
    }

    if (type !== undefined) {
      this.logSocialNetworkClick();
    } else {
      this.logWebsiteClick();
    }
  }

  public openMail(mail: string) {
    if (!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
      // if (!this.platform.url().startsWith('http')) {
      cordova.InAppBrowser.open('mailto:' + mail, '_system', 'hidden=yes,location=no');
      // window.open(this.entreprise.website, '_system');
    } else {
      window.open('mailto:' + mail);
    }
  }

  public openPhone(phone: string) {
    if (!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
      // if (!this.platform.url().startsWith('http')) {
      cordova.InAppBrowser.open('tel:' + phone, '_system', 'hidden=yes,location=no');
      // window.open(this.entreprise.website, '_system');
    } else {
      window.open('tel:' + phone);
    }
  }

  public displayingContactBloc(): boolean {
    let displayContactBloc: boolean = false;
    if (this.entreprise.telephone != null && this.entreprise.telephone.length > 0) {
      displayContactBloc = true;
    }
    if (this.entreprise.mail != null && this.entreprise.mail.length > 0) {
      displayContactBloc = true;
    }
    if (this.entreprise.legal_officiers != null && this.entreprise.legal_officiers.length > 0) {
      displayContactBloc = true;
    }
    if (this.entreprise.contacts != null && this.entreprise.contacts.length > 0) {
      if (this.entreprise.contacts.length !== 1) {
        displayContactBloc = true;
      } else {
        const contact: EntrepriseContactInterface = this.entreprise.contacts[0];
        if (contact.name != null && contact.name.length > 0) {
          displayContactBloc = true;
        }
      }
    }
    return displayContactBloc;
  }

    exportArticleWebToLead(contact: EntrepriseContactInterface, $event: MouseEvent) {
      $event.stopPropagation();
      /* TODO adapter code pour créer le contact selon 2 cas, - Cas classique avec les contacts complets
      * TODO - Cas de contact créés depuis ancien article, traitement à faire pour séparer fonction et nom
      * */
      const self = this;
      const contactWTL: ContactInterface = new class implements ContactInterface {
        contactName: string = contact.name;
        email: string = contact.mail;
        organisationName: string = self.entreprise.name;
        siren: string = self.entreprise.siren;
        socialUrl: string = contact.linkedin;
        tel: string = contact.phone;
        website: string = self.entreprise.website;
        firstName: string = contact.firstName;
        lastName: string = contact.lastName;
        contactId: number = contact.contactId;
        entrepriseId: number = self.entreprise.entrepriseId;
      };
      this.articlesService.exportArticleWebToLead(this.article.id, contactWTL)
          .pipe(
              takeUntil(this.unsubscribe$)
          ).subscribe(
          () => {
            this.toastService.simple('L\'export WebToLead a été effectué.');
          },
          (her: HttpErrorResponse) => {
            let errorStr: string;
            if (typeof her !== 'undefined' && typeof her.error.message !== 'undefined' && her.error.message !== null && her.error.message !== '') {
              errorStr = her.error.message;
            } else {
              errorStr = 'Une erreur est survenue lors de l\'export WebToLead. Veuillez réessayer ultérieurement.';
            }
            this.toastService.simple(errorStr, { color: 'toasterror' });
          }
      );
    }

  exportEnterpriseWebToLead() {
    /* TODO adapter code pour créer le contact selon 2 cas, - Cas classique avec les contacts complets
    * TODO - Cas de contact créés depuis ancien article, traitement à faire pour séparer fonction et nom
    * */
    const self = this;
    const contactWTL = new class {
      entrepriseId: number = self.entreprise.entrepriseId;

    };
    this.articlesService.exportArticleWebToLead(this.article.id, contactWTL)
        .pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(
        () => {
          this.toastService.simple('L\'export WebToLead a été effectué.');
        },
        (her: HttpErrorResponse) => {
          let errorStr: string;
          if (typeof her !== 'undefined' && typeof her.error.message !== 'undefined' && her.error.message !== null && her.error.message !== '') {
            errorStr = her.error.message;
          } else {
            errorStr = 'Une erreur est survenue lors de l\'export WebToLead. Veuillez réessayer ultérieurement.';
          }
          this.toastService.simple(errorStr, {color: 'toasterror'});
        }
    );
  }

  /**
   * Log de l'action CLICK_WEBSITE
   */
  public logWebsiteClick(): void {
    //  Log - CLICK_WEBSITE
    this.userService.log('CLICK_WEBSITE');
  }

  /**
   * Log de l'action CLICK_MAIL
   */
  public logMailClick(): void {
    //  Log - CLICK_MAIL
    this.userService.log('CLICK_MAIL');
  }

  /**
   * Log de l'action CLICK_RS
   */
  public logSocialNetworkClick(): void {
    //  Log - CLICK_RS
    this.userService.log('CLICK_RS');
  }

  moreThanThreeMonths(entrepriseArticle: EntrepriseArticleInterface) {
    const articleDate: number = Date.parse(entrepriseArticle.date);
    const todayDate: number = new Date().getTime();
    return (todayDate - articleDate) > (1000 * 60 * 60 * 24 * 90);
  }

  async openStatDetails() {
    this.userService.log('OPEN_FINANCIAL_GRAPHS', null, this.entrepriseId);

      const modal = await this.modalController.create({
        component: FicheEntrepriseStatsComponent,
        cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'chartDesktopDialog',
        componentProps: {
          entreprise: this.entreprise
        },
        backdropDismiss: true
      });
      return await modal.present();
  }
}
