<ng-container *ngIf="isFilterValid()">
    <mat-expansion-panel [disabled]="!_criterion.childCriterions || _criterion.childCriterions.length === 0" [expanded]="expanded">
        <mat-expansion-panel-header class="expansionHeader">
            <mat-panel-title>
                <mat-checkbox (click)="$event.stopPropagation()"
                              class="children-list-item"
                              [ngClass]="filtered ? 'custom-checkbox' : ''"
                              (change)="checkItem()"
                              [checked]="_criterion.checked_ || allChildrenChecked(_criterion) || isOneOfLeadTempoChecked()"
                              [indeterminate]="!_criterion.checked_ && isPartiallyChecked()">
                    {{_criterion.label}}
                </mat-checkbox>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <div class="tempoLeads" *ngIf="_criterionType == 'topics'" style="display: flex; flex-direction: row;" (click)="checkTempoLeadNotAvailable();">
<!--                <mat-radio-group>-->
<!--                    <mat-radio-button>Utiliser des temporalités</mat-radio-button>-->
                    <div style="display: flex; flex-direction: row; width: 350px; padding-bottom: 10px;">
                        <mat-slide-toggle [disabled]="!_leadsAvailable" [checked]="isTempoChecked('past')" (click)="checkTempo('past')">Passé</mat-slide-toggle>
                        <mat-slide-toggle [disabled]="!_leadsAvailable" [checked]="isTempoChecked('present')" (click)="checkTempo('present')">En cours</mat-slide-toggle>
                        <mat-slide-toggle [disabled]="!_leadsAvailable" [checked]="isTempoChecked('future')" (click)="checkTempo('future')">À venir</mat-slide-toggle>
                    </div>
                    <mat-checkbox *ngIf="leadCriterions?.lead" [disabled]="!_leadsAvailable" [checked]="isLeadChecked()" (change)="checkLead()">Utiliser les Lead Prédictifs®</mat-checkbox>
<!--                    <mat-radio-button>Utiliser les lead prédictifs</mat-radio-button>-->
<!--                </mat-radio-group>-->
            </div>
            <ng-container *ngFor="let child of _criterion.childCriterions">
                <app-criterion-item-panel *ngIf="child.subType != 'temporal' && child.subType != 'predictive'" [criterionType]="_criterionType" [criterion]="child" [parent]="_criterion" [filter]="_filter" [leadsAvailable]="_leadsAvailable" (emitter)="emitToParent($event)"></app-criterion-item-panel>
            </ng-container>
        </ng-template>
    </mat-expansion-panel>
</ng-container>
