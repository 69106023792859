import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { NavigationExtras } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DeeplinkService {

  constructor(
    private navController: NavController
  ) {

  }

  public redirect(params: any): void {

    const navigationExtras: NavigationExtras = {
      state: {
        id: params.payload.id
      }
    };

    // alert(params);

    // TODO: sync data en fonction de la route ? avant la redirection vers la page en question...
    this.navController.navigateForward(params.key, navigationExtras);
  }

}
