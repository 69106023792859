import {Component, Input, Output, EventEmitter, ChangeDetectionStrategy} from '@angular/core';
import { CriterionInterface } from 'src/app/interfaces/criterions/criterion.interface';
import { SelectionModel } from '@angular/cdk/collections';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {MediaQueryService} from '../../../services/media-query/media-query.service';

@Component({
  selector: 'my-searches-add-edit-children',
  templateUrl: 'my-searches-add-edit-children.component.html',
  styleUrls: ['my-searches-add-edit-children.component.scss'],
})
export class MySearchesAddEditChildrenComponent {

  public selectionModel: SelectionModel<CriterionInterface> = new SelectionModel<CriterionInterface>(true, []);

  public leadTitle: string = 'Leads Prédictifs® est une fonctionnalité ultra-innovante, qui vous permet de détecter des besoins potentiels de vos cibles. Nous avons analysé 150.000 signaux business pour vous permettre de détecter les événements précurseurs. Avec Leads Prédictifs®, positionnez-vous en amont et arrivez avant vos concurrents.';

  public predictive: boolean = false;
  
  public _parentCriterion: CriterionInterface;
  @Input() public currentLevel: number;
  @Input() public criterionKey: string;
  public _filterCriterion: string;
  @Input() public set filterCriterion(value: string) {
    this._filterCriterion = value;
  }
  public get filterCriterion(): string {
    return this._filterCriterion;
  }
  public _toExpand: Set<CriterionInterface> = new Set<CriterionInterface>();
  @Input() public set expanded(value: Set<CriterionInterface>) {
    this._toExpand = value;
  }
  public get expanded(): Set<CriterionInterface> {
    return this._toExpand;
  }

  @Output() emitSelection = new EventEmitter<CriterionInterface>();
  @Output() emitChildrenCriterion = new EventEmitter<{criterion: CriterionInterface, add: boolean}>();

  constructor(public mediaQueryService: MediaQueryService) {}

  public get parentCriterion(): CriterionInterface {
    return this._parentCriterion;
  }

  @Input()
  public set parentCriterion(parentCriterion: CriterionInterface) {
    this._parentCriterion = parentCriterion;
    this._parentCriterion.childCriterions.sort(function(a, b): number {
      if (a.subType === 'temporal' && b.subType === 'temporal') {
         if (a.subSubType === 'past') {
           return -1;
         } else if (a.subSubType === 'future') {
           return 1;
         } else {
           if (b.subSubType === 'past') {
             return 1;
           } else if (b.subSubType === 'future') {
             return -1;
           }
         }
      } else if (a.subType === 'temporal') {
        return -1;
      } else if (b.subType === 'temporal') {
        return 1;
      } else if (a.subType === 'predictive') {
        return -1;
      } else if (b.subType === 'predictive') {
        return 1;
      } else {
        return a.label.localeCompare(b.label);
      }
    });
  }

  public onSelectedChild(current: CriterionInterface, event: boolean, isLeads?: boolean): void {

    if (current !== null) {
      if (event === true) {
        current.checked_ = true;
      } else {
        current.checked_ = false;
      }
      const data = {
        criterion: current,
        add: current.checked_
      };
      this.emitChildrenCriterion.emit(data);

      this.emitSelection.emit(current);
    }



    // if (!isLeads) {
      this.onCheckParentChildren(current, current.checked_, isLeads);
    // }
  }

  public onCheckParentChildren(criterion: CriterionInterface, childCheckValue: boolean, isLeads?: boolean) {
    // If the criterion has childCriterions, we (de)select all of them
    if (criterion.childCriterions !== undefined) {
      for (const child of criterion.childCriterions) {
        if ((child.subType !== 'temporal' && child.subType !== 'predictive')) {
          child.checked_ = childCheckValue;
          this.onCheckParentChildren(child, childCheckValue, isLeads);
        }
        // else if (isLeads && child.subType === undefined) {
        //   child.checked_ = childCheckValue;
        // }
      }
    }
  }

  onSelectedChildEmit(child: CriterionInterface, current: CriterionInterface): void {
    if (this.criterionKey === 'topics') {
      if (child.lead === undefined && child.checked_ === false) {
        current.checked_ = false;
        for (const sub of child.childCriterions) {
          if (sub.lead !== undefined) {
            // sub.lead.checked = false;
            sub.checked_ = false;
          }
        }
      }
    }
    const data = {
      criterion: child,
      add: !child.checked_
    };
    this.emitChildrenCriterion.emit(data);
    this.emitSelection.emit(current);
  }

  /** Whether part of the descendants are selected */
  public descendantsPartiallySelected(currentCriterion: CriterionInterface, count: number): boolean {
    if (currentCriterion.childCriterions !== undefined) {
      currentCriterion.childCriterions.forEach((child) => {
        if (typeof child !== 'undefined' && this.isChecked(child)) {
          count++;
        }
      });
    } else {
      return false;
    }
    if (currentCriterion.childCriterions !== undefined && count === currentCriterion.childCriterions.length - 4) {
      return false;
    } else if (count > 0) {
      return true;
    } else {
      let partial: boolean = false;
      if (currentCriterion.childCriterions !== undefined) {
        currentCriterion.childCriterions.forEach((child) => {
          if (!partial && child.childCriterions !== undefined) {
            partial = this.descendantsPartiallySelected(child, 0);
          }
        });
      }
      return partial;
    }
  }

  public isChecked(criterion: CriterionInterface): boolean {
    let checked = false;
    // if (this.allDescendantsChecked(criterion)) {
    //   return true;
    // }
    checked = this.allDescendantsChecked(criterion);
    checked = checked || criterion.checked_ ;
    // console.log(criterion.label + ' - ' + checked);

    return checked;
  }

  onSelectedLeads(child: CriterionInterface, $event: MatSlideToggleChange) {

    if (child.subType === 'predictive') {
      this.predictive = $event.checked;
    }
    else {
      if ($event.checked)
      this.predictive = !$event.checked;
    }
    let leadC: boolean = false;
    if ($event.checked === true) {
      for (const lead of this.parentCriterion.childCriterions) {
        if (child.subType !== lead.subType && lead.lead !== undefined) {
          lead.lead.checked = !$event.checked;
          this.selectLeadsChildren(this._parentCriterion, lead);
        }
      }
    }

    child.lead.checked = $event.checked;


    for (const lead of this.parentCriterion.childCriterions) {
      if (lead.lead !== undefined) {
        if (lead.lead.checked) {
          leadC = true;
          break;
        }
      }
    }

    this.selectLeadsChildren(this._parentCriterion, child);
    this.onSelectedChild(this.parentCriterion, $event.checked || leadC, true);
  }

  selectLeadsChildren(parent: CriterionInterface, originalLeads: CriterionInterface) {
      if (parent.childCriterions !== undefined && parent.childCriterions.length > 0) {
        for (const child of parent.childCriterions) {
          if (child !== originalLeads && child.subType === originalLeads.subType && child.subSubType === originalLeads.subSubType) {
            child.lead.checked = originalLeads.lead.checked;
          }
          // if (child !== originalLeads && child.subType !== originalLeads.subType) {
          //   child.lead.checked = !originalLeads.lead.checked;
          //   child.checked_ = !originalLeads.lead.checked;
          // }
          if (child.subType !== 'temporal' && child.subType !== 'predictive') {// && !child.checked_) {
            this.selectLeadsChildren(child, originalLeads);
          }
        }
      }
  }

  public allDescendantsChecked(criterion: CriterionInterface): boolean {
    if (criterion.childCriterions !== undefined && criterion.childCriterions.length > 0) {
      for (const child of criterion.childCriterions) {
        if (child.subType !== 'temporal' && child.subType !== 'predictive' && !child.checked_) {
          return false;
        }
      }
      // means none of the criterions are unckecked, and there's more than just leads criterion
      if (criterion.childCriterions.length > 4) {
        return true;
      }
    }
    return false;
  }

  filterChildren(criterion: CriterionInterface, currentLevel: number) {
      let childrenFilter: boolean = false;
      if (criterion.childCriterions !== undefined && criterion.childCriterions.length > 0) {
        for (const child of criterion.childCriterions) {
          if (child.subType !== 'temporal' && child.subType !== 'predictive' && !child.checked_ && ((currentLevel < 2 && this.criterionKey === 'sectors') || this.criterionKey !== 'sectors')) {
            if (child.label.toLowerCase().includes(this.filterCriterion.toLowerCase())) {
              return true;
            }
          }
          childrenFilter = this.filterChildren(child, currentLevel + 1);
        }
      }
      return childrenFilter;
  }

  trackByFn(index, item) {
    return item.id;
  }
}

