import {Component, Inject, OnInit} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {ArticlesService} from '../../../services/articles/articles.service';
import {MailsService} from '../../../services/mails/mails.service';
import {NewsletterTemplate} from '../../../interfaces/newsletter/newsletter-template';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-newsletter-template',
  templateUrl: './newsletter-template.component.html',
  styleUrls: ['./newsletter-template.component.scss'],
})
export class NewsletterTemplateComponent implements OnInit {

  public templates: Array<NewsletterTemplate>;
  public nlName: any = 'Nouvelle Newsletter';

  constructor(private modalController: ModalController
      // , private navParams: NavParams
      , private articlesService: ArticlesService
      , private mailService: MailsService,
  public dialogRef: MatDialogRef<NewsletterTemplateComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.nlName = this.data.nlName;
    this.mailService.getNewsletterTemplates().subscribe(data => {
      this.templates = data;
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  selectTemplate(template: NewsletterTemplate) {
    template.name = this.nlName;
    this.dialogRef.close(template);
  }
}
