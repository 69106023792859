import {Component, OnInit, OnDestroy, Input, Renderer2} from '@angular/core';
import { Subject } from 'rxjs';
import { ArticleInterface } from 'src/app/interfaces/articles/article.interface';
import { FavoritesService } from 'src/app/services/favorites/favorites.service';
import { takeUntil } from 'rxjs/operators';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ErrorStatus } from 'src/app/classes/ErrorStatus.class';
import {AlertController, ModalController} from '@ionic/angular';
import {EmailArticleModal} from '../../modals/email-article/email-article.modal';
import {AddFavoriteModalComponent} from '../../modals/add-favorite-modal/add-favorite-modal.component';
import {MediaQueryService} from '../../../services/media-query/media-query.service';
import {FavorisFolder} from '../../../interfaces/favoritesFolders/favorites-folders.interface';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'article-toggle-favorite-button',
  templateUrl: 'article-toggle-favorite-button.component.html',
  styleUrls: ['article-toggle-favorite-button.component.scss'],
})
export class ArticleToggleFavoriteButtonComponent implements OnInit, OnDestroy {

  @Input() public article: ArticleInterface;
  @Input() public isFromArticleList: boolean;

  // in favorite list, ask before delete favorite. (article will desapear of this list. So it can not be add to favorite again, in this screen)
  @Input() public askBeforeDelete: boolean = false;

  private unsubscribe$: Subject<any> = new Subject<any>();

  public isSlected: boolean = false;

  // Menu
  private enteredButton = false;
  private isMatMenuOpen = false;
  private isMatMenu2Open = false;
  private prevButtonTrigger;
  public _favoriteFolders: Array<FavorisFolder>;
  @Input() public selectionModel: SelectionModel<string>;

  constructor(
    private favoritesService: FavoritesService
    , private toastService: ToastService
    , private alertController: AlertController
    , private modalController: ModalController
    , public mediaQueryService: MediaQueryService
    , private ren: Renderer2
    , public favoriteService: FavoritesService
  ) {}

  public ngOnInit(): void {

    this.favoriteService.getFavoritesFolders().subscribe((data: Array<FavorisFolder>) => {
      this._favoriteFolders = data;
    });
  }


  public toggleFavorite(): void {
    if (this.article.favorite) {
      this.askBeforeDelete ? this.askBeforeRemoveFavorite() : this.removeFavorite();
    } else {
      this.addFavorite();
    }
  }

  private async askBeforeRemoveFavorite() {
    const alert = await this.alertController.create({
      header: 'Attention',
      message: '<b>Voulez-vous vraiment supprimer ce favoris ? </b>',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => { }
        }, {
          text: 'Oui',
          handler: () => this.removeFavorite()
        }
      ]
    });
    await alert.present();
  }

  private removeFavorite(): void {
    const tabID: number[] = [this.article.id];
    this.favoritesService.removeArticleFromFavorites(tabID).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      () => {
        this.toastService.simple('Favori supprimé avec succès', { color: 'toastinfo' });
        this.article.favorite = false;
        this.favoritesService.needRefresh.next(true);
      },
      (error: Error | ErrorStatus) => {
        let errorStr: string = 'Une erreur est survenue lors de la suppression de ce favori';
        // if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
        //   errorStr += `(${this.translateService.instant(error.message)})`;
        // }
        this.toastService.simple(errorStr, { color: 'toasterror' });
      }
    );
  }

  public async openAddFavoriteModal() {
    const modal = await this.modalController.create({
      component: AddFavoriteModalComponent,
      cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'addFavoriteDialog',
      componentProps: {
        article: this.article
      }
    });

    modal.onDidDismiss().then((data) => {
      if (data.data.isAdd) {
        // this.toastService.simple(this.translateService.instant('ADD_ARTICLE_TO_FAVORITE_SUCCESS'));
        this.toastService.clickableLink('Favori ajouté avec succès', data.data.libelle,  data.data.folderID, { color: 'toastinfo', });
        this.favoritesService.needRefresh.next(true);
      }
    });
    return await modal.present();
  }

  private addFavorite(): void {
    this.openAddFavoriteModal();
    // this.favoritesService.addArticleToFavorites(this.article).pipe(
    //   takeUntil(this.unsubscribe$)
    // ).subscribe(
    //   () => {
    //     this.toastService.simple(this.translateService.instant('ADD_ARTICLE_TO_FAVORITE_SUCCESS'));
    //     this.article.favorite = true;
    //   },
    //   (error: Error | ErrorStatus) => {
    //     let errorStr: string = this.translateService.instant('ADD_ARTICLE_TO_FAVORITE_ERROR');
    //     if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
    //       errorStr += `(${this.translateService.instant(error.message)})`;
    //     }
    //     this.toastService.simple(errorStr, { color: 'toasterror' });
    //   }
    // );
  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }



  menuenter() {
    this.isMatMenuOpen = true;
    if (this.isMatMenu2Open) {
      this.isMatMenu2Open = false;
    }
  }

  menuLeave() {
    setTimeout(() => {
      if (!this.isMatMenu2Open && !this.enteredButton) {
        this.isMatMenuOpen = false;
        this.prevButtonTrigger.closeMenu();
      } else {
        this.isMatMenuOpen = false;
        this.prevButtonTrigger.closeMenu();
      }
    }, 80);
  }

  menu2enter() {
    this.isMatMenu2Open = true;
  }

  buttonEnter(trigger, mobile: boolean) {
    setTimeout(() => {
      if (this.prevButtonTrigger && this.prevButtonTrigger !== trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        trigger.openMenu();
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
      } else if (!this.isMatMenuOpen && !mobile) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
        trigger.openMenu();
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
      }
    });
  }

  buttonLeave(trigger, button) {
    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } if (!this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.enteredButton = false;
      }
    }, 100);
  }

  changeFavoriteFolder(favorite: FavorisFolder, $event: MouseEvent) {
    this.favoriteService.moveArticleInFavoritesToOtherFolder(favorite.folderID, this.article.id).subscribe(value => {
      this.article.favoriteFolder = favorite.folderID;
      this.favoritesService.needRefresh.next(true);
      this.selectionModel.clear();
      this.toastService.simple('Le favori a été déplacé avec succès', { color: 'toastinfo' });
    });
  }


  deleteFavorite() {
    this.askBeforeRemoveFavorite();
  }
}
