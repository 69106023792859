import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {formatDate} from '@angular/common';
import {MatCalendar} from '@angular/material/datepicker';
import {ToastService} from '../../../services/toast/toast.service';
import {ColorType, IconType} from "../fe-icon/fe-icon.component";
import {DateAdapter} from '@angular/material/core';

@Component({
  selector: 'app-fe-date-selector',
  templateUrl: './fe-date-selector.component.html',
  styleUrls: ['./fe-date-selector.component.scss']
})
export class FeDateSelectorComponent implements OnInit{

  protected periodSelected = 0;

  dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;

  @ViewChild('dateStartCalendar') dateStartCalendar: MatCalendar<Date>;
  @ViewChild('dateEndtCalendar') dateEndtCalendar: MatCalendar<Date>;

  dateStartSelected: Date;
  dateEndSelected: Date;

  dateStartString: string;
  dateEndString: string;

  maxStartDate: Date = new Date();
  minStartDate: Date = new Date();

  maxEndDate: Date = new Date();
  minEndDate: Date = new Date();

  @Output() emitter = new EventEmitter();
  public minDate: Date;
  public decouverteDates: boolean;
  @Input() public set dateStart(value: string) {
    this.dateStartSelected = new Date(value);
  }
  @Input() public set dateEnd(value: string) {
    this.dateEndSelected = new Date(value);
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<FeDateSelectorComponent>,
              public toastService: ToastService,
              private dateAdapter: DateAdapter<Date>) {
    this.dateStartSelected = new Date(data.dateStart);
    this.dateStartString = formatDate(this.dateStartSelected, 'dd/MM/yyyy', 'fr_FR');
    this.dateEndSelected = new Date(data.dateEnd);
    this.dateEndString = formatDate(this.dateEndSelected, 'dd/MM/yyyy', 'fr_FR');
    this.minDate = new Date(data.dateMin);
    const periodDate = new Date();
    periodDate.setDate(periodDate.getDate() - 180);
    if (this.minDate > periodDate) {
      this.decouverteDates = true;
    }
    this.refreshMinMaxDates();
  }

  ngOnInit(): void {
    this.dateAdapter.getFirstDayOfWeek = () => 1;
  }

  startDateChanged($event: Date) {
    this.dateStartSelected = $event;
    this.dateStartString = formatDate(this.dateStartSelected, 'dd/MM/yyyy', 'fr_FR');
    this.refreshMinMaxDates();
  }

  endDateChanged($event: Date) {
    this.dateEndSelected = $event;
    this.dateEndString = formatDate(this.dateEndSelected, 'dd/MM/yyyy', 'fr_FR');
    this.refreshMinMaxDates();
  }

  refreshMinMaxDates() {
    // TODO handle date from session

    // Min start date -3 years except for non clients
    this.minStartDate.setFullYear(this.maxStartDate.getFullYear() - 3);

    // Min end date = selected start date
    this.minEndDate = new Date(this.dateStartSelected);

    // Max start date = selected end date
    this.maxStartDate = new Date(this.dateEndSelected);

    // Max end date = last article dates
    this.maxEndDate = new Date();
  }

  validateDates() {
    this.dialogRef.close({dateStart: this.dateStartSelected, dateEnd: this.dateEndSelected});
  }

  selectPeriod(days: number) {
    const periodDate = new Date();
    periodDate.setDate(periodDate.getDate() - days);
    this.dateStartSelected = periodDate;
    this.dateStartString = formatDate(this.dateStartSelected, 'dd/MM/yyyy', 'fr_FR');
    this.dateEndSelected = new Date();
    this.dateEndString = formatDate(this.dateEndSelected, 'dd/MM/yyyy', 'fr_FR');
    this.refreshMinMaxDates();
    this.periodSelected = days;
    this.dateStartCalendar._goToDateInView(periodDate, 'month');
    this.dateEndtCalendar._goToDateInView(new Date(), 'month');
  }

  dateStartStringChange($event: string) {
    if (this.dateRegex.test($event)) {
      const parts = $event.split('/');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      const date = new Date(year, month, day);

      if (date <= this.dateEndSelected) {
        this.startDateChanged(date);
        this.dateStartCalendar._goToDateInView(date, 'month');
      }
      else {
        this.toastService.simple('La date de début ne peut pas être après la date de fin.', { color: 'toasterror', position: 'bottom' });
      }
    }
  }

  dateEndStringChange($event: string) {
    if (this.dateRegex.test($event)) {
      const parts = $event.split('/');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      const date = new Date(year, month, day);

      if (date >= this.dateStartSelected) {
        this.endDateChanged(date);
        this.dateEndtCalendar._goToDateInView(date, 'month');
      }
      else {
        this.toastService.simple('La date de fin ne peut pas être avant la date de début.', { color: 'toasterror', position: 'bottom' });
      }
    }
  }

    protected readonly IconType = IconType;
    protected readonly ColorType = ColorType;
}
