<mat-accordion>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span>{{field.placeholder}}</span>
        <span class="spacer"></span>
        <mat-icon *ngIf="field.jsonRemovable && !field.readonly" (click)="deleteThisJsonField($event, field)" color="warn" matTooltip="{{field.name}}">delete</mat-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <x-form *ngIf="jsonFields && jsonFields.length > 0" [fields]="jsonFields" hideSubmitButton="true" (formDataChanged)="formDataChanged($event)"></x-form>

  </mat-expansion-panel>  

</mat-accordion>
