import {Component, OnInit, OnDestroy, Input, Renderer2, NgZone, ChangeDetectorRef, ElementRef} from '@angular/core';
import {BehaviorSubject, ReplaySubject, Subject, Subscriber} from 'rxjs';
import {AlertController, ModalController, NavParams, Platform} from '@ionic/angular';
import {Printer, PrintOptions} from '@awesome-cordova-plugins/printer/ngx';
import {ArticleInterface} from 'src/app/interfaces/articles/article.interface';
import {ArticlesService} from 'src/app/services/articles/articles.service';
import {takeUntil} from 'rxjs/operators';
import {PrintInterface} from 'src/app/interfaces/print/print.interface';
import {EmailArticleModal} from '../email-article/email-article.modal';
import {MediaQueryService} from 'src/app/services/media-query/media-query.service';
import {TagInterface} from '../../../interfaces/tag/tag';
import {UserService} from '../../../services/user/user.service';
import {SessionInterface} from '../../../interfaces/session/session.interface';
import {FavoritesService} from 'src/app/services/favorites/favorites.service';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {PredictionInterface} from '../../../interfaces/tag/prediction';
import {Utils} from '../../../classes/Utils';
import {AddressesInterface} from '../../../interfaces/addresses/addresses';
import {AddressesService} from '../../../services/addresses/addresses.service';
import {AddressInterface} from '../../../interfaces/address/address';
import {ToastService} from '../../../services/toast/toast.service';
import {AddEditAddressModal} from '../address-add-edit/address-add-edit.modal';
import {MonitoredArticleInterface} from '../../../interfaces/monitored-articles/monitored-article.interface';
import {ArticleMonitoringService} from '../../../services/article-monitoring/article-monitoring.service';
import {MonitoredArticleActivityInterface} from '../../../interfaces/monitored-article-comments/monitored-article-activity.interface';
import {MonitoringEditCommentModalComponent} from '../../monitoring-edit-comment-modal/monitoring-edit-comment-modal.component';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {MonitoringCommentSharingModalComponent} from '../../monitoring-comment-sharing-modal/monitoring-comment-sharing-modal.component';
import {ErrorStatus} from '../../../classes/ErrorStatus.class';
import {formatDate} from '@angular/common';
import {MonitoredArticleAttachmentInterface} from '../../../interfaces/monitored-article-attachments/monitored-article-attachment.interface';
import {MonitoringAttachmentSharingModalComponent} from '../../monitoring-attachment-sharing-modal/monitoring-attachment-sharing-modal.component';
import {AuthService} from '../../../services/auth/auth.service';
import * as FileSaver from 'file-saver';
import {Observable} from 'rxjs/internal/Observable';
import {LoadingDisplayerComponent} from '../loading-displayer/loading-displayer.component';
import {MatDialog} from '@angular/material/dialog';
import {CreateLeadModalComponent} from '../create-lead-modal/create-lead-modal.component';
import * as moment from 'moment';

@Component({
    selector: 'reading-article',
    templateUrl: 'reading-article.modal.html',
    styleUrls: ['reading-article.modal.scss'],
})
export class ReadingArticleModal implements OnInit, OnDestroy {

    protected unsubscribe$: Subject<any> = new Subject<any>();
    // public addresses$: BehaviorSubject<AddressesInterface> = this.addressesService.getAddressesSubject();

    public article: ArticleInterface; // TODO: Article interface
    // TODO: WARNING different display if we come from article or favorites. Not developed yet.

    @Input() articleMonitoringId: number;
    @Input() monitoredArticle: MonitoredArticleInterface;
    public currentArticleAttributes;

    public from: string; // article or favorite // display modal for article or favorite
    public typeLead: boolean; // article est de type local ou external_lead

    public locale: any; // prevent build error

    public _keywords: Array<string>;

    public isVisualisation: Boolean;
    public customer: string;
    public isSupervisor: boolean;
    public customerFirstname: string;
    public customerLastname: string;
    public nbComments: ReplaySubject<number> = new ReplaySubject<number>();
    public monitoredArticleActivities: ReplaySubject<Array<MonitoredArticleActivityInterface>> = new ReplaySubject<Array<MonitoredArticleActivityInterface>>();
    public monitoredArticleActivities$: Observable<Array<MonitoredArticleActivityInterface>>;

    commentContent = '';

    public monitoredArticleAttachments: ReplaySubject<Array<MonitoredArticleAttachmentInterface>> = new ReplaySubject<Array<MonitoredArticleAttachmentInterface>>();
    public monitoredArticleAttachments$: Observable<Array<MonitoredArticleAttachmentInterface>>;
    public alreadyOpened: boolean = false;


    config: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '6.5rem',
        minHeight: '5rem',
        placeholder: 'Entrer votre commentaire...',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        toolbarHiddenButtons: [
            ['fontName', 'subscript', 'superscript', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent'],
            ['textColor', 'backgroundColor', 'link', 'unlink', 'insertImage', 'insertVideo', 'removeFormat', 'insertHorizontalRule', 'toggleEditorMode']
        ]
    };

    public isFromMonitoring: boolean;

    public fromComment: boolean;

    public tabIndex: number = 0;

    public isUpadted: Boolean = false;

    public isArchived: Boolean = false;

    public commentAddedOrDeleted: boolean = false;
    public attachmentAddedOrDeleted: boolean = false;
    public isLettrePersoMC = false;

    @Input()
    public set keywords(value: Array<string>) {
        this._keywords = value;
    }

    public get keywords(): Array<string> {
        return this._keywords;
    }


    // Favorite comment
    public get favoriteComment() {
        return this.article.data.comment;
    }

    public set favoriteComment(comment: string) {
        this.article.data.comment = comment;
    }

    public currentTag: TagInterface;

    public leadsAvailable: boolean = true;

    constructor(
        private modalController: ModalController
        , public mediaQueryService: MediaQueryService
        , private platform: Platform
        , private navParams: NavParams
        , private printer: Printer
        , private articlesService: ArticlesService
        , private userService: UserService
        , private favoritesService: FavoritesService
        , private ren: Renderer2
        , public addressesService: AddressesService
        , public toastService: ToastService
        , private ngZone: NgZone
        , private alertController: AlertController
        , private articleMonitoringService: ArticleMonitoringService
        , private authService: AuthService
        , public dialog: MatDialog
        , private elem: ElementRef
    ) {
    }

    public isRoleCom() {
        return this.authService.roleCom;
    }

    public ngOnInit(): void {
        this.article = this.navParams.get('article');
        this.from = this.navParams.get('from');
        this._keywords = this.navParams.get('keywords');
        this.isFromMonitoring = this.navParams.get('fromMonitoring');
        this.fromComment = this.navParams.get('fromComment');
        if (this.article.type === 'local') {
            this.userService.log('ARTICLE_OPEN_MODAL', [this.article]);
        }


        /**
         * Insertion Corps d'article
         */
        setTimeout(() => {

            let pattern;
            let tag;
            for (let i = 0; i < this.article.referenceArticlesInfos?.length; i++) {
                pattern = '##' + this.article.referenceArticlesInfos[i].articleId + '##';
                tag = '<span><a id="ref' + this.article.referenceArticlesInfos[i].articleId + '" class="ref-link">' + this.article.referenceArticlesInfos[i].text + '</a></span>';
                this.article.corps = this.article.corps.replace(pattern, tag);
            }

            let articleCorpsSpan = document.createElement('span');
            articleCorpsSpan.innerHTML = this.getHighlightedText(this.article.corps);
            this.elem.nativeElement.querySelector('#corpsArticle').appendChild(articleCorpsSpan);

            for (let i = 0; i < this.article.referenceArticlesInfos?.length; i++) {
                var refArticleElem = this.elem.nativeElement.querySelector('#ref' + this.article.referenceArticlesInfos[i].articleId);
                const self = this;
                if (refArticleElem) {
                    refArticleElem.addEventListener('click', function() {
                        self.openReferenceArticleModal(self.article.referenceArticlesInfos[i].articleId);
                    });
                }
            }
        }, 500);

        this.currentArticleAttributes = {
            articleId: this.article.id,
            articleMonitoringPosition: this.article.monitoring.position,
            isArchived: this.article.monitoring.isArchived,
            monitoringPriority: this.article.monitoring.priority !== undefined ? this.article.monitoring.priority.valueOf() : '',
            monitoringStatus: this.article.monitoring.status,
            timeInClosed: this.article.monitoring.timeInClosed,
            timeInProcessing: this.article.monitoring.timeInProcessing,
            timeInQualified: this.article.monitoring.timeInQualified,
            timeInToProcess: this.article.monitoring.timeInToProcess
        };

        if (this.fromComment) {
            this.tabIndex = 0;
        } else {
            this.tabIndex = 1;
        }

        if (this.from === 'favorite') {
            this.favoritesService.getFavorites();
        }
        this.userService.getSessionDatas().pipe(
            takeUntil(this.unsubscribe$)
        )
            .subscribe(
                (sessionData: SessionInterface) => {
                    if (sessionData !== undefined) {
                        if (sessionData.productType === 'Lettre_MotsCles') {
                            this.isLettrePersoMC = true;
                        }
                        sessionData.appConfig.viewConfigs.forEach(value => {
                            if (value.component === 'LEADS_TOPICS_CHOICE') {
                                if (value.state === 'visible') {
                                    this.leadsAvailable = true;
                                }
                            }
                        });
                        if (sessionData.customerDetails.isSupervisor !== null) {
                            this.customer = sessionData.customerDetails.user_id;
                            this.customerFirstname = sessionData.customerDetails.firstname;
                            this.customerLastname = sessionData.customerDetails.lastname;
                            this.isSupervisor = sessionData.customerDetails.isSupervisor;
                        }
                    }
                }
            );
        if (this.from === 'monitoring') {
            this.loadActivities();
            this.loadAttachments();
        }

    }

    authorAbbreviation(firstname, lastname): string {
        return (firstname !== undefined ? firstname.substring(0, 1) : '') + '' + (lastname !== undefined ? lastname.substring(0, 1) : '');
    }

    async editMonitoredArticleComment(comment) {
        const modal = await this.modalController.create({
            component: MonitoringEditCommentModalComponent,
            cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'editMonitoredArticleCommentDialog',
            componentProps: {
                comment: comment,
                articleId: this.article.id,
                customer: this.customer
            }
        });
        modal.onDidDismiss()
            .then(
                (data: any) => {
                    this.commentContent = '';
                    if (data.data !== undefined) {
                        if (data.data.commentEdited) {
                            this.loadActivities();
                        }
                    } else {
                        this.loadActivities();
                    }
                }
            );
        return await modal.present();
    }

    public deleteComment(comment: MonitoredArticleActivityInterface): void {
        this.articleMonitoringService.removeMonitoredArticleComment(comment.activityId, this.article.id, this.customer)
            .pipe(
                takeUntil(this.unsubscribe$)
            ).subscribe(
            () => {
                this.monitoredArticleActivities.subscribe((data) => {
                        data.forEach((monitoredActivity, index) => {
                            if (monitoredActivity.activityId === comment.activityId) {
                                data.splice(index, 1);
                            }
                        });
                        this.nbComments.next(data.filter(activity => activity.activityType == 'comment')?.length);
                    }
                );
                this.commentAddedOrDeleted = true;
                this.toastService.simple('Commentaire supprimé avec succès');
            },
            (error: Error | ErrorStatus) => {
                let errorStr: string = 'Une erreur est survenue lors de la suppression de ce commentaire';
                // if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
                //     errorStr += `(${this.translateService.instant(error.message)})`;
                // }
                this.toastService.simple(errorStr, {color: 'toasterror'});
            }
        );
    }

    public async deleteMonitoredArticleComment(comment: MonitoredArticleActivityInterface): Promise<any> {
        const alert = await this.alertController.create({
            header: 'Attention',
            message: '<b>Voulez-vous vraiment supprimer ce commentaire? </b> <br /><br />',
            buttons: [
                {
                    text: 'Annuler',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                    }
                }, {
                    text: 'Oui',
                    handler: () => this.deleteComment(comment)
                }
            ]
        });

        return await alert.present();
    }

    public parseDate(value: any): Date | null {
        if ((typeof value === 'string') && (value.includes('/'))) {
            const str = value.split('/');

            const year = Number(str[2]);
            const month = Number(str[1]) - 1;
            const date = Number(str[0]);

            return new Date(year, month, date);
        } else if ((typeof value === 'string') && value === '') {
            return new Date();
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }

    public formatAddingDate(stringDate): string {
        return formatDate(this.parseDate(stringDate), 'dd/MM/yy à HH\'h\'mm', 'fr');
    }

    public attachmentAddingText(attachment: MonitoredArticleAttachmentInterface): string {
        const authorFullname = attachment.authorFirstName + ' ' + attachment.authorLastName;
        return 'ajouté par ' + (attachment.customerId === this.customer ? ' vous' : authorFullname) + ' ' + this.formatAddingDate(attachment.addDate);
    }

    protected loadActivities(): void {
        this.articleMonitoringService.getArticleActivities({
            articleId: '' + this.article.id + '',
            articleMonitoringId: '' + this.articleMonitoringId + ''
        }).subscribe(
            monitoredActivitiesFromApi => {
                try {
                    this.monitoredArticleActivities.next(monitoredActivitiesFromApi);
                    this.nbComments.next(monitoredActivitiesFromApi.filter(activity => activity.activityType === 'comment')?.length);
                    this.monitoredArticleActivities$ = new Observable((observer: Subscriber<any>) => {
                        observer.next(monitoredActivitiesFromApi);
                        observer.complete();
                    });
                } catch (e) {

                }
            }
        );
    }

    public leadOpenLog(lead: PredictionInterface, isFirst: boolean): void {
        if (this.alreadyOpened === true) {
            isFirst = false;
        }
        if (!isFirst) {
            this.userService.log('ARTICLE_OPEN_LEAD', [this.article], null, lead.id);
        }
        this.alreadyOpened = true;
    }

    protected loadAttachments(): void {
        this.articleMonitoringService.getArticleAttachments({
            articleId: '' + this.article.id + '',
            articleMonitoringId: '' + this.articleMonitoringId + ''
        }).subscribe(
            monitoredAttachmentsFromApi => {
                try {
                    this.monitoredArticleAttachments.next(monitoredAttachmentsFromApi);
                    this.monitoredArticleAttachments$ = new Observable((observer: Subscriber<any>) => {
                        observer.next(monitoredAttachmentsFromApi);
                        observer.complete();
                    });
                } catch (e) {

                }
            }
        );
    }

    openFile(attachment) {
        if (!this.isRoleCom()) {
            this.articleMonitoringService.downloadMonitoredArticleAttachment({
                articleId: attachment.articleId + '',
                monitoringAttachmentId: attachment.id + ''
            }).subscribe(value => {
                FileSaver.saveAs(value.body, attachment.filename);
            });
        }
    }

    public async shareComment(commentContent) {
        if (this.monitoredArticle.associatedCustomersRealName != null && this.monitoredArticle.associatedCustomersRealName.length > 0) {
            const modal = await this.modalController.create({
                component: MonitoringCommentSharingModalComponent,
                cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'addMonitoredArticleDialog',
                componentProps: {
                    comment: commentContent,
                    articleId: this.article.id,
                    articleMonitoringId: this.articleMonitoringId
                }
            });

            modal.onDidDismiss()
                .then(
                    (data: any) => {
                        this.commentContent = '';
                        if (data.data !== undefined) {
                            if (data.data.commentShared) {
                                this.loadActivities();
                                this.commentAddedOrDeleted = true;
                            }
                        } else {
                            this.loadActivities();
                            this.commentAddedOrDeleted = true;
                        }
                    }
                );
            return await modal.present();
        } else {
            const loadingModal = this.dialog.open(LoadingDisplayerComponent, {disableClose: true});
            const monitoringCommentAddingData = {
                articleId: this.article.id,
                articleMonitoringId: this.articleMonitoringId,
                comment: commentContent,
                customers: [],
                privateComment: false
            };
            this.articleMonitoringService.addMonitoredArticleComment(monitoringCommentAddingData).subscribe(next => {
                this.toastService.simple('Commentaire ajouté avec succès');
                this.commentContent = '';
                this.loadActivities();
                loadingModal.close();
            }, (error: Error | ErrorStatus) => {
                let errorStr: string = 'Une erreur est survenue lors de l\'ajout de ce commentaire';
                // if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
                //     errorStr += `(${this.translateService.instant(error.message)})`;
                // }
                this.toastService.simple(errorStr, {color: 'toasterror'});
                loadingModal.close();
            });
        }
    }

    public deleteAttachment(attachment: MonitoredArticleAttachmentInterface): void {
        this.articleMonitoringService.removeMonitoredArticleAttachment(attachment.id, this.article.id, this.customer)
            .pipe(
                takeUntil(this.unsubscribe$)
            ).subscribe(
            () => {
                this.monitoredArticleAttachments.subscribe((data) => {
                        data.forEach((monitoredAttachment, index) => {
                            if (monitoredAttachment.id === attachment.id) {
                                data.splice(index, 1);
                            }
                        });
                    }
                );
                this.attachmentAddedOrDeleted = true;
                this.toastService.simple('Pièce-jointe supprimée avec succès');
            },
            (error: Error | ErrorStatus) => {
                let errorStr: string = 'Une erreur est survenue lors de la suppression de cette pièce-jointe';
                // if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
                //     errorStr += `(${this.translateService.instant(error.message)})`;
                // }
                this.toastService.simple(errorStr, {color: 'toasterror'});
            }
        );
    }

    public async deleteMonitoredArticleAttachment(attachment: MonitoredArticleAttachmentInterface): Promise<any> {
        const alert = await this.alertController.create({
            header: 'Attention',
            message: '<b>Voulez-vous vraiment supprimer cette pièce-jointe? </b> <br /><br />',
            buttons: [
                {
                    text: 'Annuler',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                    }
                }, {
                    text: 'Oui',
                    handler: () => this.deleteAttachment(attachment)
                }
            ]
        });
        return await alert.present();
    }

    public async editMonitoredArticleAttachmentVisibility(attachment: MonitoredArticleAttachmentInterface): Promise<any> {
        const monitoringAttachmentData = {
            monitoringAttachmentId: attachment.id,
            articleId: attachment.articleId,
            customer: this.customer,
            monitoringAttachmentStatus: !attachment.private
        };
        this.articleMonitoringService.updateMonitoredArticleAttachmentPrivacy(monitoringAttachmentData).subscribe(
            () => {
                this.monitoredArticleAttachments.subscribe((data) => {
                        data.forEach((monitoredAttachment) => {
                            if (monitoredAttachment.id === attachment.id) {
                                monitoredAttachment.private = !attachment.private;
                            }
                        });
                    }
                );
                this.toastService.simple('Visibilité de la pièce-jointe mise à jour avec succès');
            }
        );
    }

    public async shareAttachment() {
        const modal = await this.modalController.create({
            component: MonitoringAttachmentSharingModalComponent,
            cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'addMonitoredArticleDialog',
            componentProps: {
                articleId: this.article.id,
                articleMonitoringId: this.articleMonitoringId,
                monitoredArticle: this.monitoredArticle
            }
        });

        modal.onDidDismiss()
            .then(
                (data: any) => {
                    if (data.data != undefined) {
                        if (data.data.attachmentsAdded) {
                            this.attachmentAddedOrDeleted = true;
                            this.loadAttachments();
                        }
                    } else {
                        this.attachmentAddedOrDeleted = true;
                        this.loadAttachments();
                    }
                }
            );
        return await modal.present();
    }

    public  continueClose() {
        this.modalController.dismiss({
            article: this.article,
            attachmentAddedOrDeleted: this.attachmentAddedOrDeleted,
            commentAddedOrDeleted: this.commentAddedOrDeleted,
            isArchived: this.isArchived
        });
        // Fermeture - Sauvegarde du log
        if (this.article.type === 'local') {
            this.userService.log('ARTICLE_CLOSE_MODAL', [this.article]);
        }
    }
    public async close(): Promise<void> {
        if (this.article.type === 'local') {
            this.userService.log('ARTICLE_CLOSE_MODAL', [this.article]);
        }
        if (this.commentContent !== undefined && this.commentContent !== '') {
            const alert = await this.alertController.create({
                header: 'Attention',
                message: 'Vous allez perdre votre commentaire si vous quittez. Voulez-vous vraiment quitter ?',
                buttons: [
                    {
                        text: 'Annuler',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => { }
                    }, {
                        text: 'Oui',
                        handler: () => this.continueClose()
                    }
                ]
            });
            await alert.present();
        } else {
            this.continueClose();
        }

    }

    public ngOnDestroy() {
        // this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public async openEmailModal(article: ArticleInterface) {
        const modal = await this.modalController.create({
            component: EmailArticleModal,
            cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog',
            componentProps: {
                article: article
            }
        });

        modal.onWillDismiss()
            .then(
                (data: any) => {
                }
            );

        return await modal.present();
    }

    public openPrintModal(article: ArticleInterface) {
        this.articlesService.printArticlesList([article.id]).pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(
            (res: PrintInterface) => {
                // Mobile printing
                if (this.platform.is('ios') || this.platform.is('android')) {
                    this.printer.isAvailable()
                        .then(onSuccess => {
                                console.log(onSuccess);
                            },
                            onError => {
                                console.log(onError);
                            }
                        );

                    const options: PrintOptions = {
                        name: 'FirstECO_Profil_print',
                        orientation: 'portrait'
                    };

                    this.printer.print(res.html, options)
                        .then(onSuccess => {
                                console.log(onSuccess);
                            },
                            onError => {
                                console.log(onError);
                            }
                        );
                } else {
                    const popupWin = window.open('', '_blank');
                    popupWin.document.open();
                    // Sauvegarde log d'impression
                    this.userService.log('ARTICLE_PRINT');
                    popupWin.document.write(res.html);
                    popupWin.document.close();
                    setTimeout(() => popupWin.print(), 1000);
                }
            }
        );

    }

    public toggleCommentPrivacy(event: MatSlideToggleChange) {
        this.article.data.commentIsPrivate = event.checked;
    }

    getPrescripionText(tag: PredictionInterface) {
        if (tag.prescription !== undefined) {
            return tag.prescription.replace(/[\n\r]/g, '<br>');
        } else {
            // console.log(tag.label);
        }
    }

    getHighlightedText(text: string): string {
        return Utils.getHighlightedText(text, this._keywords);
    }

    sendMail(address: AddressInterface, $event: MouseEvent) {
        $event.stopPropagation();
        const self = this;
        ($event.currentTarget as Element).setAttribute('style', 'pointer-events: none;background-color: #B3B3B3;'); // = ($event.currentTarget as Element).className + ' greyed';
        this.articlesService.emailArticleById(address.addressId, this.article.id).subscribe(next => {
            this.toastService.simple('L\'article a été envoyé par e-mail avec succès.');
            this.ngZone.run(() => {
                let shared = self.article.sharedTo;
                if (shared === undefined) {
                    shared = [];
                }
                shared.push(address.data.email);
                self.article.sharedTo = shared;
            });
        });
    }

    async addContact() {
        const modal = await this.modalController.create({
            component: AddEditAddressModal,
            // cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog',
            componentProps: {
                type: 'add'
            }
        });

        return await modal.present();
    }

    // Menu
    enteredButton = false;
    isMatMenuOpen = false;
    isMatMenu2Open = false;
    prevButtonTrigger;

    menuenter() {
        this.isMatMenuOpen = true;
        if (this.isMatMenu2Open) {
            this.isMatMenu2Open = false;
        }
    }

    menuLeave(trigger, button) {
        setTimeout(() => {
            if (!this.isMatMenu2Open && !this.enteredButton) {
                this.isMatMenuOpen = false;
                // trigger.closeMenu();
                this.prevButtonTrigger.closeMenu();
                this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
                this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
            } else {
                this.isMatMenuOpen = false;
                this.prevButtonTrigger.closeMenu();
            }
        }, 80);
    }

    menu2enter() {
        this.isMatMenu2Open = true;
    }

    menu2Leave(trigger1, trigger2, button) {
        setTimeout(() => {
            if (this.isMatMenu2Open) {
                trigger1.closeMenu();
                this.isMatMenuOpen = false;
                this.isMatMenu2Open = false;
                this.enteredButton = false;
                this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
                this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
            } else {
                this.isMatMenu2Open = false;
                trigger2.closeMenu();
            }
        }, 100);
    }

    buttonEnter(trigger) {
        setTimeout(() => {
            if (this.prevButtonTrigger && this.prevButtonTrigger !== trigger) {
                this.prevButtonTrigger.closeMenu();
                this.prevButtonTrigger = trigger;
                this.isMatMenuOpen = false;
                this.isMatMenu2Open = false;
                trigger.openMenu();
                this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-focused');
                this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
            } else if (!this.isMatMenuOpen) {
                this.enteredButton = true;
                this.prevButtonTrigger = trigger;
                trigger.openMenu();
                this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-focused');
                this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
            } else {
                this.enteredButton = true;
                this.prevButtonTrigger = trigger;
            }
        });
    }

    buttonLeave(trigger, button) {
        setTimeout(() => {
            if (this.enteredButton && !this.isMatMenuOpen) {
                trigger.closeMenu();
                this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
                this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
            }
            if (!this.isMatMenuOpen) {
                trigger.closeMenu();
                this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
                this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
            } else {
                this.enteredButton = false;
            }
        }, 100);
    }

    async archiveMonitoredArticle(monitoredArticle) {
        if (monitoredArticle.monitoring.isArchived === false) {
            const alert = await this.alertController.create({
                header: 'Attention',
                message: '<b>Voulez-vous vraiment archiver cet article? </b> <br /><br />',
                buttons: [
                    {
                        text: 'Annuler',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                        }
                    }, {
                        text: 'Oui',
                        handler: () => this.archiveArticle(monitoredArticle)
                    }
                ]
            });
            alert.onDidDismiss().then((data) => {
                this.close();
            });
            await alert.present();
        } else {
            const alert = await this.alertController.create({
                header: 'Attention',
                message: '<b>Voulez-vous vraiment désarchiver cet article? </b> <br /><br />',
                buttons: [
                    {
                        text: 'Annuler',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                        }
                    }, {
                        text: 'Oui',
                        handler: () => this.unArchiveArticle(monitoredArticle)
                    }
                ]
            });
            alert.onDidDismiss().then((data) => {
                this.close();
            });
            await alert.present();
        }
        this.isArchived = false;
    }

    public archiveArticle(monitoredArticle): void {
        this.isArchived = true;
        this.currentArticleAttributes.isArchived = true;
        this.article.monitoring.isArchived = true;
        this.articleMonitoringService.updateMonitoringArticles(this.currentArticleAttributes).subscribe();
    }

    public unArchiveArticle(monitoredArticle): void {
        this.isArchived = true;
        this.currentArticleAttributes.isArchived = false;
        this.article.monitoring.isArchived = false;
        this.articleMonitoringService.updateMonitoringArticles(this.currentArticleAttributes).subscribe();
    }

    public async openLeadCreationModal(): Promise<void> {
        const modal = await this.modalController.create({
            component: CreateLeadModalComponent,
            cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'addLeadDialog',
            componentProps: {
                modification: true,
                article: this.article
            },
            backdropDismiss: false
        });
        modal.onDidDismiss().then((data) => {
            // this.isUpadted = true;
            if (data.data !== undefined) {
                this.article.title = data.data.title;
                this.article.corps = data.data.content;
                if (data.data.creationDate !== undefined) {
                    const momentVariable = moment(data.data.creationDate, 'DD/MM/YYYY');
                    const dateTransform = momentVariable.format('YYYY-MM-DD hh:mm:ss');
                    this.article.publicationDate = dateTransform;
                }
            }
        });
        return await modal.present();
    }

    public markAsRead(article: ArticleInterface) {
        if (article.read === false && !this.authService.roleCom) {
            this.articlesService.markArticleAsRead(article.id)
                .pipe(
                    takeUntil(this.unsubscribe$)
                )
                .subscribe(
                    res => {
                        this.articlesService.updateReadArticle(article.id, true);
                    }
                );
        }
    }

    /**
     * Ouverture article de référence
     * @param referenceArticleId
     */
    async openReferenceArticleModal(referenceArticleId: number) {
        const Query = {
            articles: referenceArticleId
        };
        let article;
        this.articlesService.getArticlesContent(Query).subscribe(
            (res) => {
                article = res[0];
                this.openArticleModal(article);
            }
        );
    }

    public async openArticleModal(article: ArticleInterface) {
        const modal = await this.modalController.create({
            component: ReadingArticleModal,
            cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog',
            componentProps: {
                article: article,
                keywords: this._keywords
            },
            backdropDismiss: true
        });

        // if the article isn't read, we mark it as read
        this.markAsRead(article);
        return await modal.present();
    }

}
