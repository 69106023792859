import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";
import {MailsService} from "../../../services/mails/mails.service";

@Component({
  selector: 'app-newsletter-save-as',
  templateUrl: './newsletter-save-as.component.html',
  styleUrls: ['./newsletter-save-as.component.scss'],
})
export class NewsletterSaveAsComponent implements OnInit {

  public nlName: any = 'Nouveau template';


  constructor(private modalController: ModalController
      , private navParams: NavParams
      , private mailService: MailsService) { }

  ngOnInit() {}

  public close(): void {
    this.modalController.dismiss();
  }

  saveTemplate() {
    // this.mailService.saveTemplate();
  }
}
