<div class="feModal">
    <div class="feModalHeader">
        <span [class.feTextLeft]="closeButton" class="feModalTitle">{{title}}</span>
        <div class="feModalCloseButton" *ngIf="closeButton">
            <span mat-dialog-close>Fermer</span>
            <mat-icon mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="feModalContent">
        <p [innerHtml]="text"></p>
        <fe-input [(value)]="inputValue" [placeHolder]="data.inputTitle"
                  [inputTitle]="data.inputHolder" *ngIf="type == 'Input'"/>
        <div *ngIf="type == 'textarea'" style="margin-top: 20px">
            <span [class.displayNone]="textareaLabel == ''" style="color: var(--grey); font-style: italic">{{textareaLabel}}</span>
            <textarea *ngIf="type == 'textarea'" [(ngModel)]="inputValue" class="feTextArea"></textarea>
        </div>

    </div>
    <div class="feModalFooter">
        <fe-button *ngIf="type == 'Information'" mat-dialog-close text="Fermer"/>
        <fe-button *ngIf="type == 'Confirm' || type == 'Input' || type == 'textarea'" mat-dialog-close
                   [color]="'DANGER'" type="TERTIARY" text="Annuler"/>
        <fe-button *ngIf="type == 'Confirm' || type == 'Input' || type == 'textarea'" (click)="confirmBtnClick()"
                   [text]="type == 'Confirm' || type == 'textarea' ? 'Confirmer' : 'Accepter'"/>
    </div>
</div>
