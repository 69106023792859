<button mat-icon-button class="disabledWhite"  [disabled]=" selectionModel.selected.length === 0 || _favoriteFolders.length === 1" [matMenuTriggerFor]="menu"  #button style="height: 40px;z-index:9;" #levelOneTrigger="matMenuTrigger" (mouseenter)="buttonEnter(levelOneTrigger, mediaQueryService.mobileQuery.matches)" (mouseleave)="buttonLeave(levelOneTrigger, button)" >
<!--   selectionModel.selected.length === 0 ||-->

  <mat-icon>swap_horiz</mat-icon>
</button>
  <mat-menu #menu="matMenu" direction="down" yPosition="below" [hasBackdrop]="false">
    <div (mouseenter)="menuenter()" (mouseleave)="menuLeave()" style="max-height: 270px;">
  <ng-container *ngFor="let favorite of _favoriteFolders">
    <button *ngIf="favorite.folderID !== currentFolderID" mat-menu-item (click)="changeFavoriteFolder(favorite, selectionModel.selected)">
      Déplacer vers {{favorite.libelle}}
    </button>
  </ng-container>
    </div>
  </mat-menu>
