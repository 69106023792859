<div class="searchListItem" [class.active]="selected">
  <div class="searchListItemFirstColumn" (click)="loadSearch()">
    <span class="searchListItemSearchName">{{search.data.label}}</span>
    <span *ngIf="search.dataRecentResults.nbResults > 1 && !search.dataRecentResults.isView" class="newSearchResult">{{search.dataRecentResults.nbResults}} nouveaux résultats depuis la dernière connexion</span>
    <span *ngIf="search.dataRecentResults.nbResults === 1 && !search.dataRecentResults.isView" class="newSearchResult">{{search.dataRecentResults.nbResults}} nouveau résultat depuis la dernière connexion</span>
  </div>
  <div class="searchListItemSecondColumn">
    <div class="manageSearchContainer">
      <manage-searches [search]="search" [currentIndex]="index" [searchList]="searchList"
                       (selectSearchEmitter)="loadSearch()"/>
    </div>
    <div *ngIf="search.dataRecentResults.nbResults > 0 && !search.dataRecentResults.isView" class="searchItemRedNotif"></div>
  </div>
</div>
