import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {ApiService} from '../api/api.service';
import {HttpResponse} from '@angular/common/http';
import {ArticlesInterface} from 'src/app/interfaces/articles/articles.interface';
import {ArticleInterface} from 'src/app/interfaces/articles/article.interface';
import {ErrorStatus} from 'src/app/classes/ErrorStatus.class';
import {PrintInterface} from 'src/app/interfaces/print/print.interface';
import {MailPreviewInterface} from 'src/app/interfaces/mail-preview/mail-preview.interface';
import {UserService} from '../user/user.service';
import {ArticleQueryInterface} from '../../interfaces/articles/article-query.interface';
import {Utils} from '../../classes/Utils';

@Injectable({
    providedIn: 'root'
})
export class ArticlesService {

    // Disable tabs depending of loading
    public _disableTabs: boolean = false;
    public set disableTabs(disable: boolean) {
        this._disableTabs = disable;
    }

    public get disableTabs(): boolean {
        return this._disableTabs;
    }

    // Articles Object
    public articlesObj: ArticlesInterface = null;
    public _articlesObj$ = new BehaviorSubject<ArticlesInterface>(this.articlesObj);
    // public artObs = this._articlesObj$.asObservable();
    public get articlesObj$() {
        // return this.artObs;
        return this._articlesObj$.asObservable();
    }

    public getArticlesObj(): ArticlesInterface {
        return this.articlesObj;
    }

    public setArticlesObj(articlesObj: ArticlesInterface): void {
        this._articlesObj$.next(articlesObj);
    }

    // Article Query
    public query: ArticleQueryInterface = null;
    public _queryParams$ = new BehaviorSubject<ArticleQueryInterface>(this.query);
    public get queryParams$() {
        return this._queryParams$.asObservable();
    }

    public getQueryParams(): ArticleQueryInterface {
        return this.query;
    }

    private previousQueryParams;
    public setQuery(query: ArticleQueryInterface): void {
        if (Utils.deepEqual(query, this.previousQueryParams)) {
            Utils.equalPrevious = true;
            return;
        }
        Utils.equalPrevious = false;
        this._queryParams$.next(query);
        this.previousQueryParams = JSON.parse(JSON.stringify(query));
    }

    // Get updated data from article.read and mark as read multiple datas
    public articleReadSource = new BehaviorSubject<{ id: number, read: boolean}>(undefined);
    public currentArticle = this.articleReadSource.asObservable();

    public updateReadArticle(id: number, read: boolean) {
        const article = { id: id, read: read};
        this.articleReadSource.next(article);
    }

    constructor(
        private apiService: ApiService
        , private userService: UserService
    ) {
        this._queryParams$ = new BehaviorSubject(this.getQueryParams());
        this._articlesObj$ = new BehaviorSubject(this.getArticlesObj());

        this.queryParams$.subscribe(
            _ => {
                this.disableTabs = true;
                // console.log(this._queryParams$.value);
                if (this._queryParams$.value && this._queryParams$.value !== undefined) {
                    return this.getBusinessSignalsPosts(this._queryParams$.value).subscribe(
                        () => {
                            // When finished, we enable tabs
                            this.disableTabs = false;
                        }
                    );
                }
            }
        );
    }

    /**
     * Get articles content from a specific search query
     * @param query: {[key: string]: string } Query parameters
     */
    public getBusinessSignalsPosts(query: { [key: string]: any } = {}): Observable<ArticlesInterface> {

        let articles;

        return this.apiService.get<ArticlesInterface>('v1/articles/search', this._queryParams$.value).pipe(
            switchMap((response: HttpResponse<ArticlesInterface>) => {
                articles = response.body;
                const Query = {
                    articles: articles.articles,
                    leads: null,
                    criterions: query.criterions,
                    customer: null,
                    isMonitoring: null
                };
                return this.getArticlesContent(Query);
            }),
            map((articlesDetails: any) => {
                articles.articles_ = articlesDetails;
                this._articlesObj$.next(articles);

                // set previous to avoid multiple calls because it's too much shit to fix

                return articles;
            })
        );
    }

    /**
     * Get articles IDs of a specific search (the first in the list).
     * @param query: {[key: string]: string } Query parameters
     */
    public getArticlesSearch<T>(query: { [key: string]: any } = {}): Observable<ArticlesInterface> {
        // let articles;
        return this.apiService.get<ArticlesInterface>('v1/articles/search', query).pipe(
            map((response: HttpResponse<ArticlesInterface>) => {
                // let articles.articles_ = response.body;
                this._articlesObj$.next(response.body);
                return response.body;
            })
        );
    }

    /**
     * Get articles IDs of a specific search (the first in the list).
     * @param query: {[key: string]: string } Query parameters
     */
    public getArticlesSearchForPreviex<T>(query: { [key: string]: any } = {}): Observable<ArticlesInterface> {
        // let articles;
        return this.apiService.get<ArticlesInterface>('v1/articles/search', query).pipe(
            map((response: HttpResponse<ArticlesInterface>) => {
                // let articles.articles_ = response.body;
                return response.body;
            })
        );
    }

    /**
     * Get articles.
     * @param query
     */
    // public getArticlesContent(articles: { [key: string]: any } = {}, criterions: Array<number> = [], leads?: Array<number>, customer?: string, isMonitoring?: boolean): Observable<any> {
    //     if (isMonitoring === undefined || isMonitoring === null) {
    //         isMonitoring = false;
    //     }
    //
    //     let params = ``;
    //     if (criterions.length > 0 && customer === undefined) {
    //         params = `v1/articles?articles=${articles}&leads=${leads}&criterions=${criterions}&isMonitoring=${isMonitoring}`;
    //     } else if (criterions.length === 0 && customer === undefined) {
    //         params = `v1/articles?articles=${articles}&leads=${leads}&isMonitoring=${isMonitoring}`;
    //     } else if (criterions.length > 0 && customer !== null) {
    //         params = `v1/articles?articles=${articles}&criterions=${criterions}&customer=${customer}&isMonitoring=${isMonitoring}`;
    //     } else if (criterions.length === 0 && customer !== null) {
    //         params = `v1/articles?articles=${articles}&customer=${customer}&isMonitoring=${isMonitoring}`;
    //     }
    //     return this.apiService.get<ArticleInterface>(params).pipe(
    //         map((response: HttpResponse<any>) => {
    //             return response.body;
    //         })
    //     );
    // }

    public getArticlesContent(query: { [key: string]: any } = {}): Observable<any> {
        query.referencesArticlesInfosFromPortail = true;
        return this.apiService.get<ArticleInterface>('v1/articles', query).pipe(
            map((response: HttpResponse<any>) => {
                return response.body;
            })
        );
    }
    /**
     * Export articles list as Excel or CSV.
     * @param format: string Query parameter
     * @param query: {[key: string]: string } Articles list parameters
     */
    public exportArticlesList(format: string, query: { [key: string]: any } = {}): Observable<any> {
        return this.apiService.getAsBlob(`v1/articles/export/${format}?articles=${query}`).pipe(
            map((response: HttpResponse<any>) => {
                if (response.status === 200 || response.status === 201) {
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    public exportArticlesListHistory(query: { [key: string]: any } = {}): Observable<any> {
        return this.apiService.getAsBlob(`v1/share/history/export`, query).pipe(
            map((response: HttpResponse<any>) => {
                if (response.status === 200 || response.status === 201) {
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }


    /**
     * Export article for WebToLead.
     * @param format: string Query parameter
     * @param query: {[key: string]: string } Articles list parameters
     */
    public exportArticleWebToLead(articleId: number, contact: { [key: string]: any } = {}): Observable<any> {
        return this.apiService.post(`v1/article/${articleId}/webtolead`, contact).pipe(
            tap((response: HttpResponse<any>) => {
                if (response.status === 200 || response.status === 201) {
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    /**
     * Mark article as read.
     * @param articleId: number ID article parameter
     */
    public markArticleAsRead(articleId: number, read: boolean = true): Observable<any> {
        if (read) {
            return this.apiService.post(`v1/article/${articleId}/read`).pipe(
                map((response: HttpResponse<any>) => {
                    if (response.status === 200 || response.status === 201) {
                        return response;
                    } else {
                        throwError(new ErrorStatus(response.status));
                    }
                })
            );
        } else {
            return this.apiService.post(`v1/article/${articleId}/unread`).pipe(
                map((response: HttpResponse<any>) => {
                    if (response.status === 200 || response.status === 201) {
                        return response;
                    } else {
                        throwError(new ErrorStatus(response.status));
                    }
                })
            );
        }
    }

    public markArticlesAsRead(articles: Array<number>, read: boolean = true): Observable<any> {
        if (read) {
            return this.apiService.post(`v1/articles/read?articles=` + articles.join(',')).pipe(
                map((response: HttpResponse<any>) => {
                    if (response.status === 200 || response.status === 201) {
                        return response;
                    } else {
                        throwError(new ErrorStatus(response.status));
                    }
                })
            );
        } else {
            return this.apiService.post(`v1/articles/unread?articles=` + articles.join(',')).pipe(
                map((response: HttpResponse<any>) => {
                    if (response.status === 200 || response.status === 201) {
                        return response;
                    } else {
                        throwError(new ErrorStatus(response.status));
                    }
                })
            );
        }
    }

    /**
     * Print articles list
     * @param query: {[key: string]: string } Query parameters
     */
    public printArticlesList<T>(query: { [key: string]: any } = {}): Observable<T> {
        this.userService.logMultiArticles('SELECTION_PRINT_CHECKED', query.map(x => +x));
        return this.apiService.get<PrintInterface>(`v1/share/print?articles=${query}`).pipe(
            map((response: HttpResponse<T>) => {
                return response.body;
            })
        );
    }

    /**
     * Get articles email preview
     * @param query: {[key: string]: string } Query parameters
     */
    public emailPreviewArticlesList<T>(query: { [key: string]: any } = {}): Observable<T> {
        this.userService.logMultiArticles('SELECTION_MAIL_CHECKED', query.map(x => +x));
        return this.apiService.get<MailPreviewInterface>(`v1/share/email/preview?articles=${query}`).pipe(
            map((response: HttpResponse<T>) => {
                return response.body;
            })
        );
    }

    /**
     * Send an article by e-mail
     * @param addressId number : id of the address
     * @param articleId number : id of the article
     */
    public emailArticleById(addressId: number, articleId: number): Observable<HttpResponse<any>> {
        return this.apiService.post(`v1/share/${addressId}/${articleId}/email`).pipe(
            tap((response: HttpResponse<any>) => {
                if (response.status === 200) {
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    /**
     * Send an article by e-mail with custom query
     * @param query number : id of the address
     * @param articleId number : id of the article
     */
    public emailArticleAdvanced(query: { [key: string]: any } = {}): Observable<HttpResponse<any>> {
        return this.apiService.post(`v1/share/email`, query).pipe(
            tap((response: HttpResponse<any>) => {
                if (response.status === 200 || response.status === 201) {
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    getArticle(articleId: string, token: string): Observable<ArticleInterface> {
        return this.apiService.getWithoutAuth<ArticleInterface>(`v1/article/${articleId}?token=${token}`).pipe(
            map((response: HttpResponse<ArticleInterface>) => {
                return response.body;
            })
        );

    }
}
