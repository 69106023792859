import {Component, Inject, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {AuthService} from '../../../services/auth/auth.service';
import {UserService} from '../../../services/user/user.service';
import {ErrorStatus} from '../../../classes/ErrorStatus.class';
import {map, takeUntil} from 'rxjs/operators';
import {SnackbarService} from '../../../services/snackbar/snackbar.service';
import {ModalService} from '../../../services/modal/modal.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-fe-change-password-modal',
  templateUrl: './fe-change-password-modal.component.html',
  styleUrls: ['./fe-change-password-modal.component.scss']
})
export class FeChangePasswordModalComponent implements OnInit {

  public reinit: boolean = false;
  public oldPassword: string = '';
  public newPassword: string;
  public newPasswordConfirm: string;
  public disabledSubmit: boolean = true;
  public submitControlMessage: string = '';

  protected unsubscribe$: Subject<any> = new Subject<any>();

  constructor(private authService: AuthService,
              private userService: UserService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private snaccbarService: SnackbarService,
              private modalService: ModalService,
              public dialogRef: MatDialogRef<FeChangePasswordModalComponent>) {
    if (data != null && data.reinit != null) {
      this.reinit = data.reinit;
    }
  }

  ngOnInit() {
  }

  public validate(): void {
    if (this.disabledSubmit) {
      this.snaccbarService.showDangerSnackbar('Opération impossible', 'Vous devez saisir tous les champs.');
    } else {
      this.modalService.confirmModal('Attention', 'Confirmez-vous le changement de votre mot de passe ?',
          args => {
            this.userService.changeUserPassword({'oldPassword': this.oldPassword, 'newPassword': this.newPassword}).pipe(
                takeUntil(this.unsubscribe$)
            ).subscribe(
                (status) => {
                  if (status.body.success) {
                    let message = status.body.message;
                    message += 'Veuillez vous reconnecter pour continuer.';
                    this.snaccbarService.showSuccessSnackbar('Opération réussie', message);
                    this.dialogRef.close();
                    this.authService.logout();
                  } else {
                    this.snaccbarService.showDangerSnackbar('Opération impossible', status.body.message);
                  }
                },
                (error: Error | ErrorStatus) => {
                  let errorStr: string = 'Erreur lors du changement du mot de passe.';
                  if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
                    errorStr += `(${error.message})`;
                  }
                  this.snaccbarService.showDangerSnackbar('Opération impossible', errorStr);
                }
            );
          });
    }
  }

  close() {
    this.dialogRef.close();
  }

  checkPasswordValidation() {
    // https://github.com/tarunbatra/password-validator#readme
    const passwordValidator = require('password-validator');
    const schema = new passwordValidator();
    schema
        .is().min(8)
        .has().uppercase()
        .has().lowercase()
        .has().digits(2)
        .has().not().spaces();
    if (this.newPassword?.length > 0 && !schema.validate(this.newPassword)) {
      this.disabledSubmit = true;
      this.submitControlMessage = '*Le nouveau mot de passe doit faire au moins 8 caractères, contenir au moins: une lettre majuscule, une lettre minuscule, 2 chiffres et aucun espace.';
      return;
    }
    if (this.reinit && this.oldPassword === this.newPassword) {
      this.submitControlMessage = '*Le nouveau mot de passe doit être différent de l\'actuel.';
      return;
    }
    this.submitControlMessage = '';
    if (((this.reinit && this.oldPassword?.length > 0) || !this.reinit) && (this.newPassword?.length > 0 && this.newPasswordConfirm?.length > 0)) {
      if (this.newPassword !== this.newPasswordConfirm) {
        this.disabledSubmit = true;
        this.submitControlMessage = '*Le mot de passe de confirmation ne correspond pas à celui indiqué.';
      } else {
        this.submitControlMessage = '';
        this.disabledSubmit = false;
      }
      return;
    }

    this.submitControlMessage = '';
    this.disabledSubmit = true;
  }

}
