import {Component, OnInit, OnDestroy, Output, EventEmitter, Input} from '@angular/core';
import { Subject } from 'rxjs';
import { AppPreferencesService } from 'src/app/services/app-preferences/app-preferences.service';
import { MediaQueryService } from 'src/app/services/media-query/media-query.service';
import {UserService} from '../../services/user/user.service';
import {SessionInterface} from '../../interfaces/session/session.interface';
import {takeUntil} from 'rxjs/operators';
import {ColorType, IconType} from '../parts/fe-icon/fe-icon.component';

@Component({
  selector: 'toggle-list-style',
  templateUrl: 'toggle-list-style.component.html',
  styleUrls: ['toggle-list-style.component.scss'],
})
export class ToggleListStyleComponent implements OnInit, OnDestroy {

  @Output() public listStyleChanged: EventEmitter<any> = new EventEmitter<any>();

  private unsubscribe$: Subject<any> = new Subject<any>();

  public listStyle: string;
  public mapAvailable: boolean = false;
  public mapAvailableMessage: string = '';

  public _mapShowed: boolean = true;
  public menuIconAffichage: string = 'keyboard_arrow_down';
  public menuOpenAffichage: boolean = false;
  public modeAffichage: string = 'Extraits';
  public get mapShowed(): boolean {
    return this._mapShowed;
  }
  @Input() public set mapShowed(mapShowed) {
    this._mapShowed = mapShowed;
    if (!mapShowed && this.listStyle === 'listmap') {
      this.listStyle = 'big';
      this.appPreferencesService.set('articleListeStyle', this.listStyle);
      this.listStyleChanged.emit(this.listStyle);
    }
  }

  constructor(
    public mediaQueryService: MediaQueryService
    , private appPreferencesService: AppPreferencesService
    , private userService: UserService
  ) {}

  public ngOnInit(): void {
    // this.appPreferencesService.get('articleListeStyle')
    // .then((listStyle: string) => {
    //   this.listStyle = listStyle ? listStyle : 'small';
    //   this.listStyleChanged.emit(this.listStyle);
    // });
    if (this.mediaQueryService.mobileQuery.matches) {
      this.listStyle = 'small';
      this.modeAffichage = 'Titres';
      this.appPreferencesService.set('articleListeStyle', this.listStyle);
      this.listStyleChanged.emit('small');
    } else {
      this.listStyle = 'big';
      this.modeAffichage = 'Extraits';
      this.appPreferencesService.set('articleListeStyle', this.listStyle);
      this.listStyleChanged.emit('big');
    }

    // this.userService.sessionData.appConfig.viewConfigs.forEach(value => {
    //   if(value.component === "GEOLOCATION_MAP") {
    //     if(value.state === "visible") {
    //       this.mapAvailable = true;
    //     }
    //     this.mapAvailableMessage = value.message !== undefined && value.message !== '' ? value.message : 'Carte';
    //   }
    // });
    this.userService.getSessionDatas().pipe(
        takeUntil(this.unsubscribe$)
    )
    .subscribe(
        (sessionData: SessionInterface) => {
          if (sessionData !== undefined) {
            sessionData.appConfig.viewConfigs.forEach(value => {
              if (value.component === "GEOLOCATION_MAP") {
                if (value.state === "visible") {
                  this.mapAvailable = true;
                }
                this.mapAvailableMessage = value.message !== undefined && value.message !== '' ? value.message : 'Carte';
              }
            });
          }
        }
    );
  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public toggleStyle(listStyle: string, listLabel: string): void {
    if (listStyle == 'all') {
      this.modeAffichage = 'Complets'
    } else {
      this.modeAffichage = listLabel;
    }
    if (this.mapAvailable || listStyle !== 'listmap') {
      this.listStyle = listStyle;
      this.appPreferencesService.set('articleListeStyle', this.listStyle);
      this.listStyleChanged.emit(this.listStyle);
    }
  }

  onDropDownMenuOpenAffichage($event) {
    $event.stopPropagation();
    this.menuOpenAffichage = !this.menuOpenAffichage;
    if (this.menuOpenAffichage) {
      this.menuIconAffichage = 'keyboard_arrow_up';
    }
  }
  onMenuAffichageClosed() {
    this.menuOpenAffichage = false;
    this.menuIconAffichage = 'keyboard_arrow_down';
  }

  protected readonly IconType = IconType;
  protected readonly ColorType = ColorType;
}
