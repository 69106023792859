import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {EntreprisesService} from '../../../services/entreprises/entreprises.service';
import {Observable, Subject, Subscriber} from 'rxjs';
import {UserService} from '../../../services/user/user.service';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {SessionInterface} from '../../../interfaces/session/session.interface';
import {AlertController, ModalController, NavParams} from '@ionic/angular';
import {CustomerDetailsInterface} from '../../../interfaces/session/customer-details.interface';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {SelectionModel} from '@angular/cdk/collections';
import {SearchInterface} from '../../../interfaces/search/search.interface';
import {ErrorStatus} from '../../../classes/ErrorStatus.class';
import {ToastService} from '../../../services/toast/toast.service';
import {FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ModalService} from '../../../services/modal/modal.service';
import {ColorType} from "../../parts/fe-icon/fe-icon.component";

@Component({
    selector: 'app-share-searches',
    templateUrl: './share-searches.component.html',
    styleUrls: ['./share-searches.component.scss'],
})
export class ShareSearchesComponent implements OnInit, OnDestroy {

    protected unsubscribe$: Subject<any> = new Subject<any>();
    public user_id: string;
    public entrepriseToken: string;
    public entrepriseCustomersTokens: Array<string>;
    public userSearchesList: SearchInterface[];
    public userSearchesList$: Observable<SearchInterface[]>;
    public customersCtrl = new FormControl();
    public entrepriseCustomers: CustomerDetailsInterface[];
    public entrepriseCustomers$: Observable<CustomerDetailsInterface[]>;
    public filteredCustomers$: Observable<CustomerDetailsInterface[]>;
    public customersSelectionModel: SelectionModel<CustomerDetailsInterface> = new SelectionModel<CustomerDetailsInterface>(true, []);
    public searchesSelectionModel: SelectionModel<SearchInterface> = new SelectionModel<SearchInterface>(true, []);
    public selectAllCustomersDisabled: boolean;

    constructor(
        private toastService: ToastService,
        private userService: UserService,
        private entrepriseService: EntreprisesService,
        private alertController: AlertController,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ShareSearchesComponent>,
        private modalService: ModalService
    ) {
        this.selectAllCustomersDisabled = false;
    }

    private _filter(value: string): CustomerDetailsInterface[] {
        const filterValue = value;
        this.selectAllCustomersDisabled = filterValue !== '';
        return this.entrepriseCustomers.filter(customer => {
            if (customer.firstname !== undefined) {
                return customer.firstname.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())
                    || customer.lastname.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())
                    || customer.email.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase());
            }
            return false;
        });
    }

    ngOnInit() {
    this.userSearchesList = this.data.searchesList;
    if ( this.userSearchesList != null) {
      this.userSearchesList$ = new Observable((observer: Subscriber<any>) => {
        observer.next(this.userSearchesList);
        observer.complete();
      });
    }

    this.userService.getSessionDatas().pipe(
        takeUntil(this.unsubscribe$)
    )
        .subscribe(
            (sessionData: SessionInterface) => {
              if (sessionData !== undefined) {
                this.user_id = sessionData.customerDetails.user_id;
                this.entrepriseToken = sessionData.customerDetails.companyId;
              }
            }
        );
    if (this.entrepriseToken) {
      this.entrepriseService.getEntrepriseCustomers(this.entrepriseToken)
          .subscribe(
       entrepriseCustomersFromApi => {
           try {
               this.entrepriseCustomers = entrepriseCustomersFromApi.customers;
               this.entrepriseCustomers$ = new Observable((observer: Subscriber<any>) => {
                   observer.next(entrepriseCustomersFromApi.customers);
                   observer.complete();
               });
               this.filteredCustomers$ = this.customersCtrl.valueChanges.pipe(
                   startWith(null),
                   map((customer: string | null) => {
                           return customer ? this._filter(customer) : this.entrepriseCustomers.slice();
                       }
                   ));
               // Mapping des tokens des clients
               this.entrepriseCustomersTokens = entrepriseCustomersFromApi.customers
                   .filter(x => x.user_id !== this.user_id)
                   .map(x => x.user_id);
           } catch (e) {
           }
       }
      );
    } else {
      this.entrepriseCustomersTokens = [];
    }
  }

  public ngOnDestroy() {
        // this.unsubscribe$.next();
        this.unsubscribe$.complete();
  }

    public close(): void {
        this.dialogRef.close();
    }


    /**
     * Sélection de tous les clients
     * @param $event
     */
    selectAllCustomers($event: MatCheckboxChange) {
        this.entrepriseCustomers$.subscribe(customers => {
            for (const customer of customers) {
                if ($event.checked) {
                    this.customersSelectionModel.select(customer);
                } else {
                    this.customersSelectionModel.deselect(customer);
                }
            }
        });
    }

    /**
     * Sélection de toutes les recherches
     * @param $event
     */
    selectAllSearches($event: MatCheckboxChange) {
        this.userSearchesList$.subscribe(searches => {
            for (const search of searches) {
                if ($event.checked) {
                    this.searchesSelectionModel.select(search);
                } else {
                    this.searchesSelectionModel.deselect(search);
                }
            }
        });
    }

    /**
     * Lancement Partage des recherches
     */
    public shareSearches(): void {
        const searchesIds = this.searchesSelectionModel.selected
            .map((search) =>
                search.searchId);
        const customersTokens = this.customersSelectionModel.selected
            .map((customer) =>
                customer.user_id);
      // Query
        const query = {
            searchesIds: searchesIds,
            customersTokens: customersTokens
       };

        this.entrepriseService.shareSearches(query).pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(
            () => {
                this.toastService.simple('Recherche(s) dupliquée(s) avec succès.');
                // clear selectionModel to active disabled button when selectionModel is set
                this.searchesSelectionModel.clear();
                this.customersSelectionModel.clear();
                // Close the modal
                this.close();
            },
            (error: Error | ErrorStatus) => {
                let errorStr: string = 'Une erreur est survenue lors de la duplication de la recherche. Veuillez réessayer ultérieurement.';
                // if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
                //     errorStr += `(${this.translateService.instant(error.message)})`;
                // }
                this.toastService.simple(errorStr, {color: 'toasterror'});
            }
        );
    }

    /**
     * Boîte de dialogue - Confirmation
     */
    public async askBeforeShareSearches(): Promise<any> {
        this.modalService.confirmModal('Attention', 'Voulez-vous vraiment dupliquer ces recherches?', (data) => {
            this.shareSearches();
        });
    }

    protected readonly ColorType = ColorType;
}
