import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'badge-custom',
  templateUrl: 'badge-custom.component.html',
  styleUrls: ['badge-custom.component.scss'],
})
export class BadgeCustomComponent implements OnInit {

  @Input() prefixValue: string;
  @Input() badgeValue: string;

  constructor() {}

  public ngOnInit(): void {}
}
