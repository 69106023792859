import {EventEmitter, Injectable} from '@angular/core';
import {Observable, Subscriber, throwError, ReplaySubject, BehaviorSubject, shareReplay} from 'rxjs';
import { ApiService } from '../api/api.service';
import { SearchesInterface } from 'src/app/interfaces/searches/searches.interface';
import { HttpResponse } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { SearchInterface } from 'src/app/interfaces/search/search.interface';
import { CriterionsTypesInterface } from 'src/app/interfaces/criterions/criterions-types.interface';
import { CriterionInterface } from 'src/app/interfaces/criterions/criterion.interface';
import { SelectionModel } from '@angular/cdk/collections';
import { ErrorStatus } from 'src/app/classes/ErrorStatus.class';
import { SearchDataInterface } from 'src/app/interfaces/search/search-data.interface';
import {CitySuggestion} from '../../interfaces/search/CitySuggestion';

@Injectable({
  providedIn: 'root'
})
export class SearchesService {
  myEventToBusinessSignals = new EventEmitter<number>();
  myEventToFavorites = new EventEmitter<number>();
  refreshSearchId = new EventEmitter<number>();
  public get selectedCriterionsObj$() {
    return this._selectedCriterionsObj$.asObservable();
  }
  public get searchToEdit$() {
    return this._searchToEdit$.asObservable();
  }

  constructor(
    private apiService: ApiService
  ) {
    this.displayedChildrenCriterions = [];
    this.selectedCriterionsObj = {
      'label': '',
      'searchType': '',
      'locations': [],
      'sectors': [],
      'topics': [],
      'activityTypes': [],
      'companySizes': [],
      'keywords_default_operator': '',
      'keywords': [],
      'keywords_must': [],
      'keywords_must_exact': [],
      'keywords_must_not': [],
      'keywords_should': [],
      'alertState': true
    };
  }

  public get mySearchList$(): Observable<SearchInterface[]> {
    return this._mySearchList$;
  }

  public selectionModel: SelectionModel<CriterionInterface>;
  public allCriterions: CriterionsTypesInterface;
  public criterionMap = new Map<number, CriterionInterface>();
  public displayedChildrenCriterions: Array<CriterionInterface>;
  public selectedCriterionsKey: string;

  // Articles Object
  public selectedCriterionsObj: SearchDataInterface = null;
  public _selectedCriterionsObj$ = new BehaviorSubject<SearchDataInterface>(this.selectedCriterionsObj);

  public geolocActive: boolean;

  // Edit mode
  public editMode: boolean = false;

  // Search to edit
  public searchToEdit: SearchInterface = null;
  public _searchToEdit$ = new BehaviorSubject<SearchInterface>(this.searchToEdit);

  // Search Tabs
  public mySearches$: Observable<SearchesInterface>;
  public mySearches: SearchesInterface;

  private _mySearchList$: ReplaySubject<SearchInterface[]> = new ReplaySubject(1);

  key: string;
  public getSelectedCriterionsObj(): SearchDataInterface {
    return this.selectedCriterionsObj;
  }
  public setSelectedCriterionsObj(selectedCriterionsObj: SearchDataInterface): void {
    this._selectedCriterionsObj$.next(selectedCriterionsObj);
  }
  public getSearchToEdit(): SearchInterface {
    return this._searchToEdit$.getValue();
  }
  public setSearchToEdit(searchToEdit: SearchInterface): void {
    this._searchToEdit$.next(searchToEdit);
  }
  public setMySearchList(mySearchList: SearchInterface[]): void {
    this._mySearchList$.next(mySearchList);
  }

  public init(): void {
    this._searchToEdit$ = new BehaviorSubject(this.getSearchToEdit());
  }

  /**
   * Get all the user searches
   */
  public getMySearches(forceRefresh?: boolean): Observable<SearchesInterface> {
    // return this.getMySearchesFromhttp().pipe(shareReplay(8));
    return this.apiService.get<SearchesInterface>('v1/searches').pipe(
        map((response: HttpResponse<SearchesInterface>) => {
        this.setMySearchList(response.body.searches);
        return response.body;
      })
    );
  }

  private getMySearchesFromhttp(): Observable<SearchesInterface> {
    return this.apiService.get<SearchesInterface>('v1/searches').pipe(
        map((response: HttpResponse<SearchesInterface>) => {
          this.setMySearchList(response.body.searches);
          return response.body;
        })
    );
  }

  /**
   * Update the searches order
   * @param searches : data updated
   */
  public updateSearchesOrder(searches: SearchInterface[]): Observable<any> {
    return this.apiService.patch<SearchesInterface>(`v1/searches`, searches).pipe(
      map((response: HttpResponse<SearchesInterface>) => {
        if (response.status === 200 || response.status === 201) {
          return response;
        } else {
          throwError(new ErrorStatus(response.status));
        }
      })
    );
  }

  /**
   * Remove a search
   * @param searchID number : id of the search
   */
  public removeSearchById(searchID: number): Observable<HttpResponse<SearchInterface>> {
    // remove address to address through api
    return this.apiService.delete(`v1/search/${searchID}`).pipe(
      tap((response: HttpResponse<any>) => {
        if (response.status === 200) {
          return response;
        } else {
          throwError(new ErrorStatus(response.status));
        }
      })
    );
  }

  /**
   * Get all the searches criterions
   */
  public getCriterions(): Observable<CriterionsTypesInterface> {
    return this.apiService.get<CriterionsTypesInterface>('v1/criterions').pipe(
      map((response: HttpResponse<CriterionsTypesInterface>) => {
        return response.body;
      })
    );
  }

  public getAllCriterions(): CriterionsTypesInterface {
    return this.allCriterions;
  }

  public setAllCriterions(allCriterions: CriterionsTypesInterface): void {
    this.allCriterions = allCriterions;
    // create criterion map
    this.criterionMap.clear();
    this.addCriterionToMap(allCriterions.topics.values);
    this.addCriterionToMap(allCriterions.activityTypes.values);
    this.addCriterionToMap(allCriterions.sectors.values);
    this.addCriterionToMap(allCriterions.locations.values);
    this.addCriterionToMap(allCriterions.companySizes.values);
  }

  addCriterionToMap(criterions: Array<CriterionInterface>) {
    for (let crit of criterions) {
      this.criterionMap.set(Number(crit.id), crit);
      if (crit.childCriterions && crit.childCriterions.length > 0) {
        this.addCriterionToMap(crit.childCriterions);
      }
    }
  }

  /**
  * Add a specific search with new values
  * @param search SearchInterface : the updated search datas
  */
  public addMySearch(query: SearchDataInterface): Observable<HttpResponse<any>> {
    return this.apiService.post(`v1/searches`, query).pipe(
      tap((response: HttpResponse<any>) => {
        if (response.status === 200 || response.status === 201) {
          // console.log(response);
          return response;
        } else {
          throwError(new ErrorStatus(response.status));
        }
      })
    );
  }

  /**
  * Edit a specific search with new values
  * @param searchID number : id of the search
  * @param search SearchInterface : the updated search datas
  */
  public editMySearch(searchID: number, search: SearchDataInterface): Observable<SearchInterface> {
    return this.apiService.patch<SearchInterface>(`v1/search/${searchID}`, search).pipe(
      map((response: HttpResponse<SearchInterface>) => {
        return response.body;
      })
    );
  }

  /**
  * Edit a specific search with new values
  * @param searchID number : id of the search
  * @param search SearchInterface : the updated search datas
  */
  public duplicateSearchById(searchID: number): Observable<SearchInterface> {
    return this.apiService.post<SearchInterface>(`v1/search/${searchID}/duplicate`).pipe(
      map((response: HttpResponse<SearchInterface>) => {
        return response.body;
      })
    );
  }

  public renameSearchById(currentSearchId: number, libelle: string): Observable<SearchInterface> {
    return this.apiService.post<SearchInterface>(`v1/search/rename?currentSearchId=${currentSearchId}&libelle=${libelle}`).pipe(
        map((response: HttpResponse<SearchInterface>) => {
          return response.body;
        })
    );
  }

  public searchCriterions(criterionType: string[], input: string): Observable<Array<CriterionInterface>> {
    return this.apiService.get<Array<CriterionInterface>>(`v1/criterions/search?criterionType=${criterionType}&userSearchInput=${input}`).pipe(
        map((response: HttpResponse<Array<CriterionInterface>>) => {
          return response.body;
        })
    );
  }
  /**
  * Reorder filters of the list (No API query sent)
  * @param criterions Array<CriterionInterface> : array of criterions before being displayed
  */
  public getReorderedList(criterions: Array<CriterionInterface>) {
    const orderedArray: Array<CriterionInterface> = [];
    const rootArray: Array<CriterionInterface> = [];
    const nestArray: Array<CriterionInterface> = [];

    // For each criterion,
    for (const criterion of criterions) {
      // If there's no ancestor, we consider that it's a root item so we add it
      if (criterion.childCriterions === null) {
        rootArray.push(criterion);
      } else {
        nestArray.push(criterion);
      }
    }

    // We sort our arrays alphabetically
    rootArray.sort((a, b) => a.label.localeCompare(b.label));
    nestArray.sort((a, b) => a.label.localeCompare(b.label));

    // For each root item,
    for (const root of rootArray) {

      // We define a new variable for its children
      root.childCriterions = [];

      // We loop and add the ordered nested items
      for (const nest of nestArray) {
        if (nest.childCriterions === root.childCriterions) {
          root.childCriterions.push(nest);
        }
      }

      // Finally, we add the ordered root item with its children
      orderedArray.push(root);
    }

    return orderedArray;
  }

  /**
  * Get all the children criterions of a parent
  * We reinitialize the list where a call is done
  */
  public getChildrenCriterions(childrenCriterions: Array<CriterionInterface>): void {
    this.displayedChildrenCriterions = [];
    for (const childrenCriterion of childrenCriterions) {
      this.displayedChildrenCriterions.push(childrenCriterion);
    }
  }

  /**
  * Get the selected criterions key of the sub-modal
  */
  public getSelectedCriterionsKey() {
    return this.selectedCriterionsKey;
  }

  /**
  * Set the selected criterions key of the sub-modal
  */
  public setSelectedCriterionsKey(key: string) {
    this.selectedCriterionsKey = key;
  }

  private addCriterion(key: string, selectedCriterion: CriterionInterface) {
    if (key === 'topics' && selectedCriterion.lead === undefined) {
      let nbLeadChecked = 0;
      if (selectedCriterion.childCriterions !== undefined && selectedCriterion.childCriterions.length > 0) {
        for (const selectedChild of selectedCriterion.childCriterions) {
          if (selectedChild.subType === 'temporal' || selectedChild.subType === 'predictive') {
            if (selectedChild.lead.checked || selectedChild.checked_) {
              this.selectedCriterionsObj[key].push(Number(selectedChild.id));
              nbLeadChecked++;
            }
          }
        }
      }
      if (nbLeadChecked === 0) {
        this.selectedCriterionsObj[key].push(Number(selectedCriterion.id));
      }
    } else {
      this.selectedCriterionsObj[key].push(selectedCriterion.id);
    }
  }

  /**
  * Add all the selected criterions to a global list
  */
  public addToSelectedCriterions(key: string, selectedCriterionsObj: Array<CriterionInterface>, parentCriterion?: CriterionInterface): void {
  this.key = key;
    for (const selectedCriterion of selectedCriterionsObj) {
      // Parent
      // if (selectedCriterion.checked_ === true) {
      if (this.isChecked(selectedCriterion)) {
        // pays limitrophes
        if (selectedCriterion.id.toString() === '0') {
          for (const selectedChild of selectedCriterion.childCriterions) {
            this.addCriterion(key, selectedChild);
          }
        } else {
          if (parentCriterion !== undefined) {
            // if (!parentCriterion.checked_) {
            if (!this.isChecked(parentCriterion)) {
              this.addCriterion(key, selectedCriterion);
            }
          } else {
            this.addCriterion(key, selectedCriterion);
          }
        }
      }
      // Children
      if (selectedCriterion.childCriterions !== undefined && selectedCriterion.childCriterions.length > 0) {
        for (const selectedChild of selectedCriterion.childCriterions) {
          // if (selectedChild.checked_ === true) {
          if (this.isChecked(selectedChild)) {
            if (!this.isChecked(selectedCriterion)) {
              this.addCriterion(key, selectedChild);
            }
          }
          // sub children
          if (selectedChild.childCriterions !== undefined && selectedChild.childCriterions.length > 0) {
            this.addToSelectedCriterions(key, selectedChild.childCriterions, selectedChild);
          }
        }
      }
    }
  }

  public isChecked(criterion: CriterionInterface): boolean {
    if (this.allDescendantsChecked(criterion)) {
      return true;
    } else {
      if (criterion.checked_) {
        return !this.descendantsPartiallySelected(criterion, 0);
      }
      else {
        return criterion.checked_;
      }
    }
  }

  public allDescendantsChecked(criterion: CriterionInterface): boolean {
    if (criterion.childCriterions !== undefined && criterion.childCriterions.length > 0) {
      for (const child of criterion.childCriterions) {
        if (child.subType !== 'temporal' && child.subType !== 'predictive' && !child.checked_) {
          return false;
        }
      }
      if (criterion.childCriterions.length > 4 || this.key !== 'topics') {
        return true;
      }
    }
    return false;
  }

  public descendantsPartiallySelected(currentCriterion: CriterionInterface, count: number): boolean {

    if (currentCriterion.childCriterions !== undefined) {
      currentCriterion.childCriterions.forEach((child) => {
        if (typeof child !== 'undefined' && this.isChecked(child)) {
          count++;
        }
      });
    }
    if (currentCriterion.childCriterions !== undefined && count === currentCriterion.childCriterions.length || (currentCriterion.childCriterions !== undefined && this.key === 'topics' && count === (currentCriterion.childCriterions.length - 4))) {
      return false;
    } else if (count > 0) {
      return true;
    } else {
      let partial: boolean = false;
      if (currentCriterion.childCriterions !== undefined) {
        currentCriterion.childCriterions.forEach((child) => {
          if (!partial && child.childCriterions !== undefined) {
            partial = this.descendantsPartiallySelected(child, 0);
          }
        });
      }
      return partial;
    }
  }

  /**
   * Get autocomplete values for cities
   */
  public autocompleteCities(value: string): Observable<Array<CitySuggestion>> {
    // const query: Array<string> = new Array<string>(value);
    return this.apiService.get<Array<CitySuggestion>>(`v1/geoloc/cities?query=${value}`).pipe(
        map((response: HttpResponse<Array<CitySuggestion>>) => {
          return response.body;
        })
    );
  }

  getCityById(cityFrom: number): Observable<CitySuggestion> {
    return this.apiService.get<CitySuggestion>(`v1/geoloc/cities/${cityFrom}`).pipe(
        map((response: HttpResponse<CitySuggestion>) => {
          return response.body;
        })
    );
  }


  emitEventToBusinessSignals(eventData: number) {
    this.myEventToBusinessSignals.emit(eventData);
  }

  emitEventToFavorites(eventData: number) {
    this.myEventToFavorites.emit(eventData);
  }

  emitEventToRefreshSearchId(eventData: number) {
    this.refreshSearchId.emit(eventData);
  }

}
