
<mat-form-field color="primary" class="countryFormField">
  <input matInput [readonly]="field.readonly" type="text" #countryCode placeholder="{{'COUNTRY_CODE'}}"  matInput [formControl]="countryCodeFormControl" [errorStateMatcher]="xFormErrorStateMatcher"
    [matAutocomplete]="auto">
    <mat-icon matPrefix color="primary">flag</mat-icon>
    <mat-error *ngIf="countryCodeFormControl.hasError('required')">
      <span [innerHtml]="'E_FIELD_REQUIRED_SHORT'"></span>
    </mat-error>
    <mat-error *ngIf="countryCodeFormControl.hasError('invalidCountryCode') && !countryCodeFormControl.hasError('required')">
      <span [innerHtml]="'E_FIELD_NO_VALID'"></span>
    </mat-error>
  <mat-autocomplete #auto="matAutocomplete" class="countryCode">
    <mat-option *ngFor="let countryCode of filteredCountryCodes$ | async" [value]="countryCode">
      <div class="iti-flag" [ngClass]="countryCode | lowercase"></div>
      <div>{{ countryCode }}</div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field color="accent">

  <input matInput [type]="field.type" [formControl]="formControl" #input (blur)="onBlur($event, input.value)"
    [placeholder]="field.placeholder ? field.placeholder : ''" [readonly]="field.readonly"
    [errorStateMatcher]="xFormErrorStateMatcher" autocomplete="{{field.name}}" />

  <mat-hint align="start" *ngIf="field.hint && field.hint.start"><strong>{{field.hint.start}}</strong> </mat-hint>
  <mat-hint align="end" *ngIf="field.hint && field.hint.end && (!field.validators || !field.validators.maxLength) ">
    <strong>{{field.hint.end}}</strong>
  </mat-hint>
  <mat-hint align="end" *ngIf="field.validators && field.validators.maxLength">
    <strong>&nbsp;{{formControl.value.length}}
      / {{field.validators.maxLength}}</strong> </mat-hint>

<!--  <mat-error *ngIf="formControl.hasError('valid') && !formControl.hasError('required')">-->
<!--    <span [innerHtml]="'E_FIELD_NO_VALID' | translate"></span>-->
<!--  </mat-error>-->
<!--  <mat-error *ngIf="formControl.hasError('invalidEmail') && !formControl.hasError('required')">-->
<!--    <span [innerHtml]="'E_FIELD_NO_VALID_EMAIL' | translate"></span>-->
<!--  </mat-error>-->
<!--  <mat-error *ngIf="formControl.hasError('invalidPhoneNumber') && !formControl.hasError('required')">-->
<!--    <span [innerHtml]="'E_FIELD_NO_VALID_PHONE_NUMBER' | translate"></span>-->
<!--  </mat-error>-->
<!--  <mat-error *ngIf="formControl.hasError('required')">-->
<!--    <span [innerHtml]="'E_FIELD_NAME_REQUIRED' | translate:field"></span>-->
<!--  </mat-error>-->
<!--  <mat-error *ngIf="formControl.hasError('min') && !formControl.hasError('required')">-->
<!--    <span [innerHtml]="'E_FIELD_NO_VALID_MIN' | translate:field"></span>-->
<!--  </mat-error>-->
<!--  <mat-error *ngIf="formControl.hasError('minlength') && !formControl.hasError('required')">-->
<!--    <span [innerHtml]="'E_FIELD_NO_VALID_MIN_LENGTH' | translate:field"></span>-->
<!--  </mat-error>-->
<!--  <mat-error *ngIf="formControl.hasError('max') && !formControl.hasError('required')">-->
<!--    <span [innerHtml]="'E_FIELD_NO_VALID_MAX' | translate:field"></span>-->
<!--  </mat-error>-->
<!--  <mat-error *ngIf="formControl.hasError('maxlength') && !formControl.hasError('required')">-->
<!--    <span [innerHtml]="'E_FIELD_NO_VALID_MAX_LENGTH' | translate:field"></span>-->
<!--  </mat-error>-->
<!--  <mat-error *ngIf="formControl.hasError('pattern') && !formControl.hasError('required')">-->
<!--    <span [innerHtml]="'E_FIELD_NO_VALID_PATTERN' | translate"></span>-->
<!--  </mat-error>-->
<!--  <mat-error *ngIf="formControl.hasError('notEqual') && !formControl.hasError('required')">-->
<!--    <span [innerHtml]="'E_FIELD_NOT_EQUAL' | translate"></span>-->
<!--  </mat-error>-->

  <mat-icon *ngIf="field.matLeadingIcon" color="primary" matTooltip="{{field.matLeadingIconToolTip}}" matPrefix>{{field.matLeadingIcon}}
  </mat-icon>
  <mat-icon *ngIf="field.matTrailingIcon" color="primary" matTooltip="{{field.matTrailingIconToolTip}}" matSuffix>
    {{field.matTrailingIcon}}</mat-icon>
</mat-form-field>
