<fe-dots-menu>
  <fe-menu-item (click)="openCriterions(search, currentIndex)" color="PRIMARY" text="Voir les critères"
                [icon]="IconType.filter"/>
  <fe-menu-item *ngIf="fromMenu" (click)="openBusinessSignals(search.searchId, currentIndex)" color="PRIMARY"
                text="Voir les résultats" [icon]="IconType.eye_open"/>
  <fe-menu-item (click)="editSearch(search, currentIndex)" color="PRIMARY" text="Modifier" [icon]="IconType.edit"/>
  <fe-menu-item (click)="toggleNotifications($event)" color="PRIMARY"
                [text]="search?.data.alertState ? 'Désactiver les alertes par email' : 'Activer les alertes par email'"
                [icon]="search?.data.alertState ? IconType.alerte_active : IconType.alerte"/>
  <span class="fe-divider-thin"></span>
  <fe-menu-item (click)="confirmRenameSearch(search, currentIndex)" color="PRIMARY" text="Renommer"
                [icon]="IconType.rename"/>
  <fe-menu-item (click)="askBeforeDuplicateSearch(search)" color="PRIMARY" text="Dupliquer"
                [icon]="IconType.duplicate"/>
  <fe-menu-item (click)="askBeforeRemoveSearch(search, currentIndex)" color="DANGER" text="Supprimer"
                [icon]="IconType.delete"/>
</fe-dots-menu>


<!--<mat-icon [mat-menu-trigger-for]="manageSearches"class="listSearchMatIcon">more_vert</mat-icon>-->

<!--<mat-menu style="position: absolute"  #manageSearches="matMenu"  class="matMenuManageSearches" >-->
<!--  <button mat-menu-item (click)="openBusinessSignals(search.searchId, currentIndex)">-->
<!--    <mat-icon>visibility</mat-icon>-->
<!--    <span>Voir la recherche</span>-->
<!--  </button>-->
<!--  <button mat-menu-item (click)="toggleNotifications($event)">-->
<!--    <mat-icon *ngIf="!search?.data.alertState">notifications_none</mat-icon>-->
<!--    <mat-icon *ngIf="search?.data.alertState">notifications</mat-icon>-->
<!--    <span *ngIf="!search?.data.alertState">Activer les alertes par email</span>-->
<!--    <span *ngIf="search?.data.alertState">Alerte email activée</span>-->
<!--  </button>-->
<!--  <div style="width: 100%; border-bottom: solid 1px #cbcccd; margin: 5px 0 "></div>-->
<!--  <button mat-menu-item (click)="confirmRenameSearch(search.searchId, currentIndex)">-->
<!--    <mat-icon>border_color</mat-icon>-->
<!--    <span>Renommer</span>-->
<!--  </button>-->
<!--  <button mat-menu-item (click)="editSearch(search, currentIndex)">-->
<!--    <mat-icon>border_color</mat-icon>-->
<!--    <span>Editer</span>-->
<!--  </button>-->
<!--  <button mat-menu-item (click)="askBeforeDuplicateSearch(search)">-->
<!--    <mat-icon>file_copy</mat-icon>-->
<!--    <span>Dupliquer</span>-->
<!--  </button>-->
<!--  <button mat-menu-item [disabled]="search?.readOnly" (click)="askBeforeRemoveSearch(search, currentIndex)">-->
<!--    <mat-icon style="color: #BF0214">delete</mat-icon>-->
<!--    <span style="color: #BF0214">Supprimer</span>-->
<!--  </button>-->
<!--</mat-menu>-->
