<ion-header no-border>
  <ion-toolbar color="primary">
    <div class="ion-padding">
      <div class="logoHeader">
        <img style="height: 110px;" src="./assets/images/logo_blanc.png" alt="Logo First Eco" />
      </div>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content color="primary" class="ion-padding">

  <ng-container *ngIf="(token !== undefined && token !== '') || isMobile">
    <mat-card class="modalCard" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}" style="max-width: 400px; margin:auto;margin-top: 5%;padding: 15px;">
<!--      <h1 class="primaryColor center">Connexion</h1>-->
      <h2 style="font-weight: bold; color: #273C4E;margin-left: 4%;">Accéder à l'application</h2>


      <div style="display: flex; flex-direction: column; justify-content: space-between; height: 260px;padding: 6px 12px;"
           (keydown)="isEnterKey($event)">
        <fe-input [(value)]="email" width="100%" placeHolder="Email"  [name]="'login'" />
        <fe-input [(value)]="password" width="100%" placeHolder="Mot de passe" [type]="'password'"  [name]="'password'" />
        <fe-button class="loginButton" text="Connexion" type="PRIMARY" color="PRIMARY" bold="true" (click)="loginClick()"/>

        <button mat-flat-button (click)="reinitPassword()">Mot de passe oublié ?</button>

        <fe-button text="Aide" icon="true" [iconType]="IconType.creditInfo"
                   (click)="openZendesk();" style="margin:auto; margin-top:30px;"/>

        <fe-loader *ngIf="loadingInProgress" style="position: absolute; background: black; opacity: 0.85; top: 0; left: 0;" loaderText="Connexion en cours"/>
<!--        <button *ngIf="isMobile" mat-flat-button (click)="loginInfoModal()">Comment se connecter</button>-->
      </div>
    </mat-card>
  </ng-container>
  <ng-container *ngIf="!isMobile && (token === undefined || token === '')">
    <mat-card class="modalCard" style="color:black; padding: 20px; margin: 50px auto auto auto; width: 50%;">
      <h1 class="primaryColor center">Échec de l'authentification</h1>
      <h2 class="accentColor center">Votre accès à la plateforme First ECO a échoué en raison d'un problème d'authentification.</h2>
    
      <div>
        <p>Merci de bien utiliser le lien privé qui vous est personnellement adressé par email chaque jour.</p>
        <p>Si le problème persiste, n'hésitez pas à nous contacter :</p>
        <p>03 80 59 90 56 - contact@firsteco.fr</p>
      </div>
    </mat-card>
  </ng-container>
</ion-content>

<loading-overlay *ngIf="displayAutoConnectLoader">Connexion automatique</loading-overlay>
