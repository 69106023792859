<ion-content #scrollArea scrollEvents="true">
  <mat-sidenav-container #sidenavContainer autosize="true">
    <!--    [@.disabled]="true" autosize>-->
    <mat-sidenav #snav2 style="overflow: visible; padding: 8px 0;" class="left-side-bar"
                 [ngClass]="{'small': smallSidenavDesktop && !mediaQueryService.mobileQuery.matches,
                  'mobile': mediaQueryService.mobileQuery.matches}" mode="side" opened disableClose="true">
      <div [ngClass]="{'smallBtnContainer': smallSidenavDesktop}" style="text-align: right; width: 100%;">
        <fe-button (click)="toogleSidenavSize()" [ngClass]="{'small': smallSidenavDesktop}" type="TERTIARY"
                   color="PRIMARY" bold="true" icon="true" [iconType]="IconType.menu_open" [hideText]="smallSidenavDesktop"
                   text="Réduire" iconDirection="LEFT" [reverseIcon]="smallSidenavDesktop" [class.leftSideBarBtn]="!smallSidenavDesktop"
                   bigIcon="true" noUnderline="true"></fe-button>
      </div>
      <div class="leftSideBarFavoriteContent" [class.displayNone]="smallSidenavDesktop">
        <fe-button text="Créer un nouveau dossier" color="PRIMARY" icon="true" [iconType]="IconType.add_folder" class="createFolderBtnTour"
                   fullWidth="true" (click)="createFolder()"/>
        <div class="unClassifiedFavoris" [class.selectedFolder]="currentFolderId === 0">
          <span class="feFavoriteItemLabel" (click)="loadFolderArticles(0)">Mes favoris non classés</span>
          <div class="feFavoriteItemCount">
            <span class="littleTextBold">{{favoriteFolders[0].folderArticlesCount}}</span>
          </div>
        </div>
        <div class="favoriteListItems">
          <fe-favorite-item *ngFor="let favorisFolder of favoriteFolders; let i = index" [favorisFolder]="favorisFolder"
                            [class.displayN]="favorisFolder.folderID === 0" (click)="loadFolderArticles(i)"
                            [selectedFolder]="currentFolderId === favorisFolder.folderID"
                            (onRenameClick)="renameFolder(i)" (onDeleteClick)="removeFolder(i)"/>

          <!--        <search-list-item *ngFor="let favorisFolder of favoriteFolders; let i = index" [(value)]="_favoriteFolders[i]"-->
          <!--                          [selected]="_searchList[i].searchId == currentSearch?.searchId"-->
          <!--                          [index]="i" [searchList]="_searchList"-->
          <!--                          (searchSelected)="setCurrentSearch($event)"></search-list-item>-->
          <fe-loader *ngIf="favoriteFolders?.length < 1" class="feLoader" loaderText="Chargement en cours..."/>
        </div>
      </div>
      <fe-button color="PRIMARY" hideText="true" [iconType]="IconType.add_folder" style="margin-top: 30px;"
                 (click)="createFolder()" [class.displayNone]="!smallSidenavDesktop" icon="true"
                 type="TERTIARY" matTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="Créer un nouveau dossier" bigIcon="true"/>
<!--      <search-list-container class="search-list-container" [isSmallMode]="smallSidenavDesktop"-->
<!--                             [searchList]="searchList" (emitter)="changeSearchSelection($event)"></search-list-container>-->
    </mat-sidenav>
    <mat-sidenav-content style="display: flex; flex-direction: column;">
      <ion-toolbar style="z-index: 1;">
        <div class="toolbarRecherche">
          <div class="divTitre">
            <div class="divTitreLine" style="margin: 12px 0 16px;">
              <span class="titre">{{currentFolder.libelle}}</span>

              <fe-button color="PRIMARY" hideText="true" icon="true" type="TERTIARY" [iconType]="IconType.tour" class="tourBtnTour"
                         matTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="Guide sur les favoris" (click)="launchIntroFeatureTour(2);" />

              <fe-dots-menu style="margin: auto 0;">
                <fe-menu-item *ngIf="newsLetterAccess" color="PRIMARY" text="Créer une newsletter" [icon]="IconType.newsletter"
                              (click)="createNewsletter(currentIndex)"/>
                <fe-menu-item color="PRIMARY" text="Renommer" [icon]="IconType.rename" (click)="renameFolder(currentIndex)"/>
                <fe-menu-item color="DANGER" text="Supprimer" [icon]="IconType.delete" (click)="removeFolder(currentIndex)"/>
              </fe-dots-menu>
            </div>
            <div class="divTitreLine" style="height: 50px;">
              <div class="divActions" style="position: relative">
                <span class="numberArticleSelected" [ngClass]="{'noArticlesSelected':
                this.selection.selected.length === 0}">{{ this.selection.selected.length }}
                  sélectionné{{ this.selection.selected.length > 1 ? 's' : ''}}</span>
                <button class="multipleActionBtn2" [class.active]="this.selection.selected.length > 0">
                  <mat-checkbox *ngIf="!mediaQueryService.mobileQuery.matches"
                                (change)="checkAllArticles($event)" [checked]="isAllSelected()"
                                [indeterminate]="this.selection.selected.length > 0 && !isAllSelected()"/>
                  <mat-icon (click)="onDropDownMenuOpenActions($event)" [matMenuTriggerFor]="menu4">
                    {{menuIconActions}}
                  </mat-icon>
                </button>
              </div>
<!--              <div class="divActions flex1" [ngPlural]="this.selection.selected.length">-->
<!--                <mat-checkbox *ngIf="!mediaQueryService.mobileQuery.matches" class="checkAllArticlesBusinessSignals"-->
<!--                              (change)="checkAllArticles($event)" [checked]="isAllSelected() && this.selection.selected.length > 0"-->
<!--                              [indeterminate]="this.selection.selected.length > 0 && !isAllSelected()"/>-->
<!--&lt;!&ndash;                <mat-checkbox (change)="checkAllArticles($event)"&ndash;&gt;-->
<!--&lt;!&ndash;                              [checked]="selection.hasValue() && isAllSelected()"&ndash;&gt;-->
<!--&lt;!&ndash;                              [indeterminate]="selection.hasValue() && !isAllSelected()"&ndash;&gt;-->
<!--&lt;!&ndash;                              [aria-label]="checkboxLabel()">&ndash;&gt;-->
<!--&lt;!&ndash;                </mat-checkbox>&ndash;&gt;-->
<!--                <button class="multipleActionBtn" [ngClass]="{'noArticlesSelected': this.selection.selected.length === 0}" (click)="onDropDownMenuOpenActions($event)" [matMenuTriggerFor]="menu4">-->
<!--                  <span>Actions</span>-->
<!--                  <mat-icon>{{menuIconActions}}</mat-icon>-->
<!--                </button>-->
<!--                <span [ngClass]="{'noArticlesSelected': this.selection.selected.length === 0}"-->
<!--                      style="margin-right: 3px;">-->
<!--              {{ this.selection.selected.length }}-->
<!--                </span>-->
<!--                <span [ngClass]="{'noArticlesSelected': this.selection.selected.length === 0}">-->
<!--                  <ng-template ngPluralCase="=1">-->
<!--                    article sélectionné-->
<!--                  </ng-template>-->
<!--                  <ng-template ngPluralCase="other">-->
<!--                    articles sélectionnés-->
<!--                  </ng-template>-->
<!--                </span>-->
<!--              </div>-->

              <toggle-list-style style="border-radius: 8px;padding: 6px; margin: auto; height: fit-content;"
                                 (listStyleChanged)="listStyleChanged($event)"></toggle-list-style>

              <mat-paginator [length]="currentFolder.folderArticlesCount" [pageSizeOptions]="[25, 50, 100, 250]"
                             #paginator class="favoritePaginator" [pageIndex]="page" [pageSize]="pageSize"
                             (page)="handlePageEvent($event)"/>
            </div>
          </div>

          <!--      <mat-menu class="menuTri" style="position: absolute" #menuResultFilter="matMenu" (close)="onMenuTriClosed()">-->
          <!--        <fe-menu-item (click)="onFilterChange('default')"-->
          <!--                      color="PRIMARY" text="Aucun filtre" [icon]="IconType.title"-->
          <!--                      [class.selectedOption]="resultFilter == 'default'"/>-->
          <!--        <fe-menu-item (click)="onFilterChange('isMonitored')" color="PRIMARY"-->
          <!--                      text="Articles suivis" [icon]="IconType.alerte_active"-->
          <!--                      [class.selectedOption]="resultFilter == 'isMonitored'"/>-->
          <!--        <fe-menu-item (click)="onFilterChange('isNotMonitored')" color="PRIMARY"-->
          <!--                      text="Articles non suivis" [icon]="IconType.alerte"-->
          <!--                      [class.selectedOption]="resultFilter == 'isNotMonitored'"/>-->
          <!--        <fe-menu-item (click)="onFilterChange('isFavorite')" color="PRIMARY"-->
          <!--                      text="Articles favoris" [icon]="IconType.star_full"-->
          <!--                      [class.selectedOption]="resultFilter == 'isFavorite'"/>-->
          <!--        <fe-menu-item (click)="onFilterChange('isNotFavorite')" color="PRIMARY"-->
          <!--                      text="Articles non favoris" [icon]="IconType.star"-->
          <!--                      [class.selectedOption]="resultFilter == 'isNotFavorite'"/>-->
          <!--        <fe-menu-item (click)="onFilterChange('isRead')" color="PRIMARY"-->
          <!--                      text="Articles lus" [icon]="IconType.check"-->
          <!--                      [class.selectedOption]="resultFilter == 'isRead'"/>-->
          <!--        <fe-menu-item (click)="onFilterChange('isNotRead')" color="PRIMARY"-->
          <!--                      text="Articles non lus" [icon]="IconType.extrait"-->
          <!--                      [class.selectedOption]="resultFilter == 'isNotRead'"/>-->
          <!--      </mat-menu>-->

          <!--      <mat-menu class="menuTri" style="position: absolute" #menu2="matMenu" (close)="onMenuTriClosed()">-->
          <!--        <fe-menu-item (click)="onSortingOptionChange('sortByRelevance','pertinence')"-->
          <!--                      color="PRIMARY" text="Tri par pertinence" [icon]="IconType.pertinence"-->
          <!--                      [class.selectedOption]="_sortingOption == 'sortByRelevance'"/>-->
          <!--        <fe-menu-item (click)="onSortingOptionChange('sortByDateAsc','date croissante')" color="PRIMARY"-->
          <!--                      text="Tri par date croissante" [icon]="IconType.date_croissante"-->
          <!--                      [class.selectedOption]="_sortingOption == 'sortByDate' && articlesSortingMethod == 'publicationDateAsc'"/>-->
          <!--        <fe-menu-item (click)="onSortingOptionChange('sortByDateDesc','date décroissante')" color="PRIMARY"-->
          <!--                      text="Tri par date décroissante" [icon]="IconType.date_decroissante"-->
          <!--                      [class.selectedOption]="_sortingOption == 'sortByDate' && articlesSortingMethod == 'publicationDateDesc'"/>-->
          <!--      </mat-menu>-->

          <mat-menu style="position: absolute" (close)="onMenuActionsClosed()"  #menu4="matMenu" class="menuArticleAction">
            <fe-menu-item (click)="changeArticlesFolder()" color="PRIMARY" text="Changer de dossier" [icon]="IconType.star_move"/>
            <fe-menu-item (click)="removeArticlesFromFolder()" color="DANGER" text="Retirer du dossier" [icon]="IconType.delete"/>
            <fe-menu-item (click)="openEmailModal()" color="PRIMARY" text="Envoyer par email" [icon]="IconType.send_mail"/>
            <fe-menu-item (click)="markAsRead(true)" color="PRIMARY" text="Marquer comme lu" [icon]="IconType.eye_close"/>
            <fe-menu-item (click)="markAsRead(false)" color="PRIMARY" text="Marquer comme non-lu" [icon]="IconType.eye_open"/>
            <fe-menu-item (click)="exportArticlesList('excel', selection.selected)" color="PRIMARY"
                          text="Exporter sous format Excel"  [icon]="IconType.excel"/>
            <fe-menu-item (click)="exportArticlesList('csv', selection.selected)" color="PRIMARY"
                          text="Exporter sous format CSV" [icon]="IconType.csv"/>
            <fe-menu-item (click)="printArticles()" color="PRIMARY" text="Imprimer" [icon]="IconType.print"/>
          </mat-menu>
        </div>
      </ion-toolbar>

      <div class="listMap desktop">
        <mat-list class="paginated" style="display: flex; flex-direction: column;">
          <!-- loadingInProgress and mediaQueryService is declared in PaginatedListComponent -->
          <!--        <loading-overlay *ngIf="!loadingInProgress">Chargement des articles</loading-overlay>-->
          <fe-loader *ngIf="loadingInProgress" loaderText="Chargement des articles"/>
          <div class="nofavoritePanel" *ngIf="!loadingInProgress && dataSource.data.length < 1">
            <div class="nofavoriteTextContainer">
              <span class="bigTextBold">Vous n’avez pas encore d’articles dans votre dossier.</span>
              <span class="bigTextBold">Vous pouvez en ajouter depuis vos recherches.</span>
            </div>
            <fe-button text="Accéder à mes recherches" (click)="navigateToUrl('/app/business-signals')"
                       color="PRIMARY"/>
            <span *ngIf="currentFolderId > 0">
              <span class="bigTextBold">Ou</span>
              <br /><br />
              <fe-button text="Supprimer le dossier" (click)="removeFolder(currentIndex)" color="DANGER" />
            </span>
          </div>

          <div class="articlesContainer" *ngIf="!loadingInProgress && dataSource.data.length > 0">
            <fe-article-item [style]="listStyle" [article]="article" [selectionModel]="selection"
                             *ngFor="let article of dataSource.data;" (onChangeFavorite)="changeAnArticleFromFolder($event)"
                             (onRemoveFavorite)="removeAnArticleFromFolder()" fromFavorite="true"/>
          </div>
        </mat-list>

        <google-map *ngIf="(listStyle == 'listmap' || listStyle == 'map')"
                    [center]="mapCenter"
                    [zoom]="zoom"
                    (mapClick)="mapClick()">

          <map-marker-clusterer [calculator]="getClusterStyle" imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
            <ng-container *ngFor="let marker of markers; let i = index">
              <map-marker #mapMarker="mapMarker" [options]="marker.markerOption" (mapClick)="openInfoWindow(mapMarker, infoWindow)"></map-marker>
              <map-info-window #infoWindow="mapInfoWindow">
                <div class="listDate greyColor map-modal-text">{{marker.data.publicationDate | date:'dd/LL/yyyy':'':locale}}</div>
                <div style="display: block;margin:10px;font-size: 14px;cursor: pointer;" class="listTitle map-modal-text" onclick="return false;" (click)="openReadingModalFromMarker(marker.data)">{{marker.data.title}}</div>
              </map-info-window>
            </ng-container>
          </map-marker-clusterer>
        </google-map>
      </div>

    </mat-sidenav-content>
  </mat-sidenav-container>

<!--  <div class="listMapContainer"-->
<!--       [ngClass]="{-->
<!--    'mobile': mediaQueryService.mobileQuery.matches,-->
<!--    'desktop': !mediaQueryService.mobileQuery.matches,-->
<!--    'mapActivated': mediaQueryService.mobileQuery.matches && listStyle == 'map'-->
<!--  }">-->
<!--    <mat-list class="paginated">-->
<!--      &lt;!&ndash; loadingInProgress and mediaQueryService is declared in PaginatedListComponent &ndash;&gt;-->
<!--      <loading-overlay *ngIf="loadingInProgress && !mediaQueryService.mobileQuery.matches">{{"LOADING_FAVORITES"}}-->
<!--      </loading-overlay>-->
<!--      <no-data *ngIf="!loadingInProgress && !tableDataSource.data.length">{{"NO_FAVORITES"}}</no-data>-->
<!--      -->
<!--      <div class="tableContainer" [ngClass]="{'desktop': !mediaQueryService.mobileQuery.matches}">-->
<!--        &lt;!&ndash; tableDataSource is declared in PaginatedListComponent &ndash;&gt;-->
<!--        <table mat-table [dataSource]="tableDataSource">-->
<!--          <ng-container matColumnDef="customComponent">-->
<!--        -->
<!--            <th mat-header-cell *matHeaderCellDef> customComponent </th> &lt;!&ndash; not displayed, hide with css &ndash;&gt;-->
<!--            <td mat-cell *matCellDef="let elem">-->
<!--        -->
<!--              <article-list-item [article]="elem" (mouseout)="mouseOutArticle(elem)" (mouseover)="mouseOverArticle(elem)" [style]="listStyle" [from]="'favorite'" [selectionModel]="selectionModel">-->
<!--              </article-list-item>-->
<!--        -->
<!--            </td>-->
<!--          </ng-container>-->
<!--        -->
<!--          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>-->
<!--          <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>-->
<!--        </table>-->
<!--      </div>-->
<!--      -->
<!--      &lt;!&ndash; on desktop, this component will be placed in absolute, top:-500px... &ndash;&gt;-->
<!--      <mat-paginator [pageSizeOptions]="pageSizeOptions" style="height: 56px;" class="paginator" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}" [length]="_totalCount" [pageIndex]="_page" [pageSize]="_pageSize">-->
<!--      </mat-paginator>-->
<!--&lt;!&ndash;      [pageSizeOptions]="pageSizeOptions"&ndash;&gt;-->
<!--      &lt;!&ndash; Only on mobile &ndash;&gt;-->
<!--      &lt;!&ndash; onInfiniteScroll is declared in PaginatedListComponent &ndash;&gt;-->
<!--      <ion-infinite-scroll threshold="100px" (ionInfinite)="onInfiniteScroll($event)"-->
<!--        *ngIf="mediaQueryService.mobileQuery.matches">-->
<!--        <ion-infinite-scroll-content loadingSpinner="null">-->
<!--          <loading *ngIf="loadingInProgress" class="infiniteScrollLoading">{{"LOADING_FAVORITES"}}</loading>-->
<!--        </ion-infinite-scroll-content>-->
<!--      </ion-infinite-scroll>-->
<!--  -->
<!--      <button class="mat-rounded-button small" color="primary" mat-raised-button-->
<!--        *ngIf="!hideLoadMoreButton && mediaQueryService.mobileQuery.matches" (click)="loadMoreContentButton()">-->
<!--        <mat-icon>sync</mat-icon>-->
<!--        {{"LOAD_MORE_CONTENT"}}-->
<!--      </button>-->
<!--  -->
<!--    </mat-list>-->
<!--&lt;!&ndash;    <agm-map&ndash;&gt;-->
<!--&lt;!&ndash;            *ngIf="listStyle == 'listmap' || listStyle == 'map'"&ndash;&gt;-->
<!--&lt;!&ndash;            [latitude]="mapCenterLat"&ndash;&gt;-->
<!--&lt;!&ndash;            [longitude]="mapCenterLng"&ndash;&gt;-->
<!--&lt;!&ndash;            [zoom]="zoom"&ndash;&gt;-->
<!--&lt;!&ndash;            (mapClick)="mapClick()">&ndash;&gt;-->
<!--&lt;!&ndash;      <agm-marker-cluster imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m" [calculator]="getClusterStyle">&ndash;&gt;-->
<!--&lt;!&ndash;        <agm-marker *ngFor="let marker of markers; let i = index" [animation]="marker.animation"&ndash;&gt;-->
<!--&lt;!&ndash;                    [iconUrl]="marker.icon" [latitude]="marker.lat" [longitude]="marker.lng" [label]="marker.label"&ndash;&gt;-->
<!--&lt;!&ndash;                    [markerDraggable]="marker.draggable" (markerClick)=markerClick(iw)>&ndash;&gt;-->
<!--&lt;!&ndash;          &ndash;&gt;-->
<!--&lt;!&ndash;          <agm-info-window #iw>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="listDate greyColor map-modal-text">{{marker.data.publicationDate | date:'dd/LL/yyyy':'':locale}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div style="display: block;margin:10px;font-size: 14px;cursor: pointer;" class="listTitle map-modal-text" onclick="return false;" (click)="openReadingModalFromMarker(marker.data)">{{marker.data.title}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            <a href="#0" style="display: block;margin:10px;" class="listTitle map-modal-text" onclick="return false;" (click)="openReadingModalFromMarker(marker.data)">{{marker.data.contacts[0].organisationName}}</a>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;          </agm-info-window>&ndash;&gt;-->
<!--&lt;!&ndash;        &ndash;&gt;-->
<!--&lt;!&ndash;        </agm-marker>&ndash;&gt;-->
<!--&lt;!&ndash;      &ndash;&gt;-->
<!--&lt;!&ndash;      </agm-marker-cluster>&ndash;&gt;-->
<!--&lt;!&ndash;      <agm-marker [latitude]="lat" [longitude]="lng" [iconUrl]="myPostionIcon">&ndash;&gt;-->
<!--&lt;!&ndash;      </agm-marker>&ndash;&gt;-->
<!--&lt;!&ndash;    </agm-map>&ndash;&gt;-->
<!--&lt;!&ndash;    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">&ndash;&gt;-->
<!--&lt;!&ndash;      <p style="color: white">Chargement en cours. </p>&ndash;&gt;-->
<!--&lt;!&ndash;    </ngx-spinner>&ndash;&gt;-->
<!--  </div>-->
</ion-content>
