import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HelpInterface} from '../../interfaces/help/help.interface';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  private _menuLink: BehaviorSubject<string> = new BehaviorSubject(undefined);
  public get menuLink$(): Observable<string> {
    return this._menuLink;
  }

  constructor() { }

  public updateMenuLink(name: string): void {
    this._menuLink.next(name);
  }
}
