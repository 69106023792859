import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Subject} from 'rxjs';
import {CustomerDetailsInterface} from '../../interfaces/session/customer-details.interface';
import {SelectionModel} from '@angular/cdk/collections';
import {ArticleInterface} from '../../interfaces/articles/article.interface';
import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import {ArticleMonitoringService} from '../../services/article-monitoring/article-monitoring.service';
import {SessionInterface} from '../../interfaces/session/session.interface';
import {UserService} from '../../services/user/user.service';
import {ToastService} from '../../services/toast/toast.service';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {takeUntil} from "rxjs/operators";
import {SnackbarService} from "../../services/snackbar/snackbar.service";

@Component({
    selector: 'app-supervisor-monitoring-modal',
    templateUrl: './supervisor-monitoring-modal.component.html',
    styleUrls: ['./supervisor-monitoring-modal.component.scss'],
})
export class SupervisorMonitoringModalComponent implements OnInit {

    @Output() confirm = new EventEmitter<any>();
    protected unsubscribe$: Subject<any> = new Subject<any>();
    companyCustomers: { user_id: string, name: string, email: string }[] = [];
    articleAlreadyShared: { user_id: string, name: string, email: string }[] = [];
    // 1 click send
    public selectionModel: SelectionModel<CustomerDetailsInterface> = new SelectionModel<CustomerDetailsInterface>(true, []);
    public selfSelected: boolean = false;
    public notifiedByEmail: boolean = false;
    public article: ArticleInterface;
    public isSupervisor: boolean;
    public isFromMonitoringView: boolean;
    public listAssociatedCustomers: { user_id: string, name: string }[] = [];
    public addCollaborators: Boolean = false;

    // Selection model
    public customersSelectionModel: SelectionModel<{ user_id: string, name: string, email: string }> = new SelectionModel<{ user_id: string, name: string, email: string }>(true, []);

    // Selected Customers
    public selectedCustomers: Array<{ user_id: string, name: string, email: string }>;

    public commentForm: FormGroup;
    public supervisorComment: string;

    constructor(
        private fb: FormBuilder,
        private toastService: ToastService,
        private articleMonitoringService: ArticleMonitoringService,
        private userService: UserService,
        private snackbarService: SnackbarService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<SupervisorMonitoringModalComponent>
    ) {
        this.article = data.article;
        this.isSupervisor = data.isSupervisor;
        this.isFromMonitoringView = data.isFromMonitoringView;
        this.listAssociatedCustomers = data.listAssociatedCustomers;
    }

    ngOnInit() {
        this.userService.getSessionDatas()
            .subscribe(
                (sessionData: SessionInterface) => {
                    if (sessionData !== undefined) {
                        for (let i = 0; i < sessionData.companyCustomers.length; i++) {
                            const userid = sessionData.companyCustomers[i].user_id;
                            const name = sessionData.companyCustomers[i].name;
                            const email = sessionData.companyCustomers[i].email;
                            // const index: number = this.listAssociatedCustomers.indexOf(name);
                            // console.log(this.listAssociatedCustomers);
                            const index = this.listAssociatedCustomers.find(token => token.user_id === userid);
                            if (index === undefined) {
                                this.companyCustomers.push({'user_id': userid, 'name': name, 'email': email});
                            } else {
                                this.articleAlreadyShared.push({'user_id': userid, 'name': name, 'email': email});
                            }
                        }
                    }
                }
            );
        this.commentForm = this.fb.group({
            comment: ['']
        });
    }

    public close(): void {
        this.dialogRef.close();
    }

    public ngOnDestroy() {
        // this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    protected troncateText(text: string, length: number): string {
        let troncateText: string = text;
        if (troncateText.length > length + 2) {
            troncateText = troncateText.substring(0, length) + '...';
        }
        return troncateText;
    }

    public addMonitoredArticle(article: ArticleInterface): void {
        if((this.selectionModel.selected.length == 0) && !this.selfSelected) {
            this.snackbarService.showDangerSnackbar('Opération impossible',
                'Vous devez sélectionner au moins une personne à qui ajouter l\'article à son suivi.');
        }
        else {
            const articleID = article.id;
            const assignedToMe = this.selfSelected;
            if (this.supervisorComment === undefined) {
                this.supervisorComment = '';
            }
            let customers = '';
            let listEmails = '';
            const notifiedByEmail = this.notifiedByEmail;
            for (let i = 0; i < this.selectionModel.selected.length; i++) {
                customers += 'customers=' + this.selectionModel.selected[i].user_id + '&';
                listEmails += 'listEmails=' + this.selectionModel.selected[i].email + '&';
            }
            this.articleMonitoringService.addMonitoringArticles(articleID, assignedToMe, customers, notifiedByEmail, this.supervisorComment, listEmails)
                .pipe(
                    takeUntil(this.unsubscribe$)
                ).subscribe(next => {
                    console.log(next.body)
                if (next.body.length > 0) {
                    const customersSelected = {
                        selfSelected: this.selfSelected,
                        customersSelected: this.selectionModel.selected
                    };
                    this.confirm.emit(customersSelected);
                    this.dialogRef.close();
                } else {
                    this.snackbarService.showDangerSnackbar('Ajout au suivi d\'affaires', 'L\'article <b>\'' + this.troncateText(this.article.title, 50) + '\'</b> est déjà dans le kanban du collaborateur.');
                }
            });
        }
    }

    public selectSelf(): void {
        this.selfSelected = !this.selfSelected;
    }

    public emailNotification(): void {
        this.notifiedByEmail = !this.notifiedByEmail;
    }

    updateTextareaValue(event) {
        this.supervisorComment = event.target.value;
    }
}
