<div class="feModal shareSearchesModal">
  <div class="feModalHeader">
    <span class="feModalTitle">Duplication de recherches</span>
    <div class="feModalCloseButton" mat-dialog-close>
      <span mat-dialog-close>Fermer</span>
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
  </div>
  <div class="feModalContent">
    <div style="display: flex; flex-direction: column; gap: 8px;">
      <span class="bigTextBold">Comptes Clients</span>
      <mat-checkbox class="selectAll" [disabled]="this.selectAllCustomersDisabled && customersCtrl.value !== ''"
                    (click)="$event.stopPropagation()"
                    (change)="selectAllCustomers($event)">
        Sélectionner / Déselectionner tout
      </mat-checkbox>
      <input placeholder="Nom ou prénom" #addressInput [formControl]="customersCtrl" matInput>
      <mat-list class="shareSearchesMatList">
        <ng-container *ngIf="(this.entrepriseCustomers$| async) as entrepriseCustomers">
          <ng-container *ngIf="entrepriseCustomers.length; else noData">
            <mat-list-item class="list-item-container" *ngFor="let customer of filteredCustomers$ | async">
              <div style="display: flex">
                <mat-checkbox  [checked]="customersSelectionModel.isSelected(customer)" (click)="$event.stopPropagation()"
                               (change)="customersSelectionModel.toggle(customer)"></mat-checkbox>
                <div  class="address-fullname">
                  <span><span
                          style="font-size: 14px;font-weight: bold;margin: 0;">{{customer.firstname}} {{customer.lastname}} </span><span
                          style="font-size : 12px; color: #454545;">{{customer.email}}</span></span>
                </div>
              </div>
            </mat-list-item>
          </ng-container>
        </ng-container>
        <!--        <ng-template #loading>-->
        <!--          <loading-overlay>{{"LOADING_CUSTOMERS" | translate}}</loading-overlay>-->
        <!--        </ng-template>-->
        <ng-template #noData>
          <no-data>{{"NO_CUSTOMERS"}}</no-data>
        </ng-template>
      </mat-list>
    </div>

    <div style="display: flex; flex-direction: column; gap: 8px;">
      <span class="bigTextBold">Recherches</span>
      <mat-checkbox class="selectAll" (click)="$event.stopPropagation()" (change)="selectAllSearches($event)">
        Sélectionner / Déselectionner tout
      </mat-checkbox>
      <mat-list class="shareSearchesMatList">
        <ng-container *ngIf="(userSearchesList$| async) as userSearches; else loading">
          <ng-container *ngIf="userSearches.length ; else noData">
            <mat-list-item class="list-item-container" *ngFor="let search of userSearches">
              <div style="display:flex;">
                <mat-checkbox  [checked]="searchesSelectionModel.isSelected(search)" (click)="$event.stopPropagation()"
                               (change)="searchesSelectionModel.toggle(search)"></mat-checkbox>
                <div  class="address-fullname">
                  <span>{{search.data.label}} </span>
                </div>
              </div>
            </mat-list-item>
          </ng-container>
        </ng-container>
        <ng-template #loading>
          <loading-overlay>{{"LOADING_SEARCHES"}}</loading-overlay>
        </ng-template>
        <ng-template #noData>
          <no-data>{{"NO_SEARCH"}}</no-data>
        </ng-template>
      </mat-list>
    </div>
  </div>
  <div class="feModalFooter">
    <fe-button text="Annuler" type="TERTIARY" color="DANGER" mat-dialog-close/>
    <fe-button text="Dupliquer les recherches" (click)="askBeforeShareSearches()"/>
  </div>
</div>
