import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';
import { XFieldComponent } from '../x-field/x-field.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'x-checkbox',
  templateUrl: 'x-checkbox.component.html',
  styleUrls: ['./x-checkbox.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => XCheckboxComponent), multi: true }
  ]
})
export class XCheckboxComponent extends XFieldComponent implements ControlValueAccessor, OnInit {

  constructor(
    protected matSnackBar: MatSnackBar
  ) {
    super(matSnackBar);
  }
}
