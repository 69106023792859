import {Component, OnInit, OnDestroy, Inject, LOCALE_ID, ViewChild, AfterViewInit} from '@angular/core';
import { Subject } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { DatesSelectorModal } from '../modals/dates-selector/dates-selector.modal';
import { MediaQueryService } from 'src/app/services/media-query/media-query.service';
import { DateArticleInterface } from 'src/app/interfaces/date-article/date-article.interface';
import { DateArticleService } from 'src/app/services/date-article/date-article.service';
import {formatDate} from '@angular/common';
import {MatMenuTrigger} from '@angular/material/menu';
import {MatCalendar} from '@angular/material/datepicker';

@Component({
  selector: 'dates-selector-button',
  templateUrl: 'dates-selector-button.component.html',
  styleUrls: ['dates-selector-button.component.scss'],
})
export class DatesSelectorButtonComponent implements OnInit, OnDestroy {

  private unsubscribe$: Subject<any> = new Subject<any>();
  public dateArticle: DateArticleInterface;
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  selectedDateStart: Date = new Date();
  selectedDateEnd: Date = new Date();
  selectedDate: Date = new Date(2022, 4, 12);
  @ViewChild('calendar') calendar: MatCalendar<Date>;

  constructor(
    private modalController: ModalController
    , private mediaQueryService: MediaQueryService
    , private dateArticleService: DateArticleService
    , @Inject(LOCALE_ID) public locale: string
  ) {}

  public ngOnInit(): void {
    if (this.dateArticle === undefined) {
      // this.dateArticleService.dateArticle$.pipe(
      //   first()
      // ).subscribe(
      //   (dateArticle: DateArticleInterface) => {
      //     if (this.dateArticle === undefined) {
      //       this.dateArticle = dateArticle;
      //     }
      //   }
      // );
      this.dateArticleService.dateArticle$.subscribe(
          (dateArticle: DateArticleInterface) => {
            if (this.dateArticle === undefined) {
              this.dateArticle = dateArticle;
            }
          }
      );
    }
  }

  public async openReadingModal(): Promise<void> {
    const modal = await this.modalController.create({
      component: DatesSelectorModal,
      cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog',
      backdropDismiss: false,
      componentProps: {
        dateArticle: this.dateArticle
      }
    });

    modal.onWillDismiss()
    .then(
      (data: any) => {
        if (typeof data !== 'undefined' && typeof data.data !== 'undefined') {
          // if data exist, set new date in dateArticleService
          if (data.data.period) {
            data.data.from = this.dateArticleService.parsePeriodDate(data.data.period);
            data.data.from = this.dateArticleService.parsePeriodDate(data.data.period);
            data.data.to = formatDate(new Date(), 'yyyy-MM-dd', this.locale);
          }
          this.dateArticleService.setDate(data.data);
          this.dateArticle.period = data.data.period;
          this.setDates(data.data.from, data.data.to);
        }
      }
    );

    return await modal.present();
  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setDates(sdate: string, edate: string) {
    if (this.dateArticle === undefined) {
      this.dateArticle = new class implements DateArticleInterface {
        from: string = sdate;
        period: string;
        to: string = edate;
      };
    } else if (sdate !== undefined && sdate.length > 0 && edate !== undefined && edate.length > 0) {
      this.dateArticle.from = sdate;

      this.dateArticle.to = edate;


      if (this.calendar) {
        this.selectedDateStart = new Date(this.dateArticle.from);
        this.selectedDateEnd = new Date(this.dateArticle.to);
        this.selectedDate = new Date(this.dateArticle.from);
      }

    } else {
      this.dateArticle.from = undefined;
      this.dateArticle.to = undefined;
    }
  }

  close() {
    if (this.menuTrigger) {
      this.menuTrigger.closeMenu();
    }
  }

  //
  // changeDate() {
  //   this.selectedDateStart = new Date(this.dateArticle?.from);
  //   this.selectedDateEnd = new Date(this.dateArticle?.to);
  //   this.selectedDate = new Date(this.dateArticle?.from);
  // }

}
