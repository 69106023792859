import { Component, forwardRef, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { XFieldComponent } from '../x-field/x-field.component';
import { XAddress } from './XAddress';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'x-address',
  templateUrl: 'x-address.component.html',
  styleUrls: ['./x-address.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => XAddressComponent), multi: true }
  ]
})
export class XAddressComponent extends XFieldComponent implements OnInit {

  @ViewChild('input', {static: false}) public placeAutocomplete: ElementRef;

  public geocoderData: Observable<string[]>;

  constructor(private ngZone: NgZone
    , protected matSnackBar: MatSnackBar
  ) {
    super(matSnackBar);
  }

  ngOnInit(): void {

    // on assign les champ parce que le constructeur attend address?: google.maps.places.PlaceResult | google.maps.GeocoderResult
      // et on lui passe XAddress. Du coup on assigne
    let initialValue: XAddress = new XAddress();
    initialValue = Object.assign(initialValue, this.formControl.value);
    if (typeof initialValue.formatted_address !== 'undefined') {
      this.formControl.setValue(initialValue);
    }

    // this.mapsAPILoader.load()
    // .then(
    //   () => {
    //
    //     const options: any = {
    //       componentRestrictions: { country: 'fr' }
    //     };
    //
    //     const autocomplete = new google.maps.places.Autocomplete(this.placeAutocomplete.nativeElement, options);
    //     autocomplete.addListener('place_changed', () => {
    //       this.ngZone.run(() => {
    //         const place: google.maps.places.PlaceResult = autocomplete.getPlace();
    //         const xAdrress: XAddress = new XAddress(place);
    //
    //         this.writeValue(xAdrress);
    //         this.propagateChange(this.formFieldValue);
    //       });
    //     });
    //   }
    // )
    // .catch((error: Error) => this.displayMessage(error.message, 'error'));

    this.formControl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

}
