<ion-header no-border>
    <ion-toolbar>
        <mat-label slot="start"
                   style="margin-left: 10px; font-size: 16px; font-weight: bold">{{'Notifier des collaborateurs'}}</mat-label>
        <ion-buttons slot="end">
            <button mat-icon-button aria-label="close" (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<!--<ion-content>-->
    <div style=" margin-top: 8px; margin-right: 25px; margin-left: 25px;">
        <h3 style="font-weight:bold; margin-bottom: 5px;">Notifier par email les collaborateurs :</h3>
        <mat-list style="max-height: 250px; margin-bottom:15px; overflow: auto;">
            <ng-container *ngIf="companyCustomers!= undefined && companyCustomers.length; else noData">
                <div style="border: 1px solid #111111; border-radius:5px; max-height: 200px; overflow: auto; padding: 0px 5px 0 5px;">
                    <mat-list-item class="list-item-container"
                                   *ngFor="let collaborator of companyCustomers">
                        <mat-checkbox [checked]="selectionModel.isSelected(collaborator)"
                                      (click)="$event.stopPropagation()"
                                      (change)="selectionModel.toggle(collaborator)">
                            <span style="margin: 0" class="collaborator-fullname">
                                {{collaborator.name}}  ({{collaborator.email}})
                            </span>
                        </mat-checkbox>
                    </mat-list-item>
                </div>
            </ng-container>
            <ng-template #noData>
                <no-data>{{'Aucun collaborateur'}}</no-data>
            </ng-template>
        </mat-list>

        <div class="feModalFooter">
            <fe-button text="Valider" (click)="shareComment();"/>
        </div>
    </div>
<!--</ion-content>-->
