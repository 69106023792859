<div class="feContactCommercial feModal">
    <div class="feModalHeader">
        <span class="feModalTitle">Contacter mon conseiller</span>
        <div class="feModalCloseButton">
            <span mat-dialog-close>Fermer</span>
            <mat-icon mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="feModalContent">
        <div class="contactCommercialContainer">
            <span class="bigTitleBold">{{title}}</span>
            <div class="contactCommercialBloc">
                <div class="commercialContainer">
                    <span class="bigText">Rapprochez-vous de votre commercial dédié</span>
                    <div class="commercialInfosContainer">
                        <span class="bigText" [innerText]="getCommercialName()"></span>
                        <a *ngIf="salesPersonDetails.salesPersonPhone" class="bigTextBold"
                           [href]="'tel:' + salesPersonDetails.salesPersonPhone" >
                            {{salesPersonDetails.salesPersonPhone}}</a>
                        <a *ngIf="salesPersonDetails.salesPersonEmail" class="bigTextBold"
                           [href]="'mailto:' + salesPersonDetails.salesPersonEmail">
                            {{salesPersonDetails.salesPersonEmail}}</a>
                    </div>
                </div>
                <span class="veryBigTitleBold">OU</span>
                <fe-button text="Je souhaite être contacté" (click)="askForContact()"/>
            </div>
        </div>
    </div>
</div>
