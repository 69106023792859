<ion-header no-border>
    <ion-toolbar>
        <ion-buttons slot="end">
            <button mat-icon-button aria-label="close" (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <mat-tab-group class="scrollingGroup" #matTabGroup [class.header-less-tabs]="matTabGroup._tabs.length <= 1">
        <mat-tab label="{{'EMAIL_ONE_CLICK_SEND'}}" #oneClickTab
                 *ngIf="articles !== undefined && articles.length > 1">
            <input
                    placeholder="Filtrer les destinataires"
                    #addressInputFilterOneClick
                    [formControl]="addressCtrlFilterOneClick"
                    matInput
                    style="font-size: 15px; margin: 20px 0px 20px 0px;
                padding-bottom: 5px;
                border-bottom: thick !important;
                border-bottom: 2px solid black !important;">
            <mat-checkbox class="selectAll" (click)="$event.stopPropagation()"
                          (change)="selectAllAddresses($event, true)">
                Sélectionner / Déselectionner tout
            </mat-checkbox>
            <mat-list>
                <ng-container *ngIf="(addresses$ | async) as addresses; else loading">
                    <ng-container *ngIf="addresses.address.length; else noData">
                        <mat-list-item class="list-item-container"
                                       *ngFor="let address of filteredAddressesOneClick|async">
                            <mat-checkbox [checked]="selectionModel.isSelected(address)"
                                          (click)="$event.stopPropagation()"
                                          (change)="selectionModel.toggle(address)"></mat-checkbox>
                            <div class="address-fullname">
                                <h4>{{address.data.label}}</h4>
                                <p>{{address.data.email}}</p>
                            </div>
                        </mat-list-item>
                    </ng-container>
                </ng-container>
                <ng-template #loading>
                    <loading-overlay>{{"LOADING_ADDRESSES"}}</loading-overlay>
                </ng-template>
                <ng-template #noData>
                    <no-data>{{"NO_ADDRESSES"}}</no-data>
                </ng-template>
            </mat-list>
        </mat-tab>
        <mat-tab label="{{'EMAIL_ADVANCED_SEND'}}" #advancedTab>
            <div class="stickyButtons">
                <button mat-fab aria-label="Surligner" (click)="highlightSelection()">
                    <mat-icon>brush</mat-icon>
                </button>
                <button mat-fab aria-label="Enlever" (click)="removeHighlightSelection()">
                    <mat-icon>format_color_reset</mat-icon>
                </button>
            </div>
            <div style="margin: 0 16px;">
                <mat-checkbox #addChecked>Ajouter les nouveaux destinataires au carnet d'adresse</mat-checkbox>
                <input
                        placeholder="Ajouter un nouveau destinataire non présent dans la liste de contacts"
                        #addressInput
                        [formControl]="addressCtrl"
                        matInput
                        (keyup.enter)="addAddressInput($event)"
                        style="font-size: 15px; margin: 20px 0px 20px 0px;
                padding-bottom: 5px;
                border-bottom: thick !important;
                border-bottom: 2px solid black !important;">
                <input
                        placeholder="Filtrer les destinataires"
                        #addressInputFilter
                        [formControl]="addressCtrlFilter"
                        matInput
                        style="font-size: 15px; margin: 20px 0px 20px 0px;
                padding-bottom: 5px;
                border-bottom: thick !important;
                border-bottom: 2px solid black !important;">
                <!--        <div style="display: flex;flex-direction: row;">-->

                <!--      </div>-->
                <mat-checkbox class="selectAll" [disabled]="this.selectAllAddressesDisabled && addressCtrl.value !== ''"
                              (click)="$event.stopPropagation()" (change)="selectAllAddresses($event, false) ">
                    Sélectionner / Déselectionner tout
                </mat-checkbox>
                <mat-list #addressesList style="max-height: 200px; margin-bottom: 15px; overflow: auto;">
                    <ng-container *ngIf="(addresses$ | async) as addresses; else loading">
                        <ng-container *ngIf="addresses.address.length; else noData">
                            <mat-list-item class="list-item-container" *ngFor="let address of filteredAddresses|async"
                                           (click)="addAddress(address)"
                                           style="margin-left: 20px;padding: 10px 0;width : 95%;">
                                <mat-checkbox [checked]="advancedSelectionModel.isSelected(address)"
                                              (click)="$event.stopPropagation();"
                                              (change)="advancedSelectionModel.toggle(address)"
                                ></mat-checkbox>
                                <div class="address-fullname">
                  <span><span
                          style="font-size: 14px;font-weight: bold;margin: 0;">{{address.data.label}} </span><span
                          style="font-size : 12px; color: #454545;">{{address.data.email}}</span></span>
                                </div>
                            </mat-list-item>
                        </ng-container>
                    </ng-container>
                    <ng-template #loading>
                        <loading-overlay>{{"LOADING_ADDRESSES"}}</loading-overlay>
                    </ng-template>
                    <ng-template #noData>
                        <no-data>{{"NO_ADDRESSES"}}</no-data>
                    </ng-template>
                </mat-list>
                <mat-form-field class="full-width-input" style="margin: 10px 0px 10px 0px;">
                    <mat-label>{{"EMAIL_OBJECT"}}</mat-label>
                    <input matInput #objectInput placeholder="{{'EMAIL_OBJECT_PLACEHOLDER'}}"
                           value="{{'EMAIL_OBJECT_DEFAULT'}}" style="font-size: 16px;">
                </mat-form-field>

                <mat-checkbox [(ngModel)]="checked">{{'EMAIL_RECEIVE_COPY'}}</mat-checkbox>

                <mat-card class="loginCard" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
                    <div #htmlDiv class="htmlMailContainer"
                         [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}"
                         [innerHTML]="html | safe: 'html'"></div>
                    <ng-template #loadingHtml>
                        <mat-progress-spinner mode="indeterminate" diameter="20"></mat-progress-spinner>
                    </ng-template>
                </mat-card>
            </div>
        </mat-tab>
    </mat-tab-group>

    <div class="button-container">
        <button mat-button
                [disabled]="(advancedSelectionModel.selected.length == 0 && advancedTab.isActive) || (!advancedTab.isActive && selectionModel.selected.length == 0)"
                (click)="sendMail(!advancedTab.isActive)">{{"SUBMIT"}}</button>
    </div>
</ion-content>
