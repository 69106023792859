<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="end">
      <button mat-icon-button aria-label="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <ion-title *ngIf="modalTitle">Ajouter un contact</ion-title>

  <div>
    <mat-card class="loginCard" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
      <x-form [fields]="fields" [submitMethod]="formSubmitMethod" hideSubmitButton="true" ></x-form>
    </mat-card>

    <button 
      class="mat-rounded-button" 
      mat-raised-button color="accent" 
      (click)="send()"
      [disabled]="!formComponent.formGroup.valid">
      <mat-icon>check_circle_outline</mat-icon>
      {{"SAVE"}}
    </button>

  </div>

</ion-content>
