import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { LoginInterface } from 'src/app/interfaces/login/login.interface';
import { XFormComponent } from '../x-form/x-form.component';
import { FormFieldInterface } from 'src/app/interfaces/form-field/form-field.interface';
import { Subject, Observable, throwError } from 'rxjs';
import { FormGroup } from '@angular/forms';
import {AlertController, ModalController, NavController, Platform} from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';
import { tap, takeUntil, catchError } from 'rxjs/operators';
// import { environment } from 'src/environments/environment';
import { AppPreferencesService } from 'src/app/services/app-preferences/app-preferences.service';
import { MediaQueryService } from 'src/app/services/media-query/media-query.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import {ApiService} from '../../services/api/api.service';
// import {SearchInterface} from '../../interfaces/search/search.interface';
// import {SearchSummaryComponent} from '../modals/search/search-summary/search-summary.component';
import {ReinitPasswordComponent} from './reinit-password/reinit-password.component';
import {Utils} from '../../classes/Utils';
import {NavigationOptions} from '@ionic/angular/common/providers/nav-controller';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user/user.service';
import {ModalService} from '../../services/modal/modal.service';
import {FeChangePasswordModalComponent} from '../modals/fe-change-password-modal/fe-change-password-modal.component';
import {SessionInterface} from '../../interfaces/session/session.interface';
import {SnackbarService} from "../../services/snackbar/snackbar.service";
import {IconType} from "../parts/fe-icon/fe-icon.component";
import {AddressesService} from '../../services/addresses/addresses.service';

// declare var Fingerprint2: any;

declare global {
    interface Window {
        zE: any;
    }
}

@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {

  constructor(
    private navController: NavController
    , private authService: AuthService
    , private toastService: ToastService
    , private snackbarService: SnackbarService
    , private platform: Platform
    , private appPreferencesService: AppPreferencesService
    , public mediaQueryService: MediaQueryService
    , private apiService: ApiService
    , private userService: UserService
    , private modalController: ModalController
    , private alertController: AlertController
    , private route: ActivatedRoute
    , protected modalService: ModalService
    , private addressService: AddressesService
  ) {}

  public static url: string;

  public loadingInProgress = false;

  private unsubscribe$: Subject<any> = new Subject<any>();

  public isMobile: boolean = false;

  public token = Utils.userToken;

  private initPasswordModalOpen: boolean = false;

  public email: string = '';

  public password: string = '';

  // @ViewChild(XFormComponent, {static: false}) public formComponent: XFormComponent;

  // public fields: Array<FormFieldInterface> = [
  //   {
  //     type: 'email',
  //     template: 'default',
  //     placeholder: 'Identifiant' + ' *',
  //     label: 'Identifiant' + ' *',
  //     name: 'identifier',
  //     default: '',
  //     matLeadingIcon: 'person',
  //     validators: {
  //       required: true,
  //     },
  //     readonly: false
  //   },
  //   {
  //     type: 'password',
  //     template: 'default',
  //     placeholder: 'Mot de passe' + ' *',
  //     label: 'Mot de passe' + ' *',
  //     default: '',
  //     name: 'password',
  //     matLeadingIcon: 'lock',
  //     validators: {
  //       required: true
  //     },
  //     readonly: false
  //   }
  // ];

  public formSubmitMethod: Function;

  public displayAutoConnectLoader: boolean = false;

  loginClick() {
      this.loadingInProgress = true;
    // const loginData: LoginInterface = formGroup.value;
    // // loginData.identifier = loginData.identifier.toLowerCase();
    // return this.login(loginData);
    const loginData: LoginInterface = {
      identifier: this.email,
      password: this.password,
      external: false
    };
    return this.login(loginData).subscribe();
  }

  public ngOnInit(): void {

    // this.formSubmitMethod = (formGroup: FormGroup): Observable<any> => {
    //   const loginData: LoginInterface = formGroup.value;
    //   // loginData.identifier = loginData.identifier.toLowerCase();
    //   return this.login(loginData);
    // };

    const passwordInitialized: boolean = Utils.getParameterByName('initPassword') === 'true';
    // console.log('ng on init initPassword = ' + passwordInitialized);
    if (passwordInitialized && !this.initPasswordModalOpen) {
      this.initPassword();
      return;
    }

    // get loginData from AppPreference and try to auto connect user
    // if (this.platform.is('ios') || this.platform.is('android')) {
    //   if (this.platform.is('cordova')) {
    // alert(this.platform.is('desktop'));
    // alert(this.platform.is('mobileweb'));
    if (!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
    // if (!this.platform.url().startsWith('http')) {
      this.isMobile = true;
    }


    setTimeout(() => {
      this.appPreferencesService.get('loginData')
          .then((loginDataStr: string) => {
            if (typeof loginDataStr !== 'undefined' && loginDataStr !== null) {
              const loginData: LoginInterface = JSON.parse(loginDataStr);
              // loginData.identifier = loginData.identifier.toLowerCase();
              this.appPreferencesService.getValue('userToken').then((token: string) => {
                if (token === Utils.userToken || this.isMobile) {
                  this.displayAutoConnectLoader = true;
                  this.login(loginData).pipe(
                      takeUntil(this.unsubscribe$)
                  )
                      .subscribe(
                          () => {this.displayAutoConnectLoader = false; },
                          (error: Error) => {
                            this.displayAutoConnectLoader = false;
                            this.toastService.simple('Identifiant ou mot de passe incorrect', {duration: 2500, color: 'toasterror'});
                            // Log de l'erreur
                            this.userService.log('ERROR_ACCESS');
                          }
                      );
                }  else {
                  // Cas où le token n'est pas défini ou vide Cas ou n'est pas le bon
                  this.userService.log('UNKNOWN_TOKEN_ACCESS');
                }
              });
            }
          })
          .catch((error: Error) => {
            alert('error getting storage ' + error);
          });

      // this.formSubmitMethod = (formGroup: FormGroup): Observable<any> => {
      //   const loginData: LoginInterface = formGroup.value;
      //   // loginData.identifier = loginData.identifier.toLowerCase();
      //   return this.login(loginData);
      // };
    }, 1000);


  }

  public ionViewWillEnter(): void {
  }
  public async initPassword() {
      this.loadingInProgress = false;
    // console.log('open initPassword modal');
    this.initPasswordModalOpen = true;

    this.modalService.showCustomModalWithActionOnClose(FeChangePasswordModalComponent,
        { disableClose: true, data: {reinit: false}},
        (response) => {
          this.initPasswordModalOpen = false;
          // console.log('close initPassword modal');
        }
    );
  }
  private login(loginData: LoginInterface): Observable<any> {

    const self = this;
    return this.authService.fetchTokenUsingCredential(loginData).pipe(
      takeUntil(this.unsubscribe$),
      tap(() => {
            self.authService.authOk = true;
        this.appPreferencesService.setValue('userToken', Utils.userToken);
        const params = Utils.toParamMap(window.location.href.substring(window.location.href.indexOf('?')));
        const navOptions: NavigationOptions = {
          queryParams: params,
        };

        this.userService.clean();
        this.userService.init();
        this.userService.getSessionDatas().pipe(
            takeUntil(this.unsubscribe$)
        )
        .subscribe(
            (sessionData: SessionInterface) => {
              if (sessionData !== undefined) {
                if (!sessionData.customerDetails.mandatoryAuth) {
                  this.loadingInProgress = false;
                  navOptions.queryParams['initPassword'] = true;
                  this.initPassword();
                } else {
                  this.loadingInProgress = false;
                  this.addressService.loadAdresses();
                  const initPasswordValue = Utils.getParameterByName('initPassword', window.location.href);
                  if (initPasswordValue !== undefined) {
                    delete navOptions.queryParams['initPassword'];
                  }
                    this.unsubscribe$.next(0);
                    this.unsubscribe$.complete();
                  // this.toastService.simple('Connexion réussie. Merci de patienter pendant le chargement de vos signaux business.', { duration: 2500 });
                  if (params['monitoringArticleId'] || LoginComponent.url.includes('app/monitoring-articles')) {
                    Utils.navigate('app/monitoring-articles', this.navController, navOptions);
                  } else {
                    Utils.navigate('app', this.navController, navOptions);
                  }
                }
              }
            }
        );
      }),
      catchError((error) => {
          this.loadingInProgress = false;
        try {
          if (!(error.error.error_description === 'User account is locked') && !(error.error.error_description === 'User account is disabled')) {
            this.toastService.simple('Identifiant ou mot de passe incorrect', {duration: 2500, color: 'toasterror'});
          }
          return throwError(error);
        } catch (e) {
          this.toastService.simple('Identifiant ou mot de passe incorrect', {duration: 2500, color: 'toasterror'});
        }
        // Log de l'erreur
        this.userService.log('ERROR_ACCESS');
      })
    );
  }
  public goToForgottenPassword(): void {
    this.navController.navigateForward('forgotten-password');
  }

  public ngOnDestroy() {
    this.unsubscribe$.next(0);
    this.unsubscribe$.complete();
  }

  public resetFormData(): void {
    // this.formComponent.formGroup.reset();
  }
  protected openZendesk(): void {
      window.zE('webWidget', 'open');
  }

  async reinitPassword() {
    this.modalService.inputModal('Réinitialiser votre mot de passe', ' Indiquez votre adresse email pour recevoir un nouveau mot de passe.',
        'Email', '', this.email,
        (value) => {
          if (!value || value.length <= 0) {
            this.snackbarService.showDangerSnackbar('Réinitialiser votre mot de passe', 'Vous devez indiquer une adresse email de connexion.');
          }
          else {
              this.reinitPasswordSend(value);
          }
        });
  }
  reinitPasswordSend(email: String) {
      this.apiService.secureGet('authentification/reinitPassword?email=' + email).subscribe((response: any) => {
          if (response.response.body.success) {
              this.snackbarService.showSuccessSnackbar('Réinitialiser votre mot de passe', 'Un mail vous a été envoyé avec vos nouveaux identifiants.');
          }
          else {
              this.snackbarService.showDangerSnackbar('Réinitialiser votre mot de passe', 'Une erreur s\'est produite durant la réinitialisation du mot de passe.');
          }
      });
  }
  async loginInfoModal() {
    const alert = await this.alertController.create({
      header: 'Comment se connecter',
      message: 'Bienvenue sur l\'application mobile First ECO.<br><br>First ECO est créateur d\'opportunités depuis 2001. Nous détectons pour vous des Signaux Business B2B, qui vous permettent de développer votre entreprise.<br><br>Cette application est uniquement disponible pour les clients de First ECO ayant activé leur accès mobile.<br><br>Pour activer votre accès, merci de vous rendre sur la page "Mon Compte" de votre plateforme First ECO.<br><br>Si vous n\'êtes pas encore client, vous pouvez découvrir First ECO gratuitement et sans engagement : https://www.firsteco.fr/essai (offre réservée aux entreprises)<br>Votre conseiller vous contactera pour vous accompagner.',
      buttons: [
        {
          text: 'OK',
        }
      ]
    });
    await alert.present();
  }

    protected readonly IconType = IconType;

    protected isEnterKey($event: KeyboardEvent) {
        if ($event.key === 'Enter') {
            this.loginClick();
        }
    }
}
