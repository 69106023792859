<div class="feCarnetAdressesModal feModal">
    <div class="feModalHeader">
        <span class="feModalTitle">Carnet d’adresses</span>
        <div class="feModalCloseButton">
            <span mat-dialog-close>Fermer</span>
            <mat-icon mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="feModalContent">
        <div class="searchContactsContainer">
            <fe-searchbar placeHolder="Chercher un contact..." (valueChange)="filterChange($event)"/>
        </div>
        <div class="contactOptions">
<!--            <mat-checkbox [ngModel]="allContactSelected" [indeterminate]="someContactsChecked()"-->
<!--            (change)="checkAllContacts($event.checked)"/>-->
            <mat-checkbox (change)="$event ? toggleAllRows() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()">
            </mat-checkbox>
            <fe-button *ngIf="selection.hasValue()" icon="true" [iconType]="menuIconActions"
                       class="multipleActionBtn" (click)="onDropDownMenuOpenActions($event)"
                       [ngClass]="{'noArticlesSelected': this.contactsLines.length === 0}" iconDirection="RIGHT"
                       [matMenuTriggerFor]="menuCarnetAddress" smallButton="true" text="Actions"/>
            <span [ngClass]="{'noArticlesSelected': this.contactsLines.length === 0}"
                  *ngIf="numberOfSelectedContacts() > 0">
              {{ numberOfSelectedContacts() + " " + getContactsLabel() }}
            </span>
            <fe-button class="marginLeftAuto" [iconType]="IconType.add_person" icon="true" [text]="'Ajouter un nouveau contact'"
                       (click)="openContactModal(true, null)" type="SECONDARY" bold="true"/>
            <mat-menu class="feMatMenu" style="position: absolute" (close)="onMenuActionsClosed()" #menuCarnetAddress="matMenu">
                <fe-menu-item [icon]="IconType.delete" color="DANGER" text="Supprimer les contacts"
                              (click)="deleteSelectedContacts()"/>
            </mat-menu>
        </div>

        <div class="contactsTableContainer" [class.displayNone]="loading">
            <table mat-table [dataSource]="dataSource" matSort #contactLinesSort="matSort"
                   class="mat-elevation-z8">

                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                      (change)="$event ? selection.toggle(row) : null"
                                      [checked]="selection.isSelected(row)"
                                      [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <!-- Position Column -->
                <ng-container matColumnDef="label">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>Nom</th>
                    <td mat-cell *matCellDef="let contactLine"> {{contactLine.label}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="email">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>Mail</th>
                    <td mat-cell *matCellDef="let contactLine"> {{contactLine.email}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="options">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let contactLine">
                        <fe-dots-menu>
                            <fe-menu-item [icon]="IconType.edit" text="Editer le contact"
                                          (click)="openContactModal(false, contactLine)"/>
                            <fe-menu-item [icon]="IconType.delete" color="DANGER" text="Supprimer le contact"
                                          (click)="deleteContact(contactLine)"/>
                        </fe-dots-menu>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
                <tr mat-row class="contactLine" [class.contactRecenltyAdded]="row.recentlyAdded"
                    *matRowDef="let row; columns: displayedColumns;">
                </tr>
            </table>
        </div>
<!--        <div class="contactsContainer" *ngIf="!loading">-->
<!--            <div class="contactLine" *ngFor="let contactLine of contactsLines"-->
<!--                 [class.contactRecenltyAdded]="contactLine.recentlyAdded">-->
<!--                <mat-checkbox [(ngModel)]="contactLine.checked" (ngModelChange)="updateAllComplete()"/>-->
<!--                <span class="contactName">{{contactLine.label}}</span>-->
<!--                <span class="contactMail">{{contactLine.email}}</span>-->
<!--                <fe-dots-menu>-->
<!--                    <fe-menu-item [icon]="IconType.edit" text="Editer le contact" (click)="openContactModal(false, contactLine)"/>-->
<!--                    <fe-menu-item [icon]="IconType.delete" color="DANGER" text="Supprimer le contact"-->
<!--                                  (click)="deleteContact(contactLine)"/>-->
<!--                </fe-dots-menu>-->
<!--            </div>-->
<!--        </div>-->
        <fe-loader class="feLoader" [class.displayNone]="!loading" loaderText="Chargement en cours..."/>
    </div>
    <div class="feModalFooter" *ngIf="fromMailModal">
        <fe-button class="marginLeftAuto" [iconType]="IconType.check" icon="true"
                   text="Ajouter les contacts sélectionnés à l'envoi" (click)="addContactsToMail()"/>
    </div>
</div>
