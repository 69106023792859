<div class="feMonitorSupervisordAddModal feModal">
  <div class="feModalHeader">
    <span class="feModalTitle">Clotûre d'un article suivi</span>
    <div class="feModalCloseButton">
      <span mat-dialog-close>Fermer</span>
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
  </div>
  <div class="feModalContent">
    <h3 style="margin-bottom: 5px;">Article : '{{ troncateText(article.title, 80) }}' </h3>

    <div>
     Vous devez indiquer le statut d'affaire lors du passage à l'étape "Clôturé".
    </div>
    <div style="flex: 1;display: flex; flex-direction: row; margin-top: 10px;">

      <div style="font-weight: bold; margin-right: 25px; padding-top:3px;">Statut : </div>
      <button class="dropdownBtn" style="margin: initial; width: 255px;"
              [matMenuTriggerFor]="menu2">
        <span style="text-align: left; flex: 1;">{{libelle}}</span>
      </button>
      <mat-menu style="position: absolute; max-height: 400px;" #menu2="matMenu">
        <fe-menu-item (click)="chooseStatut('in_progress_prevost', 'En cours CRM Prevost')" color="PRIMARY" *ngIf="userService.sessionData.customerDetails.companyId == '0010Y00000t5cHBQAY'" text="En cours CRM Prevost" />
        <fe-menu-item (click)="chooseStatut('gagné', 'Gagné')" color="PRIMARY" text="Gagné" />
        <fe-menu-item (click)="chooseStatut('perdu', 'Perdu')" color="PRIMARY" text="Perdu" />
        <fe-menu-item (click)="chooseStatut('reporté', 'Reporté')" color="PRIMARY" text="Reporté" />
        <fe-menu-item (click)="chooseStatut('hors cible','Hors-cible')" color="PRIMARY" text="Hors-cible" />
      </mat-menu>
    </div>
  </div>
  <div class="feModalFooter">
    <fe-button text="Annuler" type="TERTIARY" color="DANGER" mat-dialog-close />
    <fe-button text="Valider" (click)="close(true);" />
  </div>
</div>
