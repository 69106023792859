import {Component, OnInit, OnDestroy, ViewChild, ViewChildren} from '@angular/core';
import {takeUntil, switchMap, startWith, tap, take, skip} from 'rxjs/operators';
import {Subject, Observable, Subscription} from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MediaQueryService } from 'src/app/services/media-query/media-query.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ArticleInterface } from 'src/app/interfaces/articles/article.interface';
import { Utils } from 'src/app/classes/Utils';
import {MarkerInterface} from '../../interfaces/marker/marker.interface';
import {Geolocation, GeolocationOptions, Geoposition} from '@awesome-cordova-plugins/geolocation/ngx';
import {MarkerIconInterface} from '../../interfaces/marker-icon/marker-icon.interface';
import {GoogleMapsTheme} from '../../classes/GoogleMapsTheme';
import Marker = google.maps.Marker;
import {ToastService} from '../../services/toast/toast.service';
import {ReadingArticleModal} from '../modals/reading-article/reading-article.modal';
import {ModalController, NavController} from '@ionic/angular';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {SessionInterface} from '../../interfaces/session/session.interface';
import {UserService} from '../../services/user/user.service';
import MarkerOptions = google.maps.MarkerOptions;
import Icon = google.maps.Icon;
import LatLngLiteral = google.maps.LatLngLiteral;
import {MapInfoWindow} from '@angular/google-maps';

@Component({
  selector: 'paginated-list',
  template: ''
})
export class PaginatedListComponent implements OnInit, OnDestroy {
  protected set totalCount(totalCount: number) {
    this._totalCount = totalCount;
  }
  protected get totalCount(): number {
    return this._totalCount;
  }
  protected set page(page: number) {
    this._page = page;
  }
  protected get page(): number {
    return this.mediaQueryService.mobileQuery.matches ? this._page : this.matPaginator.pageIndex + 1;
  }
  protected set pageSize(pageSize: number) {
    this._pageSize = pageSize;
  }
  protected get pageSize(): number {
    return this._pageSize;
  }
  // query parameters
  protected get query(): any {
    const query: any = {
      page: this.page,
      pageSize: this.pageSize,
      startDate: this.startDate,
      endDate: this.endDate
    };
    return query;
  }

  constructor(
      public mediaQueryService: MediaQueryService
      , public geolocation: Geolocation
      , public toastService: ToastService, public modalController: ModalController
      , public userService: UserService) {
    this.selectionModel.changed.asObservable().subscribe(value => {
      this.markers = this.getMarkers(this.tableDataSource.data);
    });
  }

  // @ViewChildren()
  @ViewChild(MatPaginator, {static: true}) protected matPaginator: MatPaginator;
  // @ViewChild(AgmMap, {static: true}) protected agmMap: AgmMap;

  protected unsubscribe$: Subject<any> = new Subject<any>();

  // use to store selected article (by id)
  public selectionModel: SelectionModel<any> = new SelectionModel<any>(true, []);

  // big or small define by ToggleListStyleComponent
  public listStyle: string;

  // we use matTable juste for add paginator abilities.
  // we use only one column to display one custom component
  // data for matTable
  public tableDataSource: MatTableDataSource<ArticleInterface> = new MatTableDataSource();
  public columnsToDisplay: string[] = ['customComponent'];

  // store count of all article for current request
  public _totalCount: number;
  // store infinit scroll event to use later
  private infiniteScrollEvent: any;

  // boolean to display loading comp
  public loadingInProgress: boolean = false;

  // boolean to display or not load more content button. Use in child template
  public hideLoadMoreButton: boolean = false;

  // google maps zoom level
  public zoom: number = 8;
  public mapCenter: LatLngLiteral = {
    lat: 47.3167,
    lng: 5.0167
  };

  currentIW: MapInfoWindow;
  previousIW: MapInfoWindow;

  public markerIcon: Icon = {
    url: './assets/images/marker_fe.png',
    scaledSize: {
      width: 34,
      height: 60,
      equals(other: google.maps.Size | null): boolean {
        return false;
      }
    }
  };
  public bigIcon: Icon = {
    url: './assets/images/marker_fe.png',
    scaledSize: {
      width: 51,
      height: 90,
      equals(other: google.maps.Size | null): boolean {
        return false;
      }
    }
  };
  public myPostionIcon: Icon = {
    url: './assets/images/marker_current.png',
    scaledSize: {
      width: 30,
      height: 45,
      equals(other: google.maps.Size | null): boolean {
        return false;
      }
    }
  };
  public markers: Array<MarkerInterface>;
  public styles: any[] = GoogleMapsTheme.getJson();


  // page number
  public _page: number = 1;  // public because used by template (paginator)

  pageSizeOptions: number[] = [25, 50, 100, 250];
  // number of item by page
  public _pageSize: number = 25;
  public startDate: String = '';
  public endDate: String = '';
  // public because used by template (paginator)
  // store last query. if some parameters change, reset datasource.
  private lastQuery: any;
  // array of attribute to check to know if we do reset dataSource or Not
  protected watchQueryProperties: Array<string> = [];

  private pageObservable: Observable<any>;

  private pageSubscription: Subscription;
  public previousData;

  getPageObservable<T>() {
    // if (this.pageSubscription !== undefined) {
    //   this.pageSubscription.unsubscribe();
    // }
    if (this.pageObservable === undefined) {
      this.pageObservable = this.matPaginator.page.pipe(
          takeUntil(this.unsubscribe$),
          // take(1),
          tap(() => {
            // if desktop, reset data before loading
            if (!this.mediaQueryService.mobileQuery.matches) {
              this.tableDataSource.data = [];
            }
          }),
          startWith(this.load<T>()),
          // tap(() => this.loadingInProgress = false),
          switchMap(() => this.load<T>()),
          // tap(() => this.loadingInProgress = false),
      );
    }
    return this.pageObservable;
  }

  public ngOnInit(): void {
    this.userService.getSessionDatas().pipe(
        takeUntil(this.unsubscribe$)
    )
        .subscribe(
            (sessionData: SessionInterface) => {
              if (sessionData !== undefined) {
                sessionData.appConfig.viewConfigs.forEach(value => {
                  if (value.component === 'GEOLOCATION_MAP') {
                    if (value.state === 'visible') {
                      this.initGeolocation();
                    }
                    // this.mapAvailableMessage = value.message !== undefined && value.message !== '' ? value.message : 'Carte';
                  }
                });
              }
            }
        );

  }

  protected init<T>(): void {
    // Set paginator in tableDataSource
    // this.tableDataSource.paginator = this.matPaginator;

    // this.spinnerService.show();

    // this.loadingInProgress = true;
    // if (this.pageObservable === undefined) {
    if (this.pageSubscription !== undefined) {
      this.pageSubscription.unsubscribe();
    }
    let skipCount = 1;
    if (Utils.getSkip()) {
      skipCount = 0;
      Utils.skip = false;
    }
    this.pageSubscription = this.getPageObservable().pipe().subscribe((data: T) => {
      // this.pageSubscription = this.getPageObservable().pipe(skip(skipCount)).subscribe((data: T) => {
        if (data && data !== undefined && data !== this.previousData) {
          // this.loadingInProgress = true;
          this.previousData = data;
          // this.spinnerService.hide();
          // For the scroll to go back top when changing page
          if (document.getElementsByClassName('tableContainer') !== undefined && document.getElementsByClassName('tableContainer')[0] !== undefined) {
            for (let i = document.getElementsByClassName('tableContainer').length - 1; i >= 0; --i) {
              document.getElementsByClassName('tableContainer')[i].scrollTop = 0;
            }
          }
          this.selectionModel.clear();
          this._pageSize = this.matPaginator.pageSize;
          // Store last query to check if some attributes have changed
          this.lastQuery = Utils.cloneDeep(this.query);

          // Get data and count from child class
          const dataAndCount: { data: T | any, count: number } = this.getDataTypedAndCountAfterLoad<T>(data);
          this.markers = this.getMarkers(dataAndCount.data);
          // Set the total count for paginator
          this.totalCount = dataAndCount.count;

          if (this.mediaQueryService.mobileQuery.matches) {
            // if mobile, we add result to previous result (infinite scroll)
            // this.tableDataSource = new MatTableDataSource(this.tableDataSource.data.concat(dataAndCount.data));
            // this.tableDataSource.data = dataAndCount.data;
            this.tableDataSource = new MatTableDataSource(dataAndCount.data);
            this.loadingInProgress = false;
          } else {
            // if desktop, we replace data by the new paginated data
            this.tableDataSource.data = dataAndCount.data;
            // this.tableDataSource = new MatTableDataSource(dataAndCount.data);
            this.loadingInProgress = false;
          }

          // if infiniteScrollEvent is define, we came from onInfiniteScroll method
          if (typeof this.infiniteScrollEvent !== 'undefined') {
            // stop infinite scroll loading indication.
            this.infiniteScrollEvent.target.complete();
            // reset var
            this.infiniteScrollEvent = undefined;
          }


          if (this.totalCount > this.page * this.pageSize) {
            // display load more button
            this.hideLoadMoreButton = false;
          } else {
            // hide load more button
            this.hideLoadMoreButton = true;
          }
        } else {
          // this.loadingInProgress = false;
          // const params: NavigationOptions = {
          //   queryParams: {
          //     searchedit: 0
          //   }
          // };
          // Utils.navigate('/app/my-searches-add', this.navController, params);
        }

      },
      error => {
        console.log(error);
      });
    // }
  }

  /**
   * Load data
   */
  protected load<T>(): Observable<any> {
    throw new Error('load METHOD NOT OVERRIDED');
  }

  public listOrMapsChanged(listOrMaps: string): void {
    this.listStyle = listOrMaps;
  }

  /**
   * Update data Source
   * @param data T
   */
  protected getDataTypedAndCountAfterLoad<T>(data: T): { data: T | any, count: number} {
    throw new Error('getDataTypedAndCountAfterLoad METHOD NOT OVERRIDED');
  }

  public refreshData(forceResetData: boolean = false): void {
    if (forceResetData) {
      this.tableDataSource.data = [];
      this.page = 1;
      this.matPaginator.pageIndex = 0;
    }
    this.init();
  }

  /**
   * Check if we must reset dataSource and page index.
   */
  protected checkIfQueryHasChanged(): void {

    let queryHasChanged: boolean = false;
    this.watchQueryProperties.forEach((property: string) => {
      if (this.query[property] !== this.lastQuery[property]) {
        queryHasChanged = true;
      }
    });
    // if data must be reseted
    if (queryHasChanged) {
      this.tableDataSource.data = [];
      this.page = 1;
      this.matPaginator.pageIndex = 0;
    }
  }

  /**
   *
   * @param event trigger by template scroll.
   */
  public onInfiniteScroll(event: any): void {

    // if desktop, we do nothing.
    if (!this.mediaQueryService.mobileQuery.matches) { return; }

    // store event to use it in load() method
    this.infiniteScrollEvent = event;

    if (this.totalCount > this.page * this.pageSize) {
      // increment page number
      // this.page = this.page + 1;
      // trigger matPaginator change.
      this.matPaginator._changePageSize(25 + this.pageSize);
      // this.matPaginator.nextPage();
    }
  }

  public ngOnDestroy(): void {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // from ToggleListStyleComponent
  public listStyleChanged(listStyle: string): void {
    this.listStyle = listStyle;
  }

  /**
   * Tempalte action for load more content. Just trigger onInfiniteScroll
   */
  public loadMoreContentButton(): void {
    this.onInfiniteScroll(undefined);
  }

  public mouseOverArticle(article: ArticleInterface) {

    const self = this;
    this.markers.forEach(function (value) {
      if (value['data'].id === article.id) {
        value.markerOption.icon = self.bigIcon;
        // value.animation = 'BOUNCE';
      } else {
        value.markerOption.icon = self.markerIcon;
        // value.animation = null;
      }
    });
  }

  public mouseOutArticle(article: ArticleInterface) {

    const self = this;
    this.markers.forEach(function (value) {
      value.markerOption.icon = self.markerIcon;
      // value.animation = null;
    });
  }

  public getClusterStyle(markers, count: number) {
    return {
      text: markers.length,
      index: 3
    };
  }

  /**
   * get marker from articles list.
   * @param data Array<ArticleInterface>
   */
  public getMarkers(data: Array<ArticleInterface>): Array<MarkerInterface> {
    const markers: Array<MarkerInterface> = [];
    if (data != null) {
      data.forEach((article: ArticleInterface) => {
        let latitude: number;
        let longitude: number;
        if (article.entreprises !== undefined && article.entreprises.length > 0) {
          article.entreprises.forEach(value => {
            if (longitude !== undefined && latitude !== undefined) {
              return;
            }
            if (value.longitude !== undefined && value.latitude !== undefined) {
              latitude = value.latitude;
              longitude = value.longitude;
            }
          });
        }
        if (longitude !== undefined && latitude !== undefined) {
          // check for doubloons and slightly move them if needed
          markers.forEach(value => {
            if (value.markerOption.position.lat === latitude && value.markerOption.position.lng === longitude) {
              const a: number = 360.0 / markers.length;
              latitude = value.markerOption.position.lat + -.00004 * Math.cos((+a * markers.length) / 180 * Math.PI);
              longitude = value.markerOption.position.lng + -.00004 * Math.cos((+a * markers.length) / 180 * Math.PI);
            }
          });

          let pushMarker = false;
          if (this.selectionModel.isEmpty() || this.selectionModel.isSelected(article.id)) {
            pushMarker = true;
          }
          if (pushMarker) {
            markers.push({
              data: article,
              markerOption: {
                position: {
                  lat: latitude,
                  lng: longitude
                },
                draggable: false,
                icon: this.markerIcon
              }
            });
          }
        }
      });

      if (markers.length > 1) {
        this.findMapCenter(markers);
      } else if (markers.length === 1) {
        this.mapCenter.lat = (markers[0].markerOption.position.lat as number);
        this.mapCenter.lng = (markers[0].markerOption.position.lng as number);
      }
    }
    return markers;
  }

  firstCenterCalc = true;

  public findMapCenter(data: Array<MarkerInterface>) {
    if (!this.firstCenterCalc) {
      return;
    }
    this.firstCenterCalc = false;
    if (!(data.length > 0)) {
      return false;
    }

    const num_coords: number = data.length;

    let X: number = 0.0;
    let Y: number = 0.0;
    let Z: number = 0.0;

    let maxLat: number;
    let maxLong: number;
    let minLat: number;
    let minLong: number;

    data.forEach(value => {
      const lati = (value.markerOption.position.lat as number) * Math.PI / 180;
      const long = (value.markerOption.position.lng as number) * Math.PI / 180;

      const a = Math.cos(lati) * Math.cos(long);
      const b = Math.cos(lati) * Math.sin(long);
      const c = Math.sin(lati);

      X += a;
      Y += b;
      Z += c;

      // for zoom
      if (maxLat === undefined || maxLat < (value.markerOption.position.lat as number)) {
        maxLat = (value.markerOption.position.lat as number);
      }
      if (maxLong === undefined || maxLong < (value.markerOption.position.lng as number)) {
        maxLong = (value.markerOption.position.lng as number);
      }
      if (minLat === undefined || minLat > (value.markerOption.position.lat as number)) {
        minLat = (value.markerOption.position.lat as number);
      }
      if (minLong === undefined || minLong > (value.markerOption.position.lng as number)) {
        minLong = (value.markerOption.position.lng as number);
      }
    });

    X /= num_coords;
    Y /= num_coords;
    Z /= num_coords;

    const lon = Math.atan2(Y, X);
    const hyp = Math.sqrt(X * X + Y * Y);
    const lat = Math.atan2(Z, hyp);

    this.mapCenter.lat = (lat * 180 / Math.PI);
    this.mapCenter.lng = (lon * 180 / Math.PI);

    let mapWidth: number;
    let mapHeight: number;

    if (this.mediaQueryService.mobileQuery.matches) {
      mapWidth = window.innerWidth;
      mapHeight = window.innerHeight - (60 + 56 + 112);
    } else {
      mapWidth = window.innerWidth - 260 - (60 * (window.innerWidth - 260) / 100);
      mapHeight = window.innerHeight - (60 + 56 + 112);
    }

    this.zoom = this.getBoundsZoomLevel({maxLat: maxLat, minLat: minLat, maxLong: maxLong, minLong: minLong}, {width: mapWidth, height: mapHeight});
    // if window.innerWidth;
  }

  getBoundsZoomLevel(bounds, mapDim) {
    const WORLD_DIM = { height: 256, width: 256 };
    const ZOOM_MAX = 21;

    function latRad(lat) {
      const sin = Math.sin(lat * Math.PI / 180);
      const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx, worldPx, fraction) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    const latFraction = (latRad(bounds.maxLat) - latRad(bounds.minLat)) / Math.PI;

    const lngDiff = bounds.maxLong - bounds.minLong;
    const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  }

  public initGeolocation(): void {
    // const geolocationOptions: GeolocationOptions = {
    //   maximumAge: 3000, timeout: 5000, enableHighAccuracy: true
    // };
    // this.geolocation.getCurrentPosition(geolocationOptions)
    //     .then((geoposition: Geoposition) => {
    //       // set lat lng for the map.
    //       this.lat = geoposition.coords.latitude;
    //       this.lng = geoposition.coords.longitude;
    //     }).catch((error: any) => {
    //   // set default lat lng for this map.
    //   this.lat = 47.3167;
    //   this.lng = 5.0167;
    //   // if (error.code === 1) {
    //   //   // permission denied
    //   //   this.toastService.simple(this.translateService.instant('GEOLOCATION_PERMISSION_DENIED'), { color: 'toasterror', duration: 3000 });
    //   // } else if (error.code === 2) {
    //   //   // Position unavailable
    //   //   this.toastService.simple(this.translateService.instant('GEOLOCATION_POSITION_UNAVAILABLE'), { color: 'toasterror', duration: 3000 });
    //   // } else if (error.code === 3) {
    //   //   // Timeout
    //   //   this.toastService.simple(this.translateService.instant('GEOLOCATION_TIMEOUT'), { color: 'toasterror', duration: 3000 });
    //   // }
    // });
  }

  /**
   * Open article from Maps Info Window
   * @param article ArticleInterface
   */
  public async openReadingModalFromMarker(article: ArticleInterface): Promise<void> {
    const modal = await this.modalController.create({
      component: ReadingArticleModal,
      cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog',
      componentProps: {
        article: article,
        from: 'article' // we came from articles. Not favorites.
      }
    });

    return await modal.present();
  }

  public mapClick() {
    if (this.previousIW) {
      this.previousIW.close();
    }
  }

  // public markerClick(infoWindow: AgmInfoWindow) {
  //   if (this.previousIW) {
  //     this.currentIW = infoWindow;
  //     this.previousIW.close();
  //   }
  //   this.previousIW = infoWindow;
  // }

  checkAllArticles($event: MatCheckboxChange) {
    // $event.stopPropagation();
    if (this.selectionModel.selected.length === this.tableDataSource.data.length) {
      for (const article of this.tableDataSource.data) {
        this.selectionModel.deselect(article.id);
      }
    } else {
      for (const article of this.tableDataSource.data) {
        this.selectionModel.select(article.id);
      }
    }
  }

  allArticlesChecked() {
    try {
      if (this.previousData && this.previousData !== undefined && this.previousData.articles !== undefined) {
        if (this.previousData.articlesCount <= 0) {
          return false;
        }
      }


      if (this.previousData && this.previousData !== undefined && this.previousData.articles !== undefined) {
        for (const article of this.previousData.articles) {
          if (!this.selectionModel.isSelected(article)) {
            return false;
          }
        }
      } else if (this.previousData && this.previousData !== undefined && this.previousData.articles_ !== undefined) {
        for (const article of this.previousData.articles_) {
          if (!this.selectionModel.isSelected(article.id)) {
            return false;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
    return true;
  }

}


