<div id="feFinancialsGraphModal" class="feFinancialsGraphModal feModal">
    <div class="feModalHeader">
        <span class="feModalTitle">Statistiques financières de {{entreprise.name}}</span>
        <div class="feModalCloseButton">
            <span mat-dialog-close>Fermer</span>
            <mat-icon mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="feModalContent">
        <div *ngIf="dataSales || dataProfits || dataEff"
             style="display: flex;flex-direction: row; height: 38px;">
            <div  style="font-size: 18px; color: #254354;text-align: center; flex:1; margin-top: 15px" *ngIf="dataSales">
                Chiffre d'affaires
            </div>

            <div  style="font-size: 18px; color: #254354;text-align: center; flex:1; margin-top: 15px" *ngIf="dataProfits">
                Résultat net
            </div>

            <div  style="font-size: 18px; color: #254354;text-align: center; flex:1; margin-top: 15px" *ngIf="dataEff">
                Effectifs
            </div>
        </div>
        <div style="display: flex;flex-direction: row;">
            <ngx-charts-bar-vertical *ngIf="dataSales"
                                     [view]="view"
                                     [scheme]="colorScheme"
                                     [results]="dataSales"
                                     [gradient]="gradient"
                                     [xAxis]="showXAxis"
                                     [yAxis]="showYAxis"
                                     [legend]="showLegend"
                                     [showXAxisLabel]="showXAxisLabel"
                                     [showYAxisLabel]="showYAxisLabel"
                                     [xAxisLabel]="xAxisLabel"
                                     [yAxisLabel]="yAxisLabel">
            </ngx-charts-bar-vertical>

            <ngx-charts-bar-vertical *ngIf="dataProfits"
                                     [view]="view"
                                     [scheme]="colorScheme"
                                     [results]="dataProfits"
                                     [gradient]="gradient"
                                     [xAxis]="showXAxis"
                                     [yAxis]="showYAxis"
                                     [legend]="showLegend"
                                     [showXAxisLabel]="showXAxisLabel"
                                     [showYAxisLabel]="showYAxisLabel"
                                     [xAxisLabel]="xAxisLabel"
                                     [yAxisLabel]="yAxisLabel">
            </ngx-charts-bar-vertical>

            <ngx-charts-bar-vertical *ngIf="dataEff"
                                     [view]="view"
                                     [scheme]="colorScheme"
                                     [results]="dataEff"
                                     [gradient]="gradient"
                                     [xAxis]="showXAxis"
                                     [yAxis]="showYAxis"
                                     [legend]="showLegend"
                                     [showXAxisLabel]="showXAxisLabel"
                                     [showYAxisLabel]="showYAxisLabel"
                                     [xAxisLabel]="xAxisLabel"
                                     [yAxisLabel]="yAxisLabel">
            </ngx-charts-bar-vertical>

            <div *ngIf="!dataSales && !dataProfits && !dataEff" class="noFinancialsData">
                Aucune donnée financière n’est disponible pour cette entreprise
            </div>
        </div>
    </div>
</div>
