import { Component } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { AuthService } from './services/auth/auth.service';
import { PushService } from './services/push/push.service';
import { DeeplinkService } from './services/deeplink/deeplink.service';
import {VersionCheckService} from './services/version-check.service';
import {NavigationOptions} from '@ionic/angular/common/providers/nav-controller';
import {ActivatedRoute} from '@angular/router';
import {AuthGuard} from './guard/auth/auth.guard';
import {ApiService} from './services/api/api.service';
import {ToastService} from './services/toast/toast.service';
import {Utils} from './classes/Utils';
import {AppPreferencesService} from './services/app-preferences/app-preferences.service';


const DEFAULT_LOCALE: string = 'fr-FR';

declare var cordova: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform
    , private splashScreen: SplashScreen
    , private statusBar: StatusBar
    , private authService: AuthService
    , private navController: NavController
    , private pushService: PushService
    , private deeplinkService: DeeplinkService
    , private versionCheckService: VersionCheckService
    , private route: ActivatedRoute
    , private authGuard: AuthGuard
    , private apiService: ApiService
    , private toastService: ToastService
    , private appPreferencesService: AppPreferencesService
  ) {
    this.initializeApp();
  }

  initializeApp() {

      try {
          // throw new Error();
          this.platform.ready().then(() => {

              if (window.location.href.includes('/entreprise') || window.location.href.includes('/external-article')) {

                  return;
              }

              const params = Utils.toParamMap(window.location.href.substring(window.location.href.indexOf('?')));
              // console.log(params);
              const navOptions: NavigationOptions = {
                  queryParams: params,
              };

              let token = Utils.getParameterByName('token');
              const roleCom = Utils.getParameterByName('role');
              if (roleCom === 'com') {
                  this.authService.roleCom = true;
              }

              if (token === undefined) {
                  token = Utils.userToken;
              }

              if (!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
                  this.branchInit();
              }

              const ua = window.navigator.userAgent;

              if (this.platform.is('mobileweb') && ua.toLowerCase().includes('mobile')) {
                  params['oldUrl'] = window.location.href;
                  this.navController.navigateRoot('choice', navOptions);
              } else {
                  if (token !== undefined) {
                      params['token'] = token;
                      this.authGuard.checkAuth$(params);
                  } else {
                      this.navController.navigateRoot('login', navOptions);
                  }

                  this.statusBar.styleDefault();
                  this.splashScreen.hide();

                  // OAuth init
                  // this.authService.init();
              }

          });

          // remove android button back actions.
          this.platform.backButton.observers.pop();

          this.platform.resume.subscribe(() => {

              // this.pushService.resetBadge();

              // TODO: sync data ?

              this.branchInit();
          });

          this.versionCheckService.initVersionCheck();
      } catch (e) {
          this.navController.navigateRoot('error');
      }
  }

    public branchInit(): void {
    // only on devices
    // alert('init branch ' + this.platform.platforms());
    if (this.platform.is('desktop') || this.platform.is('mobileweb')) { return; }
    // alert('!iscordova');
    // this.branchIo.initSession()
    //   .then(
    //     (data: BranchIoPromise) => {
    //         if (data['+non_branch_link']) {
    //             const params =  data['+non_branch_link'].replace('firsteco://', '');
    //             Utils.setParamsFromBranch(params);
    //         }
    //       // alert('branch ' + JSON.stringify(data));
    //       if (data['+clicked_branch_link']) {
    //         // this.deeplinkService.redirect(data['+clicked_branch_link']);
    //       }
    //     }
    //   )
    //   .catch((error: Error) => {console.log(error); } );
  }

}
