import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'fe-toggle',
  templateUrl: './fe-toggle.component.html',
  styleUrls: ['./fe-toggle.component.scss']
})
export class FeToggleComponent {
  @Input() text: string = '';
  @Input() textDirection: string = 'RIGHT';
  @Input() reverseValue: boolean = false;
  @Input() littleMode: boolean = false;
  protected inputValue: boolean = false;

  @Input()
  get value() {
    if (this.reverseValue) {
      return !this.inputValue;
    } else {
      return this.inputValue;
    }
  }

  set value(val: boolean) {
    if (this.reverseValue) {
      this.inputValue = !val;
      this.valueChange.emit(!this.inputValue);
    } else {
      this.inputValue = val;
      this.valueChange.emit(this.inputValue);
    }
  }

  @Output()
  valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }
}
