<div class="searchListContainer" *ngIf="!isSmallMode">
  <div class="searchListContainerHeader">
    <div class="icon icon-first-eco-arrow lightBlackColor" style="margin-left: -4px;"></div>
    <span style="margin-right: auto;">Mes recherches</span>
    <fe-button color="PRIMARY" hideText="true" class="marginS10 manageSearchBtnTour" icon="true" type="TERTIARY"
               [iconType]="IconType.options" (click)="showGestionRecherchesModal()" matTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="Gérer mes recherches"/>
    <fe-button color="PRIMARY" hideText="true" icon="true" type="TERTIARY" [iconType]="IconType.add" class="createSearchBtnTour"
               matTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="Créer une nouvelle recherche" (click)="createNewSearch()"/>
  </div>
  <fe-searchbar #feSearchBarBusiness (click)="$event.stopPropagation()" (valueChange)="filterChange($event)"
                style="width: 100%;"/>
  <div class="searchListItems">
    <search-list-item *ngFor="let search of _searchList; let i = index" [(value)]="_searchList[i]"
                      [selected]="_searchList[i].searchId == currentSearch?.searchId" [index]="i"
                      [searchList]="_searchList" [class.displayNone]="hideSearch(search.data.label)"
                      (searchSelected)="setCurrentSearch($event)"></search-list-item>
    <fe-loader *ngIf="!_searchList" class="feLoader" loaderText="Chargement en cours..."/>
  </div>
</div>
<div *ngIf="isSmallMode" class="small-searchListContainer">
  <fe-button color="PRIMARY" hideText="true" class="marginS10" icon="true" type="TERTIARY" bigIcon="true"
             [iconType]="IconType.options" (click)="showGestionRecherchesModal()" matTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="Gérer mes recherches"/>
  <fe-button color="PRIMARY" hideText="true" icon="true" type="TERTIARY" [iconType]="IconType.add"
             tmatTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="Créer une nouvelle recherche" bigIcon="true" (click)="createNewSearch()"/>
</div>
