<h1 mat-dialog-title>Aide Recherche Avancée</h1>
<div mat-dialog-content>
  Pour vous aider à gagner en pertinence, vous avez à votre disposition plusieurs champs de recherche :
  <ul>
    <li>
      <b>Un champ de recherche d'expression exacte :</b> pour des recherches incluant les
      mots
      vides <code style="background-color: #F0FFFF;">le, la, de, du, ce ...etc</code>. <br>
      <b>Exemple : </b> <code style="background-color: #F0FFFF;">permis de construire</code> recherchera de façon stricte <code
            style="background-color: #F0FFFF;">permis de contruire</code> et non <code
            style="background-color: #F0FFFF;">permis construire</code>
    </li>
    <li>
      <b>Un champ de recherche ET :</b> pour des recherches qui retournent des articles ou
      des appels d'offres contenant tous les mots renseignés.
    </li>
    <li>
      <b>Un champ de recherche OU inclusif :</b> pour des recherches qui retournent des
      articles ou
      des appels d'offres contenant l'un des mots renseignés.
    </li>
    <li>
      <b>Un champ de recherche d'exclusion de mots :</b> pour des recherches qui ôtent
      les mots renseignés.
    </li>
  </ul>
</div>

