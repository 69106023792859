// Angular import
import {NgModule, LOCALE_ID} from '@angular/core';
import {registerLocaleData, DatePipe, LocationStrategy, HashLocationStrategy, PathLocationStrategy} from '@angular/common';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeFr from '@angular/common/locales/fr';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClient} from '@angular/common/http';


// Ionic Native
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@awesome-cordova-plugins/splash-screen/ngx';
import {StatusBar} from '@awesome-cordova-plugins/status-bar/ngx';
import {IonicStorageModule} from '@ionic/storage-angular';
import {Device} from '@awesome-cordova-plugins/device/ngx';
import {Geolocation} from '@awesome-cordova-plugins/geolocation/ngx';
import {Printer} from '@awesome-cordova-plugins/printer/ngx';
import {FileOpener} from '@awesome-cordova-plugins/file-opener/ngx';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';


// Other Module import
import {NgProgressModule} from 'ngx-progressbar';
import {OAuthModule} from 'angular-oauth2-oidc';

// App Modules
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AppSharedModule} from './app-shared.module';
import {ClientTokenNotFoundComponent} from './components/client-token-not-found/client-token-not-found.component';


// App Services
import {CustomHttpInterceptorService} from './services/custom-http-interceptor/custom-http-interceptor.service';
import {AuthService} from './services/auth/auth.service';
import {AppPreferencesService} from './services/app-preferences/app-preferences.service';
import {MediaQueryService} from './services/media-query/media-query.service';
import {UserService} from './services/user/user.service';
import {FavoritesService} from './services/favorites/favorites.service';
import {AddressesService} from './services/addresses/addresses.service';
import {MailsService} from './services/mails/mails.service';
import {ArticlesService} from './services/articles/articles.service';
import {ToastService} from './services/toast/toast.service';
import {DateArticleService} from './services/date-article/date-article.service';
import {DeeplinkService} from './services/deeplink/deeplink.service';
import {SearchesService} from './services/searches/searches.service';
import {HelpService} from './services/help/help.service';

import {CommonModule} from '@angular/common';
import {VersionCheckService} from './services/version-check.service';
import {ArticleDeleteFavButtonComponent} from './components/article/article-delete-fav-button/article-delete-fav-button.component';
// import {SelectAutocompleteModule} from 'mat-select-autocomplete';
import {AddFavoriteFolderModalComponent} from './components/modals/add-favorite-folder-modal/add-favorite-folder-modal.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {Drivers} from '@ionic/storage';

// register Local for Angular
registerLocaleData(localeFr, localeFrExtra);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        CommonModule,
        HttpClientModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot({
            name: 'firstEcoSql',
            driverOrder: [Drivers.LocalStorage]
        }),
        AppRoutingModule,
        BrowserAnimationsModule,
        OAuthModule.forRoot(),
        NgProgressModule,
        AppSharedModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatInputModule,
        DragDropModule,
        AngularEditorModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptorService, multi: true },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        AuthService,
        AppPreferencesService,
        MediaQueryService,
        UserService,
        FavoritesService,
        AddressesService,
        MailsService,
        ArticlesService,
        DateArticleService,
        ToastService,
        DeeplinkService,
        SearchesService,
        HelpService,
        VersionCheckService,
        Device,
        Geolocation,
        Printer,
        FileOpener,
        DatePipe
    ],
    exports: [
        AppComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
