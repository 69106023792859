import { Component } from '@angular/core';

@Component({
  selector: 'app-fe-leads',
  templateUrl: './fe-leads.component.html',
  styleUrls: ['./fe-leads.component.css']
})
export class FeLeadsComponent {

}
