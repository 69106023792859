import {Component, EventEmitter, Inject, Output, ViewChild} from '@angular/core';
import {ContactInterface} from "../../../interfaces/contact/contact.interface";
import {ModalService} from "../../../services/modal/modal.service";
import {FeCarnetContactModalComponent} from "./fe-carnet-contact-modal/fe-carnet-contact-modal.component";
import {AddressesService} from "../../../services/addresses/addresses.service";
import {combineLatest, Observable, Subject} from "rxjs";
import {AddressesInterface} from "../../../interfaces/addresses/addresses";
import {takeUntil} from "rxjs/operators";
import {SnackbarService} from "../../../services/snackbar/snackbar.service";
import {AddressInterface} from "../../../interfaces/address/address";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ErrorStatus} from "../../../classes/ErrorStatus.class";
import {forEach} from "@angular-devkit/schematics";
import {IconType} from "../../parts/fe-icon/fe-icon.component";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {PeriodicElement} from "../all-contacts/all-contacts.modal";
import {MatSort} from "@angular/material/sort";

export interface ContactLineInterface {
  id: number;
  checked: boolean;
  label?: string;
  email?: string;
  recentlyAdded?: boolean;
}

@Component({
  selector: 'fe-carnet-adresses-modal',
  templateUrl: './fe-carnet-adresses-modal.component.html',
  styleUrls: ['./fe-carnet-adresses-modal.component.scss']
})
export class FeCarnetAdressesModalComponent {
  protected menuIconActions: string = IconType.arrow_down;
  protected contactsLines: ContactLineInterface[] = [];
  private menuOpenActions: boolean = false;
  protected allContactSelected: boolean = false;
  protected addresses$: Observable<AddressesInterface>;
  protected fromMailModal: boolean = false;
  protected unsubscribe$: Subject<any> = new Subject<any>();
  protected loading: boolean = false;
  protected displayedColumns: string[] = ['select', 'label', 'email', 'options'];
  protected dataSource = new MatTableDataSource<ContactLineInterface>(this.contactsLines);
  protected selection: SelectionModel<ContactLineInterface> = new SelectionModel<ContactLineInterface>(true, []);

  @Output() confirm = new EventEmitter<ContactLineInterface[]>();
  private filter: string;
  @ViewChild('contactLinesSort') sort: MatSort;
  constructor(private _modalService: ModalService,
              private addressesService: AddressesService,
              private snackbarService: SnackbarService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<FeCarnetAdressesModalComponent>) {
    this.loading = true;
    this.addresses$ = addressesService.getAddresses();
    this.addresses$.subscribe(
        (res: AddressesInterface) => {
          this.contactsLines = [];
          res.address.forEach(value => {
            this.contactsLines.push({
              id: value.addressId,
              checked: false,
              label: value.data.label,
              email: value.data.email
            });
          });
          this.loading = false;
          this.resetDataSource();
        });
    if (data != null && data.fromMailModal != null) {
      this.fromMailModal = data.fromMailModal;
    }
  }

  private resetDataSource(): void {
    this.dataSource = new MatTableDataSource<ContactLineInterface>(this.contactsLines);
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = function (line: ContactLineInterface, filter: string) {
      if (!filter || filter.length <= 0) {
        return true;
      }
      return line.email.toLowerCase().includes(filter.toLowerCase())
          || line.label.toLowerCase().includes(filter.toLowerCase());
    };
  }

  public ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  protected onDropDownMenuOpenActions($event): void {
    $event.stopPropagation();
    this.menuOpenActions = !this.menuOpenActions;
    if (this.menuOpenActions) {
      this.menuIconActions = IconType.arrow_up;
    }
  }

  protected onMenuActionsClosed(): void {
    this.menuOpenActions = false;
    this.menuIconActions = IconType.arrow_down;
  }

  protected numberOfSelectedContacts(): number {
    let selectedContacts = 0;
    this.contactsLines.forEach(t =>  {
      if (t.checked) {
        selectedContacts ++;
      }
    });
    return selectedContacts;
  }

  protected getSelectedContacts(): ContactLineInterface[] {
    let contactsSelected: ContactLineInterface[] = [];
    this.contactsLines.forEach(t =>  {
      if (t.checked) {
        contactsSelected.push(t);
      }
    });
    return contactsSelected;
  }

  protected updateAllComplete(): void {
    this.allContactSelected = this.contactsLines.every(t => t.checked);
  }

  protected checkAllContacts(checked: boolean): void {
    this.allContactSelected = checked;
    this.contactsLines.forEach(t => (t.checked = checked));
  }

  filterChange($event: string) {
    this.filter = $event;
    this.dataSource.filter = this.filter;
  }

  protected someContactsChecked(): boolean {
    return this.contactsLines.filter(t => t.checked).length > 0 && !this.allContactSelected;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows && numSelected > 0;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: ContactLineInterface): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  protected openContactModal(createMode: boolean, contactLine: ContactLineInterface): void {
    this._modalService.showCustomModalWithActionOnClose(FeCarnetContactModalComponent, {
      data : {
        createMode: createMode,
        contactLine: contactLine,
      }
    }, (response) => {
      if (response != null) {
        let contactName: string = response.label.trim();
        if (contactName.length > 0) {
          contactName = '<b>' + contactName.trim() + "</b> (" + response.email + ')';
        } else {
          contactName = '<b>' + response.email + '</b>';
        }
        if (createMode) {
          this.addressesService.addAddress(response).subscribe(value => {
            // this.contactsLines.unshift({id: value.body.addressId, checked: false,
            //   label: value.body.data.label, email: value.body.data.email, recentlyAdded: true});
            this.resetDataSource();
            this.snackbarService.showSuccessSnackbar('Création effectuée avec succès',
                'Le contact ' + contactName + ' a été créé avec succès.', 5);
            this.addressesService.refreshAddresses();
          });
        } else {
          let addressToUpdate: any = {
            label: response.label,
            email: response.email
          }
          this.addressesService.updateAddress(contactLine.id, addressToUpdate).subscribe(value => {
            contactLine.label = response.label;
            contactLine.email = response.email;
            this.snackbarService.showSuccessSnackbar('Modification effectuée avec succès',
                'Le contact ' + contactName + ' a été modifié avec succès.', 5);
            this.addressesService.refreshAddresses();
          });
        }

      }
    });
  }

  protected deleteContact(contact: ContactLineInterface): void {
    this._modalService.confirmModal('Suppression d\'un contact', 'Attention, vous allez supprimer le contact <b>'
        + contact.label + '</b> (' + contact.email + ') ?<br/> Voulez-vous continuer ?',
        (args): void => {
          this.addressesService.removeAddress(contact.id).subscribe(value => {
            this.contactsLines.splice(this.contactsLines.map(e => e.id)
                .indexOf(contact.id), 1);
            this.resetDataSource();
            this.snackbarService.showSuccessSnackbar('Suppression effectuée avec succès',
                'Le contact <b>' + contact.label
                + '</b> (' + contact.email + ') a été supprimé avec succès.', 5);
            this.addressesService.refreshAddresses();
          });
    });
  }

  protected deleteSelectedContacts(): void {
    this._modalService.confirmModal('Suppression de contacts',
        'Attention, vous allez supprimer les contacts sélectionnés ?<br/> Voulez-vous continuer ?',
        (args): void => {

      let idsToRemove: Array<number> = [];

      this.selection.selected.forEach(value => {
        idsToRemove.push(value.id);
      });

      const arrayAddressesToDelete: Array<Observable<any>> = idsToRemove.map((id: number) => {
        return this.addressesService.removeAddress(id);
      });

      combineLatest(arrayAddressesToDelete).pipe(
          takeUntil(this.unsubscribe$)
      ).subscribe(
          () => {
            this.snackbarService.showSuccessSnackbar('Opération réussie',
                'Les adresses sélectionnées ont bien été supprimées.', 5);
            idsToRemove.forEach(value => {
              this.contactsLines.splice(this.contactsLines.map(e => e.id)
                  .indexOf(value), 1);
              this.addressesService.refreshAddresses();
            });
            this.resetDataSource();
          },
          (error: Error | ErrorStatus) => {
            this.snackbarService.showDangerSnackbar('Opération impossible',
                'Les adresses sélectionnées n\'ont pas pu être supprimées.', 5);
          }
      );
    });
  }

  protected addContactsToMail(): void {
    if (this.selection.selected.length > 0) {
      this.confirm.emit(this.selection.selected);
      this.dialogRef.close();
    } else {
      this.snackbarService.showDangerSnackbar('Opération impossible',
          'Vous devez sélectionner au moins un contact.', 5);
    }
  }

  protected getContactsLabel(): string {
    if (this.numberOfSelectedContacts() > 1) {
      return 'contacts sélectionnés';
    } else {
      return 'contact sélectionné';
    }
  }

    protected readonly IconType = IconType;
}
