import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MediaQueryService } from 'src/app/services/media-query/media-query.service';
import { UserService } from 'src/app/services/user/user.service';
import { SessionInterface } from 'src/app/interfaces/session/session.interface';
import {AuthService} from '../../services/auth/auth.service';
import {Utils} from '../../classes/Utils';

@Component({
  selector: 'user-inset',
  templateUrl: 'user-inset.component.html',
  styleUrls: ['user-inset.component.scss'],
})
export class UserInsetComponent implements OnInit, OnDestroy {

  private unsubscribe$: Subject<any> = new Subject<any>();

  @Input() public sessionData: SessionInterface;
 public userToken: string = '';
 public sessionToken: string = '';
    mobileQuery: MediaQueryList;

    fillerNav = Array.from({length: 50}, (_, i) => `Nav Item ${i + 1}`);

    fillerContent = Array.from(
        {length: 50},
        () =>
            `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
       labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
       laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
       voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
       cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    );
/*   public userData: UserInterface;
 */
  constructor(
    public mediaQueryService: MediaQueryService
    , private userService: UserService
    , public authService: AuthService
  ) {}

  public ngOnInit(): void {
/* TO REMOVE IF NOT USED (REPLACED BY SESSION ?)
    this.userService.userData$.pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe((userData: UserInterface) => {
      this.userData = userData;
    }); */

    this.userService.getSessionDatas().pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe((sessionData: SessionInterface) => {
      if (sessionData !== undefined) {
        this.sessionData = sessionData;
      }
    });
    this.userToken = Utils.userToken;
  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
