import {Component, Inject, OnInit} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {SearchInterface} from '../../../../interfaces/search/search.interface';
import {SearchesService} from '../../../../services/searches/searches.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-search-summary',
  templateUrl: './search-summary.component.html',
  styleUrls: ['./search-summary.component.scss'],
})
export class SearchSummaryComponent implements OnInit {
  public search: SearchInterface;

  constructor(
      private modalController: ModalController
      , @Inject(MAT_DIALOG_DATA) public data: any
      , private dialogRef: MatDialogRef<SearchSummaryComponent>
      , public searchesService: SearchesService) { }

  ngOnInit() {
    this.search = this.data.search;
    if (this.search.data.libelles !== undefined && this.search.data.libelles.keywords !== undefined && this.search.data.libelles.keywords.length === 0) {
      this.search.data.libelles.keywords = undefined;
    }
  }


  public close(): void {
    this.dialogRef.close();
  }

  public getCriterionTypeLabel(key: string) {
    switch (key) {
      case 'sectors':
        return 'Secteurs d\'activités';
      case 'topics':
        return 'Evénements économiques';
      case 'companySizes':
        return 'Tailles d\'entreprises';
      case 'activityTypes':
        return 'Natures des locaux';
      case 'locations':
        return 'Localisations';
      case 'keywords_must':
        return 'Tous les mots suivants';
      case 'keywords_must_exact':
        return 'Tous les mots suivants';
      case 'keywords_should':
        return 'Un des mots suivant';
      case 'keywords_must_not':
        return 'Aucun des mots suivants';
    }

    return 'Mots clés';
  }

  public getLibellesKeys(): Array<string> {
    const res = Object.keys(this.search.data.libelles);
    if (this.search.data.libelles.keywords === undefined || this.search.data.libelles.keywords.length === 0) {
      res.splice(res.indexOf('keywords'), 1);
    }
    if (this.search.data.libelles.keywords_must === undefined || this.search.data.libelles.keywords_must.length === 0) {
      res.splice(res.indexOf('keywords_must'), 1);
    }
    if (this.search.data.libelles.keywords_must_exact === undefined || this.search.data.libelles.keywords_must_exact.length === 0) {
      res.splice(res.indexOf('keywords_must_exact'), 1);
    }
    if (this.search.data.libelles.keywords_must_not === undefined || this.search.data.libelles.keywords_must_not.length === 0) {
      res.splice(res.indexOf('keywords_must_not'), 1);
    }
    if (this.search.data.libelles.keywords_should === undefined || this.search.data.libelles.keywords_should.length === 0) {
      res.splice(res.indexOf('keywords_should'), 1);
    }
    return res;
  }
}
