import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { ArticlesService } from 'src/app/services/articles/articles.service';
import { takeUntil } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { Platform } from '@ionic/angular';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import {SessionInterface} from '../../../interfaces/session/session.interface';
import {UserService} from '../../../services/user/user.service';


@Component({
  selector: '<article-export-button',
  templateUrl: 'article-export-button.component.html',
  styleUrls: ['article-export-button.component.scss'],
})
export class ArticleExportButtonComponent implements OnInit, OnDestroy {

  @Input() public selectionModel: SelectionModel<string>;
  public _disabled: boolean;
  @Input() set disabled(disabled) {
      this._disabled = disabled;
  }
  get disabled(): boolean {
      return this._disabled;
  }

  private unsubscribe$: Subject<any> = new Subject<any>();

  private file = new File();

  public warnMessage: string;

  constructor(
    private articlesService: ArticlesService
    , private platform: Platform
    , private fileOpener: FileOpener
    , private userService: UserService
  ) {}

  public ngOnInit(): void {
      this.userService.getSessionDatas().pipe(
          takeUntil(this.unsubscribe$)
      )
          .subscribe(
              (sessionData: SessionInterface) => {
                  if (sessionData !== undefined) {
                      sessionData.appConfig.viewConfigs.forEach(value => {
                          if (value.component === 'EXPORT_CONTACT') {
                              if (value.state === 'limited') {
                                  this.warnMessage = value.message;
                              }
                          }
                      });
                  }
              }
          );
  }

  public exportArticlesList(format: string, selectionModel: any) {
    this.articlesService.exportArticlesList(format, selectionModel)
    .pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe(
      response => {
        // TODO : Récupérer dynamiquement le nom du fichier (Content-Disposition introuvable dans le header de la response)
        let fileName = 'FirstECO_export_contacts';

        // TODO NE FONCTIONNE PAS MAIS PISTE DE SOLUTION
        const contentDisposition = response.headers.get('Content-Disposition');
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);

          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        } else {
            if (format === 'excel') {
                fileName += '.xlsx';
            }
            else {
                fileName += '.' + format;
            }
        }

        const fileContent = response.body;

        // Save as for mobile version
        if (this.platform.is('ios') || this.platform.is('android')) {

          this.file.writeFile(this.file.dataDirectory, fileName, fileContent)
          .then(
            _ => {

              this.fileOpener.showOpenWithDialog(this.file.dataDirectory + fileName, 'text/csv')
                .then(() => console.log('File is opened'))
                .catch(e => console.log('Error opening file', e));
            }
          )
          .catch(
            err => {
              this.file.writeExistingFile(this.file.dataDirectory, fileName, fileContent)
                .then(
                  _ => {
                    this.fileOpener.showOpenWithDialog(this.file.dataDirectory + fileName, 'text/csv')
                      .then(() => console.log('File is opened'))
                      .catch(e => console.log('Error opening file', e));
                  }
                )
                .catch(
                  err => {
                    alert('Failure');
                  }
                );
            }
          );
        }
        // Save as for desktop version
        else {
          saveAs.saveAs(fileContent, fileName);
        }
      }
    );
  }


  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
