import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';


export const AUTH_CONFIG: AuthConfig = {
  tokenEndpoint: environment.oauth.tokenEndpoint,
  clientId: environment.oauth.clientId,
  scope: environment.oauth.scope,
  dummyClientSecret: environment.oauth.dummyClientSecret,

  sessionChecksEnabled: environment.oauth.sessionChecksEnabled
};
