<div class="feMonitorSupervisordAddModal feModal">
  <div class="feModalHeader">
    <span class="feModalTitle" *ngIf="!modification">Ajout d'un lead externe</span>
    <span class="feModalTitle" *ngIf="modification">Modification d'un lead externe</span>
    <div class="feModalCloseButton">
      <span mat-dialog-close>Fermer</span>
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
  </div>
  <div class="feModalContent">
      <mat-form-field appearance="outline" class="full-width" style="width: 100%">
        <mat-label style="font-weight: bold;">Titre*</mat-label>
        <input matInput [(ngModel)]="leadTitle" style="border-color: #273c4e; border-radius: 7px; border-width: 1px;" type="text"/>
      </mat-form-field>

    <mat-form-field appearance="outline" class="full-width" style="width: 100%">
      <mat-label>Contenu</mat-label>
      <textarea [(ngModel)]="leadContent" matInput  rows="8"></textarea>
    </mat-form-field>

    <form [formGroup]="informationDateForm">
      <mat-form-field appearance="outline" floatLabel="never" style="color: grey; width: 100%" >
        <mat-label>Date de l'information</mat-label>
        <input style="caret-color: white;" matInput [matDatepicker]="pickerInformationDate"
               (focus)="pickerInformationDate.open()"
               (dateChange)="updateInformationDate($event)"
               formControlName="currentInformationDate">
        <mat-datepicker-toggle matSuffix [for]="pickerInformationDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerInformationDate></mat-datepicker>
      </mat-form-field>
    </form>
  </div>
  <div class="feModalFooter">
    <fe-button text="Annuler" type="TERTIARY" color="DANGER" mat-dialog-close (click)="close()" />
    <fe-button text="Ajouter" *ngIf="!modification;" (click)="createLead(informationDateForm.get('currentInformationDate').value)" />
    <fe-button text="Modifier" *ngIf="modification;" (click)="updateLead(informationDateForm.get('currentInformationDate').value)" />
  </div>
</div>
<!--<ion-header no-border class="ionRedHeader">-->
<!--  <ion-toolbar class="ionRedHeader">-->

<!--    <h1 *ngIf="!modification" style="margin: 0 0 0 2%;font-size: 16px; font-weight: bold">Ajout d'un lead externe</h1>-->
<!--    <h1 *ngIf="modification"  style="margin: 0 0 0 2%;font-size: 16px; font-weight: bold">Modification d'un lead externe</h1>-->

<!--    <ion-buttons slot="end">-->
<!--      <button  mat-icon-button aria-label="close" (click)="close()">-->
<!--        <mat-icon>close</mat-icon>-->
<!--      </button>-->
<!--    </ion-buttons>-->
<!--  </ion-toolbar>-->
<!--</ion-header>-->

<!--<div style="text-align: center">-->
<!--  <mat-form-field appearance="outline" class="full-width" style="width: 95%">-->
<!--    <mat-label>Titre</mat-label>-->
<!--      <input matInput [(ngModel)]="leadTitle" style="border-color: #273c4e; border-radius: 7px; border-width: 1px;" type="text"/>-->
<!--  </mat-form-field>-->
<!--</div>-->

<!--<div style="text-align: center">-->
<!--  <mat-form-field appearance="outline" class="full-width" style="width: 95%">-->
<!--    <mat-label>Contenu</mat-label>-->
<!--    <textarea [(ngModel)]="leadContent" matInput  rows="8"></textarea>-->
<!--  </mat-form-field>-->
<!--</div>-->
<!-- <div style="text-align: center">-->
<!--   <form [formGroup]="informationDateForm">-->
<!--     <mat-form-field appearance="outline" floatLabel="never" style="color: grey; width: 95%" >-->
<!--       <mat-label>Date de l'information</mat-label>-->
<!--       <input style="caret-color: white;" matInput [matDatepicker]="pickerInformationDate"-->
<!--              (focus)="pickerInformationDate.open()"-->
<!--              (dateChange)="updateInformationDate($event)"-->
<!--              formControlName="currentInformationDate">-->
<!--       <mat-datepicker-toggle matSuffix [for]="pickerInformationDate"></mat-datepicker-toggle>-->
<!--       <mat-datepicker #pickerInformationDate></mat-datepicker>-->
<!--     </mat-form-field>-->
<!--   </form>-->
<!-- </div>-->
<!--<div class="feModalFooter">-->
<!--  <fe-button text="Annuler" type="TERTIARY" color="DANGER" mat-dialog-close (click)="close()" />-->
<!--  <fe-button text="Ajouter" *ngIf="!modification;" (click)="createLead(informationDateForm.get('currentInformationDate').value)" />-->
<!--  <fe-button text="Modifier" *ngIf="modification;" (click)="updateLead(informationDateForm.get('currentInformationDate').value)" />-->
<!--</div>-->



