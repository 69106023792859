import {Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {MediaQueryService} from '../../../services/media-query/media-query.service';
import {ToastService} from '../../../services/toast/toast.service';
import {FavoritesService} from '../../../services/favorites/favorites.service';
import {FavorisFolder} from '../../../interfaces/favoritesFolders/favorites-folders.interface';
import {MatRadioChange} from '@angular/material/radio';
import {ArticleInterface} from '../../../interfaces/articles/article.interface';
import {IconType} from '../../parts/fe-icon/fe-icon.component';
import {SnackbarService} from '../../../services/snackbar/snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContactLineInterface} from '../fe-carnet-adresses-modal/fe-carnet-adresses-modal.component';
import {FeInputComponent} from "../../parts/fe-input/fe-input.component";

@Component({
  selector: 'app-add-favorite-modal',
  templateUrl: './add-favorite-modal.component.html',
  styleUrls: ['./add-favorite-modal.component.scss'],
})
export class AddFavoriteModalComponent implements OnInit {

  public isCreateFolder = false;
  protected _favoriteFolders: FavorisFolder[];
  private article: ArticleInterface;
  private articles: Array<number>;
  public favoriteSelectIcon: string = 'keyboard_arrow_down';
  protected newFolderName: string = '';
  protected title: string = '';
  protected actualForlderId: number = -1;
  @Output() confirm = new EventEmitter<any>();
  @ViewChild('feInputComponent') feInputComponent: FeInputComponent;

  public set favoriteFolders(favoriteFolders: FavorisFolder[]) {
    this._favoriteFolders = favoriteFolders;
  }
  public selectedFolderLibelle: string = 'Aucun dossier sélectionné';
  public selectedFolderID;

  constructor(
      private dialogRef: MatDialogRef<AddFavoriteModalComponent>
      // , private navParams: NavParams
      , public mediaQueryService: MediaQueryService
      , @Inject(MAT_DIALOG_DATA) public data: any
      , public toastService: ToastService
      , public favoriteService: FavoritesService
      , private snackbarService: SnackbarService
  ) {
    if (data != null) {
      this.articles = data.articles;
      if (this.data.title != null) {
        this.title = 'Ajout de l’article \'' + this.troncateText(this.data.title, 40) + '\' en favoris';
      } else {
        this.title = 'Ajout des articles sélectionnés en favoris';
      }
      if (this.data.actualForlderId != null) {
        this.actualForlderId = this.data.actualForlderId;
        if (this.articles.length > 1) {
          this.title = 'Changement de dossier des articles sélectionnés';
        } else {
          this.title = 'Changement de dossier de l\'article sélectionné';
        }
      } else {
        this.actualForlderId = -1;
      }
    }
    this.favoriteService.getFavoritesFolders().subscribe((folders: Array<FavorisFolder>) => {
      this._favoriteFolders = folders;
    });
  }

  ngOnInit() {
  }
  /**
   * Close the modal
   */
  public close(isAdd ?: boolean, libelle ?: string, folderID ?:  number): void {
    const returnValue = {
      isAdd: isAdd,
      libelle: libelle,
      folderID: folderID
    };
    this.confirm.emit(returnValue);
    this.dialogRef.close();
  }

  addFavorite() {
    if ((this.isCreateFolder && this.newFolderName !== '') || (!this.isCreateFolder && this.selectedFolderID !== undefined)) {
      if (this.articles) {
        this.addMultipleFavorites();
      } else {
        if (this.isCreateFolder) {
          const folder: FavorisFolder = {
            libelle : this.newFolderName
          };
          this.favoriteService.addFavoritesFolder(folder).subscribe(folderCreated => {
            this.favoriteService.addArticleInFavoritesFolder(folderCreated.body.folderID, this.article.id).subscribe(next => {
              this.article.favorite = true;
              this.article.favoriteFolder = folderCreated.body.folderID;
              this.close(true, folderCreated.body.libelle, folderCreated.body.folderID);
            });
          });
        } else {
          this.favoriteService.addArticleInFavoritesFolder(this.selectedFolderID, this.article.id).subscribe(value => {
            this.article.favorite = true;
            this.article.favoriteFolder = this.selectedFolderID.value;
            this.close(true, this.selectedFolderLibelle, this.selectedFolderID);
          });
        }
      }
    } else {
      this.snackbarService.showDangerSnackbar('Opération impossible',
          'Vous n\'avez sélectionné aucun dossier ou précisé le nom du dossier à créer');
    }
  }

  private addMultipleFavorites() {
    if (this.isCreateFolder) {
      const folder: FavorisFolder = {
        libelle : this.newFolderName
      };
      this.favoriteService.addFavoritesFolder(folder).subscribe(folderCreated => {
        this.favoriteService.addArticlesInFavoritesFolder(folderCreated.body.folderID, this.articles).subscribe(value => {
          this.articles.forEach(article => {
            // this.article.favorite = true;
            // this.article.favoriteFolder = (this.selectedFolder as unknown as MatSelect).value;
            this.close(true, folderCreated.body.libelle, folderCreated.body.folderID);
          });
        });
      });
    } else {
        this.favoriteService.addArticlesInFavoritesFolder(this.selectedFolderID, this.articles).subscribe(value => {
          this.articles.forEach(article => {
            // this.article.favorite = true;
            // this.article.favoriteFolder = (this.selectedFolder as unknown as MatSelect).value;
            this.close(true, this.selectedFolderLibelle, this.selectedFolderID);
          });
      });
    }
  }

  checkSelectFolder($event: MatRadioChange) {
      this.isCreateFolder = !$event.source.checked;
  }

  checkCreateFolder($event: MatRadioChange) {
    this.isCreateFolder = $event.source.checked;
  }

  getSelectedFolderLibelle(libelle: any, folderID: number) {
    this.selectedFolderLibelle = libelle;
    this.selectedFolderID = folderID;
  }

  private troncateText(text: string, length: number): string {
    let troncateText: string = text;
    if (troncateText.length > length + 2) {
      troncateText = troncateText.substring(0, length) + '...';
    }
    return troncateText;
  }

  protected readonly IconType = IconType;

  protected enableInput() {
    this.isCreateFolder = true;
    this.feInputComponent.setTheFocus();
  }
}
