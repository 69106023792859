<ion-header no-border>
  <ion-toolbar color="primary" class="mainToolbar greyBorder" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
    <div class="headerContainer" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
      <ion-buttons class="burgerMenu" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">      
        <button mat-icon-button class="buttonMenu" (click)="sideNav.toggle()"
          *ngIf="mediaQueryService.mobileQuery.matches">
          <mat-icon>menu</mat-icon>
        </button>
      </ion-buttons>
      <div class="logoHeader" (click)="navigateToUrl('/app/business-signals', 'back')" *ngIf="showFirstEcoLogo">
        <img src="./assets/images/small_logo.png" alt="Logo First Eco" />
      </div>

      <div class="menuButtonsContainer" >
        <ng-container *ngFor="let item of menuItems; trackBy: trackByMenuItem">
          <ng-template [ngIf]="item.available != null && !item.available">
            <button class="disabledBtn" (click)="showFunctionDisabled(item.name)" [title]="item.label">
              <span class="btnWithMenu bigTextBold">{{item.label}}</span>
            </button>
          </ng-template>

          <ng-template [ngIf]="item.available == null || item.available">
            <button [ngClass]="isButtonSelected(item) ? 'selectedMenuBtn' : 'unselectedMenuBtn'"
                    [routerLink]="item.uri"  (click)="onMenuClick(item)" [title]="item.label">
              <span class="btnWithMenu bigTextBold">{{item.label}}</span>
              <mat-icon class="arrowBtnMenuTour" *ngIf="item.matIcon && item.name === 'businessSignals'" [mat-menu-trigger-for]="businessSignals" (click)="onDropDownMenuOpenBusinessSignals($event)" >{{menuIconBusinessSignals}}</mat-icon>
              <mat-icon *ngIf="item.matIcon && item.name === 'myFavorites'" [mat-menu-trigger-for]="myFavorites" (click)="onDropDownMenuOpenFavorites($event)" >{{menuIconFavorites}}</mat-icon>
              <mat-icon *ngIf="item.matIcon && item.name === 'sharedSignals'" [mat-menu-trigger-for]="sharedSignals" (click)="onDropDownMenuOpenSharedSignals($event)" >{{menuIconSharedSignals}}</mat-icon>
              <div *ngIf="item.name === 'monitoring' && (getNotificationsCount() > 0)"
                   style="display: flex">
                <mat-icon class="newNotificationsIcon" [matBadge]="getNotificationsCount()"
                          matBadgeColor="warn">notifications</mat-icon>
              </div>
            </button>
          </ng-template>
        </ng-container>
      </div>

      <mat-menu style="position: absolute" #menu="matMenu">
        <button class="subscription-info" mat-menu-item disabled>
          <mat-icon class="outlined">new_releases</mat-icon>
          <span>La fonctionnalité "Suivi d'affaires" vous permet de suivre les actions commerciales de vos équipes. Si vous souhaitez en savoir plus, merci de contacter votre chargé d'affaires.</span>
        </button>
      </mat-menu>

      <mat-menu style="position: absolute" (close)="onMenuBusinessSignalsClosed()" #businessSignals="matMenu"
                class="dropDownMenuBusinessSignals" (click)="$event.stopPropagation()">
        <div class="searchGestionLine">
          <fe-searchbar #feSearchBarBusiness (click)="$event.stopPropagation()" (valueChange)="filterChange($event)"/>
          <fe-button color="PRIMARY" hideText="true" class="marginS10" icon="true" type="TERTIARY"
                     [iconType]="IconType.options" matTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="Gérer mes recherches" (click)="openManageSearchesModal()"/>
          <fe-button color="PRIMARY" hideText="true" icon="true" type="TERTIARY" [iconType]="IconType.add"
                     matTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="Créer une nouvelle recherche" (click)="createNewSearch()"/>
        </div>

        <fe-button *ngIf="authService.roleCom" fullWidth="true" style="width: 100%; display: flex; margin: 4px 0 8px;" [text]="'Dupliquer des recherches'" (click)="openDuplicateSearches()"></fe-button>

        <div class="searchListItems">
          <ng-container *ngFor="let search of mySearchList; let i = index">
            <search-list-item [(value)]="mySearchList[i]"
                              [selected]="mySearchList[i].searchId == currentSearchId" [index]="i"
                              [searchList]="mySearchList" (click)="$event.stopPropagation()"
                              (searchSelected)="openBusinessSignals(search,i)"
                              *ngIf="!filter || filter.length <= 0 || search.data.label.toLowerCase().includes(filter.toLowerCase())"/>
          </ng-container>
        </div>
      </mat-menu>

      <mat-menu style="position: absolute" (close)="onMenuFavoritesClosed()" #myFavorites="matMenu"
                class="dropDownMenuFavorites">
        <div class="dropDownMenuFavoritesContent">
          <fe-searchbar #feSearchBarFavoris (click)="$event.stopPropagation()" (valueChange)="filterChange($event)"/>
          <fe-button text="Créer un nouveau dossier" icon="true" [iconType]="IconType.add_folder"
                     fullWidth="true" (click)="createFolder()"/>
          <div class="favorisLines">
            <ng-container *ngFor="let favorite of myFavoritesList; let i = index">
              <div class="favoriLine" *ngIf="!filter || filter.length <= 0 || favorite.libelle.toLowerCase().includes(filter.toLowerCase())">
                <span title="{{favorite.libelle}}" (click)="loadFolder(favorite)">{{favorite.libelle}}</span>
                <div class="feDotsMenuCotainer">
                  <fe-dots-menu (click)="$event.stopPropagation()">
                    <fe-menu-item color="PRIMARY" [icon]="IconType.folder_open" text="Voir le dossier"
                                  (click)="loadFolder(favorite)"/>
                    <fe-menu-item color="PRIMARY" [icon]="IconType.rename" text="Renommer"
                                  (click)="renameFolder(i)"/>
                    <fe-menu-item color="DANGER" [icon]="IconType.delete" text="Supprimer"
                                  (click)="removeFolder(i)"/>
                  </fe-dots-menu>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </mat-menu>

      <mat-menu style="position: absolute" (close)="onMenuSharedSignalsClosed()" #sharedSignals="matMenu"
                class="dropDownMenuSharedSignals">
        <fe-menu-item (click)="openHistory()" [icon]="IconType.send_mail" text="Historique des envois"/>
        <fe-menu-item (click)="openCarnetAdresses()" [icon]="IconType.group" text="Carnet d'adresses"/>
      </mat-menu>

      <mat-menu style="position: absolute"  #manageFavorites="matMenu"  class="matMenuManageSearches" >
        <button mat-menu-item >
          <mat-icon>folder_open</mat-icon>
          <span>Voir le dossier</span>
        </button>
        <button mat-menu-item>
          <mat-icon>border_color</mat-icon>
          <span>Renommer</span>
        </button>
        <div style="width: 100%; border-bottom: solid 1px #cbcccd; margin: 5px 0 "></div>
        <button mat-menu-item >
          <mat-icon style="color: #BF0214">delete</mat-icon>
          <span style="color: #BF0214">Supprimer</span>
        </button>
      </mat-menu>

      <div style="display:flex; gap: 8px; align-items: center;">
        <div class="firstAoButton" *ngIf="sessionData?.productType !== 'Lettre_MotsCles'">
          <a target="_blank" [href]="'https://app.firstao.fr/aoportal/#/a-o-actuality?token='
          + userToken+'&mode=client&stoken=' + sessionData?.customerDetails.session_token"
             style="display: block; overflow: hidden">
            <div class="logoHeader2" *ngIf="showFirstEcoLogo">
              <span style="font-size: 10px; color: var(--white);">Connexion à la plateforme</span>
              <img src="./assets/images/logo_fao.png" alt="Logo First AO" style="height: 43px; margin-top: -6px;"/>
            </div>
          </a>
        </div>

        <user-inset (click)="sidenavToggle(sideNav)" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}"
                    *ngIf="showUserInset" style="margin-right: -10px;"/>
      </div>
    </div>
  </ion-toolbar>
  <ion-buttons [ngClass]="{'desktop': !mediaQueryService.mobileQuery.matches}" slot="end">
    <button mat-icon-button class="buttonMenu" (click)="navigateToUrl('/app/business-signals', 'back')"
      *ngIf="mediaQueryService.mobileQuery.matches && showButtonClose">
      <mat-icon>close</mat-icon>
    </button>
  </ion-buttons>
</ion-header>
