import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ElementRef,
  Inject,
  LOCALE_ID,
  OnChanges,
  Input,
  ChangeDetectionStrategy, HostListener, Output, EventEmitter
} from '@angular/core';
import {Subject, Observable, Subscription} from 'rxjs';
import {Location, formatDate} from '@angular/common';
import {ToastController, NavController, ModalController} from '@ionic/angular';
import {MediaQueryService} from 'src/app/services/media-query/media-query.service';
import {ToastService} from 'src/app/services/toast/toast.service';
import {SearchesService} from 'src/app/services/searches/searches.service';
import {takeUntil, switchMap, debounceTime, tap, finalize, filter} from 'rxjs/operators';
import {CriterionsTypesInterface} from 'src/app/interfaces/criterions/criterions-types.interface';
import {CriterionInterface} from 'src/app/interfaces/criterions/criterion.interface';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {ENTER, COMMA, SEMICOLON, TAB} from '@angular/cdk/keycodes';
import {SearchInterface} from 'src/app/interfaces/search/search.interface';
import {SearchDataInterface} from 'src/app/interfaces/search/search-data.interface';
import {ArticlesInterface} from 'src/app/interfaces/articles/articles.interface';
import {DateArticleService} from 'src/app/services/date-article/date-article.service';
import {DateArticleInterface} from 'src/app/interfaces/date-article/date-article.interface';
import {ArticlesService} from 'src/app/services/articles/articles.service';
import {ArticleInterface} from 'src/app/interfaces/articles/article.interface';
import {ArticleQueryInterface} from 'src/app/interfaces/articles/article-query.interface';
import {PaginatedListComponent} from '../../paginated-list/paginated-list.component';
import {ErrorStatus} from 'src/app/classes/ErrorStatus.class';
import {Geolocation, GeolocationOptions, Geoposition} from '@awesome-cordova-plugins/geolocation/ngx';
import {CitySuggestion} from '../../../interfaces/search/CitySuggestion';
import {SessionInterface} from '../../../interfaces/session/session.interface';
import {UserService} from '../../../services/user/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Utils} from '../../../classes/Utils';
import {MatDialog} from '@angular/material/dialog';
import {MySearchesHelpDialogComponent} from '../my-searches-help-dialog/my-searches-help-dialog.component';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';

@Component({
  selector: 'my-searches-add',
  templateUrl: 'my-searches-add.component.html',
  styleUrls: ['my-searches-add.component.scss'],
})
 export class MySearchesAddComponent {
//   public set offCriterions(offCriterions: Array<number>) {
//     this._offCriterions = offCriterions;
//   }
//
//   public get offCriterions(): Array<number> {
//     return this._offCriterions;
//   }
//   public set keywords(keywords: Array<string>) {
//     this._keywords = keywords;
//   }
//
//   public get keywords(): Array<string> {
//     return this._keywords;
//   }
//   protected set offKeywords(offKeywords: Array<string>) {
//     this._offKeywords = offKeywords;
//   }
//
//   protected get offKeywords(): Array<string> {
//     return this._offKeywords;
//   }
//   protected set sdate(sdate: string) {
//     this._sdate = sdate;
//   }
//
//   protected get sdate(): string {
//     return this._sdate;
//   }
//
//   protected get geolocFrom(): string {
//     if ( this.cityId === -1) {
//       return this.lat + ',' + this.lng;
//     }
//     return '';
//   }
//   protected get cityFrom(): number {
//     if ( this.cityId >= 0) {
//       return this.cityId;
//     }
//     return null;
//   }
//   protected get geolocDistanceRadius(): number {
//     if (this.cityId > 0 || this.cityId === -1) {
//       return Number(this.selectedDistance);
//     }
//     return;
//   }
//   protected set edate(edate: string) {
//     this._edate = edate;
//   }
//   protected get edate(): string {
//     return this._edate;
//   }
//   public set stype(stype: string) {
//     this._stype = stype;
//   }
//   public get stype(): string {
//     return this._stype;
//   }
//   public set articlesCount(articlesCount: number) {
//     this._articlesCount = articlesCount;
//   }
//   @Input() public get articlesCount(): number {
//     return this._articlesCount;
//   }
//
//   // Sort option by date (asc / desc)
//   protected get articlesSortingMethod(): string {
//     return 'publicationDateDesc';
//   }
//   protected set criterions(criterions: Array<number>) {
//     this._criterions = criterions;
//   }
//
//   protected get criterions(): Array<number> {
//     return this._criterions;
//   }
//
//   protected get searchDefaultOpOption(): string {
//     return this._defaultOpOption;
//   }
//
//
//
//   /**
//    * Override query from PaginatedListComponent
//    */
//   protected get query(): ArticleQueryInterface {
//     const query: ArticleQueryInterface = {
//       searchId: this.currentSearch.searchId,
//       articlesSortingMethod: this.articlesSortingMethod,
//       criterions: this.criterions,
//       keywords: (this.searchCategory == 0) ? this.selectedKeywords : [],
//       keywords_default_operator: (this.defaultOpOptionForm.value) ? 'or' : 'and',
//       keywords_must: (this.searchCategory != 0) ? this.selectedAllOfKeywords : [],
//       keywords_must_exact: (this.searchCategory != 0) ? this.selectedExactKeywords : [],
//       keywords_must_not: (this.searchCategory != 0) ? this.selectedNoneOfKeywords : [],
//       keywords_should: (this.searchCategory != 0) ? this.selectedOneOfKeywords : [],
//       page: this.page,
//       pageSize: this.matPaginator.pageSize,
//       sdate: this.sdate,
//       edate: this.edate,
//       stype: this.stype,
//       geoloc_from: this.geolocFrom,
//       city_from: this.cityFrom,
//       geoloc_distance_radius: this.geolocDistanceRadius,
//     };
//     return query;
//   }
//
//   constructor(
//       private navigation: Location
//       , private articlesService: ArticlesService
//       , public searchesService: SearchesService
//       , private dateArticleService: DateArticleService
//       , public mediaQueryService: MediaQueryService
//       , public toastController: ToastController
//       , public toastService: ToastService
//       , public navController: NavController
//       , public modalController: ModalController
//       , public geolocation: Geolocation
//       , public userService: UserService
//       , private fb: FormBuilder
//       , @Inject(LOCALE_ID) public locale: string
//       , private route: ActivatedRoute
//       // , public spinnerService: NgxSpinnerService
//       , public searchesHelpDialog: MatDialog
//   ) {
//     super(mediaQueryService, geolocation, toastService, modalController, userService);
//   }
//   public static beforeUnloadHandler: any;
//
//   protected unsubscribe$: Subject<any> = new Subject<any>();
//
//   public displayForm: boolean = false;
//
//   // Edit Search
//   public search: SearchInterface;
//   public editMode: boolean = false;
//
//   // Search Name Input
//   @ViewChild('searchNameInput', { static: true }) searchNameInput: ElementRef<HTMLInputElement>;
//
//   // Page part display
//   public displayedForm: string = 'criterions';
//
//   // Criterions
//   @Input() public selectedCriterionLabel: string;
//   public criterionsTypes: CriterionsTypesInterface = <CriterionsTypesInterface>{};
//   public criterionsFiltered: CriterionsTypesInterface;
//   public formSubmitMethod: Function;
//
//   // Geoloc
//   public geolocAvailable: boolean = false;
//   public selectedDistance: string = '50';
//   public cityFilter: string;
//   public cityForm: FormGroup;
//   public cityOptions: Array<CitySuggestion>;
//   public isLoading = false;
//   private cityId: number;
//
//   // Articles List
//   public articlesList$: Observable<Array<ArticleInterface>>;
//   public articlesList: Array<ArticleInterface>;
//
//   // Keywords
//   public keywordCtrl = new FormControl();
//   public selectedKeywords: Array<string> = new Array<string>();
//   public exactKeywordsCtrl = new FormControl();
//   public selectedExactKeywords: Array<string> = new Array<string>();
//   public allOfKeywordsCtrl = new FormControl();
//   public selectedAllOfKeywords: Array<string> = new Array<string>();
//   public oneOfKeywordsCtrl = new FormControl();
//   public selectedOneOfKeywords: Array<string> = new Array<string>();
//   public noneOfKeywordsCtrl = new FormControl();
//   public selectedNoneOfKeywords: Array<string> = new Array<string>();
//   public visible = true;
//   public selectable = true;
//   public removable = true;
//   public separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON, TAB];
//   @ViewChild('keywordInput', {static: true}) keywordInput: ElementRef<HTMLInputElement>;
//   @ViewChild('exactKeywordsInput', {static: true}) exactKeywordsInput: ElementRef<HTMLInputElement>;
//   @ViewChild('allOfKeywordsInput', {static: true}) allOfKeywordsInput: ElementRef<HTMLInputElement>;
//   @ViewChild('oneOfKeywordsInput', {static: true}) oneOfKeywordsInput: ElementRef<HTMLInputElement>;
//   @ViewChild('noneOfKeywordsInput', {static: true}) noneOfKeywordsInput: ElementRef<HTMLInputElement>;
//   // Tab for Business Signals / AO
//   public businessAoTab = 0;
//
//   // Tab for Searches Category Classic / Advanced
//   // 0 for Classic, 1 for advanced
//   public searchCategory = 0;
//
//   // Subcriterions
//   public criterionKey: string;
//   public childrenCriterions: Array<CriterionInterface>;
//   public selectedCriterionsObj: SearchDataInterface;
//   public criterions$: Observable<CriterionInterface>;
//
//   public filterCriterion: string;
//
//   public matSlideToggleDefaultValue: boolean = true;
//
//   public allTopicsSelected: boolean;
//   public allLocationsSelected: boolean;
//   public allSectorsSelected: boolean;
//   public allCompanySizesSelected: boolean;
//   public allActivityTypesSelected: boolean;
//
//   // Get offCriterions
//   private _offCriterions: Array<number> = [];
//
//   // Criterions List
//   public keywordsCount: Array<any> = [];
//   public locationsCount: Array<any> = [];
//   public sectorsCount: Array<any> = [];
//   public topicsCount: Array<any> = [];
//   public activity_typesCount: Array<any> = [];
//   public company_sizesCount: Array<any> = [];
//
//   private _keywords: Array<string> = [];
//
//   // Get OffKeywords
//   private _offKeywords: Array<string> = [];
//
//   // Start date
//   private _sdate: string = undefined;
//
//   // End date
//   private _edate: string = undefined;
//
//   // stype
//   public _stype: string = undefined;
//
//   // Results
//   public _articlesCount: number;
//
//   // Get criterions
//   private _criterions: Array<number> = [];
//
//   //  Defaut Search Option
//   public _defaultOpOption: string;
//   public defaultOpOptionForm = new FormControl();
//
//   // Articles List
//   public articlesObj$: Observable<ArticlesInterface>;
//   first: boolean = true;
//   getdateobservable: Subscription;
//
//   @Input() public from: string;
//   @Input() public criterionsList: string[];
//   @Input() public currentSearch: SearchInterface;
//   @Output() closeSettingsMenu = new EventEmitter<any>();
//   @Output() updateCriterionList = new EventEmitter<{typeCriterion: string, criterion: CriterionInterface, add: boolean}>();
//   panelTitle: string = '';
//
//     public toExpand: Set<CriterionInterface> = new Set<CriterionInterface>();
//   running: boolean = false;
//
//   /**
//    * Switch Default Search Operator
//    */
//   onSortingOptionChange() {
//     // if (this.defaultOpOptionForm.value) {
//     //   this._defaultOpOption = 'or';
//     // } else {
//     //   this._defaultOpOption = 'and';
//     // }
//   }
//
//
//
//
//
//   public ngOnInit(): void {
//     MySearchesAddComponent.beforeUnloadHandler = (event: BeforeUnloadEvent) => {
//       event.preventDefault();
//       event.returnValue = '';
//       return 'Voulez vous vraiment quitter cette page ?';
//     };
//     window.addEventListener('beforeunload', MySearchesAddComponent.beforeUnloadHandler);
//     this.route.queryParams.subscribe(params => {
//       if (params['searchedit'] !== undefined) {
//         this.searchesService.mySearchList$.subscribe(next => {
//           this.searchesService.setSearchToEdit(next[params['searchedit']]);
//           this.searchesService.editMode = true;
//         });
//       } else if (params['keyword'] !== undefined) {
//         // for (const key of params['keyword'].split(' ').join(" ")) {
//           this.selectedKeywords.push(params['keyword'].split(' ').join(' '));
//         // }
//         this.send();
//       }
//     });
//
//     this.loadCriterions();
//
//     this.cityForm = this.fb.group({
//       cityInput: null
//     });
//
//     // Geoloc
//     this.userService.getSessionDatas().pipe(
//         takeUntil(this.unsubscribe$)
//     )
//         .subscribe(
//             (sessionData: SessionInterface) => {
//               if (sessionData !== undefined) {
//                 sessionData.appConfig.viewConfigs.forEach(value => {
//                   if (value.component === 'GEOLOCATION_SEARCH') {
//                     if (value.state === 'visible') {
//                       this.setMyPosition();
//                       this.geolocAvailable = true;
//                     }
//                   }
//                 });
//               }
//             }
//         );
//
//     this.cityForm
//         .get('cityInput')
//         .valueChanges
//         .pipe(
//             debounceTime(300),
//             tap(() => this.isLoading = true),
//             switchMap(value => this.searchesService.autocompleteCities(this.cityFilter)
//                 .pipe(
//                     finalize(() => this.isLoading = false),
//                 )
//             )
//         )
//         .subscribe(cities => this.cityOptions = cities);
//     this.getDates();
//
//     if (this.from === 'sectors') {
//       this.panelTitle = 'Secteur d\'activité';
//     }
//     if (this.from === 'topics') {
//       this.panelTitle = 'Evénement économique';
//     }
//     if (this.from === 'locations') {
//       this.panelTitle = 'Localisation';
//     }
//     if (this.from === 'companySizes') {
//       this.panelTitle = 'Taille d\'entreprise';
//     }
//     if (this.from === 'activityTypes') {
//       this.panelTitle = 'Nature des locaux';
//     }
//   }
//
//   public ngOnChanges() {
//     this.loadCriterions();
//   }
//
//   public loadCriterions() {
//     this.editMode = this.searchesService.editMode;
//     this.criterionKey = this.searchesService.selectedCriterionsKey;
//     this.childrenCriterions = this.searchesService.displayedChildrenCriterions;
//     this.selectedCriterionsObj = this.searchesService.selectedCriterionsObj;
//
//     this.articlesObj$ = this.articlesService.articlesObj$;
//
//
//       this.search = this.currentSearch;
//       this.searchesService.setSearchToEdit(this.currentSearch);
//       delete this.criterionsTypes;
//       this.searchesService.selectedCriterionsObj = {};
//        this.searchesService.searchToEdit$.subscribe(
//         search => {
//            this.search = search;
//         }
//       );
//
//       this.searchesService.selectedCriterionsObj = this.search.data;
//       this.selectedCriterionsObj = this.searchesService.selectedCriterionsObj;
//
//
//     this.searchesService.getCriterions().pipe(
//       takeUntil(this.unsubscribe$)
//     )
//     .subscribe(
//       (criterionsTypes: CriterionsTypesInterface) => {
//         this.criterionsTypes = criterionsTypes;
//         this.searchesService.setAllCriterions(this.criterionsTypes);
//
//         if (this.search !== undefined) {
//           this.searchesService.selectedCriterionsObj = this.search.data;
//           this.selectedCriterionsObj = this.searchesService.selectedCriterionsObj;
//
//           if (Utils.notNullOrEmpty(this.search.data.keywords)) {
//             this.selectedKeywords = this.search.data.keywords;
//           } else if (
//               Utils.notNullOrEmpty(this.search.data.keywords_must) ||
//               Utils.notNullOrEmpty(this.search.data.keywords_should) ||
//               Utils.notNullOrEmpty(this.search.data.keywords_must_exact) ||
//               Utils.notNullOrEmpty(this.search.data.keywords_must_not)
//           ) {
//             this.searchCategory = 1;
//             if (this.search.data.keywords_must !== undefined) {
//               this.selectedAllOfKeywords = this.search.data.keywords_must;
//             }
//             if (this.search.data.keywords_should !== undefined) {
//               this.selectedOneOfKeywords = this.search.data.keywords_should;
//             }
//             if (this.search.data.keywords_must_exact !== undefined) {
//               this.selectedExactKeywords = this.search.data.keywords_must_exact;
//             }
//             if (this.search.data.keywords_must_not !== undefined) {
//               this.selectedNoneOfKeywords = this.search.data.keywords_must_not;
//             }
//           }
//           if (this.search.data.label !== undefined) {
//             this.searchNameInput.nativeElement.value = this.search.data.label;
//           }
//
//           if (this.search.data.searchType !== undefined) {
//             if (this.search.data.searchType === 'ao') {
//               this.businessAoTab = 1;
//             }
//           }
//
//           this._defaultOpOption = this.search.data.keywords_default_operator;
//
//           // geoloc
//           if (this.search.data.cityFrom > 0) {
//             // this.searchesService.getCityById(.then(value => this.getCityTextAndSetId(value));
//             this.cityId = this.search.data.cityFrom;
//             this.searchesService.getCityById(this.search.data.cityFrom).subscribe(value => this.initCity(value));
//           }
//           if (this.search.data.geolocFrom !== undefined && this.search.data.geolocFrom.length > 0) {
//             const myPosition: CitySuggestion = new class implements CitySuggestion {
//               id: number;
//               name: string;
//               zip: string;
//             };
//             myPosition.name = 'Autour de moi';
//             myPosition.zip = '';
//             myPosition.id = -1;
//             this.getCityTextAndSetId(myPosition);
//           }
//
//           // Check the results on load
//           this.checkResults();
//         }
//
//         if (this.from === 'sectors') {
//           const criterionArray = this.convertIterableToArray(this.criterionsTypes.sectors.values);
//           this.openSubPage('sectors', this.criterionsTypes.sectors['label'].toString(), criterionArray);
//         }
//         if (this.from === 'topics') {
//           const criterionArray = this.convertIterableToArray(this.criterionsTypes.topics.values);
//           this.openSubPage('topics', this.criterionsTypes.topics.label, criterionArray);
//         }
//         if (this.from === 'locations') {
//           const criterionArray = this.convertIterableToArray(this.criterionsTypes.locations.values);
//           this.openSubPage('locations', this.criterionsTypes.locations['label'].toString(), criterionArray);
//         }
//         if (this.from === 'companySizes') {
//           const criterionArray = this.convertIterableToArray(this.criterionsTypes.companySizes.values);
//           this.openSubPage('companySizes', this.criterionsTypes.companySizes['label'].toString(), criterionArray);
//         }
//         if (this.from === 'activityTypes') {
//           const criterionArray = this.convertIterableToArray(this.criterionsTypes.activityTypes.values);
//           this.openSubPage('activityTypes', this.criterionsTypes.activityTypes['label'].toString(), criterionArray);
//         }
//
//       }
//     );
//   }
//
//   public ionViewWillEnter(): void {
//     // this.loadCriterions();
//   }
//
//   /**
//    * Add a keyword
//    */
//   add(event: MatChipInputEvent, inputName?: string): void {
//     const input = event.input;
//     const value = event.value;
//
//     const valueTrimmed = (value).trim();
//     // Add our keywords
//     if (inputName == undefined) {
//       this.selectedKeywords.push(valueTrimmed);
//
//     } else if (valueTrimmed) {
//       if (inputName == 'exactKeywords') {
//         this.selectedExactKeywords.push(valueTrimmed);
//       } else if (inputName == 'allOfKeywords') {
//         this.selectedAllOfKeywords.push(valueTrimmed);
//       } else if (inputName == 'oneOfKeywords') {
//         this.selectedOneOfKeywords.push(valueTrimmed);
//       } else if (inputName == 'noneOfKeywords') {
//         this.selectedNoneOfKeywords.push(valueTrimmed);
//       }
//     }
//
//     // Reset the input value
//     if (input) {
//       input.value = '';
//     }
//
//     if (inputName == undefined) {
//       this.keywordCtrl.setValue(null);
//     } else if (inputName == 'exactKeywords') {
//       this.exactKeywordsCtrl.setValue(null);
//     } else if (inputName == 'allOfKeywords') {
//       this.allOfKeywordsCtrl.setValue(null);
//     } else if (inputName == 'oneOfKeywords') {
//       this.oneOfKeywordsCtrl.setValue(null);
//     } else if (inputName == 'noneOfKeywords') {
//       this.noneOfKeywordsCtrl.setValue(null);
//     }
//
//     // Check the results when we add a keyword
//     this.checkResults();
//   }
//
//   addBlur($event: FocusEvent) {
//     // const value = this.keywordInput.nativeElement.value;
//     const value = ($event.target as HTMLInputElement).value;
//     if (value) {
//       const valueTrimmed = (value).trim();
//       this.selectedKeywords.push(valueTrimmed);
//       ($event.target as HTMLInputElement).value = '';
//       this.keywordCtrl.setValue(null);
//     }
//   }
//
//   /**
//    * Remove a keyword
//    */
//   remove(keyword: string, inputName?: string): void {
//     if (inputName == undefined) {
//       // Get the index of the keyword
//       const index = this.selectedKeywords.indexOf(keyword);
//
//       if (index >= 0) {
//         this.selectedKeywords.splice(index, 1);
//       }
//     } else if (inputName == 'exactKeywords') {
//       // Get the index of the keyword
//       const index = this.selectedExactKeywords.indexOf(keyword);
//
//       if (index >= 0) {
//         this.selectedExactKeywords.splice(index, 1);
//       }
//     } else if (inputName == 'allOfKeywords') {
//       // Get the index of the keyword
//       const index = this.selectedAllOfKeywords.indexOf(keyword);
//
//       if (index >= 0) {
//         this.selectedAllOfKeywords.splice(index, 1);
//       }
//     } else if (inputName == 'oneOfKeywords') {
//       // Get the index of the keyword
//       const index = this.selectedOneOfKeywords.indexOf(keyword);
//
//       if (index >= 0) {
//         this.selectedOneOfKeywords.splice(index, 1);
//       }
//     } else if (inputName == 'noneOfKeywords') {
//       // Get the index of the keyword
//       const index = this.selectedNoneOfKeywords.indexOf(keyword);
//
//       if (index >= 0) {
//         this.selectedNoneOfKeywords.splice(index, 1);
//       }
//     }
//     // Check the results when we remove a keyword
//     this.checkResults();
//   }
//
//   public tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
//     this.businessAoTab = tabChangeEvent.index;
//     this.checkResults();
//   }
//
//   /**
//    * Classic Search / Advanced Search
//    * @param tabChangeEvent
//    */
//   public searchCategoryChanged = (tabChangeEvent: MatTabChangeEvent): void => {
//     this.searchCategory = tabChangeEvent.index;
//     this.checkResults();
//   }
//   getKeywordsSearched() {
//     const keywordSearched = [];
//     for (const ks of this.keywordsCount) {
//       const searched = ks.searched;
//       keywordSearched.push(...searched);
//     }
//     return keywordSearched;
//   }
//   /**
//    * Refresh results
//    */
//   public checkResults(): void {
//     this.running = true;
//     // console.log('check results');
//     // console.log(new Error().stack);
//     this.criterions = [];
//     const keywords = this.selectedKeywords;
//     const exactKeywords = this.selectedExactKeywords;
//     const oneOfKeywords = this.selectedOneOfKeywords;
//     const allOfKeywords = this.selectedAllOfKeywords;
//     const noneOfKeywords = this.selectedNoneOfKeywords;
//     if (this.searchCategory == 0) {
//       // keywords
//       this.searchesService.selectedCriterionsObj['keywords'] = (typeof keywords !== 'undefined' && keywords instanceof Array) ? keywords : [];
//       this.query.keywords = (typeof keywords !== 'undefined' && keywords instanceof Array) ? keywords : [];
//
//       // Default Operator
//       this.searchesService.selectedCriterionsObj['keywords_default_operator'] = (this.defaultOpOptionForm.value) ? 'or' : 'and';
//       this.query.keywords_default_operator = (this.defaultOpOptionForm.value) ? 'or' : 'and';
//
//       // Reset Other keywords
//       this.searchesService.selectedCriterionsObj['keywords_must_exact'] = [];
//       this.query.keywords_must_exact = [];
//       this.searchesService.selectedCriterionsObj['keywords_should'] = [];
//       this.query.keywords_should = [];
//       this.searchesService.selectedCriterionsObj['keywords_must'] = [];
//       this.query.keywords_must = [];
//       this.searchesService.selectedCriterionsObj['keywords_must_not'] = [];
//       this.query.keywords_must_not = [];
//     } else { // Case Advanced Search
//       this.searchesService.selectedCriterionsObj['keywords'] = [];
//       this.query.keywords = [];
//       // Default Operator
//       this.searchesService.selectedCriterionsObj['keywords_default_operator'] = null;
//
//       // Exact Keywords
//       this.searchesService.selectedCriterionsObj['keywords_must_exact'] = (typeof exactKeywords !== 'undefined' && exactKeywords instanceof Array) ? exactKeywords : [];
//       this.query.keywords_must_exact = [];
//       // one of Keywords
//       this.searchesService.selectedCriterionsObj['keywords_should'] = (typeof oneOfKeywords !== 'undefined' && oneOfKeywords instanceof Array) ? oneOfKeywords : [];
//       this.query.keywords_should = [];
//       // all of Keywords
//       this.searchesService.selectedCriterionsObj['keywords_must'] = (typeof allOfKeywords !== 'undefined' && allOfKeywords instanceof Array) ? allOfKeywords : [];
//       this.query.keywords_must = [];
//       // none of Keywords
//       this.searchesService.selectedCriterionsObj['keywords_must_not'] = (typeof noneOfKeywords !== 'undefined' && noneOfKeywords instanceof Array) ? noneOfKeywords : [];
//       this.query.keywords_must_not = [];
//     }
//     // searchType depending on selected tab
//     this.searchesService.selectedCriterionsObj['searchType'] = (this.businessAoTab === 1) ? 'ao' : 'business_signal';
//
//
//     // locations
//     const locations = this.searchesService.selectedCriterionsObj['locations'];
//     this.searchesService.selectedCriterionsObj['locations'] = (typeof locations !== 'undefined' && locations instanceof Array) ? locations : [];
//
//     // sectors
//     let sectors = this.searchesService.selectedCriterionsObj['sectors'];
//     this.searchesService.selectedCriterionsObj['sectors'] = (typeof sectors !== 'undefined' && sectors instanceof Array) ? sectors : [];
//
//     // topics
//     let topics = this.searchesService.selectedCriterionsObj['topics'];
//     this.searchesService.selectedCriterionsObj['topics'] = (typeof topics !== 'undefined' && topics instanceof Array) ? topics : [];
//
//     // activityTypes
//     let activityTypes = this.searchesService.selectedCriterionsObj['activityTypes'];
//     this.searchesService.selectedCriterionsObj['activityTypes'] = (typeof activityTypes !== 'undefined' && activityTypes instanceof Array) ? activityTypes : [];
//
//     // companySizes
//     let companySizes = this.searchesService.selectedCriterionsObj['companySizes'];
//     this.searchesService.selectedCriterionsObj['companySizes'] = (typeof companySizes !== 'undefined' && companySizes instanceof Array) ? companySizes : [];
//
//     // geoloc
//     this.searchesService.selectedCriterionsObj.geolocFrom = this.geolocFrom;
//     this.searchesService.selectedCriterionsObj.cityFrom = this.cityFrom;
//     this.searchesService.selectedCriterionsObj.geolocDistanceRadius = this.geolocDistanceRadius;
//
//     // If AO, we remove useless criterions
//     if (this.businessAoTab === 1) {
//       sectors = [];
//       topics = [];
//       activityTypes = [];
//       companySizes = [];
//       this.stype = 'ao';
//     } else {
//       this.stype = 'business_signal';
//     }
//
//     // Remove off criterions
//
//     this.searchesService.selectedCriterionsObj['locations'] = this.searchesService.selectedCriterionsObj['locations'].filter(x => !this._offCriterions.includes(x));
//     this.searchesService.selectedCriterionsObj['sectors'] = this.searchesService.selectedCriterionsObj['sectors'].filter(x => !this._offCriterions.includes(x));
//     this.searchesService.selectedCriterionsObj['topics'] = this.searchesService.selectedCriterionsObj['topics'].filter(x => !this._offCriterions.includes(x));
//     this.searchesService.selectedCriterionsObj['activityTypes'] = this.searchesService.selectedCriterionsObj['activityTypes'].filter(x => !this._offCriterions.includes(x));
//     this.searchesService.selectedCriterionsObj['companySizes'] = this.searchesService.selectedCriterionsObj['companySizes'].filter(x => !this._offCriterions.includes(x));
//
//
//     if (this.searchesService.selectedCriterionsObj['locations'].length > 0) {
//       this.criterions = this.query.criterions.concat(this.searchesService.selectedCriterionsObj['locations']);
//     }
//     if (this.searchesService.selectedCriterionsObj['sectors'].length > 0) {
//       this.criterions = this.query.criterions.concat(this.searchesService.selectedCriterionsObj['sectors']);
//     }
//     if (this.searchesService.selectedCriterionsObj['topics'].length > 0) {
//       this.criterions = this.query.criterions.concat(this.searchesService.selectedCriterionsObj['topics']);
//     }
//     if (this.searchesService.selectedCriterionsObj['activityTypes'].length > 0) {
//       this.criterions = this.query.criterions.concat(this.searchesService.selectedCriterionsObj['activityTypes']);
//     }
//     if (this.searchesService.selectedCriterionsObj['companySizes'].length > 0) {
//       this.criterions = this.query.criterions.concat(this.searchesService.selectedCriterionsObj['companySizes']);
//     }
//
//
//     // this.query.keywords = keywords;
//
//     // Initiate first page
//     this.page = 1;
//     this.matPaginator.pageIndex = 0;
//
//     // this.articlesService.setQuery(this.query);
//
//     this.init<ArticlesInterface>();
//
//     /*     this.articlesService.getArticlesSearch(this.query).subscribe(
//           searchList => {
//             this.articlesCount = searchList.articlesCount;
//             this.getDataTypedAndCountAfterLoad(searchList);
//           }
//         ); */
//   }
//
//   /**
//   * Submit the form
//   */
//   public send(): void {
//
//     // Display the result part
//     this.displayedForm = 'result';
//
//     this.checkResults();
//   }
//
//   private lookInChildCriterion(parent: CriterionInterface, id: string, topics: Array<number>): boolean {
//     let found: boolean = false;
//     Array.from(parent.childCriterions).forEach(child => {
//       if (!found) {
//         if (child.id.toString() === id) {
//           Array.from(child.childCriterions).forEach(sub => {
//             if (sub.subType === 'temporal' || sub.subType === 'predictive') {
//               if (sub.checked_) {
//                 topics.push(Number(sub.id));
//               }
//             }
//           });
//           found = true;
//         } else if (child.childCriterions != null && child.childCriterions.length > 0) {
//           found = this.lookInChildCriterion(child, id, topics);
//         }
//       }
//     });
//     return found;
//   }
//
//   /**
//   * Count the selected criterions
//   */
//   public getCountCriterions(criterionsType: string): string {
//     // TODO: Optimize the call of the function in the template file, it is unecessary called multiple times
//     // https://medium.com/showpad-engineering/why-you-should-never-use-function-calls-in-angular-template-expressions-e1a50f9c0496
//     let count = 0;
//     if (this.searchesService.selectedCriterionsObj.hasOwnProperty(criterionsType)) {
//       count = Object.keys(this.searchesService.selectedCriterionsObj[criterionsType]).length;
//     }
//     if (criterionsType === 'locations') {
//       if ((this.searchesService.selectedCriterionsObj.geolocFrom !== undefined && this.searchesService.selectedCriterionsObj.geolocFrom.length > 0) || this.searchesService.selectedCriterionsObj.cityFrom > 0) {
//         count++;
//       }
//     }
//     if (count > 0) {
//       return count.toString();
//     }
//     return;
//   }
//   public async getDates(): Promise<any> {
//     this.getdateobservable = this.dateArticleService.dateArticle$.pipe(
//       takeUntil(this.unsubscribe$),
//     filter(ev => window.location.href.includes('app/my-searches-add'))
//     ).subscribe(
//       (dateArticle: DateArticleInterface) => {
//         if (typeof dateArticle.period !== 'undefined') {
//           this.sdate = this.dateArticleService.parsePeriodDate(dateArticle.period);
//           this.edate = formatDate(new Date(), 'yyyy-MM-dd', this.locale);
//         } else {
//           this.sdate = formatDate(dateArticle.from, 'yyyy-MM-dd', this.locale);
//           this.edate = formatDate(dateArticle.to, 'yyyy-MM-dd', this.locale);
//         }
//         if (!this.editMode || !this.first) {
//           this.checkResults();
//         } else {
//           this.first = false;
//         }
//       }
//     );
//   }
//
//   /**
//   * Disable button
//   */
//   public disableSearchButton(): boolean {
//     // TODO: Optimize the call of the function in the template file, it is unecessary called multiple times
//     // https://medium.com/showpad-engineering/why-you-should-never-use-function-calls-in-angular-template-expressions-e1a50f9c0496
//
//     // if (this.editMode === false) {
//     if (this.selectedKeywords !== undefined && this.selectedKeywords.length > 0) {
//       return false;
//     } else if (this.selectedOneOfKeywords !== undefined && this.selectedOneOfKeywords.length > 0) {
//       return false;
//     } else if (this.selectedAllOfKeywords !== undefined && this.selectedAllOfKeywords.length > 0) {
//       return false;
//     } else if (this.selectedNoneOfKeywords !== undefined && this.selectedNoneOfKeywords.length > 0) {
//       return false;
//     } else if (this.selectedExactKeywords !== undefined && this.selectedExactKeywords.length > 0) {
//       return false;
//     } else if (this.searchesService.selectedCriterionsObj['locations'] !== undefined && this.searchesService.selectedCriterionsObj['locations'].length > 0) {
//       return false;
//     } else if (this.searchesService.selectedCriterionsObj['sectors'] !== undefined && this.searchesService.selectedCriterionsObj['sectors'].length > 0) {
//       return false;
//     } else if (this.searchesService.selectedCriterionsObj['topics'] !== undefined && this.searchesService.selectedCriterionsObj['topics'].length > 0) {
//       return false;
//     } else if (this.searchesService.selectedCriterionsObj['activityTypes'] !== undefined && this.searchesService.selectedCriterionsObj['activityTypes'].length > 0) {
//       return false;
//     } else if (this.searchesService.selectedCriterionsObj['companySizes'] !== undefined && this.searchesService.selectedCriterionsObj['companySizes'].length > 0) {
//       return false;
//     } else if (this.cityForm.get('cityInput').value != null && this.cityForm.get('cityInput').value.toString().length > 0) {
//       return false;
//     } else {
//       return true;
//     }
//     // }
//   }
//
//   /**
//   * Change form view
//   */
//   public changeDisplayForm(displayedForm: string) {
//     // Save selection on back
//     if (this.displayedForm === 'subcriterions' && displayedForm === 'criterions') {
//       this.addToSelectedCriterions(this.criterionKey);
//     }
//     this.displayedForm = displayedForm;
//   }
//
//   backClicked() {
//     delete this.criterionsTypes;
//     this.searchesService.selectedCriterionsObj = {};
//     this.navigation.back();
//   }
//
//   public ngOnDestroy() {
//     this.resetValues();
//     // this.unsubscribe$.next();
//     this.unsubscribe$.complete();
//     this.getdateobservable.unsubscribe();
//   }
//
//   /*
//   *
//   *
//   *
//   *
//   * SUBCRITERIONS
//   *
//   *
//   *
//   */
//
//   public preSelectCriterions(childrenCriterion: CriterionInterface, parentCrit: CriterionInterface = undefined) {
//     if (childrenCriterion.childCriterions !== undefined && childrenCriterion.childCriterions.length > 0) {
//       childrenCriterion.childCriterions.sort(function(a, b): number {
//         if (a.subType === 'temporal' && b.subType === 'temporal') {
//           if (a.subSubType === 'past') {
//             return -1;
//           } else if (a.subSubType === 'future') {
//             return 1;
//           } else {
//             if (b.subSubType === 'past') {
//               return 1;
//             } else if (b.subSubType === 'future') {
//               return -1;
//             }
//           }
//         } else if (a.subType === 'temporal') {
//           return -1;
//         } else if (b.subType === 'temporal') {
//           return 1;
//         } else if (a.subType === 'predictive') {
//           return -1;
//         } else if (b.subType === 'predictive') {
//           return 1;
//         } else {
//           return a.label.localeCompare(b.label);
//         }
//       });
//     }
//
//     let foundInSelected = this.selectedCriterionsObj[this.criterionKey].find(id => id === childrenCriterion.id);
//     foundInSelected = typeof foundInSelected === 'undefined' ? false : true;
//     if (!foundInSelected && parentCrit !== undefined) {
//       foundInSelected = parentCrit.checked_;
//     }
//     childrenCriterion.checked_ = foundInSelected;
//     if (childrenCriterion.childCriterions !== undefined && childrenCriterion.childCriterions.length > 0) {
//       let leadsSelected: boolean = false;
//       for (const subChildCriterion of childrenCriterion.childCriterions) {
//         let foundSubInSelected = this.selectedCriterionsObj[this.criterionKey].find(id => id === subChildCriterion.id);
//         foundSubInSelected = typeof foundSubInSelected === 'undefined' ? false : true;
//         if (subChildCriterion.subType === 'temporal' || subChildCriterion.subType === 'predictive') {
//           if (foundSubInSelected) {
//             leadsSelected = true;
//             childrenCriterion.checked_ = true;
//             subChildCriterion.checked_ = true;
//           }
//         } else {
//           subChildCriterion.checked_ = foundInSelected || foundSubInSelected;
//           this.preSelectCriterions(subChildCriterion, childrenCriterion);
//         }
//
//       }
//       // if (leadsSelected) {
//       //   for (const subChildCriterion of childrenCriterion.childCriterions) {
//       //     let foundSubInSelected = this.selectedCriterionsObj[this.criterionKey].find(id => id === subChildCriterion.id);
//       //     foundSubInSelected = typeof foundSubInSelected === 'undefined' ? false : true;
//       //     if (subChildCriterion.subType === 'temporal' || subChildCriterion.subType === 'predictive') {
//       //       subChildCriterion.lead.checked = foundSubInSelected;
//       //       subChildCriterion.checked_ = foundSubInSelected;
//       //     }
//       //   }
//       // }
//     }
//   }
//
//   /**
//   * Open the sub modal with parameters
//   */
//   public openSubPage(criterionKey: string, criterionLabel: string, criterions: Array<CriterionInterface>) {
//     this.selectedCriterionLabel = criterionLabel;
//     this.criterionKey = criterionKey;
//     this.searchesService.getChildrenCriterions(criterions);
//     this.searchesService.setSelectedCriterionsKey(criterionKey);
//     this.displayedForm = 'subcriterions';
//     this.childrenCriterions = this.searchesService.displayedChildrenCriterions;
//     this.filterCriterion = '';
//     this.toExpand = new Set<CriterionInterface>();
//
//
//
//     // if (this.selectedCriterionsObj !== undefined && this.selectedCriterionsObj[this.criterionKey] !== undefined && this.selectedCriterionsObj[this.criterionKey].length > 0) {
//       for (const childrenCriterion of this.childrenCriterions) {
//         this.preSelectCriterions(childrenCriterion);
//       }
//     // }
//
//     // console.log(this.childrenCriterions);
//
//     //   if (childrenCriterion.checked_) {
//     //     this.descendantsPartiallySelected(childrenCriterion, this.childrenCriterions.length);
//     //   } else {
//     //     childrenCriterion.checked_ = false;
//     //   }
//     // }
//   }
//
//   /** Whether all the descendants of the node are selected. */
//   public descendantsAllSelected(currentCriterion: CriterionInterface): boolean {
//     const descendants = currentCriterion.childCriterions;
//
//     if (descendants !== undefined) {
//       if (descendants.length > 0) {
//         const descAllSelected = descendants.every(child =>
//           this.isChecked(child)
//         );
//         for (const descendant of descendants) {
//           descendant.checked_ = descAllSelected;
//         }
//
//         return descAllSelected;
//       } else {
//         return false;
//       }
//     }
//   }
//
//   /** Whether part of the descendants are selected */
//   public descendantsPartiallySelected(currentCriterion: CriterionInterface, count: number): boolean {
//
//     if (currentCriterion.childCriterions !== undefined) {
//       currentCriterion.childCriterions.forEach((child) => {
//         if (typeof child !== 'undefined' && this.isChecked(child)) {
//           count++;
//         }
//       });
//     }
//     if (currentCriterion.childCriterions !== undefined && count === currentCriterion.childCriterions.length || (currentCriterion.childCriterions !== undefined && this.criterionKey === 'topics' && count === (currentCriterion.childCriterions.length - 4))) {
//       return false;
//     } else if (count > 0) {
//       return true;
//     } else {
//       let partial: boolean = false;
//       if (currentCriterion.childCriterions !== undefined) {
//         currentCriterion.childCriterions.forEach((child) => {
//           if (!partial && child.childCriterions !== undefined) {
//             partial = this.descendantsPartiallySelected(child, 0);
//           }
//         });
//       }
//       return partial;
//     }
//   }
//
//   /**
//   * Add selected criterions to the parent modal and close this one.
//   */
//   public addToSelectedCriterions(key: string): void {
//     this.searchesService.selectedCriterionsObj[key] = [];
//     this.searchesService.addToSelectedCriterions(key, this.childrenCriterions);
//     this.displayedForm = 'criterions';
//
//     // geoloc
//     if (key === 'locations') {
//       if (this.cityFrom > 0) {
//         this.searchesService.selectedCriterionsObj.cityFrom = this.cityFrom;
//       }
//       if (this.geolocFrom != null) {
//         this.searchesService.selectedCriterionsObj.geolocFrom = this.geolocFrom;
//       }
//       if (this.geolocDistanceRadius > 0) {
//         this.searchesService.selectedCriterionsObj.geolocDistanceRadius = this.geolocDistanceRadius;
//       }
//     }
//
//     // Check the results when we add criteriions
//     // this.checkResults();
//   }
//
//   /**
//   * When a checkbox is checked,
//   * If it is a child, we indeterminate the parent or check it if it's fully checked
//   */
//   public onCheckParent(criterion: CriterionInterface, event: MatCheckboxChange): void {
//
//     // Set the each child value depending wether the parent is checked or not
//     let childCheckValue: boolean;
//     if (event.checked === true) {
//       criterion.checked_ = true;
//       childCheckValue = true;
//     } else {
//       criterion.checked_ = false;
//       childCheckValue = false;
//     }
//
//      this.onCheckParentChildren(criterion, childCheckValue);
//   }
//
//   public onCheckParentChildren(criterion: CriterionInterface, childCheckValue: boolean) {
//     // If the criterion has childCriterions, we (de)select all of them
//     if (criterion.childCriterions !== undefined) {
//       for (const child of criterion.childCriterions) {
//         if (child.subType !== 'temporal' && child.subType !== 'predictive') {
//           child.checked_ = childCheckValue;
//           this.onCheckParentChildren(child, childCheckValue);
//         }
//       }
//     }
//   }
//
//   public onCheckChild(parent: CriterionInterface, current: CriterionInterface, event: MatCheckboxChange): void {
//
//     if (current !== null) {
//       if (event.checked === true) {
//         current.checked_ = true;
//       } else {
//         current.checked_ = false;
//       }
//     }
//
//     let count: number = 0;
//     if (parent.childCriterions) {
//       parent.childCriterions.forEach((child) => {
//         if (typeof child !== 'undefined' && child.checked_) {
//           count++;
//         }
//       });
//
//       if (parent.childCriterions.length === count) {
//         parent.checked_ = true;
//         this.descendantsPartiallySelected(parent, 0);
//       } else {
//         parent.checked_ = false;
//         this.descendantsPartiallySelected(parent, count);
//       }
//     }
//   }
//
//   public isChecked(criterion: CriterionInterface): boolean {
//     if (this.allDescendantsChecked(criterion)) {
//       return true;
//     }
//     return criterion.checked_;
//   }
//
//   public allDescendantsChecked(criterion: CriterionInterface): boolean {
//     if (criterion.childCriterions !== undefined && criterion.childCriterions.length > 0) {
//       for (const child of criterion.childCriterions) {
//         if (child.subType !== 'temporal' && child.subType !== 'predictive' && !child.checked_) {
//           return false;
//         }
//       }
//       if (criterion.childCriterions.length > 4 || this.criterionKey !== 'topics') {
//         return true;
//       }
//     }
//     return false;
//   }
//
//   public onSelectedChild(child: CriterionInterface, criterion: CriterionInterface, criterionChild: CriterionInterface) {
//     if (this.criterionKey === 'topics') {
//       if (child.lead === undefined && child.checked_ === false) {
//         criterion.checked_ = false;
//         for (const sub of child.childCriterions) {
//           if (sub.lead !== undefined) {
//             // sub.lead.checked = false;
//             sub.checked_ = false;
//           }
//         }
//         for (const sub of criterion.childCriterions) {
//           if (sub.lead !== undefined) {
//             // sub.lead.checked = false;
//             sub.checked_ = false;
//           }
//         }
//       }
//     }
//
//   }
//
//   /**
//    * Load data. Used by PaginatedListComponent
//    */
//   protected load(): Observable<ArticlesInterface> {
//     if (this.sdate && this.edate) {
//       this.articlesService.setQuery(this.query);
//     }
//
//     return this.articlesService.articlesObj$;
//   }
//
//   /**
//    * Get data and count. Used by PaginatedListComponent
//    * @param articles ArticlesInterface
//    */
//   protected getDataTypedAndCountAfterLoad(articles: ArticlesInterface): { data: Array<ArticleInterface>, count: number } {
//     if (typeof (articles) !== 'undefined') {
//       this.running = false;
//       this.articlesCount = articles.articlesCount;
//       this.matPaginator.length = this.articlesCount;
//       if (articles.keywordsCount.length > 0) {
//         this.keywordsCount = articles.keywordsCount;
//         this.keywordsCount.sort((a, b) => a.keyword.localeCompare(b.keyword));
//       } else {
//         this.keywordsCount = [];
//       }
//
//       if (articles.locationsCount.length > 0) {
//         this.locationsCount = articles.locationsCount;
//         this.locationsCount.sort((a, b) => a.label.localeCompare(b.label));
//       } else {
//         this.locationsCount = [];
//       }
//       this.allLocationsSelected = this.isAllKeysSelected(this.locationsCount);
//
//       if (articles.sectorsCount.length > 0) {
//         this.sectorsCount = articles.sectorsCount;
//         this.sectorsCount.sort((a, b) => a.label.localeCompare(b.label));
//       } else {
//         this.sectorsCount = [];
//       }
//       this.allSectorsSelected = this.isAllKeysSelected(this.sectorsCount);
//
//       if (articles.topicsCount.length > 0) {
//         this.topicsCount = articles.topicsCount;
//         this.topicsCount.sort((a, b) => a.label.localeCompare(b.label));
//       } else {
//         this.topicsCount = [];
//       }
//       this.allTopicsSelected = this.isAllKeysSelected(this.topicsCount);
//
//       if (articles.activity_typesCount.length > 0) {
//         this.activity_typesCount = articles.activity_typesCount;
//         this.activity_typesCount.sort((a, b) => a.label.localeCompare(b.label));
//       } else {
//         this.activity_typesCount = [];
//       }
//       this.allActivityTypesSelected = this.isAllKeysSelected(this.activity_typesCount);
//
//       if (articles.company_sizesCount.length > 0) {
//         this.company_sizesCount = articles.company_sizesCount;
//         this.company_sizesCount.sort((a, b) => a.label.localeCompare(b.label));
//       } else {
//         this.company_sizesCount = [];
//       }
//       this.allCompanySizesSelected = this.isAllKeysSelected(this.company_sizesCount);
//
//       return { data: articles.articles_, count: this.articlesCount };
//     } else {
//       return { data: [], count: 0 };
//     }
//   }
//
//   public saveSearch() {
//     // label
//     this.searchesService.selectedCriterionsObj['label'] = this.searchNameInput.nativeElement.value ? this.searchNameInput.nativeElement.value : 'Nom de recherche';
//
//     if (this.search !== undefined) {
//       this.search.data = this.searchesService.selectedCriterionsObj;
//       this.searchesService.editMySearch(this.search.searchId, this.search.data).subscribe(
//         () => {
//           const id = this.search.searchId;
//           this.resetValues();
//           // this.navController.navigateForward('/app/business-signals?search=' + id);
//           Utils.navigate('/app/my-searches', this.navController);
//           // this.navController.navigateForward('/app/my-searches');
//         },
//         (error: Error | ErrorStatus) => {
//           // let errorStr: string = this.translateService.instant('EDIT_SEARCH_ERROR');
//           // if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
//           //   errorStr += `(${this.translateService.instant(error.message)})`;
//           // }
//           // this.toastService.simple(errorStr, { color: 'toasterror' });
//         }
//       );
//     } else {
//       this.searchesService.selectedCriterionsObj['alertState'] = true;
//       this.searchesService.addMySearch(this.searchesService.selectedCriterionsObj).subscribe(
//         (value) => {
//           this.resetValues();
//           // this.navController.navigateForward('/app/business-signals?search=' + Number(value.body));
//           Utils.navigate('/app/my-searches', this.navController);
//           // this.navController.navigateForward('/app/my-searches');
//         },
//         (error: Error | ErrorStatus) => {
//           // let errorStr: string = this.translateService.instant('ADD_SEARCH_ERROR');
//           // if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
//           //   errorStr += `(${this.translateService.instant(error.message)})`;
//           // }
//           // this.toastService.simple(errorStr, { color: 'toasterror' });
//         }
//       );
//     }
//   }
//
//   private resetValues(): void {
//     this.searchesService.selectedCriterionsObj = {
//       'label': '',
//       'searchType': '',
//       'locations': [],
//       'sectors': [],
//       'topics': [],
//       'activityTypes': [],
//       'companySizes': [],
//       'keywords': [],
//       'keywords_must_exact': [],
//       'keywords_should': [],
//       'keywords_must': [],
//       'keywords_must_not': [],
//       'alertState': true
//     };
//     this.displayedForm = 'criterions';
//
//     this.search = undefined;
//     this.searchesService.searchToEdit = undefined;
//     this.searchesService.editMode = false;
//     this.editMode = false;
//   }
//
//   public getCityTextAndSetId(city: CitySuggestion): string {
//     if (city.id >= 0) {
//       return city.name + ' (' + city.zip + ')';
//     } else {
//       return city.name;
//     }
//   }
//
//   setMyPosition() {
//     const geolocationOptions: GeolocationOptions = {
//       maximumAge: 3000, timeout: 5000, enableHighAccuracy: true
//     };
//     // this.geolocation.getCurrentPosition(geolocationOptions)
//     //     .then((geoposition: Geoposition) => {
//     //       // set lat lng for the map.
//     //       this.lat = geoposition.coords.latitude;
//     //       this.lng = geoposition.coords.longitude;
//     //     }).catch((error: any) => {
//     //       if (error.code === 1) {
//     //         // permission denied
//     //         this.toastService.simple(this.translateService.instant('GEOLOCATION_PERMISSION_DENIED'), { color: 'toasterror', duration: 3000 });
//     //       } else if (error.code === 2) {
//     //         // Position unavailable
//     //         this.toastService.simple(this.translateService.instant('GEOLOCATION_POSITION_UNAVAILABLE'), { color: 'toasterror', duration: 3000 });
//     //       } else if (error.code === 3) {
//     //         // Timeout
//     //         this.toastService.simple(this.translateService.instant('GEOLOCATION_TIMEOUT'), { color: 'toasterror', duration: 3000 });
//     //       }
//     // });
//   }
//
//   geoFilterClick() {
//     if (this.cityForm.get('cityInput').value == null || this.cityForm.get('cityInput').value.toString().length === 0) {
//       const myPosition: CitySuggestion = new class implements CitySuggestion {
//         id: number;
//         name: string;
//         zip: string;
//       };
//       myPosition.name = 'Autour de moi';
//       myPosition.zip = '';
//       myPosition.id = -1;
//       this.cityOptions = [];
//       this.cityOptions.push(myPosition);
//     }
//   }
//
//   private initCity(value: CitySuggestion) {
//     this.setCityId(value);
//     this.cityFilter = this.getCityTextAndSetId(value);
//   }
//
//   setCityId(city: CitySuggestion) {
//     this.cityId = city.id;
//   }
//
//
//   returnZero() {
//     return 0;
//   }
//
//   selectDeselectAll() {
//     if (this.allChecked()) {
//       for (const value of this.childrenCriterions) {
//         value.checked_ = false;
//         this.onCheckParentChildren(value, false);
//       }
//     } else {
//       this.childrenCriterions.forEach(value => {
//         value.checked_ = true;
//         this.onCheckParentChildren(value, true);
//       });
//     }
//   }
//
//   allChecked() {
//     let checked: boolean = true;
//     for (const child of this.childrenCriterions) {
//       if (!child.checked_) {
//         checked = false;
//         break;
//       }
//     }
//     return checked;
//   }
//
//     filterCriterions(filter: string) {
//       if (filter.length > 2) {
//         // console.log(this.filterCriterion);
//         // console.log(this.toExpand);
//         this.filterCriterion = filter;
//         this.toExpand = new Set<CriterionInterface>();
//       }
//     }
//
//   filterChildren(criterion: CriterionInterface, currentLevel: number) {
//       let childrenFilter: boolean = false;
//       let parentExpanded: boolean = false;
//       if (criterion.childCriterions !== undefined && criterion.childCriterions.length > 0) {
//         for (const child of criterion.childCriterions) {
//           if (child.subType !== 'temporal' && child.subType !== 'predictive' && ((currentLevel < 3 && this.criterionKey === 'sectors') || this.criterionKey !== 'sectors')) {
//             if (child.label.toLowerCase().includes(this.filterCriterion.toLowerCase())) {
//               this.toExpand.add(criterion);
//               parentExpanded = true;
//               break;
//             }
//           }
//           childrenFilter = this.filterChildren(child, currentLevel + 1) || childrenFilter;
//
//         }
//       }
//     if (childrenFilter) {
//       this.toExpand.add(criterion);
//     }
//     return childrenFilter || parentExpanded || criterion.label.toLowerCase().includes(this.filterCriterion.toLowerCase());
//   }
//
//   trackByFn(index, item) {
//     return item.id;
//   }
//
//   /**
//    * Searches Help Dialog Box
//    */
//   openSearchesHelpDialog() {
//     const searchesHelpDialogRef = this.searchesHelpDialog.open(MySearchesHelpDialogComponent);
//     searchesHelpDialogRef.afterClosed().subscribe(result => {
//       console.log(`Searches Help Dialog result: ${result}`);
//     });
//   }
//
//
//   cityChange($event: any) {
//     if ($event.target.value || $event.target.value.length <= 0) {
//       this.cityId = undefined;
//     }
//   }
//
//   /**
//    * Sélection Désélection de tous les éléments d'un critère donné
//    * @param keys
//    * @param allKeysSelected
//    */
//   checkUncheckAllKeys(keys: Array<any>, allKeysSelected: boolean, type: string) {
//
//     for (let i = 0; i < keys.length; i++) {
//       const key = keys[i];
//       // Unselect all
//       if (!allKeysSelected) {
//         if ('id' in key) {
//           this.offCriterions.push(key.id);
//           const index = this.criterions.indexOf(key.id);
//           if (index > -1) {
//             this.criterions.splice(index, 1);
//           }
//         } else if ('keyword' in key) {
//           this.offKeywords.push(key.keyword);
//           const index = this.keywords.indexOf(key.keyword);
//           if (index > -1) {
//             this.keywords.splice(index, 1);
//           }
//         }
//       } else {
//         if ('id' in key) {
//           const index = this.offCriterions.indexOf(key.id);
//           if (index > -1) {
//             this.offCriterions.splice(index, 1);
//           }
//           this.searchesService.selectedCriterionsObj[type].push(key.id);
//           this.criterions.push(key.id);
//         } else if ('keyword' in key) {
//           const index = this.offKeywords.indexOf(key.keyword);
//           if (index > -1) {
//             this.offKeywords.splice(index, 1);
//           }
//           this.keywords.push(key.keyword);
//         }
//       }
//     }
//     // Reset page index
//     this.page = 1;
//     this.matPaginator.pageIndex = 0;
//
//     this.checkResults();
//     // this.articlesService.setQuery(this.query);
//   }
//
//   public matSlideToggleChange(event: MatSlideToggleChange, criterion: any, type: string) {
//
//     this.matSlideToggleDefaultValue = event.checked;
//
//     // If event is checked, we add the id, else we remove it
//     if (!event.checked) {
//       if ('id' in criterion) {
//         this.offCriterions.push(criterion.id);
//         const index = this.criterions.indexOf(criterion.id);
//         if (index > -1) {
//           this.criterions.splice(index, 1);
//         }
//       } else if ('keyword' in criterion) {
//         this.offKeywords.push(criterion.keyword);
//         const index = this.keywords.indexOf(criterion.keyword);
//         if (index > -1) {
//           this.keywords.splice(index, 1);
//         }
//       }
//     } else {
//       if ('id' in criterion) {
//         const index = this.offCriterions.indexOf(criterion.id);
//         if (index > -1) {
//           this.offCriterions.splice(index, 1);
//         }
//         this.searchesService.selectedCriterionsObj[type].push(criterion.id);
//         this.criterions.push(criterion.id);
//       } else if ('keyword' in criterion) {
//         const index = this.offKeywords.indexOf(criterion.keyword);
//         if (index > -1) {
//           this.offKeywords.splice(index, 1);
//         }
//         this.keywords.push(criterion.keyword);
//       }
//     }
//
//     // Reset page index
//     this.page = 1;
//     this.matPaginator.pageIndex = 0;
//
//     this.checkResults();
//     // this.articlesService.setQuery(this.query);
//   }
//
//   arrayToStringWithLineBreaks(keywords_must: Array<string>) {
//     return keywords_must.join('<br>');
//   }
//
//   public isCriterionOn(criterionId: number): boolean {
//     return !this.offCriterions.some(offCriterion => JSON.stringify(offCriterion) === JSON.stringify(criterionId));
//   }
//
//   public isKeywordOn(keyword: string): boolean {
//     return !this.offKeywords.some(offKeyword => JSON.stringify(offKeyword) === JSON.stringify(keyword));
//   }
//
//   isAllKeysSelected(keys: Array<any>): boolean {
//     for (let i = 0; i < keys.length; i++) {
//       if (this.offCriterions.includes(keys[i].id)) {
//         return false;
//       }
//     }
//     return true;
//   }
//
//
//   convertIterableToArray(array: any): Array<CriterionInterface> {
//     const returnArray: Array<CriterionInterface> = [];
//     for (const element of array) {
//       returnArray.push(element);
//     }
//     return returnArray;
//   }
//
//   close() {
//     this.closeSettingsMenu.emit();
//   }
//
//   updateCheckedCriterionsList(typeCriterion: string, criterion: CriterionInterface, isChecked: boolean) {
//     const data = {
//       typeCriterion: typeCriterion,
//       criterion: criterion,
//       add: isChecked
//     };
//     this.updateCriterionList.emit(data);
//     console.log('articleQueryInterface = ');
//     console.log(this.query);
//     console.log('CURRENTSEARCH =');
//     console.log(this.currentSearch);
//     this.query.criterions.push(41848);
//
//     this.articlesService.setQuery(this.query);
//     // this.checkResults();
//   }
//
//
 }
