import { FormControl } from '@angular/forms';
import { XAddress } from '../../components/x-form/x-address/XAddress';

export class AddressValidator {

  /**
   * [isValidEmail description]
   * @param  {FormControl} formControl [description]
   * @return {[type]}                  [description]
   */
  public static isValidAddress(formControl: FormControl) {

    // field not required but address validation => if value = '' and not required error => no error
    if (formControl.value === '') {
      if (typeof formControl.errors !== 'undefined' && formControl.errors && formControl.errors.address &&
        (typeof formControl.errors.required === 'undefined' || formControl.errors.required === null || !formControl.errors.required)) {
        return null;
      }
    } else {
      if (formControl.value instanceof XAddress) {
        const xAddress: XAddress = formControl.value as XAddress;
        if (xAddress.error) {
          return { 'address': xAddress.error };
        } else {
          return null;
        }
      } else {
        return { 'address': true };
      }
    }
  }
}
