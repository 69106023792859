<button *ngIf="!article.favorite && !isFromArticleList" mat-icon-button (click)="toggleFavorite(); $event.stopPropagation();">
  <mat-icon class="greyColor">star_border</mat-icon>
<!--  <mat-icon *ngIf="article.favorite" color="accent">start</mat-icon>-->
</button>

<button *ngIf="!mediaQueryService.mobileQuery.matches && article.favorite && !isFromArticleList" #button style="height: 40px;z-index:9;" mat-icon-button (click)="$event.stopPropagation();" [matMenuTriggerFor]="menu"  #levelOneTrigger="matMenuTrigger" (mouseenter)="buttonEnter(levelOneTrigger, mediaQueryService.mobileQuery.matches)" (mouseleave)="buttonLeave(levelOneTrigger, button)">
  <!--      <button style="height: 40px;" mat-icon-button (click)="$event.stopPropagation();openMailModal();" [matMenuTriggerFor]="menu"  *ngIf="!mediaQueryService.mobileQuery.matches">-->
  <!--      #trigger="matMenuTrigger" (mouseover)="trigger.openMenu()" (mouseout)="$event.stopPropagation();trigger.openMenu()">-->

  <mat-icon class="greyColor">star_rate</mat-icon>
</button>


<button *ngIf="!article.favorite && isFromArticleList" [class.favorite-container]="isFromArticleList" mat-icon-button (click)="toggleFavorite();">
    <mat-icon class="greyColor">star_border</mat-icon>
    <!--  <mat-icon *ngIf="article.favorite" color="accent">start</mat-icon>-->
    <span>Ajouter aux favoris</span>
</button>


<button *ngIf="!mediaQueryService.mobileQuery.matches && article.favorite && isFromArticleList" [class.favorite-container]="isFromArticleList" #button style="height: 40px;z-index:9;" mat-menu-item
        [matMenuTriggerFor]="menu"
        #levelOneTrigger="matMenuTrigger"
        (mouseenter)="buttonEnter(levelOneTrigger, mediaQueryService.mobileQuery.matches)"
        (mouseleave)="buttonLeave(levelOneTrigger, button)">
    <!--      <button style="height: 40px;" mat-icon-button (click)="$event.stopPropagation();openMailModal();" [matMenuTriggerFor]="menu"  *ngIf="!mediaQueryService.mobileQuery.matches">-->
    <!--      #trigger="matMenuTrigger" (mouseover)="trigger.openMenu()" (mouseout)="$event.stopPropagation();trigger.openMenu()">-->

    <mat-icon class="greyColor">star_rate</mat-icon>
    <span>Gestion des favoris</span>
</button>

<mat-menu #menu="matMenu" direction="down" yPosition="below" [hasBackdrop]="false">
  <div (mouseenter)="menuenter()" (mouseleave)="menuLeave()" style="max-height: 270px;">
      <ng-container *ngFor="let favorite of _favoriteFolders">
          <button *ngIf="article.favoriteFolder === favorite.folderID" mat-menu-item style="background-color: #e3e4e8">
              <i>
                  Actuellement dans "{{favorite.libelle}}"
              </i>
          </button>
      </ng-container>
    <button mat-menu-item (click)="deleteFavorite()">
      <b>Retirer des favoris</b>
    </button>
    <ng-container *ngFor="let favorite of _favoriteFolders">
      <button *ngIf="article.favoriteFolder !== favorite.folderID" mat-menu-item (click)="changeFavoriteFolder(favorite, $event)">
        Déplacer vers {{favorite.libelle}}
      </button>
    </ng-container>
  </div>
</mat-menu>
