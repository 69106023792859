import {Component, Inject} from '@angular/core';
import {SalesPersonDetailsInterface} from '../../../interfaces/session/sales-person-details.interface';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalService} from '../../../services/modal/modal.service';
import {SnackbarService} from '../../../services/snackbar/snackbar.service';
import {MailsService} from '../../../services/mails/mails.service';

@Component({
  selector: 'app-fe-contact-commercial-modal',
  templateUrl: './fe-contact-commercial-modal.component.html',
  styleUrls: ['./fe-contact-commercial-modal.component.scss']
})
export class FeContactCommercialModalComponent {

  protected salesPersonDetails: SalesPersonDetailsInterface = {};
  protected title: string = 'Vous souhaitez faire évoluer votre profilage, etc...';
  private feature: string = 'other';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private modalService: ModalService,
              private snackbarService: SnackbarService,
              private mailService: MailsService) {
    if (data != null) {
      if (data.salesPersonDetails != null) {
        this.salesPersonDetails = data.salesPersonDetails;
      }

      if (data.title != null) {
        this.title = data.title;
      }

      if (data.feature != null) {
        this.feature = data.feature;
      }
    }
  }

  protected getCommercialName(): string {
    let commercialName: string = '';
    if (this.salesPersonDetails.salesPersonFirstname != null) {
      commercialName += this.salesPersonDetails.salesPersonFirstname.trim();
    }
    if (this.salesPersonDetails.salesPersonName != null) {
      if (commercialName.length > 0) {
        commercialName += ' ';
      }
      commercialName += this.salesPersonDetails.salesPersonName;
    }

    return commercialName;
  }

  protected askForContact(): void {
    this.modalService.textAreaModal('Demande de contact', 'Une demande de contact va être envoyée à ' +
        'votre commercial dédié.<br>' + 'Voulez-vous continuer ?', args => {
      this.mailService.contactSalespersonn(this.feature, args).subscribe(value => {
        this.snackbarService.showSuccessSnackbar('Demande de contact envoyé',
            'Votre commercial dédié vous recontactera sous 48h.');
      });
    }, 'Vous pouvez ajouter un commentaire à votre demande.');
  }

}
