<div style="display: flex; flex-direction: column; height: 100%; background-color: white; border: 1px solid #879BA8; border-radius: 8px; padding: 32px;">
    <div class="criterionTypeTitleTour">
        <p style="font-size: 24px; font-weight: 600; margin: 0 0 12px;">
            {{getCriterionTypeTitle()}}
        </p>
        <p style="font-size: 16px;">{{getCriterionTypeSubTitle()}}</p>

        <fe-button [text]="getGuideLeadBtnText()" type="SECONDARY" icon="true" [iconType]="IconType.tour"
                   (click)="launchTemporalTour()" smallButton="true" style="margin-bottom: 16px; display: flex;"
                   bold="true" [class.displayNone]="this.currentTypeCriterion != 'topics'" class="feTourBorderBtn"/>

        <fe-button text="Guide sur l'utilisation de la recherche mots clés" type="SECONDARY" icon="true"
                   (click)="launchTour.emit(this.currentTypeCriterion);" class="feTourBorderBtn"
                   style="margin-bottom: 16px; display: flex;" [iconType]="IconType.tour" smallButton="true"
                   bold="true" [class.displayNone]="this.currentTypeCriterion != 'keywords'"/>
    </div>

    <ng-container *ngIf="currentTypeCriterion === 'keywords'">
        <mat-form-field class="keywordsInput">
            <mat-label>Tous les mots suivants</mat-label>
            <mat-chip-grid #mustGrid style="max-height: 115px;overflow: auto;">
                <mat-chip-row *ngFor="let keyword of keywords.must"
                  (removed)="remove(keyword, 'must')"
                  [editable]="true"
                  (edited)="edit(keyword, $event, 'must')">
                {{keyword}}
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
                <input placeholder="Ajouter vos mots-clés"
                       [matChipInputFor]="mustGrid"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       [matChipInputAddOnBlur]="addOnBlur"
                       (matChipInputTokenEnd)="add($event, 'must')"/>
            </mat-chip-grid>
        </mat-form-field>
        <div style="display: flex; align-items: center; gap: 8px; margin-bottom: 22px;">
            <span>Avec</span>
            <div style="border: 1px solid black; flex: 1;"></div>
        </div>
        <mat-form-field class="keywordsInput">
            <mat-label>Un des mots suivant</mat-label>
            <mat-chip-grid #shouldGrid style="max-height: 115px;overflow: auto;">
                <mat-chip-row *ngFor="let keyword of keywords.should"
                              (removed)="remove(keyword, 'should')"
                              [editable]="true"
                              (edited)="edit(keyword, $event, 'should')">
                    {{keyword}}
                    <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip-row>
                <input placeholder="Ajouter vos mots-clés"
                       [matChipInputFor]="shouldGrid"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       [matChipInputAddOnBlur]="addOnBlur"
                       (matChipInputTokenEnd)="add($event, 'should')"/>
            </mat-chip-grid>
        </mat-form-field>
        <div style="display: flex; align-items: center; gap: 8px; margin-bottom: 22px;">
            <span>Avec</span>
            <div style="border: 1px solid black; flex: 1;"></div>
        </div>
        <mat-form-field class="keywordsInput">
            <mat-label>Aucun des mots suivants</mat-label>
            <mat-chip-grid #mustNotGrid style="max-height: 115px;overflow: auto;">
                <mat-chip-row *ngFor="let keyword of keywords.mustNot"
                              (removed)="remove(keyword, 'mustNot')"
                              [editable]="true"
                              (edited)="edit(keyword, $event, 'mustNot')">
                    {{keyword}}
                    <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip-row>
                <input placeholder="Ajouter vos mots-clés"
                       [matChipInputFor]="mustNotGrid"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       [matChipInputAddOnBlur]="addOnBlur"
                       (matChipInputTokenEnd)="add($event, 'mustNot')"/>
            </mat-chip-grid>
        </mat-form-field>
    </ng-container>

    <ng-container *ngIf="isCriterionType()">
<!--        <ion-searchbar show-clear-button="never" matInput placeholder="Rechercher dans la liste" (input)="filterCriterions($event.target.value)"></ion-searchbar>-->

        <fe-searchbar [(value)]="currentFilter" style="margin-bottom: 10px" (valueChange)="filterCriterions($event)"/>

        <ng-container *ngIf="currentTypeCriterion === 'locations' && geolocSearchAvailable">
            <div *ngIf="!geoloc || geoloc.geolocDistanceRadius === null" style="display: flex;flex-direction: row; padding: 5px 0 20px 0;">
                <fe-icon [iconType]="IconType.localisations" class="categoryIcon"/>
                <a style="cursor:pointer;margin-left: 5px; font-weight: bold; " (click)="openGeoloc()">Utiliser une géolocalisation</a>
            </div>
            <div *ngIf="geoloc && geoloc.geolocDistanceRadius != null" style="padding: 5px 0 10px 0;">
                <div style="display: flex;flex-direction: row;">
                    <fe-icon [iconType]="IconType.localisations" class="categoryIcon"/>
                    <div style="flex:1; font-weight: bold; text-decoration: underline; margin-left: 5px;">Géolocalisation appliquée</div>
                </div>
                <div style="margin-bottom: 10px;margin-top: 5px;display: flex; flex-direction: row; align-items: center; color:#475A66;"><div>{{geoloc.cityName + ' - Rayon de ' + geoloc.geolocDistanceRadius + 'km'}}</div><div class="CloseButton"><mat-icon style="cursor: pointer" (click)="removeGeoloc()">close</mat-icon></div></div>
            </div>
        </ng-container>

        <mat-checkbox (change)="selectDeselectAll($event)" [checked]="allChecked()" class="checkAll">
            Sélectionner / Désélectionner tout
        </mat-checkbox>

        <mat-accordion style="overflow: auto; flex: 1" multi="true">
            <app-criterion-item-panel *ngFor="let criterion of currentCriterions" [leadsAvailable]="leadsAvailable" [criterionType]="currentTypeCriterion" [criterion]="criterion" (emitter)="changeCriterionSelection()" [filter]="currentFilter">

            </app-criterion-item-panel>
        </mat-accordion>
    </ng-container>
</div>
