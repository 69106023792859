import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SearchInterface} from '../../../../interfaces/search/search.interface';
import {ColorType, IconType} from '../../../parts/fe-icon/fe-icon.component';

@Component({
  selector: 'fe-recherche-line',
  templateUrl: './fe-recherche-line.component.html',
  styleUrls: ['./fe-recherche-line.component.scss']
})
export class FeRechercheLineComponent {
  @Input() search: SearchInterface;
  @Input() mainSearch: boolean = false;
  @Output() emitter = new EventEmitter();
  protected readonly IconType = IconType;

  protected loadSearch(): void {
    this.emitter.emit();
  }
  @Input() searchList: SearchInterface[];
  protected readonly ColorType = ColorType;
}
