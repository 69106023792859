<div class="feSendedMailModal feModal">
    <div class="feModalHeader">
        <span class="feModalTitle">{{mailHistory.articleCounts > 1 ? 'Articles partagés de First ECO'
            : 'Article partagé de First ECO'}}</span>
        <div class="feModalCloseButton">
            <span mat-dialog-close>Fermer</span>
            <mat-icon mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="feModalContent">
        <span class="bigText" style="margin-bottom: 16px;">Envoyé le {{formatDate(mailHistory.date, 'dd/MM/yyyy à HH:mm', 'fr_FR')}}</span>
        <div class="adresseContainer">
            <span class="bigText" style="margin: auto 0;">Partagé à :</span>
            <span class="moderateText adresse" *ngFor="let adress of mailHistory.recipients">
                {{adress}}
            </span>
        </div>

        <div class="mailContainer" [innerHTML]="adaptTableWidth(mailHistory.html) | safe: 'html'"></div>
    </div>
</div>