import {Component, Input} from '@angular/core';
import {MatMenuTrigger} from "@angular/material/menu";

@Component({
  selector: 'fe-sub-menu',
  templateUrl: './fe-sub-menu.component.html',
  styleUrls: ['./fe-sub-menu.component.scss']
})
export class FeSubMenuComponent {
  @Input() icon: string = 'home';
  @Input() text: string = 'Nom du bouton';
  @Input() color: string = 'DEFAULT';
  @Input() id: string = '';

  constructor() {
    if (this.id = '')
      this.id = this.text.replace(/\s/g, "") + '-' + Math.floor(10000);
  }

  openMyMenu(menuTrigger: MatMenuTrigger) {
    menuTrigger.openMenu();
  }
}
