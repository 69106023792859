import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {LoadingDisplayerComponent} from '../modals/loading-displayer/loading-displayer.component';
import {AlertController, ModalController} from '@ionic/angular';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {MonitoredArticleAttachmentInterface} from '../../interfaces/monitored-article-attachments/monitored-article-attachment.interface';
import {SelectionModel} from '@angular/cdk/collections';
import {CustomerDetailsInterface} from '../../interfaces/session/customer-details.interface';
import {UserService} from '../../services/user/user.service';
import {ArticleMonitoringService} from '../../services/article-monitoring/article-monitoring.service';
import {ErrorStatus} from '../../classes/ErrorStatus.class';
import {ToastService} from '../../services/toast/toast.service';
import {takeUntil} from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import {AuthService} from '../../services/auth/auth.service';
import {FormControl} from '@angular/forms';
import {MonitoredArticleInterface} from '../../interfaces/monitored-articles/monitored-article.interface';
import {SnackbarService} from '../../services/snackbar/snackbar.service';
import {ModalService} from "../../services/modal/modal.service";
import {IconType} from "../parts/fe-icon/fe-icon.component";
import {FeLoaderComponent} from "../fe-loader/fe-loader.component";

@Component({
    selector: 'app-monitoring-attachment-sharing-modal',
    templateUrl: './monitoring-attachment-sharing-modal.component.html',
    styleUrls: ['./monitoring-attachment-sharing-modal.component.scss'],
})
export class MonitoringAttachmentSharingModalComponent implements OnInit {

    @Output() confirm = new EventEmitter<any>();

    protected unsubscribe$: Subject<any> = new Subject<any>();
    customerToken = '';
    companyCustomers: { user_id: string, name: string, email: string }[] = [];

    @Input() articleId: number;
    @Input() articleMonitoringId: number;
    @Input() monitoredArticle: MonitoredArticleInterface;

    fileInput;
    inProgressFileCount: number;
    loadingModal;
    public selectionModel: SelectionModel<CustomerDetailsInterface> = new SelectionModel<CustomerDetailsInterface>(true, []);

    monitoredArticleAttachments: Array<MonitoredArticleAttachmentInterface> = new Array<MonitoredArticleAttachmentInterface>();

    public isAttachmentPrivate = true;
    public attachmentStatusForm = new FormControl();

    constructor(public dialog: MatDialog,
                private modalService: ModalService,
                private articleMonitoringService: ArticleMonitoringService,
                public dialogRef: MatDialogRef<MonitoringAttachmentSharingModalComponent>,
                private snackBarService: SnackbarService,
                private alertController: AlertController,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private authService: AuthService
    ) {
        this.articleId = data.articleId;
        this.articleMonitoringId = data.articleMonitoringId;
        this.monitoredArticle = data.monitoredArticle;
    }

    ngOnInit() {
        // Initialiser les company customers avec les clients suivant l'article monitoré
        this.articleMonitoringService.getCustomersMonitoringArticle({articleId: '' + this.articleId + ''})
            .subscribe(
                (entrepriseCustomers) => {
                    if (entrepriseCustomers !== undefined) {
                        for (let i = 0; i < entrepriseCustomers.customers.length; i++) {
                            const userid = entrepriseCustomers.customers[i].user_id;
                            const name = entrepriseCustomers.customers[i].firstname + ' ' + entrepriseCustomers.customers[i].lastname;
                            const email = entrepriseCustomers.customers[i].email;
                            this.companyCustomers.push({'user_id': userid, 'name': name, 'email': email});
                        }
                    }
                }
            );

    }

    public isRoleCom() {
        return this.authService.roleCom;
    }

    public onAttachmentStatusChange($event) {
        this.isAttachmentPrivate = !$event;
    }

    handleFileInput(files: FileList) {
        if (files != null && files.length > 0) {
            this.inProgressFileCount = files.length;
            this.loadingModal = this.dialog.open(FeLoaderComponent, {disableClose: true,
                panelClass: 'customLoader'});
            for (let i = 0; i < files.length; i++) {
                this.uploadFileToActivity(files[i]);
            }
        }
    }

    openFile(attachment) {
        if (!this.isRoleCom()) {
            this.articleMonitoringService.downloadMonitoredArticleAttachment({
                articleId: attachment.articleId + '',
                monitoringAttachmentId: attachment.id + ''
            }).subscribe(value => {
                FileSaver.saveAs(value.body, attachment.filename);
            });
        }
    }

    uploadFileToActivity(file: File) {
        const customers = [];
        // Limit size to 5Mo
        if ((file.size / (1024 * 1024)) > 5) {
            this.loadingModal.close();
            this.fileInput = null;
            const errorStr: string = 'La taille des pièces-jointes est limitée à 5Mo.';
            this.snackBarService.showDangerSnackbar(errorStr, errorStr);
            return;
        }
        for (let i = 0; i < this.selectionModel.selected.length; i++) {
            customers.push(this.selectionModel.selected[i].user_id);
        }
        this.articleMonitoringService.addMonitoredArticleAttachment(this.articleId, this.articleMonitoringId, this.customerToken, customers, this.isAttachmentPrivate, file).subscribe((value) => {
                this.fileInput = '';

                this.inProgressFileCount--;
                if (this.inProgressFileCount <= 0) {
                    this.loadingModal.close();
                }
                this.monitoredArticleAttachments.push(value.body);
            },
            (error: Error | ErrorStatus) => {
                let errorStr: string = 'Une erreur est survenue lors de l\'ajout de cette pièce-jointe';
                if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
                    errorStr += `(${error.message})`;
                }
                this.snackBarService.showDangerSnackbar(errorStr, errorStr);
            }
        );
    }

    public deleteAttachment(attachment: MonitoredArticleAttachmentInterface): void {
        this.articleMonitoringService.removeMonitoredArticleAttachment(attachment.id, this.articleId, this.customerToken)
            .pipe(
                takeUntil(this.unsubscribe$)
            ).subscribe(
            () => {
                this.snackBarService.showSuccessSnackbar('Pièce-jointe supprimée avec succès', 'Pièce-jointe supprimée avec succès');
                // Refresh data
                this.monitoredArticleAttachments.forEach((value, index) => {
                    if (value.id === attachment.id) {
                        this.monitoredArticleAttachments.splice(index, 1);
                    }
                });
            },
            (error: Error | ErrorStatus) => {
                let errorStr: string = 'Une erreur est survenue lors de la suppression de cette pièce-jointe';
                if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
                    errorStr += `(${error.message})`;
                }
                this.snackBarService.showDangerSnackbar(errorStr, errorStr);
            }
        );
    }

    public async deleteMonitoredArticleAttachment(attachment: MonitoredArticleAttachmentInterface): Promise<any> {
        this.modalService.confirmModal("Suppression d'une pièce jointe", "Confirmez-vous la suppression de cette pièce-jointe ?", () => {
            this.deleteAttachment(attachment);
        });
    }

    public close(): void {
        this.confirm.emit({attachmentsAdded: this.monitoredArticleAttachments.length > 0});
        this.dialogRef.close({attachmentsAdded: this.monitoredArticleAttachments.length > 0});
    }

    // public ngOnDestroy() {
    //     this.unsubscribe$.next();
    //     this.unsubscribe$.complete();
    // }
    protected readonly IconType = IconType;
}
