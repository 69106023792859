import {
    AfterViewInit,
    Component, ElementRef,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild
} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {ArticleInterface} from 'src/app/interfaces/articles/article.interface';
import {MediaQueryService} from 'src/app/services/media-query/media-query.service';
import {AlertController, ModalController} from '@ionic/angular';
import {ReadingArticleModal} from '../../modals/reading-article/reading-article.modal';
import {ArticlesService} from 'src/app/services/articles/articles.service';
import {takeUntil} from 'rxjs/operators';
import {FavoritesService} from 'src/app/services/favorites/favorites.service';
import {AuthService} from '../../../services/auth/auth.service';
import {Utils} from '../../../classes/Utils';
import {AddressesService} from '../../../services/addresses/addresses.service';
import {AddressesInterface} from '../../../interfaces/addresses/addresses';
import {AddressInterface} from '../../../interfaces/address/address';
import {ToastService} from '../../../services/toast/toast.service';
import {EmailArticleModal} from '../../modals/email-article/email-article.modal';
import {AppPreferencesService} from 'src/app/services/app-preferences/app-preferences.service';
import {AddEditAddressModal} from '../../modals/address-add-edit/address-add-edit.modal';
import {UserService} from '../../../services/user/user.service';
import {SessionInterface} from '../../../interfaces/session/session.interface';
import {SupervisorMonitoringModalComponent} from '../../supervisor-monitoring-modal/supervisor-monitoring-modal.component';
import {ArticleMonitoringService} from 'src/app/services/article-monitoring/article-monitoring.service';
import {EntreprisesService} from '../../../services/entreprises/entreprises.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
    selector: 'article-list-item',
    templateUrl: 'article-list-item.component.html',
    styleUrls: ['article-list-item.component.scss'],
})
export class ArticleListItemComponent implements OnInit, OnDestroy {

  @Input() public article: ArticleInterface;
  @Input() public hasSectorsInSearch: boolean;
  @Input() public hasTopicsInSearch: boolean;
  @Input() public style: string; // big, medium or small
  @Input() public selectionModel: SelectionModel<number>;
  @Input() public selectionModel2: SelectionModel<string>;
  @Input() public from: string = 'article'; // article or favorite // display modal for article or favorite
  public _keywords: Array<string>;
  // public addresses$: BehaviorSubject<AddressesInterface> = this.addressesService.getAddressesSubject();
  @ViewChild('menu') menu;
    public articleContent: SafeHtml;

  @Input() public set keywords(value: Array<string>) {
    this._keywords = value;
  }

  public get keywords(): Array<string> {
    return this._keywords;
  }

  public askBeforeDeleteFavorite: boolean = false;
  public showMoreEntrepriseInfo: boolean = false;

  public locale: any; // prevent build error

  public isSupervisor: boolean;
  public isPerformance: boolean = false;
  private unsubscribe$: Subject<any> = new Subject<any>();
  public listAssociatedCustomers: {user_id: string, name: string}[] = [];
  public nbCollaborateurs;
  public shareArticleToolTip: String;
  public isLettrePersoMC = false;

  // Menu
  private enteredButton = false;
  private isMatMenuOpen = false;
  private isMatMenu2Open = false;
  private prevButtonTrigger;

  constructor(
    public mediaQueryService: MediaQueryService
    , private articlesService: ArticlesService
    , private modalController: ModalController
    , private alertController: AlertController
    , private favoritesService: FavoritesService
    , private appPreferencesService: AppPreferencesService
    , private authService: AuthService
    , private userService: UserService
    , public addressesService: AddressesService
    , public toastService: ToastService
    , private ren: Renderer2
    , private ngZone: NgZone
    , private articleMonitoringService: ArticleMonitoringService
    , private entrepriseService: EntreprisesService
    , protected sanitizer: DomSanitizer
    , private elem: ElementRef
    ) {
    }

  public ngOnInit(): void {
    // if this comp is call by my-favorite, this.from equal 'favorite' ==> askBeforeDeleteFavorite is true. (for ArticleToggleFavoriteComponent)
    this.askBeforeDeleteFavorite = this.from === 'favorite';

    if (this.style === 'list' && this.mediaQueryService.mobileQuery.matches) {
      this.style = 'small';
      this.appPreferencesService.set('articleListeStyle', this.style);
    }

    if (this.article.entreprises != null) {
      if (this.article.entreprises.length > 2) {
        this.showMoreEntrepriseInfo = true;
      }
      if (!this.showMoreEntrepriseInfo) {
        this.article.entreprises.forEach(entreprise => {
          if (entreprise.contacts && entreprise.contacts.length > 1) {
            this.showMoreEntrepriseInfo = true;
          }
          if (entreprise.etablissements && entreprise.etablissements.length > 1) {
            this.showMoreEntrepriseInfo = true;
          }
        });
      }
    }

    // Check the value of the article.read value and update it if needed
    this.articlesService.currentArticle.subscribe(
      (article: { id: number, read: boolean}) => {
        if (article?.read === undefined) {
            if (article?.id === this.article.id && !this.article.read) {
                this.article.read = true;
            } else if (article?.id === this.article.id && this.article.read) {
                this.article.read = false;
            }
        } else {
            if (article?.id === this.article.id && !this.article.read && article.read) {
                this.article.read = true;
            } else if (article?.id === this.article.id && this.article.read && !article.read) {
                this.article.read = false;
            }
        }

      }
    );

        // Retrieve Supervisor state
        this.userService.getSessionDatas()
            .subscribe(
                (sessionData: SessionInterface) => {
                    if (sessionData !== undefined) {
                        if (sessionData.productType === 'Lettre_MotsCles') {
                          this.isLettrePersoMC = true;
                        }
                        if (sessionData.customerDetails.isSupervisor !== null) {
                          this.isSupervisor = sessionData.customerDetails.isSupervisor;
                        }
                        if (sessionData.productOfferCode === 'OFFRE_3') {
                          this.isPerformance = true;
                        }
                      if (sessionData.companyCustomers !== undefined) {
                          this.nbCollaborateurs = sessionData.companyCustomers.length;
                      }
                   }
                }
            );
      if (this.article.monitoring.associatedCustomersRealName !== undefined) {
        for (let i = 0; i < this.article.monitoring.associatedCustomersRealName.length; i++) {
          this.listAssociatedCustomers.push({user_id: this.article.monitoring.associatedCustomers[i], name: this.article.monitoring.associatedCustomersRealName[i]});
        }
      }
      if (this.listAssociatedCustomers.length > 0) {
        if (this.article.monitoring.status === undefined) {
          this.shareArticleToolTip = 'Article suivi par :\n';
          for (let i = 0; i < this.listAssociatedCustomers.length; i++) {
            this.shareArticleToolTip += this.listAssociatedCustomers[i].name + ', ';
          }
          this.shareArticleToolTip = this.shareArticleToolTip.slice(0, -2);
        } else {
          this.shareArticleToolTip = 'Article également suivi par :\n';
          for (let i = 0; i < this.listAssociatedCustomers.length; i++) {
            this.shareArticleToolTip += this.listAssociatedCustomers[i].name + ', ';
          }
          this.shareArticleToolTip = this.shareArticleToolTip.slice(0, -2);
        }
      }

    /**
     * Insertion des liens des articles de références
     */
    setTimeout(() => {
        let pattern;
        let tag, tagCorpsExpanded;
        let articleCorps = this.article.corps;
        let articleCorpsExpanded = this.article.corps;

        for (let i = 0; i < this.article.referenceArticlesInfos?.length; i++) {
            pattern = '##' + this.article.referenceArticlesInfos[i].articleId + '##';
            tag = '<span> <a id="ref' + this.article.referenceArticlesInfos[i].articleId + '" class="ref-link">' + this.article.referenceArticlesInfos[i].text + '</a></span>';
            tagCorpsExpanded = '<span> <a id="ref' + this.article.referenceArticlesInfos[i].articleId + '_expanded" class="ref-link">' + this.article.referenceArticlesInfos[i].text + '</a></span>';
            articleCorps = articleCorps.replace(pattern, tag);
            articleCorpsExpanded = articleCorps.replace(pattern, tagCorpsExpanded);
        }

        let articleCorpsSpan = document.createElement('span');
        articleCorpsSpan.innerHTML = articleCorps.length > 200 ? this.getHighlightedText(articleCorps).slice(0, 200) + '...' : this.getHighlightedText(articleCorps);
        let articleCorpsSpanExpanded = document.createElement('span');
        articleCorpsSpanExpanded.innerHTML = this.getHighlightedText(articleCorpsExpanded);
        this.elem.nativeElement.querySelector('#articleCorps').appendChild(articleCorpsSpan);
        this.elem.nativeElement.querySelector('#articleCorpsExpanded').insertBefore(articleCorpsSpanExpanded, this.elem.nativeElement.querySelector('#articleCorpsExpanded').firstChild);

        for (let i = 0; i < this.article.referenceArticlesInfos?.length; i++) {
            let refArticleElem = this.elem.nativeElement.querySelector('#ref' + this.article.referenceArticlesInfos[i].articleId);
            let refArticleElemExpanded = this.elem.nativeElement.querySelector('#ref' + this.article.referenceArticlesInfos[i].articleId + '_expanded');
            const self = this;
            if (refArticleElem) {
                refArticleElem.addEventListener('click', function(e) {
                    self.openReferenceArticleModal(self.article.referenceArticlesInfos[i].articleId);
                    e.stopPropagation();
                });
            }
            if (refArticleElemExpanded) {
                refArticleElemExpanded.addEventListener('click', function(e) {
                    self.openReferenceArticleModal(self.article.referenceArticlesInfos[i].articleId);
                    e.stopPropagation();
                });
            }
        }
      }, 500);
  }

  public getHighlightedText(text: string): string {
    return Utils.getHighlightedText(text, this._keywords);
  }

  public async openReadingModal(article: ArticleInterface): Promise<void> {
    const modal = await this.modalController.create({
      component: ReadingArticleModal,
      cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog',
      componentProps: {
        article: article,
        from: this.from,
        keywords: this._keywords
      },
      backdropDismiss: true
    });

    // if the article isn't read, we mark it as read
    this.markAsRead(article);

    modal.onWillDismiss()
    .then(
      (data: any) => {
        if (this.from === 'favorite') {
          if (this.from === 'favorite') {

            this.favoritesService.addCommentToFavorite(article.id, article.data).subscribe(
              res => {
                // TODO ???
              }
            );
          }
        }
      }
    );
    return await modal.present();
  }

  public markAsRead(article: ArticleInterface) {
    if (article.read === false && !this.authService.roleCom) {
      this.articlesService.markArticleAsRead(article.id)
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        res => {
          this.articlesService.updateReadArticle(article.id, true);
        }
      );
    }
  }

    public markAsUnread(article: ArticleInterface) {
        if (article.read && !this.authService.roleCom) {
            this.articlesService.markArticleAsRead(article.id, false)
                .pipe(
                    takeUntil(this.unsubscribe$)
                )
                .subscribe(
                    res => {
                        this.articlesService.updateReadArticle(article.id, false);
                    }
                );
        }
    }



  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  sendMail(address: AddressInterface, $event: MouseEvent) {
    $event.stopPropagation();
    const self = this;
    ($event.currentTarget as Element).setAttribute('style', 'pointer-events: none;background-color: #B3B3B3;');
    // = ($event.currentTarget as Element).className + ' greyed';
    this.articlesService.emailArticleById(address.addressId, this.article.id).subscribe(next => {
      this.toastService.simple('L\'article a été envoyé par e-mail avec succès.');
      this.ngZone.run( () => {
        let shared = self.article.sharedTo;
        if (shared === undefined) {
          shared = [];
        }
        shared.push(address.data.email);
        self.article.sharedTo = shared;
      });
    });
    //  Sauvegarde  de l'action dans le log
    this.userService.log('ARTICLE_MAIL_ONECLICK', [this.article]);
  }

  async openMailModal() {
    const modal = await this.modalController.create({
      component: EmailArticleModal,
      cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog',
      componentProps: {
        articles: [this.article.id]
      }
    });
    return await modal.present();
  }

    public monitorArticle(article: ArticleInterface) {
      const articleID = article.id;
      const customers = '';
      this.articleMonitoringService.addMonitoringArticles(articleID, true, customers, false, '', '').subscribe(next => {

      });
        this.article.monitoring.status = 'to_process';
    }

    async addMonitoredArticle(article) {
        // Cas non superviseur
        if (!this.isSupervisor) {
          if (this.article.monitoring.status === undefined) {
            const alert = await this.alertController.create({
              header: 'Ajout de l\'article au suivi d\'affaires',
              message: '<b>Voulez-vous vraiment ajouter cet article au suivi d\'affaires ?</b>',
              buttons: [
                {
                  text: 'Annuler',
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: () => {
                  }
                }, {
                  text: 'Oui',
                  handler: () => this.monitorArticle(article)
                }
              ]
            });
            await alert.present();
          }
        } else { /*cas superviseur*/
          if ((this.nbCollaborateurs > this.listAssociatedCustomers.length) || this.article.monitoring.status === undefined) {
            const modal = await this.modalController.create({
              component: SupervisorMonitoringModalComponent,
              cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'addMonitoredArticleDialog',
              componentProps: {
                article: this.article,
                isSupervisor: true,
                isFromMonitoringView: false,
                listAssociatedCustomers: this.listAssociatedCustomers
              }
            });
            modal.onDidDismiss().then((data) => {
              if (data.data !== undefined) {
                if (data.data.selfSelected) {
                  this.article.monitoring.status = 'to_process';
                }
                for (let i = 0; i < data.data.customersSelected.length; i++) {
                  const isInArray = this.listAssociatedCustomers.findIndex(obj => obj.user_id === data.data.customersSelected[i].user_id);
                  if (isInArray === -1) {
                    this.listAssociatedCustomers.push({user_id: data.data.customersSelected[i].user_id, name: data.data.customersSelected[i].name});
                    if (this.shareArticleToolTip === undefined) {
                      this.shareArticleToolTip = 'Article suivi par :\n';
                      this.shareArticleToolTip += data.data.customersSelected[i].name + '\n';
                    } else {
                      this.shareArticleToolTip += data.data.customersSelected[i].name + '\n';
                    }
                  }
                }
              }

            });
            return await modal.present();
          }
        }
    }

  async addContact() {
    const modal = await this.modalController.create({
      component: AddEditAddressModal,
      // cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog',
      componentProps: {
        type: 'add'
      }
    });

    return await modal.present();
  }

  menuenter() {
    this.isMatMenuOpen = true;
    if (this.isMatMenu2Open) {
      this.isMatMenu2Open = false;
    }
  }

  menuLeave() {
    setTimeout(() => {
      if (!this.isMatMenu2Open && !this.enteredButton) {
        this.isMatMenuOpen = false;
        this.prevButtonTrigger.closeMenu();
      } else {
        this.isMatMenuOpen = false;
        this.prevButtonTrigger.closeMenu();
      }
    }, 80);
  }

  menu2enter() {
    this.isMatMenu2Open = true;
  }

  buttonEnter(trigger, mobile: boolean) {
    setTimeout(() => {
      if (this.prevButtonTrigger && this.prevButtonTrigger !== trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        trigger.openMenu();
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
      } else if (!this.isMatMenuOpen && !mobile) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
        trigger.openMenu();
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
      }
    });
  }

  buttonLeave(trigger, button) {
    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } if (!this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.enteredButton = false;
      }
    }, 100);
  }


  isVingtAnsStyle() {
    // for()
    return false;
  }

  /**
   * Ouverture article de référence
   * @param referenceArticleId
   */
  async openReferenceArticleModal(referenceArticleId: number) {
      const Query = {
          articles: referenceArticleId
      };
      let article;
      this.articlesService.getArticlesContent(Query).subscribe(
          (res) => {
              article = res[0];
              this.openArticleModal(article);
          }
      );
  }

  public async openArticleModal(article: ArticleInterface) {
      const modal = await this.modalController.create({
          component: ReadingArticleModal,
          cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog',
          componentProps: {
              article: article,
              keywords: this._keywords
          },
          backdropDismiss: true
      });

      // if the article isn't read, we mark it as read
      this.markAsRead(article);
      return await modal.present();
  }

}
