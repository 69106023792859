<mat-list-item class="article-container active articleLine" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches, 'read': article.read === true, 'vingtans': isVingtAnsStyle()}">
  <div class="article-content" [ngClass]="style" (click)="openReadingModal(article)">
    <mat-checkbox
      [ngClass]="style"
      (click)="$event.stopPropagation()"
      (change)="selectionModel.toggle(article.id)"
      [checked]="selectionModel.isSelected(article.id)"
    ></mat-checkbox>
    <div class="article-parts textContainer">
      <div class="listDate greyColor" style="display: flex;">
        <div  style="padding-top: 16px;flex: 1;font-size: 13px;">{{article.publicationDate | date:'dd/LL/yyyy':'':locale}}</div>

        <div class="articleStatusContainer">
          <div *ngIf="article.monitoring.status !== undefined" class="articleStatus">
            <span class="spanStatus" >Suivi</span>
            <mat-icon class="matIconStatus" >dashboard</mat-icon>
          </div>
          <div *ngIf="article.favorite" class="articleStatus">
            <span class="spanStatus">Favoris</span>
            <mat-icon class="matIconStatus">star_border</mat-icon>
          </div>
          <div *ngIf="article.read" class="articleStatus">
            <span class="spanStatus">Vu</span>
            <mat-icon class="matIconStatus">done</mat-icon>
          </div>
        </div>


        <button mat-icon-button (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu3">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu style="position: absolute" #menu3="matMenu">
          <article-toggle-favorite-button *ngIf="!isLettrePersoMC" [ngClass]="style" [isFromArticleList]="true" [article]="article" [selectionModel]="selectionModel2" [askBeforeDelete]="askBeforeDeleteFavorite"></article-toggle-favorite-button>
          <button *ngIf="article.monitoring.status !== undefined"
                  #button
                  style="height: 40px;z-index:9;"
                  mat-menu-item
                  (click)="!mediaQueryService.mobileQuery.matches ? addMonitoredArticle(article) : ''"
          >
            <mat-icon [ngStyle]="{'color':'#c20015'}" >dashboard</mat-icon>
            <span>Ajouter au suivi d'affaires</span>
          </button>
          <button *ngIf="article.monitoring.status === undefined && listAssociatedCustomers.length === 0"
                  #button
                  style="height: 40px;z-index:9;"
                  mat-menu-item
                  (click)="!mediaQueryService.mobileQuery.matches ? addMonitoredArticle(article) : ''"
          >
            <mat-icon class="greyColor">dashboard_customize</mat-icon>
            <span>Ajouter au suivi d'affaires</span>
          </button>
          <button *ngIf="article.monitoring.status === undefined && listAssociatedCustomers.length > 0"
                  #button
                  style="height: 40px;z-index:9;"
                  mat-menu-item
                  (click)="!mediaQueryService.mobileQuery.matches ? addMonitoredArticle(article) : ''"
          >
            <mat-icon [ngStyle]="{'color':'#283c4c'}">dashboard_customize</mat-icon>
            <span>Ajouter au suivi d'affaires</span>
          </button>
          <button *ngIf="!mediaQueryService.mobileQuery.matches && !isLettrePersoMC" #button style="height: 40px;z-index:9;"
                  mat-menu-item
                  (click)="$event.stopPropagation();!mediaQueryService.mobileQuery.matches ? openMailModal() : ''"
                  [matMenuTriggerFor]="menu" #levelOneTrigger="matMenuTrigger"
                  (mouseenter)="buttonEnter(levelOneTrigger, mediaQueryService.mobileQuery.matches)"
                  (mouseleave)="buttonLeave(levelOneTrigger, button)">
            <!--      <button style="height: 40px;" mat-icon-button (click)="$event.stopPropagation();openMailModal();" [matMenuTriggerFor]="menu"  *ngIf="!mediaQueryService.mobileQuery.matches">-->
            <!--      #trigger="matMenuTrigger" (mouseover)="trigger.openMenu()" (mouseout)="$event.stopPropagation();trigger.openMenu()">-->

            <mat-icon class="greyColor">mail_outline</mat-icon>
            <span> Envoyer par email</span>
          </button>
          <button *ngIf="mediaQueryService.mobileQuery.matches && !isLettrePersoMC" style="height: 40px;z-index:9;" mat-menu-item (click)="$event.stopPropagation();" [matMenuTriggerFor]="menu">
            <!--      <button style="height: 40px;" mat-icon-button (click)="$event.stopPropagation();openMailModal();" [matMenuTriggerFor]="menu"  *ngIf="!mediaQueryService.mobileQuery.matches">-->
            <!--      #trigger="matMenuTrigger" (mouseover)="trigger.openMenu()" (mouseout)="$event.stopPropagation();trigger.openMenu()">-->

            <mat-icon class="greyColor">mail_outline</mat-icon>
          </button>
          <button mat-menu-item *ngIf="article.read" (click)="markAsUnread(article)">
            <mat-icon class="greyColor">visibility_off</mat-icon>
            <span>Marquer comme non lu</span>
          </button>
          <button mat-menu-item *ngIf="!article.read" (click)="markAsRead(article)">
            <mat-icon class="greyColor">visibility</mat-icon>
            <span>Marquer comme lu</span>
          </button>
        </mat-menu>

        <mat-menu  [hidden]="!isLettrePersoMC"  #menu="matMenu" direction="down" yPosition="below" [hasBackdrop]="false">
          <div (mouseenter)="menuenter()" (mouseleave)="menuLeave()" style="max-height: 270px;">
            <button mat-menu-item (click)="openMailModal()">
              <b>Utiliser la fonction mail avancée</b>
            </button>
            <ng-container *ngIf="addressesService.getAddresses() | async as addresses">
              <ng-container *ngFor="let address of addresses.address">
                <button mat-menu-item (click)="sendMail(address, $event)">
                  <mat-icon style="color: green;" *ngIf="article.sharedTo !== undefined && article.sharedTo.includes(address.data.email)">check_circle_outline</mat-icon><b>{{address.data.label}}</b> - <i>{{address.data.email}}</i>
                </button>
              </ng-container>
            </ng-container>
            <button mat-menu-item (click)="addContact()">
              <b>Ajouter un contact</b>
            </button>
          </div>
        </mat-menu>

        </div>
            <div class="listTitle"
                 [ngClass]="{'big': style === 'big', 'all': style === 'all', 'small': style === 'small', 'read': article.read === true}"
                 [innerHTML]="getHighlightedText(article.title)"></div>
            <div id="articleCorps" [hidden]="style != 'big'" class="listCorps"
                 [ngClass]="{'read': article.read === true}">
      </div>
            <div id="articleCorpsExpanded" [hidden]="style != 'all'" class="listCorps"
                 [ngClass]="{'read': article.read === true}">
        <article-sources [article]="article"></article-sources>
      </div>
      <div *ngIf="style != 'small' " class="tags">
                <article-tags [keywords]="keywords" [tags]="article.tags" [predictions]="article.predictions"
                              [nonMatching]="3" [isFromBusinessSignals]="true" [hasSectorsInSearch]="hasSectorsInSearch"
                              [hasTopicsInSearch]="hasTopicsInSearch" class="list"
                              [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}"></article-tags>
      </div>
    </div>

  </div>
<!--  <div *ngIf="style != 'small' " class="contacts-content" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">-->
<!--    <article-contacts [contacts]="article.contacts" class="list contacts" [type]="'list'" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}"></article-contacts>-->
<!--  </div>-->

  <div *ngIf="style != 'small' " class="contacts-content" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
    <div *ngIf="style != 'all'; else showAllEntreprises">
      <div *ngFor="let entreprise of article.entreprises | slice:0:2;">
        <article-entreprise [keywords]="keywords" [entreprise]="entreprise" [article]="article" class="list contacts" [condensedMode]="true"
                            [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}"></article-entreprise>
      </div>
      <ion-chip (click)="openReadingModal(article)" *ngIf="showMoreEntrepriseInfo" class="seeMoreEntrepriseIcon"
                [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}" >
        <ion-label>...</ion-label>
      </ion-chip>
    </div>
    <ng-template #showAllEntreprises>
      <div *ngFor="let entreprise of article.entreprises">
        <article-entreprise [keywords]="keywords" [entreprise]="entreprise" [article]="article" class="list contacts"
                            [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}"></article-entreprise>
      </div>
    </ng-template>
  </div>
</mat-list-item>

