import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FavorisFolder} from "../../../interfaces/favoritesFolders/favorites-folders.interface";
import {IconType} from "../fe-icon/fe-icon.component";

@Component({
  selector: 'fe-favorite-item',
  templateUrl: './fe-favorite-item.component.html',
  styleUrls: ['./fe-favorite-item.component.scss']
})
export class FeFavoriteItemComponent {
  @Input() favorisFolder: FavorisFolder;
  @Input() selectedFolder: boolean = false;
  @Output() onDeleteClick = new EventEmitter();
  @Output() onRenameClick = new EventEmitter();

  protected readonly IconType = IconType;
}
