import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'no-data',
  templateUrl: 'no-data.component.html',
  styleUrls: ['no-data.component.scss'],
})
export class NotDataComponent implements OnInit {

  constructor(
  ) {}

  public ngOnInit(): void {
  }

}
