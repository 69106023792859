import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {map, startWith} from "rxjs/operators";
import {Observable} from "rxjs";
import {MatAutocomplete} from "@angular/material/autocomplete";
import {ColorType, IconType} from "../fe-icon/fe-icon.component";

@Component({
  selector: 'fe-searchbar',
  templateUrl: './fe-searchbar.component.html',
  styleUrls: ['./fe-searchbar.component.scss']
})
export class FeSearchbarComponent {
  protected inputValue: string = '';
  @Input('control') control = new FormControl('');
  @Input('placeHolder') placeHolder: string = 'Rechercher...';
  @Input() thinSearchBar: boolean = false;
  @Input() matAutocomplete: MatAutocomplete;
  // @Input('matAutocomplete') matAutocomplete: MatAutocomplete;
  // filteredStreets: Observable<string[]>;
  // streets: string[] = ['Champs-Élysées', 'Lombard Street', 'Abbey Road', 'Fifth Avenue'];
  get value() {
    return this.inputValue;
  }

  @Output()
  valueChange = new EventEmitter<string>();

  @Input()
  set value(val: string) {
    this.inputValue = val;
  }

  public clear(): void {
    this.control = new FormControl('');
    this.change();
  }

  // ngOnInit() {
  //   this.filteredStreets = this.control.valueChanges.pipe(
  //       startWith(''),
  //       map(value => this._filter(value || '')),
  //   );
  // }

  // private _filter(value: string): string[] {
  //   const filterValue = this._normalizeValue(value);
  //   return this.streets.filter(street => this._normalizeValue(street).includes(filterValue));
  // }
  //
  // private _normalizeValue(value: string): string {
  //   return value.toLowerCase().replace(/\s/g, '');
  // }
  change() {
    this.valueChange.emit(this.control.value);
  }

  protected readonly IconType = IconType;
  protected readonly ColorType = ColorType;
}
