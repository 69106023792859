import {Component, Input, OnInit} from '@angular/core';
import {EntrepriseArticleInterface} from '../../../../interfaces/entreprise-article/entreprise-article.interface';
import {ModalService} from '../../../../services/modal/modal.service';
import {FeArticleModalComponent} from '../../fe-article-modal/fe-article-modal.component';
import {format, isBefore} from 'date-fns';
import {SnackbarService} from '../../../../services/snackbar/snackbar.service';

@Component({
  selector: 'fe-previous-article-line',
  templateUrl: './fe-previous-article-line.component.html',
  styleUrls: ['./fe-previous-article-line.component.scss']
})
export class FePreviousArticleLineComponent implements OnInit {
  @Input() article: EntrepriseArticleInterface;
  @Input() prospect: boolean = false;
  protected articleModalDisable: boolean = false;

  constructor(private modalService: ModalService,
              private snackbarService: SnackbarService) {
  }

  ngOnInit() {
    const articleDate: Date = new Date(this.article.date);
    const limitDate: Date = new Date();
    limitDate.setDate(limitDate.getDate() - 90);
    this.articleModalDisable = this.prospect && isBefore(articleDate, limitDate);
  }

  protected openArticle(): void {
    if (this.articleModalDisable) {
      this.snackbarService.showDangerSnackbar('Opération impossible',
          'L\'historique des signaux business est limité à 3 mois en période de découverte');
    } else {
      this.modalService.showCustomModal(FeArticleModalComponent, {data: {
          article: this.article,
          fromFicheEntreprise: true
        }});
    }
  }

  protected getArticleDate(): string {
    return format(new Date(this.article.date), 'dd/MM/yyyy');
  }
}
