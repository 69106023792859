import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FEEntrepriseContactInterface} from '../../../../interfaces/fe-entreprise-contact/fe-entreprise-contact.interface';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'fe-contact-enrichi-modal',
  templateUrl: './fe-contact-enrichi-modal.component.html',
  styleUrls: ['./fe-contact-enrichi-modal.component.scss']
})
export class FeContactEnrichiModalComponent {
  protected contact: FEEntrepriseContactInterface;

  constructor(private modalController: ModalController,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.contact = data.contact;
  }

  protected openUrl(url: string): void {
    window.open(url, '_blank');
  }

  /**
   * Close the modal
   */
  public close(): void {
    this.modalController.dismiss();
  }
}
