<div class="feModal addFavoriteModal">
  <div class="feModalHeader">
    <span class="feModalTitle">{{title}}</span>
    <div class="feModalCloseButton" mat-dialog-close>
      <span>Fermer</span>
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="feModalContent">
    <div style="display: flex; flex-direction: column; gap: 20px; width: fit-content; margin: auto;">
      <div style="flex: 1;display: flex; flex-direction: row; margin: 10px;">
        <mat-radio-button [checked]="!isCreateFolder" (change)="checkSelectFolder($event)"
                          [class.displayNone]="actualForlderId > -1"
                          name="rdbtn" style="width:230px;">
          Ajouter au dossier :
        </mat-radio-button>
        <button class="dropdownBtn" style="margin: initial; width: 255px;" [class.disableDiv]="isCreateFolder"
                [matMenuTriggerFor]="menu2">
          <span style="text-align: left; flex: 1;">{{selectedFolderLibelle}}</span>
          <mat-icon>{{favoriteSelectIcon}}</mat-icon>
        </button>

        <mat-menu class="menuFavoris" style="position: absolute; max-height: 400px;" #menu2="matMenu">
          <fe-menu-item (click)="getSelectedFolderLibelle(folder.libelle, folder.folderID)"
                        *ngFor="let folder of _favoriteFolders" [class.fodlerDisable]="folder.folderID == actualForlderId"
                        color="PRIMARY" [text]="folder.libelle" [icon]="IconType.pertinence"/>
        </mat-menu>
      </div>
      <div style="flex: 1;display: flex; flex-direction: row; margin: 10px;" [class.displayNone]="actualForlderId > -1">
        <mat-radio-button [checked]="isCreateFolder" (change)="checkCreateFolder($event)" style="width:230px;">
          Créer un nouveau dossier :
        </mat-radio-button>
        <div (click)="isCreateFolder = true;" style="position: relative;">
          <fe-input [(value)]="newFolderName" [disabled]="!isCreateFolder"
                    placeHolder="Nom du dossier" #feInputComponent/>
          <div *ngIf="!isCreateFolder" (click)="enableInput()"
               style="position: absolute; width: 100%; height: 100%; top: 0; cursor: pointer;"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="feModalFooter" style="display: flex;">
    <fe-button text="Annuler" mat-dialog-close color="DANGER"/>
    <fe-button text="Confirmer" (click)="addFavorite()"/>
  </div>
</div>
