import {Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'fe-loader',
  templateUrl: './fe-loader.component.html',
  styleUrls: ['./fe-loader.component.scss']
})
export class FeLoaderComponent {
  @Input() loaderText: string = '';
  @Input() @HostBinding('class.isOverflowing') isOverflowing: boolean = false;
  @Input() noBackground: boolean = false;
}
