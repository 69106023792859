import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { ContactInterface } from 'src/app/interfaces/contact/contact.interface';
import { MediaQueryService } from 'src/app/services/media-query/media-query.service';
import {EntrepriseInterface} from '../../../interfaces/entreprise/entreprise.interface';
import {EntrepriseEtablissementInterface} from '../../../interfaces/entreprise-etablissement/entreprise-etablissement.interface';
import {ArticleInterface} from '../../../interfaces/articles/article.interface';
import {ReadingArticleModal} from '../../modals/reading-article/reading-article.modal';
import {ModalController} from '@ionic/angular';
import {FicheEntrepriseModal} from '../../modals/fiche-entreprise/fiche-entreprise.modal';

@Component({
  selector: 'article-etablissement',
  templateUrl: 'article-etablissement.component.html',
  styleUrls: ['article-etablissement.component.scss'],
})
export class ArticleEtablissementComponent implements OnInit, OnDestroy {

  @Input() public etablissement: EntrepriseEtablissementInterface;
  @Input() public entreprise: EntrepriseInterface;

  private unsubscribe$: Subject<any> = new Subject<any>();

  public numberOfContacts: number;

  constructor(
    public mediaQueryService: MediaQueryService
    , private modalController: ModalController
  ) {}

  public ngOnInit(): void {
    // if (Array.isArray(this.contacts) && this.contacts.length > 0) {
    //   this.numberOfContacts = this.contacts.length;
    //   // iterate on contact to get subContact and count them.
    //   this.contacts.forEach((contact: ContactInterface) => {
    //     if (Array.isArray(contact.subContacts) && contact.subContacts.length > 0) {
    //       this.numberOfContacts += contact.subContacts.length;
    //     }
    //   });
    // }
  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public async openArticleFicheEntreprise(): Promise<void> {
    const modal = await this.modalController.create({
      component: FicheEntrepriseModal,
      cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog ficheEModal',
      componentProps: {
        entreprise: this.entreprise,
        entrepriseid: this.entreprise.entrepriseId
      }
    });
    return await modal.present();
  }
}
