<ion-header no-border class="allContactHeader">
    <div class="ficEntHeaderBtnBar" style="flex-grow: 1; display: flex;">
        <!--        <span style="margin: auto 5px auto 10px; font-size: 20px; color: #254354; word-break: break-word; hyphens: auto;">{{entreprise.name}} - Contacts</span>     -->
        <span class>Statistiques financières</span>

    </div>

    <button class="allContactCloseBtn" aria-label="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
<!--    <span >Statistiques financières</span>-->
<!--    <ion-buttons slot="end">-->
<!--      <button mat-icon-button aria-label="close" (click)="close()">-->
<!--        <mat-icon>close</mat-icon>-->
<!--      </button>-->
<!--    </ion-buttons>-->

</ion-header>

<ion-content>
  <div style="display: flex;flex-direction: row; height: 38px;">
    <div  style="font-size: 18px; color: #254354;text-align: center; flex:1; margin-top: 15px" *ngIf="dataSales">
      Chiffre d'affaires
    </div>
    <div  style="font-size: 18px; color: #254354;text-align: center; flex:1; margin-top: 15px" *ngIf="dataProfits">
      Résultat net
    </div>
    <div  style="font-size: 18px; color: #254354;text-align: center; flex:1; margin-top: 15px" *ngIf="dataEff">
      Effectifs
    </div>
  </div>
  <div style="display: flex;flex-direction: row;">
<!--    <ngx-charts-bar-vertical [results]="dataSales" [xAxis]="true" [yAxis]="true" title="Chiffre d'affaire">-->

<!--    </ngx-charts-bar-vertical>-->

<!--<div *ngIf="dataSales">-->

    <ngx-charts-bar-vertical *ngIf="dataSales"
            [view]="view"
            [scheme]="colorScheme"
            [results]="dataSales"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel">
    </ngx-charts-bar-vertical>
<!--</div>-->


  <ngx-charts-bar-vertical *ngIf="dataProfits"
                           [view]="view"
                           [scheme]="colorScheme"
                           [results]="dataProfits"
                           [gradient]="gradient"
                           [xAxis]="showXAxis"
                           [yAxis]="showYAxis"
                           [legend]="showLegend"
                           [showXAxisLabel]="showXAxisLabel"
                           [showYAxisLabel]="showYAxisLabel"
                           [xAxisLabel]="xAxisLabel"
                           [yAxisLabel]="yAxisLabel">
  </ngx-charts-bar-vertical>

  <ngx-charts-bar-vertical *ngIf="dataEff"
                           [view]="view"
                           [scheme]="colorScheme"
                           [results]="dataEff"
                           [gradient]="gradient"
                           [xAxis]="showXAxis"
                           [yAxis]="showYAxis"
                           [legend]="showLegend"
                           [showXAxisLabel]="showXAxisLabel"
                           [showYAxisLabel]="showYAxisLabel"
                           [xAxisLabel]="xAxisLabel"
                           [yAxisLabel]="yAxisLabel">
  </ngx-charts-bar-vertical>
      <div *ngIf="!dataSales && !dataProfits && !dataEff" style="font-size: 24px;margin: auto;">
          Aucune donnée financière disponible pour cette entreprise
      </div>
</div>
<!--  <div style="display: flex; flex-direction: row;">-->
<!--    <div style="width: 500px; height: 300px; padding: 20px; margin: 20px" *ngIf="dataSales">-->
<!--      <ngx-charts-bar-vertical [view]="[350, 250]" [results]="dataSales" [xAxis]="true" [yAxis]="true" title="Chiffre d'affaire">-->

<!--      </ngx-charts-bar-vertical>-->
<!--    </div>-->

<!--    <div style="width: 500px; height: 300px; padding: 20px; margin: 20px" *ngIf="dataProfits">-->
<!--      <ngx-charts-bar-vertical [view]="[350, 250]" [results]="dataProfits"title="Résultat net">-->

<!--      </ngx-charts-bar-vertical>-->
<!--    </div>-->

<!--    <div style="width: 500px; height: 300px; padding: 20px; margin: 20px" *ngIf="dataEff">-->
<!--      <ngx-charts-bar-vertical [view]="[350, 250]" [results]="dataEff" title="Effectifs">-->

<!--      </ngx-charts-bar-vertical>-->
<!--    </div>-->
<!--  </div>-->
</ion-content>
