<div class="feMonitorSupervisordAddModal feModal">
    <div class="feModalHeader">
        <span class="feModalTitle">Ajout de l'article au suivi d'affaires</span>
        <div class="feModalCloseButton">
            <span mat-dialog-close>Fermer</span>
            <mat-icon mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="feModalContent">
        <h3 style="margin-bottom: 5px;">Article : '{{ troncateText(article.title, 80) }}' </h3>

        <div style="margin-bottom: 15px; font-size: 14px; font-weight: bold;" *ngIf="isSupervisor && !isFromMonitoringView && article.monitoring.status !== undefined">
            Cet article est dans votre suivi d'affaires.
        </div>

        <div style="margin-bottom: 15px;font-weight: bold;" *ngIf="isSupervisor && !isFromMonitoringView && article.monitoring.status === undefined">
            <mat-checkbox [checked]="selfSelected"
                          (click)="$event.stopPropagation()"
                          (change)="selectSelf()"><span style="font-weight: bold; font-size: 14px;">Ajouter l'article dans mon suivi d'affaires</span>
            </mat-checkbox>
        </div>

        <div style="font-weight: bold; font-size: 14px;" >Ajouter l'article au suivi d'affaires des collaborateurs :</div>
        <mat-list style="padding:0px;">
            <ng-container *ngIf="companyCustomers!= undefined && companyCustomers.length; else noData">
                <div style="border: 1px solid #111111; border-radius:5px; max-height: 200px; overflow: auto; padding: 0px 5px 0 5px;">
                    <mat-list-item class="list-item-container"
                                   *ngFor="let collaborator of companyCustomers">
                        <mat-checkbox [checked]="selectionModel.isSelected(collaborator)"
                                      (click)="$event.stopPropagation()"
                                      (change)="selectionModel.toggle(collaborator)">
                            <span style="margin: 0" class="collaborator-fullname">
                                {{collaborator.name}}  ({{collaborator.email}})
                            </span>
                        </mat-checkbox>
                    </mat-list-item>
                </div>
            </ng-container>
            <ng-template #loading>
                <loading-overlay>Chargement des collaborateurs</loading-overlay>
            </ng-template>
            <ng-template #noData>
                <no-data>Aucun collaborateur disponible</no-data><br/>
            </ng-template>
        </mat-list>

        <div *ngIf="companyCustomers!= undefined && companyCustomers.length" style="margin-top: 3px;">
            <mat-checkbox [checked]="notifiedByEmail"
                          (click)="$event.stopPropagation()"
                          (change)="emailNotification()">Notifier les collaborateurs associés
            </mat-checkbox>
        </div>
        <mat-form-field *ngIf="companyCustomers!= undefined && companyCustomers.length;"
            style="width: 100% !important; margin-top: 3px;" appearance="outline">
            <mat-label>Commentaire de notification aux collaborateurs</mat-label>
            <textarea  (change)="updateTextareaValue($event)"  matInputformControlName="comment" rows="2" matInput class="feTextArea">{{supervisorComment}}</textarea>
        </mat-form-field>


<!--            <div class="button-container">-->
<!--                <button mat-button-->
<!--                        [disabled]="(selectionModel.selected.length == 0) && !selfSelected"-->
<!--                        (click)="addMonitoredArticle(article)">{{"ADD"}}</button>-->
<!--            </div>-->
        <div *ngIf="articleAlreadyShared.length > 0">
            <span class="bigText">Article suivi par :</span>
            <div style="margin-top: 5px; max-height: 150px; overflow: auto;">
                <p *ngFor="let customer of articleAlreadyShared"><strong>{{customer.name}}</strong> - <em>{{customer.email}}</em></p>
            </div>
        </div>

    </div>
    <div class="feModalFooter">
        <fe-button text="Fermer" color="DANGER" (click)="close();"/>
        <fe-button text="Valider" (click)="addMonitoredArticle(article);"/>
    </div>
</div>

<!--<ion-header no-border class="ionRedHeader">-->
<!--    <ion-toolbar class="ionRedHeader">-->
<!--        <mat-label slot="start" style="margin-left: 10px; color: white; font-size: 16px; font-weight: bold">-->
<!--            Ajout de l'article au suivi d'affaires-->
<!--        </mat-label>-->
<!--        <ion-buttons slot="end">-->
<!--            <button mat-icon-button aria-label="close" (click)="close(false)">-->
<!--                <mat-icon>close</mat-icon>-->
<!--            </button>-->
<!--        </ion-buttons>-->
<!--    </ion-toolbar>-->
<!--</ion-header>-->

<!--<ion-content>-->
<!--    <div style=" margin-top: 25px; margin-right: 25px; margin-left: 25px;">-->
<!--        <div style="margin-bottom: 15px;" *ngIf="isSupervisor && !isFromMonitoringView && article.monitoring.status === undefined">-->
<!--            <mat-checkbox [checked]="selfSelected"-->
<!--                          (click)="$event.stopPropagation()"-->
<!--                          (change)="selectSelf()">Ajouter l'article dans mon suivi d'affaires-->
<!--            </mat-checkbox>-->
<!--        </div>-->
<!--        <div style="margin-bottom: 15px; font-weight: bold; font-size: 14px;" >Ajouter l'article au suivi d'affaires des collaborateurs :</div>-->
<!--        <div style="margin: 0 auto 10px;" *ngIf="companyCustomers!= undefined && companyCustomers.length">-->
<!--            <mat-checkbox [checked]="notifiedByEmail"-->
<!--                          (click)="$event.stopPropagation()"-->
<!--                          (change)="emailNotification()">Notifier les collaborateurs associés-->
<!--            </mat-checkbox>-->
<!--        </div>-->
<!--        <mat-list>-->
<!--            <ng-container *ngIf="companyCustomers!= undefined && companyCustomers.length; else noData">-->
<!--                <div style="border: 1px solid #111111; min-height: 125px; max-height: 200px; margin-bottom: 15px; overflow: auto;">-->
<!--                    <mat-list-item class="list-item-container"-->
<!--                                   *ngFor="let collaborator of companyCustomers">-->
<!--                        <mat-checkbox [checked]="selectionModel.isSelected(collaborator)"-->
<!--                                      (click)="$event.stopPropagation()"-->
<!--                                      (change)="selectionModel.toggle(collaborator)">-->
<!--                        </mat-checkbox>-->
<!--                        <label style="margin: 0" class="collaborator-fullname">-->
<!--                            {{collaborator.name}}  ({{collaborator.email}})-->
<!--                        </label>-->
<!--                    </mat-list-item>-->
<!--                </div>-->
<!--            </ng-container>-->
<!--            <ng-template #loading>-->
<!--                <loading-overlay>Chargement des collaborateurs</loading-overlay>-->
<!--            </ng-template>-->
<!--            <ng-template #noData>-->
<!--                <no-data>Aucun collaborateur disponible</no-data><br/>-->
<!--            </ng-template>-->
<!--        </mat-list>-->
<!--        <form [formGroup]="commentForm">-->
<!--            <mat-form-field style="width: 100% !important;"-->
<!--                            *ngIf="selectionModel.selected.length !== 0 && notifiedByEmail"-->
<!--                            appearance="outline">-->
<!--                <mat-label>Commentaire</mat-label>-->
<!--                <textarea  (change)="updateTextareaValue($event)"  matInputformControlName="comment"  rows="5" matInput>{{supervisorComment}}</textarea>-->
<!--            </mat-form-field>-->
<!--        </form>-->

<!--        <div class="button-container">-->
<!--            <button mat-button-->
<!--                    [disabled]="(selectionModel.selected.length == 0) && !selfSelected"-->
<!--                    (click)="addMonitoredArticle(article)">{{"ADD"}}</button>-->
<!--        </div>-->
<!--        <div *ngIf="articleAlreadyShared.length > 0">-->
<!--            <h2>Article suivi par :</h2>-->
<!--                <div *ngFor="let customer of articleAlreadyShared" style="max-height: 150px; overflow: auto;">-->
<!--                    <p><strong>{{customer.name}}</strong> - <em>{{customer.email}}</em></p>-->
<!--                </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</ion-content>-->
