import { FormControl } from '@angular/forms';
import { CountryCode } from 'libphonenumber-js';

export class CountryCodeValidator {

  public static isValideCountryCode(countryCodes: Array<CountryCode>) {
    return (formControl: FormControl) => {
      if (countryCodes.indexOf(formControl.value) === -1) {
        return { invalidCountryCode: true };
      } else {
        return null;
      }
    };
  }
}
