<div class="container">
  <div class="title">
    <mat-icon *ngIf="field.matLeadingIcon" color="primary" matTooltip="{{field.matLeadingIconToolTip}}">
      {{field.matLeadingIcon}}</mat-icon>
    <span>{{field.label || field.placeholder}}</span>
  </div>
  <div class="sliderValueContainer">
    <mat-slider
      [formControl]="formControl"
      [max]="field.max"
      [min]="field.min"
      [step]="field.step">
    </mat-slider>
    <div *ngIf="field.showValueAfter && formControl.value">{{formControl.value}} <span *ngIf="field.valueUnit">{{field.valueUnit}}</span></div>
  </div>
  <mat-hint align="start" *ngIf="field.hint && field.hint.start"><strong>{{field.hint.start}}</strong> </mat-hint>
  <mat-hint align="end" *ngIf="field.hint && field.hint.end && (!field.validators || !field.validators.maxLength) "><strong>{{field.hint.end}}</strong>
  </mat-hint>
  <mat-hint align="end" *ngIf="field.validators && field.validators.maxLength"><strong>&nbsp;{{formControl.value.length}}
      / {{field.validators.maxLength}}</strong> </mat-hint>
<!--  <mat-error *ngIf="formControl.hasError('valid') && !formControl.hasError('required')">-->
<!--    <span [innerHtml]="'E_FIELD_NO_VALID' | translate"></span>-->
<!--  </mat-error>-->
<!--  <mat-error *ngIf="formControl.hasError('required')">-->
<!--    <span [innerHtml]="'E_FIELD_NAME_REQUIRED' | translate:field"></span>-->
<!--  </mat-error>-->
<!--  <mat-error *ngIf="formControl.hasError('min') && !formControl.hasError('required')">-->
<!--    <span [innerHtml]="'E_FIELD_NO_VALID_MIN' | translate:field"></span>-->
<!--  </mat-error>-->
<!--  <mat-error *ngIf="formControl.hasError('minlength') && !formControl.hasError('required')">-->
<!--    <span [innerHtml]="'E_FIELD_NO_VALID_MIN_LENGTH' | translate:field"></span>-->
<!--  </mat-error>-->
<!--  <mat-error *ngIf="formControl.hasError('max') && !formControl.hasError('required')">-->
<!--    <span [innerHtml]="'E_FIELD_NO_VALID_MAX' | translate:field"></span>-->
<!--  </mat-error>-->
<!--  <mat-error *ngIf="formControl.hasError('maxlength') && !formControl.hasError('required')">-->
<!--    <span [innerHtml]="'E_FIELD_NO_VALID_MAX_LENGTH' | translate:field"></span>-->
<!--  </mat-error>-->
<!--  <mat-error *ngIf="formControl.hasError('pattern') && !formControl.hasError('required')">-->
<!--    <span [innerHtml]="'E_FIELD_NO_VALID_PATTERN' | translate"></span>-->
<!--  </mat-error>-->
</div>
