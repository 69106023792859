<mat-card class="item" *ngIf="monitoredArticle" (dblclick)="edit.emit(monitoredArticle)">
    <mat-card-header [class.lead-card-header]="monitoredArticle.type === 'external_lead'"  style="padding: 4px 8px;">
        <mat-card-title style="width: 100%; margin: auto">
            <div style="width: 100%; display: flex; align-items: center;">
                <div style="margin-right: auto; font-size: 12px; height: 12px; line-height: 12px;">
                    {{ monitoredArticle.monitoringStartDate }}
                </div>
                <div style="display: flex; margin-right: 8px;" *ngIf="monitoredArticle.monitoringMetadata.reminderDate">
                    <div class="time-block" matTooltipClass="multiline-tooltip" matTooltipPosition="after"
                         matTooltip="{{reminderToolTip}}">
                        <fe-icon size="18" [iconType]="IconType.alerte" [color]="ColorType.lightBlack"/>
                    </div>
                </div>
                <div style="display: flex; height: 20px;">
                    <div class="time-block" matTooltipClass="multiline-tooltip" matTooltipPosition="after"
                         matTooltip="{{timeToolTip}}">
                        <fe-icon size="18" [iconType]="IconType.time" [color]="ColorType.lightBlack"/>
                    </div>
                </div>
            </div>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content style="padding-top: 5px; color: black;">
        <div *ngIf="monitoredArticle.monitoringMetadata.comment" style="display: flex; position: relative;">
            <mat-icon class="mat-icon-monitoring">notes</mat-icon>
            <span class="span-monitoring"style="margin: auto auto 4px 0; line-height: 14px; color: dimgrey;"
                          title="Note libre"> {{monitoredArticle.monitoringMetadata.comment}}
            </span>
        </div>
        <div style="display: flex; flex-direction: row-reverse; margin-bottom: 5px;"
             *ngIf="monitoredArticle.monitoringMetadata !== undefined">
            <span class="span-monitoring" style="margin-right: 0;"
                  *ngIf="monitoredArticle.monitoringMetadata.isUsualCustomer !== undefined && monitoredArticle.monitoringMetadata.isUsualCustomer == true"
                  title="Client récurrent"> client récurrent
                <mat-icon class="mat-icon-monitoring">person</mat-icon>
            </span>
            <span class="span-monitoring"
                  *ngIf="monitoredArticle.status === 'closed' && monitoredArticle.monitoringMetadata.contractStatus !== undefined && monitoredArticle.monitoringMetadata.contractStatus !== null"
                  title="Statut du contrat"> {{ monitoredArticle.monitoringMetadata.contractStatus === 'in_progress_prevost' ? "En cours CRM Prevost" : monitoredArticle.monitoringMetadata.contractStatus}}
                <mat-icon class="mat-icon-monitoring">insert_drive_file
                </mat-icon>
            </span>
            <span class="span-monitoring"
                  *ngIf="monitoredArticle.monitoringMetadata.closeDate !== undefined && monitoredArticle.monitoringMetadata.closeDate !== null"
                  title="Date de clôture du contrat"> {{monitoredArticle.monitoringMetadata.closeDate}}
                <mat-icon class="mat-icon-monitoring">event
                </mat-icon>
            </span>
            <span class="span-monitoring" *ngIf="monitoredArticle.monitoringMetadata.amountFormated" style="margin: auto auto auto 0;"
                  title="Montant du contrat"> {{monitoredArticle.monitoringMetadata.amountFormated}}
                <mat-icon class="mat-icon-monitoring">money</mat-icon>
            </span>
        </div>


        <p (click)="openReadingModal(monitoredArticle.article)">
            {{ monitoredArticle.title }}
        </p>

        <div style="font-size: 10px; font-style: italic; color: #273c4e; margin-top: -2px;">
            {{ monitoredArticle.article.publicationDate | date:'dd/LL/yyyy':'':locale}}
        </div>

        <div style="display: flex; margin-top: 5px; margin-bottom: -5px;">
            <div class="rounded-mat-select prioritySelect">
<!--                <mat-form-field class="rounded-mat-formfield" appearance="legacy" [floatLabel]="'never'"-->
<!--                                style="margin-top: -9px;">-->
                    <mat-select placeholder="{{monitoredArticle.priority}}" [ngStyle]="{'width.px': 200}"
                                (selectionChange)="updatePriority($event.value)"
                                [disabled]="monitoredArticle.isVisualisation">
                        <mat-option *ngFor="let priority of priorities" [value]="priority" style="color: white">
                            <span class="optionPrio">{{priority.valueOf()}}</span>
                        </mat-option>
                    </mat-select>
<!--                </mat-form-field>-->
            </div>
            <!--            <div class="rounded-content" style="background-color: #273C4E;">-->
            <!--                <mat-icon-->
            <!--                        style="font-size: 15px; width: 18px; height: 18px; vertical-align: top;line-height: inherit;align-items: center;">-->
            <!--                    person-->
            <!--                </mat-icon>-->
            <!--                <span>E. Lombard</span>-->
            <!--            </div>-->
            <div class="collaboratorBtn" *ngIf="this.showShareButton === true"
                 matTooltipClass="multiline-tooltip" matTooltipPosition="after"
                 (click)="addCollaborators(monitoredArticle)" matTooltip="{{shareArticleToolTip}}">
                <mat-icon *ngIf="listAssociatedCustomers.length > 1"
                          style="font-size: 15px; width: 18px; height: 18px; vertical-align: top;line-height: inherit;align-items: center;">
                    people
                </mat-icon>

                <mat-icon *ngIf="listAssociatedCustomers.length == 1 || listAssociatedCustomers.length == 0"
                          style="font-size: 15px; width: 18px; height: 18px; vertical-align: top;line-height: inherit;align-items: center;">
                    person
                </mat-icon>


                <span class="span-ellipsis" *ngIf="listAssociatedCustomers.length == 0">Partager</span>
                <span class="span-ellipsis"
                      *ngIf="listAssociatedCustomers.length == 1">{{listAssociatedCustomers[0].name}}</span>
                <span style="overflow: hidden;white-space:nowrap;text-overflow:ellipsis;width:70px;display:inline-block;"
                      *ngIf="listAssociatedCustomers.length > 1">{{listAssociatedCustomers[0].name}} ...</span>
            </div>
            <div style=" order: 2; margin-left: auto; margin-top: 5px; display: flex">
                <button class="button-info" mat-button [matMenuTriggerFor]="metadata"
                        [disabled]="monitoredArticle.isVisualisation">
                    <fe-icon [iconType]="IconType.information" size="18" [color]="ColorType.grey"/>
                </button>
                <mat-menu #metadata="matMenu" class="metadataContainer">
                    <div>
                        <!--                    <div style=" margin-top: 25px; margin-right: 25px; margin-left: 25px;">-->
                        <div style="margin-bottom: 15px; display: flex" (click)="$event.stopPropagation()"
                             *ngIf="monitoredArticle.status === 'closed'">
                            <span class="moderateText" style="width: 120px; margin: auto 0;">Statut du contrat</span>
                            <mat-form-field appearance="fill" [floatLabel]="'never'" style="margin-left: 15px;"
                                            class="monitoredSelectStatus">
                                <mat-select value="{{monitoredArticle.monitoringMetadata.contractStatus}}"
                                            [(ngModel)]="currentStatus">
                                    <mat-option *ngFor="let status of statuses" [value]="status">
                                        {{status.valueOf()}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div style="margin-bottom: 15px; display: flex" (click)="$event.stopPropagation()">
                            <span class="moderateText" style="width: 120px; margin: auto 0;">Note</span>
                            <fe-input [(value)]="currentComment" smallMode="true"/>
                        </div>

                        <div style="margin-bottom: 15px; display: flex" (click)="$event.stopPropagation()">
                            <span class="moderateText" style="width: 120px; margin: auto 0;">Montant</span>
                            <fe-input type="number" [(value)]="currentAmount" smallMode="true"/>
                        </div>

                        <div style="margin-bottom: 15px; display: flex" (click)="$event.stopPropagation()">
                            <span class="moderateText" style="width: 120px; margin: auto 0;">Date de clôture</span>
                            <form [formGroup]="closeDateForm">
                                <mat-form-field appearance="legacy" class="monitoredInputDate hidingFormDate"
                                                floatLabel="never">
                                    <mat-label style="color: white">Date de clôture</mat-label>
                                    <input formControlName="currentClosedDate" [matDatepicker]="pickerCloseDate"
                                           matInput (dateChange)="updateClotureDate($event)">
                                    <mat-datepicker-toggle matSuffix [for]="pickerCloseDate"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerCloseDate></mat-datepicker>
                                </mat-form-field>
                            </form>
                            <button class="dateDropdownBtn" (click)="pickerCloseDate.open()"
                                    style="margin-left: 0;">
                                <fe-icon [color]="ColorType.lightBlack" [iconType]="IconType.calendar"/>
                                <span>{{clotureDateLabel}}</span>
                            </button>
                            <mat-icon style="margin-left: 8px; cursor: pointer;"
                                      (click)="clearClotureDate()">close</mat-icon>
                        </div>

                        <div style="margin-bottom: 15px; display: flex" (click)="$event.stopPropagation()">
                            <span class="moderateText" style="width: 120px; margin: auto 0;">Date de rappel</span>
                            <form [formGroup]="reminderDateForm">
                                <mat-form-field appearance="legacy" class="monitoredInputDate hidingFormDate"
                                                floatLabel="never" style="margin-left: 8px;">
                                    <mat-label style="color: white">Date de rappel</mat-label>
                                    <input formControlName="currentReminderDate" [matDatepicker]="pickerReminderDate"
                                           matInput [min]="minDate" (dateChange)="updateReminderDate($event)">
                                    <mat-datepicker-toggle matSuffix [for]="pickerReminderDate"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerReminderDate></mat-datepicker>
                                </mat-form-field>
                            </form>
                            <button class="dateDropdownBtn" (click)="pickerReminderDate.open()"
                                    style="margin-left: 0;">
                                <fe-icon [color]="ColorType.lightBlack" [iconType]="IconType.calendar"/>
                                <span>{{reminderDateLabel}}</span>
                            </button>
                            <mat-icon style="margin-left: 8px; cursor: pointer;"
                                      (click)="clearReminderDate()">close</mat-icon>
                        </div>

                        <div style="margin-bottom: 15px; display: flex" (click)="$event.stopPropagation()">
                            <span class="moderateText" style="width: 120px; margin: auto 0;">Client récurrent</span>
                            <fe-toggle [(value)]="currentIsUsualCustomer"/>
                        </div>

                        <div class="monitoringCOnfirmBtnContainer" (click)="$event.stopPropagation()">
                            <fe-button text="Confirmer" (click)="addMetadataToMonitoringArticle(monitoredArticle,
                            currentIsUsualCustomer,closeDateForm.get('currentClosedDate').value,
                            reminderDateForm.get('currentReminderDate').value,currentStatus, currentAmount,
                            currentComment)"/>
                        </div>
                    </div>
                </mat-menu>
                <button class="button-info" mat-button (click)="openCommentsModal(monitoredArticle.article)" [class.monitoring_new_data_to_read]="monitoredArticle.newActivities">
                    <fe-icon [iconType]="IconType.chat" size="18"
                             [class.newNotif]="monitoredArticle.newActivities"
                             [color]="monitoredArticle.newActivities ? ColorType.lightBlack : ColorType.grey"/>
                    <span [class.lightBlackC]="monitoredArticle.newActivities"
                          [class.bold]="monitoredArticle.newActivities">{{nbComments}}</span>
                </button>
                <button class="button-info" mat-button (click)="openAttachmentsModal(monitoredArticle.article)" [class.monitoring_new_data_to_read]="monitoredArticle.newAttachments">
                    <fe-icon [iconType]="IconType.attachment" size="18"
                             [color]="monitoredArticle.newAttachments ? ColorType.lightBlack : ColorType.grey"
                             [class.newNotif]="monitoredArticle.newAttachments"/>
                    <span [class.lightBlackC]="monitoredArticle.newAttachments"
                          [class.bold]="monitoredArticle.newAttachments">
                        {{this.monitoredArticle.monitoringAttachments.length}}</span>
                </button>
            </div>
        </div>
    </mat-card-content>
</mat-card>
