<mat-form-field style="width: 100%">
<!--    <mat-label>Dates</mat-label>-->
    <mat-date-range-input [rangePicker]="picker" [formGroup]="dateRangeFormGroup">
        <input matStartDate placeholder="Date de début" #dateStartInput formControlName="start">
        <input matEndDate placeholder="Date de fin" #dateEndInput (dateChange)="dateChange(dateStartInput, dateEndInput)" formControlName="end">
    </mat-date-range-input>
<!--    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>-->
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker ></mat-date-range-picker>
</mat-form-field>
