import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {SearchesInterface} from 'src/app/interfaces/searches/searches.interface';
import {SearchesService} from 'src/app/services/searches/searches.service';
import {takeUntil} from 'rxjs/operators';
import {AlertController, ModalController, NavController} from '@ionic/angular';
import {SearchInterface} from 'src/app/interfaces/search/search.interface';
import {ToastService} from 'src/app/services/toast/toast.service';
import {ErrorStatus} from 'src/app/classes/ErrorStatus.class';
import {AuthService} from '../../../services/auth/auth.service';
import {MediaQueryService} from '../../../services/media-query/media-query.service';
import {SearchSummaryComponent} from '../../modals/search/search-summary/search-summary.component';
import {NavigationOptions} from '@ionic/angular/common/providers/nav-controller';
import {Utils} from '../../../classes/Utils';
import {ShareSearchesComponent} from '../../modals/share-searches/share-searches.component';
import {ModalService} from "../../../services/modal/modal.service";
import {SnackbarService} from "../../../services/snackbar/snackbar.service";

@Component({
    selector: 'my-searches-list',
    templateUrl: 'my-searches-list.component.html',
    styleUrls: ['my-searches-list.component.scss'],
})
export class MySearchesListComponent implements OnInit, OnDestroy {

    protected unsubscribe$: Subject<any> = new Subject<any>();
    public mySearches$: Observable<SearchesInterface>;
    public mySearches: SearchesInterface;

    public mySearchList: SearchInterface[];

    constructor(
        public mySearchesService: SearchesService
        , public mediaQueryService: MediaQueryService
        , private alertController: AlertController
        , private navController: NavController
        , private authService: AuthService
        , private modalController: ModalController,
        private modalService: ModalService,
        private snackbarService: SnackbarService
    ) {
    }

    public ngOnInit(): void {
        this.getMySearches();
    }

    /**
     * Load or reload datas
     */
    protected load(): void {
        this.getMySearches();
    }

    /**
     * Get the searches list
     */
    public getMySearches(): void {

        this.mySearches$ = this.mySearchesService.getMySearches();

        this.mySearches$.pipe(
            takeUntil(this.unsubscribe$)
        )
            .subscribe(
                (mySearches: SearchesInterface) => {
                    this.mySearchList = mySearches.searches;
                }
            );
    }

    /**
     * Drag'n'Drop order method
     */
    public drop(event: CdkDragDrop<string[]>) {

        // CDK function to reorder the list
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

        // Then we send the result
        this.mySearchesService.updateSearchesOrder(this.mySearchList).subscribe(
            () => {
            },
            (error: Error) => {
            }
        );
    }

    protected askBeforeRemoveSearch(search: SearchInterface): void {
        this.modalService.confirmModal('Suppression de recherche',
            'Voulez-vous vraiment supprimer la recherche \'' + search.data.label + '\' ?',
                args => {
            this.removeSearch(search);
        });
    }

    /**
     * Remove a search
     * @param id number
     */
    private removeSearch(search: SearchInterface): void {
        this.mySearchesService.removeSearchById(search.searchId).pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(
            () => {
                this.snackbarService.showSuccessSnackbar('Suppression réussie',
                    'La recherche \'' + search.data.label + '\' a été supprimée avec succès.');
                // juste refresh data
                this.load(); // refresh data
            },
            (error: Error | ErrorStatus) => {
                let errorStr: string = 'Une erreur est survenue lors de la suppression de la recherche \'' +
                    search.data.label + '\'.';
                // if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
                //   errorStr += `(${this.translateService.instant(error.message)})`;
                // }
                this.snackbarService.showDangerSnackbar('Erreur lors de la suppression', errorStr);
            }
        );
    }

    /**
     * Call by template. Ask user before duplicate.
     * @param search SearchInterface / Adresse
     */
    protected askBeforeDuplicateSearch(search: SearchInterface): void {
        this.modalService.confirmModal('Duplication de recherche',
            'Voulez-vous vraiment dupliquer la recherche \'' + search.data.label + '\' ?',
                args => {
            this.duplicateSearch(search);
        });
    }

    /**
     * Duplicate a search
     * @param id number
     */
    private duplicateSearch(search: SearchInterface): void {
        this.mySearchesService.duplicateSearchById(search.searchId).pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(
            () => {
                this.snackbarService.showSuccessSnackbar('Duplication réussie',
                    'la recherche \'' + search.data.label + '\' a été dupliquée avec succès.');
                // juste refresh data
                this.load(); // refresh data
            },
            (error: Error | ErrorStatus) => {
                let errorStr: string = 'La duplication de la recherche \'' + search.data.label + '\' a échoué.';
                // if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
                //   errorStr += `(${this.translateService.instant(error.message)})`;
                // }
                this.snackbarService.showDangerSnackbar('Erreur lors de la duplication', errorStr);
            }
        );
    }

    /**
     * Add or edit modal
     */
    public async openSearchPage(type: string, search?: SearchInterface): Promise<any> {
        if (type === 'edit') {
            this.mySearchesService.editMode = true;
            this.mySearchesService.setSearchToEdit(search);
        }
        Utils.navigate('/app/my-searches-add', this.navController);
        // this.navController.navigateForward('/app/my-searches-add');
    }

    public ionViewWillEnter(): void {
        this.load();
    }

    public isRoleCom() {
        return this.authService.roleCom;
    }

    public ngOnDestroy(): void {
        // this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    async openSearchSummary(search: SearchInterface) {
        // this.navController.navigateForward('/app/business-signals?search=' + search.searchId);
        const modal = await this.modalController.create({
            component: SearchSummaryComponent,
            cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog',
            componentProps: {
                search: search
            }
        });
        return modal.present();
    }

    openBusinessFromSearch(search: SearchInterface) {
        const params: NavigationOptions = {
            queryParams: {
                search: search.searchId
            }
        };
        Utils.navigate('/app/business-signals', this.navController, params);
        // this.navController.navigateForward('/app/business-signals', params);
    }


    public async openShareSearchesModal(mySearchList: SearchInterface[]) {
        const modal = await this.modalController.create({
            component: ShareSearchesComponent,
            cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog',
            componentProps: {
                searchesList: mySearchList
            }
        });

        modal.onWillDismiss()
            .then(
                (data: any) => {
                }
            );

        return await modal.present();
    }

    public shareSearches(): void {
        this.openShareSearchesModal(this.mySearchList);
    }
}
