import { Component, OnInit, OnDestroy, Input, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MediaQueryService } from 'src/app/services/media-query/media-query.service';
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/services/user/user.service';
import { AlertController } from '@ionic/angular';
import { ToastService } from 'src/app/services/toast/toast.service';
import { SessionInterface } from 'src/app/interfaces/session/session.interface';
import {ApiService} from '../../services/api/api.service';
import {ModalService} from '../../services/modal/modal.service';

@Component({
  selector: 'my-account',
  templateUrl: 'my-account.component.html',
  styleUrls: ['my-account.component.scss'],
})

export class MyAccountComponent implements OnInit, OnDestroy {

  private unsubscribe$: Subject<any> = new Subject<any>();

  @Input() public sessionData: SessionInterface;
  @Input() public criterions: string;
  public isLettrePersoMC = false;

  constructor(
    public mediaQueryService: MediaQueryService
    , private authService: AuthService
    , private userService: UserService
    , private alertController: AlertController
    , private toastService: ToastService
    , private modalService: ModalService
    , private apiService: ApiService
  ) {}

  public ngOnInit(): void {
    this.userService.getSessionDatas().pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe(
      (sessionData: SessionInterface) => {
        if (sessionData !== undefined) {
          this.sessionData = sessionData;
          if (this.sessionData.productType === 'Lettre_MotsCles') {
            this.isLettrePersoMC = true;
          }
          this.getSessionCriterions();
        }
      }
    );
  }

  public getSessionCriterions(): void {
    if (this.sessionData) {
      this.criterions = this.sessionData.criterions.map(x => x.label).join(', ');
    }
  }

  public ngOnDestroy(): void {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public logout(): void {
    this.authService.logout();
    this.toastService.simple('Vous êtes déconnecté. Vous allez être redirigé vers la page de connexion.', { color: 'toastinfo' });
  }

  public async confirmLogout() {
    const alert = await this.alertController.create({
      header: 'Attention',
      message: '<b>Voulez-vous vraiment vous déconnecter ?</b>',
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => { }
        }, {
          text: 'Oui',
          handler: () => this.logout()
        }
      ]
    });
    await alert.present();
  }

  public getMobileLoginInfos(): void {
    // const alert = await this.alertController.create({
    //   header: 'Connexion à l\'application mobile',
    //   message: 'Pour vous connecter à l\'application mobile, vous devez utiliser : <br> - un login qui est votre adresse email<br>' +
    //       ' - un mot de passe généré automatiquement<br><br>' +
    //       'Merci de noter que pour garantir la sécurité de vos données, vous devez désormais utiliser votre login et votre mot de passe pour vous connecter à votre compte First ECO sur votre application mobile et sur votre ordinateur.<br><br>' +
    //       'J\'ai bien compris et je souhaite recevoir mes identifiants par mail.',
    //   buttons: [
    //     {
    //       text: 'Valider',
    //       handler: () => this.sendMobileLoginRequest(),
    //       cssClass: 'validateButton'
    //     },
    //     {
    //       text: 'Annuler',
    //       role: 'cancel',
    //       cssClass: 'cancelButton',
    //       handler: () => { }
    //     }
    //   ],
    //   cssClass: 'alertLoginInfos'
    // });
    // await alert.present();

    this.modalService.confirmModal('Connexion à l\'application mobile', 'Pour vous connecter à l\'application mobile, vous devez utiliser : <br> - un login qui est votre adresse email<br>' +
        ' - votre mot de passe<br><br>' +
        'Merci de noter que pour garantir la sécurité de vos données, vous devez désormais utiliser votre login et votre mot de passe pour vous connecter à votre compte First ECO sur votre application mobile et sur votre ordinateur.<br><br>' +
        'J\'ai bien compris et je souhaite recevoir mes identifiants par mail.',
        args => {
          this.sendMobileLoginRequest();
        });
  }

  private sendMobileLoginRequest() {
    this.apiService.get('v1/session/receiveMobileAccess').subscribe(next => {
      this.toastService.simple('Un mail vous a été envoyé avec les informations demandées.', { duration: 2500 });
    });
  }
}
