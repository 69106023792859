import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';


@Injectable()
export class CustomHttpInterceptorService implements HttpInterceptor {

  private ngProgressRef: NgProgressRef;

  constructor(
    private ngProgress: NgProgress
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.ngProgressRef = this.ngProgress.ref('headerProgressBar');

    return next.handle(req).pipe(
      tap(
        (evt) => {
          this.ngProgressRef.start();
          if (evt instanceof HttpResponse) { }
        },
        (error: any) => {
          this.ngProgressRef.complete();
          if (error instanceof HttpErrorResponse) {
          }
        }
      ),
      finalize(() => {
        this.ngProgressRef.complete();
      })
    );
  }

}
