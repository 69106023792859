import {Component, Input, OnInit} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {MediaQueryService} from '../../../services/media-query/media-query.service';
import {UserService} from '../../../services/user/user.service';

@Component({
  selector: 'app-communication-modal',
  templateUrl: './communication-modal.component.html',
  styleUrls: ['./communication-modal.component.scss'],
})
export class CommunicationModalComponent implements OnInit {

  communications: any;
  communicationText: string;
  communicationTitle: string;
  hideButton: boolean;

  constructor(private modalController: ModalController
      , private navParams: NavParams
      , public mediaQueryService: MediaQueryService
  , private userService: UserService) { }

  ngOnInit() {
    this.communications = this.navParams.get('communications');
    // for (const com of this.communications) {
      this.communicationText = this.communications.message;
      this.communicationTitle = this.communications.title;
      this.hideButton = this.communications.hideButton;
    // }
  }

  public close(): void {
    this.modalController.dismiss();
  }

  decline() {
    this.userService.declineCommunication(this.communications);
    this.modalController.dismiss();
  }
}
