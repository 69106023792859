import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { ContactInterface } from 'src/app/interfaces/contact/contact.interface';
import { MediaQueryService } from 'src/app/services/media-query/media-query.service';
import { takeUntil } from 'rxjs/operators';
import { ArticlesService } from 'src/app/services/articles/articles.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ErrorStatus } from 'src/app/classes/ErrorStatus.class';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'article-contacts-details',
  templateUrl: 'article-contacts-details.component.html',
  styleUrls: ['article-contacts-details.component.scss'],
})
export class ArticleContactsDetailsComponent implements OnInit, OnDestroy {

  @Input() public contacts: Array<ContactInterface>;
  @Input() public articleId: number;
  @Input() public type: string = 'list'; // list or modal

  private unsubscribe$: Subject<any> = new Subject<any>();

  public numberOfContacts: number;

  constructor(
    public mediaQueryService: MediaQueryService
    , public articlesService: ArticlesService
    , public toastService: ToastService
  ) {}

  public ngOnInit(): void {
    if (Array.isArray(this.contacts) && this.contacts.length > 0) {
      this.numberOfContacts = this.contacts.length;
      // iterate on contact to get subContact and count them.
      this.contacts.forEach((contact: ContactInterface) => {
        if (Array.isArray(contact.subContacts) && contact.subContacts.length > 0) {
          this.numberOfContacts += contact.subContacts.length;
        }
      });
    }
  }

  public exportArticleWebToLead(articleId: number, contact: ContactInterface) {

    // Remove subContacts for CRM export
    if (contact.hasOwnProperty('subContacts')) {
      delete contact.subContacts;
    }

    this.articlesService.exportArticleWebToLead(articleId, contact)
    .pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      () => {
        this.toastService.simple('L\'export WebToLead a été effectué.');
      },
      (her: HttpErrorResponse) => {
        let errorStr: string;
        if (typeof her !== 'undefined' && typeof her.error.message !== 'undefined' && her.error.message !== null && her.error.message !== '') {
          errorStr = her.error.message;
        } else {
          errorStr = 'Une erreur est survenue lors de l\'export WebToLead. Veuillez réessayer ultérieurement.';
        }
        this.toastService.simple(errorStr, { color: 'toasterror' });
      }
    );
  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  logWebsiteClick() {

  }
}
