<div class="feArticleModal feModal">
    <div class="feModalHeader">
        <div class="infosContainer">
            <div *ngIf="isPerformance && !isSupervisor && article.monitoring?.status !== undefined">
                <div class="infosItem">
                    <span>Suivi</span>
                    <mat-icon>dashboard</mat-icon>
                </div>
            </div>
            <div *ngIf="isPerformance && isSupervisor && article.monitoring?.status !== undefined || listAssociatedCustomers.length > 0" matTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="{{shareArticleToolTip}}">
                <div class="infosItem">
                    <span *ngIf="article.monitoring?.status !== undefined && listAssociatedCustomers.length === 0">Suivi par moi</span>
                    <span *ngIf="article.monitoring?.status === undefined && listAssociatedCustomers.length > 0">Suivi par d'autres</span>
                    <span *ngIf="article.monitoring?.status !== undefined && listAssociatedCustomers.length > 0">Suivi par moi et d'autres</span>
                    <mat-icon>dashboard</mat-icon>
                </div>
            </div>
            <div class="infosItem" *ngIf="article?.favorite">
                <span>Favoris</span>
                <mat-icon>star</mat-icon>
            </div>
            <div class="infosItem" *ngIf="article.sharedTo !== undefined"
                 matTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="Article déjà envoyé à :&#013;{{article.sharedTo.join('&#013;')}}">
                <span>Envoyé</span>
                <mat-icon>mark_email_read</mat-icon>
            </div>
            <div class="infosItem" *ngIf="article.read">
                <span>Lu</span>
                <mat-icon>check</mat-icon>
            </div>
        </div>
        <button style="margin-left: 24px;" mat-icon-button class="feDotsMenu"
                [matMenuTriggerFor]="actionsOnArticleMenu">
            <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #actionsOnArticleMenu="matMenu" class="actionsOnArticleMenu" xPosition="before">
            <button *ngIf="isPerformance && from !== 'monitoring'" (click)="addToSuiviAffaires()" mat-menu-item>
                <fe-icon [iconType]="IconType.dashboard_add"/>
                <span class="blueBoldText">Ajouter au suivi d’affaires</span>
            </button>
            <button *ngIf="isPerformance && from === 'monitoring' && !isVisualisation" (click)="archiveMonitoredArticle(article)" mat-menu-item>
                <fe-icon [iconType]="IconType.dashboard"/>
                <span class="blueBoldText">Suivi d'affaires : {{this.article.monitoring.isArchived ? 'désarchiver' : 'archiver '}}</span>
            </button>
            <button *ngIf="isPerformance && from === 'monitoring' && !isVisualisation && typeLead" (click)="openLeadCreationEditModal();" mat-menu-item>
                <fe-icon [iconType]="IconType.dashboard"/>
                <span class="blueBoldText">Editer les informations du lead externe</span>
            </button>
            <button *ngIf="!article.favorite" (click)="addToFavorite()" mat-menu-item>
                <fe-icon [iconType]="IconType.star"/>
                <span class="blueBoldText">Ajouter en favoris</span>
            </button>
            <button *ngIf="article.favorite" mat-menu-item [matMenuTriggerFor]="gestionFavoris">
                <fe-icon [iconType]="IconType.star_full"/>
                <span class="blueBoldText">Gestion des favoris</span>
            </button>
            <button mat-menu-item [matMenuTriggerFor]="envoiRapideMenu">
                <fe-icon [iconType]="IconType.send_fast_mail"/>
                <span class="blueBoldText">Partage rapide par email</span>
            </button>
            <button (click)="showSendArticleModal()" mat-menu-item>
                <fe-icon [iconType]="IconType.send_mail"/>
                <span class="blueBoldText">Envoyer par email</span>
            </button>
            <button *ngIf="!article.read" (click)="markArticleRead(true)" mat-menu-item>
                <fe-icon [iconType]="IconType.eye_close"/>
                <span class="blueBoldText">Marquer comme lu</span>
            </button>
            <button *ngIf="article.read" (click)="markArticleRead(false)" mat-menu-item>
                <fe-icon [iconType]="IconType.eye_open"/>
                <span class="blueBoldText">Marquer comme non-lu</span>
            </button>
            <button (click)="printArticles()" mat-menu-item>
                <fe-icon [iconType]="IconType.print"/>
                <span class="blueBoldText">Imprimer</span>
            </button>
        </mat-menu>

        <mat-menu #gestionFavoris="matMenu" class="matMenuLimitHeight" direction="down" yPosition="below" [hasBackdrop]="false">
            <ng-container *ngFor="let favorite of myFavoritesList">
                <button *ngIf="article.favoriteFolder === favorite.folderID" mat-menu-item style="background-color: #e3e4e8">
                    <i>
                        Actuellement dans "{{favorite.libelle}}"
                    </i>
                </button>
            </ng-container>
            <fe-menu-item text="Retirer des favoris" color="DANGER" [icon]="IconType.delete"
                          (click)="deleteFavorite([article.id])"/>
            <ng-container *ngFor="let favorite of myFavoritesList">
                <fe-menu-item *ngIf="article.favoriteFolder !== favorite.folderID" [bold]="false"
                              [text]="'Déplacer vers ' + favorite.libelle" color="PRIMARY"
                              [icon]="IconType.move_folder" (click)="changeFavoriteFolder(favorite, article.id)"/>
            </ng-container>
        </mat-menu>

        <mat-menu #envoiRapideMenu="matMenu" class="matMenuLimitHeight" direction="down"
                  yPosition="below" [hasBackdrop]="false">
            <fe-menu-item [bold]="true" text="Carnet d'adresses" color="PRIMARY"
                          (click)="openCarnetAddress()"/>
            <fe-menu-item *ngFor="let contactLine of contactsLines" [bold]="false"
                          [text]="'Partager à ' + getContactLibelle(contactLine)" color="PRIMARY"
                          (click)="sendFastMail(contactLine, article.id)"/>
        </mat-menu>

        <div class="feModalCloseButton" (click)="closeDialog()">
            <span>Fermer</span>
            <mat-icon>close</mat-icon>
        </div>
    </div>
    <div style="overflow: auto">
        <div class="feModalContent" *ngIf="!isLoading">
            <div class="leftPart">
                <div class="articleContent">
                    <span class="moderateTextBold date">{{getDate()}}</span>
                    <span class="veryBigTitleBold" [innerHTML]="sanitizer.bypassSecurityTrustHtml(getHighlightedText(article.title))">
                    </span>

                    <span (click)="handleAnchorClick($event)" [innerHTML]="getArticleCorps()" class="moderateText"></span>
                    <div class="sourcesContainer" *ngIf="article.precisions">{{article.precisions}}</div>
                    <span *ngIf="article.sources" [innerHTML]="getSources()" class="littleText sourcesContainer"></span>
                </div>
                <div style="display: flex; flex-direction: column; gap: 4px;" *ngIf="fromFavorite">
                    <span class="moderateTextBold" style="margin-left: 4px;">Commentaire</span>
                    <textarea [(ngModel)]="article.data.comment" (focusout)="updateArticleComment()" class="feTextArea"></textarea>
                    <fe-toggle text="Je ne veux pas que ce commentaire soit visible en cas de partage"
                               class="flexNone" style="margin-top: 2px;" [(value)]="article.data.commentIsPrivate"
                               (valueChange)="updateArticleComment()" littleMode="true"/>
                </div>
                <div class="tagsContainer" *ngIf="article.tags">
    <!--                <div class="primaryTagsContainer">-->
    <!--                    <div class="tagContainerName">-->
    <!--                        <mat-icon>key</mat-icon>-->
    <!--                        <span class="moderateText">Mots clés ayant permis de proposer l’article</span>-->
    <!--                    </div>-->
    <!--                    <span class="tag" *ngFor="let tag of article.tags.locations">{{tag.label}}</span>-->
    <!--                </div>-->
                    <div class="secondaryTagsContainer">
                        <div class="tagContainerName">
                            <fe-icon [iconType]="IconType.tag_localite" [color]="ColorType.blue"/>
                            <span class="moderateText">Localités</span>
                        </div>
                        <span [class.tagMatchSearch]="tag.matchSearch" class="tag" *ngFor="let tag of article.tags.locations" [innerHTML]="sanitizer.bypassSecurityTrustHtml(getHighlightedText(tag.label))"></span>
                    </div>
                    <div class="secondaryTagsContainer">
                        <div class="tagContainerName">
                            <fe-icon [iconType]="IconType.tag_secteur" [color]="ColorType.blue"/>
                            <span class="moderateText">Secteurs</span>
                        </div>
                        <span [class.tagMatchSearch]="tag.matchSearch" class="tag" *ngFor="let tag of article.tags.sectors" [innerHTML]="sanitizer.bypassSecurityTrustHtml(getHighlightedText(tag.label))"></span>
                    </div>
                    <div class="secondaryTagsContainer">
                        <div class="tagContainerName">
                            <fe-icon [iconType]="IconType.tag_theme" [color]="ColorType.blue"/>
                            <span class="moderateText">Événements économiques</span>
                        </div>
                        <span [class.tagMatchSearch]="tag.matchSearch" class="tag" *ngFor="let tag of article.tags.topics" [innerHTML]="sanitizer.bypassSecurityTrustHtml(getHighlightedText(tag.label))"></span>
                    </div>
                    <div class="secondaryTagsContainer leadsContainer">
                        <div class="tagContainerName" [class.displayNone]="article.predictions == null
                        || article.predictions.length < 1">
                            <fe-icon [iconType]="IconType.leads" [color]="ColorType.blue"/>
                            <span class="moderateText">Leads prédictifs</span>
                        </div>
                        <span class="tag articlePrediction" *ngFor="let lead of article.predictions"
                              [class.articlePredictionSelected]="currentPrediction?.id == lead.id"
                              [innerHTML]="sanitizer.bypassSecurityTrustHtml(getHighlightedText(lead.label + '&nbsp;<b>('
                              + lead.percentage + '%)</b>'))" [class.matchingLead]="lead.matchSearch"
                              (click)="showLeadsDescription(lead)"
                              [class.displayNone]="hideLeads && from === 'monitoring'"></span>
                        <fe-button text="Voir les tags" smallButton="true" type="SECONDARY"
                                   (click)="showLeads()" bold="true"
                                   [class.displayNone]="!hideLeads || from !== 'monitoring'"/>
                    </div>
                    <div class="leadsDescription" id="articleLeadsDesc" [class.displayNone]="currentPrediction == null">
                        <div [innerHtml]="currentPrediction?.prescription"></div>
                    </div>
                </div>

            </div>
            <div class="rightPart" *ngIf="article.entreprises">
                <div class="entreprisesTitle">
                    <fe-icon [iconType]="IconType.entreprise" [color]="ColorType.lightBlack"/>
                    <span *ngIf="article.entreprises.length > 1">Entreprises concernées :</span>
                    <span *ngIf="article.entreprises.length === 1">Entreprise concernée :</span>
                </div>
                <div class="feArticleEntrepriseItem" *ngFor="let entreprise of article.entreprises"
                     (click)="showFicheEntreprise(entreprise)">
                    <span class="moderateTextBold">{{entreprise.name}}</span>
                </div>
            </div>
        </div>


        <div *ngIf="from === 'monitoring'" style="margin-top: 10px;">
            <mat-tab-group [selectedIndex]="selectedMatTab.value" (selectedIndexChange)="selectedMatTab.setValue($event)"
                           mat-stretch-tabs="false" class="matTabMonitoring" id="matTabMonitoring">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="feMatTabTitle">
                            <span style="margin-right: 8px;">Commentaires & activités</span>
                            <fe-icon [color]="selectedMatTab.value == 0 ? ColorType.blue : ColorType.grey"
                                     [iconType]="IconType.chat"/>
                            <span style="margin-left: 4px;"> {{(nbComments | async) }}</span>
                        </div>
                    </ng-template>

                    <!--  Bloc Commentaires Monitoring  -->
                    <div style="border: 1px darkgrey solid; padding: 15px;" class="marginModal greyBorderTop monitoringDataContainer"
                         *ngIf="from === 'monitoring'">
                        <div style="display: flex; margin-bottom: 15px;" *ngIf="!article.monitoring.isVisualisation">
                            <div style="margin: 25px 15px 15px 5px;">
                                <span style="background: #273C4E;" class="userAbbrv">{{authorAbbreviation(this.customerFirstname, this.customerLastname)}}</span>
                            </div>
                            <angular-editor style="width: 100%;" [placeholder]="'Commentaire...'"
                                            [(ngModel)]="commentContent" [config]="config"></angular-editor>
                        </div>
                        <div class="addDataContainer" *ngIf="!article.monitoring.isVisualisation">
                            <fe-button *ngIf="!article.monitoring.isVisualisation" type="PRIMARY" [text]="'Ajouter'" (click)="shareComment(commentContent)" />
                        </div>

                        <div class="commentAndActivityContainer">
                            <ng-container *ngIf="(monitoredArticleActivities$ | async); else loading">
                                <ng-container *ngIf="(monitoredArticleActivities | async)?.length; else noActivityData">
                                    <div class="commentBox"
                                         *ngFor="let activity of (monitoredArticleActivities | async)">
                                        <div id="commentParentBloc" style="display: flex; align-items: center;">
                                            <div style="margin:4px 10px 4px 5px;"><span
                                                    style="background: var(--grey);"
                                                    class="userAbbrv">{{authorAbbreviation(activity.authorFirstName, activity.authorLastName)}}</span>
                                            </div>
                                            <div style="margin: auto 0px;" *ngIf="activity.activityType == 'comment'">
                                                <span style="font-weight: bold; color: #273C4E;">{{activity.authorFirstName}} {{activity.authorLastName}} </span>
                                                &nbsp;
                                                <span style="font-size: 0.9em; color: #7F7F7F;">{{this.formatAddingDate(activity.activityDate)}}</span>
                                                <div style="margin:6px;" [innerHTML]="activity.activity"></div>
                                            </div>
                                            <div style="margin: auto 0px;" *ngIf="activity.activityType == 'history'">
                                                <span style="font-weight: bold; color: #273C4E;">{{activity.authorFirstName}} {{activity.authorLastName}} </span>
                                                <span [innerHTML]="activity.activity"></span>
                                                <div style="font-size: 0.85em; color:#7F7F7F;">{{this.formatAddingDate(activity.activityDate)}}</div>
                                            </div>
                                            <div class="hidden-commentButtons"
                                                 *ngIf="activity.activityType == 'comment' && (activity.isAuthor || isSupervisor)"
                                                 style="margin: 8px 8px auto auto;">
                                                <fe-dots-menu (click)="$event.stopPropagation()">
                                                    <fe-menu-item text="Éditer le commentaire" color="PRIMARY"
                                                                  [icon]="IconType.edit" (onClick)="editMonitoredArticleComment(activity)"/>
                                                    <fe-menu-item text="Supprimer le commentaire" color="DANGER"
                                                                  [icon]="IconType.delete" (onClick)="deleteMonitoredArticleComment(activity)"/>
                                                </fe-dots-menu>
                                            </div>

                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-template #loading>
                                <fe-loader loaderText="Chargement des activités"/>
                            </ng-template>
                            <ng-template #noActivityData>
                                <no-data>Il n'y a pas encore d'activité pour cet article</no-data>
                            </ng-template>
                        </div>
                    </div>

                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="feMatTabTitle">
                            <span style="margin-right: 8px;">Pièces-jointes</span>
                            <fe-icon  [color]="selectedMatTab.value == 1 ? ColorType.blue : ColorType.grey"
                                      [iconType]="IconType.attachment"/>
                            <span style="margin-left: 4px;">{{(monitoredArticleAttachments | async)?.length }}</span>
                        </div>
                    </ng-template>

                    <!-- Bloc Pièces-jointes Monitoring -->
                    <div class="marginModal greyBorderTop monitoringDataContainer"
                         *ngIf="from === 'monitoring'">
                        <div class="addDataContainer" *ngIf="!isVisualisation">
                            <fe-button text="Ajouter une pièce-jointe" (click)="shareAttachment()" />
                        </div>
                        <div class="commentAndActivityContainer">
                            <ng-container
                                    *ngIf="(monitoredArticleAttachments$ | async); else loadingAttachments">
                                <ng-container
                                        *ngIf="(monitoredArticleAttachments | async)?.length; else noAttachmentData">
                                    <div *ngFor="let monitoredArticleAttachment of (monitoredArticleAttachments | async)"
                                         class="attachmentLine">
                                        <fe-button type="TERTIARY" smallButton="true" class="feOverflowBtn"
                                                   (click)="openFile(monitoredArticleAttachment)"
                                                   [text]="monitoredArticleAttachment.filename"/>
                                        <div style="margin-left: auto; display: flex; gap: 8px; align-items: center;">
                                            <span style="width: max-content">{{ this.attachmentAddingText(monitoredArticleAttachment) }}</span>
                                            <ng-container *ngIf="monitoredArticleAttachment.isAuthor">
                                                <fe-button hideText="true" *ngIf="!article.monitoring.isVisualisation"
                                                           (click)="editMonitoredArticleAttachmentVisibility(monitoredArticleAttachment)"
                                                           icon="true" [iconType]="monitoredArticleAttachment.private
                                                           ? IconType.eye_off : IconType.eye_open" type="TERTIARY"/>
                                                <fe-button hideText="true" [iconType]="IconType.delete" icon="true"
                                                           type="TERTIARY"
                                                           (click)="deleteMonitoredArticleAttachment(monitoredArticleAttachment)"/>
                                            </ng-container>
                                        </div>
                                    </div>

                                </ng-container>
                            </ng-container>
                        </div>
                        <ng-template #loadingAttachments>
                            <fe-loader loaderText="Chargement des pièces-jointes"/>
                        </ng-template>
                        <ng-template #noAttachmentData>
                            <div style="margin-bottom: 25px;">
                                <no-data>Aucune pièce-jointe disponible</no-data>
                            </div>
                        </ng-template>
                    </div>

                </mat-tab>
            </mat-tab-group>
        </div>

        <fe-loader *ngIf="isLoading" loaderText="Chargement de l'article en cours..."
                   style="margin: 20vh auto; display: flex"/>
    </div>
</div>
