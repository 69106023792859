import {Component, EventEmitter, Inject, Input, LOCALE_ID, Output, ViewChild} from '@angular/core';
import {CriterionInterface} from '../../../interfaces/criterions/criterion.interface';
import {FormControl, FormGroup} from '@angular/forms';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-fe-date-picker',
  templateUrl: './fe-date-picker.component.html',
  styleUrls: ['./fe-date-picker.component.css']
})
export class FeDatePickerComponent {

  @ViewChild('dateStartInput') dateStartInput: HTMLInputElement;
  @ViewChild('dateEndInput') dateEndInput: HTMLInputElement;

  private currentDateStart: string;
  private currentDateEnd: string;

  dateRangeFormGroup = new FormGroup({
    start: new FormControl(new Date(2023, 12, 1)),
    end: new FormControl(new Date(2023, 12, 31)),
  });

  @Output() emitter = new EventEmitter();
  private start: Date;
  private end: Date;
  @Input() public set dateStart(value: string) {
    this.start = new Date(value);
    this.dateRangeFormGroup = new FormGroup({
      start: new FormControl(this.start),
      end: new FormControl(this.end),
    });
    if (this.start && this.end) {
      this.currentDateStart = formatDate(new Date(this.start), 'dd-MM-yyyy', this.locale);
      this.currentDateEnd = formatDate(new Date(this.end), 'dd-MM-yyyy', this.locale);
    }

    // this.dateStartInput.value = value.split('-').join('/');
  }

  @Input() public set dateEnd(value: string) {
    this.end = new Date(value);
    this.dateRangeFormGroup = new FormGroup({
      start: new FormControl(this.start),
      end: new FormControl(this.end),
    });
    if (this.start && this.end) {
      this.currentDateStart = formatDate(new Date(this.start), 'dd-MM-yyyy', this.locale);
      this.currentDateEnd = formatDate(new Date(this.end), 'dd-MM-yyyy', this.locale);
    }
    // this.dateEndInput.value = value.split('-').join('/');
  }

  constructor(@Inject(LOCALE_ID) public locale: string) {
  }

  dateChange(dateStartInput: HTMLInputElement, dateEndInput: HTMLInputElement) {
    this.currentDateStart = dateStartInput.value.split('/').join('-');
    this.currentDateEnd = dateEndInput.value.split('/').join('-');
    if (this.currentDateStart && this.currentDateStart.length > 0 && this.currentDateEnd && this.currentDateEnd.length > 0) {
      this.emitter.emit({dateStart: this.currentDateStart, dateEnd: this.currentDateEnd});
    }
  }

  getDates() {
    return {dateStart: this.currentDateStart, dateEnd: this.currentDateEnd};
  }
}
