import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {combineLatest, Observable, Subject} from 'rxjs';
import {ModalController, NavParams, ToastController} from '@ionic/angular';
import {XFormComponent} from '../../x-form/x-form.component';
import {MediaQueryService} from 'src/app/services/media-query/media-query.service';
import {ToastService} from 'src/app/services/toast/toast.service';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER, SEMICOLON, TAB} from '@angular/cdk/keycodes';
import {AddressesService} from 'src/app/services/addresses/addresses.service';
import {ArticlesService} from 'src/app/services/articles/articles.service';
import {ArticleInterface} from 'src/app/interfaces/articles/article.interface';
import {MailPreviewInterface} from 'src/app/interfaces/mail-preview/mail-preview.interface';
import {AddressesInterface} from 'src/app/interfaces/addresses/addresses';
import {AddressInterface} from 'src/app/interfaces/address/address';
import {SelectionModel} from '@angular/cdk/collections';
import {ErrorStatus} from 'src/app/classes/ErrorStatus.class';
import {UserService} from '../../../services/user/user.service';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
  selector: 'email-article',
  templateUrl: 'email-article.modal.html',
  styleUrls: ['email-article.modal.scss'],
})
export class EmailArticleModal implements OnInit, OnDestroy {

  protected unsubscribe$: Subject<any> = new Subject<any>();

  @ViewChild(XFormComponent, { static: true }) public formComponent: XFormComponent;
  @ViewChild('htmlDiv') htmlDiv: ElementRef;

  // Article parameters, one article or multiple articles
  public article: ArticleInterface;
  public articles: Array<number>;
  public html: string;

  // Articles IDs list
  private articlesId: Array<number>;

  public formSubmitMethod: Function;

  public visible = true;
  public selectable = true;
  public removable = true;
  public separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON, TAB];

  // 1 click send
  public selectionModel: SelectionModel<AddressInterface> = new SelectionModel<AddressInterface>(true, []);
  public addresses$: Observable<AddressesInterface>;

  // Advanced Click Send
  public advancedSelectionModel: SelectionModel<AddressInterface> = new SelectionModel<AddressInterface>(true, []);

  // Customized send
  public checked = false;
  public addressCtrl = new FormControl();
  public addressCtrlFilter = new FormControl();
  public addressCtrlFilterOneClick = new FormControl();
  public filteredAddresses: Observable<Array<AddressInterface>>;
  public filteredAddressesOneClick: Observable<Array<AddressInterface>>;
  // public filteredAddresses: Array<AddressInterface>;
  public selectedAddresses: Array<AddressInterface> = new Array<AddressInterface>();
  public allAddresses: Array<AddressInterface>;


  @ViewChild('objectInput', {static: true}) objectInput: ElementRef<HTMLInputElement>;
  @ViewChild('addressInput', {static: true}) addressInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: true}) matAutocomplete: MatAutocomplete;
  @ViewChild('addChecked', {static: true}) addChecked: ElementRef;
  private trialDiv: Element;
  private trialDivParent: HTMLElement;

  public selectAllAddressesDisabled: boolean;

  constructor(
      private modalController: ModalController
      , private navParams: NavParams
      , public mediaQueryService: MediaQueryService
      , public toastController: ToastController
      , public toastService: ToastService
      , public addressesService: AddressesService
      , public articlesService: ArticlesService
      , private userService: UserService
  ) {
    this.selectAllAddressesDisabled = false;
  }

  public ngOnInit(): void {

    // Get article parameters
    this.article = this.navParams.get('article');
    this.articles = this.navParams.get('articles');

    // Récupérer les adresses
    this.addresses$ = this.addressesService.getAddresses();
    this.addresses$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      (res: AddressesInterface) => {
        this.allAddresses = res.address;
        // this.filteredAddresses = this.allAddresses;
        this.filteredAddresses = this.addressCtrlFilter.valueChanges.pipe(
          startWith(null),
          map((address: string | null) => {
            return address ? this._filter(address) : this.allAddresses.slice();
          }
        ));
        this.filteredAddressesOneClick = this.addressCtrlFilterOneClick.valueChanges.pipe(
            startWith(null),
            map((address: string | null) => {
                  return address ? this._filter(address) : this.allAddresses.slice();
                }
            ));
      }
    );

    if (this.articles != null) {
      this.articlesId = this.articles;
    } else if (this.article != null) {
      this.articlesId = [this.article.id];
    }

    // Get article(s) HTML content
    this.articlesService.emailPreviewArticlesList(this.articlesId).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      (res: MailPreviewInterface) => {
        const htmlObject = document.createElement('div');
        htmlObject.innerHTML = res.html;
        const commentTextDivs: any = htmlObject.getElementsByClassName('comment-text');
        for (const div of commentTextDivs) {
          const currentCom = div.innerHTML;
          div.innerHTML = '';
          const txtArea = document.createElement('textarea');
          txtArea.setAttribute('style', 'width:100%;');
          txtArea.innerHTML = currentCom;
          div.appendChild(txtArea);
        }
        // remove trial
        this.trialDiv = htmlObject.getElementsByClassName('footer').item(0);
        this.trialDiv.setAttribute('style', this.trialDiv.getAttribute('style') + 'display:none;');
        // this.trialDivParent = this.trialDiv.parentElement;
        // this.trialDiv.remove();

        this.html = htmlObject.innerHTML;
      }
    );
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our address
    if ((value).trim()) {
      if (this.validateEmail((value).trim())) {
        this.selectedAddresses.push({
          data: {
            email: value.trim()
          }
        });
      // } else {
      //   this.selectedAddresses.push({
      //     data: {
      //       email: value.trim()
      //     }
      //   });
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    // this.addressCtrl.setValue(null);
  }

  /**
   * Ajout mail dans la liste des adresses sélectionnés
   * @param address
   */
  addAddress(address): void {
    const value = address.data.email;
    // Add our address
    if ((value).trim()) {
      if (this.validateEmail((value).trim())) {
        this.selectedAddresses.push({
          data: {
            email: value.trim()
          }
        });
        // Sélection Checkbox
        this.advancedSelectionModel.select(address);
      }
    }
    // Reset the input value
    // if (address) {
    //   input.value = '';
    // }
  }

  remove(address: AddressInterface): void {
    // const index = this.selectedAddresses.indexOf(address);
    // Get the index for AddressInterface objects
    const index = this.selectedAddresses.map((a: AddressInterface) => a.data.email).indexOf(address.data.email);

    if (index >= 0) {
      this.selectedAddresses.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {

    // this.selectedAddresses.push(event.option.value);
    // this.addressCtrl.setValue(null);
    const add: AddressInterface =  {
      addressId: 0,
      data: { label: this.addressInput.nativeElement.value, email: this.addressInput.nativeElement.value }
    };
    this.allAddresses.push(add);
    this.addressInput.nativeElement.value = '';
  }

  private _filter(value: string): Array<AddressInterface> {
    const filterValue = value;
    this.selectAllAddressesDisabled = filterValue !== '';
    return this.allAddresses.filter(address => {
          if (address.data !== undefined) {
            return address.data.label.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()) || address.data.email.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase());
          } else {
            return false;
          }
        }
    );
  }

  private validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  public sendMail(oneClick: boolean): void {
    if (oneClick) {
      this.oneClickSend(this.selectionModel.selected);
    } else {
      this.advancedClickSend(this.advancedSelectionModel.selected);
    }
  }

  // "1 click" button action
  public oneClickSend(addresses: Array<AddressInterface>): void {

    const ids: Array<number> = addresses.map((address: AddressInterface) => {
      return address.addressId;
    });

    if (this.articles != null) {
     this.advancedClickSend(addresses);
    } else if (this.article != null) {
      const arrayAddressesToSend: Array<Observable<any>> = ids.map((addressId: number) => {
        return this.articlesService.emailArticleById(addressId, this.article.id);
      });

      combineLatest(arrayAddressesToSend).pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(
        () => {
          this.toastService.simple('L\'article a été envoyé par e-mail avec succès.');
          // clear selectionModel to active disabled button when selectionModel is set
          this.selectionModel.clear();
          // Close the modal
          this.close();
        },
        (error: Error | ErrorStatus) => {
          let errorStr: string = 'Une erreur est survenue lors du partage par e-mail de l\'article. Veuillez réessayer ultérieurement.';
          // if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
          //   errorStr += `(${this.translateService.instant(error.message)})`;
          // }
          this.toastService.simple(errorStr, { color: 'toasterror' });
        }
      );
    }
  }

  // "Advanced click" button action
  public advancedClickSend(addresses: Array<AddressInterface>) {

    // Address List
    const addressesList: Array<string> = addresses.filter(a => a.addressId === undefined || a.addressId === 0).map((address: AddressInterface) => {
      return address.data.email;
    });
    const knownAddresses: Array<number> = addresses.filter(a => a.addressId !== undefined && a.addressId !== 0).map((address: AddressInterface) => {
      return address.addressId;
    });

    // E-mail Object
    const obj = this.objectInput.nativeElement.value ? this.objectInput.nativeElement.value : 'Article issu de First ECO';

    // Rechange textArea to text
    const commentTextDivs: any = this.htmlDiv.nativeElement.getElementsByClassName('comment-text');
    for (let i = commentTextDivs.length - 1; i >= 0; --i) {
      for (const child of commentTextDivs[i].children) {
        if(child.tagName === 'TEXTAREA') {
          // const txt = commentTextDivs[i].firstChild.value;
          if (child.value === undefined || child.value.length <= 0) {
            commentTextDivs[i].parentNode.parentNode.removeChild(commentTextDivs[i].parentNode);
            // break;
          } else {
            try {
              commentTextDivs[i].innerHTML = child.value;
            } catch (e) {
            }
            // break;
          }
        }
      }
    }

    this.htmlDiv.nativeElement.getElementsByClassName('footer').item(0).setAttribute('style', this.trialDiv.getAttribute('style').replace('display:none;', ''));
    // this.trialDivParent.appendChild(this.trialDiv);

    // Query
    const emailQuery = {
      articlesIdList: this.articlesId,
      messageHtml: this.htmlDiv.nativeElement.innerHTML,
      receiveCopy: this.checked,
      subject: obj,
      unknownAddresses: addressesList,
      knownAddresses: knownAddresses
    };

    if (this.article !== undefined) {
      this.userService.log('ARTICLE_MAIL', [this.article]);
    } else {
      this.userService.logMultiArticles('ARTICLE_MAIL', this.articles);
    }

    this.articlesService.emailArticleAdvanced(emailQuery).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      () => {
        this.toastService.simple('L\'article a été envoyé par e-mail avec succès.');
        // clear selectionModel to active disabled button when selectionModel is set
        this.advancedSelectionModel.clear();
        // Close the modal
        this.close();
      },
      (error: Error | ErrorStatus) => {
        let errorStr: string = 'Une erreur est survenue lors du partage par e-mail de l\'article. Veuillez réessayer ultérieurement.';
        // if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
        //   errorStr += `(${this.translateService.instant(error.message)})`;
        // }
        this.toastService.simple(errorStr, { color: 'toasterror' });
      }
    );
  }

  public highlightSelection() {
    let selectedText;
    if (window.getSelection) {
      selectedText = window.getSelection();
      if (selectedText.rangeCount) {
        const stringtxt = selectedText.toString();
        const range = selectedText.getRangeAt(0);
        range.deleteContents();
        const resString = stringtxt.replace('’', '\'').replace(stringtxt, '<span class="highlight" style="background-color: yellow !important;">' + stringtxt + '</span>');
        // const resString = stringtxt.replace('’', '\'').replace(stringtxt, '<span style="background-color: yellow !important;">' + stringtxt + '</span>');
        const temp = document.createElement('div');
        temp.innerHTML = resString;
        range.insertNode(temp.firstChild);
      }
    }
  }

  public removeHighlightSelection() {
    let selectedText;
    if (window.getSelection) {
      selectedText = window.getSelection();
      if (selectedText.rangeCount) {
        const stringtxt = selectedText.toString();

        const spans: HTMLCollectionOf<Element> = document.getElementsByClassName('highlight');
        for (let i = spans.length - 1; i >= 0; --i) {
          if (spans[i].textContent === stringtxt) {
            const range = document.createRange();
            range.setStartBefore(spans[i]);
            range.setEndAfter(spans[i]);
            range.deleteContents();
            const temp = document.createElement('div');
            temp.innerHTML = stringtxt;
            range.insertNode(temp.firstChild);
          }
        }
      }
    }
  }

  /**
  * Close the modal
  */
  public close(): void {
    const addressToRemove: Array<AddressInterface> = [];
    for (let add of this.allAddresses) {
      if (add.addressId === undefined || add.addressId === 0) {
        addressToRemove.push(add);
      }
    }
    for (let add of addressToRemove) {
      this.allAddresses.splice(this.allAddresses.indexOf(add), 1);
    }

    this.modalController.dismiss();
  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectAllAddresses($event: MatCheckboxChange, oneClickSend: boolean) {
    this.addresses$.subscribe(next => {
      for (const address of next.address) {
        if ($event.checked) {
          if (oneClickSend) {
            this.selectionModel.select(address);
          } else {
            this.advancedSelectionModel.select(address);
          }
        } else {
          if (oneClickSend) {
            this.selectionModel.deselect(address);
          } else {
            this.advancedSelectionModel.deselect(address);
          }
        }
      }
    });
  }

  addAddressInput($event: KeyboardEvent) {
    if (this.addressInput.nativeElement.value !== undefined && this.addressInput.nativeElement.value !== '') {
      const add: any =  {
         label: this.addressInput.nativeElement.value, email: this.addressInput.nativeElement.value
      };
      if (this.addChecked['checked'] === true) {
        this.addressesService.addAddress(add).subscribe(value => {
          this.allAddresses.unshift(value.body);
          this.addressCtrlFilter.setValue('');
          this.addressInput.nativeElement.value = '';
        });
      } else {
        if (this.validateEmail(add.email)) {
          const address: AddressInterface = new class implements AddressInterface {
            data = add;
          };
          this.allAddresses.unshift(address);
          this.addressCtrlFilter.setValue('');
          this.addressInput.nativeElement.value = '';
        }
      }
      // this.allAddresses.push(add);
    }

  }
}
