import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment.prod';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class AppPreferencesService {

  public token: string;

  public userToken: string;
  private loginData: string;
  private smallSidenavDesktop: string;
  private listOrMaps: string;
  private articleListeStyle: string;

  constructor(
    private storage: Storage
  ) {
    this.storage.create().then(r => {});
  }

  /**
   * Create key/value pair entries
   * @param key string
   * @param value string
   */
  public set(key: string, value: string): Promise<any> {
    this.setValue(key, value);
    return new Promise<any>(resolve => {resolve(true); });
    // encrypt value before store it.
    // const encryptedValue: string = CryptoJS.AES.encrypt(value, environment.cryptoJsSecretKey).toString();
    //
    // return this.storage.set(key, encryptedValue);
  }

  /**
   * Read entries
   * @param key string
   */
  public get(key: string): Promise<any> {
    return new Promise<any>(resolve => {resolve(this.getValue(key)); });
    // get stored value from key
    // return this.storage.get(key)
    // .then((encryptedValue: string) => {
    //   // decrypt value and return it
    //   let decryptValue: string;
    //   if (typeof encryptedValue !== 'undefined' && encryptedValue !== null) {
    //     decryptValue = CryptoJS.AES.decrypt(encryptedValue, environment.cryptoJsSecretKey).toString(CryptoJS.enc.Utf8);
    //   }
    //   return decryptValue;
    // })
    // .catch((error: Error) => {
    //   console.error(error);
    // });
  }

  /**
   * Delete an entry
   * @param key string
   */
  public remove(key): Promise<any> {
    this.setValue(key, undefined);
    return new Promise<any>(resolve => {resolve(true); });
    // return this.storage.remove(key);
  }

  // /**
  //  * clear everything stored in localStorage
  //  */
  // public clear(): Promise<any> {
  //   // remove all stored data.
  //   return this.storage.clear();
  // }

  public setValue(key: string, value: string) {
    // switch (key) {
    //   case 'token':
    //     this.storage.set('token', value);
    //     this.token = value;
    //     break;
    //   case 'loginData':
    //     this.storage.set('loginData', value);
    //     this.loginData = value;
    //     break;
    // }
    this.storage.set(key, value);
  }

  public getValue(key: string) {
    // switch (key) {
    //   case 'token':
    //     return this.storage.get('token');
    //     // return this.token;
    //   case 'loginData':
    //     return this.storage.get('loginData');
    //     // return this.loginData;
    // }
    return this.storage.get(key);
  }
}
