import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CriterionInterface} from '../../../interfaces/criterions/criterion.interface';
import {ArticleQueryInterface} from '../../../interfaces/articles/article-query.interface';
import {SearchesService} from '../../../services/searches/searches.service';
import {SearchDataInterface} from '../../../interfaces/search/search-data.interface';
import {SearchListCriterionsComponent} from '../search-list-criterions/search-list-criterions.component';
import {FeDatePickerComponent} from '../../parts/fe-date-picker/fe-date-picker.component';
import {DateArticleService} from '../../../services/date-article/date-article.service';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {ColorType, IconType} from '../../parts/fe-icon/fe-icon.component';
import {formatDate} from '@angular/common';
import {MatExpansionPanel} from '@angular/material/expansion';
import {ArticlesInterface} from '../../../interfaces/articles/articles.interface';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {FeDateSelectorComponent} from '../../parts/fe-date-selector/fe-date-selector.component';
import {CitySuggestion} from '../../../interfaces/search/CitySuggestion';
import {UserService} from '../../../services/user/user.service';
import {Utils} from '../../../classes/Utils';

@Component({
  selector: 'app-search-selected-criterions',
  templateUrl: './search-selected-criterions.component.html',
  styleUrls: ['./search-selected-criterions.component.scss']
})
export class SearchSelectedCriterionsComponent implements AfterViewInit{

  // @ViewChild(FeDatePickerComponent) datePicker: FeDatePickerComponent;

  @ViewChild('keywords') keywords: MatExpansionPanel;
  @ViewChild('sectors') sectors: MatExpansionPanel;
  @ViewChild('topics') topics: MatExpansionPanel;
  @ViewChild('locations') locations: MatExpansionPanel;
  @ViewChild('companySizes') companySizes: MatExpansionPanel;
  @ViewChild('activityTypes') activityTypes: MatExpansionPanel;

  // For dates
  public firstLoad = true;

  public _type: string;
  @Input() criteresText: string = 'Résumé de vos critères';
  @Input() showPrecisions: boolean = false;
  articleCounts: ArticlesInterface;
  articleCountsMap: Map<number, any>;
  dateInputText: string;
  @Input() loadingInProgress: boolean = false;

  public geolocCity: CitySuggestion;

  @Input() public set type(value: string) {
    this._type = value;
  }

  @Output() emitter = new EventEmitter();
  public currentSearch: SearchDataInterface = new class implements SearchDataInterface {
    activityTypes?: Array<number>;
    alertState: boolean;
    cityFrom: number;
    companySizes?: Array<number>;
    geolocDistanceRadius: number;
    geolocFrom: string;
    keywords: Array<string>;
    keywords_default_operator: string;
    keywords_must: Array<string>;
    keywords_must_exact: Array<string>;
    keywords_must_not: Array<string>;
    keywords_should: Array<string>;
    label: string;
    libelles: {
      sectors?: Array<string>;
      topics?: Array<string>;
      locations?: Array<string>;
      companySizes?: Array<string>;
      activityTypes?: Array<string>;
      keywords?: Array<string>;
      keywords_default_operator?: string;
      keywords_must?: Array<string>;
      keywords_must_exact?: Array<string>;
      keywords_must_not?: Array<string>;
      keywords_should?: Array<string>
    };
    locations?: Array<number>;
    searchType: string;
    sectors?: Array<number>;
    topics?: Array<number>;
  };

  constructor(public searchService: SearchesService,
              public dateArticleService: DateArticleService,
              public dialog: MatDialog,
              public userService: UserService) {

  }

  public ngAfterViewInit() {
    try {
      // this.datePicker.dateStart = this.dateArticleService.getDateStartFormatted();
      // this.datePicker.dateEnd = this.dateArticleService.getDateEndFormatted();



    } catch (e) {
    }
  }

  public clearParameters() {
    this.articleCounts = undefined;
    this.articleCountsMap = undefined;
    this.currentSearch = new class implements SearchDataInterface {
      activityTypes?: Array<number>;
      alertState: boolean;
      cityFrom: number;
      companySizes?: Array<number>;
      geolocDistanceRadius: number;
      geolocFrom: string;
      keywords: Array<string>;
      keywords_default_operator: string;
      keywords_must: Array<string>;
      keywords_must_exact: Array<string>;
      keywords_must_not: Array<string>;
      keywords_should: Array<string>;
      label: string;
      libelles: {
        sectors?: Array<string>;
        topics?: Array<string>;
        locations?: Array<string>;
        companySizes?: Array<string>;
        activityTypes?: Array<string>;
        keywords?: Array<string>;
        keywords_default_operator?: string;
        keywords_must?: Array<string>;
        keywords_must_exact?: Array<string>;
        keywords_must_not?: Array<string>;
        keywords_should?: Array<string>
      };
      locations?: Array<number>;
      searchType: string;
      sectors?: Array<number>;
      topics?: Array<number>;
    };
  }

  setCurrentSearch(searchData: SearchDataInterface) {
    this.currentSearch = searchData;

    if (this.firstLoad) {
      if (this.dateArticleService.startDate) {
        const start = formatDate(new Date(this.dateArticleService.startDate), 'dd/MM/yyyy', 'fr_FR');
        const end = formatDate(new Date(this.dateArticleService.endDate), 'dd/MM/yyyy', 'fr_FR');
        this.dateInputText = start + ' => ' + end;
        this.firstLoad = false;
      }
    }

    if (this.currentSearch?.cityFrom) {
      this.searchService.getCityById(this.currentSearch.cityFrom).subscribe(value => {
        this.geolocCity = value;
      });
    }

  }

  setSearchResultCounts(result: ArticlesInterface) {
    this.articleCounts = result;
    // Pour Cyril je créé une superbe hashmap
    this.articleCountsMap = new Map<number, any>();
    if (this.articleCounts.locationsCount) {
      for (let value of this.articleCounts.locationsCount) {
        this.articleCountsMap.set(value.id, value);
      }
    }
    if (this.articleCounts.sectorsCount) {
      for (let value of this.articleCounts.sectorsCount) {
        this.articleCountsMap.set(value.id, value);
      }
    }
    if (this.articleCounts.topicsCount) {
      for (let value of this.articleCounts.topicsCount) {
        this.articleCountsMap.set(value.id, value);
      }
    }
    if (this.articleCounts.activity_typesCount) {
      for (let value of this.articleCounts.activity_typesCount) {
        this.articleCountsMap.set(value.id, value);
      }
    }
    if (this.articleCounts.company_sizesCount) {
      for (let value of this.articleCounts.company_sizesCount) {
        this.articleCountsMap.set(value.id, value);
      }
    }
  }

  removeChip(criterionId: number, type: string) {
    this.currentSearch[type].splice(this.currentSearch[type].indexOf(criterionId), 1);
    this.emitter.emit({criterionId: criterionId, type: type});
  }

  dateChanged($event: any) {

    let [dayStart, monthStart, yearStart] = $event.dateStart.split('-');
    let [dayEnd, monthEnd, yearEnd] = $event.dateEnd.split('-');

    this.dateArticleService.startDate = formatDate(new Date(Number(yearStart), Number(monthStart) - 1, Number(dayStart)), 'yyyy-MM-dd', 'fr_FR');
    this.dateArticleService.endDate = formatDate(new Date(Number(yearEnd), Number(monthEnd) - 1, Number(dayEnd)), 'yyyy-MM-dd', 'fr_FR');
    this.emitter.emit($event);
  }

  public getDates() {
    return {dateStart: this.dateArticleService.startDate, dateEnd: this.dateArticleService.endDate};
  }

  checkElement(criterionId: number, type: string, $event: MatCheckboxChange) {
    this.emitter.emit({criterionId: criterionId, type: type, checked: $event.checked});
  }

  protected readonly IconType = IconType;

  getKeywordCount() {
      let count = 0;
      if (this.currentSearch.keywords_must) {
        count = count + this.currentSearch.keywords_must.length;
      }
      if (this.currentSearch.keywords_should) {
        count = count + this.currentSearch.keywords_should.length;
      }
      if (this.currentSearch.keywords_must_not) {
        count = count + this.currentSearch.keywords_must_not.length;
      }
      return count;
  }

  openCriterion(typeCriterion: string) {
    this[typeCriterion].open();
    // document.getElementById(typeCriterion).expanded = true;
  }

  getTopicLabel(topic: CriterionInterface) {
    if (topic) {
      if (topic.subType === 'temporal') {
        if (topic.subSubType === 'past') {
          return topic.label + ' [Passé]';
        }
        if (topic.subSubType === 'present') {
          return topic.label + ' [En cours]';
        }
        if (topic.subSubType === 'future') {
          return topic.label + ' [À venir]';
        }
      }
      else if (topic.subType === 'predictive') {
        return topic.label + ' [Probable]';
      }
      return topic.label;
    }
  }

  getCriterionCount(criterionId: number, typeCriterion: string) {
    // console.log('count');
    if (this.articleCounts && this.articleCounts[typeCriterion + 'Count'] && this.articleCounts[typeCriterion + 'Count'].length > 0) {
      for (let value of this.articleCounts[typeCriterion + 'Count']) {
        if (value.id === criterionId) {
          return '(' + value.articlesCount + ')';
        }
      }
    }
    return '';
  }

    openDateSelector($event: MouseEvent) {
      let targetAttr = ($event.target as HTMLElement).getBoundingClientRect();
      const dialogConfig = new MatDialogConfig();

      // dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      dialogConfig.backdropClass = 'cdk-overlay-transparent-backdrop';

      let leftDialog = targetAttr.x - targetAttr.width - 20 - 700 + 'px';

      // if input clicked
      if (targetAttr.width > 50) {
        leftDialog = targetAttr.x - targetAttr.width - 20 - 45 + 'px';
      }

      dialogConfig.position = {
        top: targetAttr.y + targetAttr.height + 'px',
        // left: leftDialog
        right: '10px'
      };
      dialogConfig.data = {
        dateStart: this.dateArticleService.getDateStartFormatted(),
        dateEnd: this.dateArticleService.getDateEndFormatted(),
        dateMin: this.dateArticleService.getMinDateFormatted()
      };

      const dialogRef = this.dialog.open(FeDateSelectorComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(data => {
        if (data != null) {
          this.userService.log('DATE_START_EDIT');
          this.dateArticleService.startDate = formatDate(data.dateStart, 'yyyy-MM-dd', 'fr_FR');
          this.dateArticleService.endDate = formatDate(data.dateEnd, 'yyyy-MM-dd', 'fr_FR');

          // this.dateInputText = this.dateArticleService.startDate + ' => ' + this.dateArticleService.endDate;
          const start = formatDate(new Date(this.dateArticleService.startDate), 'dd/MM/yyyy', 'fr_FR');
          const end = formatDate(new Date(this.dateArticleService.endDate), 'dd/MM/yyyy', 'fr_FR');
          this.dateInputText = start + ' => ' + end;

          this.emitter.emit({dateStart: this.dateArticleService.startDate, dateEnd: this.dateArticleService.endDate});
        }
      });
    }

  protected readonly ColorType = ColorType;

  truncateLabel(label: string, maxLength: number = 47): string {
    const bracketPattern = /\[.*?\]$/;
    const match = label.match(bracketPattern);
    const suffix = match ? match[0] : '';
    const suffixLength = suffix.length;

    // Adjust the maximum length to account for the suffix
    const truncatableLength = maxLength - suffixLength;

    // If the label length is within the limit, return it as is
    if (label.length <= maxLength) {
      return label;
    }

    // Calculate the length of the beginning and the end portions
    const startLength = Math.floor((truncatableLength - 3) / 2);
    const endLength = Math.ceil((truncatableLength - 3) / 2);

    // Slice the beginning and the end of the label
    const start = label.slice(0, startLength);
    const end = label.slice(label.length - endLength, label.length - suffixLength);

    return `${start}...${end}${suffix}`;
  }

  getChipsLabel(label: string, sectorId: number) {
    let result = '';
    if (label ) {
      result = this.truncateLabel(label);
    }
    else {
      result = label;
    }
    if (this.articleCountsMap && this.articleCountsMap.get(sectorId)) {
      result = result + ' (' + this.articleCountsMap.get(sectorId)?.articlesCount + ')';
    }
    return result;
  }

  protected readonly Utils = Utils;
}
