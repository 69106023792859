import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {ApiService} from '../api/api.service';
import {HttpResponse} from '@angular/common/http';
import {ArticleMonitoringInterface} from 'src/app/interfaces/article-monitoring/article-monitoring.interface';
import {ErrorStatus} from '../../classes/ErrorStatus.class';
import {AddressesInterface} from '../../interfaces/addresses/addresses';
import {AddressInterface} from '../../interfaces/address/address';
import {ArticlesInterface} from '../../interfaces/articles/articles.interface';
import {MonitoredArticleResponseInterface} from '../../interfaces/monitored-articles-response/monitored-article-response.interface';
import {MonitoredArticleInterface} from '../../interfaces/monitored-articles/monitored-article.interface';
import {MonitoredArticleActivityInterface} from '../../interfaces/monitored-article-comments/monitored-article-activity.interface';
import {MonitoredArticleAttachmentInterface} from '../../interfaces/monitored-article-attachments/monitored-article-attachment.interface';
import {EntrepriseCustomersInterface} from '../../interfaces/entreprise-customers/entreprise-customers.interface';

@Injectable({
    providedIn: 'root'
})
export class ArticleMonitoringService {

    constructor(
        private apiService: ApiService
    ) {
    }

    private articleActivities: Array<MonitoredArticleActivityInterface>;
    private articleActivities$: BehaviorSubject<Array<MonitoredArticleActivityInterface>> = new BehaviorSubject(undefined);

    private articleAttachments: Array<MonitoredArticleAttachmentInterface>;
    private articleAttachments$: BehaviorSubject<Array<MonitoredArticleAttachmentInterface>> = new BehaviorSubject(undefined);

    public getCustomersMonitoringArticle(query: { [key: string]: string } = {}): Observable<EntrepriseCustomersInterface> {
        return this.apiService.get<EntrepriseCustomersInterface>('v1/articles/monitoring/customers', query).pipe(
            map((response: HttpResponse<EntrepriseCustomersInterface>) => {
                    return response.body;
                }
            )
        );
    }

    public getArticleActivities(query: { [key: string]: string } = {}): Observable<Array<MonitoredArticleActivityInterface>> {
        return this.apiService.get<Array<MonitoredArticleActivityInterface>>('v1/articles/monitoring/activity', query).pipe(
            map((response: HttpResponse<Array<MonitoredArticleActivityInterface>>) => {
                    this.articleActivities = response.body;
                    this.articleActivities$.next(this.articleActivities);
                    return response.body;
                }
            )
        );
    }

    public addMonitoringArticles(addressId: number, assignedToMe: boolean, customers: string, notifiedByEmail: boolean, supervisorComment: string, listEmails: string): Observable<HttpResponse<any>> {
        return this.apiService.post(`v1/articles/monitoring?articleId=${addressId}&assignedToMe=${assignedToMe}&comment=${supervisorComment}&${customers}${listEmails}notifiedByEmail=${notifiedByEmail}`).pipe(
            tap((response: HttpResponse<any>) => {
                if (response.status === 200 || response.status === 201) {
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }


    public addMetadataToMonitoringArticle(articleMonitoringId: number, query: { [key: string]: any } = {}): Observable<HttpResponse<any>> {
        return this.apiService.put(`v1/articles/monitoring/${articleMonitoringId}/metadata`, query).pipe(
            tap((response: HttpResponse<any>) => {
                if (response.status === 200 || response.status === 201) {
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    public updateMonitoringArticles(query: { [key: string]: any } = {}): Observable<any> {
        return this.apiService.patch<any>(`v1/articles/monitoring`, query).pipe(
            map((response: HttpResponse<any>) => {
                if (response.status === 200 || response.status === 201) {
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    public getArticles(query: { [key: string]: any } = {}): Observable<any> { // TODO ECE
        return this.apiService.get<MonitoredArticleResponseInterface>('v1/articles/monitoring', query).pipe(
            map((response: HttpResponse<MonitoredArticleResponseInterface>) => {
                if (response.status === 200 || response.status === 201) {
                    return response.body;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    public updateMonitoringArticlesPositions(query: { [key: string]: any } = {}): Observable<any> {
        return this.apiService.patch<any>(`v1/articles/monitoring/move`, query).pipe(
            map((response: HttpResponse<any>) => {
                if (response.status === 200 || response.status === 201) {
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    public addMonitoredArticleComment(query: { [key: string]: any } = {}) {
        return this.apiService.post(`v1/articles/monitoring/activity`, query)
            .pipe(
                tap((response: HttpResponse<any>) => {
                    if (response.status === 200 || response.status === 201) {
                        return response;
                    } else {
                        throwError(new ErrorStatus(response.status));
                    }
                })
            );
    }

    public addExternalLead(query: { [key: string]: any } = {}) {
        return this.apiService.post(`v1/articles/monitoring/externallead`, query)
            .pipe(
                tap((response: HttpResponse<any>) => {
                    if (response.status === 200 || response.status === 201) {
                        return response;
                    } else {
                        throwError(new ErrorStatus(response.status));
                    }
                })
            );
    }

    public updateExternalLead(query: { [key: string]: any } = {}): Observable<any> {
        return this.apiService.patch(`v1/articles/monitoring/externallead`, query)
            .pipe(
                map((response: HttpResponse<any>) => {
                    if (response.status === 200 || response.status === 201) {
                        return response;
                    } else {
                        throwError(new ErrorStatus(response.status));
                    }
                })
            );
    }

    public updateMonitoredArticleComment(query: { [key: string]: any } = {}): Observable<any> {
        return this.apiService.patch<any>(`v1/articles/monitoring/activity`, query).pipe(
            map((response: HttpResponse<any>) => {
                if (response.status === 200 || response.status === 201) {
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    public removeMonitoredArticleComment(monitoringCommentId: number, articleId: number, customer: string): Observable<HttpResponse<any>> {
        return this.apiService.delete(`v1/articles/monitoring/activity?monitoringCommentId=${monitoringCommentId}&articleId=${articleId}&customer=${customer}`).pipe(
            tap((response: HttpResponse<any>) => {
                if (response.status === 200) {
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    public getArticleAttachments(query: { [key: string]: string } = {}): Observable<Array<MonitoredArticleAttachmentInterface>> {
        return this.apiService.get<Array<MonitoredArticleAttachmentInterface>>('v1/articles/monitoring/attachments', query).pipe(
            map((response: HttpResponse<Array<MonitoredArticleAttachmentInterface>>) => {
                    this.articleAttachments = response.body;
                    this.articleAttachments$.next(this.articleAttachments);
                    return response.body;
                }
            )
        );
    }

    getFormUrlEncoded(toConvert) {
        const formBody = [];
        for (const property in toConvert) {
            const encodedKey = encodeURIComponent(property);
            const encodedValue = encodeURIComponent(toConvert[property]);
            formBody.push(encodedKey + '=' + encodedValue);
        }
        return formBody.join('&');
    }

    public addMonitoredArticleAttachment(articleId: number, articleMonitoringId: number, customer: string, customers: string[], monitoringAttachmentStatus: boolean, file: File) {
        let formData = new FormData();
        formData.append('file', file as string | Blob);
        formData.append('articleId', articleId + '');
        formData.append('articleMonitoringId', articleMonitoringId + '');
        formData.append('customer', customer);
        formData.append('customersJson', JSON.stringify(customers));
        formData.append('monitoringAttachmentStatus', monitoringAttachmentStatus + '');

        return this.apiService.post(`v1/articles/monitoring/attachments`, formData, true)
            .pipe(
                tap((response: HttpResponse<any>) => {
                    if (response.status === 200 || response.status === 201) {
                        return response;
                    } else {
                        throwError(new ErrorStatus(response.status));
                    }
                }));
    }

    public updateMonitoredArticleAttachmentPrivacy(query: { [key: string]: any } = {}): Observable<any> {
        return this.apiService.patch<any>(`v1/articles/monitoring/attachments`, query).pipe(
            map((response: HttpResponse<any>) => {
                if (response.status === 200 || response.status === 201) {
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    public removeMonitoredArticleAttachment(monitoringAttachmentId: number, articleId: number, customer: string): Observable<HttpResponse<any>> {
        return this.apiService.delete(`v1/articles/monitoring/attachments?monitoringAttachmentId=${monitoringAttachmentId}&articleId=${articleId}&customer=${customer}`).pipe(
            tap((response: HttpResponse<any>) => {
                if (response.status === 200) {
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    public downloadMonitoredArticleAttachment(query: { [key: string]: string } = {}): Observable<HttpResponse<any>> {
        return this.apiService.getAsBlob<HttpResponse<any>>('v1/articles/monitoring/attachments/download', query).pipe(
            map((response: HttpResponse<any>) => {
                    return response;
                }
            )
        );
    }

}
