import {Component, ElementRef, EventEmitter, Inject, Output, ViewChild} from '@angular/core';
import {ContactInterface} from "../../../interfaces/contact/contact.interface";
import {AddressInterface} from "../../../interfaces/address/address";
import {ModalService} from '../../../services/modal/modal.service';
import {FEEntrepriseContactInterface} from '../../../interfaces/fe-entreprise-contact/fe-entreprise-contact.interface';
import {FeContactEnrichiModalComponent} from '../fe-fiche-entreprise-modal/fe-contact-enrichi-modal/fe-contact-enrichi-modal.component';
import {
  ContactLineInterface,
  FeCarnetAdressesModalComponent
} from '../fe-carnet-adresses-modal/fe-carnet-adresses-modal.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ArticleInterface} from "../../../interfaces/articles/article.interface";
import {map, startWith, takeUntil} from "rxjs/operators";
import {MailPreviewInterface} from "../../../interfaces/mail-preview/mail-preview.interface";
import {ArticlesService} from "../../../services/articles/articles.service";
import {Observable, Subject} from "rxjs";
import {SnackbarService} from '../../../services/snackbar/snackbar.service';
import {ErrorStatus} from '../../../classes/ErrorStatus.class';
import {AddressesService} from "../../../services/addresses/addresses.service";
import {AddressesInterface} from "../../../interfaces/addresses/addresses";
import {FormControl} from "@angular/forms";
import {FeInputComponent} from '../../parts/fe-input/fe-input.component';
import {IconType} from '../../parts/fe-icon/fe-icon.component';
import {UserService} from '../../../services/user/user.service';

export interface ErrorMsg {
  addressId: number;
  errorState: boolean;
}
@Component({
  selector: 'fe-mail-modal',
  templateUrl: './fe-mail-modal.component.html',
  styleUrls: ['./fe-mail-modal.component.scss']
})
export class FeMailModalComponent {
  protected showUnknownDestinataireContainer: boolean = false;
  protected showMailApercu: boolean = false;
  protected contactsArray: AddressInterface[] = [];
  protected unknowDestinataires: AddressInterface[] = [{
    addressId: 0,
    data: {
      label: '',
      email: ''
    },
    haveErrors: false
  }];
  @ViewChild('htmlDiv') htmlDiv: ElementRef;
  @Output() confirm = new EventEmitter<any>();
  protected articles: ArticleInterface[] = [];
  public html: string = '';
  protected unsubscribe$: Subject<any> = new Subject<any>();
  private trialDiv: Element;
  private articleIds: Array<number> = [];
  protected receiveACopy: boolean = false;
  protected loadingPreview: boolean = false;
  protected object: string = '';
  protected modalTitle: string = 'Envoyer un signal business';
  protected addresses: Observable<AddressesInterface>;
  protected contactsLines: ContactLineInterface[] = [];
  protected filteredContacts: Observable<ContactLineInterface[]>;
  contactControl = new FormControl('');

  constructor(private _modalService: ModalService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private articlesService: ArticlesService,
              private snackbarService: SnackbarService,
              private addressesService: AddressesService,
              private dialogRef: MatDialogRef<FeMailModalComponent>,
              public userService: UserService) {
    if (data != null && data.articles != null) {
      this.articles = data.articles;
    }

    if (this.articles.length > 1) {
      this.modalTitle = 'Envoyer des signaux business';
    }

    this.articles.forEach(value => {
      this.articleIds.push(value.id);
    });

    this.addresses = addressesService.getAddresses();
    this.addresses.subscribe(
        (res: AddressesInterface) => {
          res.address.forEach(value => {
            this.contactsLines.push({
              id: value.addressId,
              checked: false,
              label: value.data.label,
              email: value.data.email
            });
          });

          this.filteredContacts = this.contactControl.valueChanges.pipe(
              startWith(''),
              map(value => this._filterContact(value || '')),
          );
        });

    this.redrawHtmlPreview();
  }

  ngOnInit() {

  }

  protected redrawHtmlPreview(): void {
    this.loadingPreview = true;
    this.articlesService.emailPreviewArticlesList(this.articleIds).pipe(takeUntil(this.unsubscribe$))
        .subscribe(
            (res: MailPreviewInterface) => {
              const htmlObject = document.createElement('div');
              htmlObject.innerHTML = res.html;
              const commentTextDivs: any = htmlObject.getElementsByClassName('comment-text');
              let index: number = 0;
              for (const div of commentTextDivs) {
                const currentCom = div.innerHTML;
                div.innerHTML = '';

                const txtArea = document.createElement('textarea');
                txtArea.setAttribute('style', 'width:100%;');
                txtArea.id = 'feArttxtArea' + res.articles_id[index];
                txtArea.innerHTML = currentCom;
                div.appendChild(txtArea);
                index ++;
              }
              // remove trial
              this.trialDiv = htmlObject.getElementsByClassName('footer').item(0);
              this.trialDiv.setAttribute('style', this.trialDiv.getAttribute('style') + 'display:none;');

              this.html = htmlObject.innerHTML;

              setTimeout(() => {
                res.articles_id.forEach(value => {
                  const feInput = <HTMLInputElement>document.getElementById('feArtComment' + value);
                  const inputHtml = <HTMLInputElement>document.getElementById('feArttxtArea' + value);

                  if (feInput.value != null && feInput.value.trim().length > 0) {
                    inputHtml.value = feInput.value;
                  } else if (inputHtml.value != null && inputHtml.value.trim().length > 0) {
                    feInput.value = inputHtml.value;
                  }

                  feInput.addEventListener('keyup', function (evt) {
                    inputHtml.value = feInput.value;
                  });

                  inputHtml.addEventListener('keyup', function (evt) {
                    feInput.value = inputHtml.value;
                  });

                  this.loadingPreview = false;
                });
              }, 500);
            }
        );
  }

  private _filterContact(value: string): ContactLineInterface[] {
    const filterValue = value.toLowerCase();

    return this.contactsLines.filter(option =>
        option.label.toLowerCase().includes(filterValue)
        || option.email.toLowerCase().includes(filterValue));
  }

  removeAddress(addressId: number): void {
    this.contactsArray.splice(this.contactsArray.map(e => e.addressId)
        .indexOf(addressId), 1);
  }

  protected getArticle(articleId: number): ArticleInterface {
    let articleToReturn: ArticleInterface = null;

    this.articles.forEach(article => {
      if (article.id === articleId) {
        articleToReturn = article;
      }
    });

    return articleToReturn;
  }

  openCarnetAdresses() {
    this._modalService.showCustomModalWithActionOnClose(FeCarnetAdressesModalComponent,
        {data : {fromMailModal: true}}, (response) => {
      if (response != null) {
       response.forEach((value: ContactLineInterface) => {
         let address: AddressInterface = {
           addressId: value.id,
           data: {
             label: value.label,
             email: value.email
           }
         }
         this.insertContact(address);
       })
      }});
  }

  private insertContact(address: AddressInterface):void {
    let notPresent: boolean = true;
    this.contactsArray.forEach(value => {
      if (value.addressId == address.addressId) {
        notPresent = false;
      }
    });
    if (notPresent) {
      this.contactsArray.push(address);
    }
  }

  onEditUnknownDestinataire(unknowDestinataire: AddressInterface) {
    if (this.unknowDestinataires[this.unknowDestinataires.length - 1].addressId == unknowDestinataire.addressId) {
      this.unknowDestinataires.push({addressId: unknowDestinataire.addressId + 1, data: {label: '', email: ''}})
    }
    this.updateMailErrorMsg(unknowDestinataire);
  }

  isLastDestinataire(unknowDestinataire: AddressInterface) {
    return this.unknowDestinataires[this.unknowDestinataires.length - 1].addressId != unknowDestinataire.addressId;
  }

  removeUnknownDestinataire(addressId: number): void {
    this.unknowDestinataires.splice(this.unknowDestinataires.map(e => e.addressId)
        .indexOf(addressId), 1);
  }

  protected getContactCount(): number {
    let count: number = this.contactsArray.length;
    if (this.showUnknownDestinataireContainer) {
      this.unknowDestinataires.forEach(value => {
        if (value.data.email.trim().length > 0) {
          count ++;
        }
      });
    }
    return count;
  }

  protected getResumeCountLabel(): string {
    let resumeLabel: string = this.articles.length + ' article';
    if (this.articles.length > 1) {
      resumeLabel += 's';
    }
    resumeLabel += ' vers ' + this.getContactCount() + ' destinataire';
    if (this.getContactCount() > 1) {
      resumeLabel += 's';
    }
    return resumeLabel;
  }

  private getUnknowDestinatairesMails(): string[] {
    const mailsToReturn: Array<string> = new Array<string>();
    this.unknowDestinataires.forEach(value => {
      if (value.data.email.trim().length > 0) {
        mailsToReturn.push(value.data.email);
      }
    });
    return mailsToReturn;
  }

  private getKnowDestinatairesMails(): number[] {
    const mailsToReturn: Array<number> = new Array<number>();
    this.contactsArray.forEach(value => {
      mailsToReturn.push(value.addressId);
    });
    return mailsToReturn;
  }

  private getArtclesListIds(): number[] {
    const articleIdsToReturn: Array<number> = new Array<number>();
    this.articles.forEach(value => {
      articleIdsToReturn.push(value.id);
    });
    return articleIdsToReturn;
  }

  protected isValidEmail(email: string): boolean {
    const emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return email.trim().length < 1 || emailRegex.test(email);
  }

  protected updateMailErrorMsg(unknowDestinataire: AddressInterface): void {
    unknowDestinataire.haveErrors = !this.isValidEmail(unknowDestinataire.data.email);
    // this.snackbarService.showSnackbar('TEST',
    //     'Valeur : ' + unknowDestinataire.haveErrors + ' Email : ' + unknowDestinataire.data.email);
  }

  protected sendMail(): void {
    if (this.getContactCount() > 0) {
      const unknownAdressWithErrors: AddressInterface[] = new Array<AddressInterface>();

      if (this.showUnknownDestinataireContainer) {
        this.unknowDestinataires.forEach(value => {
          if (value.haveErrors && value.data.email.trim().length > 0) {
            unknownAdressWithErrors.push(value);
          }
        });
      }

      if (unknownAdressWithErrors.length < 1) {
        let subject: string = this.object;
        if (subject === null || subject.trim().length < 1) {
          subject = 'Article partagé de First ECO';
        }

        this.adaptTextBToLabel();

        document.getElementsByClassName('footer').item(0).setAttribute('style', this.trialDiv.getAttribute('style') + 'display:block;');
        const emailQuery = {
          articlesIdList: this.getArtclesListIds(),
          messageHtml: this.htmlDiv.nativeElement.innerHTML,
          receiveCopy: this.receiveACopy,
          subject: subject,
          unknownAddresses: this.showUnknownDestinataireContainer ? this.getUnknowDestinatairesMails() : [],
          knownAddresses: this.getKnowDestinatairesMails()
        };

        this.articlesService.emailArticleAdvanced(emailQuery).pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(
            () => {
              if (this.getArtclesListIds() !== undefined && this.getArtclesListIds().length <= 1) {
                this.userService.logMultiArticles('ARTICLE_MAIL', this.getArtclesListIds());
              } else {
                this.userService.logMultiArticles('ARTICLE_MAIL', this.getArtclesListIds());
              }
              let articleCountLabel = 'L’article a bien été partagé';
              if (this.articles.length > 1)
                articleCountLabel = 'Les article ont bien été partagés';
              this.snackbarService.showSuccessSnackbar('Mail envoyé avec succès',
                  articleCountLabel + ' par email.');

              let emails: Array<string> = new Array();
              if (this.showUnknownDestinataireContainer) {
                this.unknowDestinataires.forEach(value => {
                  if (value.data.email.trim().length > 0) {
                    emails.push(value.data.email);
                    if (value.data.saveContact) {
                      const contactToAdd: any =  {
                        label: '', email: value.data.email
                      };
                      this.addressesService.addAddress(contactToAdd).subscribe(value => {});
                    }
                  }
                });
              }

              this.contactsArray.forEach(value => {
                emails.push(value.data.email);
              });
              this.confirm.emit(emails);
              // Close the modal
              this.dialogRef.close();
            },
            (error: Error | ErrorStatus) => {
              let articleCountLabel = 'de l\'article';
              if (this.articles.length > 1)
                articleCountLabel = 'des articles';
              const errorStr: string = 'Une erreur est survenue lors du partage par e-mail '
                  + articleCountLabel + '. Veuillez réessayer ultérieurement.';
              this.snackbarService.showDangerSnackbar('Envoi impossible', errorStr);
            }
        );
      } else {
        let message: string = 'Les adresses suivantes ne sont pas conformes : ';
        unknownAdressWithErrors.forEach(value => {
          message += '<br>- ' + value.data.email;
        });
        this.snackbarService.showDangerSnackbar('Opération impossible', message);

      }
    } else {
      this.snackbarService.showDangerSnackbar('Opération impossible',
          'Vous n\'avez ajouté aucun destinataire à votre email.');
    }
  }

  protected adaptTextBToLabel(): void {
    // Rechange textArea to text
    const commentTextDivs: any = this.htmlDiv.nativeElement.getElementsByClassName('comment-text');
    for (let i = commentTextDivs.length - 1; i >= 0; --i) {
      for (const child of commentTextDivs[i].children) {
        if (child.tagName === 'TEXTAREA') {
          // const txt = commentTextDivs[i].firstChild.value;
          if (child.value === undefined || child.value.length <= 0) {
            commentTextDivs[i].parentNode.parentNode.removeChild(commentTextDivs[i].parentNode);
            // break;
          } else {
            try {
              commentTextDivs[i].innerHTML = child.value;
            } catch (e) {
            }
            // break;
          }
        }
      }
    }
  }

  public highlightSelection() {
    let selectedText;
    if (window.getSelection) {
      selectedText = window.getSelection();
      if (selectedText.rangeCount) {
        const stringtxt = selectedText.toString();
        const range = selectedText.getRangeAt(0);
        range.deleteContents();
        const resString = stringtxt.replace('’', '\'').replace(stringtxt, '<span class="highlight" style="background-color: yellow !important;">' + stringtxt + '</span>');
        // const resString = stringtxt.replace('’', '\'').replace(stringtxt, '<span style="background-color: yellow !important;">' + stringtxt + '</span>');
        const temp = document.createElement('div');
        temp.innerHTML = resString;
        range.insertNode(temp.firstChild);
      }
    }
  }

  public removeHighlightSelection() {
    let selectedText;
    if (window.getSelection) {
      this.highlightSelection();
      selectedText = window.getSelection();
      if (selectedText.rangeCount) {
        const stringtxt = selectedText.toString();

        const spans: HTMLCollectionOf<Element> = document.getElementsByClassName('highlight');
        for (let i = spans.length - 1; i >= 0; --i) {
          if (spans[i].textContent === stringtxt) {
            const range = document.createRange();
            range.setStartBefore(spans[i]);
            range.setEndAfter(spans[i]);
            range.deleteContents();
            const temp = document.createElement('div');
            temp.innerHTML = stringtxt;
            range.insertNode(temp.firstChild);
          }
        }
      }
    }
  }

  protected addContactToMail(contact: ContactLineInterface): void {
    this.insertContact({addressId: contact.id, data: {label: contact.label, email: contact.email}});
  }

  protected getContactFormatLabel(contact: ContactLineInterface): string {
    let contactLabel: string = '';
    if (contact.label && contact.label.trim().length > 0) {
      contactLabel = '<span class="bold">' + contact.label + '</span> (' + contact.email + ')';
    } else {
      contactLabel = contact.email;
    }
    return contactLabel;
  }

  protected getContactTitle(contact: ContactLineInterface): string {
    let contactLabel: string = '';
    if (contact.label && contact.label.trim().length > 0) {
      contactLabel = contact.label + ' (' + contact.email + ')';
    } else {
      contactLabel = contact.email;
    }
    return contactLabel;
  }

  protected removeArticle(article: ArticleInterface): void {
    if (this.articles.length > 1) {
      this.articles.splice(this.articles.map(e => e.id).indexOf(article.id), 1);
      this.articleIds.splice(this.articleIds.indexOf(article.id), 1);
      if (this.articles.length < 2) {
        this.modalTitle = 'Envoyer un signal business';
      }
      this.redrawHtmlPreview();
    }
  }

  protected readonly IconType = IconType;
}
