<ion-header no-border>
  <ion-toolbar>
    <b style="margin-left: 10px">Choix du template</b>
    <ion-buttons slot="end">
      <button mat-icon-button aria-label="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content style="height: 630px">
  <div style="height: 90px; display: flex; flex-direction: column;">
    <mat-label style="margin-left: 3%; margin-top: 10px;">Nom de la newsletter : </mat-label>
    <input
            placeholder="Nom de la newsletter"
            matInput
            [(ngModel)]="nlName"
            style="font-size: 15px; margin: 10px 5% 10px 5%; padding-bottom: 5px; border-bottom: 2px solid black !important;">
  </div>
  <div style="height: 540px;">
    <button mat-button *ngFor="let template of templates" (click)="selectTemplate(template)" style="border: 1px solid black; height: 537px; width: 360px; vertical-align: top">
      <div>{{template.name}}</div>
      <img *ngIf="template.image" [src]="template.image" style="max-height:512px; max-width: 360px;" alt="Aucune image disponible"/>
      <div *ngIf="!template.image">Aucune image disponible</div>

    </button>
  </div>
</ion-content>
