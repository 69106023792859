<ion-header no-border>
  <ion-toolbar>
    <b style="margin-left: 10px">Prévisualisation</b>
    <ion-buttons slot="end">
      <button mat-icon-button aria-label="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div>
    <span style="margin-left: 10px; margin-bottom: 5px; margin-top: 15px;">Destinataire : </span>
    <input
            placeholder="Destinataire"
            matInput
            [(ngModel)]="email"
            style="width: 96%; font-size: 15px; margin: 0px 20px 20px 20px;
                padding-bottom: 5px;
                border-bottom: thick !important;
                border-bottom: 2px solid black !important;">
  </div>
  <div style="height: 660px;overflow-y: auto;margin: auto;" [innerHTML]="html">

  </div>
  <fe-button style="display: block; width: 85px; margin: auto; margin-top: 30px; margin-bottom: 50px;" text="Envoyer"
             type="PRIMARY" bold="true" (click)="sendNewsletter()"/>
<!--  <button-->
<!--          class="mat-rounded-button"-->
<!--          mat-raised-button color="accent"-->
<!--          (click)="sendNewsletter()">-->
<!--    Envoyer-->
<!--  </button>-->
</ion-content>
