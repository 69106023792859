    <button class="dropdownBtnExtrait"  (click)="onDropDownMenuOpenAffichage($event)"
            [matMenuTriggerFor]="menu1" [matTooltip]="'Affichage : ' + modeAffichage">
        <fe-icon size="14" style="margin-right: 4px" [iconType]="IconType.article"
                 [color]="ColorType.grey"/>
        <span class="notSmallDesktop">{{modeAffichage}}</span>
        <mat-icon >{{menuIconAffichage}}</mat-icon>
    </button>

    <mat-menu style="position: absolute" #menu1="matMenu" (close)="onMenuAffichageClosed()">
        <fe-menu-item (click)="toggleStyle('small','Titres')" color="PRIMARY" text="Titres"
                      [icon]="IconType.title" [class.selectedOption]="listStyle == 'small'"/>
        <fe-menu-item (click)="toggleStyle('big','Extraits')" color="PRIMARY" text="Extraits"
                      [icon]="IconType.extrait" [class.selectedOption]="listStyle == 'big'"/>
        <fe-menu-item (click)="toggleStyle('all','Articles complets')" color="PRIMARY"
                      text="Articles complets" [icon]="IconType.complet" [class.selectedOption]="listStyle == 'all'"/>
        <fe-menu-item *ngIf="mapAvailable" (click)="toggleStyle('map','Carte')" color="PRIMARY"
                      text="Carte" [icon]="IconType.localisations" [class.selectedOption]="listStyle == 'map'"/>
    </mat-menu>

