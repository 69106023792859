import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {EntrepriseInterface} from "../../../../interfaces/entreprise/entreprise.interface";
import {EntrepriseFinancialInterface} from "../../../../interfaces/entreprise-financial/entreprise-financial.interface";
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'fe-financials-graph-modal',
  templateUrl: './fe-financials-graph-modal.component.html',
  styleUrls: ['./fe-financials-graph-modal.component.scss']
})
export class FeFinancialsGraphModalComponent {
  view: any[] = [650, 150];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Année';
  showYAxisLabel = true;
  yAxisLabel = 'Valeur';

  colorScheme = {
    domain: ['#124457']
  };

  dataEff = undefined;
  dataSales = undefined;
  dataProfits = undefined;

  private financials: EntrepriseFinancialInterface[] = [];
  protected entreprise: EntrepriseInterface = {};

  constructor(private modalController: ModalController,
              public dialogRef: MatDialogRef<FeFinancialsGraphModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data != null) {
      this.financials = data.financials;
      this.entreprise = data.entreprise;
    }
  }

  ngOnInit() {
    var modal = document.getElementById('feFinancialsGraphModal');

    const self = this;
    const currentYear = new Date().getFullYear();
    let salesAvailable = false;
    let netAvailable = false;
    let effAvailable = false;
    this.financials?.forEach(value =>  {
      if (value.annualSalesNumber) {
        if (self.dataSales === undefined) {
          self.dataSales = [];
        }
        self.dataSales.push({value: value.annualSalesNumber, name: value.year});
        if (value.year > currentYear - 4) {
          salesAvailable = true;
        }
      }
      if (value.netProfilNumber) {
        if (self.dataProfits === undefined) {
          self.dataProfits = [];
        }
        self.dataProfits.push({value: value.netProfilNumber, name: value.year});
        if (value.year > currentYear - 4) {
          netAvailable = true;
        }
      }
      if (value.effectif) {
        if (self.dataEff === undefined) {
          self.dataEff = [];
        }
        self.dataEff.push({value: value.effectif, name: value.year});
        if (value.year > currentYear - 4) {
          effAvailable = true;
        }
      }
    });
    let count = 0;
    if (this.dataEff && effAvailable) {
      count++;
    } else {
      self.dataEff = undefined;
    }
    if (this.dataProfits && netAvailable) {
      count++;
    } else {
      self.dataProfits = undefined;
    }
    if (this.dataSales && salesAvailable) {
      count++;
    } else {
      self.dataSales = undefined;
    }
    this.view = [((modal.offsetWidth - 32) / count), 350];
  }

  public close(): void {
    this.modalController.dismiss();
  }

  protected readonly undefined = undefined;
}
