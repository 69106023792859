import {ChangeDetectionStrategy, Component, HostListener, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MediaQueryService} from 'src/app/services/media-query/media-query.service';
import {AppPreferencesService} from 'src/app/services/app-preferences/app-preferences.service';
import {AlertController, ModalController, NavController, NavParams, Platform} from '@ionic/angular';
import {Subject, Subscription} from 'rxjs';
import {UserService} from 'src/app/services/user/user.service';
import {DateArticleService} from 'src/app/services/date-article/date-article.service';
import {PushService} from 'src/app/services/push/push.service';
import {MatSidenav} from '@angular/material/sidenav';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {SessionInterface} from '../../interfaces/session/session.interface';
import {SearchSummaryComponent} from '../modals/search/search-summary/search-summary.component';
import {CommunicationModalComponent} from '../modals/communication-modal/communication-modal.component';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {Utils} from '../../classes/Utils';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {ApiService} from '../../services/api/api.service';
import {AuthService} from '../../services/auth/auth.service';
import {NavigationOptions} from '@ionic/angular/common/providers/nav-controller';
import {MySearchesAddComponent} from '../my-searches/my-searches-add/my-searches-add.component';
import {ToastService} from '../../services/toast/toast.service';
import {SearchesInterface} from '../../interfaces/searches/searches.interface';
import {SearchInterface} from '../../interfaces/search/search.interface';
import {SearchesService} from '../../services/searches/searches.service';
import {DatesSelectorButtonComponent} from '../dates-selector-button/dates-selector-button.component';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {CriterionInterface} from '../../interfaces/criterions/criterion.interface';
import {FormControl} from '@angular/forms';
import {ErrorStatus} from '../../classes/ErrorStatus.class';
import {MatSelect} from '@angular/material/select';
import {MatSnackBar} from "@angular/material/snack-bar";
import {SnackbarService} from "../../services/snackbar/snackbar.service";
import {ModalService} from "../../services/modal/modal.service";
import {strings} from "@angular-devkit/core";
import {SearchDataInterface} from "../../interfaces/search/search-data.interface";
import {SearchRecentDataInterface} from "../../interfaces/search/search-recent-data.interface";
import {CriterionsTypesInterface} from '../../interfaces/criterions/criterions-types.interface';
import {forEach} from "@angular-devkit/schematics";
import {
    FeCustomerDepartementsModalComponent
} from "../modals/fe-customer-departements-modal/fe-customer-departements-modal.component";
import {FeChangePasswordModalComponent} from "../modals/fe-change-password-modal/fe-change-password-modal.component";
import {IconType} from '../parts/fe-icon/fe-icon.component';
import {FeContactCommercialModalComponent} from '../modals/fe-contact-commercial-modal/fe-contact-commercial-modal.component';

declare global {
    interface Window {
        zE: any;
    }
}

@Component({
    selector: 'layout',
    templateUrl: 'layout.component.html',
    styleUrls: ['layout.component.scss']
})


export class LayoutComponent implements OnInit, OnDestroy {

    private unsubscribe$: Subject<any> = new Subject<any>();

    public smallSidenavDesktop: boolean = false;
    public smallRightSidenavDesktop: boolean = false;

    public monitoringLinkAvailable: boolean = true;
    public monitoringLinkAvailableMessage: string = '';
    public temporaryMonitoringLinkDisabling: boolean = true;
    public menuItems: Array<any> = [];
    private isMobile: boolean;
    private overwatch;
    public sessionData: SessionInterface;
    public searchList: SearchInterface[];
    public displayChips: boolean = false;
    public currentSearch: SearchInterface;
    public currentSearchId: number;
    public currentIndex: number;
    public isSideNavOpen: boolean = true;
    public sideNavIcon: string = 'keyboard_double_arrow_left';
    public search: SearchInterface;
    @ViewChild('dateSelector') dateSelector: DatesSelectorButtonComponent;
    public fullListKeywords: string[];
    public fullListSectors: string[];
    public fullListTopics: string[];
    public fullListLocations: string[];
    public fullListCompanySizes: string[];
    public fullListActivityTypes: string[];
    displayedKeywordsList: string[] = [];
    displayedSectorsList: string[] = [];
    displayedTopicsList: string[] = [];
    displayedLocationsList: string[] = [];
    displayedCompanySizesList: string[] = [];
    displayedActivityTypesList: string[] = [];
    showAllKeywords: boolean = false;
    showAllSectors: boolean = false;
    showAllTopics: boolean = false;
    showAllLocations: boolean = false;
    showAllCompanySizes: boolean = false;
    showAllActivityTypes: boolean = false;
    isKeywordMenuOpened: boolean = false;
    isTopicsMenuOpened: boolean = false;
    isCompanySizeMenuOpened: boolean = false;
    isActivityTypeMenuOpened: boolean = false;
    isLocalisationMenuOpened: boolean = false;
    isSectorsMenuOpened: boolean = false;
    activeCriterionsCount: number = 0;
    activeCriterionsString: string = '';
    settingMenuOpen: boolean = false;
    keywordSettingsOpen: boolean = false;
    locationSettingsOpen: boolean = true;
    sectorsSettingsOpen: boolean = true;
    topicsSettingsOpen: boolean = true;
    companySizesSettingsOpen: boolean = true;
    activityTypesSettingsOpen: boolean = true;
    inputSectors: string = '';
    inputTopics: string = '';
    inputLocations: string = '';
    inputCompanySizes: string = '';
    inputActivityTypes: string = '';
    sectorsControl = new FormControl('');
    topicsControl = new FormControl('');
    locationsControl = new FormControl('');
    companySizesControl = new FormControl('');
    activityTypesControl = new FormControl('');
    sectorsControlSubscription: Subscription;
    topicsControlSubscription: Subscription;
    locationsControlSubscription: Subscription;
    companySizesControlSubscription: Subscription;
    activityTypesControlSubscription: Subscription;
    displayedSectorsSearch: string[] = [];
    displayedTopicsSearch: string[] = [];
    displayedLocationsSearch: string[] = [];
    displayedCompanySizesSearch: string[] = [];
    displayedActivityTypesSearch: string[] = [];
    themesElements: ThemeElement[] = [];
    public multiCtrl: FormControl = new FormControl();
    @ViewChild('mySelect') mySelect!: MatSelect;
    protected departements: string[] = [];
    protected isLettrePersoMC: boolean = false;

    constructor(
        public mediaQueryService: MediaQueryService
        , private appPreferencesService: AppPreferencesService
        , private navController: NavController
        , private userService: UserService
        , private dateArticleSerivice: DateArticleService
        , private pushService: PushService
        , private modalController: ModalController
        , private bottomSheet: MatBottomSheet
        , private apiService: ApiService
        , private authService: AuthService
        , private platform: Platform
        , private alertController: AlertController
        , private toastService: ToastService
        , public mySearchesService: SearchesService
        , private _snackBar: MatSnackBar
        , protected snackbarService: SnackbarService
        , protected modalService: ModalService
    ) {

        this.mySearchesService.getCriterions().pipe().subscribe((criterionsTypes: CriterionsTypesInterface) => {
            this.mySearchesService.setAllCriterions(criterionsTypes);
        });

        this.menuItems = [
            {
                label: 'Mes signaux business',
                firstEcoIcon: 'icon-first-eco-arrow',
                uri: '/app/business-signals',
                direction: 'back'
            },
            {
                label: 'Suivi d\'affaires',
                // id: 'ARTICLES_MONITORING',
                available: this.monitoringLinkAvailable,
                matIcon: 'dashboard',
                class: 'outlined',
                uri: '/app/monitoring-articles',
                direction: 'forward'
            },
            {
                label: 'Mes recherches',
                matIcon: 'search',
                class: 'outlined',
                uri: '/app/my-searches',
                direction: 'forward'
            },
            {
                label: 'Mes favoris',
                matIcon: 'star_border',
                class: 'outlined',
                uri: '/app/my-favorites',
                direction: 'forward'
            },
            {
                label: 'Carnet d\'adresses',
                matIcon: 'contacts',
                class: 'outlined',
                uri: '/app/addresses',
                direction: 'forward'
            },
            {
                label: 'Historique des envois',
                matIcon: 'access_time',
                class: 'outlined',
                uri: '/app/sending-history',
                direction: 'forward'
            },
            {
                label: 'Mon compte',
                matIcon: 'account_circle',
                class: 'outlined',
                uri: '/app/my-account',
                direction: 'forward'
            }
        ];
        this.themesElements = [
            {
                name: 'Mots-clés et expressions',
                icon: 'text_fields',
                count: 0
            },
            {
                name: 'Secteur d’activité',
                icon: 'emoji_events',
                count: 2
            },
            {
                name: 'Événements économique',
                icon: 'trending_up',
                count: 0
            },
            {
                name: 'Localisations',
                icon: 'location_on',
                count: 5
            },
            {
                name: 'Taille de l’entreprise',
                icon: 'groups',
                count: 0
            },
            {
                name: 'Nature des locaux',
                icon: 'domain',
                count: 7
            }];
        if (!(!this.platform.is('desktop') && !this.platform.is('mobileweb'))) {
            // this.isMobile = true;
            this.menuItems.push({
                label: 'Aide',
                matIcon: 'contact_support',
                class: 'outlined',
                uri: '/app/help',
                direction: 'forward'
            });
        }
    }

    public ngOnInit(): void {

        if (!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
            this.isMobile = true;
        }
        const self = this;
        // overwatch
        this.overwatch = setInterval(() => {
            this.apiService.post('v1/session/ping', {
                'connectFrom': self.isMobile ? 'mobile' : 'web',
                'fingerprint': this.authService.fingerprint
            }).subscribe();
        }, 60000);
        this.authService.setOverwatch(this.overwatch);

        // We are logged ===> init service.
        // this.userService.init();
        this.dateArticleSerivice.init();

        this.pushService.init();

        this.appPreferencesService.get('smallSidenavDesktop')
            .then((smallSidenavDesktopStr: string) => {
                // TODO PENSER à le remettre pour déploiement
                // this.smallSidenavDesktop = smallSidenavDesktopStr === 'true';
            });

        this.userService.getSessionDatas().pipe(
            takeUntil(this.unsubscribe$)
        )
            .subscribe(
                (sessionData: SessionInterface) => {
                    this.sessionData = sessionData;
                    if (sessionData !== undefined ) {
                        if (!this.authService.roleCom) {
                            try {
                                if (sessionData.communications !== undefined && sessionData.communications.length > 0) {
                                    // this.openCommunicationModal(sessionData.communications);
                                    for (const com of sessionData.communications) {
                                        if (!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
                                            if (com.type === 'information_mobile') {
                                                const communicationText = com.message;
                                                this.bottomSheet.open(BottomSheetCommunication, {data: {communicationText: communicationText}});
                                            } else if (com.type === 'modal_mobile') {
                                                this.openCommunicationModal(com);
                                            }
                                        } else {
                                            if (com.type === 'information') {
                                                const communicationText = com.message;
                                                this.bottomSheet.open(BottomSheetCommunication, {data: {communicationText: communicationText}});
                                            } else if (com.type === 'modal') {
                                                this.openCommunicationModal(com);
                                            }
                                        }
                                    }
                                }
                            } catch (e) {
                            }
                        }

                        // let com = { declinenotallowed: false, id: 2, message: '<div align="center">\n' +
                        //         '<a href="https://www.firsteco.fr/revue/FirstEco_Suivi_d-affaires_informations.pdf" target="_blank"><img src="https://www.firsteco.fr/revue/images/publication_lancement_suivi_affaires_clients_efficience.jpg" /></a>\n' +
                        //         '</div>\n', type:'modal', title:'test'  };
                        // this.openCommunicationModal(com);

                        if (sessionData.localisations != null) {
                            this.departements = [];

                            sessionData.localisations.forEach(value => {
                                this.departements.push(value.label);
                            });

                            if(sessionData.criterions != null) {
                                sessionData.criterions.forEach(value => {
                                    if (value.type === 'sector' || value.type === 'topic')
                                        this.departements.push(value.label);
                                });
                            }
                        }

                        if (sessionData.productType === 'Lettre_MotsCles') {
                            this.isLettrePersoMC = true;
                        }

                        sessionData.appConfig.viewConfigs.forEach(value => {
                            if (value.component === 'SUIVI_AFFAIRES') {
                                if (value.state !== 'visible') {
                                    this.monitoringLinkAvailable = false;
                                    this.menuItems[1].available = false;
                                }
                                this.temporaryMonitoringLinkDisabling = false;
                                this.monitoringLinkAvailableMessage = value.message !== undefined && value.message !== '' ? value.message : 'Carte';
                            }
                        });
                        if (sessionData.productType === 'Lettre_MotsCles') {
                            this.menuItems = [
                                {
                                    label: 'Mes signaux business',
                                    firstEcoIcon: 'icon-first-eco-arrow',
                                    uri: '/app/business-signals',
                                    direction: 'back'
                                },
                                {
                                    label: 'Mes recherches',
                                    matIcon: 'search',
                                    class: 'outlined',
                                    uri: '/app/my-searches',
                                    direction: 'forward'
                                },
                                {
                                    label: 'Mon compte',
                                    matIcon: 'account_circle',
                                    class: 'outlined',
                                    uri: '/app/my-account',
                                    direction: 'forward'
                                }
                            ];
                        }
                    }
                }
            );
        this.mySearchesService.getMySearches().subscribe(
            (mySearches: SearchesInterface) => {
                this.searchList = mySearches.searches;
                if (this.search?.data.libelles !== undefined && this.search?.data.libelles.keywords !== undefined && this.search?.data.libelles.keywords.length === 0) {
                    this.search.data.libelles.keywords = undefined;
                }

            }
        );

        // this.mySearchesService.refreshSearchId.subscribe((eventData: number) => {
        //     this.currentSearch = this.searchList[eventData];
        //     this.fullListKeywords = this.currentSearch.data.libelles['keywords'];
        //     this.fullListSectors = this.currentSearch.data.libelles['sectors'];
        //     this.fullListTopics = this.currentSearch.data.libelles['topics'];
        //     this.fullListLocations = this.currentSearch.data.libelles['locations'];
        //     this.fullListCompanySizes = this.currentSearch.data.libelles['companySizes'];
        //     this.fullListActivityTypes = this.currentSearch.data.libelles['activityTypes'];
        //
        //     this.activeCriterionsCount = 0;
        //
        //     if (this.fullListKeywords !== undefined) {
        //         this.activeCriterionsCount += this.currentSearch.data.libelles['keywords'].length;
        //     }
        //     if (this.fullListSectors !== undefined) {
        //         this.activeCriterionsCount += this.currentSearch.data.libelles['sectors'].length;
        //     }
        //     if (this.fullListTopics !== undefined) {
        //         this.activeCriterionsCount += this.currentSearch.data.libelles['topics'].length;
        //     }
        //     if (this.fullListLocations !== undefined) {
        //         this.activeCriterionsCount += this.currentSearch.data.libelles['locations'].length;
        //     }
        //     if (this.fullListCompanySizes !== undefined) {
        //         this.activeCriterionsCount += this.currentSearch.data.libelles['companySizes'].length;
        //     }
        //     if (this.fullListActivityTypes !== undefined) {
        //         this.activeCriterionsCount += this.currentSearch.data.libelles['activityTypes'].length;
        //     }
        //
        //     this.activeCriterionsString =  this.activeCriterionsCount > 1 ? 'critères actifs' : 'critère actif';
        //
        //
        //     this.updateDisplayedKeywordsList();
        //     this.updateDisplayedSectorsList();
        //     this.updateDisplayedTopicsList();
        //     this.updateDisplayedLocationsList();
        //     this.updateDisplayedCompanySizesList();
        //     this.updateDisplayedActivityTypesList();
        // });


        // this.sectorsControlSubscription =
        //     this.sectorsControl.valueChanges.pipe(
        //         debounceTime(2000),
        //         distinctUntilChanged()
        //     ).subscribe(newMessage => {
        //         if (newMessage.length > 2) {
        //             const criterionType = ['sector'];
        //             this.mySearchesService.searchCriterions(criterionType, this.inputSectors).pipe(
        //                 takeUntil(this.unsubscribe$)
        //             ).subscribe (
        //                 (sectorsList: any) => {
        //                     this.displayedSectorsSearch = [];
        //                     for (let i = 0; i < sectorsList.locations?.values.length; i++) {
        //                         if (!this.displayedSectorsSearch.includes(sectorsList.locations.values[i].label)) {
        //                             this.displayedSectorsSearch.push(sectorsList.locations.values[i].label);
        //                         }
        //                     }
        //                 }
        //             );
        //         }
        //     });
        //
        // this.topicsControlSubscription =
        //     this.topicsControl.valueChanges.pipe(
        //         debounceTime(2000),
        //         distinctUntilChanged()
        //     ).subscribe(newMessage => {
        //         const criterionType = ['topic'];
        //         this.mySearchesService.searchCriterions(criterionType, this.inputTopics).pipe(
        //             takeUntil(this.unsubscribe$)
        //         ).subscribe (
        //             (topicsList: any) => {
        //                 this.displayedTopicsSearch = [];
        //                 for (let i = 0; i < topicsList.locations?.values.length; i++) {
        //                     if (!this.displayedTopicsSearch.includes(topicsList.locations.values[i].label)) {
        //                         this.displayedTopicsSearch.push(topicsList.locations.values[i].label);
        //                     }
        //                 }
        //             }
        //         );
        //
        //     });
        //
        // this.locationsControlSubscription =
        //     this.locationsControl.valueChanges.pipe(
        //         debounceTime(2000),
        //         distinctUntilChanged()
        //     ).subscribe(newMessage => {
        //         const criterionType = ['department', 'region', 'country'];
        //         this.mySearchesService.searchCriterions(criterionType, this.inputLocations).pipe(
        //             takeUntil(this.unsubscribe$)
        //         ).subscribe (
        //             (locationsList: any) => {
        //                 this.displayedLocationsSearch = [];
        //                 for (let i = 0; i < locationsList.locations?.values.length; i++) {
        //                     if (!this.displayedLocationsSearch.includes(locationsList.locations.values[i].label)) {
        //                         this.displayedLocationsSearch.push(locationsList.locations.values[i].label);
        //                     }
        //                 }
        //             }
        //         );
        //
        //     });
        //
        // this.companySizesControlSubscription =
        //     this.companySizesControl.valueChanges.pipe(
        //         debounceTime(2000),
        //         distinctUntilChanged()
        //     ).subscribe(newMessage => {
        //         const criterionType = ['company_size'];
        //         this.mySearchesService.searchCriterions(criterionType, this.inputCompanySizes).pipe(
        //             takeUntil(this.unsubscribe$)
        //         ).subscribe (
        //             (companySizesList: any) => {
        //                 this.displayedCompanySizesSearch = [];
        //                 for (let i = 0; i < companySizesList.locations?.values.length; i++) {
        //                     if (!this.displayedCompanySizesSearch.includes(companySizesList.locations.values[i].label)) {
        //                         this.displayedCompanySizesSearch.push(companySizesList.locations.values[i].label);
        //                     }
        //                 }
        //             }
        //         );
        //
        //     });
        //
        // this.activityTypesControlSubscription =
        //     this.activityTypesControl.valueChanges.pipe(
        //         debounceTime(2000),
        //         distinctUntilChanged()
        //     ).subscribe(newMessage => {
        //         const criterionType = ['activity_type'];
        //         this.mySearchesService.searchCriterions(criterionType, this.inputActivityTypes).pipe(
        //             takeUntil(this.unsubscribe$)
        //         ).subscribe (
        //             (activityTypesList: any) => {
        //                 this.displayedActivityTypesSearch = [];
        //                 for (let i = 0; i < activityTypesList.locations?.values.length; i++) {
        //                     if (!this.displayedActivityTypesSearch.includes(activityTypesList.locations.values[i].label)) {
        //                         this.displayedActivityTypesSearch.push(activityTypesList.locations.values[i].label);
        //                     }
        //                 }
        //             }
        //         );
        //
        //     });
    }

    getParameterByName(name: any) {
        const url = window.location.href;
        name = name.replace(/[[]]/g, '\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) {
            return undefined;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace('/+/g', ' '));
    }

    public async openCommunicationModal(communications) {
        const modal = await this.modalController.create({
            component: CommunicationModalComponent,
            cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog',
            componentProps: {
                communications: communications
            }
        });
        modal.present();
    }

    public ngOnDestroy(): void {
        // this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public toogleSidenavSize(): void {
        this.isSideNavOpen = !this.isSideNavOpen;
        if (this.isSideNavOpen) {
            this.sideNavIcon = 'keyboard_double_arrow_left';
        } else {
            this.sideNavIcon = 'keyboard_double_arrow_right';
        }

        this.smallSidenavDesktop = !this.smallSidenavDesktop;
        this.appPreferencesService.set('smallSidenavDesktop', this.smallSidenavDesktop.toString());
    }

    public navigateToUrl(url: string, direction: string = 'forward'): void {
        // window.removeEventListener('beforeunload', MySearchesAddComponent.beforeUnloadHandler);
        if (typeof url !== 'undefined' && url !== null && url !== '') {
            if (direction === 'forward') {
                Utils.navigate(url, this.navController);
                // this.navController.navigateForward(url);
            } else if (direction === 'back') {
                const extras = {
                    queryParams: {
                        token: Utils.userToken
                    }
                };
                this.navController.navigateBack(url, extras);
            } else if (direction === 'root') {
                this.navController.navigateRoot(url);
            }
        }
    }

    onMenuClick(snav: MatSidenav, item: any) {
        if (this.mediaQueryService.mobileQuery.matches) {
            snav.close();
        }
        if (item.uri === '/app/sending-history') {
            this.userService.log('READ_MAIL_HISTORY');
        }
        this.userService.log('NAVIGATION_TAB_DISPLAY');
        // if (item.id === 'ARTICLES_MONITORING' && item.available != null && !item.available) {
        //
        // }
        this.navigateToUrl(item.uri, item.direction);
    }

    // public async confirmLogout() {
    //     const alert = await this.alertController.create({
    //         header: 'Attention',
    //         message: 'Etes-vous sur de vouloir vous déconnecter ?',
    //         buttons: [
    //             {
    //                 text: 'Annuler',
    //                 role: 'cancel',
    //                 cssClass: 'secondary',
    //                 handler: () => { }
    //             }, {
    //                 text: 'Oui',
    //                 handler: () => this.logout()
    //             }
    //         ]
    //     });
    //     await alert.present();
    // }

    protected confirmLogout(): void {
        this.modalService.confirmModal('Déconnexion', 'Etes-vous sur de vouloir vous déconnecter ?', args => {
            this.authService.logout();
            this.snackbarService.showDangerSnackbar('Déconnexion', 'Vous êtes déconnecté. Vous allez être redirigé vers la page de connexion.');
        });
    }

    public logout(): void {
        this.authService.logout();
        this.toastService.simple('Vous êtes déconnecté. Vous allez être redirigé vers la page de connexion.', { color: 'toastinfo' });
    }

    public trackByMenuItem(index: number, item) {
        return item.url;
    }

    emitEventBusinessSignals(index: number) {
        this.mySearchesService.emitEventToBusinessSignals(index);
    }

    openBusinessSignals(search: SearchInterface, index: number) {
        if (search !== undefined) {
            search.dataRecentResults.isView = true;
            this.emitEventBusinessSignals(index);

            const params: NavigationOptions = {
                queryParams: {
                    search: search.searchId
                }
            };
            Utils.navigate('/app/business-signals', this.navController, params);
            // this.navController.navigateForward('/app/business-signals', params);
        }
    }

    isToolTipActive(i: number): boolean {
        this.displayChips = false;
        const nbResults: number = this.searchList[i]?.dataRecentResults.nbResults;
        const isView: boolean = this.searchList[i]?.dataRecentResults.isView;
        if (isView !== undefined && !isView && nbResults !== undefined) {
            this.displayChips = true;
        }
        return !this.displayChips;
    }

    getToolTipLabel(i: number) {
        if (this.searchList[i] !== undefined) {
            const nbResults: number = this.searchList[i].dataRecentResults.nbResults;
            if (this.displayChips) {
                return nbResults;
            }
        }
        return undefined;
    }

    updateCurrentSearch(search: SearchInterface, index: number, $event) {
        $event.stopPropagation();
        this.currentSearch = search;
        this.currentSearchId = search.searchId;
        this.currentIndex = index;
    }

    updateDisplayedKeywordsList() {
        this.displayedKeywordsList = this.showAllKeywords ? this.fullListKeywords : this.fullListKeywords?.slice(0, 5);
    }
    updateDisplayedSectorsList() {
        this.displayedSectorsList = this.showAllSectors ? this.fullListSectors : this.fullListSectors?.slice(0, 5);
    }
    updateDisplayedTopicsList() {
        this.displayedTopicsList = this.showAllTopics ? this.fullListTopics : this.fullListTopics?.slice(0, 5);
    }
    updateDisplayedLocationsList() {
        this.displayedLocationsList = this.showAllLocations ? this.fullListLocations : this.fullListLocations?.slice(0, 5);
    }
    updateDisplayedCompanySizesList() {
        this.displayedCompanySizesList = this.showAllCompanySizes ? this.fullListCompanySizes : this.fullListCompanySizes?.slice(0, 5);
    }
    updateDisplayedActivityTypesList() {
        this.displayedActivityTypesList = this.showAllActivityTypes ? this.fullListActivityTypes : this.fullListActivityTypes?.slice(0, 5);
    }


    showAllKeywordsItems() {
        this.showAllKeywords = true;
        this.updateDisplayedKeywordsList();
    }
    showAllSectorsItems() {
        this.showAllSectors = true;
        this.updateDisplayedSectorsList();
    }
    showAllTopicsItems() {
        this.showAllTopics = true;
        this.updateDisplayedTopicsList();
    }
    showAllLocationsItems() {
        this.showAllLocations = true;
        this.updateDisplayedLocationsList();
    }
    showAllCompanySizeItems() {
        this.showAllCompanySizes = true;
        this.updateDisplayedCompanySizesList();
    }
    showAllActivityTypeItems() {
        this.showAllActivityTypes = true;
        this.updateDisplayedActivityTypesList();
    }

    showLessKeywordsItems() {
        this.showAllKeywords = false;
        this.updateDisplayedKeywordsList();
    }
    showLessSectorsItems() {
        this.showAllSectors = false;
        this.updateDisplayedSectorsList();
    }
    showLessTopicsItems() {
        this.showAllTopics = false;
        this.updateDisplayedTopicsList();
    }
    showLessLocationsItems() {
        this.showAllLocations = false;
        this.updateDisplayedLocationsList();
    }
    showLessCompanySizeItems() {
        this.showAllCompanySizes = false;
        this.updateDisplayedCompanySizesList();
    }
    showLessActivityTypesItems() {
        this.showAllActivityTypes = false;
        this.updateDisplayedActivityTypesList();
    }

    openKeywordMenu() {
        this.isKeywordMenuOpened = true;
    }

    closeKeywordMenu() {
        this.isKeywordMenuOpened = false;
    }

    openTopicsMenu() {
        this.isTopicsMenuOpened = true;
    }

    closeTopicsMenu() {
        this.isTopicsMenuOpened = false;
    }

    openCompanySizeMenu() {
        this.isCompanySizeMenuOpened = true;
    }

    closeCompanySizeMenu() {
        this.isCompanySizeMenuOpened = false;
    }

    openLocalisationMenu() {
        this.isLocalisationMenuOpened = true;
    }

    closeLocalisationMenu() {
        this.isLocalisationMenuOpened = false;
    }

    openSectorsMenu() {
        this.isSectorsMenuOpened = true;
    }

    closeSectorsMenu() {
        this.isSectorsMenuOpened = false;
    }

    openActivityTypesMenu() {
        this.isActivityTypeMenuOpened = true;
    }

    closeActivityTypesMenu() {
        this.isActivityTypeMenuOpened = false;
    }

    displayKeywordsMenu() {
        this.settingMenuOpen = true;
        this.sectorsSettingsOpen = false;
        this.topicsSettingsOpen = false;
        this.locationSettingsOpen = false;
        this.companySizesSettingsOpen = false;
        this.activityTypesSettingsOpen = false;
        this.keywordSettingsOpen = true;
    }

    displaySectorsMenu() {
        this.settingMenuOpen = true;
        this.sectorsSettingsOpen = true;
        this.topicsSettingsOpen = false;
        this.locationSettingsOpen = false;
        this.companySizesSettingsOpen = false;
        this.activityTypesSettingsOpen = false;
        this.keywordSettingsOpen = false;
    }

    displayTopicsMenu() {
        this.settingMenuOpen = true;
        this.sectorsSettingsOpen = false;
        this.topicsSettingsOpen = true;
        this.locationSettingsOpen = false;
        this.companySizesSettingsOpen = false;
        this.activityTypesSettingsOpen = false;
        this.keywordSettingsOpen = false;
    }

    displayLocationMenu() {
        this.settingMenuOpen = true;
        this.sectorsSettingsOpen = false;
        this.topicsSettingsOpen = false;
        this.locationSettingsOpen = true;
        this.companySizesSettingsOpen = false;
        this.activityTypesSettingsOpen = false;
        this.keywordSettingsOpen = false;
    }


    displayCompanySizesMenu() {
        this.settingMenuOpen = true;
        this.sectorsSettingsOpen = false;
        this.topicsSettingsOpen = false;
        this.locationSettingsOpen = false;
        this.companySizesSettingsOpen = true;
        this.activityTypesSettingsOpen = false;
        this.keywordSettingsOpen = false;
    }

    displayActivityTypesMenu() {
        this.settingMenuOpen = true;
        this.sectorsSettingsOpen = false;
        this.topicsSettingsOpen = false;
        this.locationSettingsOpen = false;
        this.companySizesSettingsOpen = false;
        this.activityTypesSettingsOpen = true;
        this.keywordSettingsOpen = false;
    }

    closeSettingsMenu(data: boolean) {
        this.settingMenuOpen = false;
    }

    onUpdateCriterionList(data: {typeCriterion: string, criterion: CriterionInterface, add: boolean }) {

        if (data.typeCriterion === 'topics') {
            if (data.add) {
                if (this.displayedTopicsList !== undefined) {
                    this.displayedTopicsList.push(data.criterion.label);
                } else {
                    this.displayedTopicsList = [];
                    this.displayedTopicsList.push(data.criterion.label);
                }
            } else {
                this.displayedTopicsList = this.displayedTopicsList.filter(element => element !== data.criterion.label);
            }
        }

        if (data.typeCriterion === 'sectors')  {
            if (data.add) {
                if (this.displayedSectorsList !== undefined) {
                    this.displayedSectorsList.push(data.criterion.label);
                } else {

                    this.displayedSectorsList = [];
                    this.displayedSectorsList.push(data.criterion.label);
                }
            } else {
                this.displayedSectorsList = this.displayedSectorsList.filter(element => element !== data.criterion.label);
            }
        }
        if (data.typeCriterion === 'locations') {
            if (data.add) {
                this.displayedLocationsList.push(data.criterion.label);
            } else {
                this.displayedLocationsList = this.displayedLocationsList.filter(element => element !== data.criterion.label);
            }
        }
        if (data.typeCriterion === 'companySizes') {
            if (data.add) {
                this.displayedCompanySizesList.push(data.criterion.label);
            } else {
                this.displayedCompanySizesList = this.displayedCompanySizesList.filter(element => element !== data.criterion.label);
            }
        }
        if (data.typeCriterion === 'activityTypes') {
            if (data.add) {
                this.displayedActivityTypesList.push(data.criterion.label);
            } else {
                this.displayedActivityTypesList = this.displayedActivityTypesList.filter(element => element !== data.criterion.label);
            }
        }
    }

    addToSectorsList(criterion: string) {
        if (this.displayedSectorsList !== undefined) {
            this.displayedSectorsList.push(criterion);
        } else {
            this.displayedSectorsList = [];
            this.displayedSectorsList.push(criterion);
        }
    }

    addToTopicsList(criterion: string) {
        if (this.displayedTopicsList !== undefined) {
            this.displayedTopicsList.push(criterion);
        } else {
            this.displayedTopicsList = [];
            this.displayedTopicsList.push(criterion);
        }
    }

    addToLocationsList(criterion: string) {
        if (this.displayedLocationsList !== undefined) {
            this.displayedLocationsList.push(criterion);
        } else {
            this.displayedLocationsList = [];
            this.displayedLocationsList.push(criterion);
        }
    }

    addToCompanySizesList(criterion: string) {
        if (this.displayedCompanySizesList !== undefined) {
            this.displayedCompanySizesList.push(criterion);
        } else {
            this.displayedCompanySizesList = [];
            this.displayedCompanySizesList.push(criterion);
        }
    }

    addToActivityTypesList(criterion: string) {
        if (this.displayedActivityTypesList !== undefined) {
            this.displayedActivityTypesList.push(criterion);
        } else {
            this.displayedActivityTypesList = [];
            this.displayedActivityTypesList.push(criterion);
        }
    }


    newSearch() {
        Utils.navigate('/app/my-searches-add', this.navController);
    }

    openSnackBar() {
        this._snackBar.open('Cannonball!!', 'Splash', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom'
        });
    }

    showInformationModal() {
        this.modalService.informationModal('C\'est un titre d\'information',
            'C\'est le message par défaut');
    }

    confirmModal() {
        this.modalService.confirmModal('Confirmer l\'alerte',
            'Confirmer ce message enverra une alerte dans le navigateur',
            (args: any): void => {
            alert('Confirmation de la modal');
        });
    }

    inputModal(): void {
        this.modalService.inputModal('Input de la modal',
            'Ajouter une valeur et elle sera dans la modal de retour', null, null,
            '',
            (args: any): void => {
            alert('Valeur de l\'input : ' + args);
            });
    }

    protected showLocationDetails(): void {
        this.modalService.showCustomModal(FeCustomerDepartementsModalComponent, {
            data: {
                departements: this.departements,
                salesPersonDetails: this.sessionData?.salesPersonDetails
            }
        });
    }

    public getMobileLoginInfos(): void {
        this.modalService.confirmModal('Connexion à l\'application mobile', 'Pour vous connecter à l\'application mobile, vous devez utiliser : ' +
            '<br> - un login qui est votre adresse email<br> - votre mot de passe<br><br>Merci de noter que pour garantir la sécurité ' +
            'de vos données, vous devez désormais utiliser votre login et votre mot de passe pour vous connecter à votre compte First ECO sur votre ' +
            'application mobile et sur votre ordinateur.<br><br>J\'ai bien compris et je souhaite recevoir mes identifiants par mail.',
            args => {
                this.sendMobileLoginRequest();
            });
    }

    private sendMobileLoginRequest() {
        this.apiService.get('v1/session/receiveMobileAccess').subscribe(next => {
            this.toastService.simple('Un mail vous a été envoyé avec les informations demandées.', { duration: 2500 });
        });
    }

    public changePassword(reinit: boolean): void  {
        // const alert = await this.modalController.create({
        //     component: FeChangePasswordModalComponent,
        //     componentProps: {
        //         reinit: reinit
        //     },
        //     cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'changePasswordDialog',
        //     backdropDismiss: false
        // });
        // return await alert.present();

        this.modalService.showCustomModal(FeChangePasswordModalComponent, { disableClose: true, data: {reinit: reinit}});
    }

    protected contactMyCommercial(): void {
        this.modalService.showCustomModal(FeContactCommercialModalComponent, { data : {
                salesPersonDetails: this.userService.sessionData.salesPersonDetails,
                feature: 'other',
                title: 'Vous avez des questions ? besoin de renseignements ?'
            }});
    }

    protected readonly IconType = IconType;

    protected openZendesk(): void {
        window.zE('webWidget', 'open');
    }
}

@Component({
    selector: 'bottom-sheet-communication',
    templateUrl: 'bottom-sheet-communication.html',
})
export class BottomSheetCommunication {
    public communicationText: SafeHtml;

    constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private sanitizer: DomSanitizer) {
        this.communicationText = sanitizer.bypassSecurityTrustHtml(data.communicationText);
    }
}

export interface ThemeElement {
    name: string;
    icon: string;
    count: number;
}
