import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SessionInterface} from '../../../interfaces/session/session.interface';
import {SearchInterface} from '../../../interfaces/search/search.interface';

@Component({
  selector: 'search-list-item',
  templateUrl: './search-list-item.component.html',
  styleUrls: ['./search-list-item.component.scss'],
})
export class SearchListItemComponent implements OnInit {
  public search: SearchInterface;
  @Input() protected selected: boolean = false;
  @Input() public index: number;
  @Input() public searchList: SearchInterface[];

  // protected show: boolean = false;
  @Output() searchSelected = new EventEmitter<SearchInterface>();

  constructor() { }

  @Input()
  get value(): SearchInterface {
    return this.search;
  }

  @Output()
  valueChange = new EventEmitter<SearchInterface>();

  set value(search: SearchInterface) {
    this.search = search;
  }

  ngOnInit() {}

  loadSearch(): void {
    // this.show = !this.show;
    this.searchSelected.emit(this.search);
  }
}
