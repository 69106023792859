<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="end">
      <button mat-icon-button aria-label="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  
  <div class="cardContainer" [ngClass]="{'desktop': !mediaQueryService.mobileQuery.matches}">

    <div class="cards" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
      <mat-card class="modalCard" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches, 'noBoxShadow': isDatesActive || !isPeriodActive }" (click)="active('period')">
        <div class="formTitle greyColor" [ngClass]="{'primaryColor': isPeriodActive, 'greyColor': isDatesActive || !isPeriodActive}" >{{"DEFINE_ONE_PERIOD"}}</div>
        <x-form #formPeriod [fields]="fieldsPeriod" [submitMethod]="formSubmitMethod" hideSubmitButton="true"></x-form>
      </mat-card>

      <mat-card class="modalCard" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches, 'noBoxShadow': isPeriodActive || !isDatesActive }" (click)="active('dates')">
        <div class="formTitle" [ngClass]="{'primaryColor': isDatesActive, 'greyColor': isPeriodActive || !isDatesActive}">{{"FILTER_FROM_DATE_TO_DATE"}}</div>
        <x-form #formDates [fields]="fieldsDates" [submitMethod]="formSubmitMethod" hideSubmitButton="true"></x-form>
      </mat-card>
    </div>
    <div class="buttonContainer" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
      <button class="mat-rounded-button" mat-raised-button color="accent" (click)="save()" [disabled]="!isDateOrPeriodSelected()">
        <mat-icon>check_circle_outline</mat-icon>
        {{"APPLY"}}
      </button>
    </div>
  </div>

</ion-content>
