<ion-header no-border class="allContactHeader">
  <div class="ficEntHeaderBtnBar" style="flex-grow: 1; display: flex;">
    <!--        <span style="margin: auto 5px auto 10px; font-size: 20px; color: #254354; word-break: break-word; hyphens: auto;">{{entreprise.name}} - Contacts</span>     -->
    <span class>{{contact.firstName}} {{contact.lastName}}</span>

  </div>

  <button class="contactDetailCloseBtn" aria-label="close" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</ion-header>

<ion-content style="text-align: center">
  <p class="contactTitle">{{contact.civility}} {{contact.firstName}} {{contact.lastName}}</p>
  <p>{{contact.role}}</p>

  <h1 class="categoryTitle">Informations générales</h1>
  <div style="display: flex;flex-direction: column; margin-bottom: 30px;">
    <div class="contactInformationLine">
      <span class="contactInfoLabel">Civilité :</span>
      <span class="contactInfoText">{{contact.civility}}</span>
    </div>
    <div class="contactInformationLine">
      <span class="contactInfoLabel">Prénom :</span>
      <span class="contactInfoText">{{contact.firstName}}</span>
    </div>
    <div class="contactInformationLine">
      <span class="contactInfoLabel">Nom :</span>
      <span class="contactInfoText">{{contact.lastName}}</span>
    </div>
  </div>

  <h1 class="categoryTitle">Informations administratives</h1>
  <div style="display: flex;flex-direction: column; margin-bottom: 30px;">
    <div class="contactInformationLine">
      <span class="contactInfoLabel">Intitulé du poste :</span>
      <span class="contactInfoText">{{contact.role}}</span>
    </div>
    <div class="contactInformationLine">
      <span class="contactInfoLabel">Domaine :</span>
      <span class="contactInfoText">{{contact.domain_label}}</span>
    </div>
    <div class="contactInformationLine">
      <span class="contactInfoLabel">Niveau :</span>
      <span class="contactInfoText">{{contact.level_label}}</span>
    </div>
  </div>

  <h1 class="categoryTitle">Informations de contacts</h1>
  <div style="display: flex;flex-direction: column; margin-bottom: 30px;">
    <div class="contactInformationLine">
      <span class="contactInfoLabel">Email :</span>
      <span class="contactInfoText" *ngIf="contact.email === null || contact.email === undefined || contact.email.trim().length === 0">NC</span>
      <a class="contactInfoText" href="mailto:{{contact.email}}" *ngIf="contact.email?.trim().length > 0"
         title="{{contact.email}}">{{contact.email}}</a>
    </div>
<!--    <div class="contactInformationLine">
      <span class="contactInfoLabel">Score email :</span>
      <span class="contactInfoText">{{contact.email_score}}</span>
    </div>-->
    <div class="contactInformationLine">
      <span class="contactInfoLabel" style="height: 26px; line-height: 26px;">LinkedIn :</span>
      <span class="contactInfoText" *ngIf="contact.linkedin_url === null || contact.linkedin_url === undefined
      || contact.linkedin_url.trim().length === 0">NC</span>
      <button *ngIf="contact.linkedin_url && contact.linkedin_url.trim().length > 0"
              class="allContactSNButton" style="flex: 7; text-align: left;"
              (click)="openUrl(contact.linkedin_url, 'RS')">
        <img style="width: 20px; height: 20px;" src="./assets/icon/linkedin2.png">
      </button>
    </div>
  </div>

  <button (click)="close()" class="closeContactDetailBtn">Fermer la fenêtre</button>

</ion-content>
