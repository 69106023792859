import { Component, forwardRef, Input, OnInit, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';
import { FormFieldInterface } from '../../../interfaces/form-field/form-field.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { XFormErrorStateMatcher } from '../x-form-error-state-matcher.class';
import { Subject } from 'rxjs';

@Component({
  selector: 'x-field',
  template: '',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => XFieldComponent), multi: true }
  ]
})
export class XFieldComponent implements ControlValueAccessor, OnInit {

  protected unsubscribe$: Subject<any> = new Subject<any>();

  @Input() public field: FormFieldInterface;

  @Input() formControl: FormControl;

  public formFieldValue: any;
  public xFormErrorStateMatcher: XFormErrorStateMatcher;

  protected propagateChange: Function = (value: string) => { };
  protected onTouched: Function = (value: string) => { };

  constructor(
    protected matSnackBar: MatSnackBar
  ) {
    this.xFormErrorStateMatcher = new XFormErrorStateMatcher();
  }

  public ngOnInit(): void {
    // trigger valueChange on each field (usefull for xJson and xJsonArray)
    this.formControl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  public writeValue(value: any): void {
    if (value) {
      this.formFieldValue = value;
    }
  }

  public registerOnChange(fn: Function): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  public onBlur(event: Event, value: any): void {
    this.formFieldValue = value;
    this.propagateChange(this.formFieldValue);
    this.formControl.updateValueAndValidity();
  }

  public onFocus($event: any, field: FormFieldInterface): void {}

}
