import { Injectable } from '@angular/core';
import {Observable, BehaviorSubject, Subscriber, throwError, of, shareReplay} from 'rxjs';
import { share, tap, map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { AddressesInterface } from 'src/app/interfaces/addresses/addresses';
import { AddressInterface } from 'src/app/interfaces/address/address';
import { HttpResponse } from '@angular/common/http';
import { ErrorStatus } from 'src/app/classes/ErrorStatus.class';


@Injectable({
    providedIn: 'root'
})
export class AddressesService {
    private firstCall: any = true;

    constructor(
        private apiService: ApiService
    ) {
        this.loadAdresses();
    }

    private addresses: AddressesInterface;
    private addresses$: BehaviorSubject<AddressesInterface> = new BehaviorSubject(undefined);
    private refreshAdresses$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private cache$: Observable<any>;

    private addressesCache: BehaviorSubject<AddressesInterface> = new BehaviorSubject(undefined);

    public getAddresses(query: { [key: string]: string } = {}): BehaviorSubject<AddressesInterface> {
        return this.addressesCache;
    }

    public loadAdresses(): void {
        this.apiService.get<AddressesInterface>('v1/addresses', {}).pipe(
            map((response: HttpResponse<AddressesInterface>) => {
                return response.body;
            }), shareReplay(1)
        ).subscribe(res => {
            this.addressesCache.next(res);
        });
    }
    // /**
    //  * Get address online
    //  */
    // public getAddresses(query: { [key: string]: string } = {}): Observable<AddressesInterface> {
    //   if (!this.cache$) {
    //       this.cache$ = this.apiService.get<AddressesInterface>('v1/addresses', query).pipe(
    //           map((response: HttpResponse<AddressesInterface>) => {
    //               this.addresses = response.body;
    //               return response.body;
    //           }), shareReplay(1)
    //       );
    //   }
    //   return this.cache$;
    // }

    /**
     * Add address in address
     * @param addressID number : id of the article
     */
    public addAddress(address: AddressInterface): Observable<HttpResponse<AddressInterface>> {
        // add address to address through api
        return this.apiService.post<AddressInterface>(`v1/addresses`, address).pipe(
            map((response: HttpResponse<AddressInterface>) => {
                if (response.status === 200 || response.status === 201) {
                    // this.addresses = undefined;
                    // this.firstCall = true;
                    // this.getAddressesSubject(true);
                    this.loadAdresses();
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
                // return response.body;
            })
        );
    }

    /**
     * Update an address of address
     * @param addressID: number : id of the address
     * @param addressUpdated : data updated
     */
    public updateAddress(addressID: number, addressUpdated: HttpResponse<AddressInterface>): Observable<any> {
        // add address to address through api
        return this.apiService.patch<AddressInterface>(`v1/address/${addressID}`, addressUpdated).pipe(
            map((response: HttpResponse<AddressInterface>) => {
                if (response.status === 200 || response.status === 201) {
                    // this.addresses = undefined;
                    // this.firstCall = true;
                    this.loadAdresses();
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    /**
     * Remove an address
     * @param addressID number : id of the address
     */
    public removeAddress(addressID: number): Observable<HttpResponse<AddressInterface>> {
        // remove address to address through api
        return this.apiService.delete(`v1/address/${addressID}`).pipe(
            tap((response: HttpResponse<any>) => {
                if (response.status === 200) {
                    // this.addresses = undefined;
                    // this.firstCall = true;
                    this.loadAdresses();
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    // getAddressesSubject(forceRefresh: boolean = false) {
    //   if (this.firstCall || forceRefresh) {
    //       this.firstCall = false;
    //       this.apiService.get<AddressesInterface>('v1/addresses', {}).pipe(
    //           map((response: HttpResponse<AddressesInterface>) => {
    //               this.addresses = response.body;
    //               return response.body;
    //           })
    //       ).subscribe(response => {
    //           this.addresses = response;
    //           this.addresses$.next(response);
    //       });
    //   }
    //     return this.addresses$;
    // }

    public refreshAddresses(): void {
        this.refreshAdresses$.next(!this.refreshAdresses$.value);
    }

    public onAddressesChange(): BehaviorSubject<boolean> {
        return this.refreshAdresses$;
    }
}
