<ion-header no-border>
  <ion-toolbar>
    <b style="margin-left: 10px">Sauvegarder en tant que template</b>
    <ion-buttons slot="end">
      <button mat-icon-button aria-label="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div>
    <input
            placeholder="Nom du template"
            matInput
            [(ngModel)]="nlName"
            style="font-size: 15px; margin: 20px 0px 20px 0px;
                padding-bottom: 5px;
                border-bottom: thick !important;
                border-bottom: 2px solid black !important;">
  </div>
  <button
          class="mat-rounded-button"
          mat-raised-button color="accent"
          (click)="saveTemplate()">
    Sauvegarder
  </button>
</ion-content>
