import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {UserService} from '../../../services/user/user.service';
import {ApiService} from '../../../services/api/api.service';
import {ToastService} from '../../../services/toast/toast.service';

@Component({
  selector: 'app-reinit-password',
  templateUrl: './reinit-password.component.html',
  styleUrls: ['./reinit-password.component.scss'],
})
export class ReinitPasswordComponent implements OnInit {

  @ViewChild('email') email: ElementRef;

  constructor(private modalController: ModalController
  , private apiService: ApiService
  , private toastService: ToastService
  , private navParams: NavParams) { }

  ngOnInit() {
  }

  public ionViewWillEnter(): void {
    this.email.nativeElement.value = this.navParams.get('mail');
  }

  /**
   * Close the modal
   */
  public close(success: boolean = false): void {
    this.modalController.dismiss(success);
  }

  send() {
    this.apiService.secureGet('authentification/reinitPassword?email=' + this.email.nativeElement.value).subscribe((response: any) => {
      if (response.body.success) {
        this.toastService.simple('Un mail vous a été envoyé avec vos nouveaux identifiants');
        this.close(true);
      }
      else {
        this.toastService.simple('Une erreur s\'est produite durant la réinitialisation du mot de passe', { duration: 2500, position: 'bottom', color: 'toasterror' });
      }
    });
  }
}
