import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable({
  providedIn: 'root'
})
export class MatPaginatorIntlCustom extends MatPaginatorIntl {

  constructor(
  ) {
    super();

    this.itemsPerPageLabel = 'Articles'; // this.translateService.instant('ITEMS_PER_PAGE');
    this.nextPageLabel = 'Suivant';
    this.previousPageLabel = 'Précédent';
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {

    const start: string = ((page * pageSize) + 1).toString();
    const end: string = ((((page * pageSize) + pageSize) < length) ? ((page * pageSize) + pageSize) : length).toString();

    return start + ' - ' + end + ' ' + 'sur' + ' ' + length;
  }
}
