import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { HttpResponse } from '@angular/common/http';
import { MailsHistoryInterface } from 'src/app/interfaces/mails-history/mails-history.interface';
import {NewsletterTemplate} from '../../interfaces/newsletter/newsletter-template';
import {FavorisFolder} from '../../interfaces/favoritesFolders/favorites-folders.interface';
import {Newsletter} from '../../interfaces/newsletter/newsletter';
import {ArticleInterface} from '../../interfaces/articles/article.interface';

@Injectable({
  providedIn: 'root'
})
export class MailsService {

  public selectedTemplate: NewsletterTemplate;
  public selectedFolder: FavorisFolder;
  public nl: Newsletter;

  constructor(
      private apiService: ApiService
  ) {
  }

  /**
   * Get mails history online
   * @param query { [key: string]: string
   */
  public getHistory(query: { [key: string]: string } = {}): Observable<MailsHistoryInterface> {
    return this.apiService.get<MailsHistoryInterface>('v1/share/history', query).pipe(
        map((response: HttpResponse<MailsHistoryInterface>) => {
          return response.body;
        })
    );
  }

  public getNewsletterTemplates(): Observable<Array<NewsletterTemplate>> {
    return this.apiService.get<Array<NewsletterTemplate>>('v1/newsletter/templates').pipe(
        map((response: HttpResponse<Array<NewsletterTemplate>>) => {
          return response.body;
        })
    );
  }

  public getNewsletters(): Observable<Array<Newsletter>> {
    return this.apiService.get<Array<Newsletter>>('v1/newsletter/objects').pipe(
        map((response: HttpResponse<Array<Newsletter>>) => {
          return response.body;
        })
    );
  }

  public getNewsletterAvailableArticles(newsletterId: number): Observable<Array<ArticleInterface>> {
    return this.apiService.get<Array<ArticleInterface>>(`v1/newsletter/articles/${newsletterId}`).pipe(
        map((response: HttpResponse<Array<ArticleInterface>>) => {
          return response.body;
        })
    );
  }

  public updateNewsletter(nl: Newsletter): Observable<Newsletter> {
    return this.apiService.patch<Newsletter>(`v1/newsletter/object`, nl).pipe(
        map((response: HttpResponse<Newsletter>) => {
          return response.body;
        })
    );
  }

  public setTemplateSelection(template: NewsletterTemplate, folder: FavorisFolder) {
    this.selectedTemplate = template;
    this.selectedFolder = folder;
  }

  createNewsletterFromTemplateAndFolder(selectedTemplate: NewsletterTemplate, selectedFolder: FavorisFolder): Observable<Newsletter> {
    return this.apiService.get<Newsletter>(`v1/newsletter/open/${selectedFolder.folderID}/${selectedTemplate.id}/${encodeURIComponent(selectedTemplate.name)}`).pipe(
        map((response: HttpResponse<Newsletter>) => {
          return response.body;
        })
    );
  }

  getLibArticlesHtml(articles: Array<ArticleInterface>) {
    let libArticles = '';
    articles.forEach(article => {
      libArticles += this.getLibArticleHtml(article);
    });
    return libArticles;
  }

  private getLibArticleHtml(article: ArticleInterface) {

    const source = '<a style="display: none;" href="' + article.sources.sourcesArray[0].directUrl + '">' + article.sources.sourcesArray[0].name + '</a>';
    const a = '<div id="article_' + article.id + '" style="display:block; cursor: pointer; padding: 10px; border: 1px solid black; border-radius: 60px;" class="thumbnail">\n' +
        '\t<p titlelink="' + article.articleLink + '">' + article.title + '</p>\n' +
        '\t<div style="display: none;">' + article.corps + '</div>\n' +
        '\t' + source + '\n' +
        '</div>\n';
    return a.replace(/'/g, "\'");
  }

  sendNewsletter(mail: string) {
    return this.apiService.post<Newsletter>(`v1/newsletter/send/${encodeURIComponent(mail)}`, this.nl).pipe(
        map((response: HttpResponse<Newsletter>) => {
          return response.body;
        })
    );
  }

  historizeNewsletter(id: number) {
    return this.apiService.get<Newsletter>(`v1/newsletter/history/${encodeURIComponent(id)}`).pipe(
        map((response: HttpResponse<Newsletter>) => {
          return response.body;
        })
    );
  }

  public saveAsTemplate(nl: Newsletter): Observable<Newsletter> {
    return this.apiService.put<Newsletter>(`v1/newsletter/object/template`, nl).pipe(
        map((response: HttpResponse<Newsletter>) => {
          return response.body;
        })
    );
  }

    public contactSalespersonn(feature: string, comment?: string) {
      let data: {} = {
          feature: feature
      };
      if (comment != null) {
          data = {
              feature: feature,
              comment: comment
          }
      }
      return this.apiService.post<any>(`v1/clients/contactSalesperson?feature=${feature}`, data).pipe(
          map((response: HttpResponse<any>) => {
              return response;
          }));
    }
}
