import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { ArticlesService } from 'src/app/services/articles/articles.service';
import { takeUntil } from 'rxjs/operators';
import {ArticlesInterface} from '../../../interfaces/articles/articles.interface';
import {ErrorStatus} from '../../../classes/ErrorStatus.class';
import {FavoritesService} from '../../../services/favorites/favorites.service';
import {ToastService} from '../../../services/toast/toast.service';
import {AlertController} from '@ionic/angular';

@Component({
  selector: 'article-delete-fav-button',
  templateUrl: './article-delete-fav-button.component.html',
  styleUrls: ['./article-delete-fav-button.component.scss'],
})
export class ArticleDeleteFavButtonComponent implements OnInit {

  @Input() public selectionModel: SelectionModel<string>;
  @Input() public articles: ArticlesInterface;
  @Input() public askBeforeDelete: boolean = false;
  articleRead: boolean;

  private unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
      private articlesService: ArticlesService,
      private favoritesService: FavoritesService,
      private toastService: ToastService,
      private alertController: AlertController
  ) {}

  public ngOnInit(): void {}

  public removeFavorite(selectionModel: any) {
    this.favoritesService.removeArticleFromFavorites(selectionModel).pipe(
        takeUntil(this.unsubscribe$)
    ).subscribe(
        () => {
          this.toastService.simple('Favoris supprimés avec succès', { color: 'toastinfo' });
          // this.article.favorite = false;
          this.favoritesService.needRefresh.next(true);
        },
        (error: Error | ErrorStatus) => {
          let errorStr: string = 'Une erreur est survenue lors de la suppression de ces favoris';
          // if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
          //   errorStr += `(${this.translateService.instant(error.message)})`;
          // }
          this.toastService.simple(errorStr, { color: 'toasterror' });
        }
    );
  }

  public async askBeforeRemoveFavorite(selectionModel: any) {
    const alert = await this.alertController.create({
      header: 'Attention',
      message: '<b>Voulez-vous vraiment supprimer ces favoris ?',
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => { }
        }, {
          text: 'Oui',
          handler: () => this.removeFavorite(selectionModel)
        }
      ]
    });
    await alert.present();
  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


}
