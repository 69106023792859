import {Component, Input, OnInit} from '@angular/core';
import {ColorType, IconType} from '../fe-icon/fe-icon.component';

@Component({
  selector: 'fe-button',
  templateUrl: './fe-button.component.html',
  styleUrls: ['./fe-button.component.scss']
})
export class FeButtonComponent {
  @Input() type: string = 'PRIMARY';
  @Input() color: string = 'DEFAULT';
  @Input() icon: boolean = false;
  @Input() iconType: IconType = IconType.star;
  @Input() iconDirection: string = 'LEFT';
  @Input() text: string = 'Bouton';
  @Input() bold: boolean = false;
  @Input() hideText: boolean = false;
  @Input() textCenter: boolean = false;
  @Input() reverseIcon: boolean = false;
  @Input() bigIcon: boolean = false;
  @Input() noUnderline: boolean = false;
  @Input() smallButton: boolean = false;
  @Input() disabled: boolean = false;
  @Input() fullWidth: boolean = false;
  @Input() iconColor: ColorType = ColorType.white;
  constructor() {
  }

  public ngOnInit(): void {
    if (this.type !== 'PRIMARY') {
      switch (this.color) {
        case 'PRIMARY':
          this.iconColor = ColorType.blue;
          break;
        case 'DANGER':
          this.iconColor = ColorType.red;
          break;
        case 'SUCCES':
          this.iconColor = ColorType.green;
          break;
        default:
          this.iconColor = ColorType.lightBlack;
      }
    }
  }
}
