import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {ModalController} from '@ionic/angular';
import {ArticleMonitoringService} from '../../services/article-monitoring/article-monitoring.service';
import {UserService} from '../../services/user/user.service';
import {ToastService} from '../../services/toast/toast.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from "../../services/snackbar/snackbar.service";
import {ArticleInterface} from "../../interfaces/articles/article.interface";
import {MonitoredArticleInterface} from "../../interfaces/monitored-articles/monitored-article.interface";
import {IconType} from "../parts/fe-icon/fe-icon.component";
import {
  MonitoredArticleMetadataInterface
} from "../../interfaces/monitored-articles-metadata/monitored-article-metadata.interface";

@Component({
  selector: 'app-monitoring-confirm-cloture-modal',
  templateUrl: './monitoring-confirm-cloture-modal.component.html',
  styleUrls: ['./monitoring-confirm-cloture-modal.component.scss'],
})
export class MonitoringConfirmClotureModalComponent implements OnInit {
  @Output() confirm = new EventEmitter<any>();
  protected unsubscribe$: Subject<any> = new Subject<any>();
  public statut: String;
  public libelle: String = 'Aucun statut sélectionné';
  protected monitoringMetadata;

  protected articleMonitoringId;
  protected article: ArticleInterface;
  protected monitoredArticle: MonitoredArticleInterface;

  constructor(
      public dialog: MatDialog,
      private articleMonitoringService: ArticleMonitoringService,
      public userService: UserService,
      private snackbarService: SnackbarService,
      public dialogRef: MatDialogRef<MonitoringConfirmClotureModalComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.article = data.article;
    this.monitoredArticle = data.monitoredArticle;
    this.articleMonitoringId = data.articleMonitoringId;
  }

  ngOnInit() {
    this.monitoringMetadata = {
      amount: this.monitoredArticle.monitoringMetadata.amount,
      contractStatus: this.monitoredArticle.monitoringMetadata.contractStatus,
      isUsualCustomer: this.monitoredArticle.monitoringMetadata.isUsualCustomer,
      closeDate: this.monitoredArticle.monitoringMetadata.closeDate
    };
  }

  protected troncateText(text: string, length: number): string {
    let troncateText: string = text;
    if (troncateText.length > length + 2) {
      troncateText = troncateText.substring(0, length) + '...';
    }
    return troncateText;
  }

  public close(fromValidateButton: Boolean): void {
    if (fromValidateButton) {
      if (this.statut !== null && this.statut !== undefined) {
        this.monitoringMetadata.contractStatus = this.statut;
        this.articleMonitoringService.addMetadataToMonitoringArticle(this.article.id, this.monitoringMetadata).subscribe(
            objet => {
              this.confirm.emit(this.statut);
              this.dialogRef.close();
            });
      } else {
        this.snackbarService.showDangerSnackbar('Opération impossible',
            'Vous devez sélectionner un statut de clôture.');
      }
    }
    else {
      this.dialogRef.close();
    }
  }

  public chooseStatut(value, libelle) {
    this.statut = value;
    this.libelle = libelle;
  }

  protected readonly IconType = IconType;
}
