<button class="fe-button" [ngClass]="{'fe-btn-primary' : type === 'PRIMARY',
'fe-btn-secondary' : type === 'SECONDARY', 'fe-btn-tertiary' : type === 'TERTIARY',
'fe-btn-default' : color === 'DEFAULT', 'fe-btn-blue' : color === 'PRIMARY', 'fe-btn-danger' : color === 'DANGER',
'fe-btn-success' : color === 'SUCCESS', 'fe-btn-grey' : color === 'GREY',
'fe-right-icon' :  iconDirection === 'RIGHT',
'fe-left-icon' :  iconDirection === 'LEFT'}" [class.smallButton]="smallButton" [class.big-icon]="bigIcon"
[disabled]="disabled" [class.onlyIcon]="hideText" [class.fullWidth]="fullWidth">
    <fe-icon [iconType]="iconType" *ngIf="icon" [class.reverse-icon]="reverseIcon" [color]="iconColor" size="18"/>
    <span [class.textCenter]="textCenter" *ngIf="!hideText" [class.bold]="bold"
          [class.no-underline]="noUnderline">{{text}}</span>
</button>
