<button mat-icon-button class="disabledWhite" *ngIf="!isFromActionList" [disabled]="selectionModel.selected.length === 0" (click)="markAsRead(selectionModel.selected)">
  <mat-icon>visibility</mat-icon>
</button>

<button mat-menu-item class="disabledWhite" *ngIf="isFromActionList" [class.fromActionList]="isFromActionList" [disabled]="selectionModel.selected.length === 0" (click)="markAsRead(selectionModel.selected)">
  <mat-icon>visibility</mat-icon>
  <span>Marquer comme lu</span>
</button>

<button mat-menu-item class="disabledWhite" *ngIf="isFromActionList" [class.fromActionList]="isFromActionList" [disabled]="selectionModel.selected.length === 0" (click)="markAsUnread(selectionModel.selected)">
  <mat-icon>visibility_off</mat-icon>
  <span>Marquer comme non lu</span>
</button>
