import {Component, ElementRef, HostBinding, HostListener, Inject, Renderer2, ViewChild} from '@angular/core';
import {EntrepriseInterface} from '../../../interfaces/entreprise/entreprise.interface';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContactInterface} from '../../../interfaces/contact/contact.interface';
import {UserService} from '../../../services/user/user.service';
import {EntreprisesService} from '../../../services/entreprises/entreprises.service';
import {EntrepriseContactInterface} from '../../../interfaces/entreprise-contact/entreprise-contact.interface';
import {EntrepriseArticleInterface} from '../../../interfaces/entreprise-article/entreprise-article.interface';
import {EntrepriseEtablissementInterface} from '../../../interfaces/entreprise-etablissement/entreprise-etablissement.interface';
import {EntrepriseLegalOfficierInterface} from '../../../interfaces/entreprise-legal-officier/entreprise-legal-officier.interface';
import {FEEntrepriseContactInterface} from '../../../interfaces/fe-entreprise-contact/fe-entreprise-contact.interface';
import {strings} from "@angular-devkit/core";
import {ModalService} from "../../../services/modal/modal.service";
import {FeFinancialsGraphModalComponent} from "./fe-financials-graph-modal/fe-financials-graph-modal.component";
import {EntrepriseFinancialInterface} from "../../../interfaces/entreprise-financial/entreprise-financial.interface";
import {MatTableDataSource} from "@angular/material/table";
import {SnackbarService} from '../../../services/snackbar/snackbar.service';
import {FeContactEnrichiModalComponent} from './fe-contact-enrichi-modal/fe-contact-enrichi-modal.component';
import {IconType} from '../../parts/fe-icon/fe-icon.component';
import {MatSort} from '@angular/material/sort';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import Icon = google.maps.Icon;
import {SessionInterface} from '../../../interfaces/session/session.interface';
import {Utils} from '../../../classes/Utils';
import {HttpErrorResponse} from '@angular/common/http';
import {ArticlesService} from '../../../services/articles/articles.service';
import {MailsService} from '../../../services/mails/mails.service';
import {FormControl} from "@angular/forms";
import {DOCUMENT} from '@angular/common';
import {FeAskContactsEnrichisModalComponent} from '../fe-ask-contacts-enrichis-modal/fe-ask-contacts-enrichis-modal.component';
import {MatAccordion} from "@angular/material/expansion";


@Component({
  selector: 'fe-fiche-entreprise-modal',
  templateUrl: './fe-fiche-entreprise-modal.component.html',
  styleUrls: ['./fe-fiche-entreprise-modal.component.scss']
})
export class FeFicheEntrepriseModalComponent {
  @HostBinding('class.selectedAnchor') protected visibleElementIndex: number = -1;
  @ViewChild('contactAccordion') protected contactAccordion: MatAccordion;
  @ViewChild('contactEnrichisAccordion') protected contactEnrichisAccordion: MatAccordion;
  protected entreprise: EntrepriseInterface;
  protected contacts: EntrepriseContactInterface[] = [];
  protected articleContacts: EntrepriseContactInterface[] = [];
  protected legal_officiers: EntrepriseLegalOfficierInterface[] = [];
  protected entrepriseArticles: EntrepriseArticleInterface[] = [];
  protected etablissements: EntrepriseEtablissementInterface[] = [];
  protected contactsEnrichis: FEEntrepriseContactInterface[] = [];
  protected financialsData: EntrepriseFinancialInterface[] = [];

  protected contactEnrichiColumn: string[] = ['role', 'name', 'linkedin', 'mail', 'pertinence', 'credit'];
  protected etabColumn: string[] = ['name', 'adresse', 'siret', 'APE'];
  protected contactColumn: string[] = ['nameAndFunctions', 'mail', 'phone', 'networks'];
  protected articleContactColumn: string[] = ['nameAndFunctions', 'mail', 'phone', 'networks'];
  protected mandataireColumn: string[] = ['role', 'name', 'startDate'];

  protected creditCount: number = 0;
  protected pageIndex: number = 0;
  protected pageSize: number = 10;
  protected creditUsed: boolean = false;

  protected hasAddress: boolean = false;
  protected contactEnrichiSearchBar: string = '';
  protected control = new FormControl('');
  protected searchBusinessSignalText: string = '';

  public companyId: string;
  public webToLead: boolean;
  public webToLeadCompanies: Array<String> = Utils.webToLeadCompanies;
  protected isLoading: boolean = true;

  @ViewChild('legalOfficierMatSort') legalOfficierMatSort: MatSort;
  @ViewChild('etablissementsMatSort') etablissementsMatSort: MatSort;
  @ViewChild('contactEnrichiMatSort') contactEnrichiMatSort: MatSort;
  @ViewChild('articleContactMatSort') articleContactMatSort: MatSort;
  @ViewChild('contactMatSort') contactMatSort: MatSort;

  protected legalOfficierDataSource: MatTableDataSource<EntrepriseLegalOfficierInterface>;
  protected etablissementsDataSource: MatTableDataSource<EntrepriseEtablissementInterface>;
  protected contactDataSource: MatTableDataSource<EntrepriseContactInterface> = new MatTableDataSource();
  protected contactsEnrichisDataSource: MatTableDataSource<FEEntrepriseContactInterface> = new MatTableDataSource();
  protected articleContactsDataSource: MatTableDataSource<EntrepriseContactInterface> = new MatTableDataSource();

  // private scrollOffsets: number[] = [];
  @ViewChild('prevSignauxpaginator') prevSignauxpaginator: MatPaginator;
  @ViewChild('etablissementsPaginator') etablissementsPaginator: MatPaginator;
  @ViewChild('legalOfficiersPaginator') legalOfficiersPaginator: MatPaginator;
  @ViewChild('previousContactPaginator') previousContactPaginator: MatPaginator;
  @ViewChild('contactsEnrichisPaginator') contactsEnrichisPaginator: MatPaginator;


  public markerIcon: Icon = {
    url: './assets/images/marker_fe.png',
    scaledSize: {
      width: 34,
      height: 60,
      equals(other: google.maps.Size | null): boolean {
        return false;
      }
    }
  };

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.snackbarService.showSuccessSnackbar('Scrolling !', '');
  }

  public geoloc: { draggable: boolean; icon: Icon; position: { lng: number; lat: number } };
  public mapCenter: {lat: number, lng: number};
  private articleId: any;

  constructor(public dialogRef: MatDialogRef<FeFicheEntrepriseModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              @Inject(DOCUMENT) private document: Document,
              private _modalService: ModalService,
              protected userService: UserService,
              private entreprisesService: EntreprisesService,
              private el: ElementRef,
              protected snackbarService: SnackbarService,
              private renderer: Renderer2
      , private articlesService: ArticlesService,
              private mailService: MailsService) {

    this.userService.getSessionDatas().pipe().subscribe(
        (sessionData: SessionInterface) => {
          if (sessionData !== undefined) {
            this.companyId = sessionData.customerDetails.companyId;
            sessionData.appConfig.viewConfigs.forEach(value => {
              if (value.component === 'WEBTOLEAD') {
                if (value.state === 'visible') {
                  this.webToLead = true;
                }
              }
            });
          }
        }
    );
    if (data != null) {
      this.entreprise = data.entreprise;
      this.articleId = data.articleId;
      if (this.entreprise.contacts != null) {
        this.articleContacts = this.entreprise.contacts;
        this.articleContacts.forEach(value => {
          value.nameAndFunctions = this.getEntrepriseContact(value);
        });
        this.articleContactsDataSource = new MatTableDataSource<EntrepriseContactInterface>(this.articleContacts);
        this.articleContactsDataSource.sort = this.articleContactMatSort;
      }

      if (this.entreprise.siren == null || this.entreprise.siren.trim().length == 0)
        this.entreprise.siren = 'NC';
      if (this.entreprise.siret == null || this.entreprise.siret.trim().length == 0)
        this.entreprise.siret = 'NC';
      if (this.entreprise.legalStatus == null || this.entreprise.legalStatus.trim().length == 0)
        this.entreprise.legalStatus = 'NC';
      if (this.entreprise.vatNumber == null || this.entreprise.vatNumber.trim().length == 0)
        this.entreprise.vatNumber = 'NC';
      if (this.entreprise.capital == null || this.entreprise.capital.trim().length == 0)
        this.entreprise.capital = 'NC';
      if (this.entreprise.dateCreation == null || this.entreprise.dateCreation.trim().length == 0)
        this.entreprise.dateCreation = 'NC';
      if (this.entreprise.closing_date == null)
        this.entreprise.closing_date = 'NC';
      if (this.entreprise.chiffreAffaire == null)
        this.entreprise.chiffreAffaire = 'NC';
      if (this.entreprise.resultatNet == null)
        this.entreprise.resultatNet = 'NC';
      if (this.entreprise.effectif == null)
        this.entreprise.effectif = 'NC';
      if (this.entreprise.telephone == null)
        this.entreprise.telephone = 'NC';
      if (this.entreprise.mail == null)
        this.entreprise.mail = 'NC';
      if (this.entreprise.lastUpdate == null)
        this.entreprise.lastUpdate = 'NC';

      this.hasAddress = (this.entreprise.adresse != null && this.entreprise.adresse.length > 0)
          || (this.entreprise.cp != null && this.entreprise.cp.length > 0)
          || (this.entreprise.ville != null && this.entreprise.ville.length > 0)
          || (this.entreprise.pays != null && this.entreprise.pays.length > 0);
    }

    this.searchBusinessSignalText = '';

    if (this.entreprise.entrepriseId) {
      // this.userService.log('CLICK_FICHE', [this.article], this.entreprise.entrepriseId);

      this.entreprisesService.getEntreprise(this.entreprise.entrepriseId + '').subscribe(
          entrepriseFromApi => {
            if (entrepriseFromApi.entrepriseData.etablissements != null) {
              this.etablissements = entrepriseFromApi.entrepriseData.etablissements;
              this.etablissementsDataSource = new MatTableDataSource(this.etablissements);
              this.etablissementsDataSource.paginator = this.etablissementsPaginator;
              this.etablissementsDataSource.sort = this.etablissementsMatSort;
            }

            if (entrepriseFromApi.articles != null) {
              this.entrepriseArticles = entrepriseFromApi.articles;
            }

            if (entrepriseFromApi.entrepriseData.legal_officiers != null) {
              this.legal_officiers = entrepriseFromApi.entrepriseData.legal_officiers;
              this.legalOfficierDataSource = new MatTableDataSource(this.legal_officiers);
              this.legalOfficierDataSource.paginator = this.legalOfficiersPaginator;
              this.legalOfficierDataSource.sort = this.legalOfficierMatSort;
            }

            if (entrepriseFromApi.entrepriseData.closing_date != null)
              this.entreprise.closing_date = entrepriseFromApi.entrepriseData.closing_date;

            this.financialsData = entrepriseFromApi.entrepriseData.financials;
            entrepriseFromApi.entrepriseData.financials?.forEach(value =>  {
              if (value.annualSalesNumber) {
                this.entreprise.chiffreAffaire = value.annualSales;
                this.entreprise.chiffreAffaireAnnee = value.year;
              }
              if (value.netProfilNumber) {
                this.entreprise.resultatNet = value.netProfit;
                this.entreprise.resultatNetAnnee = value.year;
              }
              if (value.effectif) {
                this.entreprise.effectif = value.effectif + '';
                this.entreprise.effectifAnnee = value.year;
              }
            });

            if (entrepriseFromApi.entrepriseData.contacts != null) {
              this.contacts = entrepriseFromApi.entrepriseData.contacts;
              this.contacts.forEach(value => {
                value.nameAndFunctions = this.getEntrepriseContact(value);
              })
              this.contactDataSource = new MatTableDataSource(this.contacts);
              this.contactDataSource.paginator = this.previousContactPaginator;
              this.contactDataSource.sort = this.contactMatSort;
            }
            // if (this.entreprise.financials && this.entreprise.financials.length > 0) {
            //   this.entreprise.chiffreAffaire = this.entreprise.financials[this.entreprise.financials.length - 1].annualSales;
            //   this.entreprise.resultatNet = this.entreprise.financials[this.entreprise.financials.length - 1].netProfit;
            // }
            if (entrepriseFromApi.articles != null)
              this.entrepriseArticles = entrepriseFromApi.articles;

            // const elements = this.el.nativeElement.querySelectorAll('.blocToScroll');
            // elements.forEach(element => {
            //   this.scrollOffsets.push(element.offsetTop);
            // });

            if (entrepriseFromApi.entrepriseData.latitude && entrepriseFromApi.entrepriseData.longitude) {
              this.geoloc = {
                  position: {
                    lat: entrepriseFromApi.entrepriseData.latitude,
                    lng: entrepriseFromApi.entrepriseData.longitude
                  },
                  draggable: false,
                  icon: this.markerIcon
              };
              this.mapCenter = {
                lat: entrepriseFromApi.entrepriseData.latitude,
                lng: entrepriseFromApi.entrepriseData.longitude
              };
            }

          });
    } else {
      this.articleContacts = [];
    }

  }

  protected openUrl(url: string): void {
    window.open(url, '_blank');
    this.logSocialNetworkClick();
  }

  public logSocialNetworkClick(): void {
    //  Log - CLICK_RS
    this.userService.log('CLICK_RS');
  }

  protected scrollToSection(sectionId: string): void {
    // const scrollPosition = (event.target as HTMLElement).scrollTop;
    // const targetBlock = this.el.nativeElement.querySelector(`#${sectionId}`);

    const element = this.document.getElementById(sectionId);
    if (element) {
      const elementBottom = element.offsetTop + element.offsetHeight;
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' });
      });
    }
    //
    // const element = this.el.nativeElement.querySelector(`#${sectionId}`);
    // if (element) {
    //   element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // }

    // if (targetBlock) {
    //   const targetBlockPosition = targetBlock.offsetTop;
    //
    //   // Vérifiez si la position de défilement est proche du début du bloc
    //   if (Math.abs(scrollPosition - targetBlockPosition) < 10) {
    //     // Envoyez une alerte lorsque vous atteignez le début du bloc
    //     alert('Vous avez atteint le début du bloc avec ID "votre-bloc-id"');
    //   }
    // }
  }

  // @HostListener('window:scroll', ['$event'])
  // onScroll(event: Event) {
  //   const scrollPosition: number = (event.target as HTMLElement).scrollTop;
  //   alert("!");
  //   this.visibleElementIndex = this.scrollOffsets.findIndex(offset => scrollPosition >= offset);
  // }
  getEntrepriseContact(contact: EntrepriseContactInterface): string {
    let contactToReturn: string = "";

    if (contact.firstName)
      contactToReturn = contact.firstName;

    if (contact.lastName) {
      if (contactToReturn.length > 0)
        contactToReturn += " ";
      contactToReturn += contact.lastName;
    }

    if (contact.function) {
      if (contactToReturn.length > 0)
        contactToReturn += " - ";
      contactToReturn += contact.function;
    }

    return contactToReturn;
  }

  openFinancialGraph() {
    this._modalService.showCustomModal(FeFinancialsGraphModalComponent, {
      data: {
        financials: this.financialsData,
        entreprise: this.entreprise
      }});
  }

  ngAfterViewInit() {
    this.entreprisesService.getEntrepriseAllContacts(this.entreprise.entrepriseId).subscribe(
        entrepriseFromApi => {
          this.creditCount = entrepriseFromApi.credits;
          this.contactsEnrichis = entrepriseFromApi.contacts;
          this.contactsEnrichisDataSource = new MatTableDataSource<FEEntrepriseContactInterface>(this.contactsEnrichis);
          this.contactsEnrichisDataSource.sort = this.contactEnrichiMatSort;
          this.contactsEnrichisDataSource.paginator = this.contactsEnrichisPaginator;
          this.isLoading = false;
        });

    this.legalOfficiersPaginator._intl.itemsPerPageLabel = '';

    if (this.articleContacts.length > 0) {
      this.contactAccordion.openAll();
    }

    this.userService.log('CLICK_FICHE', [this.entrepriseArticles], this.entreprise.entrepriseId);
  }

  private static removeAccent(rawText: string): string {
    let traitedText: string = rawText.toLowerCase();
    traitedText = traitedText.replace(new RegExp('[àáâãäå]', 'g'), 'a');
    traitedText = traitedText.replace(new RegExp('æ', 'g'), 'ae');
    traitedText = traitedText.replace(new RegExp('ç', 'g'), 'c');
    traitedText = traitedText.replace(new RegExp('[èéêë]', 'g'), 'e');
    traitedText = traitedText.replace(new RegExp('[ìíîï]', 'g'), 'i');
    traitedText = traitedText.replace(new RegExp('ñ', 'g'), 'n');
    traitedText = traitedText.replace(new RegExp('[òóôõö]', 'g'), 'o');
    traitedText = traitedText.replace(new RegExp('œ', 'g'), 'oe');
    traitedText = traitedText.replace(new RegExp('[ùúûü]', 'g'), 'u');
    traitedText = traitedText.replace(new RegExp('[ýÿ]', 'g'), 'y');
    return traitedText;
  }

  getNoContactEnrichiMsg(): string {
    return 'Nous n\'avons trouvé aucun contact pour cette entreprise.';
  }

  protected askForloadOneContactEnrichi(contactEnrichi: FEEntrepriseContactInterface): void {
    if (this.creditCount == 0) {
      this.snackbarService.showDangerSnackbar('Crédit insuffisant',
          'Vous n\'avez plus suffisamment de crédit pour consulter les contacts enrichis.<br>Contactez ' +
          'votre commercial dédié pour recharger votre solde.');
    }
    else {
      this._modalService.confirmModal('Voir un contact', 'Confirmez-vous l\'utilisation d\'un crédit pour voir ce contact ?<br /> Votre solde : ' + this.getCreditCount(), args => {
        this.loadOneContactEnrichi(contactEnrichi);
      });
    }
  }

  loadOneContactEnrichi(contactEnrichi: FEEntrepriseContactInterface): void {
    if (this.creditCount == 0) {
      this.snackbarService.showDangerSnackbar('Crédit insuffisant',
          'Vous n\'avez plus suffisamment de crédit pour consulter les contacts enrichis.<br>Contactez ' +
          'votre commercial dédié pour renouveler votre solde.');
    } else {
      this.entreprisesService.getFullDataForAContact(this.entreprise.entrepriseId, contactEnrichi.id).subscribe(
          value => {
            this.creditUsed = true;
            this.creditCount = value.credits;
            this.convertContact(contactEnrichi, value.contacts[0]);
            contactEnrichi.anonymized = false;
            this.contactsEnrichisDataSource.sort = this.contactEnrichiMatSort;
          });
    }
  }

  protected handlePageEvent($event: PageEvent) {
    this.pageSize = $event.pageSize;
    this.pageIndex = $event.pageIndex;
  }

  showContactFiche(contactEnrichi: FEEntrepriseContactInterface) {
    this._modalService.showCustomModal(FeContactEnrichiModalComponent, {
      data: {
        contact: contactEnrichi
      }});
  }

  openContactEnrichiLinkedin(contactEnrichi) {
    if (contactEnrichi.anonymized) {
      this.snackbarService.showDangerSnackbar('Action impossible', 'Vous devez consommer un crédit pour accéder au LinkedIn de ce contact.');
    } else {
      window.open(contactEnrichi.linkedin_url, '_blank');
    }
  }

  protected getEntrepriseEffectifLabel(): string {
    if (this.entreprise?.effectifAnnee != null) {
      return '(' + this.entreprise.effectifAnnee + ')';
    }
    return '';
  }

  protected getEntrepriseCALabel(): string {
    if (this.entreprise?.chiffreAffaireAnnee != null) {
      return '(' + this.entreprise.chiffreAffaireAnnee + ')';
    }
    return '';
  }

  protected getEntrepriseResultatNetLabel(): string {
    if (this.entreprise?.resultatNetAnnee != null) {
      return '(' + this.entreprise.resultatNetAnnee + ')';
    }
    return '';
  }

  protected readonly IconType = IconType;

  protected getEtablissementBlocTitle() {
    if (this.etablissements.length > 1) {
      return 'Établissements (' + this.etablissements.length + ')';
    }
    return 'Établissement (' + this.etablissements.length + ')';
  }

  protected getMandatairesBlocTitle() {
    if (this.legal_officiers.length > 1) {
      return 'Mandataires (' + this.legal_officiers.length + ')';
    }
    return 'Mandataire (' + this.legal_officiers.length + ')';
  }

  protected getContactsEnrichisBlocTitle() {
    if (this.contactsEnrichis.length > 1) {
      return 'Contacts enrichis (' + this.contactsEnrichis.length + ')';
    }
    return 'Contact enrichi (' + this.contactsEnrichis.length + ')';
  }

  protected getArticleContactsBlocTitle() {
    if (this.articleContacts.length > 1) {
      return 'Contacts de l’article (' + this.articleContacts.length + ')';
    }
    return 'Contact de l’article (' + this.articleContacts.length + ')';
  }

  protected getPreviousContactsBlocTitle() {
    if (this.contacts.length > 1) {
      return 'Contacts issus d’articles précédents (' + this.contacts.length + ')';
    }
    return 'Contact issu d’articles précédents (' + this.contacts.length + ')';
  }

  protected getPreviousArticlesBlocTitle() {
    if (this.entrepriseArticles.length > 1) {
      return 'Signaux d’affaires précédents (' + this.entrepriseArticles.length + ')';
    }
    return 'Signal d’affaires précédent (' + this.entrepriseArticles.length + ')';
  }

  protected doesCCHasData() {
    let textToReturn: string = '';
    if (this.entreprise.closing_date == null && this.entreprise.effectif == null &&
        this.entreprise.chiffreAffaire == null && this.entreprise.resultatNet == null) {
     textToReturn = ' (Aucune donnée disponible)';
    }
    return textToReturn;
  }

  protected askForContact(): void {
    this._modalService.showCustomModal(FeAskContactsEnrichisModalComponent, {data: { salesPersonDetails: this.userService.sessionData.salesPersonDetails}});
    // this._modalService.confirmModal('Demande d’informations sur contacts enrichis',
    //     '<a href="https://www.firsteco.fr/revue/plaquette_contacts_enrichis.pdf" target="_blank">\n' +
    //     '<img src="https://www.firsteco.fr/revue/images/contacts_enrichis_efficience.jpg" />\n' +
    //     '</a><br>Une demande de contact va être envoyée à votre commercial dédié.<br>' + 'Voulez-vous continuer ?',
    //         args => {
    //   this.mailService.contactSalespersonn('contacts_enrichis').subscribe(value => {
    //     this.snackbarService.showSuccessSnackbar('Demande de contact envoyée',
    //       'Votre commercial dédié vous recontactera sous 48h.');
    //   });
    // });
  }

  get startIndex(): number {
    return this.pageIndex * this.pageSize;
    // return 0;
  }

  get endIndex(): number {
    return (this.pageIndex + 1) * this.pageSize;
    // return 5;
  }

  protected getCreditCount(): string {
    let creditCountLabel: string = '';
    if (this.userService.sessionData.productOfferCode === 'OFFRE_3') {
      if (this.creditCount < 0) {
        creditCountLabel = 'Crédits illimités'
      } else if (this.creditCount < 2) {
        creditCountLabel = this.creditCount + ' crédit restant';
      } else {
        creditCountLabel = this.creditCount + ' crédits restants';
      }
    }
    return creditCountLabel;
  }

  protected getApeText(): string {
    let textToReturn = '';

    if (this.entreprise.ape != null && this.entreprise.ape.trim().length > 0)
      textToReturn = this.entreprise.ape;
    if (this.entreprise.ape_label != null && this.entreprise.ape_label.trim().length > 0) {
      if (textToReturn.length > 0)
        textToReturn += ' - ';
      textToReturn += this.entreprise.ape_label;
    }

    if (textToReturn.length == 0)
      textToReturn = 'NC';

    return textToReturn;
  }

  public close(): void {
    if (this.creditUsed) {
      let title: string = 'Attention';
      let message: string = 'Si vous fermer cette fenêtre, les informations seront perdues et vous devrez ' +
          'réutiliser des\n crédits pour consulter à nouveau les informations détaillées des contacts.';
      if (this.creditCount < 0) {
        message = 'Si vous fermer cette fenêtre, vous allez perdre les contacts visualisés.';
      }

      this._modalService.confirmModal(title, message, args => {
        this.dialogRef.close();
      });
    } else {
      this.dialogRef.close();
    }
  }

  private convertContact(contactSource: FEEntrepriseContactInterface, contactAPI: FEEntrepriseContactInterface) {
    const contactConverted: FEEntrepriseContactInterface = contactSource;
    contactConverted.firstName = contactAPI.firstName;
    contactConverted.lastName = contactAPI.lastName;
    contactConverted.civility = contactAPI.civility;
    contactConverted.domain_code = contactAPI.domain_code;
    contactConverted.domain_label = contactAPI.domain_label;
    contactConverted.level_code = contactAPI.level_code;
    contactConverted.email = contactAPI.email;
    contactConverted.email_score = contactAPI.email_score;
    contactConverted.linkedin_url = contactAPI.linkedin_url;
    contactConverted.extraction_date = contactAPI.extraction_date;
    contactConverted.anonymized = contactAPI.anonymized;
    contactConverted.email_test_date = contactAPI.email_test_date;

    return contactConverted;
  }

  protected applyContactEnrichisFilter(event: string): void {
    this.contactsEnrichisDataSource.filter = event.trim().toLowerCase();

    if (this.contactsEnrichisPaginator != null) {
      this.contactsEnrichisPaginator.firstPage();
    }
  }

  protected applyEtablissemntsFilter(event: string): void {
    this.etablissementsDataSource.filter = event.trim().toLowerCase();

    if (this.etablissementsPaginator != null) {
      this.etablissementsPaginator.firstPage();
    }
  }

  protected applyMandatairesFilter(event: string): void {
    this.legalOfficierDataSource.filter = event.trim().toLowerCase();

    if (this.legalOfficiersPaginator != null) {
      this.legalOfficiersPaginator.firstPage();
    }
  }

  protected applyPreviousContactsFilter(event: string): void {
    this.contactDataSource.filter = event.trim().toLowerCase();

    if (this.previousContactPaginator != null) {
      this.previousContactPaginator.firstPage();
    }
  }

  protected applyBusinessSignalsFilter(value: string): void {
    this.pageIndex = 0;
    this.searchBusinessSignalText = value;
  }

  get getFilteredBusinessSignals(): EntrepriseArticleInterface[] {
    return this.entrepriseArticles.filter(item => item.title.toLowerCase()
        .includes(this.searchBusinessSignalText.toLowerCase()));
  }

  public logWebsiteClick(): void {
    //  Log - CLICK_WEBSITE
    this.userService.log('CLICK_WEBSITE');
  }

  exportEnterpriseWebToLead() {
    /* TODO adapter code pour créer le contact selon 2 cas, - Cas classique avec les contacts complets
    * TODO - Cas de contact créés depuis ancien article, traitement à faire pour séparer fonction et nom
    * */
    const self = this;
    const contactWTL = new class {
      entrepriseId: number = self.entreprise.entrepriseId;

    };
    this.articlesService.exportArticleWebToLead(this.articleId, contactWTL)
        .pipe().subscribe(
        () => {
          this.snackbarService.showSuccessSnackbar('WebtoLead exporté avec succès', 'WebtoLead exporté avec succès');
        },
        (her: HttpErrorResponse) => {
          let errorStr: string;
          if (typeof her !== 'undefined' && typeof her.error.message !== 'undefined' && her.error.message !== null && her.error.message !== '') {
            errorStr = her.error.message;
          } else {

          }
          this.snackbarService.showDangerSnackbar('Erreur lors de l\'export WebToLead', errorStr);
        }
    );
  }

  exportContactWebToLead(contact: EntrepriseContactInterface, $event: MouseEvent) {
    $event.stopPropagation();

    /* TODO adapter code pour créer le contact selon 2 cas, - Cas classique avec les contacts complets
    * TODO - Cas de contact créés depuis ancien article, traitement à faire pour séparer fonction et nom
    * */
    const self = this;
    const contactWTL: ContactInterface = new class implements ContactInterface {
      contactName: string = contact.name;
      email: string = contact.mail;
      organisationName: string = self.entreprise.name;
      siren: string = self.entreprise.siren;
      socialUrl: string = contact.linkedin;
      tel: string = contact.phone;
      website: string = self.entreprise.website;
      firstName: string = contact.firstName;
      lastName: string = contact.lastName;
      contactId: number = contact.contactId;
      entrepriseId: number = self.entreprise.entrepriseId;
    };
    this.articlesService.exportArticleWebToLead(this.articleId, contactWTL)
        .pipe().subscribe(
        () => {
          this.snackbarService.showSuccessSnackbar('WebtoLead exporté avec succès', 'WebtoLead exporté avec succès');
        },
        (her: HttpErrorResponse) => {
          let errorStr: string;
          if (typeof her !== 'undefined' && typeof her.error.message !== 'undefined' && her.error.message !== null && her.error.message !== '') {
            errorStr = her.error.message;
          } else {

          }
          this.snackbarService.showDangerSnackbar('Erreur lors de l\'export WebToLead', errorStr);
        }
    );
  }


  clearSBSignauxBusiness() {
    this.control.setValue('');
  }

  protected onFicheScroll($event: Event): void {
    const container: HTMLElement = ($event.target as HTMLElement);
    // const infosJuridiquesBloc: HTMLElement = this.document.getElementById('infosJuridiquesBloc');
    const contactsBloc: HTMLElement = this.document.getElementById('contactsBloc');
    const signauxBloc: HTMLElement = this.document.getElementById('signauxBloc');
    const etablissementsBloc: HTMLElement = this.document.getElementById('etablissementsBloc');
    const scrollPosition: number = container.scrollTop + container.offsetHeight + 10;

    // this.snackbarService.showSnackbar('Scrolling', 'Scroll Actuel : ' + (container.scrollTop + container.offsetHeight) + '<br>'
    //     + 'Infos juridiques : ' + infosJuridiquesBloc.offsetTop + '<br>'
    //     + 'Contact enrichi : ' + contactsBloc.offsetTop + '<br>'
    //     + 'Signaux d\'affaires : ' + signauxBloc.offsetTop + '<br>'
    //     + 'Établiseements : ' + etablissementsBloc.offsetTop + '<br>');

    if (scrollPosition > etablissementsBloc.offsetTop) {
      this.visibleElementIndex = 3;
    } else if (scrollPosition > signauxBloc.offsetTop) {
      this.visibleElementIndex = 2;
    } else if (scrollPosition > contactsBloc.offsetTop) {
      this.visibleElementIndex = 1;
    } else {
      this.visibleElementIndex = 0;
    }

    // const targetElement = event.target as HTMLElement;

    // // Check if the clicked element is an anchor tag
    // if (targetElement.tagName.toLowerCase() === 'a') {
    //   this.openReferenceArticleModal(Number(targetElement.id));
    // }
  }
}
