<ion-header no-border>
  <ion-toolbar>
    <mat-label slot="start" style="margin-left: 10px;font-size: 16px;">Créer un nouveau dossier de favoris</mat-label>
    <ion-buttons slot="end">
      <button mat-icon-button aria-label="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div style="display: flex; flex-direction: column;">
    <div style="flex: 1;display: flex; flex-direction: row; margin: 10px;">
      <mat-form-field class="removePadding removeback" appearance="fill" style="margin-left :auto; margin-right: auto">
        <input #createdFolder matInput placeholder="Nom du dossier" style="text-align: center; margin-bottom: 10px;margin-right: auto;margin-left: auto">
      </mat-form-field>
    </div>
  </div>
  <div class="button-container">
    <button mat-button
            (click)="addFavorite()" style="margin-top: 15px">Confirmer</button>
  </div>
</ion-content>
