<ion-header no-border>
  <ion-toolbar>
    <ion-buttons slot="end">
      <button mat-icon-button aria-label="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  
  <ion-title>Réinitialisation de mot de passe</ion-title>
  
  <div>
    <input style="margin: auto; display: block; margin-top: 25px; width: 80%;" #email placeholder="E-mail">
    
    <button
            class="mat-rounded-button"
            mat-raised-button color="accent"
            (click)="send()">
      <mat-icon>check_circle_outline</mat-icon>
      Envoyer
    </button>
  
  </div>

</ion-content>
