<div class="fe-article-item" [class.articleRead]="article.read">
    <div class="optionsLine">
        <span class="fe-article-date" [ngClass]="{'articlePreview' : style === 'preview'}">{{getDate()}}</span>
        <div class="infosContainer">
            <div *ngIf="isPerformance && !isSupervisor && article.monitoring.status !== undefined">
                <div class="infosItem">
                    <span>Suivi</span>
                    <mat-icon>dashboard</mat-icon>
                </div>
            </div>
            <div *ngIf="isPerformance && isSupervisor && article.monitoring.status !== undefined || listAssociatedCustomers.length > 0" matTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="{{shareArticleToolTip}}">
                <div class="infosItem">
                    <span *ngIf="article.monitoring.status !== undefined && listAssociatedCustomers.length === 0">Suivi par moi</span>
                    <span *ngIf="article.monitoring.status === undefined && listAssociatedCustomers.length > 0">Suivi par d'autres</span>
                    <span *ngIf="article.monitoring.status !== undefined && listAssociatedCustomers.length > 0">Suivi par moi et d'autres</span>
                    <mat-icon>dashboard</mat-icon>
                </div>
            </div>
            <div class="infosItem" *ngIf="article.favorite">
                <span>Favoris</span>
                <mat-icon>star</mat-icon>
<!--                <mat-icon class="material-icons-outlined">dashboard</mat-icon>-->
<!--                <mat-icon class="material-symbols-outlined">dashboard_outline</mat-icon>-->
<!--                <span class="material-symbols-outlined">dashboard</span>-->
            </div>
            <div class="infosItem" *ngIf="article.sharedTo !== undefined" matTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="Article déjà envoyé à :&#013;{{article.sharedTo.join('&#013;')}}">
                <span>Envoyé</span>
                <mat-icon>mark_email_read</mat-icon>
            </div>
            <div class="infosItem" *ngIf="article.data && article.data.comment && article.data.comment?.trim() != ''">
                <span>Commentaire</span>
                <mat-icon>comment</mat-icon>
            </div>
            <div class="infosItem" *ngIf="article.read">
                <span>Lu</span>
                <mat-icon>check</mat-icon>
            </div>
            <button style="margin-left: 24px;" mat-icon-button class="feDotsMenu"
                    [matMenuTriggerFor]="actionsOnArticleMenu">
                <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #actionsOnArticleMenu="matMenu" class="actionsOnArticleMenu" xPosition="before">
                <button *ngIf="isPerformance" (click)="addToSuiviAffaires()" mat-menu-item>
                    <fe-icon [iconType]="IconType.dashboard_add"/>
                    <span class="blueBoldText">Ajouter au suivi d’affaires</span>
                </button>
                <button *ngIf="!article.favorite" (click)="addArticleToFavorite()" mat-menu-item>
                    <fe-icon [iconType]="IconType.star"/>
                    <span class="blueBoldText">Ajouter en favoris</span>
                </button>
                <button *ngIf="article.favorite" mat-menu-item [matMenuTriggerFor]="gestionFavoris">
                    <fe-icon [iconType]="IconType.star_full"/>
                    <span class="blueBoldText">Gestion des favoris</span>
                </button>
                <button mat-menu-item [matMenuTriggerFor]="envoiRapideMenu">
                    <fe-icon [iconType]="IconType.send_fast_mail"/>
                    <span class="blueBoldText">Partage rapide par email</span>
                </button>
                <button (click)="showSendArticleModal()" mat-menu-item>
                    <fe-icon [iconType]="IconType.send_mail"/>
                    <span class="blueBoldText">Envoyer par email</span>
                </button>
                <button *ngIf="!article.read" (click)="markArticleRead(true)" mat-menu-item>
                    <fe-icon [iconType]="IconType.eye_close"/>
                    <span class="blueBoldText">Marquer comme lu</span>
                </button>
                <button *ngIf="article.read" (click)="markArticleRead(false)" mat-menu-item>
                    <fe-icon [iconType]="IconType.eye_open"/>
                    <span class="blueBoldText">Marquer comme non-lu</span>
                </button>
                <button (click)="printArticles()" mat-menu-item>
                    <fe-icon [iconType]="IconType.print"/>
                    <span class="blueBoldText">Imprimer</span>
                </button>
            </mat-menu>

            <mat-menu #gestionFavoris="matMenu" class="matMenuLimitHeight" direction="down"
                      yPosition="below" [hasBackdrop]="false">
                <ng-container *ngFor="let favorite of myFavoritesList">
                    <button *ngIf="article.favoriteFolder === favorite.folderID" mat-menu-item style="background-color: #e3e4e8">
                        <i>
                            Actuellement dans "{{favorite.libelle}}"
                        </i>
                    </button>
                </ng-container>
                <fe-menu-item text="Retirer des favoris" color="DANGER" [icon]="IconType.delete"
                              (click)="deleteFavorite([article.id])"/>
                    <ng-container *ngFor="let favorite of myFavoritesList">
                        <fe-menu-item *ngIf="article.favoriteFolder !== favorite.folderID" [bold]="false"
                                      [text]="'Déplacer vers ' + favorite.libelle" color="PRIMARY"
                                      [icon]="IconType.move_folder" (click)="changeFavoriteFolder(favorite, article.id)"/>
                    </ng-container>
            </mat-menu>
            <mat-menu #envoiRapideMenu="matMenu" class="matMenuLimitHeight" direction="down"
                      yPosition="below" [hasBackdrop]="false">
                <fe-menu-item [bold]="true" text="Carnet d'adresses" color="PRIMARY"
                              (click)="openCarnetAddress()"/>
                <fe-menu-item *ngFor="let contactLine of contactsLines" [bold]="false"
                              [text]="'Partager à ' + getContactLibelle(contactLine)" color="PRIMARY"
                              (click)="sendFastMail(contactLine, article.id)"/>
            </mat-menu>
        </div>
    </div>
    <div class="articleBody">
        <div class="left-part" (click)="openArticleModal(article)" >
            <div class="articleHeaderContainer">
                <mat-checkbox *ngIf="style !== 'preview'" [checked]="selectionModel.isSelected(article.id)"
                              (change)="selectionModel.toggle(article.id)" (click)="$event.stopPropagation();"/>
                <span [ngClass]="{'articlePreview' : style === 'preview'}" class="articleTitle" [innerHTML]="sanitizer.bypassSecurityTrustHtml(getHighlightedText(article.title))"></span>
            </div>
            <div class="tagsContainer" [ngClass]="{'articlePreview' : style === 'preview'}" *ngIf="style !== 'small'">
                <ng-container *ngFor="let tag of getArticleTags()">
                    <fe-tag *ngIf="tagShowing(tag)" [tag]="tag" [keywords]="_keywords"></fe-tag>
                </ng-container>
            </div>
            <div class="leadsContainer" [ngClass]="{'articlePreview' : style === 'preview'}" *ngIf="style !== 'small' && article.predictions != null && article.predictions.length > 0">
<!--                <span style="margin-right: 4px;">Leads Prédictifs</span>-->
                <fe-icon [iconType]="IconType.leads" matTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="Leads prédictifs" style="margin: 3px 0"/>
                <ng-container *ngIf="!hasLeadsInSearch">
                    <ng-container *ngFor="let tag of article.predictions | slice: 0:3">
                        <fe-tag leadMode="true" *ngIf="tagShowing(tag)" [tag]="tag" [keywords]="_keywords"></fe-tag>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="hasLeadsInSearch">
                    <ng-container *ngFor="let tag of article.predictions">
                        <fe-tag leadMode="true" *ngIf="tagShowing(tag) && tag.matchSearch" [tag]="tag" [keywords]="_keywords"></fe-tag>
                    </ng-container>
                </ng-container>
            </div>
            <div id="articleCorps" [ngClass]="{'articlePreview' : style === 'preview'}"class="textContainer" *ngIf="style !== 'small'">
                <p (click)="handleAnchorClick($event)" [innerHTML]="getArticleCorps()"></p>
            </div>
            <div class="sourcesContainer" *ngIf="style !== 'small' && article.precisions">{{article.precisions}}</div>
            <article-sources style="margin-left: 26px;" *ngIf="style === 'all'" [article]="article"  class="littleText sourcesContainer"></article-sources>
        </div>
        <div class="fe-vert-divider" *ngIf="style !== 'small' && style !== 'preview'"></div>
        <div class="right-part" *ngIf="style !== 'small' && style !== 'preview'">
            <div class="entreprisesTitle">
                <fe-icon [iconType]="IconType.entreprise" [color]="ColorType.lightBlack"/>
                <span *ngIf="article.entreprises.length > 1">Entreprises concernées :</span>
                <span *ngIf="article.entreprises.length === 1">Entreprise concernée :</span>
            </div>
            <div class="entreprisesContainer">
                <fe-entreprise-item [entreprise]="entreprise" [article]="article" *ngFor="let entreprise of article.entreprises
                | slice:0:this.style === 'all' ? article.entreprises.length: 2"/>
                <fe-button *ngIf="article.entreprises.length > 2 && this.style === 'big'" hideText="true"
                           [iconType]="IconType.three_dots_hori" icon="true" type="TERTIARY"
                           (click)="openArticleModal(article)"/>
            </div>
        </div>
    </div>
</div>
