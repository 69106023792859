<button mat-icon-button class="dots-button" [matMenuTriggerFor]="dotsMenu">
    <mat-icon>more_vert</mat-icon>
</button>
<mat-menu class="dotsMatMenu" xPosition="{{xPosition}}" #dotsMenu="matMenu">
<!--    <button mat-menu-item>-->
<!--        <mat-icon>filter_alt</mat-icon>-->
<!--        <span >Voir les critères</span>-->
<!--    </button>-->
<!--    <button mat-menu-item>-->
<!--        <mat-icon>notifications</mat-icon>-->
<!--        <span >Activer les alertes par email</span>-->
<!--    </button>-->
<!--    <button mat-menu-item>-->
<!--        <mat-icon>edit</mat-icon>-->
<!--        <span >Renommer</span>-->
<!--    </button>-->
<!--    <button mat-menu-item>-->
<!--        <mat-icon>content_copy</mat-icon>-->
<!--        <span >Dupliquer</span>-->
<!--    </button>-->
<!--    <button>-->
<!--        <mat-icon style="color: #BF0214">delete</mat-icon>-->
<!--        <span style="color: #BF0214">Supprimer</span>-->
<!--    </button>-->
<!--    <fe-menu-item></fe-menu-item>-->
<!--    <fe-sub-menu></fe-sub-menu>-->
    <ng-content></ng-content>
</mat-menu>
