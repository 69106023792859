import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HelpService } from 'src/app/services/help/help.service';
import { HelpInterface } from 'src/app/interfaces/help/help.interface';

@Component({
  selector: 'help',
  templateUrl: 'help.component.html',
  styleUrls: ['help.component.scss'],
})
export class HelpComponent implements OnInit, OnDestroy {

  protected unsubscribe$: Subject<any> = new Subject<any>();
  public help$: Observable<HelpInterface>;

  @Input() public helpData: HelpInterface;

  constructor(
    private helpService: HelpService
  ) {}

  public ngOnInit(): void {
    this.getHelp();
  }

  public getHelp(): void {
    // this.help$ = this.helpService.getHelp();

    // this.help$.pipe(
    //   takeUntil(this.unsubscribe$)
    // )
    // .subscribe(
    //   (help: HelpInterface) => {
    //     this.helpData = help;
    //   }
    // );
  }

  public ngOnDestroy(): void {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
