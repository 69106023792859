import { Directive, Input, ElementRef, Renderer2, OnInit, OnDestroy } from '@angular/core';
import { DomController, IonContent } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MediaQueryService } from 'src/app/services/media-query/media-query.service';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[hideTollbarOnScroll]'
})
export class HideTollbarOnScrollDirective implements OnInit, OnDestroy {

  private unsubscribe$: Subject<any> = new Subject<any>();

  @Input('hideTollbarOnScroll') public scrollArea: IonContent;

  private hidden: boolean = false;

  private height: number;
  private minHeight: number;
  private maxHeight: number;

  private ascending: boolean = false;
  private descending: boolean = true;
  private refPosition: number = 0;
  private previousDelta: number = 0;

  constructor(
    private elementRef: ElementRef
    , private renderer2: Renderer2
    , private domController: DomController
    , private mediaQueryService: MediaQueryService
  ) { }

  ngOnInit() {

    // not in desktop mode
    if (!this.mediaQueryService.mobileQuery.matches) {
      return;
    }

    this.initStyles();

    this.scrollArea.ionScroll.pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe((scrollEvent: CustomEvent<any>) => {
      const delta: any = scrollEvent.detail.deltaY;

      if (this.height == null) {
        this.minHeight = - this.elementRef.nativeElement.offsetHeight;
        this.height = 0;
        this.maxHeight = 0;
      }

      if (delta === 0) {
        this.previousDelta = 0;
      }

      if (delta > this.previousDelta) {
        if (this.ascending) {
          this.descending = true;
          this.ascending = false;
          this.refPosition = scrollEvent.detail.currentY;
        }
        this.hide(scrollEvent.detail.currentY);
      } else if (delta < this.previousDelta) {
        if (this.descending) {
          this.ascending = true;
          this.descending = false;
          this.refPosition = scrollEvent.detail.currentY;
        }
        this.show(scrollEvent.detail.currentY);
      }
      this.previousDelta = delta;
    });
  }

  initStyles() {
    this.domController.write(() => {
      this.renderer2.setStyle(
        this.elementRef.nativeElement,
        'transition',
      'all 0.05s'
      );
      this.renderer2.setStyle(this.elementRef.nativeElement, 'height', this.height);
      this.renderer2.setStyle(this.elementRef.nativeElement, 'position', 'absolute');
    });
  }

  hide(delta) {
    if (delta < (-this.minHeight)) {
      this.height = this.maxHeight;
    } else if ((this.height + (this.refPosition - delta)) > this.minHeight) {
      this.height = this.height + (this.refPosition - delta);
    } else {
      this.height = this.minHeight;
    }
    this.domController.write(() => {
      this.renderer2.setStyle(this.elementRef.nativeElement, 'transition', 'all 0.05s');
      this.renderer2.setStyle(this.elementRef.nativeElement, 'margin-top', this.height + 'px');
    });

    this.hidden = true;
  }

  show(delta) {
    if (delta < - this.minHeight) {
      this.height = this.maxHeight;
    } else if ((this.height - (delta - this.refPosition)) < this.maxHeight) {
      this.height = this.height - (delta - this.refPosition);
    } else {
      this.height = this.maxHeight;
    }
    this.domController.write(() => {
      this.renderer2.setStyle(this.elementRef.nativeElement, 'transition', 'all 0.2s');
      this.renderer2.setStyle(this.elementRef.nativeElement, 'margin-top', this.height + 'px');
    });

    this.hidden = false;
  }

  public ngOnDestroy(): void {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
