<div class="feRechercheLine">
    <div class="centerContainer">
        <div class="searchTitleContainer">
            <span (click)="loadSearch()" class="searchTitle">{{search.data.label}}</span>
            <div class="firstResearch" *ngIf="mainSearch">
                <fe-icon size="16" [iconType]="IconType.crown" [color]="ColorType.darkGrey"/>
                <span class="firstResearchText">Recherche principale</span>
            </div>
        </div>

        <div class="infosLine">
            <div class="infoItem" *ngIf="search.readOnly">
                <mat-icon class="infoIcon">lock</mat-icon>
                <span class="infoText">Lecture seule</span>
            </div>
            <div class="infoItem" *ngIf="search.data.alertState">
                <mat-icon class="infoIcon">notifications</mat-icon>
                <span class="infoText">Alerte active</span>
            </div>
            <div class="infoNewResults" *ngIf="search.dataRecentResults.nbResults > 0 && !search.dataRecentResults.isView">
                <span *ngIf="search.dataRecentResults.nbResults > 1" class="newSearchResult">
                    <span style="font-weight: bold;">{{search.dataRecentResults.nbResults}}</span>
                    nouveaux résultats depuis la dernière connexion
                </span>
                <span *ngIf="search.dataRecentResults.nbResults == 1" class="newSearchResult">
                    <span style="font-weight: bold;">{{search.dataRecentResults.nbResults}}</span>
                    nouveau résultat depuis la dernière connexion
                </span>
                <div class="searchItemRedNotif"></div>
            </div>

        </div>
    </div>
    <manage-searches [fromMenu]="true" [searchList]="searchList" [currentIndex]="searchList.indexOf(search)"
                     [search]="search" (selectSearchEmitter)="loadSearch()"/>
<!--    <div class="dotsContainer">-->
<!--        <fe-dots-menu style="margin: auto 4px auto 12px">-->
<!--            <fe-menu-item text="Définir en tant que recherche principale" color="PRIMARY"></fe-menu-item>-->
<!--            <fe-menu-item text="Voir les résultats" color="PRIMARY"></fe-menu-item>-->
<!--            <fe-menu-item text="Voir les critères" color="PRIMARY"></fe-menu-item>-->
<!--            <fe-menu-item text="Activer les alertes par email" color="PRIMARY"></fe-menu-item>-->
<!--            <fe-menu-item text="Renommer" color="PRIMARY"></fe-menu-item>-->
<!--            <fe-menu-item text="Dupliquer" color="PRIMARY"></fe-menu-item>-->
<!--            <fe-menu-item text="Supprimer" color="DANGER"></fe-menu-item>-->
<!--        </fe-dots-menu>-->
<!--    </div>-->
</div>
