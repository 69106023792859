<div></div>
<!--<div>-->
<!--  <ng-container>-->
<!--    <div style="display: flex; justify-content: space-between;align-items: center;">-->
<!--      <span style="font-size: 16px;font-weight: bold;">{{panelTitle}}</span>-->
<!--      <button  mat-icon-button  (click)="close()" style="width: 20%">-->
<!--        <span style="color: #273c4e;font-size: 16px;">Fermer</span>-->
<!--        <mat-icon>close</mat-icon>-->
<!--      </button>-->
<!--    </div>-->
<!--    &lt;!&ndash;Geoloc filter&ndash;&gt;-->
<!--    <fieldset *ngIf="geolocAvailable && criterionKey == 'locations' && stype === 'business_signal'" style="border:2px solid #C20015;margin:10px;">-->
<!--      <legend>Géolocalisation</legend>-->
<!--      <form [formGroup]='cityForm'>-->
<!--        <div style="display: flex;">-->
<!--          <input [matAutocomplete]="auto" (change)="cityChange($event)" formControlName='cityInput' [(ngModel)]="cityFilter" type="text" class="rounded-searchbar" [placeholder]="'Où'" matInput (click)="geoFilterClick()">-->
<!--        </div>-->
<!--        &lt;!&ndash;        <p>{{cityFilter}}</p>&ndash;&gt;-->
<!--        <mat-autocomplete #auto="matAutocomplete" >-->
<!--          <mat-option *ngIf="isLoading" class="is-loading"><mat-spinner diameter="50"></mat-spinner></mat-option>-->
<!--          <ng-container *ngIf="!isLoading">-->
<!--            <mat-option *ngFor="let option of cityOptions" [value]="getCityTextAndSetId(option)" (click)="setCityId(option)">-->
<!--              {{ option.name }} {{option.id > 0 ? (option.zip) : ''}}-->
<!--            </mat-option>-->
<!--          </ng-container>-->
<!--        </mat-autocomplete>-->
<!--      </form>-->
<!--      <mat-label>Dans un rayon</mat-label>-->
<!--      <mat-select [(ngModel)]="selectedDistance">-->
<!--        <mat-option value="10"> 10Km </mat-option>-->
<!--        <mat-option value="20"> 20Km </mat-option>-->
<!--        <mat-option value="50"> 50Km </mat-option>-->
<!--        <mat-option value="100"> 100Km </mat-option>-->
<!--        <mat-option value="200"> 200Km </mat-option>-->
<!--      </mat-select>-->
<!--    </fieldset>-->

<!--    <ion-searchbar matInput placeholder="Rechercher dans la liste" (input)="filterCriterions($event.target.value)"></ion-searchbar>-->

<!--    <mat-checkbox (change)="selectDeselectAll()" [checked]="allChecked()" class="checkAll">-->
<!--      Sélectionner / Déselectionner tout-->
<!--    </mat-checkbox>-->
<!--    &lt;!&ndash;    <div style="position: absolute; width: 100vw; height: 100vh; top: 0; left: 0;">&ndash;&gt;-->
<!--    <mat-accordion multi="true" style="display:flex; flex-direction: column;"-->
<!--                   [ngClass]="{'padBotMobile': mediaQueryService.mobileQuery.matches}">-->
<!--      <ng-container *ngFor="let criterion of childrenCriterions;trackBy: trackByFn">-->
<!--        <mat-expansion-panel [expanded]="toExpand.has(criterion)" [disabled]="criterion.childCriterions?.length > 0 ? false : true" [ngClass]="{'highlightItem' : ((filterCriterion !== undefined && filterCriterion !== '') && (filterChildren(criterion, 1)))}" hideToggle="false">-->
<!--          <mat-expansion-panel-header class="expansionHeader">-->
<!--            <mat-panel-title>-->
<!--              <mat-checkbox-->
<!--                      [checked]="isChecked(criterion) || criterion.checked_"-->
<!--                      [indeterminate]="descendantsPartiallySelected(criterion, 0)"-->
<!--                      (click)="updateCheckedCriterionsList(from,criterion, !criterion.checked_)"-->
<!--                      (change)="onCheckParent(criterion, $event);">-->
<!--                {{criterion.label}}-->
<!--              </mat-checkbox>-->
<!--            </mat-panel-title>-->
<!--          </mat-expansion-panel-header>-->
<!--          <ng-template matExpansionPanelContent>-->
<!--            <my-searches-add-edit-children [expanded]="toExpand" [filterCriterion]="filterCriterion" (emitChildrenCriterion)="updateCheckedCriterionsList(from,$event.criterion, $event.add)" (emitSelection)="onSelectedChild($event, criterion, criterion.childCriterions)" [criterionKey]="criterionKey" [currentLevel]="1" [parentCriterion]="criterion" *ngIf="criterion.childCriterions?.length > 0"></my-searches-add-edit-children>-->
<!--          </ng-template>-->
<!--        </mat-expansion-panel>-->
<!--      </ng-container>-->
<!--    </mat-accordion>-->
<!--    &lt;!&ndash;    </div>&ndash;&gt;-->


<!--  </ng-container>-->
<!--</div>-->
<!--<ion-header no-border style="display: none" >-->
<!--  <ion-toolbar color="secondary" class="header-searches-add">-->
<!--    <ion-buttons slot="start">-->
<!--      <button *ngIf="displayedForm == 'subcriterions' || displayedForm == 'result' || displayedForm == 'save'"-->
<!--              mat-icon-button aria-label="close" (click)="changeDisplayForm('criterions')">-->
<!--        <mat-icon>arrow_back_ios</mat-icon>-->
<!--      </button>-->
<!--      <button *ngIf="displayedForm == 'criterions'" mat-icon-button aria-label="close" (click)="backClicked()">-->
<!--        <mat-icon>arrow_back_ios</mat-icon>-->
<!--      </button>-->
<!--    </ion-buttons>-->
<!--    <ion-title class="searchTitle" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}"-->
<!--               *ngIf="displayedForm == 'criterions' && !search">{{'SEARCH'}}</ion-title>-->
<!--    <ion-title class="searchTitle" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}"-->
<!--               *ngIf="displayedForm == 'subcriterions' && search">{{'EDIT_SEARCH_TITLE'}}</ion-title>-->
<!--    <ion-title class="searchTitle" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}"-->
<!--               *ngIf="displayedForm == 'subcriterions' && selectedCriterionLabel">{{selectedCriterionLabel}}</ion-title>-->
<!--    <ion-title class="searchTitle" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}"-->
<!--               *ngIf="displayedForm == 'result'">{{'SEARCH_RESULTS'}}</ion-title>-->
<!--    <ion-title class="searchTitle" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}"-->
<!--               *ngIf="displayedForm == 'save'">{{'SEARCH_SAVE'}}</ion-title>-->
<!--    <ion-buttons slot="end">-->
<!--      <button *ngIf="displayedForm == 'criterions' && childrenCriterions" (click)="send()"-->
<!--              class="toolbar-rounded-button accent" mat-flat-button [disabled]="disableSearchButton()">-->
<!--        <mat-icon *ngIf="!disableSearchButton()">check_circle_outline</mat-icon>-->
<!--        <span *ngIf="!running && (disableSearchButton() || articlesCount === 0)">{{"SEARCH_NO_RESULT"}}</span>-->
<!--        <span *ngIf="!running && (!disableSearchButton() && articlesCount > 1)">Voir les {{articlesCount}} résultats</span>-->
<!--        <span *ngIf="!running && (!disableSearchButton() && articlesCount === 1)">Voir le résultat</span>-->
<!--        <span *ngIf="running"><mat-spinner style="display: inline-block; vertical-align: middle;" [diameter]="20" strokeWidth="3"></mat-spinner></span>-->
<!--&lt;!&ndash;        <span><mat-spinner [diameter]="20" strokeWidth="3"></mat-spinner></span>&ndash;&gt;-->
<!--      </button>-->
<!--      <button *ngIf="displayedForm == 'subcriterions'" (click)="addToSelectedCriterions(criterionKey)"-->
<!--              class="toolbar-rounded-button accent" mat-flat-button>-->
<!--        <mat-icon>check_circle_outline</mat-icon>-->
<!--        <span>{{"VALIDATE"}}</span>-->
<!--      </button>-->
<!--      <button *ngIf="displayedForm == 'result'" (click)="changeDisplayForm('save')"-->
<!--              class="toolbar-rounded-button accent" mat-flat-button>-->
<!--        <mat-icon>check_circle_outline</mat-icon>-->
<!--        <span>{{"SEARCH_VALIDATE"}}</span>-->
<!--      </button>-->
<!--      <button *ngIf="displayedForm == 'save'" (click)="saveSearch()" class="toolbar-rounded-button accent"-->
<!--              mat-flat-button>-->
<!--        <mat-icon>check_circle_outline</mat-icon>-->
<!--        <span>{{"SAVE"}}</span>-->
<!--      </button>-->
<!--    </ion-buttons>-->
<!--  </ion-toolbar>-->
<!--  <ion-toolbar class="" color="primary" [hidden]="displayedForm == 'save'">-->
<!--    <div class="date-container actionButtons centerDates">-->
<!--      <dates-selector-button style="margin-right: 15px;"></dates-selector-button>-->
<!--      <div *ngIf="searchCategory == 0" style="margin-top: 4px;">Opérateur<b style="margin-left: 5px;">ET</b></div>-->
<!--      <mat-slide-toggle *ngIf="searchCategory == 0" style="margin-left: 15px;" id="defaultOpOptionToggle"-->
<!--                        [checked]="this._defaultOpOption == 'and'"-->
<!--                        [formControl]="defaultOpOptionForm" (change)="checkResults()" title="Faire une recherche avec l'opérateur ET ou l'opérateur OU.-->
<!--ET : Tous les mots doivent être présents.-->
<!--OU : Un ou plusieurs mots doivent être présents."-->
<!--      >Opérateur <b>OU</b>-->
<!--      </mat-slide-toggle>-->
<!--    </div>-->
<!--  </ion-toolbar>-->
<!--  <ion-toolbar #redToolbar id="redToolbar" style="z-index: 1;"-->
<!--               [hidden]="displayedForm != 'result' && !loadingInProgress" class="redToolbar" color="secondary"-->
<!--               [hideTollbarOnScroll]="scrollArea">-->
<!--    <div class="actionButtons">-->
<!--      <mat-checkbox class="checkAllArticles" (change)="checkAllArticles($event)"-->
<!--                    [checked]="allArticlesChecked()"></mat-checkbox>-->
<!--      <toggle-list-style [mapShowed]="stype === 'business_signal'" style="flex: 1"-->
<!--                         (listStyleChanged)="listStyleChanged($event)"></toggle-list-style>-->

<!--      <div class="articlesCategories" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches }">-->
<!--        <button mat-button *ngIf="locationsCount !== undefined && locationsCount.length > 0" [matMenuTriggerFor]="locations">Localisation</button>-->
<!--        <mat-menu #locations="matMenu">-->
<!--          <div style="margin:10px;">-->
<!--            <mat-checkbox [(ngModel)]="allLocationsSelected" name="locations_list_name"-->
<!--                          (click)="$event.stopPropagation()"-->
<!--                          (change)="checkUncheckAllKeys(locationsCount, allLocationsSelected, 'locations')">-->
<!--              <strong>Sélectionner/ Déselectionner tout</strong>-->
<!--            </mat-checkbox>-->
<!--          </div>-->
<!--          <button mat-menu-item *ngFor="let location of locationsCount">-->
<!--            <mat-slide-toggle [checked]="isCriterionOn(location.id)"-->
<!--                              name="locations_list_name"-->
<!--                              (click)="$event.stopPropagation()"-->
<!--                              (change)="matSlideToggleChange($event, location, 'locations')">{{location.label}}-->
<!--              <span class="badge-count">{{location.articlesCount}}</span></mat-slide-toggle>-->
<!--          </button>-->
<!--        </mat-menu>-->

<!--        <button mat-button-->
<!--                *ngIf="sectorsCount && sectorsCount.length > 0"-->
<!--                [matMenuTriggerFor]="sectors">Secteur d'activité-->
<!--        </button>-->
<!--        <mat-menu #sectors="matMenu">-->
<!--          <div style="margin:10px;">-->
<!--            <mat-checkbox [(ngModel)]="allSectorsSelected" name="sectors_list_name"-->
<!--                          (click)="$event.stopPropagation()"-->
<!--                          (change)="checkUncheckAllKeys(sectorsCount,allSectorsSelected, 'sectors')">-->
<!--              <strong>Sélectionner/ Déselectionner tout</strong>-->
<!--            </mat-checkbox>-->
<!--          </div>-->
<!--          <button mat-menu-item *ngFor="let sector of sectorsCount">-->
<!--            <mat-slide-toggle [checked]="isCriterionOn(sector.id)"-->
<!--                              name="sectors_list_name"-->
<!--                              (click)="$event.stopPropagation();"-->
<!--                              (change)="matSlideToggleChange($event, sector, 'sectors')">{{sector.label}}-->
<!--              <span-->
<!--                      class="badge-count">{{sector.articlesCount}}</span></mat-slide-toggle>-->
<!--          </button>-->
<!--        </mat-menu>-->

<!--        <button mat-button-->
<!--                *ngIf="topicsCount !== undefined && topicsCount.length > 0"-->
<!--                [matMenuTriggerFor]="topics">Evénement-->
<!--        </button>-->
<!--        <mat-menu #topics="matMenu">-->
<!--          <div style="margin:10px;">-->
<!--            <mat-checkbox [(ngModel)]="allTopicsSelected" name="topics_list_name"-->
<!--                          (click)="$event.stopPropagation()"-->
<!--                          (change)="checkUncheckAllKeys(topicsCount, allTopicsSelected, 'topics')">-->
<!--              <strong>Sélectionner/ Déselectionner tout</strong>-->
<!--            </mat-checkbox>-->
<!--          </div>-->
<!--          <button mat-menu-item *ngFor="let topic of topicsCount">-->
<!--            <mat-slide-toggle [checked]="isCriterionOn(topic.id)"-->
<!--                              name="topics_list_name"-->
<!--                              (click)="$event.stopPropagation()"-->
<!--                              (change)="matSlideToggleChange($event, topic, 'topics')">{{topic.label}}-->
<!--              <span-->
<!--                      class="badge-count">{{topic.articlesCount}}</span></mat-slide-toggle>-->
<!--          </button>-->
<!--        </mat-menu>-->

<!--        <button mat-button-->
<!--                *ngIf="company_sizesCount && company_sizesCount.length > 0"-->
<!--                [matMenuTriggerFor]="companySizes" >Taille d'entreprise-->
<!--        </button>-->
<!--        <mat-menu #companySizes="matMenu">-->
<!--          <div style="margin:10px;">-->
<!--            <mat-checkbox [(ngModel)]="allCompanySizesSelected" name="company_sizes_list_name"-->
<!--                          (click)="$event.stopPropagation()"-->
<!--                          (change)="checkUncheckAllKeys(company_sizesCount, allCompanySizesSelected, 'companySizes')">-->
<!--              <strong>Sélectionner/ Déselectionner tout</strong>-->
<!--            </mat-checkbox>-->
<!--          </div>-->
<!--          <button mat-menu-item *ngFor="let companySize of company_sizesCount">-->
<!--            <mat-slide-toggle [checked]="isCriterionOn(companySize.id)"-->
<!--                              name="company_sizes_list_name"-->
<!--                              (click)="$event.stopPropagation()"-->
<!--                              (change)="matSlideToggleChange($event, companySize, 'companySizes')">{{companySize.label}}-->
<!--              <span class="badge-count">{{companySize.articlesCount}}</span></mat-slide-toggle>-->
<!--          </button>-->
<!--        </mat-menu>-->

<!--        <button mat-button-->
<!--                *ngIf="activity_typesCount && activity_typesCount.length > 0"-->
<!--                [matMenuTriggerFor]="activityTypes">Nature des locaux-->
<!--        </button>-->
<!--        <mat-menu #activityTypes="matMenu">-->
<!--          <div style="margin:10px;">-->
<!--            <mat-checkbox [(ngModel)]="allActivityTypesSelected" name="activity_types_list_name"-->
<!--                          (click)="$event.stopPropagation()"-->
<!--                          (change)="checkUncheckAllKeys(activity_typesCount, allActivityTypesSelected, 'activityTypes')">-->
<!--              <strong>Sélectionner/ Déselectionner tout</strong>-->
<!--            </mat-checkbox>-->
<!--          </div>-->
<!--          <button mat-menu-item *ngFor="let activityType of activity_typesCount">-->
<!--            <mat-slide-toggle [checked]="isCriterionOn(activityType.id)"-->
<!--                              name="activity_types_list_name"-->
<!--                              (click)="$event.stopPropagation()"-->
<!--                              (change)="matSlideToggleChange($event, activityType, 'activityTypes');">{{activityType.label}}-->
<!--              <span class="badge-count">{{activityType.articlesCount}}</span></mat-slide-toggle>-->
<!--          </button>-->
<!--        </mat-menu>-->

<!--        <button mat-button-->
<!--                *ngIf="searchesService.selectedCriterionsObj !== undefined && searchesService.selectedCriterionsObj.keywords != undefined && searchesService.selectedCriterionsObj.keywords.length > 0 && keywordsCount !== undefined && keywordsCount.length > 0"-->
<!--                [matMenuTriggerFor]="keywords">Mots-clé-->
<!--        </button>-->
<!--        <mat-menu #keywords="matMenu">-->
<!--          <button mat-menu-item *ngFor="let keyword of keywordsCount">-->
<!--            <mat-slide-toggle [checked]="isKeywordOn(keyword.keyword)"-->
<!--                              (click)="$event.stopPropagation()"-->
<!--                              (change)="matSlideToggleChange($event, keyword, 'keywords')">{{keyword.keyword}} <span-->
<!--                    class="badge-count">{{keyword.articlesCount}}</span></mat-slide-toggle>-->
<!--          </button>-->
<!--        </mat-menu>-->

<!--        &lt;!&ndash;        Recherche avancée&ndash;&gt;-->
<!--        <button mat-button-->
<!--                *ngIf="searchesService.selectedCriterionsObj !== undefined && searchesService.selectedCriterionsObj.keywords_must != undefined && searchesService.selectedCriterionsObj.keywords_must.length > 0"-->
<!--                [matMenuTriggerFor]="keywordsAdvanced">Recherche avancée-->
<!--        </button>-->
<!--        <mat-menu #keywordsAdvanced="matMenu">-->
<!--          &lt;!&ndash;            <button mat-menu-item *ngFor="let keyword of keywordsCount">&ndash;&gt;-->
<!--          <div style="padding: 10px; width: 210px; line-height: 25px;"><b-->
<!--                  *ngIf="searchesService.selectedCriterionsObj.keywords_must !== undefined && searchesService.selectedCriterionsObj.keywords_must.length > 0">Expressions-->
<!--            exactes : <br></b>-->
<!--            <div *ngIf="searchesService.selectedCriterionsObj.keywords_must !== undefined && searchesService.selectedCriterionsObj.keywords_must.length > 0"-->
<!--                 [innerHTML]="arrayToStringWithLineBreaks(searchesService.selectedCriterionsObj.keywords_must)"><br></div>-->
<!--            <b *ngIf="searchesService.selectedCriterionsObj.keywords_must_exact !== undefined && searchesService.selectedCriterionsObj.keywords_must_exact.length > 0">Tous-->
<!--              les mots suivants : <br></b>-->
<!--            <div *ngIf="searchesService.selectedCriterionsObj.keywords_must_exact !== undefined && searchesService.selectedCriterionsObj.keywords_must_exact.length > 0"-->
<!--                 [innerHTML]="arrayToStringWithLineBreaks(searchesService.selectedCriterionsObj.keywords_must_exact)"><br>-->
<!--            </div>-->
<!--            <b *ngIf="searchesService.selectedCriterionsObj.keywords_should !== undefined && searchesService.selectedCriterionsObj.keywords_should.length > 0">Un-->
<!--              des mots suivant : <br></b>-->
<!--            <div *ngIf="searchesService.selectedCriterionsObj.keywords_should !== undefined && searchesService.selectedCriterionsObj.keywords_should.length > 0"-->
<!--                 [innerHTML]="arrayToStringWithLineBreaks(searchesService.selectedCriterionsObj.keywords_should)"><br></div>-->
<!--            <b *ngIf="searchesService.selectedCriterionsObj.keywords_must_not !== undefined && searchesService.selectedCriterionsObj.keywords_must_not.length > 0">Aucun-->
<!--              des mots suivants : <br></b>-->
<!--            <div *ngIf="searchesService.selectedCriterionsObj.keywords_must_not !== undefined && searchesService.selectedCriterionsObj.keywords_must_not.length > 0"-->
<!--                 [innerHTML]="arrayToStringWithLineBreaks(searchesService.selectedCriterionsObj.keywords_must_not)"></div>-->
<!--          </div>-->
<!--          &lt;!&ndash;            </button>&ndash;&gt;-->
<!--        </mat-menu>-->
<!--      </div>-->






<!--      <div class="iconButtons">-->
<!--        &lt;!&ndash; selectionModel is declared in PaginatedListComponent &ndash;&gt;-->
<!--        <article-send-mail-button [selectionModel]="selectionModel"></article-send-mail-button>-->
<!--        <article-read-unread-button [selectionModel]="selectionModel"></article-read-unread-button>-->
<!--        <article-export-button *ngIf="!mediaQueryService.mobileQuery.matches"-->
<!--                               [selectionModel]="selectionModel"></article-export-button>-->
<!--        <article-print-button [selectionModel]="selectionModel"></article-print-button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </ion-toolbar>-->
<!--</ion-header>-->

<!--<ion-content [hidden]="displayedForm != 'criterions' && displayedForm != 'subcriterions'" style="display: none">-->


<!--  &lt;!&ndash; 1) CRITERIONS &ndash;&gt;-->
<!--  <ng-container *ngIf="displayedForm == 'criterions' && childrenCriterions">-->
<!--    <mat-tab-group [ngClass]="{'marBotMobile': mediaQueryService.mobileQuery.matches}"-->
<!--                   (selectedTabChange)="searchCategoryChanged($event)" [(selectedIndex)]="searchCategory">-->
<!--      <mat-tab label="Recherche Mots-clés Simple"></mat-tab>-->
<!--      <mat-tab label="Recherche Mots-clés Avancée"></mat-tab>-->
<!--    </mat-tab-group>-->
<!--    <ng-container *ngIf="searchCategory==0">-->

<!--      <mat-form-field class="full-width-input search-margin">-->
<!--&lt;!&ndash;        <mat-chip-list #chipList aria-label="Keywords selection">&ndash;&gt;-->
<!--&lt;!&ndash;          <mat-chip&ndash;&gt;-->
<!--&lt;!&ndash;                  *ngFor="let keyword of selectedKeywords"&ndash;&gt;-->
<!--&lt;!&ndash;                  [selectable]="selectable"&ndash;&gt;-->
<!--&lt;!&ndash;                  [removable]="removable"&ndash;&gt;-->
<!--&lt;!&ndash;                  (removed)="remove(keyword)">&ndash;&gt;-->
<!--&lt;!&ndash;            {{keyword}}&ndash;&gt;-->
<!--&lt;!&ndash;            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>&ndash;&gt;-->
<!--&lt;!&ndash;          </mat-chip>&ndash;&gt;-->
<!--&lt;!&ndash;          <input&ndash;&gt;-->
<!--&lt;!&ndash;                  placeholder="{{'ADD_KEYWORDS_PLACEHOLDER'}}"&ndash;&gt;-->
<!--&lt;!&ndash;                  #keywordInput&ndash;&gt;-->
<!--&lt;!&ndash;                  [formControl]="keywordCtrl"&ndash;&gt;-->
<!--&lt;!&ndash;                  [matChipInputFor]="chipList"&ndash;&gt;-->
<!--&lt;!&ndash;                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"&ndash;&gt;-->
<!--&lt;!&ndash;                  (matChipInputTokenEnd)="add($event)"&ndash;&gt;-->
<!--&lt;!&ndash;                  (blur)="addBlur($event)">&ndash;&gt;-->
<!--&lt;!&ndash;        </mat-chip-list>&ndash;&gt;-->
<!--      </mat-form-field>-->
<!--    </ng-container>-->
<!--    &lt;!&ndash;  Advanced Searches  &ndash;&gt;-->
<!--    <ng-container *ngIf="searchCategory !=0">-->
<!--      &lt;!&ndash;  Searches Help Dialog   &ndash;&gt;-->
<!--&lt;!&ndash;      <button mat-raised-button&ndash;&gt;-->
<!--&lt;!&ndash;              style="float: right; margin-right: 25px; margin-top: -50px; color: white; background-color:#c20015; z-index: 10000;"&ndash;&gt;-->
<!--&lt;!&ndash;              (click)="openSearchesHelpDialog()">Besoin&ndash;&gt;-->
<!--&lt;!&ndash;        d'aide ?&ndash;&gt;-->
<!--&lt;!&ndash;      </button>&ndash;&gt;-->
<!--      <form class="advanced-search-form">-->

<!--&lt;!&ndash;        <h1 mat-dialog-title>Aide Recherche Avancée</h1>&ndash;&gt;-->
<!--&lt;!&ndash;        <div mat-dialog-content style="text-align: left; width: 935px; margin: auto; margin-bottom: 40px;">&ndash;&gt;-->
<!--&lt;!&ndash;          Pour vous aider à gagner en pertinence, vous avez à votre disposition plusieurs champs de recherche :&ndash;&gt;-->
<!--&lt;!&ndash;          <ul>&ndash;&gt;-->
<!--&lt;!&ndash;            <li>&ndash;&gt;-->
<!--&lt;!&ndash;              <b>Un champ de recherche d'expression exacte :</b> pour des recherches incluant les&ndash;&gt;-->
<!--&lt;!&ndash;              mots&ndash;&gt;-->
<!--&lt;!&ndash;              vides <code style="background-color: #F0FFFF;">le, la, de, du, ce ...etc</code>. <br>&ndash;&gt;-->
<!--&lt;!&ndash;              <b>Exemple : </b> <code style="background-color: #F0FFFF;">permis de construire</code> recherchera de façon stricte <code&ndash;&gt;-->
<!--&lt;!&ndash;                    style="background-color: #F0FFFF;">permis de contruire</code> et non <code&ndash;&gt;-->
<!--&lt;!&ndash;                    style="background-color: #F0FFFF;">permis construire</code>&ndash;&gt;-->
<!--&lt;!&ndash;            </li>&ndash;&gt;-->
<!--&lt;!&ndash;            <li>&ndash;&gt;-->
<!--&lt;!&ndash;              <b>Un champ de recherche ET :</b> pour des recherches qui retournent des articles ou&ndash;&gt;-->
<!--&lt;!&ndash;              des appels d'offres contenant tous les mots renseignés.&ndash;&gt;-->
<!--&lt;!&ndash;            </li>&ndash;&gt;-->
<!--&lt;!&ndash;            <li>&ndash;&gt;-->
<!--&lt;!&ndash;              <b>Un champ de recherche OU inclusif :</b> pour des recherches qui retournent des&ndash;&gt;-->
<!--&lt;!&ndash;              articles ou&ndash;&gt;-->
<!--&lt;!&ndash;              des appels d'offres contenant l'un des mots renseignés.&ndash;&gt;-->
<!--&lt;!&ndash;            </li>&ndash;&gt;-->
<!--&lt;!&ndash;            <li>&ndash;&gt;-->
<!--&lt;!&ndash;              <b>Un champ de recherche d'exclusion de mots :</b> pour des recherches qui ôtent&ndash;&gt;-->
<!--&lt;!&ndash;              les mots renseignés.&ndash;&gt;-->
<!--&lt;!&ndash;            </li>&ndash;&gt;-->
<!--&lt;!&ndash;          </ul>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->

<!--        &lt;!&ndash; Exact keywords   &ndash;&gt;-->
<!--        <mat-label style="font-size: 15px; font-weight: 500; display: flex; width: 80%; margin: auto; margin-bottom: -30px; padding-left: 20px;">Expressions exactes</mat-label>-->
<!--        <div style="font-size:12px; font-style: italic; margin-top: 30px; margin-bottom: -30px; padding-left: 20px; width: 80%; margin-left: auto; margin-right: auto; text-align: left;">Un champ de recherche d'<b>expression exacte :</b> pour des recherches incluant les-->
<!--        mots-->
<!--        vides <code style="background-color: #F0FFFF;">le, la, de, du, ce ...etc</code>. <br>-->
<!--        <b>Exemple : </b> <code style="background-color: #F0FFFF;">permis de construire</code> recherchera de façon stricte <code-->
<!--              style="background-color: #F0FFFF;">permis de contruire</code> et non <code-->
<!--              style="background-color: #F0FFFF;">permis construire</code></div>-->
<!--        <mat-form-field class="advanced-search-width-input search-margin">-->
<!--          &lt;!&ndash; class="full-width-input search-margin" &ndash;&gt;-->
<!--&lt;!&ndash;          <mat-chip-list #exactKeywordsChipsList aria-label="Exact keywords selection">&ndash;&gt;-->
<!--&lt;!&ndash;            <mat-chip&ndash;&gt;-->
<!--&lt;!&ndash;                    style="background-color:  #7BBCD5FF;"&ndash;&gt;-->
<!--&lt;!&ndash;                    *ngFor="let selectedExactKeyword of selectedExactKeywords"&ndash;&gt;-->
<!--&lt;!&ndash;                    [selectable]="selectable"&ndash;&gt;-->
<!--&lt;!&ndash;                    [removable]="removable"&ndash;&gt;-->
<!--&lt;!&ndash;                    (removed)="remove(selectedExactKeyword, 'exactKeywords')">&ndash;&gt;-->
<!--&lt;!&ndash;              {{selectedExactKeyword}}&ndash;&gt;-->
<!--&lt;!&ndash;              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>&ndash;&gt;-->
<!--&lt;!&ndash;            </mat-chip>&ndash;&gt;-->
<!--&lt;!&ndash;            <input matInput&ndash;&gt;-->
<!--&lt;!&ndash;                   #exactKeywordsInput&ndash;&gt;-->
<!--&lt;!&ndash;                   [matChipInputFor]="exactKeywordsChipsList"&ndash;&gt;-->
<!--&lt;!&ndash;                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"&ndash;&gt;-->
<!--&lt;!&ndash;                   [formControl]="exactKeywordsCtrl"&ndash;&gt;-->
<!--&lt;!&ndash;                   (matChipInputTokenEnd)="add($event, 'exactKeywords')">&ndash;&gt;-->
<!--&lt;!&ndash;          </mat-chip-list>&ndash;&gt;-->
<!--        </mat-form-field>-->
<!--        &lt;!&ndash; All of Keywords   &ndash;&gt;-->
<!--        <mat-label style="font-size: 15px; font-weight: 500; display: flex; width: 80%; margin: auto; margin-bottom: -30px; padding-left: 20px;">Tous les mots suivants</mat-label>-->
<!--        <div style="font-size: 12px; font-style: italic; margin-top: 30px; margin-bottom: -30px; padding-left: 20px; width: 80%; margin-left: auto; margin-right: auto; text-align: left;">Un champ de recherche <b>ET :</b> pour des recherches qui retournent des articles ou-->
<!--          des appels d'offres contenant tous les mots renseignés.</div>-->
<!--        <mat-form-field class="advanced-search-width-input search-margin">-->
<!--&lt;!&ndash;          <mat-chip-list #allOfKeywordsChipsList aria-label="All of Keywords selection">&ndash;&gt;-->
<!--&lt;!&ndash;            <mat-chip&ndash;&gt;-->
<!--&lt;!&ndash;                    style="background-color:#D0E2AFFF;"&ndash;&gt;-->
<!--&lt;!&ndash;                    *ngFor="let selectedAllOfKeyword of selectedAllOfKeywords"&ndash;&gt;-->
<!--&lt;!&ndash;                    [selectable]="selectable"&ndash;&gt;-->
<!--&lt;!&ndash;                    [removable]="removable"&ndash;&gt;-->
<!--&lt;!&ndash;                    (removed)="remove(selectedAllOfKeyword, 'allOfKeywords')">&ndash;&gt;-->
<!--&lt;!&ndash;              {{selectedAllOfKeyword}}&ndash;&gt;-->
<!--&lt;!&ndash;              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>&ndash;&gt;-->
<!--&lt;!&ndash;            </mat-chip>&ndash;&gt;-->
<!--&lt;!&ndash;            <input matInput&ndash;&gt;-->
<!--&lt;!&ndash;                   #allOfKeywordsInput&ndash;&gt;-->
<!--&lt;!&ndash;                   [matChipInputFor]="allOfKeywordsChipsList"&ndash;&gt;-->
<!--&lt;!&ndash;                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"&ndash;&gt;-->
<!--&lt;!&ndash;                   [formControl]="allOfKeywordsCtrl"&ndash;&gt;-->
<!--&lt;!&ndash;                   (matChipInputTokenEnd)="add($event, 'allOfKeywords')">&ndash;&gt;-->
<!--&lt;!&ndash;          </mat-chip-list>&ndash;&gt;-->
<!--        </mat-form-field>-->
<!--        &lt;!&ndash; One of Keywords  &ndash;&gt;-->
<!--        <mat-label style="font-size: 15px; font-weight: 500; display: flex; width: 80%; margin: auto; margin-bottom: -30px; padding-left: 20px;">Un des mots suivant</mat-label>-->
<!--        <div style="font-size: 12px; font-style: italic; margin-top: 30px; margin-bottom: -30px; padding-left: 20px; width: 80%; margin-left: auto; margin-right: auto; text-align: left;">Un champ de recherche <b>OU inclusif :</b> pour des recherches qui retournent des-->
<!--        articles ou-->
<!--          des appels d'offres contenant l'un des mots renseignés.</div>-->
<!--        <mat-form-field class="advanced-search-width-input search-margin">-->
<!--&lt;!&ndash;          <mat-chip-list #oneOfKeywordsChipsList aria-label="One of Keywords selection">&ndash;&gt;-->
<!--&lt;!&ndash;            <mat-chip&ndash;&gt;-->
<!--&lt;!&ndash;                    style="background-color:#F5DB99FF;"&ndash;&gt;-->
<!--&lt;!&ndash;                    *ngFor="let selectedOneOfKeyword of selectedOneOfKeywords"&ndash;&gt;-->
<!--&lt;!&ndash;                    [selectable]="selectable"&ndash;&gt;-->
<!--&lt;!&ndash;                    [removable]="removable"&ndash;&gt;-->
<!--&lt;!&ndash;                    (removed)="remove(selectedOneOfKeyword, 'oneOfKeywords')">&ndash;&gt;-->
<!--&lt;!&ndash;              {{selectedOneOfKeyword}}&ndash;&gt;-->
<!--&lt;!&ndash;              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>&ndash;&gt;-->
<!--&lt;!&ndash;            </mat-chip>&ndash;&gt;-->
<!--&lt;!&ndash;            <input matInput&ndash;&gt;-->
<!--&lt;!&ndash;                   #oneOfKeywordsInput&ndash;&gt;-->
<!--&lt;!&ndash;                   [matChipInputFor]="oneOfKeywordsChipsList"&ndash;&gt;-->
<!--&lt;!&ndash;                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"&ndash;&gt;-->
<!--&lt;!&ndash;                   [formControl]="oneOfKeywordsCtrl"&ndash;&gt;-->
<!--&lt;!&ndash;                   (matChipInputTokenEnd)="add($event, 'oneOfKeywords')">&ndash;&gt;-->
<!--&lt;!&ndash;          </mat-chip-list>&ndash;&gt;-->
<!--        </mat-form-field>-->
<!--        &lt;!&ndash;   None Keywords  &ndash;&gt;-->
<!--        <mat-label style="font-size: 15px; font-weight: 500; display: flex; width: 80%; margin: auto; margin-bottom: -30px; padding-left: 20px;">Aucun des mots suivants</mat-label>-->
<!--        <div style="font-size: 12px; font-style: italic; margin-top: 30px; margin-bottom: -30px; padding-left: 20px; width: 80%; margin-left: auto; margin-right: auto; text-align: left;">Un champ de recherche d'<b>exclusion de mots :</b> pour des recherches qui ôtent-->
<!--          les mots renseignés.</div>-->
<!--        <mat-form-field class="advanced-search-width-input search-margin">-->
<!--&lt;!&ndash;          <mat-chip-list #noneOfKeywordsChipsList aria-label="None of Keywords selection">&ndash;&gt;-->
<!--&lt;!&ndash;            <mat-chip&ndash;&gt;-->
<!--&lt;!&ndash;                    style="background-color:#D2848DFF;"&ndash;&gt;-->
<!--&lt;!&ndash;                    *ngFor="let selectedNoneOfKeyword of selectedNoneOfKeywords"&ndash;&gt;-->
<!--&lt;!&ndash;                    [selectable]="selectable"&ndash;&gt;-->
<!--&lt;!&ndash;                    [removable]="removable"&ndash;&gt;-->
<!--&lt;!&ndash;                    (removed)="remove(selectedNoneOfKeyword, 'noneOfKeywords')">&ndash;&gt;-->
<!--&lt;!&ndash;              {{selectedNoneOfKeyword}}&ndash;&gt;-->
<!--&lt;!&ndash;              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>&ndash;&gt;-->
<!--&lt;!&ndash;            </mat-chip>&ndash;&gt;-->
<!--&lt;!&ndash;            <input matInput&ndash;&gt;-->
<!--&lt;!&ndash;                   #noneOfKeywordsInput&ndash;&gt;-->
<!--&lt;!&ndash;                   [matChipInputFor]="noneOfKeywordsChipsList"&ndash;&gt;-->
<!--&lt;!&ndash;                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"&ndash;&gt;-->
<!--&lt;!&ndash;                   [formControl]="noneOfKeywordsCtrl"&ndash;&gt;-->
<!--&lt;!&ndash;                   (matChipInputTokenEnd)="add($event, 'noneOfKeywords')">&ndash;&gt;-->
<!--&lt;!&ndash;          </mat-chip-list>&ndash;&gt;-->
<!--        </mat-form-field>-->
<!--      </form>-->
<!--    </ng-container>-->

<!--    <mat-list>-->
<!--      <ng-container *ngIf=" (criterionsTypes) as criterionsTypes; else loading">-->
<!--        <ng-container *ngFor="let criterionsType of criterionsTypes | keyvalue : returnZero">-->
<!--          <mat-list-item class="critetionType"-->
<!--                         (click)="openSubPage(criterionsType.key, criterionsType.value.label, criterionsType.value.values)"-->
<!--                         [ngClass]="[(businessAoTab === 1 && criterionsType.value.label !== 'Localisation') ? 'disabled' : 'enabled']">-->
<!--            &lt;!&ndash; TODO: ngClass sur les couleurs active + afficher l'icône check_box et le matBadge uniquement quand c'est rempli &ndash;&gt;-->
<!--            &lt;!&ndash;            <mat-icon *ngIf="!getCountCriterions(criterionsType.key)" [matBadge]="getCountCriterions(criterionsType.key)" matBadgePosition="above after" [ngClass]="[(businessAoTab === 1 && criterionsType.value.label !== 'Localisation') ? 'disabled' : 'enabled']">check_box_outline_blank</mat-icon>&ndash;&gt;-->
<!--            &lt;!&ndash;            <mat-icon *ngIf="getCountCriterions(criterionsType.key)" [matBadge]="getCountCriterions(criterionsType.key)" matBadgePosition="above after" [ngClass]="[(businessAoTab === 1 && criterionsType.value.label !== 'Localisation') ? 'disabled' : 'enabled']">check_box</mat-icon>&ndash;&gt;-->
<!--            <mat-icon *ngIf="!getCountCriterions(criterionsType.key)"-->
<!--                      [ngClass]="[(businessAoTab === 1 && criterionsType.value.label !== 'Localisation') ? 'disabled' : 'enabled']">-->
<!--              check_box_outline_blank-->
<!--            </mat-icon>-->
<!--            <mat-icon *ngIf="getCountCriterions(criterionsType.key)"-->
<!--                      [ngClass]="[(businessAoTab === 1 && criterionsType.value.label !== 'Localisation') ? 'disabled' : 'enabled']">-->
<!--              check_box-->
<!--            </mat-icon>-->
<!--            <div class="criterion-value">-->
<!--              <h4>{{criterionsType.value.label}}</h4>-->
<!--              <p>{{criterionsType.value.help}}</p>-->
<!--            </div>-->
<!--            <mat-icon>keyboard_arrow_right</mat-icon>-->
<!--          </mat-list-item>-->
<!--        </ng-container>-->
<!--      </ng-container>-->
<!--    </mat-list>-->

<!--    <mat-tab-group [ngClass]="{'marBotMobile': mediaQueryService.mobileQuery.matches}"-->
<!--                   (selectedTabChange)="tabChanged($event)" [(selectedIndex)]="businessAoTab">-->
<!--      <mat-tab label="{{'BUTTON_BUSINESS_SIGNALS'}}"></mat-tab>-->
<!--      <mat-tab label="{{'BUTTON_AO'}}"></mat-tab>-->
<!--    </mat-tab-group>-->

<!--  </ng-container>-->

<!--  &lt;!&ndash; 2) SUBCRITERIONS &ndash;&gt;-->
<!--  <ng-container *ngIf="displayedForm == 'subcriterions' && childrenCriterions && criterionKey">-->

<!--    &lt;!&ndash;Geoloc filter&ndash;&gt;-->
<!--    <fieldset *ngIf="geolocAvailable && criterionKey == 'locations' && stype === 'business_signal'" style="border:2px solid #C20015;margin:10px;">-->
<!--      <legend>Géolocalisation</legend>-->
<!--      <form [formGroup]='cityForm'>-->
<!--        <div style="display: flex;">-->
<!--          <input [matAutocomplete]="auto" (change)="cityChange($event)" formControlName='cityInput' [(ngModel)]="cityFilter" type="text" class="rounded-searchbar" [placeholder]="'Où'" matInput (click)="geoFilterClick()">-->
<!--        </div>-->
<!--&lt;!&ndash;        <p>{{cityFilter}}</p>&ndash;&gt;-->
<!--        <mat-autocomplete #auto="matAutocomplete" >-->
<!--          <mat-option *ngIf="isLoading" class="is-loading"><mat-spinner diameter="50"></mat-spinner></mat-option>-->
<!--          <ng-container *ngIf="!isLoading">-->
<!--            <mat-option *ngFor="let option of cityOptions" [value]="getCityTextAndSetId(option)" (click)="setCityId(option)">-->
<!--              {{ option.name }} {{option.id > 0 ? (option.zip) : ''}}-->
<!--            </mat-option>-->
<!--          </ng-container>-->
<!--        </mat-autocomplete>-->
<!--      </form>-->
<!--      <mat-label>Dans un rayon</mat-label>-->
<!--      <mat-select [(ngModel)]="selectedDistance">-->
<!--        <mat-option value="10"> 10Km </mat-option>-->
<!--        <mat-option value="20"> 20Km </mat-option>-->
<!--        <mat-option value="50"> 50Km </mat-option>-->
<!--        <mat-option value="100"> 100Km </mat-option>-->
<!--        <mat-option value="200"> 200Km </mat-option>-->
<!--      </mat-select>-->
<!--    </fieldset>-->

<!--    <ion-searchbar matInput placeholder="Rechercher dans la liste" (input)="filterCriterions($event.target.value)"></ion-searchbar>-->

<!--    <mat-checkbox (change)="selectDeselectAll()" [checked]="allChecked()" class="checkAll">-->
<!--      Sélectionner / Déselectionner tout-->
<!--    </mat-checkbox>-->
<!--&lt;!&ndash;    <div style="position: absolute; width: 100vw; height: 100vh; top: 0; left: 0;">&ndash;&gt;-->
<!--      <mat-accordion multi="true" style="display:flex; flex-direction: column;"-->
<!--                     [ngClass]="{'padBotMobile': mediaQueryService.mobileQuery.matches}">-->
<!--        <ng-container *ngFor="let criterion of childrenCriterions;trackBy: trackByFn">-->
<!--          <mat-expansion-panel [expanded]="toExpand.has(criterion)" [disabled]="criterion.childCriterions?.length > 0 ? false : true" [ngClass]="{'highlightItem' : ((filterCriterion !== undefined && filterCriterion !== '') && (filterChildren(criterion, 1)))}" hideToggle="false">-->
<!--            <mat-expansion-panel-header class="expansionHeader">-->
<!--              <mat-panel-title>-->
<!--                <mat-checkbox-->
<!--                  [checked]="isChecked(criterion) || criterion.checked_"-->
<!--                  [indeterminate]="descendantsPartiallySelected(criterion, 0)"-->
<!--                  (click)="$event.stopPropagation()"-->
<!--                  (change)="onCheckParent(criterion, $event);">-->
<!--                  {{criterion.label}}-->
<!--                </mat-checkbox>-->
<!--              </mat-panel-title>-->
<!--            </mat-expansion-panel-header>-->
<!--            <ng-template matExpansionPanelContent>-->
<!--              <my-searches-add-edit-children [expanded]="toExpand" [filterCriterion]="filterCriterion" (emitSelection)="onSelectedChild($event, criterion, criterion.childCriterions)" [criterionKey]="criterionKey" [currentLevel]="1" [parentCriterion]="criterion" *ngIf="criterion.childCriterions?.length > 0"></my-searches-add-edit-children>-->
<!--            </ng-template>-->
<!--          </mat-expansion-panel>-->
<!--        </ng-container>-->
<!--      </mat-accordion>-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->


<!--  </ng-container>-->

<!--  <ng-template #loading>-->
<!--    <loading>{{"LOADING_CRITERIONS"}}</loading>-->
<!--  </ng-template>-->
<!--  <ng-template #noData>-->
<!--    <no-data>{{"NO_CRITERION"}}</no-data>-->
<!--  </ng-template>-->
<!--</ion-content>-->

<!-- &lt;!&ndash; 3) RESULT &ndash;&gt;-->
<!--<ion-content class="result" [hidden]="displayedForm != 'result'" #scrollArea scrollEvents="true">-->
<!--  <div class="listMapContainer"-->
<!--       [ngClass]="{-->
<!--    'mobile': mediaQueryService.mobileQuery.matches,-->
<!--    'desktop': !mediaQueryService.mobileQuery.matches,-->
<!--    'mapActivated': mediaQueryService.mobileQuery.matches && listStyle == 'map'-->
<!--  }">-->
<!--    <mat-list class="paginated">-->
<!--      &lt;!&ndash; loadingInProgress and mediaQueryService is declared in PaginatedListComponent &ndash;&gt;-->
<!--      <loading-overlay *ngIf="loadingInProgress && !mediaQueryService.mobileQuery.matches">-->
<!--        {{"LOADING_ARTICLES"}}</loading-overlay>-->
<!--      <no-data *ngIf="!loadingInProgress && !tableDataSource.data.length">{{"NO_ARTICLE"}}</no-data>-->

<!--      <div class="tableContainer" [ngClass]="{'desktop': !mediaQueryService.mobileQuery.matches}">-->
<!--        &lt;!&ndash; tableDataSource is declared in PaginatedListComponent &ndash;&gt;-->
<!--        <table mat-table [dataSource]="tableDataSource" [ngClass]="{'noData': tableDataSource.data.length === 0}">-->
<!--          <ng-container matColumnDef="customComponent">-->

<!--            <th mat-header-cell *matHeaderCellDef> customComponent </th> &lt;!&ndash; not displayed, hide with css &ndash;&gt;-->
<!--            <td mat-cell *matCellDef="let elem">-->
<!--              <article-list-item [keywords]="getKeywordsSearched()" [article]="elem" [style]="listStyle" [selectionModel]="selectionModel"></article-list-item>-->
<!--            </td>-->
<!--          </ng-container>-->

<!--          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>-->
<!--          <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>-->
<!--        </table>-->
<!--      </div>-->

<!--      &lt;!&ndash; on desktop, this component will be placed in absolute, top:-500px... &ndash;&gt;-->
<!--      <mat-paginator style="height: 56px;" class="paginator" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches, 'noData': tableDataSource.data.length === 0}" [length]="articlesCount" [pageIndex]="_page"-->
<!--        [pageSize]="_pageSize">-->
<!--      </mat-paginator>-->

<!--      &lt;!&ndash; Only on mobile &ndash;&gt;-->
<!--      &lt;!&ndash; onInfiniteScroll is declared in PaginatedListComponent &ndash;&gt;-->
<!--      <ion-infinite-scroll threshold="100px" (ionInfinite)="onInfiniteScroll($event)"-->
<!--        *ngIf="mediaQueryService.mobileQuery.matches">-->
<!--        <ion-infinite-scroll-content loadingSpinner="null">-->
<!--          <loading *ngIf="loadingInProgress" class="infiniteScrollLoading">{{"LOADING_ARTICLES"}}</loading>-->
<!--        </ion-infinite-scroll-content>-->
<!--      </ion-infinite-scroll>-->

<!--      <button class="mat-rounded-button small" [ngClass]="{'marBotMobile': mediaQueryService.mobileQuery.matches}"-->
<!--              color="primary" mat-raised-button-->
<!--        *ngIf="!hideLoadMoreButton && mediaQueryService.mobileQuery.matches" (click)="loadMoreContentButton()">-->
<!--        <mat-icon>sync</mat-icon>-->
<!--        {{"LOAD_MORE_CONTENT"}}-->
<!--      </button>-->
<!--    </mat-list>-->
<!--&lt;!&ndash;    <agm-map&ndash;&gt;-->
<!--&lt;!&ndash;            *ngIf="listStyle == 'listmap' || listStyle == 'map'"&ndash;&gt;-->
<!--&lt;!&ndash;            [latitude]="mapCenterLat"&ndash;&gt;-->
<!--&lt;!&ndash;            [longitude]="mapCenterLng"&ndash;&gt;-->
<!--&lt;!&ndash;            [zoom]="zoom"&ndash;&gt;-->
<!--&lt;!&ndash;            (mapClick)="mapClick()">&ndash;&gt;-->
<!--&lt;!&ndash;      <agm-marker-cluster imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m" [calculator]="getClusterStyle">&ndash;&gt;-->
<!--&lt;!&ndash;        <agm-marker *ngFor="let marker of markers; let i = index" [animation]="marker.animation"&ndash;&gt;-->
<!--&lt;!&ndash;                    [iconUrl]="marker.icon" [latitude]="marker.lat" [longitude]="marker.lng" [label]="marker.label"&ndash;&gt;-->
<!--&lt;!&ndash;                    [markerDraggable]="marker.draggable" (markerClick)=markerClick(iw)>&ndash;&gt;-->

<!--&lt;!&ndash;          <agm-info-window #iw>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="listDate greyColor map-modal-text">{{marker.data.publicationDate | date:'dd/LL/yyyy':'':locale}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div style="display: block;margin:10px;font-size: 14px;cursor: pointer;" class="listTitle map-modal-text" onclick="return false;" (click)="openReadingModalFromMarker(marker.data)">{{marker.data.title}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;            &lt;!&ndash;            <a href="#0" style="display: block;margin:10px;" class="listTitle map-modal-text" onclick="return false;" (click)="openReadingModalFromMarker(marker.data)">{{marker.data.contacts[0].organisationName}}</a>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;          </agm-info-window>&ndash;&gt;-->

<!--&lt;!&ndash;        </agm-marker>&ndash;&gt;-->

<!--&lt;!&ndash;      </agm-marker-cluster>&ndash;&gt;-->
<!--&lt;!&ndash;      <agm-marker [latitude]="lat" [longitude]="lng" [iconUrl]="myPostionIcon">&ndash;&gt;-->
<!--&lt;!&ndash;      </agm-marker>&ndash;&gt;-->
<!--&lt;!&ndash;    </agm-map>&ndash;&gt;-->
<!--  </div>-->
<!--&lt;!&ndash;  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">&ndash;&gt;-->
<!--&lt;!&ndash;    <p style="color: white">Chargement en cours. </p>&ndash;&gt;-->
<!--&lt;!&ndash;  </ngx-spinner>&ndash;&gt;-->

<!--</ion-content>-->

<!--&lt;!&ndash; 4) SAVE &ndash;&gt;-->
<!--<ion-content [hidden]="displayedForm != 'save'">-->
<!--  <mat-form-field class="full-width-input search-margin">-->
<!--    <mat-label>{{"SEARCH_NAME"}}</mat-label>-->
<!--    <input matInput #searchNameInput placeholder="{{'SEARCH_NAME_PLACEHOLDER'}}">-->
<!--  </mat-form-field>-->
<!--</ion-content>-->
