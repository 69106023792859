<div class="searchLeftPartTour">
    <mat-list>
        <mat-list-item [ngClass]="{'selectedItem': selectedType === 'keywords'}" class="listItem" style="height:auto;" (click)="openCriterionPage('keywords')">
            <div class="categoryBtn">
                <fe-icon [iconType]="IconType.mots_cles"/>
                <div style="flex: 1">
                    <p style="margin: 0;">Mots-clés</p>
                    <p style="font-size: 14px; color: #475A66;margin: 0;">{{getNumberCriterionSelected('keywords')}}</p>
                </div>
            </div>
        </mat-list-item>
        <mat-list-item [ngClass]="{'selectedItem': selectedType === 'sectors'}" class="listItem" style="height:auto;" (click)="openCriterionPage('sectors')">
            <div class="categoryBtn">
                <fe-icon [iconType]="IconType.secteurs"/>
                <div style="flex: 1">
                    <p style="margin: 0;">Secteurs d'activité</p>
                    <p style="font-size: 14px; color: #475A66;margin: 0;">{{getNumberCriterionSelected('sectors')}}</p>
                </div>
            </div>
        </mat-list-item>
        <mat-list-item [ngClass]="{'selectedItem': selectedType === 'topics'}" class="listItem" style="height:auto;" (click)="openCriterionPage('topics')">
            <div class="categoryBtn">
                <fe-icon [iconType]="IconType.tag_theme"/>
                <div style="flex: 1">
                    <p style="margin: 0;">Événements économiques</p>
                    <p style="font-size: 14px; color: #475A66;margin: 0;">{{getNumberCriterionSelected('topics')}}</p>
                </div>
            </div>
        </mat-list-item>
        <mat-list-item [ngClass]="{'selectedItem': selectedType === 'locations'}" class="listItem" style="height:auto;" (click)="openCriterionPage('locations')">
            <div class="categoryBtn">
                <fe-icon [iconType]="IconType.localisations"/>
                <div style="flex: 1">
                    <p style="margin: 0;">Localisation</p>
                    <p style="font-size: 14px; color: #475A66;margin: 0;">{{getNumberCriterionSelectedLocalisation('locations')}}</p>
                </div>
            </div>
        </mat-list-item>
        <mat-list-item [ngClass]="{'selectedItem': selectedType === 'companySizes'}" class="listItem" style="height:auto;" (click)="openCriterionPage('companySizes')">
            <div class="categoryBtn">
                <fe-icon [iconType]="IconType.taille_entreprise"/>
                <div style="flex: 1">
                    <p style="margin: 0;">Taille d'entreprise</p>
                    <p style="font-size: 14px; color: #475A66;margin: 0;">{{getNumberCriterionSelected('companySizes')}}</p>
                </div>
            </div>
        </mat-list-item>
        <mat-list-item [ngClass]="{'selectedItem': selectedType === 'activityTypes'}" class="listItem" style="height:auto;" (click)="openCriterionPage('activityTypes')">
            <div class="categoryBtn">
                <fe-icon [iconType]="IconType.nature_locaux"/>
                <div style="flex: 1">
                    <p style="margin: 0;">Nature des locaux</p>
                    <p style="font-size: 14px; color: #475A66;margin: 0;">{{getNumberCriterionSelected('activityTypes')}}</p>
                </div>
            </div>
        </mat-list-item>
    </mat-list>
</div>
