<button mat-button class="feUserAccountBtn">
  <mat-icon>account_circle</mat-icon>
  <ng-container *ngIf="!mediaQueryService.mobileQuery.matches">
    <span class="userData" *ngIf="sessionData; else noData">
      <span class="bigTextBold fullName" *ngIf="sessionData.customerDetails.firstname && sessionData.customerDetails.lastname">
        {{sessionData.customerDetails.firstname}} {{sessionData.customerDetails.lastname}}
      </span>
      <span class="littleText" *ngIf="sessionData.customerDetails.companyName">{{sessionData.customerDetails.companyName}}</span>
      <span class="littleText" *ngIf="sessionData.productOfferLabel">{{sessionData.productOfferLabel}}</span>
    </span>
    <ng-template #noData>
      <span class="userData">
        <mat-progress-spinner class="" color="accent" mode="indeterminate" diameter="20"></mat-progress-spinner>
      </span>
    </ng-template>
  </ng-container>
  <mat-icon *ngIf="authService.roleCom">admin_panel_settings</mat-icon>
</button>
<!--<div class="userData" *ngIf="sessionData;">-->
<!--<div style="height: 50px; margin-right: -53px; border-color: white; border: solid;    border-width: 2px; margin-left: 22px;-->
<!--    border-radius: 3px;padding-right: 5px;" >-->
<!--  <p style="font-size: 10px; margin-left: 4px;font-weight: normal;">Connexion à la plateforme</p>-->
<!--  <a target="_blank" [href]="'https://app.firstao.fr/aoportal/#/a-o-actuality?token=' + userToken+'&mode=client&stoken=' + sessionData.customerDetails.session_token">-->
<!--    <div class="logoHeader2">-->
<!--      <img src="./assets/images/logo_fao.png" alt="Logo First AO" style="height: 43px;padding-left: 30px;padding-top: 3px; margin-top: -25px;"/>-->
<!--    </div>-->
<!--  </a>-->
<!--</div>-->
<!--</div>-->
