import {Component, Input} from '@angular/core';
import {TagInterface} from '../../../interfaces/tag/tag';
import {Utils} from '../../../classes/Utils';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'fe-tag',
  templateUrl: './fe-tag.component.html',
  styleUrls: ['./fe-tag.component.scss']
})
export class FeTagComponent {
@Input() tag: TagInterface;
@Input() keywords: Array<string>;
@Input() leadMode: boolean = false;
  protected readonly Utils = Utils;

  constructor(public sanitizer: DomSanitizer) {
  }
}
