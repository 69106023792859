export class XAddress {

  public formatted_address: string;
  public address: string;
  public address_name: string;
  public country: string;
  public countryCode: string;
  public department: string;
  public region: string;
  public city: string;
  public zipCode: string;
  public position: object;
  private _error: string;

  public get error(): string {
    return this._error;
  }

  constructor(address?: google.maps.places.PlaceResult | google.maps.GeocoderResult) {
    if (address) {
      this.formatted_address = address.formatted_address;

      if (address['name']) { // google.maps.places.PlaceResult
        this.address_name = address['name'];
      } else {
        this.address_name = address.formatted_address;
      }

      this.address = this.getAddressComponent(address.address_components, 'route');
      if (!this.address) {
        this.address = this.address_name;
      }

      this.country = this.getAddressComponent(address.address_components, 'country');
      this.countryCode = this.getAddressComponent(address.address_components, 'country', 'short_name');
      this.region = this.getAddressComponent(address.address_components, 'administrative_area_level_1');
      this.department = this.getAddressComponent(address.address_components, 'administrative_area_level_2');
      this.city = this.getAddressComponent(address.address_components, 'locality');
      this.zipCode = this.getAddressComponent(address.address_components, 'postal_code');

      if (address.geometry && address.geometry.location
        && typeof address.geometry.location.lat === 'function'
        && typeof address.geometry.location.lng === 'function') {
        this.position = {
          lat: address.geometry.location.lat(),
          lng: address.geometry.location.lng()
        };
      }

      if (!( this.formatted_address && this.address && this.address_name && this.country && this.countryCode && this.position && this.position['lat'] && this.position['lng'] )) {
        this._error = 'Adresse incomplète';
      }
    }
  }

  public toString(): string {
    return this.formatted_address;
  }

  public getAddressComponent(components: Array<google.maps.GeocoderAddressComponent>, property: string, key = 'long_name'): string {
    if (!components) {
      return '';
    }

    let value: string;
    components.forEach((component: google.maps.GeocoderAddressComponent) => {
      let found = false;
      component.types.forEach((type) => {
        if (type === property) {
          found = true;
          return false;
        }
      });
      if (found) {
        value = component[key];
        return false;
      }
    });
    return value;
  }

}
