import {Component, EventEmitter, Inject, Output, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContactLineInterface} from '../fe-carnet-adresses-modal.component';
import {SnackbarService} from '../../../../services/snackbar/snackbar.service';

@Component({
  selector: 'fe-carnet-contact-modal',
  templateUrl: './fe-carnet-contact-modal.component.html',
  styleUrls: ['./fe-carnet-contact-modal.component.scss']
})
export class FeCarnetContactModalComponent {
  protected buttonText: string = 'Confirmer';
  protected title: string = 'Édition d\'un contact';
  protected contact: ContactLineInterface;
  protected createMode: boolean;
  protected mailErrorMsg: boolean = false;

  @Output() confirm = new EventEmitter<ContactLineInterface>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private snackbarService: SnackbarService,
              public dialogRef: MatDialogRef<FeCarnetContactModalComponent>) {
    this.createMode = data.createMode;
    this.contact = {
      id: 0,
      checked: false,
      label: '',
      email: ''};
    if (this.createMode) {
      this.buttonText = 'Créer le contact';
      this.title = 'Création d\'un contact';
    } else {
      this.contact.label = data.contactLine.label;
      this.contact.email = data.contactLine.email;
    }
  }

  protected confirmBtnClick(): void {
    if (this.contact.email.trim().length > 0) {
      if (this.isValidEmail(this.contact.email)) {
        this.confirm.emit(this.contact);
        this.dialogRef.close();
      } else {
        this.snackbarService.showDangerSnackbar('Enregistrement impossible',
            'Le format de l\'adresse mail n\'est pas correct.', 5);
      }
    } else {
      this.snackbarService.showDangerSnackbar('Enregistrement impossible',
          'Vous devez renseigner au moins l\'adresse mail du contact.', 5);
    }
  }

  protected isValidEmail(email: string): boolean {
    const emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  protected updateMailErrorMsg(): void {
    this.mailErrorMsg = !this.isValidEmail(this.contact.email);
  }
}
