<ion-header no-border>
  <ion-toolbar color="secondary">
    <div class="toolbar">
<!--      <mat-form-field class="input-amount mat-form-field" style="margin-left: 5px; flex: 1;" appearance="outline">-->
<!--        <mat-label style="font-size:16px;">Newsletters</mat-label>-->
      <div style="flex: 1;">
        <mat-select style="margin:5px" placeholder="Choix d'une newsletter" [(value)]="selectedNl" (selectionChange)="selectNewsletter($event)">
          <mat-option *ngFor="let nl of newsletters" [value]="nl">{{nl.name}}</mat-option>
        </mat-select>
      </div>
<!--      </mat-form-field>-->
      <ion-buttons slot="end">
        <button id="codeEditor" mat-icon-button title="Editeur">
          <mat-icon class="outlined">code</mat-icon>
        </button>
      </ion-buttons>
      <ion-buttons slot="end">
        <button id="undoButton" mat-icon-button title="Annuler">
          <mat-icon class="outlined">undo</mat-icon>
        </button>
      </ion-buttons>
      <ion-buttons slot="end">
        <button id="redoButton" mat-icon-button title="Rétablir">
          <mat-icon class="outlined">redo</mat-icon>
        </button>
      </ion-buttons>
      <ion-buttons slot="end">
        <button mat-icon-button matTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="Prévisualiser" (click)="previewNewsletter()">
          <mat-icon class="outlined">slideshow</mat-icon>
        </button>
      </ion-buttons>
      <ion-buttons slot="end">
        <button mat-icon-button matTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="Sauvegarder comme nouveau template" (click)="saveAsTemplate()">
          <mat-icon>save</mat-icon>
        </button>
      </ion-buttons>
      <ion-buttons slot="end">
        <button mat-icon-button matTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="Historiser" (click)="askHistorizeNewsletter()">
          <mat-icon>history</mat-icon>
        </button>
      </ion-buttons>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div>
    <!--Plugin containers -->
    <div #stripoSettingsContainer id="stripoSettingsContainer"></div>
    <div #stripoPreviewContainer id="stripoPreviewContainer"><div style="margin-top: 100px;text-align: center; font-size: 22px;">Choisissez une newsletter ou rendez-vous dans favoris pour en créer une nouvelle.</div></div>

  </div>
</ion-content>
