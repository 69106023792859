import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'fe-modal',
  templateUrl: './fe-modal.component.html',
  styleUrls: ['./fe-modal.component.scss']
})
export class FeModalComponent {
  protected title: string = 'Titre par défaut';
  protected text: string = 'Texte par défaut';
  protected inputValue: string = '';
  protected closeButton: boolean = true;
  public type: string = 'Information';
  @Output() confirm = new EventEmitter<any>();
  textareaLabel: string = '';


  constructor(public dialogRef: MatDialogRef<FeModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data != null) {
      this.title = data.title;
      this.text = data.text;
      this.type = data.type;
      this.inputValue = data.inputValue;

      if (data.textareaLabel != null) {
        this.textareaLabel = data.textareaLabel;
      }
    }
  }

  confirmBtnClick() {
    if (this.type == "Confirm")
      this.confirm.emit();
    else if (this.type == "Input" || this.type == "textarea") {
      this.confirm.emit(this.inputValue);
    }

    this.dialogRef.close();
  }
}
