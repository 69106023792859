import {Component, forwardRef, OnInit, OnDestroy, Inject, LOCALE_ID} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { XFieldComponent } from '../x-field/x-field.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormFieldInterface } from 'src/app/interfaces/form-field/form-field.interface';
import { takeUntil } from 'rxjs/operators';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {APP_DATE_FORMATS, AppDateAdapter} from './datepicker-format';

@Component({
  selector: 'x-input-date',
  templateUrl: 'x-input-date.component.html',
  styleUrls: ['./x-input-date.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => XInputDateComponent), multi: true },
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})
export class XInputDateComponent extends XFieldComponent implements OnInit {

  constructor(
    protected matSnackBar: MatSnackBar
      , @Inject(LOCALE_ID) public locale: string
  ) {
    super(matSnackBar);
  }

  public ngOnInit(): void {
    this.formControl.valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((value: any) => {
      this.formFieldValue = value;
      if (typeof this.formFieldValue === 'string') {
        this.formFieldValue = new Date(this.formFieldValue);
      }
    });

    this.formControl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  public onBlur(event: Event, value: any): void {}

  public onFocus($event: any, field: FormFieldInterface): void { }

}
