import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MonitoredArticleInterface} from '../../interfaces/monitored-articles/monitored-article.interface';
import {AlertController, ModalController} from '@ionic/angular';
import {ArticleMonitoringService} from '../../services/article-monitoring/article-monitoring.service';
import {SupervisorMonitoringModalComponent} from '../supervisor-monitoring-modal/supervisor-monitoring-modal.component';
import {MediaQueryService} from '../../services/media-query/media-query.service';
import {SessionInterface} from '../../interfaces/session/session.interface';
import {UserService} from '../../services/user/user.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import * as moment from 'moment';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {ArticleInterface} from '../../interfaces/articles/article.interface';
import {ReadingArticleModal} from '../modals/reading-article/reading-article.modal';
import {MonitoredArticleActivityInterface} from '../../interfaces/monitored-article-comments/monitored-article-activity.interface';
import {ReplaySubject} from 'rxjs';
import {MonitoredArticleAttachmentInterface} from '../../interfaces/monitored-article-attachments/monitored-article-attachment.interface';
import {ModalService} from '../../services/modal/modal.service';
import {FeArticleModalComponent} from '../modals/fe-article-modal/fe-article-modal.component';
import {SnackbarService} from "../../services/snackbar/snackbar.service";
import {ColorType, IconType} from "../parts/fe-icon/fe-icon.component";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";

@Component({
    selector: 'app-monitored-article',
    templateUrl: './monitored-article.component.html',
    styleUrls: ['./monitored-article.component.scss'],
})
export class MonitoredArticleComponent implements OnInit {
    @Input() monitoredArticle: MonitoredArticleInterface | null = null;
    @Input() showShareButton: Boolean;
    @Output() edit = new EventEmitter<MonitoredArticleInterface>();
    @Output() monitoringPriority = new EventEmitter<{ newPriority: string, oldPriority: string, articleId: number }>();
    @Output() monitoringIsArchived = new EventEmitter<MonitoredArticleInterface>();
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    public locale: any; // prevent build error
    public priorities = [];
    public statuses = [];
    public currentArticleAttributes;
    public isSupervisor: boolean;
    public currentIsUsualCustomer: boolean = false;
    public currentClosedDate: string;
    public currentReminderDate: string;
    public currentStatus: string;
    public currentAmount: string;
    public currentComment: string;
    public closeDateForm: FormGroup;
    public reminderDateForm: FormGroup;
    private customerToken: String;
    public listAssociatedCustomers: { user_id: string, name: string }[] = [];
    public monitoringParams = {};
    public nbComments: number;
    public timeToolTip: String;
    public reminderToolTip: String;
    public shareArticleToolTip: String;
    public minDate = new Date();
    protected clotureDateLabel: string = 'Date de clôture';
    protected reminderDateLabel: string = 'Date de rappel';

    constructor(private articleMonitoringService: ArticleMonitoringService,
                public mediaQueryService: MediaQueryService,
                private userService: UserService,
                private datePipe: DatePipe,
                private fb: FormBuilder,
                private _modalService: ModalService,
                private snackBarService: SnackbarService,) {
    }

    ngOnInit() {
        if (this.monitoredArticle.associatedCustomersRealName !== undefined) {
            for (let i = 0; i < this.monitoredArticle.associatedCustomersRealName.length; i++) {
                const index = this.listAssociatedCustomers.findIndex(object => object.user_id === this.monitoredArticle.associatedCustomers[i]);
                if (index === -1) {
                    this.listAssociatedCustomers.push({
                        user_id: this.monitoredArticle.associatedCustomers[i],
                        name: this.monitoredArticle.associatedCustomersRealName[i]
                    });
                }
            }
        }
        if (this.monitoredArticle != null) {
            this.currentArticleAttributes = {
                articleId: this.monitoredArticle.id,
                monitoringId: this.monitoredArticle.monitoringId,
                articleMonitoringPosition: this.monitoredArticle.position,
                isArchived: this.monitoredArticle.isArchived,
                monitoringPriority: this.monitoredArticle.priority.valueOf(),
                monitoringStatus: this.monitoredArticle.status,
                timeInToProcess: this.monitoredArticle.timeInToProcess,
                timeInProcessing: this.monitoredArticle.timeInProcessing,
                timeInQualified: this.monitoredArticle.timeInQualified,
                timeInClosed: this.monitoredArticle.timeInClosed
            };

            this.timeToolTip = 'Temps passé'
                + '\nÀ traiter : ' + (this.monitoredArticle.timeInToProcess != null ? this.monitoredArticle.timeInToProcess : '0 jour')
                + '\nEn cours : ' + (this.monitoredArticle.timeInProcessing != null ? this.monitoredArticle.timeInProcessing : '0 jour')
                + '\nQualifié : ' + (this.monitoredArticle.timeInQualified != null ? this.monitoredArticle.timeInQualified : '0 jour')
                + '\nClôturé : ' + (this.monitoredArticle.timeInClosed != null ? this.monitoredArticle.timeInClosed : '0 jour');

            if (this.monitoredArticle.monitoringMetadata.closeDate !== null) {
                const momentVariable = moment(this.monitoredArticle.monitoringMetadata.closeDate, 'DD/MM/YYYY');
                const dateTransform = momentVariable.format('MM/DD/YYYY');
                const date = new Date(dateTransform);
                this.closeDateForm = this.fb.group({
                    currentClosedDate: [date]
                });
                this.clotureDateLabel = dateTransform;
            } else {
                this.closeDateForm = this.fb.group({
                    currentClosedDate: [undefined]
                });
            }

            if (this.monitoredArticle.monitoringMetadata.reminderDate !== null) {
                this.reminderToolTip = 'Rappel prévu le ' + this.monitoredArticle.monitoringMetadata.reminderDate;

                const momentVariable = moment(this.monitoredArticle.monitoringMetadata.reminderDate, 'DD/MM/YYYY');
                const dateTransform = momentVariable.format('MM/DD/YYYY');
                const date = new Date(dateTransform);
                this.reminderDateForm = this.fb.group({
                    currentReminderDate: [date]
                });

                this.reminderDateLabel = dateTransform;
            } else {
                this.reminderDateForm = this.fb.group({
                    currentReminderDate: [undefined]
                });
            }

            this.currentIsUsualCustomer = this.monitoredArticle.monitoringMetadata.isUsualCustomer;
            this.currentStatus = this.monitoredArticle.monitoringMetadata.contractStatus;
            this.currentAmount = this.monitoredArticle.monitoringMetadata.amount;
            this.currentComment = this.monitoredArticle.monitoringMetadata.comment;
        }
        this.userService.getSessionDatas()
            .subscribe(
                (sessionData: SessionInterface) => {
                    if (sessionData !== undefined) {
                        if (sessionData.customerDetails.isSupervisor !== null) {
                            this.isSupervisor = sessionData.customerDetails.isSupervisor;
                            this.customerToken = sessionData.customerDetails.user_id;
                        }
                    }
                }
            );
        if (this.listAssociatedCustomers !== undefined) {
            if (this.listAssociatedCustomers.length > 0) {
                this.shareArticleToolTip = 'Article suivi par :\n';
                for (let i = 0; i < this.listAssociatedCustomers.length; i++) {
                    this.shareArticleToolTip += this.listAssociatedCustomers[i].name + '\n';
                }
            }
        }
        if (this.monitoredArticle.listPropertiesAvailable !== undefined) {
            this.priorities = this.monitoredArticle.listPropertiesAvailable;
        }
        if (this.monitoredArticle.listStatusAvailable !== undefined) {
            this.statuses = this.monitoredArticle.listStatusAvailable;
        }

        this.monitoringParams = {
            articleId: '' + this.monitoredArticle.id + '',
            articleMonitoringId: '' + this.monitoredArticle.monitoringId + ''
        };
        if (this.monitoredArticle.monitoringActivities !== undefined) {
            this.nbComments = this.monitoredArticle.monitoringActivities.filter(a => a.activityType === 'comment')?.length;
        }
    }


    /**
     * Recharge des activités
     * @param params Params de monitoring
     */
    private reloadActivities(params): void {
        this.articleMonitoringService.getArticleActivities(params).subscribe((data: Array<MonitoredArticleActivityInterface>) => {
            this.monitoredArticle.monitoringActivities = data;
            if (this.monitoredArticle.monitoringActivities !== undefined) {
                this.nbComments = this.monitoredArticle.monitoringActivities.filter(a => a.activityType === 'comment')?.length;
            }
        });
    }

    /**
     * Recharge des PJ
     * @param params Params de monitoring
     */
    private reloadAttachments(params): void {
        this.articleMonitoringService.getArticleAttachments(params).subscribe((data: Array<MonitoredArticleAttachmentInterface>) => {
            this.monitoredArticle.monitoringAttachments = data;
        });
    }

    updatePriority(val: any) {
        if (val !== this.currentArticleAttributes.monitoringPriority) {
            const oldMonitoringPriority = this.currentArticleAttributes.monitoringPriority;
            this.currentArticleAttributes.monitoringPriority = val;
            // this.monitoredArticle.priority = val;
            this.articleMonitoringService.updateMonitoringArticles(this.currentArticleAttributes).subscribe(articleMonitoringAfterUpdate => {
                this.monitoringPriority.emit({
                    newPriority: articleMonitoringAfterUpdate.body.monitoringPriority, oldPriority: oldMonitoringPriority,
                    articleId: this.currentArticleAttributes.articleId
                });
            });
        }

    }
    private troncateText(text: string, length: number): string {
        let troncateText: string = text;
        if (troncateText.length > length + 2) {
            troncateText = troncateText.substring(0, length) + '...';
        }
        return troncateText;
    }

    public async addCollaborators(monitoredArticle: MonitoredArticleInterface) {
        if (!monitoredArticle.isVisualisation) {
            this._modalService.showCustomModalWithActionOnClose(SupervisorMonitoringModalComponent, { data: {
                    article: monitoredArticle,
                    isSupervisor: this.isSupervisor,
                    isFromMonitoringView: true,
                    listAssociatedCustomers: this.listAssociatedCustomers
                }}, (data) => {
                if (data !== undefined) {
                    this.snackBarService.showSuccessSnackbar('Partage d\'affaire',
                        'L\'article <b>\'' + this.troncateText(this.monitoredArticle.title, 50)
                        + '\'</b> a bien été partagé.');

                    for (let i = 0; i < data.customersSelected.length; i++) {
                        const isInArray = this.listAssociatedCustomers.findIndex(obj => obj.user_id === data.customersSelected[i].user_id);
                        if (isInArray === -1) {
                            this.listAssociatedCustomers.push({
                                user_id: data.customersSelected[i].user_id,
                                name: data.customersSelected[i].name
                            });
                            // Mise à jour liste des clients associées
                            if (this.monitoredArticle.associatedCustomers === undefined || this.monitoredArticle.associatedCustomers === null) {
                                this.monitoredArticle.associatedCustomers = [];
                                this.monitoredArticle.associatedCustomersRealName = [];
                            }
                            this.monitoredArticle.associatedCustomers.push(data.customersSelected[i].user_id);
                            this.monitoredArticle.associatedCustomersRealName.push(data.customersSelected[i].name);
                            if (this.shareArticleToolTip === undefined) {
                                this.shareArticleToolTip = 'Article suivi par :\n';
                                this.shareArticleToolTip += data.customersSelected[i].name + '\n';
                            } else {
                                this.shareArticleToolTip += data.customersSelected[i].name + '\n';
                            }
                        }
                    }
                }
            });
        }
    }

    public archiveArticle(monitoredArticle): void {
        if (this.currentArticleAttributes.isArchived === false) {
            this.currentArticleAttributes.isArchived = true;
            this.articleMonitoringService.updateMonitoringArticles(this.currentArticleAttributes).subscribe();
        } else {
            this.currentArticleAttributes.isArchived = false;
            this.articleMonitoringService.updateMonitoringArticles(this.currentArticleAttributes).subscribe();
        }
    }


    public addMetadataToMonitoringArticle(monitoredArticle: MonitoredArticleInterface,
                                          currentIsUsualCustomer: boolean,
                                          currentClosedDate: Date,
                                          currentReminderDate: Date,
                                          currentStatus: String,
                                          currentAmout: String,
                                          currentComment: String) {
        try {
            this.currentAmount = this.currentAmount.trim();
        } catch (e) {
        }

        try {
            currentAmout = currentAmout.trim();
        } catch (e) {
        }

        const publicationDate = this.datePipe.transform(currentClosedDate, 'dd/MM/yyyy');
        const reminderDate = this.datePipe.transform(currentReminderDate, 'dd/MM/yyyy');
        this.reminderToolTip = 'Rappel prévu le ' + reminderDate;
        const monitoringMetadata = {
            amount: currentAmout,
            closeDate: publicationDate,
            reminderDate: reminderDate,
            contractStatus: currentStatus,
            isUsualCustomer: currentIsUsualCustomer,
            comment: currentComment
        };

        this.articleMonitoringService.addMetadataToMonitoringArticle(monitoredArticle.id, monitoringMetadata).subscribe(
            objet => {
                this.monitoredArticle.monitoringMetadata.amount = this.currentAmount;
                this.monitoredArticle.monitoringMetadata.amountFormated = objet.body.amountFormated;
                this.monitoredArticle.monitoringMetadata.contractStatus = this.currentStatus;
                this.monitoredArticle.monitoringMetadata.isUsualCustomer = this.currentIsUsualCustomer;
                this.monitoredArticle.monitoringMetadata.comment = this.currentComment;
                this.monitoredArticle.monitoringMetadata.closeDate = publicationDate;
                this.monitoredArticle.monitoringMetadata.reminderDate = reminderDate;
                this.trigger.closeMenu();
            });
    }
    protected openModal(article: ArticleInterface, fromComment: boolean) {
        this._modalService.showCustomModalWithActionOnClose(FeArticleModalComponent, {
            data: {
                article: article,
                keywords: '',
                fromFavorite: false,
                from: 'monitoring',
                monitoredArticle: this.monitoredArticle,
                isVisualisation: article.monitoring.isVisualisation,
                fromComment: fromComment,
            }}, (data) => {
            this.closeReadingModalAction(data);
        });
    }

    protected closeReadingModalAction(data) {
        if (data !== undefined) {
            this.monitoredArticle.newAttachments = false;
            this.monitoredArticle.newActivities = false;

            if (data.article !== undefined && data.article.monitoring !== undefined) {
                this.monitoredArticle.isArchived = data.article.monitoring.isArchived;
                this.monitoredArticle.title = data.article.title;
                this.monitoredArticle.article.corps = data.article.corps;
                if (data.article.publicationDate !== undefined) {
                    this.monitoredArticle.article.publicationDate = data.article.publicationDate;
                }
                if (data.isArchived !== undefined) {
                    this.monitoringIsArchived.emit(this.monitoredArticle);
                }
            }
        }
        this.reloadActivities(this.monitoringParams);
        this.reloadAttachments(this.monitoringParams);
    }

    public async openReadingModal(article: ArticleInterface): Promise<void> {
        this.openModal(article, null);
    }

    protected getClotureDateLabel(): string {
        let clotureDateLabel: string = this.closeDateForm.get('currentClosedDate').value
        if (clotureDateLabel == null || clotureDateLabel.length < 1) {
            clotureDateLabel = 'Date de clôture';
        } else {
            // const year = this.closeDateForm.getFullYear();
            // const month = this.startDatePicker.getMonth() + 1;
            // const day = this.startDatePicker.getDate().toString();
            // this.startDate = year + '-' + month + '-' + day;
        }
        return clotureDateLabel;
    }

    private getDateFormat(day, month, year: number): string {
        let dateToReturn = '';
        if (day < 10) {
            dateToReturn = '0';
        }
        dateToReturn += day + '/';
        if (month < 10) {
            dateToReturn += '0';
        }
        dateToReturn += month + '/' + year;
        return dateToReturn;
    }

    public async openCommentsModal(article: ArticleInterface): Promise<void> {
        this.openModal(article, true);
        // const modal = await this.modalController.create({
        //     component: ReadingArticleModal,
        //     cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog',
        //     componentProps: {
        //         article: article,
        //         articleMonitoringId: this.monitoredArticle.monitoringId,
        //         from: 'monitoring', // we came from monitoring. Not favorites or articles.
        //         fromMonitoring: true,
        //         isVisualisation: article.monitoring.isVisualisation,
        //         fromComment: true,
        //         monitoredArticle: this.monitoredArticle
        //     },
        //     backdropDismiss: true
        // });
        // modal.componentProps;
        // modal.onDidDismiss()
        //     .then(
        //         (data: any) => {
        //             this.monitoredArticle.newAttachments = false;
        //             this.monitoredArticle.newActivities = false;
        //
        //             if (data.data !== undefined) {
        //                 if (data.data.article !== undefined && data.data.article.monitoring !== undefined) {
        //                     this.monitoredArticle.isArchived = data.data.article.monitoring.isArchived;
        //                     if (data.data.isArchived) {
        //                         this.monitoringIsArchived.emit(this.monitoredArticle);
        //                     }
        //                 }
        //                 if (data.data.commentAddedOrDeleted) {
        //                     this.reloadActivities(this.monitoringParams);
        //                 }
        //                 if (data.data.attachmentAddedOrDeleted) {
        //                     this.reloadAttachments(this.monitoringParams);
        //                 }
        //             } else {
        //                 this.reloadActivities(this.monitoringParams);
        //                 this.reloadAttachments(this.monitoringParams);
        //             }
        //         }
        //     );
        // return await modal.present();
    }

    public async openAttachmentsModal(article: ArticleInterface): Promise<void> {
        this.openModal(article, false);
        // const modal = await this.modalController.create({
        //     component: ReadingArticleModal,
        //     cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog',
        //     componentProps: {
        //         article: article,
        //         articleMonitoringId: this.monitoredArticle.monitoringId,
        //         from: 'monitoring', // we came from monitoring. Not favorites or articles.
        //         fromMonitoring: true,
        //         isVisualisation: article.monitoring.isVisualisation,
        //         fromComment: false,
        //         monitoredArticle: this.monitoredArticle
        //     },
        //     backdropDismiss: true
        // });
        // modal.onDidDismiss()
        //     .then(
        //         (data: any) => {
        //             this.monitoredArticle.newAttachments = false;
        //             this.monitoredArticle.newActivities = false;
        //
        //             if (data.data !== undefined) {
        //                 if (data.data.article !== undefined && data.data.article.monitoring !== undefined) {
        //                     this.monitoredArticle.isArchived = data.data.article.monitoring.isArchived;
        //                     if (data.data.isArchived) {
        //                         this.monitoringIsArchived.emit(this.monitoredArticle);
        //                     }
        //                 }
        //                 if (data.data.commentAddedOrDeleted) {
        //                     this.reloadActivities(this.monitoringParams);
        //                 }
        //                 if (data.data.attachmentAddedOrDeleted) {
        //                     this.reloadAttachments(this.monitoringParams);
        //                 }
        //             } else {
        //                 this.reloadActivities(this.monitoringParams);
        //                 this.reloadAttachments(this.monitoringParams);
        //             }
        //         }
        //     );
        // return await modal.present();
    }

    protected readonly ColorType = ColorType;
    protected readonly IconType = IconType;

    protected updateClotureDate($event: MatDatepickerInputEvent<any, any>): void {
        if ($event.value != null) {
            this.clotureDateLabel = this.dateToString(new Date($event.value));
        } else {
            this.clotureDateLabel = 'Date de clôture';
        }
    }

    private dateToString(date: Date): string {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate().toString();
        return this.getDateFormat(day, month, year);
    }

    updateReminderDate($event: MatDatepickerInputEvent<any, any>) {
        if ($event.value != null) {
            let date: Date = new Date($event.value);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate().toString();
            this.reminderDateLabel = this.getDateFormat(day, month, year);
        } else {
            this.reminderDateLabel = 'Date de rappel';
        }
    }

    protected clearClotureDate(): void {
        this.clotureDateLabel = 'Date de clôture';
        this.closeDateForm = this.fb.group({
            currentClosedDate: null
        });
    }

    protected clearReminderDate(): void {
        this.reminderDateLabel = 'Date de rappel';
        this.reminderDateForm = this.fb.group({
            currentReminderDate: null
        });
    }
}
