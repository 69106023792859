import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { Printer, PrintOptions } from '@awesome-cordova-plugins/printer/ngx';
import { ToastService } from 'src/app/services/toast/toast.service';
import { Platform } from '@ionic/angular';
import { ArticlesService } from 'src/app/services/articles/articles.service';
import { takeUntil } from 'rxjs/operators';
import { PrintInterface } from 'src/app/interfaces/print/print.interface';
import {UserService} from '../../../services/user/user.service';

@Component({
  selector: 'article-print-button',
  templateUrl: 'article-print-button.component.html',
  styleUrls: ['article-print-button.component.scss'],
})
export class ArticlePrintButtonComponent implements OnInit, OnDestroy {

  @Input() public selectionModel: SelectionModel<string>;
  @Input() public isFromActionList: boolean;

  private unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private printer: Printer
    , private platform: Platform
    , private toastService: ToastService
    , private articlesService: ArticlesService
    , private userService: UserService
  ) {}

  public ngOnInit(): void {

  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  printDocument(selectionModel: any) {
    this.articlesService.printArticlesList(selectionModel).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      (res: PrintInterface) => {
        // Mobile printing
        if (this.platform.is('ios') || this.platform.is('android')) {
          this.printer.isAvailable()
            .then(onSuccess => {
            },
              onError => {
              }
            );

          const options: PrintOptions = {
            name: 'FirstECO_Profil_print',
            orientation: 'portrait'
          };

          this.printer.print(res.html, options)
            .then(onSuccess => {
            },
              onError => {
              }
            );
        }
        // Desktop printing
        else {
          const popupWin = window.open('', '_blank');
          popupWin.document.open();
          popupWin.document.write(res.html);
          setTimeout(() => popupWin.print(), 1000);
        }
      }
    );
  }
}
