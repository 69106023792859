<ion-header no-border>
  <dates-selector-button #dateSelector style="display:none;" >
<!--    TODO Penser à le dégager à terme, pour le moment il est en display none-->
  </dates-selector-button>

</ion-header>
<ion-content #scrollArea scrollEvents="true">

  <mat-sidenav-container #sidenavContainer autosize="true">
<!--    [@.disabled]="true" autosize>-->
  <mat-sidenav #snav2 style="overflow: visible;" class="left-side-bar"
               [ngClass]="{'small': smallSidenavDesktop && !mediaQueryService.mobileQuery.matches,
                  'mobile': mediaQueryService.mobileQuery.matches}" mode="side" opened disableClose="true">
    <div [ngClass]="{'smallBtnContainer': smallSidenavDesktop}" style="text-align: right; width: 100%;">
      <fe-button (click)="toogleSidenavSize()" [ngClass]="{'small': smallSidenavDesktop}" type="TERTIARY"
                 color="PRIMARY" bold="true" icon="true" [iconType]="IconType.menu_open" [hideText]="smallSidenavDesktop"
                 text="Réduire" iconDirection="LEFT" [reverseIcon]="smallSidenavDesktop" [class.leftSideBarBtn]="!smallSidenavDesktop"
                 bigIcon="true" noUnderline="true"></fe-button>
    </div>
    <search-list-container class="search-list-container" #searchListContainer [searchList]="searchList"
                           (emitter)="changeSearchSelection($event)" [isSmallMode]="smallSidenavDesktop"/>
  </mat-sidenav>
    <mat-sidenav-content style="display: flex; flex-direction: column" [ngClass]="{'hideCriterions' : !noSearches}">
      <div style="width: fit-content; margin: auto; margin-top: 25%;">
        <mat-label style="font-weight: bold; font-size: 18px; color: #003F51;">Vous n'avez pas encore de recherches enregistrées sur votre plateforme.</mat-label>
        <fe-button style="width: fit-content; display: block; margin: auto; margin-top: 20px;" type="PRIMARY" color="PRIMARY" bold="true" [text]="'Créer une nouvelle recherche'" (click)="createNewSearch()"></fe-button>
      </div>
    </mat-sidenav-content>
  <mat-sidenav-content style="display: flex; flex-direction: column" [ngClass]="{'hideCriterions' : noSearches}">
    <ion-toolbar style="z-index: 1;">
      <div class="toolbarRecherche">

        <div class="divTitre">

          <div class="divTitreLine" style="margin: 12px 0 16px;">
            <span class="titre">{{currentSearch?.data.label}}</span>

<!--            <button mat-icon-button class="dots-button tourBtnTour" (click)="launchIntroFeatureTour(1);">-->
<!--              <mat-icon>help_center</mat-icon>-->
<!--            </button>-->

            <fe-button color="PRIMARY" hideText="true" icon="true" type="TERTIARY" [iconType]="IconType.tour" class="tourBtnTour"
                       matTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="Guide sur signaux business" (click)="launchIntroFeatureTour(1);" />

            <manage-searches [search]="currentSearch" [currentIndex]="selectedIndex" [searchList]="searchList"
                             [fromMenu]="false" class="dotsMenuSearchBtnTour" style="margin: auto 0;"/>
          </div>

          <div class="divTitreLine divSearchActionTour" style="height: 50px;">
            <div class="divActions" style="position: relative">
                <span class="numberArticleSelected" [ngClass]="{'noArticlesSelected':
                this.selectionModel.selected.length === 0}">{{ this.selectionModel.selected.length }}
                  sélectionné{{ this.selectionModel.selected.length > 1 ? 's' : ''}}</span>
              <button class="multipleActionBtn2" [class.active]="this.selectionModel.selected.length > 0">
                <mat-checkbox *ngIf="!mediaQueryService.mobileQuery.matches"
                              (change)="checkAllArticles($event)" [checked]="allArticlesChecked()"
                              [indeterminate]="this.selectionModel.selected.length > 0 && !allArticlesChecked()"/>
                <mat-icon (click)="onDropDownMenuOpenActions($event)" [matMenuTriggerFor]="menu4">
                  {{menuIconActions}}
                </mat-icon>
              </button>
            </div>

            <div style="display: flex;">
              <toggle-list-style style="border-radius: 8px;padding: 6px; margin: auto; height: fit-content;" [mapShowed]="stype === 'business_signal'"
                                 (listStyleChanged)="listStyleChanged($event)"/>
              <button class="dropdownBtn" (click)="onDropDownMenuOpenTri($event)" [matMenuTriggerFor]="menu2"
                      [matTooltip]="'Tri : ' + modeTri">
                <fe-icon size="14" style="margin-right: 4px" [iconType]="IconType.sorting_desc"
                         [color]="ColorType.grey"/>
                <span class="notSmallDesktop">{{modeTri}}</span>
                <mat-icon>{{menuIconTri}}</mat-icon>
              </button>

              <button style="margin-left: 6px;" class="dropdownBtn" [matMenuTriggerFor]="menuResultFilter"
                      [matTooltip]="'Filtre : '+ getResultFilterLabel(resultFilter)"
                      (click)="onDropDownMenuOpenFiltre($event)">
                <fe-icon size="14" style="margin-right: 4px" [iconType]="IconType.filter"
                         [color]="ColorType.grey"/>
                <span class="notSmallDesktop">{{getResultFilterLabel(resultFilter)}}</span>
                <mat-icon>{{menuIconFiltre}}</mat-icon>
              </button>
            </div>

            <!-- on desktop, this component will be placed in absolute, top:-500px... -->
            <mat-paginator [pageSizeOptions]="pageSizeOptions" class="paginator articlesPaginator"
                           [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches, 'noData': tableDataSource.data.length === 0}"
                           [length]="articlesCount" [pageIndex]="_page" [pageSize]="_pageSize">
<!--                           (page)="loadingInProgress = true;">-->
            </mat-paginator>
            <!--      -->
          </div>

<!--          <div class="divTitreLine smallDesktop" style="height: 50px;">-->
<!--            <div style="display: flex; margin: auto 12px auto auto;">-->
<!--              <toggle-list-style style="border-radius: 8px;padding: 6px; margin: auto; height: fit-content;" [mapShowed]="stype === 'business_signal'" matTooltip="Mode d'affichage"-->
<!--                                 (listStyleChanged)="listStyleChanged($event)"></toggle-list-style>-->
<!--              <button class="dropdownBtn" (click)="onDropDownMenuOpenTri($event)" [matMenuTriggerFor]="menu2"-->
<!--                      matTooltip="Mode de tri">-->
<!--                <fe-icon size="14" style="margin-right: 4px" [iconType]="IconType.sorting_desc"-->
<!--                         [color]="ColorType.grey"/>{{modeTri}}-->
<!--                <mat-icon>{{menuIconTri}}</mat-icon>-->
<!--              </button>-->

<!--              <button style="margin-left: 6px;" class="dropdownBtn" [matMenuTriggerFor]="menuResultFilter"-->
<!--                      matTooltip="Filtrage" (click)="onDropDownMenuOpenFiltre($event)">-->
<!--                <fe-icon size="14" style="margin-right: 4px" [iconType]="IconType.filter"-->
<!--                         [color]="ColorType.grey"/>{{getResultFilterLabel(resultFilter)}}-->
<!--                <mat-icon>{{menuIconFiltre}}</mat-icon>-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
        </div>

        <mat-menu class="menuTri" style="position: absolute" #menuResultFilter="matMenu" (close)="onMenuFiltreClosed()">
          <fe-menu-item (click)="onFilterChange('default')"
                        color="PRIMARY" text="Aucun filtre" [icon]="IconType.title"
                        [class.selectedOption]="resultFilter == 'default'"/>
          <fe-menu-item *ngIf="isPerformance" (click)="onFilterChange('isMonitored')" color="PRIMARY"
                        text="Articles suivis" [icon]="IconType.dashboard_full"
                        [class.selectedOption]="resultFilter == 'isMonitored'"/>
          <fe-menu-item *ngIf="isPerformance" (click)="onFilterChange('isNotMonitored')" color="PRIMARY"
                        text="Articles non suivis" [icon]="IconType.dashboard"
                        [class.selectedOption]="resultFilter == 'isNotMonitored'"/>
          <fe-menu-item (click)="onFilterChange('isFavorite')" color="PRIMARY"
                        text="Articles favoris" [icon]="IconType.star_full"
                        [class.selectedOption]="resultFilter == 'isFavorite'"/>
          <fe-menu-item (click)="onFilterChange('isNotFavorite')" color="PRIMARY"
                        text="Articles non favoris" [icon]="IconType.star"
                        [class.selectedOption]="resultFilter == 'isNotFavorite'"/>
          <fe-menu-item (click)="onFilterChange('isRead')" color="PRIMARY"
                        text="Articles lus" [icon]="IconType.eye_close"
                        [class.selectedOption]="resultFilter == 'isRead'"/>
          <fe-menu-item (click)="onFilterChange('isNotRead')" color="PRIMARY"
                        text="Articles non lus" [icon]="IconType.eye_open"
                        [class.selectedOption]="resultFilter == 'isNotRead'"/>
        </mat-menu>

        <mat-menu class="menuTri" style="position: absolute" #menu2="matMenu" (close)="onMenuTriClosed()">
          <fe-menu-item (click)="onSortingOptionChange('sortByRelevance','pertinence')"
                        color="PRIMARY" text="Tri par pertinence" [icon]="IconType.pertinence"
                        [class.selectedOption]="_sortingOption == 'sortByRelevance'"/>
          <fe-menu-item (click)="onSortingOptionChange('sortByDateAsc','date croissante')" color="PRIMARY"
                        text="Tri par date croissante" [icon]="IconType.date_croissante"
                        [class.selectedOption]="_sortingOption == 'sortByDate' && articlesSortingMethod == 'publicationDateAsc'"/>
          <fe-menu-item (click)="onSortingOptionChange('sortByDateDesc','date décroissante')" color="PRIMARY"
                        text="Tri par date décroissante" [icon]="IconType.date_decroissante"
                        [class.selectedOption]="_sortingOption == 'sortByDate' && articlesSortingMethod == 'publicationDateDesc'"/>
        </mat-menu>

        <mat-menu style="position: absolute;" class="menuArticleAction"
                  (close)="onMenuActionsClosed()"  #menu4="matMenu">
          <fe-menu-item (click)="addArticlesToFavorite()" color="PRIMARY" text="Ajouter en favoris" [icon]="IconType.star"/>
          <fe-menu-item (click)="openEmailModal()" color="PRIMARY" text="Envoyer par email" [icon]="IconType.send_mail"/>
          <fe-menu-item (click)="markAsRead(true)" color="PRIMARY" text="Marquer comme lu" [icon]="IconType.eye_close"/>
          <fe-menu-item (click)="markAsRead(false)" color="PRIMARY" text="Marquer comme non-lu" [icon]="IconType.eye_open"/>
          <fe-menu-item (click)="exportArticlesList('excel', selectionModel.selected)" color="PRIMARY"
                        text="Exporter sous format Excel"  [icon]="IconType.excel"/>
          <fe-menu-item (click)="exportArticlesList('csv', selectionModel.selected)" color="PRIMARY"
                        text="Exporter sous format CSV" [icon]="IconType.csv"/>
          <fe-menu-item (click)="printArticles()" color="PRIMARY" text="Imprimer" [icon]="IconType.print"/>
        </mat-menu>
      </div>
    </ion-toolbar>
    <div class="listMapContainer"
    [ngClass]="{
      'mobile': mediaQueryService.mobileQuery.matches,
      'desktop': !mediaQueryService.mobileQuery.matches,
      'mapActivated': mediaQueryService.mobileQuery.matches && listStyle == 'map',
      'listActivated': mediaQueryService.mobileQuery.matches && !(listStyle == 'map')}">

      <mat-list class="paginated" style="display: flex; flex-direction: column;">
        <!-- loadingInProgress and mediaQueryService is declared in PaginatedListComponent -->
<!--        <loading-overlay *ngIf="!loadingInProgress">Chargement des articles</loading-overlay>-->
        <fe-loader *ngIf="loadingInProgress" loaderText="Chargement des articles"/>
        <no-data *ngIf="!loadingInProgress && tableDataSource.data.length < 1">Aucun article disponible</no-data>

        <div class="articlesContainer" *ngIf="!loadingInProgress && tableDataSource.data.length > 0">
          <fe-article-item [_keywords]="getKeywordsSearched()" [style]="listStyle" [article]="article"
                           [selectionModel]="selectionModel" *ngFor="let article of tableDataSource.data;"
                           [hasSectorsInSearch]="hasSectorsInSearch" [hasTopicsInSearch]="hasTopicsInSearch"
                           [hasLeadsInSearch]="hasLeadsInSearch"/>
        </div>

        <!-- Only on mobile -->
        <!-- onInfiniteScroll is declared in PaginatedListComponent -->
        <ion-infinite-scroll threshold="100px" (ionInfinite)="onInfiniteScroll($event)"
          *ngIf="mediaQueryService.mobileQuery.matches">
          <ion-infinite-scroll-content loadingSpinner="null">
            <loading *ngIf="loadingInProgress" class="infiniteScrollLoading">{{"LOADING_ARTICLES"}}</loading>
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>

        <button class="mat-rounded-button small" color="primary" mat-raised-button
          *ngIf="!hideLoadMoreButton && mediaQueryService.mobileQuery.matches" (click)="loadMoreContentButton()">
          <mat-icon>sync</mat-icon>
          {{"LOAD_MORE_CONTENT"}}
        </button>
      </mat-list>
<!--      <google-map *ngIf="(apiLoaded | async) && (listStyle == 'listmap' || listStyle == 'map')">-->
        <google-map *ngIf="(listStyle == 'listmap' || listStyle == 'map')"
                    [center]="mapCenter"
                    [zoom]="zoom"
                    (mapClick)="mapClick()">

          <map-marker-clusterer [calculator]="getClusterStyle" imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
            <ng-container *ngFor="let marker of markers; let i = index">
              <map-marker #mapMarker="mapMarker" [options]="marker.markerOption" (mapClick)="openInfoWindow(mapMarker, infoWindow)"></map-marker>
              <map-info-window #infoWindow="mapInfoWindow">
                <div class="listDate greyColor map-modal-text">{{marker.data.publicationDate | date:'dd/LL/yyyy':'':locale}}</div>
                <div style="display: block;margin:10px;font-size: 14px;cursor: pointer;" class="listTitle map-modal-text" onclick="return false;" (click)="openReadingModalFromMarker(marker.data)">{{marker.data.title}}</div>
              </map-info-window>
            </ng-container>
          </map-marker-clusterer>
        </google-map>
  <!--    <agm-map-->
  <!--            *ngIf="listStyle == 'listmap' || listStyle == 'map'"-->
  <!--            [latitude]="mapCenterLat"-->
  <!--            [longitude]="mapCenterLng"-->
  <!--            [zoom]="zoom"-->
  <!--            (mapClick)="mapClick()">-->
  <!--      <agm-marker-cluster imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m" [calculator]="getClusterStyle">-->
  <!--        <agm-marker *ngFor="let marker of markers; let i = index" [animation]="marker.animation"-->
  <!--                    [iconUrl]="marker.icon" [latitude]="marker.lat" [longitude]="marker.lng" [label]="marker.label"-->
  <!--                    [markerDraggable]="marker.draggable" >-->
  <!--        -->
  <!--          <agm-info-window #iw>-->

  <!--            <a href="#0" style="display: block;margin:10px;" class="listTitle map-modal-text" onclick="return false;" (click)="openReadingModalFromMarker(marker.data)">{{marker.data.contacts[0].organisationName}}</a>-->
  <!--          </agm-info-window>-->
  <!--      -->
  <!--        </agm-marker>-->
  <!--    -->
  <!--      </agm-marker-cluster>-->
  <!--      <agm-marker [latitude]="lat" [longitude]="lng" [iconUrl]="myPostionIcon">-->
  <!--      </agm-marker>-->
  <!--    </agm-map>-->
  <!--    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">-->
  <!--      <p style="color: white">Chargement en cours. </p>-->
  <!--    </ngx-spinner>-->
    </div>
  </mat-sidenav-content>
  <mat-sidenav position="end" mode="side" opened class="right-side-bar" disableClose="true"
               [ngClass]="{'small': smallRightSidenavDesktop && !mediaQueryService.mobileQuery.matches,
                  'mobile': mediaQueryService.mobileQuery.matches}">
    <div [ngClass]="{'smallBtnContainer': smallRightSidenavDesktop}" class="rightBtnContainer">
      <fe-button (click)="rightsidenav()"
                 [ngClass]="{'small': smallRightSidenavDesktop}" type="TERTIARY"
                 color="PRIMARY" bold="true" icon="true" [iconType]="IconType.menu_open" [hideText]="smallRightSidenavDesktop"
                 text="Réduire" iconDirection="LEFT" [reverseIcon]="!smallRightSidenavDesktop"
                 bigIcon="true" noUnderline="true"></fe-button>
      <fe-button *ngIf="!smallRightSidenavDesktop" style="margin: 0 8px 0 auto" text="Modifier ma recherche" class="editSearchBtnTour"
                 type="SECONDARY" smallButton="true" bold="true" (click)="editSearch(currentSearch)"/>
    </div>
    <app-search-selected-criterions [class.displayNone]="smallRightSidenavDesktop" (emitter)="changeSelectedCriterion($event)"
                                    [type]="'business'" class="search-selected-criterions-container"
                                    criteresText="Filtrer les résultats" showPrecisions="true"
                                    [loadingInProgress]="loadingInProgress"/>
<!--    <fe-loader *ngIf="loadingInProgress" isOverflowing="true"/>-->
    <!--        </div>-->
  </mat-sidenav>
  </mat-sidenav-container>
</ion-content>


