import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {CriterionInterface} from '../../../../interfaces/criterions/criterion.interface';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {SnackbarService} from "../../../../services/snackbar/snackbar.service";
import {
  FeContactCommercialModalComponent
} from "../../../modals/fe-contact-commercial-modal/fe-contact-commercial-modal.component";
import {ModalService} from "../../../../services/modal/modal.service";
import {UserService} from "../../../../services/user/user.service";

@Component({
  selector: 'app-criterion-item-panel',
  templateUrl: './criterion-item-panel.component.html',
  styleUrls: ['./criterion-item-panel.component.scss']
})
export class CriterionItemPanelComponent implements OnInit {
  public _criterion: CriterionInterface;
  public _parentCriterion: CriterionInterface;
  public _filter: string;
  public _criterionType: string;
  public leadCriterions: {past: CriterionInterface, present: CriterionInterface, future: CriterionInterface, lead: CriterionInterface};

  @Output() emitter = new EventEmitter();
  @ViewChildren(CriterionItemPanelComponent) criterionItemPanels: QueryList<CriterionItemPanelComponent>;
  expanded: boolean;
  filtered: boolean;
  public _leadsAvailable: boolean;

  @Input() public set leadsAvailable(value: boolean) {
    this._leadsAvailable = value;
  }

  @Input() public set criterionType(value: string) {
    this._criterionType = value;
  }

  @Input() public set criterion(value: CriterionInterface) {
    this._criterion = value;
  }

  @Input() public set parent(value: CriterionInterface) {
    this._parentCriterion = value;
  }

  @Input() public set filter(value: string) {
    this._filter = value;
    this.criterionItemPanels?.forEach(itemPanel => {
      itemPanel.filter = this._filter;
    });
    if (value && value.length > 2) {
      if (this._criterion.label.toLowerCase().includes(this._filter.toLowerCase())) {
        this.filtered = true;
      }
      if (this.isChildrenFilterValid(this._criterion)) {
        this.expanded = true;
      }
    }
    else {
      this.filtered = false;
    }
  }

  constructor(public snackbarService: SnackbarService,
              protected modalService: ModalService,
              protected userService: UserService) {
  }

  ngOnInit() {
    if (this._criterionType === 'topics') {
      this.leadCriterions = {
        past: undefined,
        present: undefined,
        future: undefined,
        lead: undefined
      };
      this._criterion.childCriterions.forEach(value => {
        if (value.subType === 'temporal') {
          if (value.subSubType === 'past') {
            this.leadCriterions.past = value;
          }
          else if (value.subSubType === 'present') {
            this.leadCriterions.present = value;
          }
          else if (value.subSubType === 'future') {
            this.leadCriterions.future = value;
          }
        }
        else if (value.subType === 'predictive') {
          this.leadCriterions.lead = value;
        }
      });
    }
  }

  checkItem() {
    // check/uncheck item, verify children
    const newValue = !this._criterion.checked_;
    this._criterion.checked_ = newValue;
    this.checkUncheckChildren(this._criterion, newValue);
    this.emitter.emit(this._criterion);
  }

  private checkUncheckChildren(_criterion: CriterionInterface, newValue: boolean) {
    try {
      for (let child of _criterion.childCriterions) {
        if (child.subType === 'temporal' || child.subType === 'predictive') {
          //si on a coché la case on décoche les leads
          if (newValue) {
            child.checked_ = false;
          }
        }
        else {
          child.checked_ = newValue;
          this.checkUncheckChildren(child, newValue);
        }
      }
    } catch (e) {
    }
  }

  isPartiallyChecked(): boolean {
    let checking = new Checking();
    // let checking: {
    //   hasOneChecked: boolean;
    //   hasOneUnchecked: boolean;
    // };
    try {
      for (let child of this._criterion.childCriterions) {
        // if (!(child.subType === 'temporal') && !(child.subType === 'predictive')) {
          if (child.checked_) {
            checking.hasOneChecked = true;
          } else {
            checking.hasOneUnchecked = true;
          }
          this.isChildPartiallyChecked(checking, child);
        // }
      }
    } catch (e) {
    }
    return checking.hasOneChecked && checking.hasOneUnchecked;
  }

  isChildPartiallyChecked(checking: { hasOneChecked; hasOneUnchecked }, criterion: CriterionInterface) {
    try {
      if (criterion.childCriterions) {
        for (let child of criterion.childCriterions) {
          // if (!(child.subType === 'temporal') && !(child.subType === 'predictive')) {
            if (child.checked_) {
              checking.hasOneChecked = true;
            } else {
              checking.hasOneUnchecked = true;
            }
            this.isChildPartiallyChecked(checking, child);
          // }
        }
      }
      else {
        // checking.hasOneChecked = true;
      }
    } catch (e) {
      console.log(e);
    }
  }

  allChildrenChecked(criterion: CriterionInterface) {
    try {
      if (!criterion.childCriterions || criterion.childCriterions.length === 0 || (this._criterionType === 'topics' && criterion.childCriterions.length <= 4)) {
        return false;
      }
      for (let child of criterion.childCriterions) {
        if (!(child.subType === 'temporal') && !(child.subType === 'predictive')) {
          if (!child.checked_) {
            if (!this.allChildrenChecked(child)) {
              return false;
            }
          }
        }
      }
    } catch (e) {
    }
    return true;
  }

  allTempoChildrenChecked(_criterion: CriterionInterface, subSubType: string) {
    try {
      for (let child of _criterion.childCriterions) {
        if (!(child.subType === 'temporal' || child.subType === 'predictive')) {
          for (let childChild of child.childCriterions) {
            if (childChild.subType === 'temporal' && childChild.subSubType === subSubType) {
              if (!childChild.checked_) {
                return false;
              }
              break;
            }
          }
        }
      }
    } catch (e) {
    }
    return true;
  }

  allLeadChildrenChecked(_criterion: CriterionInterface) {
    try {
      for (let child of _criterion.childCriterions) {
        if (!(child.subType === 'temporal' || child.subType === 'predictive')) {
          for (let childChild of child.childCriterions) {
            if (childChild.subType === 'predictive') {
              if (!childChild.checked_) {
                return false;
              }
              break;
            }
          }
        }
      }
    } catch (e) {
    }
    return true;
  }

  emitToParent($event: any) {
    // check children

    try {
      if ($event.subType === 'temporal' || $event.subType === 'predictive') {
        if ($event.subType === 'temporal') {
          this._criterion.childCriterions.forEach(value => {
            if (value.subSubType === $event.subSubType) {

              // Weird shit avec les différentes tempos, faut voir comment gérer


              // Si cocher, vérifier que tout est coché
              // l'inverse si décoché
              if ($event.checked_) {
                if (this.allTempoChildrenChecked(this._criterion, $event.subSubType)) {
                  value.checked_ = $event.checked_;
                }
              } else {
                value.checked_ = $event.checked_;
              }
            }
          });
        } else {
          this._criterion.childCriterions.forEach(value => {
            if (value.subType === 'predictive') {
              // if (this.isLeadChecked()) {
              //   value.checked_ = true;
              // } else {
              if ($event.checked_) {
                if (this.allLeadChildrenChecked(this._criterion)) {
                  value.checked_ = $event.checked_;
                }
              } else {
                value.checked_ = $event.checked_;
              }
              // value.checked_ = $event.checked_;
              // }
            }
          });
        }
      } else {
        if (this.isPartiallyChecked()) {
          this._criterion.checked_ = false;
        } else if (this.allChildrenChecked(this._criterion)) {
          this._criterion.checked_ = true;
        }
      }
    } catch (e) {
    }

    this.emitter.emit(this._criterion);
  }

  isFilterValid() {
    if (this._filter && this._filter.length > 2) {
      if (this._criterion.label.toLowerCase().includes(this._filter.toLowerCase())) {
        return true;
      }
      if (this.isChildrenFilterValid(this._criterion)) {
        return true;
      }
      else {
        return false;
      }
    }
    return true;
  }

  isChildrenFilterValid(_criterion: CriterionInterface) {
    try {
      for (let child of _criterion.childCriterions) {
        if (child.label.toLowerCase().includes(this._filter.toLowerCase())) {
          return true;
        }
        if (this.isChildrenFilterValid(child)) {
          return true;
        }
      }
    } catch (e) {
    }
    return false;
  }

  checkTempoLeadNotAvailable() {
    if (this._leadsAvailable) {
      return;
    }
    else {
      this.snackbarService.showDangerSnackbar('Temporalités et leads prédictifs', 'Votre offre ne vous permet pas d\'utiliser ces critères. Ils sont disponibles en offre <b>Performance</b>. Si vous souhaitez en savoir plus, nous vous invitons à contacter votre commercial dédié.', 15, args => {
        this.modalService.showCustomModal(FeContactCommercialModalComponent, {
          data : {
            salesPersonDetails: this.userService.sessionData.salesPersonDetails,
            feature: 'performance',
            title: 'Vous souhaitez des renseignements sur l\'offre Performance ?'
          }
        })
      }, "Contacter mon conseiller");
    }
  }

  checkTempo(leadType: string) {
    if (!this._leadsAvailable) {
      return;
    }

    let newValue = false;
    let checkedLead;
    this._criterion.childCriterions.forEach(value => {
      if (value.subSubType === leadType) {
        value.checked_ = ! value.checked_;
        newValue = value.checked_;
        checkedLead = value;
      }
    });

    if (this._criterion.checked_ && newValue) {
      this._criterion.checked_ = false;
      this.checkUncheckChildren(this._criterion, false);
    }

    this._criterion.childCriterions.forEach(value => {
      this.checkUncheckTempoChildren(value, leadType, newValue);
    });

    // remove leads
    if (newValue) {
      let wasChecked = false;
      this._criterion.childCriterions.forEach(value => {
        if (value.subType === 'predictive') {
          wasChecked = value.checked_;
          value.checked_ = false;
        }
      });
      if (wasChecked) {
        this.snackbarService.showDangerSnackbar("Temporalité et Leads Prédictifs", "Pour plus de pertinence dans vos recherches, la prédiction “Leads Prédictifs®” ne peut pas être associée aux autres temporalités. Nous vous invitons à créer une recherche supplémentaire portant sur les autres temporalités.");
        this._criterion.childCriterions.forEach(value => {
          this.checkUncheckLeadChildren(value, false);
        });
      }
    }

    this.emitter.emit(checkedLead);
  }

  checkUncheckTempoChildren(crit: CriterionInterface, leadType: string, newValue: boolean) {
    crit.childCriterions.forEach(value => {
      if (value.subSubType === leadType) {
        value.checked_ = newValue;
      }
      this.checkUncheckTempoChildren(value, leadType, newValue);
    });
  }

  isTempoChecked(leadType: string): boolean {
    for (let value of this._criterion.childCriterions) {
      if (value.subSubType === leadType) {
        return value.checked_;
      }
    }
    return false;
  }

  isLeadChecked() {
    for (let value of this._criterion.childCriterions) {
      if (value.subType === 'predictive') {
        return value.checked_;
      }
    }
    return false;
  }

  checkLead() {
    let newValue = false;
    let checkedLead;
    this._criterion.childCriterions.forEach(value => {
      if (value.subType === 'predictive') {
        value.checked_ = ! value.checked_;
        newValue = value.checked_;
        checkedLead = value;
      }
    });

    if (this._criterion.checked_ && newValue) {
      this._criterion.checked_ = false;
      this.checkUncheckChildren(this._criterion, false);
    }

    this._criterion.childCriterions.forEach(value => {
      this.checkUncheckLeadChildren(value, newValue);
    });

    // remove tempos
    if (newValue) {
      let wasChecked = false;
      this._criterion.childCriterions.forEach(value => {
        if (value.subType === 'temporal') {
          wasChecked = wasChecked ? true : value.checked_;
          value.checked_ = false;
        }
      });
      if (wasChecked) {
        this.snackbarService.showDangerSnackbar("Temporalité et Leads Prédictifs", "Pour plus de pertinence dans vos recherches, la prédiction “Leads Prédictifs®” ne peut pas être associée aux autres temporalités. Nous vous invitons à créer une recherche supplémentaire portant sur les autres temporalités.");
        this._criterion.childCriterions.forEach(value => {
          this.checkUncheckTempoChildren(value, 'past', false);
          this.checkUncheckTempoChildren(value, 'present', false);
          this.checkUncheckTempoChildren(value, 'future', false);
        });
      }
    }

    this.emitter.emit(checkedLead);
  }

  private checkUncheckLeadChildren(value: CriterionInterface, newValue: boolean) {
    value.childCriterions.forEach(value => {
      if (value.subType === 'predictive') {
        value.checked_ = newValue;
      }
      this.checkUncheckLeadChildren(value, newValue);
    });
  }

  isOneOfLeadTempoChecked() {
    return this.isTempoChecked('past') || this.isTempoChecked('present') || this.isTempoChecked('future') || this.isLeadChecked();
  }
}

class Checking {
  public hasOneChecked: boolean = false;
  public hasOneUnchecked: boolean = false;
  constructor() {
  }
}
