import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {map, share, tap} from 'rxjs/operators';
import {ApiService} from '../api/api.service';
import {HttpResponse} from '@angular/common/http';
import {SessionInterface} from 'src/app/interfaces/session/session.interface';
import {ArticleInterface} from '../../interfaces/articles/article.interface';
import {LogContextInterface} from '../../interfaces/logging/log.context.interface';
import {LogArticleInterface} from '../../interfaces/logging/log.article.interface';
import {LogDataInterface} from '../../interfaces/logging/log.data.interface';
import {LogCriterionInterface} from '../../interfaces/logging/log.criterion.interface';
import {LogInterface} from '../../interfaces/logging/log.interface';
import {ErrorStatus} from '../../classes/ErrorStatus.class';
import {AuthService} from '../auth/auth.service';
import {DepartementInterface} from '../../interfaces/departement/departement.interface';
import {ContactInterface} from '../../interfaces/contact/contact.interface';
import {EntrepriseInterface} from '../../interfaces/entreprise/entreprise.interface';
import {TagsInterface} from '../../interfaces/tags/tags';
import {PredictionInterface} from '../../interfaces/tag/prediction';
import {ArticleQueryInterface} from '../../interfaces/articles/article-query.interface';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private _sessionData$: BehaviorSubject<SessionInterface> = new BehaviorSubject(undefined);
    public get sessionData$(): Observable<SessionInterface> {
        return this._sessionData$;
    }

    public sessionData: SessionInterface;

    constructor(
        private apiService: ApiService,
        private authService: AuthService,
        private router: Router
    ) {
    }

    public init(): void {
        // init request here. Called by layoutComponent. So we have sesssion and request not failed
        this.getSession();
    }
    public clean(): void {
        this.sessionData = undefined;
        this._sessionData$.next(undefined);
    }

    /**
     * Get the current user session data
     */
    public getSession(): void {
        this.apiService.get('v1/session').pipe(
            map((response: HttpResponse<SessionInterface>) => {
                return response.body;
            }),
            share()
        ).subscribe((sessionData: SessionInterface) => {
            this.sessionData = sessionData;
            this._sessionData$.next(sessionData);
        }); // this._sessionData$.next(sessionData));
    }

    public getSessionDatas(): BehaviorSubject<SessionInterface> {
        return this._sessionData$;
        // shitty trick to avoid multiple calls to session and to have to change the code everywhere
        // if (this.sessionData !== undefined) {
        //   return new Observable<SessionInterface>((observer) => {
        //     observer.next( this.sessionData);
        //     observer.complete();
        //   });
        // } else {
        //   return this.apiService.get('v1/session').pipe(
        //     map((response: HttpResponse<SessionInterface>) => {
        //       this.sessionData = response.body;
        //       return response.body;
        //     })
        //   );
        // }
    }

    public declineCommunication(communication: any) {
        this.apiService.post<any>(`v1/communication/${communication.id}/decline`).pipe(
            map((response: HttpResponse<any>) => {
                if (response.status === 200 || response.status === 201) {
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        ).subscribe(
            () => {
            },
            (error: Error | ErrorStatus) => {
            }
        );
    }
    public doneFeatureTour(featureTourId: any) {
        this.apiService.post<any>(`v1/tours/${featureTourId}/done`).pipe(
            map((response: HttpResponse<any>) => {
                if (response.status === 200 || response.status === 201) {
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        ).subscribe(
            () => {
            },
            (error: Error | ErrorStatus) => {
            }
        );
    }

    public logMultiArticles(action: string, articleIds: Array<number>) {
        const articles: Array<ArticleInterface> = [];
        articleIds.forEach(value => {
            articles.push(new class implements ArticleInterface {
                contacts: Array<ContactInterface>;
                corps: string;
                data: { comment?: string; commentIsPrivate?: boolean };
                departements: Array<DepartementInterface>;
                entreprises: Array<EntrepriseInterface>;
                favorite: boolean;
                id: number = value;
                precisions: any;
                predictions: Array<PredictionInterface>;
                publicationDate: string;
                read: boolean;
                score: number;
                sources: any;
                subTitle: string;
                tags: TagsInterface;
                title: string;
                type: string;
            });
        });
        this.log(action, articles);
    }

    public log(action: string, articles?: Array<any>, enterpriseId?: number, articlePredictionId?: number) {

        const self = this;

        const url: string = this.router.url;

        this.authService.getStoredToken().then((token: string) => {
            self.getSessionDatas().subscribe((sessionData: SessionInterface) => {
                if (sessionData !== undefined) {
                    try {
                        const logContext: LogContextInterface = new class implements LogContextInterface {
                            current_tab: string = self.getTab(url);
                            details: Array<string>;
                            session: string = token;
                        };
                        // const logArticles: Array<LogArticleInterface> = [];
                        // if (articles !== undefined) {
                        //     articles.forEach(value => {
                        //         const logArticle = new class implements LogArticleInterface {
                        //             id: string = value.id.toString();
                        //             note: number;
                        //             position: number;
                        //             type: string = self.getLogTypeFromArticleType(value.type);
                        //         };
                        //         logArticles.push(logArticle);
                        //     });
                        // }

                        const logData: LogDataInterface = new class implements LogDataInterface {
                            agent: string;
                            // articles: Array<LogArticleInterface> = logArticles;
                            criterion: Array<LogCriterionInterface> = [];
                            details: Array<string>;
                            hash: string;
                            ip: string;
                            // searchString: string = query !== undefined && query.keywords !== undefined ? query.keywords.join(',') : undefined;
                        };

                        if (articles != null) {
                            logData.articleIds = articles
                                .filter(article => article?.id != null || article?.articleId != null)
                                .map(article => article?.id != null ? article.id : article?.articleId);
                        }
                        if (enterpriseId != null) {
                            logData.enterpriseId = enterpriseId;
                        }
                        if (articlePredictionId != null) {
                            logData.articlePredictionId = articlePredictionId;
                        }

                        const log: LogInterface = new class implements LogInterface {
                            action: string = self.getActionBasedOnTab(action, url);
                            context: LogContextInterface = logContext;
                            data: LogDataInterface = logData;
                            date: string = new Date().toISOString();
                            user: string = sessionData.customerDetails.user_id;
                            version: number = 4.0;
                        };

                        this.apiService.post<any>('v1/log', log, false, {'Content-Type': 'text/plain'}).pipe(
                            map((response: HttpResponse<any>) => {
                                if (response.status === 200 || response.status === 201) {
                                    return response;
                                } else {
                                    throwError(new ErrorStatus(response.status));
                                }
                            })
                        ).subscribe(
                            () => {
                            },
                            (error: Error | ErrorStatus) => {
                            }
                        );
                    } catch (e) {
                    }
                }
            });
        });
    }

    private getTab(tab: string): string {

        const indexOfParams = tab.indexOf('?');
        if (indexOfParams !== -1) {
            tab = tab.substring(0, indexOfParams);
        }

        switch (tab) {
            case '/app/business-signals':
                return 'SEARCH';
            // case 'FILTRES':
            //     return 'FILTRES';
            // case 'NATIONAL':
            //     return 'NATIONAL';
            // case 'INVESTMENT':
            //     return 'INVESTMENT';
            // case 'AO_BTP':
            //     return 'AO_BTP';
            // case 'AO_FS':
            //     return 'AO_FS';
            // case 'AL':
            //     return 'AL';
            case '/app/my-favorites':
                return 'SELECTION';
            case '/app/addresses':
                return 'PARTAGE';
            case '/app/sending-history':
                return 'ENVOIS';
            case '/app/my-searches':
                return 'SEARCH';
            case '/app/monitoring-articles':
                return 'MONITORING';
        }
        return 'RECO';
    }

    private getLogTypeFromArticleType(type: string): string {
        switch (type) {
            case 'local':
                return 'LOCAL';
            case 'flash':
                return 'FLASH';
            case 'national':
                return 'NATIONAL';
            case 'nationalHome':
                return 'NATIONAL';
            case 'appeloffres_btp':
                return 'AO_BTP';
            case 'appeloffres_fs':
                return 'AO_FS';
            case 'annoncelegale':
                return 'AL';
            case 'resumeinvest':
                return 'RI';
        }
        return 'LOCAL';
    }

    private getActionBasedOnTab(action: string, url: string): string {
        switch (action) {
            case 'SEARCH_ARTICLES':
                if (url === '/app/business-signals') {
                    return 'FILTERS_VALIDATE';
                }
        }
        return action;
    }

    /**
     * Mise à jour des préférences de l'utilisateur
     * @param userPreferences
     */
    public patchUserPreferences(userPreferences: { [key: string]: any } = {}): Observable<HttpResponse<any>> {
        return this.apiService.patch(`v1/userpreferences`, userPreferences).pipe(
            tap((response: HttpResponse<any>) => {
                if (response.status === 200 || response.status === 201) {
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    public accessFeature(feature: string): Observable<HttpResponse<any>> {
        return this.apiService.post<any>(`v1/clients/accessFeature?feature=` + feature).pipe(
            map((response: HttpResponse<any>) => {
                if (response.status === 200 || response.status === 201) {
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    public accessFeatureProduct(feature: string, product: string, token: string): Observable<HttpResponse<any>> {
        return this.apiService.postWithoutAuth<any>(`v1/clients/accessFeatureProduct?feature=` + feature + `&product=` + product + `&customer=` + token).pipe(
            map((response: HttpResponse<any>) => {
                if (response.status === 200 || response.status === 201) {
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

    public changeUserPassword(passwordsData: { [key: string]: any } = {}): Observable<HttpResponse<any>> {
        return this.apiService.post<any>(`v1/session/editPwd`, passwordsData).pipe(
            map((response: HttpResponse<any>) => {
                if (response.status === 200 || response.status === 201) {
                    return response;
                } else {
                    throwError(new ErrorStatus(response.status));
                }
            })
        );
    }

}
