<ion-header no-border>
  <ion-toolbar>
    <ion-buttons style="width: 100%; display: flex;">
      <mat-icon style="margin-left: 10px;">person</mat-icon>
<!--      <img class="batimentIcon" style="width: 40px; margin-left: 5px;" src="./assets/icon/entreprise_noir.png">-->
      <div class="ficEntHeaderBtnBar" style="flex-grow: 1; display: flex;">
        <span style="margin: auto 5px auto 10px; font-size: 20px; color: #254354;" *ngIf="entreprise">{{entreprise.name}}</span>
      </div>

      <ion-button mat-icon-button aria-label="close" slot="end" (click)="close()">
        <mat-icon>close</mat-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="ficheEBody">
    <ion-row>
      <ion-card class="feCard">
        <ion-card-header class="feCardHeader">
          <ion-card-title>
            <span>Contacts clés</span>
          </ion-card-title>
        </ion-card-header>

        <ion-card-content style="padding: 0;">
            <ion-item style="margin-left: -16px;">
                <ion-row class="contactFieldContainer" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
                    <div class="contactFieldName title" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">Nom et fonction</div>
                    <div class="contactFieldMail title" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">Mail</div>
                    <div class="contactFieldLastPart" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
                        <div class="contactFieldPhone title" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">Téléphone</div>
                        <div class="contactFieldButtonContainer title" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">Réseaux</div>
                    </div>
                </ion-row>
            </ion-item>
          <ion-list style="padding: 0;">
            <ion-item style="margin-left: -16px;" *ngFor="let contact of entreprise.contacts">
                <ion-row class="contactFieldContainer" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
                    <div class="contactFieldName" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
                        <span>{{contact.name}}</span>
                        <span *ngIf="contact.function"> - {{contact.function}}</span>
                        <span *ngIf="!contact.function && !contact.name">Standard</span>
                    </div>
                    <div class="contactFieldMail" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">{{contact.mail}}</div>
                    <div class="contactFieldLastPart" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
                        <div class="contactFieldPhone" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">{{contact.phone}}</div>
                        <div class="contactFieldButtonContainer" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
                            <button *ngIf="contact.linkedin" class="snButton" (click)="openRsLink(contact.linkedin)">
                                <img style="width: 20px; height: 20px;" src="./assets/icon/linkedin2.png">
                            </button>
                            <button style="margin-left: 5px;" *ngIf="contact.twitter" class="snButton" (click)="openRsLink(contact.twitter)">
                                <img style="width: 20px; height: 16px; margin-bottom: 2px;" src="./assets/icon/twitter-bird.png">
                            </button>
                            <button style="margin-left: 5px;" *ngIf="contact.facebook" class="snButton" (click)="openRsLink(contact.facebook)">
                                <img style="width: 20px; height: 20px;" src="./assets/icon/facebook-icon.png">
                            </button>
                        </div>
                    </div>
                </ion-row>
            </ion-item>
          </ion-list>

<!--          <ion-row>-->
<!--            <ion-button class="seeMoreBtn">Voir plus</ion-button>-->
<!--          </ion-row>-->
        </ion-card-content>
      </ion-card>
    </ion-row>
  </div>
</ion-content>
