import {Component, EventEmitter, OnInit, Output, QueryList, Renderer2, ViewChildren} from '@angular/core';
import {SearchesService} from '../../../services/searches/searches.service';
import {MatListItem} from '@angular/material/list';
import {IconType} from '../../parts/fe-icon/fe-icon.component';

@Component({
  selector: 'app-search-left-part',
  templateUrl: './search-left-part.component.html',
  styleUrls: ['./search-left-part.component.scss']
})
export class SearchLeftPartComponent implements OnInit {
  @Output() emitter = new EventEmitter();

  @ViewChildren(MatListItem) listItems: QueryList<MatListItem>;

  public selectedType: string;

  constructor(public searchesService: SearchesService, private renderer: Renderer2) {

  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.openCriterionPage('keywords');
    }, 500);
  }

  getNumberCriterionSelected(typeCriterion: string) {
    if (this.searchesService.selectedCriterionsObj && this.searchesService.selectedCriterionsObj[typeCriterion].length > 0) {
      if (this.searchesService.selectedCriterionsObj[typeCriterion].length === 1) {
        return '1 critère sélectionné';
      } else {
        return this.searchesService.selectedCriterionsObj[typeCriterion].length + ' critères sélectionnés';
      }
    }
    return 'Aucun critère sélectionné';
  }

  getNumberCriterionSelectedLocalisation(typeCriterion: string) {
    if (this.searchesService.selectedCriterionsObj && this.searchesService.selectedCriterionsObj[typeCriterion].length > 0) {
      if (this.searchesService.selectedCriterionsObj[typeCriterion].length === 1 && !this.searchesService.geolocActive) {
        return '1 critère sélectionné';
      } else {
        let count = 0;
        if (this.searchesService.geolocActive) {
          count = 1;
        }
        return (this.searchesService.selectedCriterionsObj[typeCriterion].length + count) + ' critères sélectionnés';
      }
    }
    if (this.searchesService.geolocActive) {
      return '1 critère sélectionné';
    }
    return 'Aucun critère sélectionné';
  }

  openCriterionPage(typeCriterion: string) {
    this.selectedType = typeCriterion;
    // this.listItems.forEach(item => {
    //   this.renderer.removeClass(item._elementRef.nativeElement, 'selectedItem');
    // });
    this.emitter.emit(typeCriterion);
  }

    protected readonly IconType = IconType;
}
