<div class="searchSelectedCriterions">
    <div class="dateContainer">
        <p style="font-size: 16px;font-weight: 700;">Filtrer par date</p>
<!--        <app-fe-date-picker #datePicker (emitter)="dateChanged($event)"></app-fe-date-picker>-->
        <div style="display: flex;flex-direction: row;cursor:pointer; align-items: center;"
             (click)="openDateSelector($event)">
            <fe-input [value]="dateInputText" [readonly]="true" style="flex: 1;" [width]="'100%'" [cursor]="'pointer'"></fe-input>
            <fe-button color="PRIMARY" type="TERTIARY" [hideText]="true" [icon]="true"
                       [iconType]="IconType.calendar"></fe-button>
        </div>
    </div>
    <div class="criterionsContainer searchModalCriterionsContainerTour">
        <span class="bigTextBold">{{criteresText}}</span>
        <span *ngIf="showPrecisions" class="moderateText">
            Ajuster vos paramètres de recherches et affinez vos résultats.
        </span>
        <div class="fe-divider-without-m"></div>
        <mat-accordion style="overflow: auto; flex: 1; display: flex; flex-direction: column">
            <mat-expansion-panel #keywords *ngIf="_type === 'search' || currentSearch.keywords_must?.length > 0 || currentSearch.keywords_must_not?.length > 0 || currentSearch.keywords_should?.length > 0">
                <mat-expansion-panel-header>
                    <mat-panel-title class="criterionMainTitle">
                        <fe-icon [iconType]="IconType.mots_cles"
                                 [color]="keywords.expanded ? ColorType.lightBlack : ColorType.darkGrey"/>
                        <span class="bigText">
                            Mots-clés ({{getKeywordCount()}})
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div style="display: flex; flex-direction: row;">
                    <p style="flex: 1" *ngIf="(currentSearch.keywords_must && currentSearch.keywords_must.length > 0) || (currentSearch.keywords_should && currentSearch.keywords_should.length > 0) || (currentSearch.keywords_must_not && currentSearch.keywords_must_not.length > 0)">
                        <ng-container *ngIf="currentSearch.keywords_must && currentSearch.keywords_must.length > 0">
                            <b>Tous les mots suivants : </b><br>
                            <div *ngFor="let key of currentSearch.keywords_must">
                                - {{key}}
                            </div>
                            <br>
                        </ng-container>
                        <ng-container *ngIf="currentSearch.keywords_should && currentSearch.keywords_should.length > 0">
                            <b>Un des mots suivant : </b><br>
                            <div *ngFor="let key of currentSearch.keywords_should">
                                - {{key}}
                            </div>
                            <br>
                        </ng-container>
                        <ng-container *ngIf="currentSearch.keywords_must_not && currentSearch.keywords_must_not.length > 0">
                            <b>Aucun des mots suivants : </b><br>
                            <div *ngFor="let key of currentSearch.keywords_must_not">
                                - {{key}}
                            </div>
                        </ng-container>
                    </p>
                    <span class="badge-count" *ngIf="articleCounts && articleCounts?.keywordsCount[0]">{{articleCounts?.keywordsCount[0]?.articlesCount}}</span>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel #sectors *ngIf="_type === 'search' || currentSearch.sectors">
                <mat-expansion-panel-header>
                    <mat-panel-title class="criterionMainTitle">
                        <fe-icon [iconType]="IconType.secteurs"
                                 [color]="sectors.expanded ? ColorType.lightBlack : ColorType.darkGrey"/>
                        <span class="bigText">
                            Secteurs d'activité ({{currentSearch.sectors ? currentSearch.sectors.length : 0}})
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-chip-grid *ngIf="_type === 'search'">
                    <mat-chip-row matTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="{{searchService.criterionMap.get(sectorId)?.label}}" (removed)="removeChip(sectorId, 'sectors')" *ngFor="let sectorId of currentSearch.sectors">
                       {{getChipsLabel(searchService.criterionMap.get(sectorId)?.label, sectorId)}}
                        <button matChipRemove [attr.aria-label]="'remove'">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                </mat-chip-grid>
                <div *ngIf="_type === 'business'" style="display: flex; flex-direction: column; overflow: auto">
                    <div style="display: flex;flex-direction: row" *ngFor="let sector of articleCounts?.sectorsCount">
                        <mat-checkbox [checked]="Utils.offCriterions.length > 0 && !Utils.offCriterions.includes(sector.id)" (change)="checkElement(sector.id, 'sectors', $event)"
                                      class="criterionCheckBox">
                            <span class="moderateText">{{searchService.criterionMap.get(sector.id)?.label}}</span>
                        </mat-checkbox>
                        <span class="badge-count" *ngIf="articleCounts">{{articleCountsMap.get(sector.id)?.articlesCount}}</span>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel #topics *ngIf="_type === 'search' || currentSearch.topics">
                <mat-expansion-panel-header>
                    <mat-panel-title class="criterionMainTitle">
                        <fe-icon [iconType]="IconType.tag_theme"
                                 [color]="topics.expanded ? ColorType.lightBlack : ColorType.darkGrey"/>
                        <span class="bigText">
                            Événements économiques ({{currentSearch.topics ? currentSearch.topics.length : 0}})
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-chip-grid *ngIf="_type === 'search'">
                    <mat-chip-row matTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="{{getTopicLabel(searchService.criterionMap.get(sectorId))}}" (removed)="removeChip(sectorId, 'topics')" *ngFor="let sectorId of currentSearch.topics">
                        {{getChipsLabel(getTopicLabel(searchService.criterionMap.get(sectorId)), sectorId)}}
                        <button matChipRemove [attr.aria-label]="'remove'">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                </mat-chip-grid>
                <div *ngIf="_type === 'business'" style="display: flex; flex-direction: column; overflow: auto">
                    <div style="display: flex;flex-direction: row" *ngFor="let sector of articleCounts?.topicsCount">
                        <mat-checkbox [checked]="Utils.offCriterions.length > 0 && !Utils.offCriterions.includes(sector.id)" (change)="checkElement(sector.id, 'topics', $event)"
                                       class="criterionCheckBox">
                            <span class="moderateText">{{getTopicLabel(searchService.criterionMap.get(sector.id))}}</span>
                        </mat-checkbox>
                        <span class="badge-count" *ngIf="articleCounts">{{articleCountsMap.get(sector.id)?.articlesCount}}</span>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel #locations *ngIf="_type === 'search' || currentSearch.locations?.length > 0 || currentSearch.geolocDistanceRadius">
                <mat-expansion-panel-header>
                    <mat-panel-title class="criterionMainTitle">
                        <fe-icon [iconType]="IconType.localisations"
                                 [color]="locations.expanded ? ColorType.lightBlack : ColorType.darkGrey"/>
                        <span class="bigText">
                            Localisation ({{(currentSearch.locations ? currentSearch.locations.length : 0) + (currentSearch.geolocDistanceRadius ? 1 : 0)}})
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="_type === 'search' && currentSearch.geolocDistanceRadius && geolocCity">{{geolocCity.name + ' - ' + this.currentSearch.geolocDistanceRadius + 'km'}}</div>
                <mat-chip-grid *ngIf="_type === 'search'" style=" overflow: auto;">
                    <mat-chip-row matTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="{{searchService.criterionMap.get(sectorId)?.label}}"  [title]="searchService.criterionMap.get(sectorId)?.label" (removed)="removeChip(sectorId, 'locations')" *ngFor="let sectorId of currentSearch.locations">
                        {{getChipsLabel(searchService.criterionMap.get(sectorId)?.label, sectorId)}}
                        <button matChipRemove [attr.aria-label]="'remove'">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                </mat-chip-grid>
                <div *ngIf="_type === 'business'" style="display: flex; flex-direction: column; overflow: auto">
                    <div *ngIf="_type === 'business' && currentSearch.geolocDistanceRadius && geolocCity">{{geolocCity.name + ' - ' + this.currentSearch.geolocDistanceRadius + 'km'}}</div>
                    <div style="display: flex;flex-direction: row" *ngFor="let sector of articleCounts?.locationsCount">
                        <mat-checkbox [checked]="Utils.offCriterions.length > 0 && !Utils.offCriterions.includes(sector.id)" (change)="checkElement(sector.id, 'locations', $event)"
                                      class="criterionCheckBox">
                            <span class="moderateText">{{searchService.criterionMap.get(sector.id)?.label}}</span>
                        </mat-checkbox>
                        <span class="badge-count" *ngIf="articleCounts">{{articleCountsMap.get(sector.id)?.articlesCount}}</span>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel #companySizes *ngIf="_type === 'search' || currentSearch.companySizes">
                <mat-expansion-panel-header>
                    <mat-panel-title class="criterionMainTitle">
                        <fe-icon [iconType]="IconType.taille_entreprise"
                                 [color]="companySizes.expanded ? ColorType.lightBlack : ColorType.darkGrey"/>
                        <span class="bigText">
                            Taille d'entreprise ({{currentSearch.companySizes ? currentSearch.companySizes.length : 0}})
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-chip-grid *ngIf="_type === 'search'">
                    <mat-chip-row [title]="searchService.criterionMap.get(sectorId)?.label" (removed)="removeChip(sectorId, 'companySizes')" *ngFor="let sectorId of currentSearch.companySizes">
                        {{getChipsLabel(searchService.criterionMap.get(sectorId)?.label, sectorId)}}
                        <button matChipRemove [attr.aria-label]="'remove'">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                </mat-chip-grid>
                <div *ngIf="_type === 'business'" style="display: flex; flex-direction: column; overflow: auto">
                    <div style="display: flex;flex-direction: row" *ngFor="let sectorId of currentSearch.companySizes">
                        <mat-checkbox (change)="checkElement(sectorId, 'companySizes', $event)"
                                      class="criterionCheckBox">
                            <span class="moderateText">{{searchService.criterionMap.get(sectorId)?.label}}</span>
                        </mat-checkbox>
                        <span class="badge-count" *ngIf="articleCounts">{{articleCountsMap.get(sectorId)?.articlesCount}}</span>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel #activityTypes *ngIf="_type === 'search' || currentSearch.activityTypes">
                <mat-expansion-panel-header>
                    <mat-panel-title class="criterionMainTitle">
                        <fe-icon [iconType]="IconType.nature_locaux"
                                 [color]="activityTypes.expanded ? ColorType.lightBlack : ColorType.darkGrey"/>
                        <span class="bigText">
                            Nature des locaux ({{currentSearch.activityTypes ? currentSearch.activityTypes.length : 0}})
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-chip-grid *ngIf="_type === 'search'">
                    <mat-chip-row [title]="searchService.criterionMap.get(sectorId)?.label" (removed)="removeChip(sectorId, 'activityTypes')" *ngFor="let sectorId of currentSearch.activityTypes">
                        {{getChipsLabel(searchService.criterionMap.get(sectorId)?.label, sectorId)}}
                        <button matChipRemove [attr.aria-label]="'remove'">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                </mat-chip-grid>
                <div *ngIf="_type === 'business'" style="display: flex; flex-direction: column; overflow: auto">
                    <div style="display: flex;flex-direction: row" *ngFor="let sectorId of currentSearch.activityTypes">
                        <mat-checkbox (change)="checkElement(sectorId, 'activityTypes', $event)"
                                      class="criterionCheckBox">
                            <span class="moderateText">{{searchService.criterionMap.get(sectorId)?.label}}</span>
                        </mat-checkbox>
                        <span class="badge-count" *ngIf="articleCounts">{{articleCountsMap.get(sectorId)?.articlesCount}}</span>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <fe-loader *ngIf="loadingInProgress" isOverflowing="true"/>
    </div>
</div>
