import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ContactInterface} from 'src/app/interfaces/contact/contact.interface';
import {MediaQueryService} from 'src/app/services/media-query/media-query.service';
import {EntrepriseInterface} from '../../../interfaces/entreprise/entreprise.interface';
import {FicheEntrepriseModal} from '../../modals/fiche-entreprise/fiche-entreprise.modal';
import {ModalController, Platform} from '@ionic/angular';
import {EntrepriseContactsModal} from '../../modals/entreprise-contacts/entreprise-contacts.modal';
import {EntrepriseContactInterface} from '../../../interfaces/entreprise-contact/entreprise-contact.interface';
import {takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {ArticlesService} from '../../../services/articles/articles.service';
import {ToastService} from '../../../services/toast/toast.service';
import {ArticleInterface} from '../../../interfaces/articles/article.interface';
import {Utils} from '../../../classes/Utils';
import {SessionInterface} from '../../../interfaces/session/session.interface';
import {UserService} from '../../../services/user/user.service';

declare var cordova: any;

@Component({
  selector: 'article-entreprise',
  templateUrl: 'article-entreprise.component.html',
  styleUrls: ['article-entreprise.component.scss'],
})
export class ArticleEntrepriseComponent implements OnInit, OnDestroy {

  @Input() public entreprise: EntrepriseInterface;
  @Input() public article: ArticleInterface;
  @Input() public condensedMode: boolean = false;
  public _keywords: Array<string>;
  public webToLead: boolean;
  public companyId: string;
  public webToLeadCompanies: Array<String> = Utils.webToLeadCompanies;
  @Input() public set keywords(value: Array<string>) {
    this._keywords = value;
  }
  public get keywords(): Array<string> {
    return this._keywords;
  }

  private unsubscribe$: Subject<any> = new Subject<any>();

  public numberOfContacts: number;
  public sliceLimit: number = 2;

  constructor(
    public mediaQueryService: MediaQueryService
    , private modalController: ModalController
    , public articlesService: ArticlesService
    , public toastService: ToastService
    , public userService: UserService
      , private platform: Platform
  ) {}

  public ngOnInit(): void {
    // if (Array.isArray(this.contacts) && this.contacts.length > 0) {
    //   this.numberOfContacts = this.contacts.length;
    //   // iterate on contact to get subContact and count them.
    //   this.contacts.forEach((contact: ContactInterface) => {
    //     if (Array.isArray(contact.subContacts) && contact.subContacts.length > 0) {
    //       this.numberOfContacts += contact.subContacts.length;
    //     }
    //   });
    // }
    this.userService.getSessionDatas().pipe(
        takeUntil(this.unsubscribe$)
    )
        .subscribe(
            (sessionData: SessionInterface) => {
              if (sessionData !== undefined) {
                this.companyId = sessionData.customerDetails.companyId;
                sessionData.appConfig.viewConfigs.forEach(value => {
                  if (value.component === 'WEBTOLEAD') {
                    if (value.state === 'visible') {
                      this.webToLead = true;
                    }
                  }
                });
              }
            }
        );
    if (this.condensedMode) {
      this.sliceLimit = 1;
    }
  }

  public getHighlightedText(text: string): string {
    return Utils.getHighlightedText(text, this._keywords);
  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public openOrganisationWebsite() {
    if (!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
      // if (!this.platform.url().startsWith('http')) {
      cordova.InAppBrowser.open(this.entreprise.website, '_system', 'hidden=yes,location=no');
      // window.open(this.entreprise.website, '_system');
    }
    else {
      window.open(this.entreprise.website, '_blank');
    }
  }

  public async openArticleFicheEntreprise(): Promise<void> {
    const modal = await this.modalController.create({
      component: FicheEntrepriseModal,
      cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog ficheEModal' : 'desktopDialog ficheEModal',
      componentProps: {
        entreprise: this.entreprise,
        entrepriseId: this.entreprise.entrepriseId,
        article: this.article
      }
    });

    return await modal.present();
  }

  public async openEntrepriseContacts(): Promise<void> {
    const modal = await this.modalController.create({
      component: EntrepriseContactsModal,
      cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'desktopDialog',
      componentProps: {
        entreprise: this.entreprise,
      }
    });

    return await modal.present();
  }

  public exportArticleWebToLead(contact: EntrepriseContactInterface, $event: MouseEvent) {
    $event.stopPropagation();
    /* TODO adapter code pour créer le contact selon 2 cas, - Cas classique avec les contacts complets
    * TODO - Cas de contact créés depuis ancien article, traitement à faire pour séparer fonction et nom
    * */
    const self = this;
    const contactWTL: ContactInterface = new class implements ContactInterface {
      contactName: string = contact.name;
      email: string = contact.mail;
      organisationName: string = self.entreprise.name;
      siren: string = self.entreprise.siren;
      socialUrl: string = contact.linkedin;
      tel: string = contact.phone;
      website: string = self.entreprise.website;
      firstName: string = contact.firstName;
      lastName: string = contact.lastName;
      contactId: number = contact.contactId;
      entrepriseId: number = self.entreprise.entrepriseId;
    };
    this.articlesService.exportArticleWebToLead(this.article.id, contactWTL)
        .pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(
        () => {
          this.toastService.simple('L\'export WebToLead a été effectué.');
        },
        (her: HttpErrorResponse) => {
          let errorStr: string;
          if (typeof her !== 'undefined' && typeof her.error.message !== 'undefined' && her.error.message !== null && her.error.message !== '') {
            errorStr = her.error.message;
          } else {
            errorStr = 'Une erreur est survenue lors de l\'export WebToLead. Veuillez réessayer ultérieurement.';
          }
          this.toastService.simple(errorStr, { color: 'toasterror' });
        }
    );
    // this.toastService.simple(this.translateService.instant('EXPORT_WEBTOLEAD_SUCCESS'));
  }

  openEntrepriseLocalisation() {
    if (!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
      // if (!this.platform.url().startsWith('http')) {
      cordova.InAppBrowser.open('https://www.google.com/maps/place/' + this.entreprise.latitude + ',' + this.entreprise.longitude, '_system', 'hidden=yes,location=no');
      // window.open(this.entreprise.website, '_system');
    }
    else {
      window.open('https://www.google.com/maps/place/' + this.entreprise.latitude + ',' + this.entreprise.longitude, '_blank');
    }
  }

  displayContactBloc(contacts: Array<EntrepriseContactInterface>) {
    let display: boolean = false;
    if (this.entreprise.contacts != null) {
      if (this.entreprise.contacts.length > 0) {
        this.entreprise.contacts.forEach(contact => {
          if (contact.name || contact.function || contact.phone || contact.mail) {
            display = true;
          }
        });
      }
    }
    return display;
  }

  exportEntrepriseWebTolead(entreprise: EntrepriseInterface, $event: MouseEvent) {
        $event.stopPropagation();
    /* TODO adapter code pour créer le contact selon 2 cas, - Cas classique avec les contacts complets
    * TODO - Cas de contact créés depuis ancien article, traitement à faire pour séparer fonction et nom
    * */
    const self = this;
    const contactWTL = new class {
      entrepriseId: number = self.entreprise.entrepriseId;

    };
    this.articlesService.exportArticleWebToLead(this.article.id, contactWTL)
        .pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(
        () => {
          this.toastService.simple('L\'export WebToLead a été effectué.');
        },
        (her: HttpErrorResponse) => {
          let errorStr: string;
          if (typeof her !== 'undefined' && typeof her.error.message !== 'undefined' && her.error.message !== null && her.error.message !== '') {
            errorStr = her.error.message;
          } else {
            errorStr = 'Une erreur est survenue lors de l\'export WebToLead. Veuillez réessayer ultérieurement.';
          }
          this.toastService.simple(errorStr, { color: 'toasterror' });
        }
    );
  }
}
