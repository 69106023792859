<ion-header no-border class="ionRedHeader">
  <ion-toolbar class="ionRedHeader">
      <button  mat-icon-button aria-label="close" (click)="close()" style="margin-left: 1%">
        <mat-icon>close</mat-icon>
        <span style="color: #273c4e;font-size: 16px;font-weight: bold;margin-left: 20%;">Fermer</span>
      </button>
  </ion-toolbar>
</ion-header>



<ion-content>

  <mat-list class="matListSearches">
    <ng-container *ngIf="searchList; else loading">
      <div cdkDropList
           [cdkDropListData]="searchList"
           (cdkDropListDropped)="drop($event)">
        <div>
          <h1 style="margin: 5px 7px;font-size: 19px; color: #273c4e;font-weight: bold;">Mes recherches enregistrées</h1>
          <div (click)="$event.stopPropagation()" class="divRechercheModale">
            <mat-icon style="color: rgba(0, 0, 0, 0.54);">search</mat-icon>
            <input style="background-color: #F3F5F6" placeholder="Rechercher ..."/>
          </div>
        </div>
        <div cdkDrag *ngFor="let search of searchList;let i=index;">
          <div class="listSearch" [class.changeBackground]="itemStates[i]" (mouseenter)="setItemState(i, true)"
               (mouseleave)="setItemState(i, false)" (click)="openBusinessSignals(search,i)">
            <button mat-icon-button cdkDragHandle style="margin: auto 0" [class.hiddenBtn]="!itemStates[i]" >
              <mat-icon>drag_indicator</mat-icon>
            </button>
            <div style="width: 300px;display: flex;justify-content: space-around;flex-direction: column;" >
              <span class="spanSearches">{{search.data.label}}</span>
              <div style="display: flex;flex-direction: row; justify-content: space-between">
                <span style="font-size: 12px; color: #bbbbbb;">56 critères de recherche</span>
                <a href="test">Voir les critères</a>
              </div>
            </div>
            <div class="chipAndMenuBtn">
              <ion-chip class="listSearchChip" [class.hideChips]="isToolTipActive(i)">{{getToolTipLabel(i)}}</ion-chip>
              <manage-searches style=" margin-top: 10px;" (click)="updateCurrentSearch(search, i,$event)" [search]="currentSearch" [currentIndex]="currentIndex" [searchList]="searchList"></manage-searches>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </mat-list>

  <ng-template #loading>
    <loading>{{"LOADING_SEARCHES"}}</loading>
  </ng-template>
  <ng-template #noData>
    <no-data>{{"NO_SEARCH"}}</no-data>
  </ng-template>

<!--  <div *ngFor="let search of searchList; let i = index">-->
<!--    <button class="searchListBtn">-->
<!--      <span>{{search.data.label}}</span>-->
<!--    </button>-->
<!--  </div>-->
</ion-content>

<ion-footer class="ionFooter">
  <button class="createSearchBtnModal" >
    <mat-icon style="color: white">add</mat-icon>
    <span>Nouvelle recherche</span>
  </button>
</ion-footer>


