<div class="monitoringEditCommentModal feModal">
    <div class="feModalHeader">
        <span class="feModalTitle">Editer un commentaire</span>
        <div class="feModalCloseButton">
            <span mat-dialog-close>Fermer</span>
            <mat-icon (click)="close()">close</mat-icon>
        </div>
    </div>
    <div class="feModalContent">
        <angular-editor class="full-width" [placeholder]="'Commentaire...'"
                        [(ngModel)]="newComment" [config]="config"></angular-editor>
    </div>
    <div class="feModalFooter">
        <fe-button text="Valider"
                   (click)="editComment()" />
    </div>
</div>