import {AfterViewInit, Component, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {SearchListCriterionsComponent} from '../search-list-criterions/search-list-criterions.component';
import {SearchesService} from '../../../services/searches/searches.service';
import {ArticleQueryInterface} from '../../../interfaces/articles/article-query.interface';
import {formatDate} from '@angular/common';
import {ArticlesService} from '../../../services/articles/articles.service';
import {CriterionsTypesInterface} from '../../../interfaces/criterions/criterions-types.interface';
import {CriterionInterface} from '../../../interfaces/criterions/criterion.interface';
import {SearchDataInterface} from '../../../interfaces/search/search-data.interface';
import {SearchSelectedCriterionsComponent} from '../search-selected-criterions/search-selected-criterions.component';
import {ModalController} from '@ionic/angular';
import {ModalService} from '../../../services/modal/modal.service';
import {DateArticleService} from '../../../services/date-article/date-article.service';
import {SearchInterface} from '../../../interfaces/search/search.interface';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Utils} from '../../../classes/Utils';
import {PaginatedListComponent} from '../../paginated-list/paginated-list.component';
import {MediaQueryService} from '../../../services/media-query/media-query.service';
import {Geolocation} from '@awesome-cordova-plugins/geolocation/ngx';
import {ToastService} from '../../../services/toast/toast.service';
import {UserService} from '../../../services/user/user.service';
import {Observable} from 'rxjs';
import {ArticlesInterface} from '../../../interfaces/articles/articles.interface';
import {MatTableDataSource} from '@angular/material/table';
import {ArticleInterface} from '../../../interfaces/articles/article.interface';
import {SelectionModel} from '@angular/cdk/collections';
import {SnackbarService} from '../../../services/snackbar/snackbar.service';
import {ColorType, IconType} from "../../parts/fe-icon/fe-icon.component";
import introJs from "intro.js";
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-search-creation',
  templateUrl: './search-creation.component.html',
  styleUrls: ['./search-creation.component.scss']
})
export class SearchCreationComponent implements AfterViewInit {
  @ViewChild(SearchListCriterionsComponent) listCriterionsComponent: SearchListCriterionsComponent;
  @ViewChild(SearchSelectedCriterionsComponent) selectedCriterionsComponent: SearchSelectedCriterionsComponent;

  public resultCount: number = 0;
  private currentSearchData: SearchDataInterface;
  public searchToEdit: SearchInterface;
  public loading: boolean = false;
  public previewResults = false;
  public queryResult: ArticlesInterface;
  public tableDataSource: MatTableDataSource<ArticleInterface> = new MatTableDataSource();
  public selectionModel: SelectionModel<any> = new SelectionModel<any>(true, []);
  public loadingInProgress: boolean = true;
  public keywords: Array<string>;

  constructor(public searchesService: SearchesService,
              public articleService: ArticlesService,
              public modalController: ModalController,
              public dialogRef: MatDialogRef<SearchCreationComponent>,
              // private navParams: NavParams,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public modalService: ModalService,
              public mediaQueryService: MediaQueryService,
              public geolocation: Geolocation,
              public toastService: SnackbarService,
              public userService: UserService,
              private articlesService: ArticlesService) {
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    // Your code to execute when the user tries to leave the app
    // For example, you can show a confirmation message
    event.returnValue = true; // This line prompts the user with a confirmation dialog
  }

  public launchIntroFeatureTour(tourName: string) {
  let steps = [];

    if(tourName !== undefined) {
      if(tourName === 'search_modal') {
        steps = [
          {
            title: 'Création/édition d\'une recherche',
            intro: 'Cette fenêtre vous permet de définir les critères d\'une recherche.'
          },
          {
            title: 'Catégories',
            element: document.querySelector<HTMLElement>('.searchLeftPartTour'),
            intro: 'Retrouvez ici les différentes catégories de critères. Cliquez sur le nom d\'une catégorie pour définir les critères associés.',
            position: 'right'
          },
          {
            title: 'Sélection des critères',
            element: document.querySelector<HTMLElement>('.searchListCriterionsTour'),
            intro: 'Cette zone permet de définir vos critères de recherche dans une catégorie.',
          },
          {
            title: 'Résumé des critères',
            element: document.querySelector<HTMLElement>('.searchModalCriterionsContainerTour'),
            intro: 'Retrouvez ici tous les critères de votre recherche classés par catégories. Vous pouvez voir le nombre d\'articles associé à chaque critère et les supprimer sans avoir à retourner dans la catégorie.',
            position: 'left'
          },
          {
            title: 'Aperçu des résultats',
            element: document.querySelector<HTMLElement>('.searchPreviewBtnTour'),
            intro: 'Vous pouvez voir un aperçu des résultats de votre recherche en cliquant sur ce bouton. ',
            position: 'top'
          },
          {
            title: 'Enregistrement',
            element: document.querySelector<HTMLElement>('.saveSearchBtnTour'),
            intro: 'Une fois votre recherche terminée cliquez ici pour l\'enregistrer.',
            position: 'top'
          },
        ];
      }
      else if(tourName === 'keywords') {
        steps = [
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: 'Recherche mots clés',
            intro: 'La recherche mots clés vous permet de faire des recherches avancées en combinant différentes stratégies.'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: 'Saisie d\'un mot clé',
            intro: 'Dans chaque champ de recherche, cliquez dedans et commencez à indiquer votre terme ou expression à rechercher et appuyer sur la touche Entrée pour valider la saisie. <br />Vous pouvez rechercher des expressions exactes comme <i>"levée de fonds"</i> en les mettant entre guillemets.'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: 'Edition d\'un mot clé',
            intro: 'Vous pouvez éditer un terme déjà saisi en double cliquant dessus. Cela vous permet de le modifier directement sans devoir le supprimer au préalable.'
          },
          {
            title: 'Tous les mots suivants',
            element: document.querySelector<HTMLElement>('.keywordsMustTour'),
            intro: 'Permet de rechercher les articles contenant <b>obligatoirement</b> les termes renseignés. Tous les articles recherchés contiendront ces termes.<br /> Par exemple <i>investissement</i> va retenir tous les articles contenant ce terme. Si vous indiquez plusieurs termes, seuls les articles les contenants tous seront retenus.',
            position: 'bottom'
          },
          {
            title: 'Un des mots suivants',
            element: document.querySelector<HTMLElement>('.keywordsShouldTour'),
            intro: 'Permet de rechercher les articles contenant <b>optionnellement</b> les termes renseignés. Tous les articles recherchés pourront contenir ces termes. Chaque terme indiqué sera optionnel.',
            position: 'bottom'
          },
          {
            title: 'Aucun des mots suivants',
            element: document.querySelector<HTMLElement>('.keywordsMustNotTour'),
            intro: 'Permet de rechercher les articles <b>ne contenant pas</b> les termes renseignés. Tous les articles recherchés ne contiendront pas ces termes.',
            position: 'bottom'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: 'Combinaison des critères',
            intro: 'Le résultat de votre recherche mots clés <b>est la combinaison de ces 3 champs</b>. Vous pouvez rechercher des articles contenant obligatoirement des termes avec des termes optionnels et en ôtant certains termes.'
          },
        ];
      }
      else if(tourName === 'topic_temporal_or_leads') {
        steps = [
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: 'Recherche avancée sur les événements économiques',
            intro: 'Votre offre vous permet de rechercher plus précisément sur les événements économiques avec <b>4 axes d\'enrichissement</b> :<br /><br /> - I) <b>3 axes</b> pour les <b>temporalités</b> d\'informations<br />- II) <b>1 axe</b> avec les <b>Leads Prédictifs®</b> = <b>prédictions</b> de projets potentiels.'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: 'I) Temporalités d\'un événement économique',
            intro: 'Les signaux business First ECO sont indexés avec des temporalités sur l\'événément associé :<br /> <b>passé, en cours, à venir</i></b>. <br /><br /> Notre équipe BI indexe ces informations suivant le contexte du signal. Les temporalités présentent un <b>délai de concrétisation d’un projet</b>.'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: 'Temporalité "<b>passé</b>" d\'un événement économique',
            intro: 'Elle est à utiliser pour cibler des événements ayant déjà eu lieu dans un passé relativement proche <br />' +
                '<br />' +
                '<b>= entre 1 à 2 mois à 18 mois avant aujourd’hui</n>'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: 'Temporalité "<b>en cours</b>" d\'un événement économique',
            intro: 'Elle est à utiliser pour cibler des événements qui ont débuté récemment et se déroulent actuellement (en cours) ou bien qui vont se concrétiser très rapidement.<br />' +
                '<br />' +
                '<b>= 1 à 2 mois avant aujourd’hui jusqu’à 1 à 2 mois après aujourd’hui</b>'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: 'Temporalité "<b>à venir</b>" d\'un événement économique',
            intro: 'Elle est à utiliser pour cibler des événements qui débuteront dans quelques mois ou bien font partie des projets annoncés de l’entreprise.<br />' +
                '<br />' +
                '<b>= entre 1 à 2 mois après aujourd’hui et 2 à 3 ans dans le futur</b>'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: 'II) <i>Leads Prédictifs®</i> : <b>prédictions</b> de projets potentiels.',
            intro: '<i>Leads Prédictifs®</i> est une <b>fonctionnalité ultra-innovante</b>, qui vous permet de détecter des besoins potentiels qui ont une <b>forte <u>probabilité</u> de se produire</b>.<br /><br />' +
                'Ainsi, vous vous positionnez <b>en amont</b> sur de <b>potentiels achats</b> de produits et services dans les entreprises identifiées.\n'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: '<i>Leads Prédictifs®</i>',
            intro: 'Nous vous permettons de prospecter par <b>signaux précurseurs</b> afin de vous permettre d\'innover dans votre prospection : vous arrivez en amont d’un projet (et des intentions d’achat liées), et ce, avant vos concurrents.'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: '<i>Leads Prédictifs®</i>',
            intro: 'Les <b>Leads Prédictifs</b> s’appliquent aux événements qui ont des chances de se produire. <br /><br />Ces <b>prédictions</b> sont calculées à partir d\'une <b>analyse statistique</b> menée sur les signaux business First ECO.'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: '<i>Leads Prédictifs®</i>',
            intro: 'Un lead prédictif étant une <b>prédiction</b>, nous vous donnons à chaque fois un <b>indice de confiance</b> (probabilité qu’un événement se produise).' +
                '<br /><br /><i>Un lead prédictif ne possède pas de temporalité, puisque l’événement est potentiel (donc pas encore annoncé et pas envisagé par l’entreprise).</i>'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: '<i>Leads Prédictifs®</i>',
            intro: 'Pour chaque signal business First ECO, notre algorithme génère entre 2 et 6 prédictions. <br /><br />' +
                'Avec Leads Prédictifs, vous accédez ainsi à un <b>potentiel de signaux business</b> multiplié par deux (x2), voire trois (x3) ou plus.'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: '<i>Leads Prédictifs®</i>',
            intro: 'Les prédictions sont également complétées par des informations détaillées sur les besoins potentiels des entreprises, mais aussi par des conseils d’action à mener pour construire votre approche commerciale.<br /><br /> Ces informations sont accessibles dans le signal business.'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: 'Temporalités et prédiction',
            intro: 'Pour plus de pertinence dans vos recherches, une recherche par prédiction <b>ne peut pas être associée</b> aux autres temporalités. <b>Vous perdrez toute pertinence des résultats.</b> <br /><br />Vous devez créer une recherche supplémentaire portant sur les autres temporalités.'
          }
        ];
      }
      else if(tourName === 'discover_topic_temporal_or_leads') {
        steps = [
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: 'Recherche avancée sur les événements économiques',
            intro: 'Vous êtes actuellement en offre <b>Efficience</b>.<br /><br />Nous vous proposons de découvrir une fonctionnalité avancée de notre offre <b>Performance</b> qui permet de rechercher plus précisément sur les événements économiques avec <b>4 axes d\'enrichissement</b> :<br /><br /> - I) <b>3 axes</b> pour les <b>temporalités</b> d\'informations<br />- II) <b>1 axe</b> avec les <b>Leads Prédictifs®</b> = <b>prédictions</b> de projets potentiels.'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: 'I) Temporalités d\'un événement économique',
            intro: 'Les signaux business First ECO sont indexés avec des temporalités sur l\'évènement associé :<br /> <b>passé, en cours, à venir</i></b>. <br /><br /> Notre équipe BI indexe ces informations suivant le contexte du signal. Les temporalités présentent un <b>délai de concrétisation d’un projet</b>.'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: 'Temporalité "<b>passé</b>" d\'un événement économique',
            intro: 'Elle est à utiliser pour cibler des événements ayant déjà eu lieu dans un passé relativement proche <br />' +
                '<br />' +
                '<b>= entre 1 à 2 mois à 18 mois avant aujourd’hui</n>'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: 'Temporalité "<b>en cours</b>" d\'un événement économique',
            intro: 'Elle est à utiliser pour cibler des événements qui ont débuté récemment et se déroulent actuellement (en cours) ou bien qui vont se concrétiser très rapidement.<br />' +
                '<br />' +
                '<b>= 1 à 2 mois avant aujourd’hui jusqu’à 1 à 2 mois après aujourd’hui</b>'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: 'Temporalité "<b>à venir</b>" d\'un événement économique',
            intro: 'Elle est à utiliser pour cibler des événements qui débuteront dans quelques mois ou bien font partie des projets annoncés de l’entreprise.<br />' +
                '<br />' +
                '<b>= entre 1 à 2 mois après aujourd’hui et 2 à 3 ans dans le futur</b>'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: 'II) <i>Leads Prédictifs®</i> : <b>prédictions</b> de projets potentiels.',
            intro: '<i>Leads Prédictifs®</i> est une <b>fonctionnalité ultra-innovante</b>, qui vous permet de détecter des besoins potentiels qui ont une <b>forte <u>probabilité</u> de se produire</b>.<br /><br />' +
                'Ainsi, vous vous positionnez <b>en amont</b> sur de <b>potentiels achats</b> de produits et services dans les entreprises identifiées.\n'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: '<i>Leads Prédictifs®</i>',
            intro: 'Nous vous permettons de prospecter par <b>signaux précurseurs</b> afin de vous permettre d\'innover dans votre prospection : vous arrivez en amont d’un projet (et des intentions d’achat liées), et ce, avant vos concurrents.'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: '<i>Leads Prédictifs®</i>',
            intro: 'Les <b>Leads Prédictifs</b> s’appliquent aux événements qui ont des chances de se produire. <br /><br />Ces <b>prédictions</b> sont calculées à partir d\'une <b>analyse statistique</b> menée sur les signaux business First ECO.'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: '<i>Leads Prédictifs®</i>',
            intro: 'Un lead prédictif étant une <b>prédiction</b>, nous vous donnons à chaque fois un <b>indice de confiance</b> (probabilité qu’un événement se produise).' +
                '<br /><br /><i>Un lead prédictif ne possède pas de temporalité, puisque l’événement est potentiel (donc pas encore annoncé et pas envisagé par l’entreprise).</i>'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: '<i>Leads Prédictifs®</i>',
            intro: 'Pour chaque signal business First ECO, notre algorithme génère entre 2 et 6 prédictions. <br /><br />' +
                'Avec Leads Prédictifs, vous accédez ainsi à un <b>potentiel de signaux business</b> multiplié par deux (x2), voire trois (x3) ou plus.'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: '<i>Leads Prédictifs®</i>',
            intro: 'Les prédictions sont également complétées par des informations détaillées sur les besoins potentiels des entreprises, mais aussi par des conseils d’action à mener pour construire votre approche commerciale.<br /><br /> Ces informations sont accessibles dans le signal business.'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: 'Temporalités et prédiction',
            intro: 'Pour plus de pertinence dans vos recherches, une recherche par prédiction <b>ne peut pas être associée</b> aux autres temporalités. <b>Vous perdrez toute pertinence des résultats.</b> <br /><br />Vous devez créer une recherche supplémentaire portant sur les autres temporalités.'
          },
          {
            element: document.querySelector<HTMLElement>('.criterionTypeTitleTour'),
            title: 'En savoir plus',
            intro: 'Si vous souhaitez en savoir plus sur notre offre <b>Performance</b>, nous vous invitons à contacter votre commercial dédié.'
          }
        ];
      }
    }

    let a = this;
    introJs().setOptions({
      nextLabel: '>>',
      prevLabel: '<<',
      doneLabel: 'Commencer',
      disableInteraction: true,
      steps: steps
    }).start();
  }

  ngAfterViewInit() {
    const criterionsTypes = Utils.deepCopy(this.searchesService.allCriterions);

    this.emptyCriterionSelection(criterionsTypes);

    // this.searchesService.getCriterions().pipe().subscribe((criterionsTypes: CriterionsTypesInterface) => {
    this.searchesService.setAllCriterions(criterionsTypes);
    this.listCriterionsComponent.initCriterions(criterionsTypes);

    if (this.data && this.data.search) {
      this.listCriterionsComponent.initKeywordToEdit({must: this.data.search.data.keywords_must, should: this.data.search.data.keywords_should, mustNot: this.data.search.data.keywords_must_not, all: []});
      if (this.data.search.data.geolocDistanceRadius) {
        this.searchesService.getCityById(this.data.search.data.cityFrom).subscribe(value =>  {
          const geoloc =  {
            cityFrom: this.data.search.data.cityFrom,
            geolocFrom: '',
            geolocDistanceRadius: this.data.search.data.geolocDistanceRadius,
            cityName: value.name + ' (' + value.zip + ')'
          };
          this.listCriterionsComponent.initGeoloc(geoloc);
        });
      }
      this.searchesService.setSearchToEdit(this.data.search);
      this.searchToEdit = this.searchesService.getSearchToEdit();
      if (this.searchesService.getSearchToEdit().data.sectors) {
        for (let sector of this.searchesService.getSearchToEdit().data.sectors) {
          this.selectCriterion({criterionId: sector, type: 'sectors'});
        }
      }
      if (this.searchesService.getSearchToEdit().data.topics) {
        for (let topic of this.searchesService.getSearchToEdit().data.topics) {
          this.selectCriterion({criterionId: topic, type: 'topics'});
        }
      }
      if (this.searchesService.getSearchToEdit().data.companySizes) {
        for (let companySize of this.searchesService.getSearchToEdit().data.companySizes) {
          this.selectCriterion({criterionId: companySize, type: 'companySizes'});
        }
      }
      if (this.searchesService.getSearchToEdit().data.activityTypes) {
        for (let activityType of this.searchesService.getSearchToEdit().data.activityTypes) {
          this.selectCriterion({criterionId: activityType, type: 'activityTypes'});
        }
      }
      if (this.searchesService.getSearchToEdit().data.locations) {
        for (let location of this.searchesService.getSearchToEdit().data.locations) {
          this.selectCriterion({criterionId: location, type: 'locations'});
        }
      }
      this.checkResults();
    }
    else {
      this.searchesService.setSearchToEdit(null);
    }
    // });
  }

  leftPartEventFired(typeCriterion: any) {
    this.listCriterionsComponent.loadCriterions(typeCriterion);
    this.checkResults();
    this.selectedCriterionsComponent.openCriterion(typeCriterion);
  }

  openPreview() {
    if (this.resultCount <= 0) {
      return;
    }

    const selectedKeywords = this.listCriterionsComponent.getKeywords();

    this.keywords = selectedKeywords.all;

    this.previewResults = true;
    this.loadingInProgress = true;

    const allCriterions = this.listCriterionsComponent.getCriterions();
    const selectedCriterions = this.findSelectedCriterions(allCriterions);

    const Query = {
      articles: this.queryResult.articles,
      leads: null,
      criterions: selectedCriterions.selectedCriterions,
      customer: null,
      isMonitoring: null
    };
    this.articlesService.getArticlesContent(Query).subscribe(value => {
      this.tableDataSource.data = value;
      this.loadingInProgress = false;
    });

  }

  checkResults() {
    this.loading = true;
    const allCriterions = this.listCriterionsComponent.getCriterions();
    const selectedKeywords = this.listCriterionsComponent.getKeywords();

    this.keywords = selectedKeywords.all;

    const selectedCriterions = this.findSelectedCriterions(allCriterions);

    const dates = this.selectedCriterionsComponent.getDates();

    // let [dayStart, monthStart, yearStart] = dates.dateStart.split('-');
    // let [dayEnd, monthEnd, yearEnd] = dates.dateEnd.split('-');

    const query: ArticleQueryInterface = {
      articlesSortingMethod: this.articlesSortingMethod,
      articlesSortingOption: this._sortingOption,
      criterions: selectedCriterions.selectedCriterions,
      keywords: [],
      keywords_default_operator: 'and',
      keywords_must: selectedKeywords.must,
      keywords_must_exact: [],
      keywords_must_not: selectedKeywords.mustNot,
      keywords_should: selectedKeywords.should,
      page: 1,
      pageSize: 25,
      sdate: dates.dateStart,
      edate: dates.dateEnd,
      stype: 'business_signal',
      geoloc_from: this.listCriterionsComponent.geoloc ? this.listCriterionsComponent.geoloc.geolocFrom : '',
      city_from: this.listCriterionsComponent.geoloc ? this.listCriterionsComponent.geoloc.cityFrom : null,
      geoloc_distance_radius: this.listCriterionsComponent.geoloc ? this.listCriterionsComponent.geoloc.geolocDistanceRadius : null,
    };

    // create search data interface for the summary panel
    this.currentSearchData = {
      label: '',
      searchType: 'business_signal',
      locations: selectedCriterions.locations,
      sectors: selectedCriterions.sectors,
      topics: selectedCriterions.topics,
      activityTypes: selectedCriterions.activityTypes,
      companySizes: selectedCriterions.companySizes,
      keywords: [],
      keywords_default_operator: 'and',
      keywords_must: selectedKeywords.must,
      keywords_must_exact: [],
      keywords_must_not: selectedKeywords.mustNot,
      keywords_should: selectedKeywords.should,
      alertState: false,
      geolocFrom: this.listCriterionsComponent.geoloc ? this.listCriterionsComponent.geoloc.geolocFrom : '',
      cityFrom: this.listCriterionsComponent.geoloc ? this.listCriterionsComponent.geoloc.cityFrom : null,
      geolocDistanceRadius: this.listCriterionsComponent.geoloc ? this.listCriterionsComponent.geoloc.geolocDistanceRadius : null,
      libelles: null
    };

    this.searchesService.geolocActive = this.listCriterionsComponent.geoloc?.geolocDistanceRadius ? true : false;

    this.selectedCriterionsComponent.setCurrentSearch(this.currentSearchData);
    this.searchesService.selectedCriterionsObj = this.currentSearchData;

    // console.log('query');
    // console.log(query);
    // console.log('search');
    // console.log(this.currentSearchData);

    // If no criterions no check results
    if ((!selectedCriterions.selectedCriterions || selectedCriterions.selectedCriterions.length <= 0) && !this.currentSearchData.geolocDistanceRadius) {
      if ((!selectedKeywords.must || selectedKeywords.must.length <= 0) && (!selectedKeywords.mustNot || selectedKeywords.mustNot.length <= 0) && (!selectedKeywords.should || selectedKeywords.should.length <= 0)) {
        this.loading = false;
        this.resultCount = 0;
        if (this.previewResults) {
          this.previewResults = false;
        }
        this.selectedCriterionsComponent.clearParameters();
        return;
      }
    }

    this.articleService.getArticlesSearchForPreviex(query).subscribe(result => {
      this.loading = false;
      this.resultCount = result.articlesCount;
      this.queryResult = result;
      this.selectedCriterionsComponent.setSearchResultCounts(result);

      if (this.previewResults) {
        this.openPreview();
      }

    });

  }

  findSelectedCriterions(allCriterions: CriterionsTypesInterface) {
    const selectedCriterions: Array<number> = new Array<number>();

    const allSelectedCriterions = {
      selectedCriterions: new Array<number>(),
      sectors: new Array<number>(),
      locations: new Array<number>(),
      companySizes: new Array<number>(),
      activityTypes: new Array<number>(),
      topics: new Array<number>(),
    };
    let currentCriterions: Array<CriterionInterface> = allCriterions['sectors'].values;
    this.fetchCriterions(allSelectedCriterions, currentCriterions, 'sectors');
    currentCriterions = allCriterions['locations'].values;
    this.fetchCriterions(allSelectedCriterions, currentCriterions, 'locations');
    currentCriterions = allCriterions['companySizes'].values;
    this.fetchCriterions(allSelectedCriterions, currentCriterions, 'companySizes');
    currentCriterions = allCriterions['activityTypes'].values;
    this.fetchCriterions(allSelectedCriterions, currentCriterions, 'activityTypes');
    currentCriterions = allCriterions['topics'].values;
    this.fetchCriterions(allSelectedCriterions, currentCriterions, 'topics');

    return allSelectedCriterions;
  }

  private fetchCriterions(allSelectedCriterions, currentCriterions: Array<CriterionInterface>, type: string) {
    // tri pour gérer les leads et tempos
    currentCriterions.sort((a, b) => {
      if (a.subType === 'predictive') {
        return -1;
      }
      if (b.subType === 'predictive') {
        return 1;
      }
      if (a.subType === 'temporal') {
        if (b.subType === 'temporal') {
          return a.label.localeCompare(b.label);
        }
        else {
          return -1;
        }
      }
      else if (b.subType === 'temporal') {
        return 1;
      }
      return a.label.localeCompare(b.label);
    });

    let leadCheck = false;
    for (let crit of currentCriterions) {
      if (leadCheck && !(crit.subType === 'temporal') && !(crit.subType === 'predictive')) {
        return;
      }

      if (crit.checked_) {
        allSelectedCriterions.selectedCriterions.push(Number(crit.id));
        allSelectedCriterions[type].push(Number(crit.id));

        if (crit.subType === 'temporal' || crit.subType === 'predictive') {
          leadCheck = true;
        }
      } else {
        if (crit.childCriterions && crit.childCriterions.length > 0) {
          this.fetchCriterions(allSelectedCriterions, crit.childCriterions, type);
        }
      }

    }
  }

  changeSelection($event: any) {
    if ($event.dateStart) {
      // this.checkResults();
    }
    else {
      const allCriterions = this.listCriterionsComponent.getCriterions();
      for (let crit of allCriterions[$event.type].values) {
        if (crit.id === $event.criterionId) {
          crit.checked_ = false;
          this.uncheckChildren(crit.childCriterions);
          break;
        }
        this.changeChildSelection(crit.childCriterions, $event.criterionId);
      }
    }
    this.checkResults();
  }

  changeChildSelection(children, criterionId: number) {
    if (children && children.length > 0) {
      for (let crit of children) {
        if (crit.id === criterionId) {
          crit.checked_ = false;
          this.uncheckChildren(crit.childCriterions);
          break;
        }
        this.changeChildSelection(crit.childCriterions, criterionId);
      }
    }
  }

  private uncheckChildren(children) {
    if (children && children.length > 0) {
      for (let crit of children) {
        crit.checked_ = false;
        this.uncheckChildren(crit.childCriterions);
      }
    }
  }

  closeDialog() {
    // this.modalController.dismiss();
    this.modalService.confirmModal('Edition de la recherche', 'En quittant maintenant, votre recherche ne sera pas enregistrée.',  (value => {
       this.dialogRef.close();
    }));
  }

  private selectCriterion(param: { criterionId: number; type: string }) {
    const allCriterions = this.listCriterionsComponent.getCriterions();
    for (let crit of allCriterions[param.type].values) {
      if (crit.id === param.criterionId) {
        crit.checked_ = true;
        this.selectChildren(crit.childCriterions);
        break;
      }
      this.selectSubCriterion(param, crit);
    }
  }

  private selectSubCriterion(param: { criterionId: number; type: string }, parent) {
    for (let crit of parent.childCriterions) {
      if (crit.id === param.criterionId) {
        crit.checked_ = true;
        if (crit.subType === 'temporal' || crit.subType === 'predictive') {
          this.selectChildTempoLeads(parent, crit);
        }
        this.selectChildren(crit.childCriterions);
        break;
      }
      this.selectSubCriterion(param, crit);
    }
  }

  private selectChildTempoLeads(criterion, tempolead: any) {
    for (let crit of criterion.childCriterions) {
      if (crit.subType === 'temporal' || crit.subType === 'predictive') {

      }
      else {
        for (let sub of crit.childCriterions) {
          if (sub.subType === tempolead.subType && sub.subSubType === tempolead.subSubType) {
            sub.checked_ = true;
            break;
          }
        }
        this.selectChildTempoLeads(crit, tempolead);
      }
    }
  }

  private selectChildren(children) {
    if (children && children.length > 0) {
      for (let crit of children) {
        if (crit.subType === 'temporal' || crit.subType === 'predictive') {
          // on fait rien je pense la
        } else {
          crit.checked_ = true;
          this.selectChildren(crit.childCriterions);
        }
      }
    }
  }

  saveSearch() {
    const allCriterions = this.listCriterionsComponent.getCriterions();
    const selectedKeywords = this.listCriterionsComponent.getKeywords();

    const selectedCriterions = this.findSelectedCriterions(allCriterions);

    // If no criterions no check results
    if ((!selectedCriterions.selectedCriterions || selectedCriterions.selectedCriterions.length <= 0) && !this.currentSearchData.geolocDistanceRadius) {
      if ((!selectedKeywords.must || selectedKeywords.must.length <= 0) && (!selectedKeywords.mustNot || selectedKeywords.mustNot.length <= 0) && (!selectedKeywords.should || selectedKeywords.should.length <= 0)) {
        this.resultCount = 0;
        this.toastService.showDangerSnackbar('Enregistrement de la recherche', 'Votre recherche ne contient aucun critère.');
        return;
      }
    }

    if (this.searchesService.getSearchToEdit()) {
      this.modalService.confirmModal('Edition de la recherche', 'Les critères de la recherche "' + this.searchesService.getSearchToEdit().data.label + '" vont être modifiés, voulez-vous continuer ?',  (value => {
        this.searchesService.editMySearch(this.searchesService.getSearchToEdit().searchId, this.currentSearchData).subscribe(result => {
          this.toastService.showSuccessSnackbar('Edition de la recherche', 'La recherche "' + this.searchesService.getSearchToEdit().data.label + '" a bien été modifiée.');
          this.dialogRef.close(true);
        });
      }));
    } else {
      //dialog name choice
      this.modalService.inputModal('Enregistrement de la recherche', 'Indiquer le nom de la recherche à créer', 'Nom de la recherche', 'Nom de la recherche', '',  (value => {
        this.currentSearchData.label = value;
        if (!value || value.length <= 0) {
          this.toastService.showDangerSnackbar('Enregistrement de la recherche', 'Votre recherche doit avoir un nom pour être enregistrée.');
        }
        else {
          this.searchesService.addMySearch(this.currentSearchData).subscribe(result => {
            this.toastService.showSuccessSnackbar('Enregistrement de la recherche', 'La recherche "' + this.currentSearchData.label + '" a bien été créée.');
            this.dialogRef.close(true);
          });
        }
      }));
    }
  }

  changeCriterionSelection($event: any) {
    this.checkResults();
  }

  private emptyCriterionSelection(criterionsTypes: CriterionsTypesInterface) {
    for (let crit of criterionsTypes['topics'].values) {
      crit.checked_ = false;
      this.uncheckChildren(crit.childCriterions);
    }
    for (let crit of criterionsTypes['companySizes'].values) {
      crit.checked_ = false;
      this.uncheckChildren(crit.childCriterions);
    }
    for (let crit of criterionsTypes['activityTypes'].values) {
      crit.checked_ = false;
      this.uncheckChildren(crit.childCriterions);
    }
    for (let crit of criterionsTypes['locations'].values) {
      crit.checked_ = false;
      this.uncheckChildren(crit.childCriterions);
    }for (let crit of criterionsTypes['sectors'].values) {
      crit.checked_ = false;
      this.uncheckChildren(crit.childCriterions);
    }
  }

  closePreview() {
    this.previewResults = false;
    // this.listCriterionsComponent.loadCriterions(this.listCriterionsComponent.currentTypeCriterion);
  }

  protected readonly IconType = IconType;
  public menuOpenTri: boolean = false;
  public menuIconTri: string = 'keyboard_arrow_down';
  public _sortingOption: string = 'sortByRelevance';
  public articlesSortingMethod: string = 'publicationDateDesc';
  public modeTri: string = 'pertinence';

  onMenuTriClosed() {
    this.menuOpenTri = false;
    this.menuIconTri = 'keyboard_arrow_down';
  }

  onSortingOptionChange(sortingOption: string, sortingLabel: string) {
    if (sortingOption === 'sortByDateAsc') {
      this._sortingOption = 'sortByDate';
      this.articlesSortingMethod = 'publicationDateAsc';
    }
    else if (sortingOption === 'sortByDateDesc') {
      this._sortingOption = 'sortByDate';
      this.articlesSortingMethod = 'publicationDateDesc';
    }
    else {
      this._sortingOption = sortingOption;
    }

    this.modeTri = sortingLabel;
    this.checkResults();
  }

  onDropDownMenuOpenTri($event) {
    $event.stopPropagation();
    this.menuOpenTri = !this.menuOpenTri;
    if (this.menuOpenTri) {
      this.menuIconTri = 'keyboard_arrow_up';
    }
  }

  protected readonly ColorType = ColorType;
}
