import { Component, Inject } from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';

@Component({
  selector: 'fe-snackbar',
  templateUrl: './fe-snackbar.component.html',
  styleUrls: ['./fe-snackbar.component.scss']
})
export class FeSnackbarComponent {
  protected buttonColor: string = 'DEFAULT';
  protected title: string = 'Titre par défaut';
  protected text: string = 'Texte par défaut';
  protected acceptBtnLabel: string = 'Accepter';
  private timerSubscription: any;
  protected progress: number = 0;
  protected duration: number = 0;
  protected callbackFunction?: (args: any) => void;

  constructor(@Inject(MAT_SNACK_BAR_DATA) protected data: string,
              private _snackRef: MatSnackBarRef<FeSnackbarComponent>) {
    if (data != null) {
      this.buttonColor = data['color'];
      this.title = data['title'];
      this.text = data['text'];

      if (data['duration']) {
        this.duration = data['duration'];
        this.startTimer();
      } else {
        this.duration = 0;
      }

      if (data['actionToDo']) {
        this.callbackFunction = this.data['actionToDo'];
      }

      if (data['acceptBtnLabel']) {
        this.acceptBtnLabel = this.data['acceptBtnLabel'];
      }
    }
  }

  closeSnackbar() {
    this._snackRef.dismiss();
  }

  doAction() {
    this.callbackFunction('');
  }

  private startTimer(): void {
    this.timerSubscription = setInterval(() => {
      if (this.progress < 100) {
        this.progress += 100 / (this.duration * 5);
      } else {
        this.stopTimer();
      }
    }, 200);
  }

  private stopTimer(): void {
    clearInterval(this.timerSubscription);
  }
}
