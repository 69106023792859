<div class="monitoringAttachmentSharingModal feModal">
    <div class="feModalHeader">
        <span class="feModalTitle">Ajouter des pièces-jointes</span>
        <div class="feModalCloseButton">
            <span mat-dialog-close>Fermer</span>
            <mat-icon (click)="close()">close</mat-icon>
        </div>
    </div>
    <div class="feModalContent">
        <fe-toggle text="Privé/Publique" [(value)]="!this.isAttachmentPrivate" style="display: flex;"
                   (valueChange)="onAttachmentStatusChange($event)"/>
        <ng-container *ngIf="!this.isAttachmentPrivate &&  monitoredArticle.associatedCustomersRealName?.length > 0">
            <h3 style="font-weight: bold; margin: 15px 0px 3px 0px;">Notifier par email les collaborateurs :</h3>
            <mat-list style="max-height: 200px; overflow: auto;">
                <ng-container *ngIf="companyCustomers!= undefined && companyCustomers.length; else noData">
                    <div style="border: 1px solid #111111; border-radius:5px; max-height: 200px; overflow: auto; padding: 0px 5px 0 5px;">
                        <mat-list-item class="list-item-container"
                                       *ngFor="let collaborator of companyCustomers">
                            <mat-checkbox [checked]="selectionModel.isSelected(collaborator)"
                                          (click)="$event.stopPropagation()"
                                          (change)="selectionModel.toggle(collaborator)">
                            <span style="margin: 0" class="collaborator-fullname">
                                {{collaborator.name}}  ({{collaborator.email}})
                            </span>
                            </mat-checkbox>
                        </mat-list-item>
                    </div>
                </ng-container>
                <ng-template #noData>
                    <no-data>{{'Aucun collaborateur'}}</no-data>
                </ng-template>
            </mat-list>
        </ng-container>
        <div class="form-group">
            <input type="file" multiple [(ngModel)]="fileInput" class="fileInputZone"
                   (change)="handleFileInput($event.target.files)">
        </div>
        <div style="text-align: center;">
            <h3 style="font-weight: bold;">Pièce(s) jointe(s) ajoutée(s) à cet article suivi</h3>

            <div class="attachmentLine" *ngFor="let attachment of monitoredArticleAttachments">
                <fe-button [text]="attachment.filename" (click)="openFile(attachment)"
                           type="TERTIARY" smallButton="true" fullWidth="true" class="feOverflowBtn"/>
                <fe-button hideText="true" [iconType]="IconType.delete" icon="true" type="TERTIARY"
                           (click)="deleteMonitoredArticleAttachment(attachment)" style="margin-left: auto;"/>
            </div>

            <div *ngIf="monitoredArticleAttachments.length === 0" style="margin-bottom: 25px;">
                <p>Aucune pièce-jointe ajoutée à cet article suivi</p>
            </div>
        </div>
    </div>
    <div class="feModalFooter">
        <fe-button text="Fermer" (click)="close()"/>
    </div>
</div>
