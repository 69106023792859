import { Injectable } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class MediaQueryService {

  private _mobileQuery: MediaQueryList;

  public get mobileQuery(): MediaQueryList {
    return this._mobileQuery;
  }

  constructor(
    private mediaMatcher: MediaMatcher
  ) {
    // https://material.angular.io/cdk/layout/overview
    this._mobileQuery = this.mediaMatcher.matchMedia('(max-width: 900px)');
  }

}
