<div class="feModal feCarnetContactModal">
    <div class="feModalHeader">
        <span class="feModalTitle">{{title}}</span>
        <div class="feModalCloseButton" mat-dialog-close>
            <span mat-dialog-close>Fermer</span>
            <mat-icon mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="feModalContent">
        <div class="inputField">
            <span class="moderateText">Nom du contact</span>
            <fe-input [(value)]="contact.label"/>
        </div>
        <div class="inputField">
            <span class="moderateTextBold">Adresse e-mail du contact*</span>
            <fe-input (focusout)="updateMailErrorMsg()" [(value)]="contact.email"/>
            <span *ngIf="mailErrorMsg" class="moderateText dangerColor">
                Le format de l'adresse e-mail n'est pas correct.
            </span>
        </div>
    </div>
    <div class="feModalFooter">
        <fe-button mat-dialog-close color="DANGER" type="TERTIARY" [text]="'Annuler'"/>
        <fe-button (click)="confirmBtnClick()" [text]="buttonText"/>
    </div>
</div>
