<ion-header no-border>
  <ion-toolbar color="secondary" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
    <ion-buttons slot="end">
      <button mat-icon-button *ngIf="selectionModel.selected.length == 0" (click)="openAddressFormModal('add')">
        <mat-icon class="outlined">person_add_outline</mat-icon>
      </button>
    </ion-buttons>
    <ion-buttons slot="end">
      <button mat-icon-button *ngIf="selectionModel.selected.length > 0" (click)="askBeforeRemoveAddresses(selectionModel.selected)">
        <mat-icon>delete_outline</mat-icon>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <mat-list>

    <ng-container *ngIf="(addresses$ | async) as addresses; else loading">
      <ng-container *ngIf="addresses.address.length; else noData">
        <mat-list-item class="list-item-container" *ngFor="let address of addresses.address">
          <mat-checkbox [checked]="selectionModel.isSelected(address)" (click)="$event.stopPropagation()"
            (change)="selectionModel.toggle(address)"></mat-checkbox>
          <div class="address-fullname">
            <h4>{{address.data.label}}</h4>
            <p>{{address.data.email}}</p>
          </div>
          <ion-buttons slot="end">
            <button mat-icon-button  [disabled]="selectionModel.selected.length > 0" (click)="openAddressFormModal('edit', address)">
              <mat-icon class="outlined">edit_outline</mat-icon>
            </button>
          </ion-buttons>
          <ion-buttons slot="end">
            <button mat-icon-button [disabled]="selectionModel.selected.length > 0" (click)="askBeforeRemoveAddress(address)">
              <mat-icon class="outlined">delete_outline</mat-icon>
            </button>
          </ion-buttons>
        </mat-list-item>
      </ng-container>
    </ng-container>

    <ng-template #loading>
      <loading-overlay>{{"LOADING_ADDRESSES"}}</loading-overlay>
    </ng-template>
    <ng-template #noData>
      <no-data>{{"NO_ADDRESSES"}}</no-data>
    </ng-template>
   
  </mat-list>

</ion-content>
