<mat-radio-group [formControl]="formControl">
  <mat-radio-button *ngFor="let option of field.options" [value]="option.value">{{ option.name }}</mat-radio-button>
  <mat-hint align="start" *ngIf="field.hint && field.hint.start"><strong>{{field.hint.start}}</strong> </mat-hint>
  <mat-hint align="end" *ngIf="field.hint && field.hint.end && (!field.validators || !field.validators.maxLength) "><strong>{{field.hint.end}}</strong>
  </mat-hint>
  <mat-hint align="end" *ngIf="field.validators && field.validators.maxLength"><strong>&nbsp;{{formControl.value.length}}
      / {{field.validators.maxLength}}</strong> </mat-hint>
  
<!--  <mat-error class="customMatError" *ngIf="formControl.hasError('valid') && !formControl.hasError('required')">-->
<!--    <span [innerHtml]="'E_FIELD_NO_VALID' | translate"></span>-->
<!--  </mat-error>-->
<!--  <mat-error class="customMatError" *ngIf="formControl.hasError('required') ">-->
<!--    <span [innerHtml]="'E_FIELD_NAME_REQUIRED' | translate:field"></span>-->
<!--  </mat-error>-->
<!--  <mat-error class="customMatError" *ngIf="formControl.hasError('min') && !formControl.hasError('required') ">-->
<!--    <span [innerHtml]="'E_FIELD_NO_VALID_MIN' | translate:field"></span>-->
<!--  </mat-error>-->
<!--  <mat-error class="customMatError" *ngIf="formControl.hasError('minlength') && !formControl.hasError('required') ">-->
<!--    <span [innerHtml]="'E_FIELD_NO_VALID_MIN_LENGTH' | translate:field"></span>-->
<!--  </mat-error>-->
<!--  <mat-error class="customMatError" *ngIf="formControl.hasError('max') && !formControl.hasError('required') ">-->
<!--    <span [innerHtml]="'E_FIELD_NO_VALID_MAX' | translate:field"></span>-->
<!--  </mat-error>-->
<!--  <mat-error class="customMatError" *ngIf="formControl.hasError('maxlength') && !formControl.hasError('required') ">-->
<!--    <span [innerHtml]="'E_FIELD_NO_VALID_MAX_LENGTH' | translate:field"></span>-->
<!--  </mat-error>-->
<!--  <mat-error class="customMatError" *ngIf="formControl.hasError('pattern') && !formControl.hasError('required') ">-->
<!--    <span [innerHtml]="'E_FIELD_NO_VALID_PATTERN' | translate"></span>-->
<!--  </mat-error>-->
</mat-radio-group>
