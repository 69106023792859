import { Component, forwardRef, OnDestroy, ViewChild, ElementRef, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl, Validators } from '@angular/forms';
import { XFieldComponent } from '../x-field/x-field.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fromEvent, Observable } from 'rxjs';
import { takeUntil, map, debounceTime, startWith } from 'rxjs/operators';
import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js';
import { CountryCodeValidator } from 'src/app/validators/country-code/country-code.validator';


@Component({
  selector: 'x-input-phone',
  templateUrl: 'x-input-phone.component.html',
  styleUrls: ['./x-input-phone.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => XInputPhoneComponent), multi: true }
  ]
})
export class XInputPhoneComponent extends XFieldComponent implements OnInit, OnDestroy {

  // GB 01634541234
  // FR 0385244229
  // BE 025512020
  // DE 30120221210

  @ViewChild('countryCode', {static: false}) protected countryCode: ElementRef;
  @ViewChild('input', { static: true }) protected input: ElementRef;

  // tslint:disable-next-line:max-line-length
  public countryCodes: Array<CountryCode> = ['AC', 'AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM', 'AO', 'AR', 'AS', 'AT', 'AU', 'AW', 'AX', 'AZ', 'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BL', 'BM', 'BN', 'BO', 'BQ', 'BR', 'BS', 'BT', 'BW', 'BY', 'BZ', 'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL', 'CM', 'CN', 'CO', 'CR', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ', 'EC', 'EE', 'EG', 'EH', 'ER', 'ES', 'ET', 'FI', 'FJ', 'FK', 'FM', 'FO', 'FR', 'GA', 'GB', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN', 'GP', 'GQ', 'GR', 'GT', 'GU', 'GW', 'GY', 'HK', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN', 'IO', 'IQ', 'IR', 'IS', 'IT', 'JE', 'JM', 'JO', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KW', 'KY', 'KZ', 'LA', 'LB', 'LC', 'LI', 'LK', 'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH', 'MK', 'ML', 'MM', 'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE', 'NF', 'NG', 'NI', 'NL', 'NO', 'NP', 'NR', 'NU', 'NZ', 'OM', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL', 'PM', 'PR', 'PS', 'PT', 'PW', 'PY', 'QA', 'RE', 'RO', 'RS', 'RU', 'RW', 'SA', 'SB', 'SC', 'SD', 'SE', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 'SR', 'SS', 'ST', 'SV', 'SX', 'SY', 'SZ', 'TA', 'TC', 'TD', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR', 'TT', 'TV', 'TW', 'TZ', 'UA', 'UG', 'US', 'UY', 'UZ', 'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 'WF', 'WS', 'XK', 'YE', 'YT', 'ZA', 'ZM', 'ZW'];
  public filteredCountryCodes$: Observable<string[]>;
  public countryCodeFormControl: FormControl;
  public phoneNumberFormControl: FormControl;

  constructor(
    protected matSnackBar: MatSnackBar
    , @Inject(LOCALE_ID) private locale: string
  ) {
    super(matSnackBar);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.countryCodeFormControl = new FormControl('', [Validators.required, CountryCodeValidator.isValideCountryCode(this.countryCodes)]);
    const phoneNumberCountryCode = parsePhoneNumberFromString(this.formControl.value);
    const countryCode: string = phoneNumberCountryCode ? phoneNumberCountryCode.country : 'FR';
    this.countryCodeFormControl.setValue(countryCode);

    this.phoneNumberFormControl = new FormControl('');
    this.phoneNumberFormControl.setValidators(this.formControl.validator);

    this.formControl['$$countryCode'] = countryCode;

    this.filteredCountryCodes$ = this.countryCodeFormControl.valueChanges
    .pipe(
      startWith(''),
      map((value: string) => {
        // yes i know, it's dirty...
        this.formControl['$$countryCode'] = this.getCountryCode();
        // on trigger le keyup pour lancer le check de l'input qui contient le numéro
        this.input.nativeElement.dispatchEvent(new Event('keyup'));
        return this._filter(value);
      }),
      takeUntil(this.unsubscribe$),
      debounceTime(100),
    );

    fromEvent(this.input.nativeElement, 'keyup')
    .pipe(
      startWith({target: {value: ''}}),
      debounceTime(300),
      takeUntil(this.unsubscribe$)
    ).subscribe(($event: any) => {

      const phoneNumber = parsePhoneNumberFromString(this.formControl.value, this.getCountryCode());
      if (phoneNumber && phoneNumber.isValid()) {
        this.formControl.setValue(phoneNumber.formatInternational());
      }

      this.formControl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.countryCodes.filter(option => option.toLowerCase().includes(filterValue));
  }

  public ngOnDestroy(): void {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public getCountryCode(): CountryCode {
    let countryCode: CountryCode = 'FR';
    if (this.countryCodeFormControl.value !== 'undefined') {
      countryCode = this.countryCodeFormControl.value as CountryCode;
    }
    return countryCode;
  }
}
