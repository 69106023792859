<div class="contacts-container" *ngIf="contacts != null && contacts.length > 0" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
  <ng-container *ngIf="contacts.length > 0">
    <button mat-stroked-button style="margin: auto;" color="primary" class="contacts-button"
            [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}" [ngPlural]="contacts.length-1">
      <mat-icon>person</mat-icon>{{contacts.length}}
      <ng-template ngPluralCase="=0">contact</ng-template>
      <ng-template ngPluralCase="other">contacts</ng-template>
    </button> 
  </ng-container>
</div>
