import {Component, EventEmitter, Inject, Output, ViewChild} from '@angular/core';
import {AlertController, ModalController, Platform} from '@ionic/angular';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ArticleInterface} from '../../../interfaces/articles/article.interface';
import {FeFicheEntrepriseModalComponent} from '../fe-fiche-entreprise-modal/fe-fiche-entreprise-modal.component';
import {ModalService} from '../../../services/modal/modal.service';
import {EntrepriseInterface} from '../../../interfaces/entreprise/entreprise.interface';
import * as moment from 'moment/moment';
import {SourceInterface} from '../../../interfaces/source/source.interface';
import {FeMailModalComponent} from '../fe-mail-modal/fe-mail-modal.component';
import {AddFavoriteModalComponent} from '../add-favorite-modal/add-favorite-modal.component';
import {
  SupervisorMonitoringModalComponent
} from '../../supervisor-monitoring-modal/supervisor-monitoring-modal.component';
import {FavoritesService} from '../../../services/favorites/favorites.service';
import {SnackbarService} from '../../../services/snackbar/snackbar.service';
import {UserService} from '../../../services/user/user.service';
import {ArticleMonitoringService} from '../../../services/article-monitoring/article-monitoring.service';
import {SessionInterface} from '../../../interfaces/session/session.interface';
import {takeUntil} from 'rxjs/operators';
import {ArticlesService} from '../../../services/articles/articles.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ColorType, IconType} from '../../parts/fe-icon/fe-icon.component';
import {Utils} from '../../../classes/Utils';
import {FavorisFolder} from '../../../interfaces/favoritesFolders/favorites-folders.interface';
import {ContactLineInterface, FeCarnetAdressesModalComponent} from '../fe-carnet-adresses-modal/fe-carnet-adresses-modal.component';
import {AddressesInterface} from '../../../interfaces/addresses/addresses';
import {AddressesService} from '../../../services/addresses/addresses.service';
import {Observable, ReplaySubject, Subscriber} from 'rxjs';
import {FeCarnetContactModalComponent} from '../fe-carnet-adresses-modal/fe-carnet-contact-modal/fe-carnet-contact-modal.component';
import {PredictionInterface} from '../../../interfaces/tag/prediction';
import {DOCUMENT, formatDate} from '@angular/common';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {
  MonitoredArticleActivityInterface
} from '../../../interfaces/monitored-article-comments/monitored-article-activity.interface';
import {
  MonitoringEditCommentModalComponent
} from '../../monitoring-edit-comment-modal/monitoring-edit-comment-modal.component';
import {ErrorStatus} from '../../../classes/ErrorStatus.class';
import {
  MonitoringCommentSharingModalComponent
} from '../../monitoring-comment-sharing-modal/monitoring-comment-sharing-modal.component';
import {LoadingDisplayerComponent} from '../loading-displayer/loading-displayer.component';
import {
  MonitoredArticleAttachmentInterface
} from '../../../interfaces/monitored-article-attachments/monitored-article-attachment.interface';
import {
  MonitoringAttachmentSharingModalComponent
} from '../../monitoring-attachment-sharing-modal/monitoring-attachment-sharing-modal.component';
import * as FileSaver from 'file-saver';
import {AuthService} from '../../../services/auth/auth.service';
import {MonitoredArticleInterface} from '../../../interfaces/monitored-articles/monitored-article.interface';
import {CreateLeadModalComponent} from "../create-lead-modal/create-lead-modal.component";
import {MediaQueryService} from "../../../services/media-query/media-query.service";
import {PrintInterface} from '../../../interfaces/print/print.interface';
import {Printer, PrintOptions} from '@awesome-cordova-plugins/printer/ngx';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'fe-article-modal',
  templateUrl: './fe-article-modal.component.html',
  styleUrls: ['./fe-article-modal.component.scss']
})
export class FeArticleModalComponent {

  constructor(private modalController: ModalController,
              @Inject(DOCUMENT) private document: Document,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _modalService: ModalService,
              public mediaQueryService: MediaQueryService,
              private favoritesService: FavoritesService,
              private snackBarService: SnackbarService,
              private userService: UserService,
              private platform: Platform,
              private printer: Printer,
              private alertController: AlertController,
              private articleMonitoringService: ArticleMonitoringService,
              private articlesService: ArticlesService,
              protected addressesService: AddressesService,
              public sanitizer: DomSanitizer,
              public dialogRef: MatDialogRef<FeArticleModalComponent>,
              public authService: AuthService,
              public dialog: MatDialog) {

    this.article = data.article;
    this._keywords = data.keywords;

    /**
     * Suivi d'affaires
     */
    if(data.from) {
      this.from = data.from;
    }
    if(data.monitoredArticle) {
      this.monitoredArticle = data.monitoredArticle;
    }
    if(data.isVisualisation) {
      this.isVisualisation = data.isVisualisation;
    }
    if(data.fromComment != null) {
      this.fromComment = data.fromComment;
      if (!this.fromComment) {
        this.selectedMatTab.setValue(1);
      }
    }
    if (this.article.type === 'external_lead') {
      this.typeLead = true;
    }
    else if (this.article.type === 'local') {
      this.userService.log('ARTICLE_OPEN_MODAL', [this.article]);
    }
    if(this.article.monitoring) {
      this.isArchived = this.article.monitoring.isArchived;

      this.currentArticleAttributes = {
        articleId: this.article.id,
        monitoringId: this.article.monitoring.monitoringId,
        articleMonitoringPosition: this.article.monitoring.position,
        isArchived: this.article.monitoring.isArchived,
        monitoringPriority: this.article.monitoring.priority !== undefined ? this.article.monitoring.priority.valueOf() : '',
        monitoringStatus: this.article.monitoring.status
      };

    }
    this.userService.getSessionDatas().subscribe(
            (sessionData: SessionInterface) => {
              if (sessionData !== undefined) {
                if (sessionData.productOfferCode === 'OFFRE_3') {
                  this.isPerformance = true;
                }
                if (sessionData.companyCustomers !== undefined) {
                  this.nbCollaborateurs = sessionData.companyCustomers.length;
                }
                if (sessionData.customerDetails.isSupervisor !== null) {
                  this.customer = sessionData.customerDetails.user_id;
                  this.customerFirstname = sessionData.customerDetails.firstname;
                  this.customerLastname = sessionData.customerDetails.lastname;
                  this.isSupervisor = sessionData.customerDetails.isSupervisor;
                }
              }
            }
        );
    if (data.fromFavorite) {
      this.fromFavorite = data.fromFavorite;
    }
    this.favoritesService.getFavoritesFolders().subscribe((data: Array<FavorisFolder>) => {
      if (data != null) {
        this.myFavoritesList = data;
      }
    });
    if (data.fromFicheEntreprise) {
      this.isLoading = true;
      const Query = {
        articles: data.article.articleId
      };
      this.articlesService.getArticlesContent(Query).subscribe(
          (res) => {
            this.article = res[0];
            this.markArticleAsRead();
            this.isLoading = false;
          }
      );
    } else {
      this.markArticleAsRead();
    }

    this.loadAdresses();

    addressesService.onAddressesChange().subscribe(value => {
     this.loadAdresses();
    });
  }
  @Output() confirm = new EventEmitter<any>();

  protected article: ArticleInterface;
  protected fromFavorite: boolean = false;
  public isPerformance: boolean;
  public isSupervisor: boolean;
  private nbCollaborateurs: number;
  public listAssociatedCustomers: {user_id: string, name: string}[] = [];
  public shareArticleToolTip: String;
  protected isLoading: boolean = false;
  private _keywords: any;
  protected myFavoritesList: FavorisFolder[] = [];
  protected contactsLines: ContactLineInterface[] = [];
  protected addresses: Observable<AddressesInterface>;
  protected currentPrediction: PredictionInterface = null;

  // Pour suivi d'affaires
  public monitoredArticle: MonitoredArticleInterface;
  public from: string;
  public nbComments: ReplaySubject<number> = new ReplaySubject<number>();
  public monitoredArticleActivities: ReplaySubject<Array<MonitoredArticleActivityInterface>> = new ReplaySubject<Array<MonitoredArticleActivityInterface>>();
  public monitoredArticleActivities$: Observable<Array<MonitoredArticleActivityInterface>>;
  public monitoredArticleAttachments: ReplaySubject<Array<MonitoredArticleAttachmentInterface>> = new ReplaySubject<Array<MonitoredArticleAttachmentInterface>>();
  public monitoredArticleAttachments$: Observable<Array<MonitoredArticleAttachmentInterface>>;
  public customerFirstname: string;
  public customerLastname: string;
  public customer: string;
  public commentAddedOrDeleted: boolean = false;
  public attachmentAddedOrDeleted: boolean = false;
  public isVisualisation: Boolean;
  public fromComment: boolean;
  public typeLead: boolean = false; // article est de type local ou external_lead
  public commentContent = '';
  public isArchived: Boolean = false;
  public currentArticleAttributes;
  protected hideLeads = true;
  protected selectedMatTab = new FormControl(0);
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '6.5rem',
    minHeight: '5rem',
    placeholder: 'Entrer votre commentaire...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    toolbarHiddenButtons: [
      ['fontName', 'subscript', 'superscript', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent'],
      ['textColor', 'backgroundColor', 'link', 'unlink', 'insertImage', 'insertVideo', 'removeFormat', 'insertHorizontalRule', 'toggleEditorMode']
    ]
  };

  protected readonly IconType = IconType;
  protected readonly ColorType = ColorType;

  private loadAdresses(): void {
    this.contactsLines = [];
    this.addressesService.getAddresses().subscribe(
        (res: AddressesInterface) => {
          if (res && res.address) {
            res.address.forEach(value => {
              this.contactsLines.push({
                id: value.addressId,
                checked: false,
                label: value.data.label,
                email: value.data.email
              });
            });
          }
    });
  }

  public ngAfterViewInit(): void {
    this.dialogRef.afterClosed().subscribe(result => {
      this.userService.log('ARTICLE_CLOSE_MODAL', [this.article]);
    });

    if (this.article.monitoring != null && this.article.monitoring.associatedCustomersRealName !== undefined) {
      for (let i = 0; i < this.article.monitoring.associatedCustomersRealName.length; i++) {
        this.listAssociatedCustomers.push({user_id: this.article.monitoring.associatedCustomers[i], name: this.article.monitoring.associatedCustomersRealName[i]});
      }
    }
    if (this.listAssociatedCustomers.length > 0) {
      if (this.article.monitoring.status === undefined) {
        this.shareArticleToolTip = 'Article suivi par :\n';
        for (let i = 0; i < this.listAssociatedCustomers.length; i++) {
          this.shareArticleToolTip += this.listAssociatedCustomers[i].name + ', ';
        }
        this.shareArticleToolTip = this.shareArticleToolTip.slice(0, -2);
      } else {
        this.shareArticleToolTip = 'Article également suivi par :\n';
        for (let i = 0; i < this.listAssociatedCustomers.length; i++) {
          this.shareArticleToolTip += this.listAssociatedCustomers[i].name + ', ';
        }
        this.shareArticleToolTip = this.shareArticleToolTip.slice(0, -2);
      }
    }
    if (this.from === 'monitoring') {
      this.loadActivities();
      this.loadAttachments();

      if (this.fromComment != null) {
          const element = this.document.getElementById('matTabMonitoring');
          if (element != null) {
            const elementBottom = element.offsetTop + element.offsetHeight;
            setTimeout(() => {
              element.scrollIntoView({ behavior: 'smooth' });
            });
          }
      }
    }
  }

  public async openLeadCreationEditModal(): Promise<void> {
    this._modalService.showCustomModalWithActionOnClose(CreateLeadModalComponent, {
      data: {
        modification: true,
        article: this.article
      }}, (data) => {
      if (data !== undefined) {
        this.article.title = data.title;
        this.article.corps = data.content;
        if (data.creationDate !== undefined) {
          const momentVariable = moment(data.creationDate, 'DD/MM/YYYY');
          const dateTransform = momentVariable.format('YYYY-MM-DD hh:mm:ss');
          this.article.publicationDate = dateTransform;
        }
      }
    });
  }

  private markArticleAsRead(): void {
    if(!this.typeLead) {
      this.articlesService.markArticleAsRead(this.article.id)
          .pipe()
          .subscribe(
              res => {
                this.articlesService.updateReadArticle(this.article.id, true);
              }
          );
    }
  }

  protected showFicheEntreprise(entreprise: EntrepriseInterface): void {
    this._modalService.showCustomModal(FeFicheEntrepriseModalComponent, {
      data: {
        entreprise: entreprise,
        articleId: this.article.id
      }, disableClose: true});
  }

  protected getDate(): string {
    return (moment(this.article.publicationDate)).format('DD/MM/YYYY');
  }

  protected getSources(): string {

    let sourcesToString: string = 'Source : ';
    let first: boolean = true;
    for (const source of this.article.sources.sourcesArray) {
      if (!first) {
        sourcesToString += ' // ';
      }
      sourcesToString += this.getSourceFormated(source);
      first = false;
    }
    if (this.article.sources.signature != null && this.article.sources.signature.length > 0) {
      if (sourcesToString.length > 0) {
        sourcesToString += ' // ';
      }
      sourcesToString += this.article.sources.signature;
    }
    return sourcesToString;
  }

  private getSourceFormated(source: SourceInterface): string {
    let sourceToReturn: string = '';

    if (source?.name.length > 0) {
      if (source?.directUrl.length > 0) {
        sourceToReturn = '<a href="' + source.directUrl + '" target="_blank">' + source.name + '</a>';
      } else {
        sourceToReturn = source.name;
      }
    }

    if (source?.dateOrNumber.length > 0) {
      if (sourceToReturn.length > 0) {
        sourceToReturn += ', ';
      }
      sourceToReturn += source.dateOrNumber;
    }

    if (source?.author.length > 0) {
      if (sourceToReturn.length > 0) {
        sourceToReturn += ', ';
      }
      sourceToReturn += source.author;
    }

    return sourceToReturn;
  }

  protected markArticleRead(read: boolean): void {
    this.article.read = read;
  }

  showSendArticleModal() {
    this._modalService.showCustomModalWithActionOnClose(FeMailModalComponent, {data: {
        articles: [this.article]
      }}, (response: Array<string>) => {
      response.forEach(value => {
        if (this.article.sharedTo == null) {
          this.article.sharedTo = [];
        }
        if (this.article.sharedTo.indexOf(value) < 0) {
          this.article.sharedTo.push(value);
        }
      });
    });
  }

  protected addToFavorite(): void {
    this._modalService.showCustomModalWithActionOnClose(AddFavoriteModalComponent, {data: {articles: [this.article.id],
        title: this.article.title}}, (response) => {
      if (response != null) {
        if (response.isAdd) {
          this.article.favorite = true;
          this.article.favoriteFolder = response.folderID;
          this.snackBarService.showSuccessSnackbar('Favori ajouté avec succès',
              'L\'article <b>\'' + this.troncateText(this.article.title, 50)
              + '\'</b> a bien été ajouté au dossier \'' + this.getFavoriteFolderLink(response.folderID, response.libelle) + '\'.');
          this.favoritesService.needRefresh.next(true);
        }
      }
    });
  }

  private troncateText(text: string, length: number): string {
    let troncateText: string = text;
    if (troncateText.length > length + 2) {
      troncateText = troncateText.substring(0, length) + '...';
    }
    return troncateText;
  }

  private getFavoriteFolderLink(folderId: number, folderLabel: string): string {
    return '<b><a class="greenC" href="/#/app/my-favorites?folderID='
        + folderId + '">' + this.troncateText(folderLabel, 40) + '</a></>';
  }

  // public monitorArticle(article: ArticleInterface) {
  //   const articleID = article.id;
  //   const customers = '';
  //   this.articleMonitoringService.addMonitoringArticles(articleID, true, customers, false, '', '').subscribe(next => {
  //
  //   });
  //   this.article.monitoring.status = 'to_process';
  // }

  async removeFavorite() {
    const alert = await this.alertController.create({
      header: 'Attention',
      message: 'Etes-vous sur de vouloir retirer cet article des favoris ?',
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => { }
        }, {
          text: 'Oui',
          handler: () => this.deleteFavorite(this.article.id)
        }
      ]
    });
    await alert.present();
  }

  public deleteFavorite(selectionModel: any) {
    this._modalService.confirmModal('Retrait du favori', 'Confirmez vous le retrait de l\'article <b>\''
        + this.troncateText(this.article.title, 50) + '\'</b> du dossier <b>\'' +
        this.getFavoriById(this.article.favoriteFolder).libelle + '\'</b> ?', () => {
      this.favoritesService.removeArticleFromFavorites(selectionModel).pipe().subscribe(
          () => {
            this.snackBarService.showSuccessSnackbar('Favori retiré avec succès', 'L\'article <b>\''
                + this.troncateText(this.article.title, 50) + '\'</b> a été retiré du dossier \''
                + this.getFavoriteFolderLink(this.article.favoriteFolder, this.getFavoriById(this.article.favoriteFolder).libelle) + '\' avec succès.');
            this.article.favorite = false;
            this.article.favoriteFolder = undefined;
            this.favoritesService.needRefresh.next(true);
          },
          (error: Error) => {
            this.snackBarService.showDangerSnackbar('Erreur', 'Une erreur s\'est produite lors de la suppression du favoris');
          }
      );
    });
  }

  private getFavoriById(folderId: number): FavorisFolder {
    let favoriToReturn: FavorisFolder = null;
    this.myFavoritesList.forEach(value => {
      if (value.folderID === folderId) {
        favoriToReturn = value;
      }
    });
    return favoriToReturn;
  }
  private showAddToMonitoringSuccess() {
    this.snackBarService.showSuccessSnackbar('Ajout au suivi d\'affaires',
        'L\'article <b>\'' + this.troncateText(this.article.title, 50)
        + '\'</b> a bien été ajouté au suivi d\'affaires.');
  }

  async addToSuiviAffaires() {
    // Cas non superviseur
    if (!this.isSupervisor) {
      if (this.article.monitoring.status === undefined) {
        this._modalService.confirmModal('Ajout au suivi d\'affaires', 'Confirmez-vous l\'ajout de l\'article <b>\'' + this.troncateText(this.article.title, 50) + '\'</b> à votre suivi d\'affaires ?', args => {
          this.monitorArticle(this.article);
        });
      } else {
        this.snackBarService.showDangerSnackbar('Ajout au suivi d\'affaires', 'L\'article <b>\'' + this.troncateText(this.article.title, 50) + '\'</b> est déjà dans votre suivi d\'affaires.');
      }
    } else { /*cas superviseur*/
      this._modalService.showCustomModalWithActionOnClose(SupervisorMonitoringModalComponent,
          { data: {
              article: this.article,
              isSupervisor: true,
              isFromMonitoringView: false,
              listAssociatedCustomers: this.listAssociatedCustomers
            }},
          (data) => {
            this.showAddToMonitoringSuccess();
            if (data !== undefined) {
              if (data.selfSelected) {
                this.article.monitoring.status = 'to_process';
              }
              for (let i = 0; i < data.customersSelected.length; i++) {
                const isInArray = this.listAssociatedCustomers.findIndex(obj => obj.user_id === data.customersSelected[i].user_id);
                if (isInArray === -1) {
                  this.listAssociatedCustomers.push({user_id: data.customersSelected[i].user_id, name: data.customersSelected[i].name});
                  if (this.shareArticleToolTip === undefined) {
                    this.shareArticleToolTip = 'Article suivi par :\n';
                    this.shareArticleToolTip += data.customersSelected[i].name + ' \n';
                  } else {
                    this.shareArticleToolTip += data.customersSelected[i].name + ' \n';
                  }
                }
              }
            }
          }
      );

    }
  }
  public monitorArticle(article: ArticleInterface) {
    const articleID = article.id;
    const customers = '';
    this.articleMonitoringService.addMonitoringArticles(articleID, true, customers, false, '', '').subscribe(next => {
      this.article.monitoring.status = 'to_process';
      this.showAddToMonitoringSuccess();
    });
  }

  // async addToSuiviAffaires() {
  //   // Cas non superviseur
  //   if (!this.isSupervisor) {
  //     if (this.article.monitoring.status === undefined) {
  //       const alert = await this.alertController.create({
  //         header: 'Ajout de l\'article au suivi d\'affaires',
  //         message: '<b>Voulez-vous vraiment ajouter cet article au suivi d\'affaires ?</b>',
  //         buttons: [
  //           {
  //             text: 'Annuler',
  //             role: 'cancel',
  //             cssClass: 'secondary',
  //             handler: () => {
  //             }
  //           }, {
  //             text: 'Oui',
  //             handler: () => this.monitorArticle(this.article)
  //           }
  //         ]
  //       });
  //       await alert.present();
  //     }
  //   } else { /*cas superviseur*/
  //     if ((this.nbCollaborateurs > this.listAssociatedCustomers.length) || this.article.monitoring.status === undefined) {
  //       const modal = await this.modalController.create({
  //         component: SupervisorMonitoringModalComponent,
  //         cssClass: 'addMonitoredArticleDialog',
  //         componentProps: {
  //           article: this.article,
  //           isSupervisor: true,
  //           isFromMonitoringView: false,
  //           listAssociatedCustomers: this.listAssociatedCustomers
  //         }
  //       });
  //       modal.onDidDismiss().then((data) => {
  //         if (data.data !== undefined) {
  //           if (data.data.selfSelected) {
  //             this.article.monitoring.status = 'to_process';
  //           }
  //           for (let i = 0; i < data.data.customersSelected.length; i++) {
  //             const isInArray = this.listAssociatedCustomers.findIndex(obj => obj.user_id === data.data.customersSelected[i].user_id);
  //             if (isInArray === -1) {
  //               this.listAssociatedCustomers.push({user_id: data.data.customersSelected[i].user_id, name: data.data.customersSelected[i].name});
  //               if (this.shareArticleToolTip === undefined) {
  //                 this.shareArticleToolTip = 'Article suivi par :\n';
  //                 this.shareArticleToolTip += data.data.customersSelected[i].name + '\n';
  //               } else {
  //                 this.shareArticleToolTip += data.data.customersSelected[i].name + '\n';
  //               }
  //             }
  //           }
  //         }
  //
  //       });
  //       return await modal.present();
  //     }
  //   }
  // }

  public getArticleCorps() {
    let pattern;
    let tag;
    let articleCorps = this.article.corps;

    for (let i = 0; i < this.article.referenceArticlesInfos?.length; i++) {
      pattern = '##' + this.article.referenceArticlesInfos[i].articleId + '##';
      tag = '<span> <a id="' + this.article.referenceArticlesInfos[i].articleId + '" style="color: #7f7f7f;text-decoration: underline;cursor: pointer">' + this.article.referenceArticlesInfos[i].text + '</a></span>';
      articleCorps = articleCorps.replace(pattern, tag);
    }

    const result = this.getHighlightedText(articleCorps);

    return this.sanitizer.bypassSecurityTrustHtml(result);
  }

  public getHighlightedText(text: string): string {
    return Utils.getHighlightedText(text, this._keywords);
  }

  handleAnchorClick(event: Event): void {
    // Prevent the default behavior of the anchor tag
    event.preventDefault();

    // Extract the target element that was clicked
    const targetElement = event.target as HTMLElement;

    // Check if the clicked element is an anchor tag
    if (targetElement.tagName.toLowerCase() === 'a') {
      this.openReferenceArticleModal(Number(targetElement.id));
    }
  }

  public async openReferenceArticleModal(referenceArticleId: number) {
    const Query = {
      articles: referenceArticleId
    };
    let article;
    this.articlesService.getArticlesContent(Query).subscribe(
        (res) => {
          article = res[0];
          this.openArticleModal(article);
        }
    );
  }
  openArticleModal(article) {
    // this._modalService.showCustomModal(FeArticleModalComponent, {});
    this._modalService.showCustomModal(FeArticleModalComponent, {
      data: {
        article: article
      }});
  }

  protected updateArticleComment() {
    const data =   {
      comment: this.article.data.comment,
      commentIsPrivate: this.article.data.commentIsPrivate != null ? this.article.data.commentIsPrivate : false
    };
    this.favoritesService.addCommentToFavorite(this.article.id, data).subscribe(value => {

    });
  }

  protected changeFavoriteFolder(favorite: FavorisFolder, selectionModel: any): void {
    this.favoritesService.moveArticlesInFavoritesToOtherFolder(favorite.folderID, selectionModel).subscribe(value => {
      this.favoritesService.needRefresh.next(true);
      this.article.favoriteFolder = favorite.folderID;
      this.snackBarService.showSuccessSnackbar('Déplacement réussi',
          'L\'article <b>\'' + this.troncateText(this.article.title, 50) + '\'</b> a été ' +
          'déplacé dans le dossier \'' + this.getFavoriteFolderLink(favorite.folderID, favorite.libelle) + '\' avec succès.');
    });
  }

  protected getContactLibelle(contactLine: ContactLineInterface) {
    let contactLibelle = '<i>' + contactLine.email + '</i>';
    if (contactLine.label !== undefined && contactLine.label !== '') {
      contactLibelle = '<b>' + contactLine.label + '</b> - ' + contactLibelle;
    }
    return contactLibelle;
  }

  protected sendFastMail(contactLine: ContactLineInterface, id: number): void {
    this.articlesService.emailArticleById(contactLine.id, this.article.id).subscribe(next => {
      this.snackBarService.showSuccessSnackbar('Envoi réussi', 'L\'article <b>\''
          + this.troncateText(this.article.title, 50) + '\'</b> a bien été envoyé à <b>\'' +
          contactLine.email + '\'</b> avec succès.');
      let shared = this.article.sharedTo;
      if (shared === undefined) {
        shared = [];
      }
      shared.push(contactLine.email);
      this.article.sharedTo = shared;
    });
    //  Sauvegarde  de l'action dans le log
    this.userService.log('ARTICLE_MAIL_ONECLICK', [this.article]);
  }

  protected createANewContact(): void {
    this._modalService.showCustomModalWithActionOnClose(FeCarnetContactModalComponent, {data: {createMode: true}}, response => {
      let contactName: string = response.label.trim();
      if (contactName.length > 0) {
        contactName = '<b>' + contactName.trim() + '</b> (' + response.email + ')';
      } else {
        contactName = '<b>' + response.email + '</b>';
      }
      this.addressesService.addAddress(response).subscribe(value => {
        this.contactsLines.unshift({id: value.body.addressId, checked: false,
          label: value.body.data.label, email: value.body.data.email, recentlyAdded: true});
        this.snackBarService.showSuccessSnackbar('Création effectuée avec succès',
            'Le contact ' + contactName + ' a été créé avec succès.', 5);
      });
    });
  }

  protected showLeadsDescription(lead: PredictionInterface): void {
    if (this.currentPrediction == null || this.currentPrediction.id != lead.id) {
      this.currentPrediction = lead;
      const element = this.document.getElementById('articleLeadsDesc');
      if (element) {
        const elementBottom = element.offsetTop + element.offsetHeight;
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        });
      }
    } else {
      this.currentPrediction = null;
    }
  }

  openCarnetAddress() {
    this._modalService.showCustomModal(FeCarnetAdressesModalComponent, {});
  }

  closeDialog() {
    this.confirm.emit({article: this.article});
    this.dialogRef.close();
  }

  closeMonitoringDialog() {
    let data = {
      article: this.article,
      isArchived: this.isArchived
    };
    this.confirm.emit(data);
    this.dialogRef.close();
  }
  public async close(): Promise<void> {
    if (this.article.type === 'local') {
      this.userService.log('ARTICLE_CLOSE_MODAL', [this.article]);
    }
  }

  // Suivi d'affaires
  authorAbbreviation(firstname, lastname): string {
    return (firstname !== undefined ? firstname.substring(0, 1) : '') + '' + (lastname !== undefined ? lastname.substring(0, 1) : '');
  }
  editMonitoredArticleComment(comment) {

    this._modalService.showCustomModalWithActionOnClose(MonitoringEditCommentModalComponent,
        {data: {
            comment: comment,
            articleId: this.article.id,
            customer: this.customer
    }}, (data) => {
          this.commentContent = '';
          if (data !== undefined) {
            if (data.commentEdited) {
              this.loadActivities();
            }
          } else {
            this.loadActivities();
          }
        });
  }

  public deleteComment(comment: MonitoredArticleActivityInterface): void {
    this.articleMonitoringService.removeMonitoredArticleComment(comment.activityId, this.article.id, this.customer)
        .pipe().subscribe(
        () => {
          this.monitoredArticleActivities.subscribe((data) => {
                data.forEach((monitoredActivity, index) => {
                  if (monitoredActivity.activityId === comment.activityId) {
                    data.splice(index, 1);
                  }
                });
                this.nbComments.next(data.filter(activity => activity.activityType === 'comment')?.length);
              }
          );
          this.commentAddedOrDeleted = true;
          this.snackBarService.showSuccessSnackbar('Commentaire supprimé avec succès', 'Commentaire supprimé avec succès');
        },
        (error: Error | ErrorStatus) => {
          const errorStr: string = 'Une erreur est survenue lors de la suppression de ce commentaire';
          // if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
          //     errorStr += `(${this.translateService.instant(error.message)})`;
          // }
          // this.toastService.simple(errorStr, {color: 'toasterror'});
        }
    );
  }

  public async deleteMonitoredArticleComment(comment: MonitoredArticleActivityInterface): Promise<any> {
    this._modalService.confirmModal('Suppression d\'un commentaire', 'Confirmez-vous la suppression de ce commentaire ?', () => {
      this.deleteComment(comment);
    });
  }
  public async shareComment(commentContent) {
    if(commentContent.length === 0 || commentContent.trim() === '') {
      this.snackBarService.showDangerSnackbar('Opération impossible',
          'Vous devez saisir un commentaire.');
    }
    else {
      //Modale de notification aux personnes à qui l'article est partagé
      if (this.monitoredArticle.associatedCustomersRealName != null && this.monitoredArticle.associatedCustomersRealName.length > 0) {
        this._modalService.showCustomModalWithActionOnClose(MonitoringCommentSharingModalComponent, {
          data: {
            comment: commentContent,
            articleId: this.article.id,
            articleMonitoringId: this.monitoredArticle.monitoringId
          }
        }, (data) => {
          this.commentContent = '';
          if (data !== undefined) {
            if (data.commentShared) {
              this.loadActivities();
              this.commentAddedOrDeleted = true;
            }
          } else {
            this.loadActivities();
            this.commentAddedOrDeleted = true;
          }
        });
      } else {
        const loadingModal = this.dialog.open(LoadingDisplayerComponent, {disableClose: true});
        const monitoringCommentAddingData = {
          articleId: this.article.id,
          articleMonitoringId: this.monitoredArticle.monitoringId,
          comment: commentContent,
          customers: [],
          privateComment: false
        };
        this.articleMonitoringService.addMonitoredArticleComment(monitoringCommentAddingData).subscribe(next => {
          this.snackBarService.showSuccessSnackbar('Ajout d\'un commentaire', 'Commentaire ajouté avec succès');
          this.commentContent = '';
          this.loadActivities();
        }, (error: Error | ErrorStatus) => {
          let errorStr: string = 'Une erreur est survenue lors de l\'ajout de ce commentaire';
          this.snackBarService.showDangerSnackbar('Opération impossible',
              errorStr);
        });
        loadingModal.close();
      }
    }
  }

  public deleteAttachment(attachment: MonitoredArticleAttachmentInterface): void {
    this.articleMonitoringService.removeMonitoredArticleAttachment(attachment.id, this.article.id, this.customer)
        .pipe().subscribe(
        () => {
          this.monitoredArticleAttachments.subscribe((data) => {
                data.forEach((monitoredAttachment, index) => {
                  if (monitoredAttachment.id === attachment.id) {
                    data.splice(index, 1);
                  }
                });
              }
          );
          this.attachmentAddedOrDeleted = true;
          this.snackBarService.showSuccessSnackbar('Suppression d\'une pièce jointe', 'Pièce-jointe supprimée avec succès');
        },
        (error: Error | ErrorStatus) => {
          let errorStr: string = 'Une erreur est survenue lors de la suppression de cette pièce-jointe';
          this.snackBarService.showDangerSnackbar('Opération impossible',
              errorStr);
        }
    );
  }

  public async deleteMonitoredArticleAttachment(attachment: MonitoredArticleAttachmentInterface): Promise<any> {
    this._modalService.confirmModal('Attention', 'Confirmez-vous la suppression de cette pièce jointe ?', () => {
      this.deleteAttachment(attachment);
    });
    // const alert = await this.alertController.create({
    //   header: 'Attention',
    //   message: '<b>Voulez-vous vraiment supprimer cette pièce-jointe? </b> <br /><br />',
    //   buttons: [
    //     {
    //       text: 'Annuler',
    //       role: 'cancel',
    //       cssClass: 'secondary',
    //       handler: () => {
    //       }
    //     }, {
    //       text: 'Oui',
    //       handler: () => this.deleteAttachment(attachment)
    //     }
    //   ]
    // });
    // return await alert.present();
  }

  public async editMonitoredArticleAttachmentVisibility(attachment: MonitoredArticleAttachmentInterface): Promise<any> {
    const monitoringAttachmentData = {
      monitoringAttachmentId: attachment.id,
      articleId: attachment.articleId,
      customer: this.customer,
      monitoringAttachmentStatus: !attachment.private
    };
    this.articleMonitoringService.updateMonitoredArticleAttachmentPrivacy(monitoringAttachmentData).subscribe(
        () => {
          this.monitoredArticleAttachments.subscribe((data) => {
                data.forEach((monitoredAttachment) => {
                  if (monitoredAttachment.id === attachment.id) {
                    monitoredAttachment.private = !attachment.private;
                  }
                });
              }
          );
          this.snackBarService.showSuccessSnackbar('Visibilité de la pièce-jointe', 'Visibilité de la pièce-jointe mise à jour avec succès');
        }
    );
  }

  public async shareAttachment() {
    this._modalService.showCustomModalWithActionOnClose(MonitoringAttachmentSharingModalComponent, {data: {
      articleId: this.article.id,
      articleMonitoringId: this.monitoredArticle.monitoringId,
      monitoredArticle: this.monitoredArticle
    }}, (data) => {
      if (data != undefined) {
        if (data.attachmentsAdded) {
          this.attachmentAddedOrDeleted = true;
          this.loadAttachments();
        }
      } else {
        this.attachmentAddedOrDeleted = true;
        this.loadAttachments();
      }
    });

    // const modal = await this.modalController.create({
    //   component: MonitoringAttachmentSharingModalComponent,
    //   cssClass: 'addMonitoredArticleDialog',
    //   componentProps: {
    //     articleId: this.article.id,
    //     articleMonitoringId: this.monitoredArticle.monitoringId,
    //     monitoredArticle: this.monitoredArticle
    //   }
    // });
    //
    // modal.onDidDismiss()
    //     .then(
    //         (data: any) => {
    //           if (data.data != undefined) {
    //             if (data.data.attachmentsAdded) {
    //               this.attachmentAddedOrDeleted = true;
    //               this.loadAttachments();
    //             }
    //           } else {
    //             this.attachmentAddedOrDeleted = true;
    //             this.loadAttachments();
    //           }
    //         }
    //     );
    // return await modal.present();
  }

  async archiveMonitoredArticle(monitoredArticle) {
    let confirmText = '';
    let titleText = '';
    let archive: boolean = true;
    if (monitoredArticle.monitoring.isArchived === false) {
      titleText = 'Archiver un article du suivi d\'affaires';
      confirmText = 'Voulez-vous vraiment archiver cet article ?';
      archive = true;
    }
    else {
      titleText = 'Désarchiver un article du suivi d\'affaires';
      confirmText = 'Voulez-vous vraiment désarchiver cet article ?';
      archive = false;
    }

    this._modalService.confirmModal(titleText, confirmText, () => {
      if(archive) {
        this.archiveArticle(monitoredArticle);
        this.snackBarService.showSuccessSnackbar('Archiver un article', 'L\'article <b>\''
            + this.troncateText(this.article.title, 50) + '\'</b> a été archivé avec succès.');
      }
      else {
        this.unArchiveArticle(monitoredArticle);
        this.snackBarService.showSuccessSnackbar('Désarchiver un article', 'L\'article <b>\''
            + this.troncateText(this.article.title, 50) + '\'</b> a été désarchivé avec succès.');
      }
      this.closeMonitoringDialog();
    });
  }

  public archiveArticle(monitoredArticle): void {
    this.isArchived = true;
    this.currentArticleAttributes.isArchived = true;
    this.article.monitoring.isArchived = true;
    this.articleMonitoringService.updateMonitoringArticles(this.currentArticleAttributes).subscribe();
  }

  public unArchiveArticle(monitoredArticle): void {
    this.isArchived = false;
    this.currentArticleAttributes.isArchived = false;
    this.article.monitoring.isArchived = false;
    this.articleMonitoringService.updateMonitoringArticles(this.currentArticleAttributes).subscribe();
  }

  protected loadActivities(): void {
    this.articleMonitoringService.getArticleActivities({
      articleId: '' + this.article.id + '',
      articleMonitoringId: '' + this.monitoredArticle.monitoringId + ''
    }).subscribe(
        monitoredActivitiesFromApi => {
          try {
            this.monitoredArticleActivities.next(monitoredActivitiesFromApi);
            this.nbComments.next(monitoredActivitiesFromApi.filter(activity => activity.activityType === 'comment')?.length);
            this.monitoredArticleActivities$ = new Observable((observer: Subscriber<any>) => {
              observer.next(monitoredActivitiesFromApi);
              observer.complete();
            });
          } catch (e) {

          }
        }
    );
  }

  protected loadAttachments(): void {
    this.articleMonitoringService.getArticleAttachments({
      articleId: '' + this.article.id + '',
      articleMonitoringId: '' + this.monitoredArticle.monitoringId + ''
    }).subscribe(
        monitoredAttachmentsFromApi => {
          try {
            this.monitoredArticleAttachments.next(monitoredAttachmentsFromApi);
            this.monitoredArticleAttachments$ = new Observable((observer: Subscriber<any>) => {
              observer.next(monitoredAttachmentsFromApi);
              observer.complete();
            });
          } catch (e) {

          }
        }
    );
  }

  public formatAddingDate(stringDate): string {
    return formatDate(this.parseDate(stringDate), 'le dd/MM/yy à HH\'h\'mm', 'fr');
  }

  public parseDate(value: any): Date | null {
    if ((typeof value === 'string') && (value.includes('/'))) {
      const str = value.split('/');

      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);

      return new Date(year, month, date);
    } else if ((typeof value === 'string') && value === '') {
      return new Date();
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  openFile(attachment) {
    if (!this.authService.roleCom) {
      this.articleMonitoringService.downloadMonitoredArticleAttachment({
        articleId: attachment.articleId + '',
        monitoringAttachmentId: attachment.id + ''
      }).subscribe(value => {
        FileSaver.saveAs(value.body, attachment.filename);
      });
    }
  }

  public attachmentAddingText(attachment: MonitoredArticleAttachmentInterface): string {
    const authorFullname = attachment.authorFirstName + ' ' + attachment.authorLastName;
    return 'ajouté par ' + (attachment.customerId === this.customer ? ' vous' : authorFullname) + ' ' + this.formatAddingDate(attachment.addDate);
  }

  printArticles() {
      this.articlesService.printArticlesList([this.article.id]).pipe().subscribe(
          (res: PrintInterface) => {
            // Mobile printing
            if (this.platform.is('ios') || this.platform.is('android')) {
              this.printer.isAvailable()
                  .then(onSuccess => {
                      },
                      onError => {
                      }
                  );

              const options: PrintOptions = {
                name: 'FirstECO_print',
                orientation: 'portrait'
              };

              this.printer.print(res.html, options)
                  .then(onSuccess => {
                      },
                      onError => {
                      }
                  );
            }
            // Desktop printing
            else {
              const popupWin = window.open('', '_blank');
              popupWin.document.open();
              popupWin.document.write(res.html);
              setTimeout(() => popupWin.print(), 1000);
            }
          });
  }

  protected showLeads() {
      this.hideLeads = false;
  }
}
