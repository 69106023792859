<mat-icon>account_circle</mat-icon>
<div>
  <span class="lastConnectionContainer" *ngIf="date; else noData">
    <span class="lastConnectionLabel">{{"LAST_CONNECTION"}}</span>
    <span class="lastConnectionDate">{{date | date:'dd/LL/yyyy':'':locale}}</span>
  </span>
  <ng-template #noData>
    <span class="lastConnectionContainer">
      <span class="lastConnectionLabel">{{"LAST_CONNECTION"}}</span>
      <span class="lastConnectionDate"><mat-progress-spinner class="" color="accent" mode="indeterminate" diameter="20"></mat-progress-spinner></span>
    </span>
  </ng-template>
</div>
