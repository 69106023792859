<div style="height: 100%; display: flex; flex-direction: column; background-color: #F5F8FA">
    <div style="height: 50px; display: flex;">
        <p *ngIf="!searchToEdit" style="flex: 1; font-size: 24px;font-weight: 700;padding: 16px;font-family: Poppins;">Création d'une nouvelle recherche</p>
        <p *ngIf="searchToEdit" style="flex: 1; font-size: 24px;font-weight: 700;padding: 16px;font-family: Poppins;">Edition de la recherche "{{searchToEdit.data.label}}"</p>
<!--        <fe-button [iconDirection]="'RIGHT'" [icon]="true" [iconType]="'close'" [type]="'TERTIARY'" [bold]="true" style="font-size: 16px;" (click)="closeDialog()" text="Quitter l'édition de la recherche"></fe-button>-->
<!--        <fe-button color="PRIMARY" hideText="true" icon="true" type="TERTIARY" [iconType]="IconType.filter" class="searchCreationEditionBtnTour"-->
<!--                   title="Guide sur la création/édition d'une recherche" (click)="launchIntroFeatureTour('search_modal');" />-->
<!--        <div style="display: flex; flex-direction: row; margin: 10px 0 20px 0;">-->
<!--            <mat-icon style="margin-top: 5px; margin-right: 1px;">help_center</mat-icon>-->
<!--            <a style="padding-top:7px; cursor:pointer; font-weight: bold; font-size: 12px;" (click)="launchIntroFeatureTour('search_modal');">Guide d'utilisation</a>-->
<!--        </div>-->
        <fe-button text="Guide d'utilisation" type="SECONDARY" icon="true" [iconType]="IconType.tour"
                   (click)="launchIntroFeatureTour('search_modal')" smallButton="true"
                   style="margin: auto 0;" bold="true"/>
        <div class="CloseButton">
            <span *ngIf="searchToEdit" style="text-decoration: underline; cursor:pointer;" (click)="closeDialog()">Quitter l'édition</span>
            <span *ngIf="!searchToEdit" style="text-decoration: underline; cursor:pointer;" (click)="closeDialog()">Quitter la création</span>
            <mat-icon (click)="closeDialog()">close</mat-icon>
        </div>
    </div>
    <div style="display: flex; flex-direction: row; flex: 1; overflow: hidden">
        <app-search-left-part class="leftPart" (emitter)="leftPartEventFired($event)"/>
        <app-search-list-criterions (launchTour)="launchIntroFeatureTour($event)" [ngClass]="{'hideWhenPreview' : previewResults}" style="flex: 1;margin-top: 8px; margin-left: 10px; margin-right: 10px;" (emitter)="changeCriterionSelection($event)" class="searchListCriterionsTour" />

        <div *ngIf="previewResults" class="previewPanel">
            <div style="display: flex; margin-bottom: 8px">
                <button class="dropdownBtn" (click)="onDropDownMenuOpenTri($event)" [matMenuTriggerFor]="menu2">
                    <fe-icon size="14" style="margin-right: 4px" [iconType]="IconType.sorting_desc"
                             [color]="ColorType.grey"/>
                    <span>{{modeTri}}</span>
                    <mat-icon>{{menuIconTri}}</mat-icon>
                </button>
                <fe-button style="display: flex;justify-content: end;" (click)="closePreview()" [text]="'Fermer la prévisualisation'" [type]="'TERTIARY'"></fe-button>
                <mat-menu class="menuTri" style="position: absolute" #menu2="matMenu" (close)="onMenuTriClosed()">
                    <fe-menu-item (click)="onSortingOptionChange('sortByRelevance','pertinence')"
                                  color="PRIMARY" text="Tri par pertinence" [icon]="IconType.pertinence"
                                  [class.selectedOption]="_sortingOption == 'sortByRelevance'"/>
                    <fe-menu-item (click)="onSortingOptionChange('sortByDateAsc','date croissante')" color="PRIMARY"
                                  text="Tri par date croissante" [icon]="IconType.date_croissante"
                                  [class.selectedOption]="_sortingOption == 'sortByDate' && articlesSortingMethod == 'publicationDateAsc'"/>
                    <fe-menu-item (click)="onSortingOptionChange('sortByDateDesc','date décroissante')" color="PRIMARY"
                                  text="Tri par date décroissante" [icon]="IconType.date_decroissante"
                                  [class.selectedOption]="_sortingOption == 'sortByDate' && articlesSortingMethod == 'publicationDateDesc'"/>
                </mat-menu>
            </div>
            <mat-list class="paginated" style="display: flex; flex-direction: column;height: 95%!important;">
                <!-- loadingInProgress and mediaQueryService is declared in PaginatedListComponent -->
                <!--        <loading-overlay *ngIf="!loadingInProgress">Chargement des articles</loading-overlay>-->
                <fe-loader *ngIf="loadingInProgress" loaderText="Chargement des articles"/>
                <no-data *ngIf="!loadingInProgress && tableDataSource.data.length < 1">Aucun article disponible</no-data>

                <div class="articlesContainer" *ngIf="!loadingInProgress && tableDataSource.data.length > 0">
                    <fe-article-item [_keywords]="queryResult?.keywordsCount[0]?.searched" [style]="'preview'" [article]="article"
                                     [selectionModel]="selectionModel" *ngFor="let article of tableDataSource.data;"
                                     [hasSectorsInSearch]="false" [hasTopicsInSearch]="false"
                    />
                    <fe-pro-tips *ngIf="resultCount > 25" style="padding:10px;" title="" text="La prévisualisation affiche les 25 premiers résultats. Enregistrer la recherche pour tous les consulter." dismissButton="false"></fe-pro-tips>
                </div>
            </mat-list>
        </div>
        <app-search-selected-criterions class="rightPart" style="width: 400px; margin-right: 8px;display: flex"
                                        (emitter)="changeSelection($event)" [type]="'search'"/>
    </div>
    <div style="height: 50px; display: flex; flex-direction: row; justify-content: flex-end; align-items: center; padding-right: 10px;">
        <p style="padding-right: 10px; margin: 0; cursor: pointer; text-decoration: underline" *ngIf="!loading" (click)="openPreview()" class="searchPreviewBtnTour">
            <span *ngIf="resultCount === 0">Aucun résultat</span>
            <span *ngIf="resultCount > 0">{{resultCount <= 1 ? resultCount + ' résultat' : resultCount + ' résultats'}}</span>
        </p>
        <mat-progress-spinner style="margin-right: 10px" *ngIf="loading" color="black" mode="indeterminate" diameter="30"></mat-progress-spinner>
        <fe-button (click)="saveSearch()" [text]="'Enregistrer la recherche'" class="saveSearchBtnTour"/>
        <fe-button (click)="closeDialog()" [text]="'Annuler'" [type]="'TERTIARY'" [color]="'DANGER'" style="margin-left: 5px"></fe-button>
    </div>
</div>
