<div class="feGestionRecherchesModal feModal">
    <div class="feModalHeader">
        <span class="feModalTitle">Gérer mes recherches</span>
        <fe-button color="PRIMARY" hideText="true" icon="true" type="TERTIARY" [iconType]="IconType.tour"
                   matTooltipClass="multiline-tooltip" matTooltipPosition="after" matTooltip="Guide sur la gestion de vos recherches" (click)="launchIntroFeatureTour('search_modal');" />
        <div class="feModalCloseButton">
                        <span mat-dialog-close>Fermer</span>
            <mat-icon mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="feModalContent">
<!--        <fe-pro-tips text="Donner une petite explication sur la façon dont la hiérarchisation des recherches influe sur-->
<!--        les lettres et les “projets ciblés" color="PRIMARY" dismissButton="true"></fe-pro-tips>-->
        <div class="mainActionsLine">
            <fe-searchbar (valueChange)="filterChange($event)" class="searchModalSearchBarFilterTour" />
            <fe-button mat-dialog-close class="marginLeftAuto" [iconType]="IconType.add" icon="true"
                       [text]="'Nouvelle recherche'" (click)="createNewSearch()" class="searchModalCreateSearchBtnTour" />
        </div>
    </div>
    <div class="rechercheLinesContainer" cdkDropList [cdkDropListData]="mySearchList" (cdkDropListDropped)="drop($event)">
<!--        <ng-container cdkDrag *ngFor="let search of mySearchList; let i = index">-->
            <div cdkDrag style="display:flex; flex-direction: row;" class="rechercheLine" *ngFor="let search of mySearchList; let i = index">
                <ng-container *ngIf="!filter || filter.length <= 0 || search.data.label.toLowerCase().includes(filter.toLowerCase())">
                    <div class="dAndDContainer" cdkDragHandle>
                        <div class="dAndDButton">
                            <mat-icon class="leftVert">more_vert</mat-icon>
                            <mat-icon class="rightVert">more_vert</mat-icon>
                        </div>
                    </div>
                    <fe-recherche-line style="flex: 1;" *ngIf="!filter || filter.length <= 0
                    || search.data.label.toLowerCase().includes(filter.toLowerCase())" [mainSearch]="i === 0"
                                       (emitter)="loadSearch(search, i)" [search]="search" [searchList]="mySearchList"/>
                </ng-container>
            </div>

<!--        </ng-container>-->
        <fe-loader class="feLoader" *ngIf="showLoader" loaderText="Chargement en cours..."/>
    </div>
</div>
