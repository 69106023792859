import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-displayer',
  templateUrl: './loading-displayer.component.html',
  styleUrls: ['./loading-displayer.component.scss'],
})
export class LoadingDisplayerComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
