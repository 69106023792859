import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ArticleMonitoringService} from '../../../services/article-monitoring/article-monitoring.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SessionInterface} from '../../../interfaces/session/session.interface';
import {UserService} from '../../../services/user/user.service';
import {DatePipe} from '@angular/common';
import {ArticleInterface} from '../../../interfaces/articles/article.interface';
import {ToastService} from '../../../services/toast/toast.service';
import * as moment from 'moment';
import {SnackbarService} from "../../../services/snackbar/snackbar.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-create-lead-modal',
  templateUrl: './create-lead-modal.component.html',
  styleUrls: ['./create-lead-modal.component.scss'],
})
export class CreateLeadModalComponent implements OnInit {

  @Output() confirm = new EventEmitter<any>();

  public informationDatePicker: Date;
  public informationDate: String = '';
  public leadTitle: string;
  public leadContent: string;
  public informationDateForm: FormGroup;
  public customerToken: string;
  public currentInformationDate: string;
  public modification: boolean;
  public article: ArticleInterface;

  constructor(
              private articleMonitoringService: ArticleMonitoringService,
              private userService: UserService,
              private datePipe: DatePipe,
              private snackbarService: SnackbarService,
              public dialogRef: MatDialogRef<CreateLeadModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder) {
    if(data.article) {
      this.article = data.article;
    }
    this.modification = data.modification;
  }

  ngOnInit() {
    this.informationDateForm = this.fb.group({
      currentInformationDate: [null]
    });
    this.userService.getSessionDatas()
        .subscribe(
            (sessionData: SessionInterface) => {
              if (sessionData !== undefined) {
                this.customerToken = sessionData.customerDetails.user_id;
              }
            }
        );

    if (this.modification) {
      if (this.article.title !== undefined) {
        this.leadTitle = this.article.title;
      }
      if (this.article.corps !== undefined) {
        this.leadContent = this.article.corps;
      }
      if (this.article.publicationDate !== undefined) {
        this.currentInformationDate = this.article.publicationDate;
        const momentVariable = moment(this.article.publicationDate, 'YYYY-MM-DD hh:mm:ss');
        const dateTransform = momentVariable.format('MM/DD/YYYY');
        const date = new Date(dateTransform);
        this.informationDateForm = this.fb.group({
          currentInformationDate: [date]
        });
      }
    }
  }

  close() {
    this.dialogRef.close();
  }

  public updateInformationDate(dateObject) {
    if (dateObject.value != null) {
      this.informationDatePicker = new Date(dateObject.value);
      const year = this.informationDatePicker.getFullYear();
      const month = this.informationDatePicker.getMonth() + 1;
      const day = this.informationDatePicker.getDate().toString();
      this.informationDate = year + '-' + month + '-' + day;
      // this.initArticles(this.sortByPriority, this.sortByAddDate, this.startDate, this.endDate, this.showArchivedArticles, this.customerToken, false);
    } else {
      this.informationDate = null;
    }
  }

  public createLead(informationDate: Date) {
    if(!this.leadTitle) {
      this.snackbarService.showDangerSnackbar('Opération impossible',
          'Un lead externe doit avoir au minimum un titre.');
    }
    else {
      const informationDates = this.datePipe.transform(informationDate, 'dd/MM/yyyy');
      const externalLead = {
        customerId: this.customerToken,
        title: this.leadTitle,
        content: this.leadContent,
        creationDate: informationDates
      };
      this.articleMonitoringService.addExternalLead(externalLead).subscribe(object => {
        this.confirm.emit();
        this.close();
        this.snackbarService.showSuccessSnackbar('Création d\'un lead externe', 'Le lead a été créé avec succés.');
      });
    }
  }

  public updateLead(informationDate: Date) {
    if(!this.leadTitle) {
      this.snackbarService.showDangerSnackbar('Opération impossible',
          'Un lead externe doit avoir au minimum un titre.');
    } else {
      let informationDates = null;
      // @ts-ignore
      if (informationDate instanceof Date && !isNaN(informationDate)) {
        informationDates = this.datePipe.transform(informationDate, 'dd/MM/yyyy');
      }
      const externalLead = {
        leadId: this.article.id,
        customerId: this.customerToken,
        title: this.leadTitle,
        content: this.leadContent,
        creationDate: informationDates
      };

      this.articleMonitoringService.updateExternalLead(externalLead).subscribe(object => {
        this.confirm.emit(externalLead);
        this.close();
        this.snackbarService.showSuccessSnackbar('Edition d\'un lead externe', 'Le lead a été modifié avec succés.');
      });
    }
  }
}
