<div class="fe-snackbar" [ngClass]="{'fe-snackbar-default' : buttonColor === 'DEFAULT',
'fe-snackbar-confirm' : buttonColor === 'SUCCESS', 'fe-snackbar-danger' : buttonColor === 'DANGER'}">
    <span class="snackbar-title" [innerHtml]="title"></span>
    <span class="snackbar-texte" [innerHtml]="text"></span>
    <div class="snackbarBtnContainer">
        <fe-button *ngIf="callbackFunction != null" [text]="acceptBtnLabel" (click)="doAction()" style="margin-left: 0;"/>
        <fe-button [color]="buttonColor" [text]="'Fermer'" (click)="closeSnackbar()"/>
    </div>
</div>
<div class="matProgressBarContainer" *ngIf="duration > 0" [ngClass]="{'feSnackbarDefaultProgressBar' : buttonColor === 'DEFAULT',
'feSnackbarConfirmProgressBar' : buttonColor === 'SUCCESS', 'feSnackbarDangerProgressBar' : buttonColor === 'DANGER'}">
    <mat-progress-bar mode="determinate" [value]="progress"/>
</div>
