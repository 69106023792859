import {Component, OnInit, OnDestroy, Input, Output, EventEmitter, Renderer2} from '@angular/core';
import { Subject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { ArticlesService } from 'src/app/services/articles/articles.service';
import {ArticlesInterface} from '../../../interfaces/articles/articles.interface';
import {ArticleInterface} from '../../../interfaces/articles/article.interface';
import {FavorisFolder} from '../../../interfaces/favoritesFolders/favorites-folders.interface';
import {FavoritesService} from '../../../services/favorites/favorites.service';
import {ToastService} from '../../../services/toast/toast.service';
import {AlertController, ModalController} from '@ionic/angular';
import {MediaQueryService} from '../../../services/media-query/media-query.service';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'article-move-folder-button',
  templateUrl: './article-move-folder-button.component.html',
  styleUrls: ['./article-move-folder-button.component.scss'],
})
export class ArticleMoveFolderButtonComponent implements OnInit {

  private isMatMenuOpen = false;
  private isMatMenu2Open = false;
  private enteredButton = false;
  private prevButtonTrigger;
  @Input() public selectionModel: SelectionModel<string>;
  @Input() public source: MatTableDataSource<ArticleInterface> = new MatTableDataSource();
  @Input() public articles: ArticlesInterface;
  @Input() public article: ArticleInterface;
  @Input() public currentFolderID: number;
  public _favoriteFolders: Array<FavorisFolder>;

  private unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
      private articlesService: ArticlesService,
      public favoriteService: FavoritesService,
      private toastService: ToastService
      , private alertController: AlertController
      , private modalController: ModalController
      , public mediaQueryService: MediaQueryService
      , private ren: Renderer2
      , private favoritesService: FavoritesService
  ) {}

  public ngOnInit(): void {
    this.favoriteService.getFavoritesFolders().subscribe((data: Array<FavorisFolder>) => {
      this._favoriteFolders = data;
    });
  }

  menuenter() {
    this.isMatMenuOpen = true;
    if (this.isMatMenu2Open) {
      this.isMatMenu2Open = false;
    }
  }

  menuLeave() {
    setTimeout(() => {
      if (!this.isMatMenu2Open && !this.enteredButton) {
        this.isMatMenuOpen = false;
        this.prevButtonTrigger.closeMenu();
      } else {
        this.isMatMenuOpen = false;
        this.prevButtonTrigger.closeMenu();
      }
    }, 80);
  }

  buttonEnter(trigger, mobile: boolean) {
    setTimeout(() => {
      if (this.prevButtonTrigger && this.prevButtonTrigger !== trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        trigger.openMenu();
      } else if (!this.isMatMenuOpen && !mobile) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
        trigger.openMenu();
      } else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
      }
    });
  }

  buttonLeave(trigger, button) {
    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
      } if (!this.isMatMenuOpen) {
        trigger.closeMenu();
      } else {
        this.enteredButton = false;
      }
    }, 100);
  }

  public changeFavoriteFolder(favorite: FavorisFolder, selectionModel: any) {
    this.favoriteService.moveArticlesInFavoritesToOtherFolder(favorite.folderID, selectionModel).subscribe(value => {
      this.favoritesService.needRefresh.next(true);
      this.selectionModel.clear();
      this.toastService.simple('Les favoris ont été déplacés avec succès', { color: 'toastinfo' });
    });
  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
