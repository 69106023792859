import { Injectable } from '@angular/core';
import {NavController, ToastController} from '@ionic/angular';
import { ToastOptionInterface } from 'src/app/interfaces/toast-options/toast-options.interface';
import {NavigationOptions} from '@ionic/angular/common/providers/nav-controller';
import {Utils} from '../../classes/Utils';
import {MediaQueryService} from '../media-query/media-query.service';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private toastController: ToastController,
    private navController: NavController,
    public mediaQueryService: MediaQueryService
  ) {}


  public async simple(message: string, options?: ToastOptionInterface) {
    const toast = await this.toastController.create({
      message: message,
      duration: (options && options.duration) ? options.duration : 4000,
      position: (options && options.position) ? options.position : 'middle',
      color: (options && options.color) ? options.color : 'toastsuccess',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel'
        }
      ]
    });
    await toast.present();
  }

  public async clickableLink(message: string, folderLibelle: string, folderID: number, options?: ToastOptionInterface) {
    const toast = await this.toastController.create({
      message: message,
      duration: (options && options.duration) ? options.duration : 5000,
      position: (options && options.position) ? options.position : 'middle',
      color: (options && options.color) ? options.color : 'toastsuccess',
      cssClass: 'clickableLink',
      buttons: [
        {
          text: folderLibelle,
          role: 'info',
          handler: () => {
            const params: NavigationOptions = {
            queryParams: {
              folderID: folderID
            }
          };
            Utils.navigate('/app/my-favorites', this.navController, params); }
        }
      ]
    });
    await toast.present();
  }

  public async clickableBlankLink(message: string, url: string, options?: ToastOptionInterface) {
    const toast = await this.toastController.create({
      message: message,
      duration: (options && options.duration) ? options.duration : 4000,
      position: (options && options.position) ? options.position : 'middle',
      color: (options && options.color) ? options.color : 'toastsuccess',
      buttons: [
        {
          text: 'DOSSIER SCOPE',
          role: 'info',
          handler: () => {
            window.open(url, '_blank');
          }
        }
      ]
    });
    await toast.present();
  }

}
