<ion-header no-border>
    <ion-toolbar>
        <ion-buttons *ngIf="!isLettrePersoMC && false" style="margin-left: 20px;">
            <div *ngIf="article.type === 'local'">
                <ion-button #button style="height: 40px;z-index:1050;" shape="round" class="greyColor buttonpadding"
                            (click)="$event.stopPropagation();!mediaQueryService.mobileQuery.matches ? openEmailModal(article) : ''"
                            [matMenuTriggerFor]="menu" #levelOneTrigger="matMenuTrigger"
                            (mouseenter)="buttonEnter(levelOneTrigger)"
                            (mouseleave)="buttonLeave(levelOneTrigger, button)">
                    <mat-icon>mail_outline</mat-icon>
                </ion-button>
                <mat-menu #menu="matMenu" direction="down" yPosition="below" [hasBackdrop]="false">
                    <div (mouseenter)="menuenter()" (mouseleave)="menuLeave(levelOneTrigger, button)"
                         style="max-height: 270px;">
                        <ng-container *ngIf="addressesService.getAddresses() | async as addresses">
                            <ng-container *ngFor="let address of addresses.address">
                                <button mat-menu-item (click)=" sendMail(address, $event)">
                                    <mat-icon style="color: green;"
                                              *ngIf="article.sharedTo !== undefined && article.sharedTo.includes(address.data.email)">
                                        check_circle_outline
                                    </mat-icon>
                                    <b>{{address.data.label}}</b> - <i>{{address.data.email}}</i>
                                </button>
                            </ng-container>
                        </ng-container>
                        <button *ngIf="article.type === 'local'" mat-menu-item (click)="openEmailModal(article)"
                                style="color: #273c4e;">
                            <b>Envoi avancé</b>
                        </button>
                        <button *ngIf="article.type === 'local'" mat-menu-item (click)="addContact()"
                                style="color: #273c4e;">
                            <b>Ajouter un contact</b>
                        </button>
                    </div>
                </mat-menu>
            </div>
            <article-toggle-favorite-button [article]="article"></article-toggle-favorite-button>
            <ion-button *ngIf="article.type === 'local'" style="height: 40px;" shape="round"
                        class="greyColor buttonpadding"
                        (click)="openPrintModal(article)">
                <mat-icon>print_outline</mat-icon>
            </ion-button>
            <ion-button *ngIf="isFromMonitoring && !isVisualisation && typeLead"
                        (click)="openLeadCreationModal()"
                        style="height: 40px;"
                        class="greyColor buttonpadding"
                        shape="round"
                        title='Modification un lead externe'>
                <mat-icon>edit</mat-icon>
            </ion-button>
            <ion-button *ngIf="isFromMonitoring && !isVisualisation"
                    style="height: 40px;"
                    class="greyColor buttonpadding"
                    mat-button
                    shape="round"
                    title="{{this.article.monitoring.isArchived ? 'Désarchiver' : 'Archiver'}}"
                    (click)="archiveMonitoredArticle(article)">
                <mat-icon>
                    archive
                </mat-icon>
            </ion-button>
        </ion-buttons>
        <ion-buttons slot="end" class="informationsContainer" style="margin-right: 15px;">
            <div>
                <span class="label">Suivi</span>
                <span class="material-symbols-outlined">dashboard</span>
            </div>
            <div>
                <span class="label">Favoris</span>
                <span class="material-symbols-outlined">grade</span>
            </div>
        </ion-buttons>
        <ion-buttons slot="end" style="margin-right: 15px;">
            <button class="moreBtn" mat-icon-button aria-label="more">
                <mat-icon>more_vert</mat-icon>
            </button>
        </ion-buttons>
        <ion-buttons slot="end" style="margin-right: 15px;" (click)="close()">
            <span class="blueBoldText">Fermer</span>
            <button class="closeBtn" mat-icon-button aria-label="close">
                <mat-icon>close</mat-icon>
            </button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="readingArticleModal">
    <div class="articleBody">
        <div class="articleLeftPart" [class.aritlceLeftPartFW]="article.entreprises == null">
            <div class="articleContent">
                <div *ngIf="article; else noData">
                    <div class="date">{{article.publicationDate | date:'dd/MM/yyyy':'':locale}}</div>
                    <div class="description" [innerHTML]="getHighlightedText(article.title)"></div>
                    <article-tags *ngIf="article.tags && false" [keywords]="keywords" [tags]="article.tags"
                                  [predictions]="article.predictions" [isFromBusinessSignals]="false"
                                  class="list"></article-tags>
                </div>
                <div id="corpsArticle" class="body"
                     [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
                    <!--                <span [innerHTML]="getHighlightedText(article.corps)"></span>-->
                </div>
                <div *ngIf="article" class="sourcesContainer">
                    <div *ngIf="article.precisions">{{article.precisions}}</div>
                    <article-sources *ngIf="article.sources" [article]="article"></article-sources>
                    <!--<article-contacts-details [articleId]="article.id" [contacts]="article.contacts" [type]="'modal'"></article-contacts-details>-->
                </div>
            </div>
        </div>

        <div class="articleRightPart" *ngIf="article.entreprises"
             [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
            <div *ngFor="let entreprise of article.entreprises">
                <article-entreprise [keywords]="keywords" [entreprise]="entreprise" [article]="article"
                                    class="list contacts article-entreprise"
                                    [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}"></article-entreprise>
            </div>
        </div>
    </div>

    <div *ngIf="from === 'monitoring'" style="margin-top: 10px;">
        <mat-tab-group [selectedIndex]="tabIndex">
            <mat-tab>
                <ng-template mat-tab-label>
                    <div>
                        <span style="margin-right: 25px; font-weight: bold;color: #273C4E;">Commentaires & activités</span>
                        <mat-icon style="vertical-align: top;line-height: inherit;align-items: center;">message
                        </mat-icon>
                        <span> {{(nbComments | async) }}</span>
                    </div>
                </ng-template>

                <!--  Bloc Commentaires Monitoring  -->
                <div style="border: 1px darkgrey solid; padding: 15px;" class="marginModal greyBorderTop"
                     *ngIf="from === 'monitoring'">
                    <div style="display: flex;" *ngIf="!article.monitoring.isVisualisation">
                        <div style="margin: 25px 15px 15px 5px;"><span style="background: #C20015;"
                                                                       class="userAbbrv">{{authorAbbreviation(this.customerFirstname, this.customerLastname)}}</span>
                        </div>
                        <angular-editor class="full-width" [placeholder]="'Commentaire...'"
                                        [(ngModel)]="commentContent" [config]="config"></angular-editor>
                    </div>
                    <div style="flex-direction: row-reverse; display: flex;" *ngIf="!article.monitoring.isVisualisation">
                        <button style="color: white;"
                                [ngClass]="commentContent.length !== 0 ? 'submitButton' : 'submitButtonDisabled'"
                                [disabled]="commentContent.length === 0" (click)="shareComment(commentContent)"
                                mat-button>Envoyer
                        </button>
                    </div>
                    <div class="commentAndActivityContainer">
                        <ng-container *ngIf="(monitoredArticleActivities$ | async); else loading">
                            <ng-container *ngIf="(monitoredArticleActivities | async)?.length; else noActivityData">
                                <div class="commentBox"
                                     *ngFor="let activity of (monitoredArticleActivities | async)">
                                    <div id="commentParentBloc" style="display: flex;">
                                        <div style="margin:15px 10px 10px 5px;"><span
                                                style="background: #273C4E;"
                                                class="userAbbrv">{{authorAbbreviation(activity.authorFirstName, activity.authorLastName)}}</span>
                                        </div>
                                        <div style="margin: auto 0px;" *ngIf="activity.activityType == 'comment'">
                                            <span style="font-weight: bold; color: #273C4E;">{{activity.authorFirstName}} {{activity.authorLastName}} </span>
                                            &nbsp;
                                            <span style="font-size: 0.9em; color: #7F7F7F;">{{this.formatAddingDate(activity.activityDate)}}</span>
                                            <div style="margin:6px;" [innerHTML]="activity.activity"></div>
                                        </div>
                                        <div style="margin: auto 0px;" *ngIf="activity.activityType == 'history'">
                                            <span style="font-weight: bold; color: #273C4E;">{{activity.authorFirstName}} {{activity.authorLastName}} </span>
                                            <span [innerHTML]="activity.activity"></span>
                                            <div style="font-size: 0.85em; color:#7F7F7F;">{{this.formatAddingDate(activity.activityDate)}}</div>
                                        </div>
                                        <div class="hidden-commentButtons"
                                             *ngIf="activity.activityType == 'comment' && (activity.isAuthor || isSupervisor)"
                                             style="margin-left: auto;">
                                            <button class="commentButtons" mat-button
                                                    (click)="editMonitoredArticleComment(activity)">
                                                <mat-icon>edit</mat-icon>
                                            </button>
                                            <button class="commentButtons" mat-button
                                                    (click)="deleteMonitoredArticleComment(activity)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-template #loading>
                            <loading-overlay>{{"LOADING_ACTIVITIES"}}</loading-overlay>
                        </ng-template>
                        <ng-template #noActivityData>
                            <no-data>{{"NO_ACTIVITY"}}</no-data>
                        </ng-template>
                    </div>
                </div>

            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <div>
                        <span style="margin-right: 25px; font-weight: bold;color: #273C4E;">Pièces-jointes</span>
                        <mat-icon style="vertical-align: top;line-height: inherit;align-items: center;">attach_file
                        </mat-icon>
                        <span> {{(monitoredArticleAttachments | async)?.length }}</span>
                    </div>
                </ng-template>

                <!-- Bloc Pièces-jointes Monitoring -->
                <div style="border: 1px darkgrey solid; padding: 15px;" class="marginModal greyBorderTop"
                     *ngIf="from === 'monitoring'">
                    <div *ngIf="!isVisualisation">
                        <button style="width: 100%; margin-top: 10px; text-align: center; background-color: #C20015; color: white;"
                                (click)="shareAttachment()" mat-button>
                            Ajouter une pièce-jointe
                        </button>
                    </div>
                    <div class="commentAndActivityContainer">
                        <ng-container
                                *ngIf="(monitoredArticleAttachments$ | async); else loadingAttachments">
                            <ng-container
                                    *ngIf="(monitoredArticleAttachments | async)?.length; else noAttachmentData">
                                <div *ngFor="let monitoredArticleAttachment of (monitoredArticleAttachments | async)">
                                    <div style="width:100%; background-color: #A3A2A2; color: white; margin: 5px 0px; border-radius: 4px;">
                                        <div style="display: flex;">
                                            <button (click)="openFile(monitoredArticleAttachment)"
                                                    mat-button> {{ monitoredArticleAttachment.filename }}
                                            </button>
                                            <div style="margin-left: auto; padding-top: 7px; padding-right: 16px;">
                                                {{ this.attachmentAddingText(monitoredArticleAttachment) }}
                                                <ng-container *ngIf="monitoredArticleAttachment.isAuthor">
                                                    <button class="attachmentEditingBtns"
                                                            *ngIf="!article.monitoring.isVisualisation"
                                                            (click)="editMonitoredArticleAttachmentVisibility(monitoredArticleAttachment)"
                                                            mat-button>
                                                        <mat-icon class="material-icons mat-icon-no-color"
                                                                  style="color: white;"> {{ monitoredArticleAttachment.private ? 'visibility_off' : 'visibility'}}
                                                        </mat-icon>
                                                    </button>
                                                    <button class="attachmentEditingBtns"
                                                            *ngIf="!article.monitoring.isVisualisation"
                                                            (click)="deleteMonitoredArticleAttachment(monitoredArticleAttachment)"
                                                            mat-button>
                                                        <mat-icon class="material-icons mat-icon-no-color"
                                                                  style="color: white;">
                                                            delete
                                                        </mat-icon>
                                                    </button>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </ng-container>
                        </ng-container>
                    </div>
                    <ng-template #loadingAttachments>
                        <loading-overlay>{{"LOADING_ATTACHMENTS" }}</loading-overlay>
                    </ng-template>
                    <ng-template #noAttachmentData>
                        <no-data>{{"NO_ATTACHMENT" }}</no-data>
                    </ng-template>
                </div>

            </mat-tab>
        </mat-tab-group>
    </div>

    <div *ngIf="from === 'favorite'" class="marginModal greyBorderTop commentBloc"
         [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
        <mat-form-field [ngClass]="{'lowBotPad': mediaQueryService.mobileQuery.matches}" appearance="outline"
                        class="full-width">
            <mat-label>{{"COMMENT"}}</mat-label>
            <textarea matInput [(ngModel)]="favoriteComment" rows="5">{{article.data.comment}}</textarea>
        </mat-form-field>
        <mat-slide-toggle [checked]="article.data.commentIsPrivate"
                          (change)="toggleCommentPrivacy($event)">{{"COMMENT_PRIVATE"}}</mat-slide-toggle>
    </div>

    <!--    Bloc Leads prédictifs -->
    <div class="marginModal greyBorderTop" *ngIf="article.predictions !== undefined && false">
        <mat-label class="predictionHeader">Leads Prédictifs®</mat-label>
        <mat-accordion *ngIf="leadsAvailable">

            <mat-expansion-panel (opened)="leadOpenLog(tag,isFirst)" *ngFor="let tag of article.predictions; first as isFirst" [expanded]="isFirst">
                <mat-expansion-panel-header class="leadsHeader">
                    <mat-panel-title class="predictionTitle">

<!--                        <rg-gauge-chart style="margin-top: 10px;"-->
<!--                                        [canvasWidth]="50"-->
<!--                                        [needleValue]="tag.percentage"-->
<!--                                        [centralLabel]=""-->
<!--                                        [options]="{-->
<!--                          hasNeedle: true,-->
<!--                          needleColor: 'gray',-->
<!--                          needleUpdateSpeed: 1000,-->
<!--                          arcColors: ['rgb(255, 0, 0)', 'lightgray'],-->
<!--                          arcDelimiters: [tag.percentage],-->
<!--                          needleStartValue: 50}">-->
<!--                        </rg-gauge-chart>-->
                    </mat-panel-title>
                    <mat-panel-description>
                        <mat-label [textContent]="tag.label"
                                   [ngClass]="{'redTextLightBgColor': tag.matchSearch, 'redTextBgColor': !tag.matchSearch, 'mobile': mediaQueryService.mobileQuery.matches }"
                                   class="predictionLabel"></mat-label>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div style="margin-top: 10px;" [innerHTML]="getPrescripionText(tag)"></div>

            </mat-expansion-panel>
        </mat-accordion>
        <!--    <mat-accordion *ngIf="leadsAvailable">-->
        <!--      <mat-expansion-panel *ngFor="let tag of article.tags.topics; first as isFirst" [expanded]="isFirst">-->
        <!--        <mat-expansion-panel-header>-->
        <!--          <mat-panel-title class="predictionTitle">-->
        <!--            <rg-gauge-chart-->
        <!--                    [canvasWidth]="70"-->
        <!--                    [needleValue]="50"-->
        <!--                    [options]="{-->
        <!--                      hasNeedle: true,-->
        <!--                      needleColor: 'gray',-->
        <!--                      needleUpdateSpeed: 1000,-->
        <!--                      arcColors: ['rgb(255, 0, 0)', 'lightgray'],-->
        <!--                      arcDelimiters: [50],-->
        <!--                      needleStartValue: 50}">-->
        <!--            </rg-gauge-chart>-->
        <!--          </mat-panel-title>-->
        <!--          <mat-panel-description>-->
        <!--            <mat-label [textContent]="tag.label" class="predictionLabel"></mat-label>-->
        <!--          </mat-panel-description>-->
        <!--        </mat-expansion-panel-header>-->
        <!--        {{tag.label}}-->
        <!--      </mat-expansion-panel>-->
        <!--    </mat-accordion>-->
    </div>
</ion-content>

<ng-template #noData>
    <no-data>{{"NO_ARTICLE"}}</no-data>
</ng-template>
