<!-- <ion-header no-border>
  <ion-toolbar color="primary-tint" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
    <div class="customerStatus">Status client</div>

    <ion-buttons slot="end">
      <user-last-connexion [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}"></user-last-connexion>
    </ion-buttons>
  </ion-toolbar>
</ion-header> -->
<ion-content class="accountPage">
  
  <mat-toolbar color="accent" class="smallAccent">
    <span *ngIf="!mediaQueryService.mobileQuery.matches">Mes infos personnelles et mon application mobile</span>
    <span *ngIf="mediaQueryService.mobileQuery.matches">Mes infos personnelles</span>
  </mat-toolbar>
  <div style="display: flex; flex-direction: row;">
    <div class="my-account-content ion-padding" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches, 'personnalInfos': !mediaQueryService.mobileQuery.matches}">
        <ng-container *ngIf="sessionData; else loadingInformationsData">
          <div style="margin-bottom: 10px;height: 40px;">
            <div style="color: rgba(0,0,0,.6);font-size: 14px;">Nom</div>
            <div>{{sessionData.customerDetails.civility}} {{sessionData.customerDetails.firstname}} {{sessionData.customerDetails.lastname}}</div>
          </div>
          <div style="margin-bottom: 10px">
            <div style="color: rgba(0,0,0,.6);font-size: 14px;">Société</div>
            <div>{{sessionData.customerDetails.companyName}}</div>
          </div>
          <div>
            <div style="color: rgba(0,0,0,.6);font-size: 14px;">Email</div>
            <div>{{sessionData.customerDetails.email}}</div>
          </div>
  <!--        <mat-form-field class="example-full-width" *ngIf="sessionData.customerDetails.civility">-->
  <!--          <input matInput placeholder="{{'MY_ACCOUNT_INFORMATIONS_CIVILITY' | translate}}" disabled value="{{sessionData.customerDetails.civility}}">-->
  <!--        </mat-form-field>-->
  <!--        <mat-form-field class="example-full-width" *ngIf="sessionData.customerDetails.firstname">-->
  <!--          <input matInput placeholder="{{'FIRSTNAME' | translate}}" disabled value="{{sessionData.customerDetails.firstname}}">-->
  <!--        </mat-form-field>-->
  <!--        <mat-form-field class="example-full-width" *ngIf="sessionData.customerDetails.lastname">-->
  <!--          <input matInput placeholder="{{'LASTNAME' | translate}}" disabled value="{{sessionData.customerDetails.lastname}}">-->
  <!--        </mat-form-field>-->
  <!--        <mat-form-field class="example-full-width" *ngIf="sessionData.customerDetails.companyName">-->
  <!--          <input matInput placeholder="{{'COMPANY' | translate}}" disabled value="{{sessionData.customerDetails.companyName}}">-->
  <!--        </mat-form-field>-->
  <!--        <mat-form-field class="example-full-width" *ngIf="sessionData.customerDetails.email">-->
  <!--          <input matInput placeholder="{{'EMAIL' | translate}}" disabled value="{{sessionData.customerDetails.email}}">-->
  <!--        </mat-form-field>-->
  
        </ng-container>
        <ng-template #loadingInformationsData>
          <mat-progress-spinner color="accent" mode="indeterminate" diameter="20"></mat-progress-spinner>
        </ng-template>
    </div>
    <div *ngIf="!mediaQueryService.mobileQuery.matches && !isLettrePersoMC" style="width: 50%;" class="ion-padding">
      <button mat-raised-button color="primary" (click)="getMobileLoginInfos()">Recevoir mes identifiants pour l'application mobile</button>
    </div>
  </div>

  <mat-toolbar color="accent" class="smallAccent">
    <span>{{"MY_ACCOUNT_FORMULA"}}</span>
  </mat-toolbar>
  <div class="my-account-content formula ion-padding" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
      <ng-container *ngIf="sessionData; else loadingFormulaData">
        <div style="margin-bottom: 10px;">
          <div style="color: rgba(0,0,0,.6);font-size: 14px;">Offre</div>
          <div>{{sessionData.productOfferLabel}}</div>
        </div>
  
        <div>
          <div style="color: rgba(0,0,0,.6);font-size: 14px;">Critères</div>
          <div>{{criterions}}</div>
        </div>
        
<!--        <mat-form-field class="example-full-width" *ngIf="sessionData.productStatus">-->
<!--          <input matInput placeholder="{{'OFFER' | translate}}" disabled value="{{sessionData.productOfferLabel}}">-->
<!--        </mat-form-field>-->
<!--        <mat-form-field class="example-full-width" *ngIf="criterions">-->
<!--          <input matInput placeholder="{{'CRITERIONS' | translate}}" disabled value=" ">-->
<!--          <div class="criterions-content">{{criterions}}</div>-->
<!--        </mat-form-field>-->
      </ng-container>
      <ng-template #loadingFormulaData>
        <mat-progress-spinner class="" color="accent" mode="indeterminate" diameter="20"></mat-progress-spinner>
      </ng-template>
  </div>

  <mat-toolbar color="accent" class="smallAccent">
    <span>{{"MY_ACCOUNT_COMMERCIAL"}}</span>
  </mat-toolbar>
  <div class="my-account-content ion-padding" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
      <ng-container *ngIf="sessionData; else loadingCommercialData">
        <div style="margin-bottom: 10px;">
          <div style="color: rgba(0,0,0,.6);font-size: 14px;">Prénom</div>
          <div>{{sessionData.salesPersonDetails.salesPersonFirstname}}</div>
        </div>
        <div style="margin-bottom: 10px;">
          <div style="color: rgba(0,0,0,.6);font-size: 14px;">Nom</div>
          <div>{{sessionData.salesPersonDetails.salesPersonName}}</div>
        </div>
        <div style="margin-bottom: 10px;">
          <div style="color: rgba(0,0,0,.6);font-size: 14px;">Téléphone</div>
          <div>{{sessionData.salesPersonDetails.salesPersonPhone}}</div>
        </div>
        <div>
          <div style="color: rgba(0,0,0,.6);font-size: 14px;">Email</div>
          <div>{{sessionData.salesPersonDetails.salesPersonEmail}}</div>
        </div>
<!--        <mat-form-field class="example-full-width">-->
<!--          <input matInput placeholder="{{'FIRSTNAME' | translate}}" disabled value="{{sessionData.salesPersonDetails.salesPersonFirstname}}">-->
<!--        </mat-form-field>-->
<!--        <mat-form-field class="example-full-width">-->
<!--          <input matInput placeholder="{{'LASTNAME' | translate}}" disabled value="{{sessionData.salesPersonDetails.salesPersonName}}">-->
<!--        </mat-form-field>-->
<!--        <mat-form-field class="example-full-width">-->
<!--          <input matInput placeholder="{{'TELEPHONE' | translate}}" disabled value="{{sessionData.salesPersonDetails.salesPersonPhone}}">-->
<!--        </mat-form-field>-->
<!--        <mat-form-field class="example-full-width">-->
<!--          <input matInput placeholder="{{'EMAIL' | translate}}" disabled value="{{sessionData.salesPersonDetails.salesPersonEmail}}">-->
<!--        </mat-form-field>-->
      </ng-container>
      <ng-template #loadingCommercialData>
        <mat-progress-spinner class="" color="accent" mode="indeterminate" diameter="20"></mat-progress-spinner>
      </ng-template>
  </div>

  <div class="ion-padding">
    <button mat-raised-button color="primary" class="full" (click)="confirmLogout()">{{"LOGOUT_BUTTON" | uppercase}}</button>
  </div>
  
</ion-content>
