import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {SalesPersonDetailsInterface} from "../../../interfaces/session/sales-person-details.interface";
import {ModalService} from "../../../services/modal/modal.service";
import {SnackbarService} from "../../../services/snackbar/snackbar.service";
import {MailsService} from '../../../services/mails/mails.service';

@Component({
  selector: 'app-fe-customer-departements-modal',
  templateUrl: './fe-customer-departements-modal.component.html',
  styleUrls: ['./fe-customer-departements-modal.component.scss']
})
export class FeCustomerDepartementsModalComponent {
  protected departements: string[] = [];
  protected salesPersonDetails: SalesPersonDetailsInterface = {};

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private modalService: ModalService,
              private snackbarService: SnackbarService,
              private mailService: MailsService) {
    if (data != null) {
      if (data.departements != null) {
        this.departements = data.departements;
      }
      if (data.salesPersonDetails != null) {
        this.salesPersonDetails = data.salesPersonDetails;
      }
    }
  }

  protected getCommercialName(): string {
    let commercialName: string = '';
    if (this.salesPersonDetails.salesPersonFirstname != null) {
      commercialName += this.salesPersonDetails.salesPersonFirstname.trim();
    }
    if (this.salesPersonDetails.salesPersonName != null) {
      if (commercialName.length > 0) {
        commercialName += ' ';
      }
      commercialName += this.salesPersonDetails.salesPersonName;
    }

    return commercialName;
  }

  protected askForContact(): void {
    this.modalService.textAreaModal('Demande de contact', 'Une demande de contact va être envoyée à ' +
        'votre commercial dédié.<br>' + 'Voulez-vous continuer ?', args => {
      this.mailService.contactSalespersonn('profile', args).subscribe(value => {
        this.snackbarService.showSuccessSnackbar('Demande de contact envoyé',
            'Votre commercial dédié vous recontactera sous 48h.');
      });
    }, 'Vous pouvez ajouter un commentaire à votre demande.');
  }
}
