import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { ApiService } from '../api/api.service';
import { DateArticleInterface } from 'src/app/interfaces/date-article/date-article.interface';
import { UserService } from '../user/user.service';
import { SessionInterface } from 'src/app/interfaces/session/session.interface';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DateArticleService {

/*   private _dateArticle$: BehaviorSubject<DateArticleInterface> = new BehaviorSubject(
    {
      from: undefined,
      to: undefined,
      period: undefined
    }
  ); */

  // private _dateArticle$: ReplaySubject<DateArticleInterface> = new ReplaySubject(1);
  private _dateArticle$: BehaviorSubject<DateArticleInterface> = new BehaviorSubject(undefined);

  public get dateArticle$(): Observable<DateArticleInterface> {
    return this._dateArticle$;
  }

  // Default dates
  public startDate: string;
  public endDate: string;

  // Min dates of the form
  public minDate: string;

  constructor(
    private apiService: ApiService
    , private userService: UserService
    , @Inject(LOCALE_ID) public locale: string
  ) {
  }
  public init(): void {

    // Default dates
    // this.startDate = formatDate(new Date(Date.now() - 120 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd', this.locale);
    // this.endDate = formatDate(new Date(), 'yyyy-MM-dd', this.locale);

    this.userService.getSessionDatas().subscribe(
      (session: SessionInterface) => {
        if (session !== undefined) {
          if (session.publicationPeriodStart !== undefined) {
            this.startDate = session.publicationPeriodStart.toString();
          } else {
            this.startDate = new Date(Date.now() - 120 * 24 * 60 * 60 * 1000).toISOString();
          }

          if (session.publicationPeriodEnd !== undefined) {
            this.endDate = session.publicationPeriodEnd.toString();
          } else {
            this.endDate = new Date().toISOString();
          }

          if (session.publicationPeriodStartLimit !== undefined) {
            this.minDate = session.publicationPeriodStartLimit.toString();
          } else {
            this.endDate = new Date().toISOString();
          }

          this.startDate = formatDate(new Date(this.startDate), 'yyyy-MM-dd', this.locale);
          this.endDate = formatDate(new Date(this.endDate), 'yyyy-MM-dd', this.locale);

          this.setDate({ from: this.startDate, to: this.endDate, period: undefined });
        }
      }
    );
  }

  public setDate(dateArticle: DateArticleInterface): void {
    // this.startDate = dateArticle.from;
    this.startDate = dateArticle.from;// '2022-05-24T00:00:00.000+0200';
    this.endDate = dateArticle.to;
    this._dateArticle$.next(dateArticle);
  }

  public parsePeriodDate(period: string): string {
    switch (period) {
      case 'LAST_WEEK': {
        return formatDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toString(), 'yyy-MM-dd', this.locale);
      }
      case 'LAST_MONTH': {
        return formatDate(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toString(), 'yyy-MM-dd', this.locale);
      }
      case 'LAST_3_MONTHS': {
        return formatDate(new Date(Date.now() - 90 * 24 * 60 * 60 * 1000).toString(), 'yyy-MM-dd', this.locale);
      }
      case 'LAST_6_MONTHS': {
        return formatDate(new Date(Date.now() - 180 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd', this.locale);
      }
      case 'LAST_12_MONTHS': {
        return formatDate(new Date(Date.now() - 365 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd', this.locale);
      }
      case 'LAST_24_MONTHS': {
        return formatDate(new Date(Date.now() - 730 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd', this.locale);
      }
      case 'LAST_36_MONTHS': {
        return formatDate(new Date(Date.now() - 1095 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd', this.locale);
      }
    }
  }

  public getDateStartFormatted() {
    return formatDate(new Date(this.startDate), 'yyyy-MM-dd', this.locale);
  }

  public getDateEndFormatted() {
    return formatDate(new Date(this.endDate), 'yyyy-MM-dd', this.locale);
  }

  public getMinDateFormatted() {
    return formatDate(new Date(this.minDate), 'yyyy-MM-dd', this.locale);
  }
}
