import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatCheckbox} from "@angular/material/checkbox";

@Component({
  selector: 'fe-checkbox',
  templateUrl: './fe-checkbox.component.html',
  styleUrls: ['./fe-checkbox.component.scss']
})
export class FeCheckboxComponent extends MatCheckbox{
  @Input() text: string = '';
  @Input() textDirection: string = 'RIGHT';
  @Input() bold: boolean = false;
  protected inputValue: boolean = false;
  protected indeterminateValue: boolean = false;

  // @Input()
  // get value() {
  //   return this.inputValue;
  // }

  @Input()
  get indeterminate() {
    return this.indeterminateValue;
  }

  // set value(val: boolean) {
  //   this.indeterminateValue = val;
  //   this.valueChange.emit(this.inputValue);
  // }

  set indeterminate(val: boolean) {
    this.indeterminateValue = val;
    this.indeterminateChange.emit(this.indeterminateValue);
  }

  @Output()
  valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  indeterminateChange: EventEmitter<boolean> = new EventEmitter<boolean>();
}
