import {NavController} from '@ionic/angular';

export class Utils {

    static connected: boolean;
    static userLogin: string;
    static skip: boolean = false;
    static equalPrevious: boolean = false;
    static offCriterions: Array<number> = [];

    constructor() {

    }


    public static userToken: string;
    public static branchParams = [];
    public static webToLeadCompanies: Array<String> = ['0010Y00000t5ttlQAA', '0010Y00000t5W1AQAU'];

    public static getSkip() {
        return this.skip;
    }

    public static deepCopy<T>(obj: T): T {
        // Use JSON.stringify to serialize the object to a JSON string
        const serializedObj = JSON.stringify(obj);

        // Use JSON.parse to deserialize the JSON string back into an object
        const copiedObj = JSON.parse(serializedObj);

        return copiedObj as T;
    }

    public static cloneDeep(source: Object): Object {
        const clone: Object = Object.assign({}, source);
        return clone;
    }

    public static getRandomInt(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    public static getHighlightedText(text: string, keywords: Array<string>): string {
        if (keywords && keywords !== undefined && keywords.length > 0) {
            let corps = text;
            for (const key of keywords) {
                const keyForRegex = this.getKeyForRegex(key);
                corps = corps.replace(new RegExp(keyForRegex, 'gi'), match => {
                    return '<span style="background: var(--main-blue);color: white;">' + match + '</span>';
                });
            }
            return corps;
        }
        return text;
    }

    private static getKeyForRegex(key: string) {
        return key.replace('e', '[eéèêë]').replace('a', '[aàâ]').replace('o', '[oô]')
            .replace('u', '[uùûü]').replace('i', '[iïî]').replace('c', '[cç]');
    }

    public static browserDetect(): string {
        const data = [
            {string: window.navigator.userAgent, subString: 'Edge', identity: 'MS Edge'},
            {string: window.navigator.userAgent, subString: 'MSIE', identity: 'Explorer'},
            {string: window.navigator.userAgent, subString: 'Trident', identity: 'Explorer'},
            {string: window.navigator.userAgent, subString: 'Firefox', identity: 'Firefox'},
            {string: window.navigator.userAgent, subString: 'Opera', identity: 'Opera'},
            {string: window.navigator.userAgent, subString: 'OPR', identity: 'Opera'},

            {string: window.navigator.userAgent, subString: 'Chrome', identity: 'Chrome'},
            {string: window.navigator.userAgent, subString: 'Safari', identity: 'Safari'}
        ];

        for (let i = 0; i < data.length; i++) {
            const dataString = data[i].string;

            if (dataString.indexOf(data[i].subString) !== -1) {
                return data[i].identity;
            }
        }

        return 'Unknown';
    }

    public static navigate(url: string, navController: NavController, extras?) {
        if (extras === undefined) {
            extras = {
                queryParams: {
                    token: this.userToken
                }
            };
        } else {
            extras.queryParams.token = this.userToken;
        }
        navController.navigateForward(url, extras);
    }

    public static getParameterByName(name: any, urlToParse?: string) {
        let url = '';
        if (urlToParse !== undefined && urlToParse !== null) {
            url = urlToParse;
        } else {
            url = window.location.href;
        }
        name = name.replace(/[[]]/g, '\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) {
            return undefined;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace('/+/g', ' '));
    }

    static toParamMap(params: string): any {
        const urlParams = new URLSearchParams(params);
        const result = new Object();
        if (urlParams.has('token')) {
            result['token'] = urlParams.get('token');
        }
        if (urlParams.has('article')) {
            result['article'] = urlParams.get('article');
        }
        if (urlParams.has('sdate')) {
            result['sdate'] = urlParams.get('sdate');
        }
        if (urlParams.has('edate')) {
            result['edate'] = urlParams.get('edate');
        }
        if (urlParams.has('search')) {
            result['search'] = urlParams.get('search');
        }
        if (urlParams.has('oldUrl')) {
            result['oldUrl'] = urlParams.get('oldUrl');
        }
        if (urlParams.has('monitoringId')) {
            result['monitoringId'] = urlParams.get('monitoringId');
        }
        if (urlParams.has('monitoringArticleId')) {
            result['monitoringArticleId'] = urlParams.get('monitoringArticleId');
        }
        if (urlParams.has('isLead')) {
            result['isLead'] = urlParams.get('isLead');
        }
        if (urlParams.has('folderID')) {
            result['folderID'] = urlParams.get('folderID');
        }
        if (urlParams.has('entreprise')) {
            result['entreprise'] = urlParams.get('entreprise');
        }

        return result;
    }

    static setParamsFromBranch(params: string) {
        this.branchParams['sdate'] = this.getParameterByName('sdate', params);
        this.branchParams['edate'] = this.getParameterByName('edate', params);
        this.branchParams['article'] = this.getParameterByName('article', params);
        this.branchParams['search'] = this.getParameterByName('search', params);
    }


    static hasBranchParameters() {
        if (this.branchParams['article'] !== undefined) {
            return true;
        }
        return false;
    }

    static notNullOrEmpty(array: Array<string>) {
        return array !== undefined && array.length !== 0;
    }

    static transferAllItems(sourceArray: number[], destinationArray: number[]): void {
        // Transfer all items from the source array to the destination array
        while (sourceArray.length > 0) {
            const transferredItem = sourceArray.shift();
            if (transferredItem !== undefined) {
                destinationArray.push(transferredItem);
            }
        }
    }

    static deepEqual(obj1, obj2) {
        if (!obj1 || !obj2) {
            return false;
        }
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        for (const key of keys1) {
            const val1 = obj1[key];
            if (!val1) {
                continue;
            }
            const val2 = obj2[key];

            if (typeof val1 === 'object' && typeof val2 === 'object') {
                if (!Utils.deepEqual(val1, val2)) {
                    return false;
                }
            } else if (val1 !== val2) {
                return false;
            }
        }

        return true;
    }

}
