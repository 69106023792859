import {Injectable} from '@angular/core';

import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';

import {environment} from '../../../environments/environment';
import {Observable, throwError, from} from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {map, publishReplay, refCount, switchMap, takeUntil} from 'rxjs/operators';
import {LogInterface} from '../../interfaces/logging/log.interface';
import {LogContextInterface} from '../../interfaces/logging/log.context.interface';
import {LogDataInterface} from '../../interfaces/logging/log.data.interface';
import {LogArticleInterface} from '../../interfaces/logging/log.article.interface';
import {LogCriterionInterface} from '../../interfaces/logging/log.criterion.interface';
import {ArticleInterface} from '../../interfaces/articles/article.interface';
import {ErrorStatus} from '../../classes/ErrorStatus.class';
import {SessionInterface} from '../../interfaces/session/session.interface';


@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(
        private httpClient: HttpClient
        , private authService: AuthService
    ) {
    }

    /**
     * Transform headers object to `HttpHeaders`
     *
     * @param headers   The headers object
     *
     * @return An `HttpHeaders`
     */
    private getHeaders(headers: { [key: string]: string } = {}): HttpHeaders {
        Object.keys(headers).map((key: string) => (typeof headers[key] === 'undefined' || headers[key] === null || headers[key] === '') ? delete headers[key] : '');
        return Object.keys(headers).reduce((httpHeaders: HttpHeaders, key: string) => httpHeaders = httpHeaders.set(key, headers[key] as string), new HttpHeaders());
    }

    /**
     * Transform params object to `HttpParams`
     *
     * @param params The url params object
     *
     * @return An `HttpParams`
     */
    private getParams(params: { [key: string]: any } = {}): HttpParams {
        Object.keys(params).map((key: string) => (typeof params[key] === 'undefined' || params[key] === null || params[key] === '') ? delete params[key] : '');
        return Object.keys(params).reduce((httpParam: HttpParams, key: string) => httpParam = httpParam.set(key, params[key] as string), new HttpParams());
    }

    /**
     * Add Content-type and Authorization entries in headers object (before transforming into HttpHeaders)
     * @param headers { [key: string]: string }
     */
    private setSecureAuthHeaders(headers: { [key: string]: string }): Promise<{ [key: string]: string }> {
        return this.authService.getStoredToken()
            .then((token: string) => {
                headers['Content-Type'] = 'application/x-www-form-urlencoded';
                // headers['Authorization'] = 'Basic ' + btoa(environment.api.username + ':' + environment.api.password);
                headers['X-Fingerprint'] = this.authService.getFingerprint();
                if (this.authService.roleCom) {
                    headers['X-Role-Com'] = 'OO64QW6LBS3NG8Z0HZAO';
                }
                return headers;
            });
    }

    /**
     * Add Content-type and Authorization entries in headers object (before transforming into HttpHeaders)
     * @param headers { [key: string]: string }
     */
    private setDefaultAuthHeaders(headers: { [key: string]: string }): Promise<{ [key: string]: string }> {
        return this.authService.getStoredToken()
            .then((token: string) => {
                headers['Content-Type'] = headers['Content-Type'] !== undefined ? headers['Content-Type'] : 'application/json';
                headers['Authorization'] = 'Bearer ' + token;
                headers['X-Fingerprint'] = this.authService.getFingerprint();
                if (this.authService.roleCom) {
                    headers['X-Role-Com'] = 'OO64QW6LBS3NG8Z0HZAO';
                }
                return headers;
            });
    }


    private setDefaultAuthHeadersFileUpload(headers: { [key: string]: string }): Promise<{ [key: string]: string }> {
        return this.authService.getStoredToken()
            .then((token: string) => {
                headers['Authorization'] = 'Bearer ' + token;
                headers['X-Fingerprint'] = this.authService.getFingerprint();
                if (this.authService.roleCom) {
                    headers['X-Role-Com'] = 'OO64QW6LBS3NG8Z0HZAO';
                }
                return headers;
            });
    }

    /**
     * Call a `HttpClient` `GET` method
     *
     * @param endpoint  The endpoint URL.
     * @param params    The url params object
     * @param headers   The headers object
     *
     * @return An `Observable` of the full `HTTPResponse` for the request, with a response body in the requested type.
     */
    public get<T>(endpoint: string = null, params: { [key: string]: any } = {}, headers: { [key: string]: string } = {}): Observable<HttpResponse<T>> {
        if (endpoint === null) {
            throwError(new Error('E_NO_ENDPOINT'));
        }

        const url: string = environment.api.url + endpoint;

        return from(this.setDefaultAuthHeaders(headers)).pipe(
            switchMap((headersWithAuth: { [key: string]: string }) => {
                try {
                    return this.httpClient.get<T>(url, {
                        headers: this.getHeaders(headersWithAuth),
                        observe: 'response',
                        params: this.getParams(params),
                        responseType: 'json'
                    });
                } catch (e) {
                    console.log(e);
                }
            })
        );
    }

    /**
     * Call a `HttpClient` `GET` method as Blob
     *
     * @param endpoint  The endpoint URL.
     * @param params    The url params object
     * @param headers   The headers object
     *
     * @return An `Observable` of the full `HTTPResponse` for the request, with a response body in the requested type.
     */
    public getAsBlob<T>(endpoint: string = null, params: { [key: string]: any } = {}, headers: { [key: string]: string } = {}): Observable<HttpResponse<T>> {
        if (endpoint === null) {
            throwError(new Error('E_NO_ENDPOINT'));
        }

        const url: string = environment.api.url + endpoint;

        return from(this.setDefaultAuthHeaders(headers)).pipe(
            switchMap((headersWithAuth: { [key: string]: string }) => {
                return this.httpClient.get<HttpResponse<T>>(url, {
                    headers: this.getHeaders(headersWithAuth),
                    observe: 'response' as 'body',
                    params: this.getParams(params),
                    responseType: 'blob' as 'json'
                });
            })
        );
    }

    /**
     * Call a `HttpClient` secure `POST` method
     *
     * @param endpoint  The endpoint URL
     * @param data      The data object
     * @param headers   The headers object
     *
     * @return An `Observable` of the full `HTTPResponse` for the request, with a response body in the requested type.
     */
    public securePost<T>(endpoint: string = null, data: { [key: string]: any } = {}, headers: { [key: string]: string } = {}): Observable<HttpResponse<T>> {
        if (endpoint === null) {
            throwError(new Error('E_NO_ENDPOINT'));
        }

        const url: string = environment.api.secure + endpoint;

        return from(this.setSecureAuthHeaders(headers)).pipe(
            switchMap((headersWithAuth: { [key: string]: string }) => {
                return this.httpClient.post<T>(url, this.getParams(data), {
                    headers: this.getHeaders(headersWithAuth),
                    observe: 'response',
                    responseType: 'json'
                });
            })
        );
    }

    public secureGet<T>(endpoint: string = null, params: { [key: string]: any } = {}, headers: { [key: string]: string } = {}): Observable<HttpResponse<T>> {
        if (endpoint === null) {
            throwError(new Error('E_NO_ENDPOINT'));
        }

        const url: string = environment.api.secure + endpoint;

        return from(this.setDefaultAuthHeaders(headers)).pipe(
            switchMap((headersWithAuth: { [key: string]: string }) => {
                return this.httpClient.get<T>(url, {
                    headers: this.getHeaders(headersWithAuth),
                    observe: 'response',
                    params: this.getParams(params),
                    responseType: 'json'
                });
            })
        );
    }

    /**
     * Call a `HttpClient` `POST` method
     *
     * @param endpoint  The endpoint URL
     * @param data      The data object
     * @param headers   The headers object
     * @param isFileUpload True if it file uploading
     *
     * @return An `Observable` of the full `HTTPResponse` for the request, with a response body in the requested type.
     */
    public post<T>(endpoint: string = null, data: { [key: string]: any } = {}, isFileUpload: boolean = false, headers: { [key: string]: string } = {}): Observable<HttpResponse<T>> {
        if (endpoint === null) {
            throwError(new Error('E_NO_ENDPOINT'));
        }

        const url: string = environment.api.url + endpoint;

        return from(isFileUpload ? this.setDefaultAuthHeadersFileUpload(headers) : this.setDefaultAuthHeaders(headers)).pipe(
            switchMap((headersWithAuth: { [key: string]: string }) => {
                return this.httpClient.post<T>(url, data, {
                    headers: this.getHeaders(headersWithAuth),
                    observe: 'response',
                    responseType: 'json'
                });
            })
        );
    }

    postWithoutAuth<T>(endpoint: string) {
        if (endpoint === null) {
            throwError(new Error('E_NO_ENDPOINT'));
        }

        const url: string = environment.api.url + endpoint;

        return this.httpClient.post<T>(url, {
            observe: 'response',
            responseType: 'json'
        });
    }

    /**
     * Call a `HttpClient` `PATCH` method
     *
     * @param endpoint  The endpoint URL.
     * @param data      The data object
     * @param headers   The headers object
     *
     * @return An `Observable` of the full `HTTPResponse` for the request, with a response body in the requested type.
     */
    public patch<T>(endpoint: string = null, data: { [key: string]: any } = {}, headers: { [key: string]: string } = {}): Observable<HttpResponse<T>> {
        if (endpoint === null) {
            throwError(new Error('E_NO_ENDPOINT'));
        }

        const url: string = environment.api.url + endpoint;

        return from(this.setDefaultAuthHeaders(headers)).pipe(
            switchMap((headersWithAuth: { [key: string]: string }) => {
                return this.httpClient.patch<T>(url, data, {
                    headers: this.getHeaders(headersWithAuth),
                    observe: 'response',
                    responseType: 'json'
                });
            })
        );
    }

    /**
     *  Call a `HttpClient` `DELETE` method
     *
     * @param endpoint  The endpoint URL.
     * @param params    The url params object
     * @param headers   The headers object
     *
     * @return An `Observable` of the full `HTTPResponse` for the request, with a response body in the requested type.
     */
    public delete<T>(endpoint: string = null, params: { [key: string]: any } = {}, headers: { [key: string]: string } = {}): Observable<HttpResponse<T>> {
        if (endpoint === null) {
            throwError(new Error('E_NO_ENDPOINT'));
        }

        const url: string = environment.api.url + endpoint;

        return from(this.setDefaultAuthHeaders(headers)).pipe(
            switchMap((headersWithAuth: { [key: string]: string }) => {
                return this.httpClient.delete<T>(url, {
                    headers: this.getHeaders(headersWithAuth),
                    observe: 'response',
                    responseType: 'json',
                    params: params
                });
            })
        );
    }

    /**
     *  Call a `HttpClient` `DELETE` method
     *
     * @param endpoint  The endpoint URL.
     * @param params    The url params object
     * @param headers   The headers object
     *
     * @return An `Observable` of the full `HTTPResponse` for the request, with a response body in the requested type.
     */
    public deleteBody<T>(endpoint: string = null, data: { [key: string]: any } = {}, headers: { [key: string]: string } = {}): Observable<HttpResponse<T>> {
        if (endpoint === null) {
            throwError(new Error('E_NO_ENDPOINT'));
        }

        const url: string = environment.api.url + endpoint;

        return from(this.setDefaultAuthHeaders(headers)).pipe(
            switchMap((headersWithAuth: { [key: string]: string }) => {

                return this.httpClient.request<T>('delete', url, {
                    headers: this.getHeaders(headersWithAuth),
                    observe: 'response',
                    responseType: 'json',
                    body: data
                });
            })
        );
    }

    /**
     * Call a `HttpClient` `PUT` method
     *
     * @param endpoint  The endpoint URL.
     * @param data      The data object
     * @param headers   The headers object
     *
     * @return An `Observable` of the full `HTTPResponse` for the request, with a response body in the requested type.
     */
    public put<T>(endpoint: string = null, data: { [key: string]: any } = {}, headers: { [key: string]: string } = {}): Observable<HttpResponse<T>> {
        if (endpoint === null) {
            throwError(new Error('E_NO_ENDPOINT'));
        }

        const url: string = environment.api.url + endpoint;

        return from(this.setDefaultAuthHeaders(headers)).pipe(
            switchMap((headersWithAuth: { [key: string]: string }) => {
                return this.httpClient.put<T>(url, data, {
                    headers: this.getHeaders(headersWithAuth),
                    observe: 'response',
                    responseType: 'json'
                });
            })
        );
    }

    getWithoutAuth<T>(endpoint: string) {
        if (endpoint === null) {
            throwError(new Error('E_NO_ENDPOINT'));
        }

        const url: string = environment.api.url + endpoint;

        return this.httpClient.get<T>(url, {
            observe: 'response',
            responseType: 'json'
        });
    }
}
