import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SearchInterface} from '../../../interfaces/search/search.interface';
import {NavigationOptions} from '@ionic/angular/common/providers/nav-controller';
import {Utils} from '../../../classes/Utils';
import {MediaQueryService} from '../../../services/media-query/media-query.service';
import {AlertController, ModalController, NavController} from '@ionic/angular';
import {Router} from '@angular/router';
import {UserService} from '../../../services/user/user.service';
import {ApiService} from '../../../services/api/api.service';
import {SearchesService} from '../../../services/searches/searches.service';
import {FavoritesService} from '../../../services/favorites/favorites.service';
import {ToastService} from '../../../services/toast/toast.service';
import {takeUntil} from 'rxjs/operators';
import {ErrorStatus} from '../../../classes/ErrorStatus.class';
import {Subject} from 'rxjs';
import {SearchCreationComponent} from '../search-creation/search-creation.component';
import {MatDialog} from '@angular/material/dialog';
import {ModalService} from "../../../services/modal/modal.service";
import {SnackbarService} from "../../../services/snackbar/snackbar.service";
import {SearchDataInterface} from '../../../interfaces/search/search-data.interface';
import {IconType} from '../../parts/fe-icon/fe-icon.component';
import {SearchSummaryComponent} from '../../modals/search/search-summary/search-summary.component';
import {SearchesInterface} from '../../../interfaces/searches/searches.interface';
import {GlobalService} from '../../../services/global/global.service';
import {AuthService} from '../../../services/auth/auth.service';

@Component({
  selector: 'manage-searches',
  templateUrl: './manage-searches.component.html',
  styleUrls: ['./manage-searches.component.scss'],
})
export class ManageSearchesComponent implements OnInit {

  @Input() public search: SearchInterface;
  @Input() public searchList: SearchInterface[];
  @Input() public currentIndex: number;
  @Input() public fromMenu: boolean = true;
  @Output() selectSearchEmitter = new EventEmitter();
  private unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
        public mediaQueryService: MediaQueryService
      , private navController: NavController
      , public mySearchesService: SearchesService
      , private snackbarService: SnackbarService
      , private globalService: GlobalService
      , private authService: AuthService
      , public dialog: MatDialog,
        private modalService: ModalService) { }

  ngOnInit() {}

  emitEventBusinessSignals(index: number) {
    this.mySearchesService.emitEventToBusinessSignals(index);
  }

  openBusinessSignals(search: number, index: number) {
      if (this.selectSearchEmitter !== null) {
          this.selectSearchEmitter.emit();
      } else {
          this.emitEventBusinessSignals(index);

          const params: NavigationOptions = {
              queryParams: {
                  search: search
              }
          };
          Utils.navigate('/app/business-signals', this.navController, params);
          // this.navController.navigateForward('/app/business-signals', params);
      }
      this.globalService.updateMenuLink('businessSignals');
  }

  public toggleNotifications($event): void {
    $event.stopPropagation();
    this.search.data.alertState = !this.search.data.alertState;

    this.mySearchesService.editMySearch(this.search.searchId, this.search.data).subscribe(
        (editedSearch: SearchInterface) => {
            let alertStateLabel: string = 'activées';
            if (editedSearch.data.alertState === false) {
                alertStateLabel = 'désactivées';
            }
            this.snackbarService.showSuccessSnackbar('Opération réussie',
                'Les alertes de nouveaux résultats sont ' + alertStateLabel + ' pour la recherche ' + this.search.data.label);
        }
    );
  }

  protected confirmRenameSearch(search: SearchInterface, currentIndex: number) {
      if (search.readOnly && !this.authService.roleCom) {
          this.snackbarService.showDangerSnackbar('Édition impossible', 'Cette recherche est en lecture ' +
              'seule et n’est pas éditable.');
      } else {
          const oldLib = search.data.label;
          this.modalService.inputModal('Renommage de la recherche', 'Indiquez un nouveau nom pour la ' +
              'recherche \'' + search.data.label + '\'.', '', '', oldLib,
              (value) => {
                  if (!value || value.length <= 0) {
                      this.snackbarService.showDangerSnackbar('Renommage de la recherche', 'Votre recherche doit avoir un nom pour pouvoir être renommée.');
                  }
                  else {
                      this.renameSearch(search, currentIndex, value);
                  }
          });
      }
  }

  renameSearch(search: SearchInterface, currentIndex: number, libelle: string) {
      const oldLib = search.data.label;
      search.data.label = libelle;
      this.mySearchesService.editMySearch(search.searchId, search.data).pipe(
          takeUntil(this.unsubscribe$)
      ).subscribe(
    // this.mySearchesService.renameSearchById(search.searchId, libelle).pipe(
    //     takeUntil(this.unsubscribe$)
    // ).subscribe(
        (renamedSearch) => {
          this.snackbarService.showSuccessSnackbar('Renommage réussi avec succès',
              'La recherche \'' + oldLib + '\' a bien été renommée en \'' + libelle + '\'.');
          // this.searchList[currentIndex] = renamedSearch;
        },
        (error: Error | ErrorStatus) => {
          let errorStr: string = 'La recherche \'' + search.data.label + '\' n\'a pas pu être renommée en \'' + libelle + '\'.';
          // if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
          //   errorStr += `(${this.translateService.instant(error.message)})`;
          // }
          this.snackbarService.showDangerSnackbar('Erreur lors du renommage', errorStr);
        }
    );
  }

  protected askBeforeDuplicateSearch(search: SearchInterface): void {
    this.modalService.confirmModal('Duplication de recherche',
        'Voulez-vous vraiment dupliquer la recherche \'' + search.data.label + '\' ?',
        args => {
          this.duplicateSearch(search);
        });
  }

  private duplicateSearch(search: SearchInterface): void {
      const news: SearchDataInterface = Utils.deepCopy(search.data);
      news.label = search.data.label + ' (copie)';
      news.duplicateFrom = true;
      this.mySearchesService.addMySearch(news).pipe(
          takeUntil(this.unsubscribe$)
      ).subscribe(
    // this.mySearchesService.duplicateSearchById(search.searchId).pipe(
    //     takeUntil(this.unsubscribe$)
    // ).subscribe(
        res => {
          // this.snackbarService.showSuccessSnackbar('Duplication réussie',
          //     'la recherche \'' + search.data.label + '\' a été dupliquée avec succès.');
          // const newSearch: SearchInterface = {
          //   readOnly: this.searchList[this.currentIndex].readOnly,
          //   data: this.searchList[this.currentIndex].data,
          //   dataRecentResults: this.searchList[this.currentIndex].dataRecentResults
          // };
          // newSearch.data.label += ' (copie)';
          // this.searchList.push(newSearch);
            this.getMySearches(false, res.body, true);
        },
        (error: Error | ErrorStatus) => {
          const errorStr: string = 'La duplication de la recherche \'' + search.data.label + '\' a échoué.';
          // if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
          //   errorStr += `(${this.translateService.instant(error.message)})`;
          // }
          this.snackbarService.showDangerSnackbar('Erreur lors de la duplication', errorStr);
        }
    );
  }

    public getMySearches(loadLastSearch?: boolean, searchId?: number, editSearch?: boolean): void {

        this.mySearchesService.getMySearches().subscribe(
            (mySearches: SearchesInterface) => {
                this.searchList = mySearches.searches;
                if (loadLastSearch) {
                    try {
                        this.mySearchesService.emitEventToBusinessSignals(mySearches.searches[mySearches.searches.length - 1].searchId);
                    } catch (e) {
                        this.mySearchesService.emitEventToBusinessSignals(-1);
                    }
                }
                else if (searchId) {
                    this.mySearchesService.emitEventToBusinessSignals(searchId);
                }

                if (editSearch && searchId) {
                    for (let search of this.searchList) {
                        if (search.searchId === searchId) {
                            this.editSearch(search, 0);
                        }
                    }
                }

                this.mySearchesService.mySearchList$.subscribe(value => {
                    this.searchList = value;
                });
            }
        );
    }

  protected askBeforeRemoveSearch(search: SearchInterface, currentIndex: number): void {
    this.modalService.confirmModal('Suppression de recherche',
        'Voulez-vous vraiment supprimer la recherche \'' + search.data.label + '\' ?',
            args => {
      this.removeSearch(search, currentIndex);
    });
  }


  private removeSearch(search: SearchInterface, currentIndex: number): void {
      if (search.readOnly && !this.authService.roleCom) {
          this.snackbarService.showDangerSnackbar('Suppression impossible', 'Cette recherche est ' +
              'en lecture seule et ne peut pas être supprimée.');
      } else {
          this.mySearchesService.removeSearchById(search.searchId).pipe(
              takeUntil(this.unsubscribe$)
          ).subscribe(
              () => {
                  this.snackbarService.showSuccessSnackbar('Suppression réussie',
                      'La recherche \'' + search.data.label + '\' a été supprimée avec succès.');
                  // this.searchList.splice(currentIndex, 1);
                  this.getMySearches(true);
                  // this.mySearchesService.getMySearches().subscribe((mySearches: SearchesInterface) => {
                  //     this.mySearchesService.emitEventToBusinessSignals(search.searchId);
                  // });
              },
              (error: Error | ErrorStatus) => {
                  const errorStr: string = 'Une erreur est survenue lors de la suppression de la recherche \'' +
                      search.data.label + '\'.';
                  // if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
                  //   errorStr += `(${this.translateService.instant(error.message)})`;
                  // }
                  this.snackbarService.showDangerSnackbar('Erreur lors de la suppression', errorStr);
              }
          );
      }
  }

  async editSearch(search: SearchInterface, currentIndex: number) {
      if (search.readOnly && !this.authService.roleCom) {
          this.snackbarService.showDangerSnackbar('Édition impossible', 'Cette recherche est en lecture ' +
              'seule et n’est pas éditable.');
      } else {
          const dialogRef = this.dialog.open(SearchCreationComponent,
              {disableClose: true, height: 'calc(100% - 73px)', width: '100%', maxWidth: '100%',
                  position: {bottom: '0', top: '73px', right: '0', left: '0'},
              data: {search: search},
              hasBackdrop: true});
          // const modal = await this.modalController.create({
          //   component: SearchCreationComponent,
          //   cssClass: 'searchModal',
          //   showBackdrop: false,
          //   componentProps: {
          //     search: search
          //   },
          //   // backdropDismiss: true
          // });
          // modal.onDidDismiss()
          //     .then(
          //         (data: any) => {
          //
          //         }
          //     );
          // return await modal.present();
          dialogRef.beforeClosed().subscribe(value => {
              if (value) {
                  this.mySearchesService.getMySearches().subscribe((mySearches: SearchesInterface) => {
                      this.mySearchesService.emitEventToBusinessSignals(search.searchId);
                  });
              }
          });
      }
  }

    protected readonly IconType = IconType;

    async openCriterions(search: SearchInterface, currentIndex: number) {
        this.dialog.open(SearchSummaryComponent, {height: '570px', width: '600px', maxWidth: '100%',
            data: {search: search},
            hasBackdrop: false});
        // const modal = await this.modalController.create({
        //     component: SearchSummaryComponent,
        //     cssClass: 'addMonitoredArticleDialog',
        //     componentProps: {
        //         search: search
        //     }
        // });
        // return modal.present();
    }
}
