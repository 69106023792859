import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, Router } from '@angular/router';
import { Platform } from '@ionic/angular';

const routes: Routes = [
  { path: 'app', loadChildren: () => import('./components/layout/layout.module').then(m => m.LayoutComponentModule) },
  { path: 'login', loadChildren: () => import('./components/login/login.module').then(m => m.LoginComponentModule)},
  { path: 'error', loadChildren: () => import('./components/login/login-error/login-error.module').then(m => m.LoginErrorComponentModule)},
  { path: 'choice', loadChildren: () => import('./components/layout/mobile-choice/mobile-choice.module').then(m => m.MobileChoiceModule)},
  { path: 'noProduct', loadChildren: () => import('./components/client-token-not-found/client-token-not-found.module').then(m => m.ClientTokenNotFoundComponentModule)},
  { path: 'entreprise', loadChildren: () => import('./components/entreprise/entreprise.module').then(m => m.EntrepriseComponentModule)},
  { path: 'external-article', loadChildren: () => import('./components/article/external-article-access/external-article-access.module').then(m => m.ExternalArticleAccessComponentModule)},
  // {path: '**', redirectTo: 'app'}

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {

  constructor(
    private platform: Platform
    , private router: Router
  ) {

    // on ajoute la route par defaut uniquement pour les desktop.
    // on ne la met pas directement dans le tableau routes parce que sur mobile,
    // cette route par defaut sera chargée avant que le storage soit actif.
    // Du coup, on ne récuperera pas les data dans le storage (identifier and password) et on ne relancera pas la session active...
    // if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
    //   routes.push({ path: '', redirectTo: 'app', pathMatch: 'full' });
    //   this.router.resetConfig(routes);
    // }
  }
}
