import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {EntrepriseInterface} from '../../../../interfaces/entreprise/entreprise.interface';

@Component({
  selector: 'app-fiche-entreprise-stats',
  templateUrl: './fiche-entreprise-stats.component.html',
  styleUrls: ['./fiche-entreprise-stats.component.scss'],
})
export class FicheEntrepriseStatsComponent implements OnInit {

  view: any[] = [650, 350];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Année';
  showYAxisLabel = true;
  yAxisLabel = 'Valeur';

  colorScheme = {
    domain: ['#124457']
  };

  dataEff = undefined;
  dataSales = undefined;
  dataProfits = undefined;

  private entreprise: EntrepriseInterface;
  constructor(private modalController: ModalController, private navParams: NavParams) {
    this.entreprise = this.navParams.get('entreprise');
    const self = this;
    const currentYear = new Date().getFullYear();
    let salesAvailable = false;
    let netAvailable = false;
    let effAvailable = false;
    this.entreprise.financials?.forEach(value =>  {
      if (value.annualSalesNumber) {
        if (self.dataSales === undefined) {
          self.dataSales = [];
        }
        self.dataSales.push({value: value.annualSalesNumber, name: value.year});
        if (value.year > currentYear - 4) {
          salesAvailable = true;
        }
      }
      if (value.netProfilNumber) {
        if (self.dataProfits === undefined) {
          self.dataProfits = [];
        }
        self.dataProfits.push({value: value.netProfilNumber, name: value.year});
        if (value.year > currentYear - 4) {
          netAvailable = true;
        }
      }
      if (value.effectif) {
        if (self.dataEff === undefined) {
          self.dataEff = [];
        }
        self.dataEff.push({value: value.effectif, name: value.year});
        if (value.year > currentYear - 4) {
          effAvailable = true;
        }
      }
    });
    let count = 0;
    if (this.dataEff && effAvailable) {
      count++;
    } else {
      self.dataEff = undefined;
    }
    if (this.dataProfits && netAvailable) {
      count++;
    } else {
      self.dataProfits = undefined;
    }
    if (this.dataSales && salesAvailable) {
      count++;
    } else {
      self.dataSales = undefined;
    }
    this.view = [(1300 / count), 350];
  }

  ngOnInit() {}

  public close(): void {
    this.modalController.dismiss();
  }
}
