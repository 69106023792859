import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {first} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {

  private currentHash;

  constructor(private http: HttpClient) { }

  public initVersionCheck(frequency = 1000 * 60 * 30) {
    // get currentHash
    setInterval(() => {
      this.checkVersion(location.origin + '/hash.txt');
    }, frequency);
  }

  private checkVersion(url: string) {
    this.http.get(url + '?t=' + new Date().getTime()).pipe(first())
      .subscribe(
        (response: any) => {
          const hash = response.hash;
          if (this.currentHash !== undefined) {
            if (hash !== this.currentHash) {
              location.reload();
            }
          }
          // store the new hash so we wouldn't trigger versionChange again
          // only necessary in case you did not force refresh
          // console.log(hash);
          this.currentHash = hash;
        },
        (err) => {
          // console.error(err, 'Could not get version');
        }
    );
  }
}
