import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EntrepriseInterface} from '../../interfaces/entreprise/entreprise.interface';
import {ArticleInterface} from '../../interfaces/articles/article.interface';
import * as moment from 'moment';
import {TagInterface} from '../../interfaces/tag/tag';
import {ModalService} from '../../services/modal/modal.service';
import {FeArticleModalComponent} from '../modals/fe-article-modal/fe-article-modal.component';
import {FeMailModalComponent} from '../modals/fe-mail-modal/fe-mail-modal.component';
import {FeGestionRecherchesModalComponent} from '../modals/fe-gestion-recherches-modal/fe-gestion-recherches-modal.component';
import {AddFavoriteModalComponent} from '../modals/add-favorite-modal/add-favorite-modal.component';
import {FavoritesService} from '../../services/favorites/favorites.service';
import {AlertController, ModalController, Platform} from '@ionic/angular';
import {SnackbarService} from '../../services/snackbar/snackbar.service';
import {SupervisorMonitoringModalComponent} from '../supervisor-monitoring-modal/supervisor-monitoring-modal.component';
import {SessionInterface} from '../../interfaces/session/session.interface';
import {UserService} from '../../services/user/user.service';
import {ArticleMonitoringService} from '../../services/article-monitoring/article-monitoring.service';
import {SelectionModel} from '@angular/cdk/collections';
import {ArticlesService} from '../../services/articles/articles.service';
import {Utils} from '../../classes/Utils';
import {DomSanitizer} from '@angular/platform-browser';
import {ColorType, IconType} from '../parts/fe-icon/fe-icon.component';
import {FavorisFolder} from "../../interfaces/favoritesFolders/favorites-folders.interface";
import {Observable} from "rxjs";
import {AddressesInterface} from "../../interfaces/addresses/addresses";
import {AddressesService} from "../../services/addresses/addresses.service";
import {ContactLineInterface, FeCarnetAdressesModalComponent} from '../modals/fe-carnet-adresses-modal/fe-carnet-adresses-modal.component';
import {map, startWith} from "rxjs/operators";
import {AddressInterface} from "../../interfaces/address/address";
import {PrintInterface} from '../../interfaces/print/print.interface';
import {Printer, PrintOptions} from '@awesome-cordova-plugins/printer/ngx';

@Component({
  selector: 'fe-article-item',
  templateUrl: './fe-article-item.component.html',
  styleUrls: ['./fe-article-item.component.scss']
})
export class FeArticleItemComponent implements OnInit {
  @Input({required: true}) article: ArticleInterface;
  @Input() public selectionModel: SelectionModel<number>;
  @Input() public style: string; // big, medium or small
  @Input() public _keywords: Array<string>;
  @Input() public hasSectorsInSearch: boolean;
  @Input() public hasTopicsInSearch: boolean;
  @Input() public hasLeadsInSearch: boolean = false;
  @Input() public fromFavorite: boolean = false;
  @Output() onRemoveFavorite = new EventEmitter();
  @Output() onChangeFavorite = new EventEmitter();
  public isSupervisor: boolean;
  public isPerformance: boolean;
  private nbCollaborateurs: number;
  public listAssociatedCustomers: {user_id: string, name: string}[] = [];
  public shareArticleToolTip: String;
  protected myFavoritesList: FavorisFolder[] = [];
  protected addresses: Observable<AddressesInterface>;
  protected contactsLines: ContactLineInterface[] = [];

  constructor(private _modalService: ModalService,
              private favoritesService: FavoritesService,
              private modalController: ModalController,
              private snackBarService: SnackbarService,
              private userService: UserService,
              private platform: Platform,
              private printer: Printer,
              private alertController: AlertController,
              private articleMonitoringService: ArticleMonitoringService,
              private articlesService: ArticlesService,
              private addressesService: AddressesService,
              private elem: ElementRef,
              public sanitizer: DomSanitizer) {
    // Retrieve Supervisor state
    this.userService.getSessionDatas()
        .subscribe(
            (sessionData: SessionInterface) => {
              if (sessionData !== undefined) {
                if (sessionData.customerDetails.isSupervisor !== null) {
                  this.isSupervisor = sessionData.customerDetails.isSupervisor;
                }
                if (sessionData.productOfferCode === 'OFFRE_3') {
                  this.isPerformance = true;
                }
                if (sessionData.companyCustomers !== undefined) {
                  this.nbCollaborateurs = sessionData.companyCustomers.length;
                }
              }
            }
        );

    this.favoritesService.getFavoritesFolders().subscribe((data: Array<FavorisFolder>) => {
      if (data != null) {
        this.myFavoritesList = data;
      }
    });

    addressesService.onAddressesChange().subscribe(value => {
      this.loadAddresses();
    });
  }

  private loadAddresses(): void {
    this.contactsLines = [];
    this.addressesService.getAddresses().subscribe(
        (res: AddressesInterface) => {
          if (res && res.address) {
            this.contactsLines = [];
            res.address.forEach(value => {
              this.contactsLines.push({
                id: value.addressId,
                checked: false,
                label: value.data.label,
                email: value.data.email
              });
            });
          }
        });
  }

  public ngOnInit(): void {
    if (this.article.monitoring.associatedCustomersRealName !== undefined) {
      for (let i = 0; i < this.article.monitoring.associatedCustomersRealName.length; i++) {
        this.listAssociatedCustomers.push({user_id: this.article.monitoring.associatedCustomers[i], name: this.article.monitoring.associatedCustomersRealName[i]});
      }
    }
    if (this.listAssociatedCustomers.length > 0) {
      if (this.article.monitoring.status === undefined) {
        this.shareArticleToolTip = 'Article suivi par :\n';
        for (let i = 0; i < this.listAssociatedCustomers.length; i++) {
          this.shareArticleToolTip += this.listAssociatedCustomers[i].name + ', ';
        }
        this.shareArticleToolTip = this.shareArticleToolTip.slice(0, -2);
      } else {
        this.shareArticleToolTip = 'Article également suivi par :\n';
        for (let i = 0; i < this.listAssociatedCustomers.length; i++) {
          this.shareArticleToolTip += this.listAssociatedCustomers[i].name + ', ';
        }
        this.shareArticleToolTip = this.shareArticleToolTip.slice(0, -2);
      }
    }
  }

  public test() {
    const dynamicHtml = '<p>This is <a href="#" (click)="handleAnchorClick($event)">dynamic HTML</a>.</p>';
    return this.sanitizer.bypassSecurityTrustHtml(dynamicHtml);
  }

  handleAnchorClick(event: Event): void {
    // Prevent the default behavior of the anchor tag
    event.preventDefault();


    // Extract the target element that was clicked
    const targetElement = event.target as HTMLElement;

    // Check if the clicked element is an anchor tag
    if (targetElement.tagName.toLowerCase() === 'a') {
      event.stopPropagation();
      this.openReferenceArticleModal(Number(targetElement.id));
    }
  }

  public getArticleCorps() {
    let pattern;
    let tag;
    let articleCorps = this.article.corps;

    for (let i = 0; i < this.article.referenceArticlesInfos?.length; i++) {
      pattern = '##' + this.article.referenceArticlesInfos[i].articleId + '##';
      tag = '<span> <a id="' + this.article.referenceArticlesInfos[i].articleId + '" class="ref-link">' + this.article.referenceArticlesInfos[i].text + '</a></span>';
      articleCorps = articleCorps.replace(pattern, tag);
    }
    let result = '';
    if (this.style === 'all') {
      result = this.getHighlightedText(articleCorps);
    } else {
      result = articleCorps.length > 200 ? this.getHighlightedText(articleCorps).slice(0, 200) + '...' : this.getHighlightedText(articleCorps);
    }

    return this.sanitizer.bypassSecurityTrustHtml(result);
  }

  /**
   * Ouverture article de référence
   * @param referenceArticleId
   */
  public async openReferenceArticleModal(referenceArticleId: number) {
    const Query = {
      articles: referenceArticleId
    };
    let article;
    this.articlesService.getArticlesContent(Query).subscribe(
        (res) => {
          article = res[0];
          this.openArticleModal(article);
        }
    );
  }

  public getHighlightedText(text: string): string {
    try {
      return Utils.getHighlightedText(text, this._keywords);
    } catch (e) {
      return text;
    }
  }

  protected getDate(): string {
    return (moment(this.article.publicationDate)).format('DD/MM/YYYY');
  }

  protected getArticleTags(): TagInterface[] {
    const allTags: TagInterface[] = [];
    allTags.push(...this.article.tags.locations);
    allTags.push(...this.article.tags.sectors);
    allTags.push(...this.article.tags.topics);
    return allTags;
  }

  openArticleModal(article) {
    // this._modalService.showCustomModal(FeArticleModalComponent, {});
    this._modalService.showCustomModal(FeArticleModalComponent, {
      data: {
        article: article,
        keywords: this._keywords,
        fromFavorite: this.fromFavorite
      }});
    this.article.read = true;
  }

  protected markArticleRead(read: boolean): void {
    this.article.read = read;
    this.articlesService.markArticleAsRead(this.article.id, read)
        .pipe()
        .subscribe(
            res => {
              this.articlesService.updateReadArticle(this.article.id, read);
            }
        );
  }

  showSendArticleModal() {
    this._modalService.showCustomModalWithActionOnClose(FeMailModalComponent, {data: {
      articles: [this.article]
      }}, (response: Array<string>) => {
      response.forEach(value => {
        if (this.article.sharedTo == null)
          this.article.sharedTo = [];
        if (this.article.sharedTo.indexOf(value) < 0)
          this.article.sharedTo.push(value);
      });
    });
  }

  protected addArticleToFavorite(): void {
    this._modalService.showCustomModalWithActionOnClose(AddFavoriteModalComponent, {data: {articles: [this.article.id],
        title: this.article.title}}, (response) => {
      if (response != null) {
        if (response.isAdd) {
          this.article.favorite = true;
          this.article.favoriteFolder = response.folderID;
          this.snackBarService.showSuccessSnackbar('Favori ajouté avec succès',
              'L\'article <b>\'' + this.troncateText(this.article.title, 50)
              + '\'</b> a bien été ajouté au dossier \'' + this.getFavoriteFolderLink(response.folderID, response.libelle) + '\'.');
          this.favoritesService.needRefresh.next(true);
        }
      }
    });
  }

  private getFavoriteFolderLink(folderId: number, folderLabel: string): string {
    const favoriFolder: FavorisFolder = this.getFavoriById(folderId);
    return '<b><a class="greenC" href="/#/app/my-favorites?folderID='
        + favoriFolder.folderID + '">' + this.troncateText(folderLabel, 40) + '</a></>';
  }

  private showAddToMonitoringSuccess() {
    this.snackBarService.showSuccessSnackbar('Ajout au suivi d\'affaires',
        'L\'article <b>\'' + this.troncateText(this.article.title, 50)
        + '\'</b> a bien été ajouté au suivi d\'affaires.');
  }

  async addToSuiviAffaires() {
    // Cas non superviseur
    if (!this.isSupervisor) {
      if (this.article.monitoring.status === undefined) {
        this._modalService.confirmModal('Ajout au suivi d\'affaires', 'Confirmez-vous l\'ajout de l\'article <b>\'' + this.troncateText(this.article.title, 50) + '\'</b> à votre suivi d\'affaires ?', args => {
          this.monitorArticle(this.article);
        });
      }
      else {
        this.snackBarService.showDangerSnackbar('Ajout au suivi d\'affaires', 'L\'article <b>\'' + this.troncateText(this.article.title, 50) + '\'</b> est déjà dans votre suivi d\'affaires.');
      }
    } else { /*cas superviseur*/
        this._modalService.showCustomModalWithActionOnClose(SupervisorMonitoringModalComponent,
            { data: {
                article: this.article,
                isSupervisor: true,
                isFromMonitoringView: false,
                listAssociatedCustomers: this.listAssociatedCustomers
              }},
            (data) => {
              this.showAddToMonitoringSuccess();
              if (data !== undefined) {
                if (data.selfSelected) {
                  this.article.monitoring.status = 'to_process';
                }
                for (let i = 0; i < data.customersSelected.length; i++) {
                  const isInArray = this.listAssociatedCustomers.findIndex(obj => obj.user_id === data.customersSelected[i].user_id);
                  if (isInArray === -1) {
                    this.listAssociatedCustomers.push({user_id: data.customersSelected[i].user_id, name: data.customersSelected[i].name});
                    if (this.shareArticleToolTip === undefined) {
                      this.shareArticleToolTip = 'Article suivi par :\n';
                      this.shareArticleToolTip += data.customersSelected[i].name + ' \n';
                    } else {
                      this.shareArticleToolTip += data.customersSelected[i].name + ' \n';
                    }
                  }
                }
              }
            }
        );

    }
  }
  public monitorArticle(article: ArticleInterface) {
    const articleID = article.id;
    const customers = '';
    this.articleMonitoringService.addMonitoringArticles(articleID, true, customers, false, '', '').subscribe(next => {
      this.article.monitoring.status = 'to_process';
      this.showAddToMonitoringSuccess();
    });
  }

  public deleteFavorite(selectionModel: any) {
    this._modalService.confirmModal('Retrait du favori', 'Confirmez vous le retrait de l\'article <b>\''
        + this.troncateText(this.article.title, 50) + '\'</b> du dossier <b>\'' +
        this.getFavoriById(this.article.favoriteFolder).libelle + '\'</b> ?', () => {
      this.favoritesService.removeArticleFromFavorites(selectionModel).pipe().subscribe(
          () => {
            this.snackBarService.showSuccessSnackbar('Favori retiré avec succès', 'L\'article <b>\''
                + this.troncateText(this.article.title, 50) + '\'</b> a été retiré du dossier \''
                + this.getFavoriteFolderLink(this.article.favoriteFolder, this.getFavoriById(this.article.favoriteFolder).libelle) + '\' avec succès.');
            this.article.favorite = false;
            this.article.favoriteFolder = undefined;
            this.favoritesService.needRefresh.next(true);
            this.onRemoveFavorite.emit();
          },
          (error: Error) => {
            this.snackBarService.showDangerSnackbar('Erreur', 'Une erreur s\'est produite lors de la suppression du favoris');
          }
      );
    });
  }

  private getFavoriById(folderId: number): FavorisFolder {
    let favoriToReturn: FavorisFolder = null;
    this.myFavoritesList.forEach(value => {
      if (value.folderID === folderId) {
        favoriToReturn = value;
      }
    });
    return favoriToReturn;
  }

  private getFavoriteFolder(folderId: number): FavorisFolder {
    let favoriFodler: FavorisFolder = null;
    this.myFavoritesList.forEach(value => {
      if (value.folderID === folderId) {
        favoriFodler = value;
      }
    });
    return favoriFodler;
  }

  tagShowing(tag: TagInterface) {
    if (this.article.tags.sectors.includes(tag) && this.hasSectorsInSearch && !tag.matchSearch) {
      return false;
    }
    if (this.article.tags.topics.includes(tag) && this.hasTopicsInSearch && !tag.matchSearch) {
      return false;
    }
    return true;
  }

    protected readonly IconType = IconType;
    protected readonly ColorType = ColorType;

  protected changeFavoriteFolder(favorite: FavorisFolder, selectionModel: any): void {
    this.favoritesService.moveArticlesInFavoritesToOtherFolder(favorite.folderID, selectionModel).subscribe(value => {
      this.favoritesService.needRefresh.next(true);
      this.selectionModel.clear();
      this.article.favoriteFolder = favorite.folderID;
      this.snackBarService.showSuccessSnackbar('Déplacement réussi',
          'L\'article <b>\'' + this.troncateText(this.article.title, 50) + '\'</b> a été ' +
          'déplacé dans le dossier \'' + this.getFavoriteFolderLink(favorite.folderID, favorite.libelle) + '\' avec succès.');
      this.onChangeFavorite.emit(favorite.folderID);
    });
  }

  private troncateText(text: string, length: number): string {
    let troncateText: string = text;
    if (troncateText.length > length + 2) {
      troncateText = troncateText.substring(0, length) + '...';
    }
    return troncateText;
  }

  protected getContactLibelle(contactLine: ContactLineInterface) {
    let contactLibelle = '<i>'+contactLine.email+'</i>';
    if(contactLine.label !== undefined && contactLine.label !== '') {
      contactLibelle = '<b>'+contactLine.label+'</b> - ' + contactLibelle
    }
    return contactLibelle;
  }

  protected sendFastMail(contactLine: ContactLineInterface, id: number): void {
    this.articlesService.emailArticleById(contactLine.id, this.article.id).subscribe(next => {
      this.snackBarService.showSuccessSnackbar('Envoi réussi', 'L\'article <b>\''
          + this.troncateText(this.article.title, 50) + '\'</b> a bien été envoyé à <b>\'' +
          contactLine.email + '\'</b> avec succès.');
      let shared = this.article.sharedTo;
      if (shared === undefined) {
        shared = [];
      }
      shared.push(contactLine.email);
      this.article.sharedTo = shared;
    });
    //  Sauvegarde de l'action dans le log
    this.userService.log('ARTICLE_MAIL_ONECLICK', [this.article]);
  }

  protected openCarnetAddress(): void {
    this._modalService.showCustomModal(FeCarnetAdressesModalComponent, {});
  }

  printArticles() {
    this.articlesService.printArticlesList([this.article.id]).pipe().subscribe(
        (res: PrintInterface) => {
          // Mobile printing
          if (this.platform.is('ios') || this.platform.is('android')) {
            this.printer.isAvailable()
                .then(onSuccess => {
                    },
                    onError => {
                    }
                );

            const options: PrintOptions = {
              name: 'FirstECO_print',
              orientation: 'portrait'
            };

            this.printer.print(res.html, options)
                .then(onSuccess => {
                    },
                    onError => {
                    }
                );
          }
          // Desktop printing
          else {
            const popupWin = window.open('', '_blank');
            popupWin.document.open();
            popupWin.document.write(res.html);
            setTimeout(() => popupWin.print(), 1000);
          }
        });
  }
}
