<button mat-icon-button class="disabledWhite" [disabled]="selectionModel.selected.length === 0 || _disabled" [matMenuTriggerFor]="menu">
  <mat-icon>cloud_upload</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="exportArticlesList('excel', selectionModel.selected)" [disabled]="warnMessage !== undefined && selectionModel.selected.length > 10">
    Export EXCEL
  </button>
  <button mat-menu-item (click)="exportArticlesList('csv', selectionModel.selected)" [disabled]="warnMessage !== undefined && selectionModel.selected.length > 10">
    Export CSV
  </button>
  <button class="subscription-info" mat-menu-item disabled *ngIf="warnMessage !== undefined && selectionModel.selected.length > 10">
    <mat-icon class="outlined">new_releases</mat-icon>
    <span>{{warnMessage}}</span>
  </button>
</mat-menu>
