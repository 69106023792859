<ion-header no-border>
  <ion-toolbar>
    <div class="summaryTitle">{{communicationTitle}}</div>
    <ion-buttons slot="end">
      <button mat-icon-button aria-label="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div [innerHTML]="communicationText" style="height: calc(100% - 100px);"></div>
  <div class="buttonContainer" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
    <fe-button *ngIf="!hideButton" style="margin: auto" text="Ne plus recevoir cette annonce"
               type="SECONDARY" smallButton="true" bold="true" (click)="decline()"/>
  </div>
</ion-content>
