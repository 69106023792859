import { FormControl } from '@angular/forms';

export class EmailValidator {

  /**
   * [isValidEmail description]
   * @param  {FormControl} formControl [description]
   * @return {[type]}                  [description]
   */
  public static isValidEmail(formControl: FormControl) {


    // field not required but email validation => if value = '' and not required error => no error
    if (formControl.value === '') {
      if (typeof formControl.errors !== 'undefined' && formControl.errors && formControl.errors.invalidEmail &&
        (typeof formControl.errors.required === 'undefined' || formControl.errors.required === null || !formControl.errors.required)) {
        return null;
      }
    } else {
      const emailRegexp: any = /[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
      if (emailRegexp.test(formControl.value.toLowerCase())) {
        return null;
      } else {
        return { invalidEmail: true };
      }
    }

  }
}
