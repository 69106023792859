export class ErrorStatus extends Error {

  public status: number;

  constructor(status: number) {
    super();
    this.status = status;
    if (status === 401) {
      this.message = 'E_UNAUTHORIZED_ERROR';
    } else if (status === 403) {
      this.message = 'E_FORBIDDEN_ERROR';
    } else if (status === 404) {
      this.message = 'E_NOT_FOUND_ERROR';
    } else {
      this.message = 'E_UNKNOWN_ERROR';
    }
  }

}
