import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { SourcesInterface } from 'src/app/interfaces/sources/sources.interface';
import {SourceInterface} from '../../../interfaces/source/source.interface';
import {UserService} from '../../../services/user/user.service';
import {ArticleInterface} from '../../../interfaces/articles/article.interface';

@Component({
  selector: 'article-sources',
  templateUrl: 'article-sources.component.html',
  styleUrls: ['article-sources.component.scss'],
})
export class ArticleSourcesComponent implements OnInit, OnDestroy {

  @Input() public article: ArticleInterface;

  private unsubscribe$: Subject<any> = new Subject<any>();

  constructor(private userService: UserService
  ) {}

  public ngOnInit(): void {
  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  logSourceClick(source: SourceInterface, $event: MouseEvent) {
    $event.stopPropagation();
    this.userService.log('CLICK_SOURCE', [this.article]);
  }
}
