import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ColorType, IconType} from '../../parts/fe-icon/fe-icon.component';

@Component({
  selector: 'fe-menu-item',
  templateUrl: './fe-menu-item.component.html',
  styleUrls: ['./fe-menu-item.component.scss']
})
export class FeMenuItemComponent {
  @Input() icon: IconType = null;
  @Input() iconColor: ColorType = ColorType.blue;
  @Input() text: string = 'Nom du bouton';
  @Input() description: string = '';
  @Input() color: string = 'DEFAULT';
  @Input() bold: boolean = true;
  @Output() onClick = new EventEmitter();

  constructor() {
    switch (this.color) {
      case 'PRIMARY':
        this.iconColor = ColorType.blue;
        break;
      case 'DANGER':
        this.iconColor = ColorType.red;
        break;
      default:
        this.iconColor = ColorType.lightBlack;
        break;
    }
  }

  ngOnInit() {
    switch (this.color) {
      case 'PRIMARY':
        this.iconColor = ColorType.blue;
        break;
      case 'DANGER':
        this.iconColor = ColorType.red;
        break;
      default:
        this.iconColor = ColorType.lightBlack;
        break;
    }
  }

  protected readonly ColorType = ColorType;
}
