import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {Subject} from 'rxjs';
import {AddFavoriteModalComponent} from '../../modals/add-favorite-modal/add-favorite-modal.component';
import {FavoritesService} from '../../../services/favorites/favorites.service';
import {ToastService} from '../../../services/toast/toast.service';
import {AlertController, ModalController} from '@ionic/angular';
import {MediaQueryService} from '../../../services/media-query/media-query.service';
import {FavorisFolder} from '../../../interfaces/favoritesFolders/favorites-folders.interface';

@Component({
  selector: 'app-article-add-favorite-button',
  templateUrl: './article-add-favorite-button.component.html',
  styleUrls: ['./article-add-favorite-button.component.scss'],
})
export class ArticleAddFavoriteButtonComponent implements OnInit, OnDestroy {
  @Input() public selectionModel: SelectionModel<string>;
  @Input() public isFromActionList: boolean;
  @Output() public multipleFavoris = new EventEmitter<void>();

  private unsubscribe$: Subject<any> = new Subject<any>();
  public _favoriteFolders: Array<FavorisFolder>;

  constructor(
      private favoritesService: FavoritesService
      , private toastService: ToastService
      , private alertController: AlertController
      , private modalController: ModalController
      , public mediaQueryService: MediaQueryService
      , private ren: Renderer2
      , public favoriteService: FavoritesService
  ) {}

  ngOnInit() {
    this.favoriteService.getFavoritesFolders().subscribe((data: Array<FavorisFolder>) => {
      this._favoriteFolders = data;
    });
  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public async openAddFavoriteModal() {
    const modal = await this.modalController.create({
      component: AddFavoriteModalComponent,
      cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'addFavoriteDialog',
      componentProps: {
        articles: this.selectionModel.selected
      }
    });
    modal.onWillDismiss().then(
        (data: any) => {
          if (data.data.isAdd) {
            // this.toastService.simple(this.translateService.instant('ADD_ARTICLE_TO_FAVORITE_SUCCESS'));
            this.toastService.clickableLink('Favori ajouté avec succès', data.data.libelle,  data.data.folderID, { color: 'toastinfo', });
            this.favoritesService.needRefresh.next(true);
          }
          this.multipleFavoris.emit();
        }
    );
    return await modal.present();
  }

  public addFavorite(): void {
    this.openAddFavoriteModal();
  }

}
