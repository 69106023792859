<ion-header no-border class="allContactHeader">
    <div class="ficEntHeaderBtnBar" style="flex-grow: 1; display: flex;">
        <!--        <span style="margin: auto 5px auto 10px; font-size: 20px; color: #254354; word-break: break-word; hyphens: auto;">{{entreprise.name}} - Contacts</span>     -->
        <span class>Contacts de l’entreprise {{entrepriseName}}</span>

    </div>

    <button class="allContactCloseBtn" aria-label="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</ion-header>

<ion-content>
    <div style="width: 1200px">
    <mat-accordion class="example-headers-align" style="padding: 20px 20px 40px; display: flex; overflow-y: auto; flex-direction: column;" multi>
        <mat-expansion-panel expanded="true" style="border-radius: 0; display: table" class="contactAccodrion"
                             *ngIf="articleContactsDS.data.length > 0">
            <mat-expansion-panel-header class="contactAccodrionHeader">
                <mat-panel-title>
                    <span>Contacts de l'article</span>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <table mat-table *ngIf="articleContactsDS.data.length > 0 else noArticleContacts"
                   [dataSource]="articleContactsDS" class="mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                <!-- role Column -->
                <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef style="flex: 3;">Nom et fonctions</th>
                    <td mat-cell *matCellDef="let articleContact" style="padding-right:24px; padding-left: 24px;">
                        {{articleContact.firstName}} {{articleContact.lastName}} - {{articleContact.function}}
                    </td>
                </ng-container>

                <!-- nom Column -->
                <ng-container matColumnDef="mail">
                    <th style="width: 260px;" mat-header-cell *matHeaderCellDef>Mail</th>
                    <td style="width: 260px; max-width: 260px; overflow: hidden;" mat-cell
                        *matCellDef="let articleContact">
                        <a href="mailto:{{articleContact.mail}}" *ngIf="articleContact.mail"
                           title="{{articleContact.mail}}">
                            {{articleContact.mail}}
                        </a>
                    </td>
                </ng-container>

                <!-- nom Column -->
                <ng-container matColumnDef="telephone">
                    <th style="width: 260px;" mat-header-cell *matHeaderCellDef>Téléphone</th>
                    <td style="width: 260px; max-width: 260px; overflow: hidden;" mat-cell
                        *matCellDef="let articleContact">
                        <a href="tel:{{articleContact.phone}}" *ngIf="articleContact.phone"
                           title="{{articleContact.phone}}">
                            {{articleContact.phone}}
                        </a>
                    </td>
                </ng-container>

                <!-- nom Column -->
                <ng-container matColumnDef="reseaux">
                    <th style="width: 150px;" mat-header-cell *matHeaderCellDef>Réseaux</th>
                    <td style="width: 150px; max-width: 150px; overflow: hidden;" mat-cell
                        *matCellDef="let articleContact">
                        <button *ngIf="articleContact.linkedin"
                                class="allContactSNButton"
                                (click)="openUrl(articleContact.linkedin, 'RS')">
                            <img style="width: 20px; height: 20px;" src="./assets/icon/linkedin2.png">
                        </button>
                        <button style="margin-left: 5px;" *ngIf="articleContact.twitter"
                                class="allContactSNButton"
                                (click)="openUrl(articleContact.twitter, 'RS')">
                            <img style="width: 20px; height: 16px; margin-bottom: 2px;"
                                 src="./assets/icon/twitter-bird.png">
                        </button>
                        <button style="margin-left: 5px;" *ngIf="articleContact.facebook"
                                class="allContactSNButton"
                                (click)="openUrl(articleContact.facebook, 'RS')">
                            <img style="width: 20px; height: 20px;" src="./assets/icon/facebook-icon.png">
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="articleContactsDC"></tr>
                <tr mat-row *matRowDef="let row; columns: articleContactsDC;"></tr>
            </table>
            <ng-template #noArticleContacts>
                <div style="padding: 35px; font-size: 20px; text-align: center; color: #124457 !important;">
                    Aucun contact pour l'article
                </div>
            </ng-template>
        </mat-expansion-panel>


        <mat-expansion-panel expanded="true" style="border-radius: 0" class="allContactAccordion allContactContainer">
            <mat-expansion-panel-header class="contactAccodrionHeader">
                <mat-panel-title>
                    <span>Rechercher un contact</span>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="feSearchFieldsContainer">
                <input class="searchContactInput" (keyup)="applyContactFilter($event)"
                       placeholder="Rechercher une fonction...">
            </div>

            <div *ngIf="sessionData.productOfferCode === 'OFFRE_3' && sessionData.productStatus === 'customer' && creditCount < 201 && creditCount > -1"
                 style="text-align: right; padding: 0 10px; font-style: italic;">
                <span *ngIf="creditCount > 0">Il vous reste {{creditCount}} crédits.</span>
                <span *ngIf="creditCount === 0">Vous n'avez plus de crédits.</span>
                <span> Pensez à prendre contact avec votre chargé d'affaires pour recharger votre solde.</span>
            </div>

            <div [hidden]="!allContactLoaded" class="allContactsListContainer">
                <div [hidden]="!(allContacts?.data.length > 0)" style="height: 100%;">
                    <section class="allContactsSection">
                        <table mat-table [dataSource]="allContacts" #allContactSort="matSort" matSort>

                            <!--- Note that these columns can be defined in any order.
                                  The actual rendered columns are set as a property on the row definition" -->

                            <!-- role Column -->
                            <ng-container matColumnDef="role">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 3;"> Rôle
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding-right:24px; padding-left: 24px;">
                                    <span style="font-weight: bold;">{{element.role}}</span>
                                    <div *ngIf="element.domain_label?.trim().length > 0 || element.level_label?.trim().length > 0"
                                    style="font-style: italic;">
                                        <span>{{element.domain_label}}</span>
                                        <span *ngIf="element.domain_label?.trim().length > 0 && element.level_label?.trim().length > 0"> / </span>
                                        <span>{{element.level_label}}</span>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- nom Column -->
                            <ng-container matColumnDef="nom">
                                <th style="width: 250px;" mat-header-cell *matHeaderCellDef> Nom</th>
                                <td style="width: 250px; max-width: 250px; overflow: hidden;" mat-cell
                                    *matCellDef="let element">
                                <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
                                display: block;" [class.contactBlured]="element?.anonymized">
                                    {{element.firstName}} {{element.lastName}}</span>
                                </td>
                            </ng-container>

                            <!-- linkedIn Column -->
                            <ng-container matColumnDef="linkedIn">
                                <th style="width: 110px;" mat-header-cell *matHeaderCellDef> LinkedIn</th>
                                <td mat-cell *matCellDef="let element">
                                    <span class="contactBlured" *ngIf="element?.anonymized">Lien LinkedIn</span>
                                    <button *ngIf="!element?.anonymized && element.linkedin_url && element.linkedin_url.trim().length > 0"
                                            class="allContactSNButton"
                                            (click)="openUrl(element.linkedin_url, 'RS')">
                                        <img style="width: 20px; height: 20px;" src="./assets/icon/linkedin2.png">
                                    </button>
                                </td>
                            </ng-container>

                            <!-- mail Column -->
                            <ng-container matColumnDef="mail">
                                <th style="width: 260px;" mat-header-cell *matHeaderCellDef> Mail</th>
                                <td style="width: 260px; max-width: 260px; overflow: hidden;" mat-cell
                                    *matCellDef="let element">
                                <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
                                display: block;" class="contactBlured"
                                      *ngIf="element?.anonymized">{{element.email}}</span>
                                    <a href="mailto:{{element.email}}" *ngIf="!element?.anonymized"
                                       title="{{element.email}}">{{element.email}}</a>
                                </td>
                            </ng-container>

                            <!-- mailPertinence Column -->
                            <ng-container matColumnDef="mailPertinence">
                                <th style="width: 50px;" mat-header-cell *matHeaderCellDef>
                                    <mat-icon class="mailPertinenceLogo"
                                              [matMenuTriggerFor]="mailPertinenceInfo">help
                                    </mat-icon>
                                    <mat-menu #mailPertinenceInfo="matMenu" xPosition="before"
                                              class="mailPertinenceMenu">
                                        <div class="mailPertinencePopup">
                                            <div class="mailPertinencePopupHeader">
                                                <span>La couleur de la pastille indique la pertinence de l'email</span>
                                            </div>
                                            <div class="mailPertinencePopupContent">
                                                <div class="mailLedLine">
                                                    <div class="mailLed mailLed-green"></div>
                                                    <span>Email validé</span>
                                                </div>
                                                <div class="mailLedLine">
                                                    <div class="mailLed mailLed-yellow"></div>
                                                    <span>Email détecté sur le web</span>
                                                </div>
                                                <div class="mailLedLine">
                                                    <div class="mailLed mailLed-orange"></div>
                                                    <span>Email non validé</span>
                                                </div>
                                                <div class="mailLedLine">
                                                    <div class="mailLed mailLed-grey"></div>
                                                    <span>Pas d'email détecté</span>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-menu>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <div [ngSwitch]="element.color">
                                        <div *ngSwitchCase="'green'">
                                            <div class="mailLed mailLed-green"></div>
                                        </div>
                                        <div *ngSwitchCase="'yellow'">
                                            <div class="mailLed mailLed-yellow"></div>
                                        </div>
                                        <div *ngSwitchCase="'orange'">
                                            <div class="mailLed mailLed-orange"></div>
                                        </div>
                                        <div *ngSwitchDefault>
                                            <div class="mailLed mailLed-grey"></div>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Symbol Column -->
                            <ng-container matColumnDef="credit">
                                <th mat-header-cell *matHeaderCellDef class="creditColumn"
                                    style="text-align: center;">
                                    {{creditCount > 1 ? creditCount + ' Crédits' : ''}}
                                    {{creditCount < 0 ? 'Crédits illimités' : ''}}
                                    {{creditCount == 1 ? creditCount + ' Crédit' : ''}}
                                    {{creditCount == 0 ? creditCount + ' Crédits' : ''}}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <button [disabled]="creditCount == 0" *ngIf="element?.anonymized" id="contactBtn-{{element.id}}"
                                            class="showContactFor1Credit"
                                            (click)="checkLoadFullDAtaForAContact(element);">Voir pour 1 crédit
                                    </button>
                                    <button *ngIf="!element?.anonymized" class="showContactSheet"
                                            (click)="openContactSheet(element)">Voir la fiche
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </section>
                </div>
<!--                <ng-template #noAllContacts>-->
                    <div [hidden]="allContacts?.data.length > 0" style="padding: 35px; font-size: 20px; text-align: center; color: #124457 !important;">
                        Aucun contact pour l'entreprise
                    </div>
<!--                </ng-template>-->
            </div>
<!--            <ng-template  #loadingSpinner>-->
                <div [hidden]="allContactLoaded" style="text-align: center; padding: 35px;">
                    <mat-spinner style="margin: auto;"></mat-spinner>
                </div>
<!--            </ng-template>-->
            <div class="displayNone" #allContactPaginatorContainer>
                <mat-paginator #allContactPaginator  [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons
                               aria-label="Select page of periodic elements">
                </mat-paginator>
            </div>
        </mat-expansion-panel>


        <mat-expansion-panel expanded="true" style="border-radius: 0" class="contactAccodrion"
                             [hidden]="!(othersContactsDS.data.length > 0)">
            <mat-expansion-panel-header class="contactAccodrionHeader">
                <mat-panel-title>
                    <span>Contacts issus d’articles précédents</span>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <section style="overflow: auto; max-height: 450px;">
                <table [hidden]="!(othersContactsDS.data.length > 0)" mat-table
                       [dataSource]="othersContactsDS" #otherContactSort="matSort" matSort class="mat-elevation-z8">

                    <!--- Note that these columns can be defined in any order.
                          The actual rendered columns are set as a property on the row definition" -->

                    <!-- role Column -->
                    <ng-container matColumnDef="firstName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 3;">Nom et fonctions</th>
                        <td mat-cell *matCellDef="let othersContact" style="padding-right:24px; padding-left: 24px;">
                            {{othersContact.firstName}} {{othersContact.lastName}} - {{othersContact.function}}
                        </td>
                    </ng-container>

                    <!-- nom Column -->
                    <ng-container matColumnDef="mail">
                        <th style="width: 260px;" mat-header-cell *matHeaderCellDef>Mail</th>
                        <td style="width: 260px; max-width: 260px; overflow: hidden;" mat-cell
                            *matCellDef="let othersContact">
                            <a href="mailto:{{othersContact.mail}}" *ngIf="othersContact.mail"
                               title="{{othersContact.mail}}">
                                {{othersContact.mail}}
                            </a>
                        </td>
                    </ng-container>

                    <!-- nom Column -->
                    <ng-container matColumnDef="telephone">
                        <th style="width: 260px;" mat-header-cell *matHeaderCellDef>Téléphone</th>
                        <td style="width: 260px; max-width: 260px; overflow: hidden;" mat-cell
                            *matCellDef="let othersContact">
                            <a href="tel:{{othersContact.phone}}" *ngIf="othersContact.phone"
                               title="{{othersContact.phone}}">
                                {{othersContact.phone}}
                            </a>
                        </td>
                    </ng-container>

                    <!-- nom Column -->
                    <ng-container matColumnDef="reseaux">
                        <th style="width: 150px;" mat-header-cell *matHeaderCellDef>Réseaux</th>
                        <td style="width: 150px; max-width: 150px; overflow: hidden;" mat-cell
                            *matCellDef="let othersContact">
                            <button *ngIf="othersContact.linkedin"
                                    class="allContactSNButton"
                                    (click)="openUrl(othersContact.linkedin, 'RS')">
                                <img style="width: 20px; height: 20px;" src="./assets/icon/linkedin2.png">
                            </button>
                            <button style="margin-left: 5px;" *ngIf="othersContact.twitter"
                                    class="allContactSNButton"
                                    (click)="openUrl(othersContact.twitter, 'RS')">
                                <img style="width: 20px; height: 16px; margin-bottom: 2px;"
                                     src="./assets/icon/twitter-bird.png">
                            </button>
                            <button style="margin-left: 5px;" *ngIf="othersContact.facebook"
                                    class="allContactSNButton"
                                    (click)="openUrl(othersContact.facebook, 'RS')">
                                <img style="width: 20px; height: 20px;" src="./assets/icon/facebook-icon.png">
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="articleContactsDC; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: articleContactsDC;"></tr>
                </table>
            </section>
<!--            <ng-template #noOthersContacts>-->
                <div [hidden]="othersContactsDS.data.length > 0"
                     style="padding: 35px; font-size: 20px; text-align: center;">
                    Aucun autres contacts
                </div>
<!--            </ng-template>-->
        </mat-expansion-panel>
    </mat-accordion>
    </div>
</ion-content>
