<ion-header no-border></ion-header>
<ion-content>
    <button
            (click)="openSearchPage('add')"
            class="mat-full-width-button accent"
            mat-flat-button>
        <mat-icon>add_circle_outline</mat-icon>
        {{"CREATE_NEW_SEARCH"}}
    </button>
    <button *ngIf="isRoleCom()"
            (click)="shareSearches()"
            class="mat-full-width-button accent"
            style="background-color: #273c4e !important;"
            mat-flat-button>
        <mat-icon>share</mat-icon>
        {{"SHARE_SEARCHES"}}
    </button>
    <mat-list>
        <ng-container *ngIf="mySearchList; else loading">
            <!--<ng-container *ngIf="searches.count >= 1; else noData" cdkDropListGroup>-->
            <div cdkDropList
                 [cdkDropListData]="mySearchList"
                 (cdkDropListDropped)="drop($event)" class="searches-list">
                <mat-list-item cdkDrag class="my-searches-box"
                               *ngFor="let search of mySearchList; let first = first;let i=index;"
                               [ngClass]="{'oddRowColor' : i%2 === 1}">
                    <!--            <button (click)="openSearchTab(search)">TEST</button>-->
                    <button mat-icon-button cdkDragHandle>
                        <mat-icon>drag_indicator</mat-icon>
                    </button>
                    <button mat-icon-button (click)="openSearchSummary(search)" title="Prévisualisation des critères">
                        <mat-icon>search</mat-icon>
                    </button>
                    <ion-buttons slot="start" *ngIf="first">
                        <button mat-icon-button>
                            <mat-icon class="outlined">home</mat-icon>
                        </button>
                    </ion-buttons>
                    <p class="searchLabel" *ngIf="mediaQueryService.mobileQuery.matches"
                       (click)="openBusinessFromSearch(search)" title="Ouvrir">
                        {{(search.data.label.length > 25) ? (search.data.label | slice:0:25) + '...' : (search.data.label)}}
                    </p>
                    <p class="searchLabel" *ngIf="!mediaQueryService.mobileQuery.matches"
                       (click)="openBusinessFromSearch(search)" title="Ouvrir">{{search.data.label}}</p>
                    <div style="flex: 1;"></div>
                    <ng-container *ngIf="mediaQueryService.mobileQuery.matches">
                        <button [disabled]="search.readOnly" mat-icon-button title="Verrouiller"
                                *ngIf="search.readOnly">
                            <!--                matTooltip="Verrouiller"-->
                            <mat-icon class="greyColor outlined">lock</mat-icon>
                        </button>
                        <button [disabled]="search.readOnly" mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon class="outlined">more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <ion-buttons style="padding-top: 4px;" slot="end" *ngIf="search.readOnly===false">
                                <button mat-icon-button (click)="openBusinessFromSearch(search)" title="Ouvrir">
                                    <!--                matTooltip="Editer"-->
                                    <mat-icon class="outlined">remove_red_eye</mat-icon>
                                </button>
                                <toggle-notification-button [search]="search"></toggle-notification-button>
                                <button mat-icon-button (click)="openSearchPage('edit', search)" title="Modifier">
                                    <!--                matTooltip="Editer"-->
                                    <mat-icon class="outlined">edit</mat-icon>
                                </button>
                                <button mat-icon-button (click)="askBeforeDuplicateSearch(search)" title="Dupliquer">
                                    <!--                matTooltip="Dupliquer"-->
                                    <mat-icon class="outlined">file_copy</mat-icon>
                                </button>
                                <button mat-icon-button (click)="askBeforeRemoveSearch(search)" title="Supprimer">
                                    <!--                matTooltip="Supprimer"-->
                                    <mat-icon class="outlined">delete</mat-icon>
                                </button>
                            </ion-buttons>
                        </mat-menu>
                    </ng-container>
                    <ng-container *ngIf="!mediaQueryService.mobileQuery.matches">
                        <ion-buttons slot="end" *ngIf="search.readOnly">
                            <button [disabled]="search.readOnly" mat-icon-button title="Verrouiller">
                                <!--                matTooltip="Verrouiller"-->
                                <mat-icon class="greyColor outlined">lock</mat-icon>
                            </button>
                            <button mat-icon-button (click)="openBusinessFromSearch(search)" title="Ouvrir">
                                <!--                matTooltip="Editer"-->
                                <mat-icon class="outlined">remove_red_eye</mat-icon>
                            </button>
                            <toggle-notification-button [search]="search"></toggle-notification-button>
                            <button [disabled]="search.readOnly && !isRoleCom()" mat-icon-button
                                    (click)="openSearchPage('edit', search)" title="Modifier">
                                <!--                matTooltip="Editer"-->
                                <mat-icon [ngClass]="!isRoleCom() ? 'greyColor' : ''" class="outlined">edit</mat-icon>
                  </button>
                  <button mat-icon-button  (click)="askBeforeDuplicateSearch(search)" title="Dupliquer">
    <!--                matTooltip="Dupliquer"-->
                    <mat-icon class="outlined">file_copy</mat-icon>
                  </button>
                  <button [disabled]="search.readOnly && !isRoleCom()" mat-icon-button (click)="askBeforeRemoveSearch(search)" title="Supprimer">
    <!--                matTooltip="Supprimer"-->
                    <mat-icon [ngClass]="!isRoleCom() ? 'greyColor' : ''" class="outlined">delete</mat-icon>
                  </button>
                </ion-buttons>
                <ion-buttons slot="end" *ngIf="search.readOnly===false">
                    <button mat-icon-button (click)="openBusinessFromSearch(search)" title="Ouvrir">
                        <!--                matTooltip="Editer"-->
                        <mat-icon class="outlined">remove_red_eye</mat-icon>
                    </button>
                  <toggle-notification-button [search]="search"></toggle-notification-button>
                  <button mat-icon-button (click)="openSearchPage('edit', search)" title="Modifier">
    <!--                matTooltip="Editer"-->
                    <mat-icon class="outlined">edit</mat-icon>
                  </button>
                  <button mat-icon-button (click)="askBeforeDuplicateSearch(search)" title="Dupliquer">
    <!--                matTooltip="Dupliquer"-->
                    <mat-icon class="outlined">file_copy</mat-icon>
                  </button>
                  <button mat-icon-button (click)="askBeforeRemoveSearch(search)" title="Supprimer">
    <!--                matTooltip="Supprimer"-->
                    <mat-icon class="outlined">delete</mat-icon>
                  </button>
                </ion-buttons>
            </ng-container>
          </mat-list-item>
        </div>
      <!--</ng-container>-->
    </ng-container>
  </mat-list>

  <ng-template #loading>
    <loading>{{"LOADING_SEARCHES"}}</loading>
  </ng-template>
  <ng-template #noData>
    <no-data>{{"NO_SEARCH"}}</no-data>
  </ng-template>
</ion-content>
