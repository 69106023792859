import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, ElementRef, ViewChild, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormGroup, Validators, ValidatorFn, FormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';

import { EmailValidator } from '../../validators/email/email.validator';

import * as _ from 'lodash';
import { Subscription, Subject, timer} from 'rxjs';
import { ApiService } from '../../services/api/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FormFieldInterface } from '../../interfaces/form-field/form-field.interface';

// import { AddressValidator } from '../../validators/address/address.validator';
import { XFormErrorStateMatcher } from './x-form-error-state-matcher.class';
import { PhoneNumberValidator } from 'src/app/validators/phone-number/phone-number.validator';
import { takeUntil, debounce } from 'rxjs/operators';
import { AddressValidator } from 'src/app/validators/address/address.validator';

// const COMMA = 188;

@Component({
  selector: 'x-form',
  templateUrl: 'x-form.component.html',
  styleUrls: ['./x-form.component.scss']
})

export class XFormComponent implements OnInit, OnDestroy, OnChanges {

  @Input() public fields: Array<FormFieldInterface>;
  @Input() public submitMethod: Function;
  @Input() public submitButtonLabel: string = 'Envoyer';
  @Input() public submitButtonColor: string = 'primary';
  @Input() public hideSubmitButton;
  @Output() public formDataChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() public focusChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() public blurChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output() public deleteFromXJsonArray: EventEmitter<any> = new EventEmitter<any>();

  private unsubscribe$: Subject<any> = new Subject<any>();

  private xFormErrorStateMatcher: XFormErrorStateMatcher;

  // chips
  private separatorKeysCodes: Array<any>;

  protected _inputFileElement: ElementRef;
  @ViewChild('inputFileElement', {static: false}) public set inputFileElement(content: ElementRef) {
    this._inputFileElement = content;
  }
  public get inputFileElement(): ElementRef {
    return this._inputFileElement;
  }
  private files: any = {};

  private formValueChangeSubscription: Subscription;

  public formGroup: FormGroup;
  public submitButtonLabelTranslated: string;

  public httpErrorResponse: HttpErrorResponse;

  public viewInit: Boolean = false;

  public isSending: boolean = false;

  constructor(
    protected apiService: ApiService
    , protected changeDetectorRef: ChangeDetectorRef
  ) {
    this.xFormErrorStateMatcher = new XFormErrorStateMatcher();
    this.separatorKeysCodes = [ENTER, COMMA];
  }

  ngOnInit() {

    this.formGroup = this.getFormGroup(this.fields);

    this.displayConditionHandler(this.fields);

    if (typeof this.formValueChangeSubscription !== 'undefined') {
      this.formValueChangeSubscription.unsubscribe();
    }
    this.formValueChangeSubscription = this.formGroup.valueChanges
    .pipe(debounce(() => timer(300)))
    .subscribe(
      (data: any) => {
        this.formDataChanged.emit(this.formGroup);
        this.displayConditionHandler(this.fields);
      }
    );
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (typeof simpleChanges.fields !== 'undefined') {
      this.ngOnInit();
    }
  }

  ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.formValueChangeSubscription.unsubscribe();
  }

  private getFormGroup(fields: Array<any>): FormGroup {
    const group: any = {};
    _.forEach(fields, (field: any) => {
      group[field.name] = this.getFormControls(field);
    });
    return new FormGroup(group);
  }

  private getFormControls(field: FormFieldInterface): FormControl {
    const formControl: FormControl = new FormControl(this.getDefaultValue(field), this.getValidators(field));
    this.getDisabledValue(field) ? formControl.disable() : formControl.enable();
    return formControl;
  }

  private getDefaultValue(field: FormFieldInterface): any {
    let defaultValue: any;
    if (field.type === 'boolean' || field.type === 'checkbox') {
      defaultValue = field.default;
    } else {
      defaultValue = field.default || '';
    }
    return defaultValue;
  }
  private getDisabledValue(field: FormFieldInterface): any {
    return field.disabled || false;
  }

  private getValidators(field: FormFieldInterface): ValidatorFn {
    const validators: Array<ValidatorFn> = [];
    if (typeof field.validators !== 'undefined') {
      if (typeof field.validators.required !== 'undefined' && field.validators.required) {
        validators.push(Validators.required);
      }
      if (typeof field.validators.requiredTrue !== 'undefined' && field.validators.requiredTrue) {
        validators.push(Validators.requiredTrue);
      }
      if (typeof field.validators.min !== 'undefined' && field.validators.min !== '') {
        validators.push(Validators.min(field.validators.min));
      }
      if (typeof field.validators.minLength !== 'undefined' && field.validators.minLength !== '') {
        validators.push(Validators.minLength(field.validators.minLength));
      }
      if (typeof field.validators.max !== 'undefined' && field.validators.max !== '') {
        validators.push(Validators.max(field.validators.max));
      }
      if (typeof field.validators.maxLength !== 'undefined' && field.validators.maxLength !== '') {
        validators.push(Validators.maxLength(field.validators.maxLength));
      }
      if (typeof field.validators.email !== 'undefined' && field.validators.email) {
        validators.push(EmailValidator.isValidEmail);
      }
      if (typeof field.validators.phonenumber !== 'undefined' && field.validators.phonenumber) {
        validators.push(PhoneNumberValidator.isValidPhoneNumber);
      }
      if (typeof field.validators.address !== 'undefined' && field.validators.address) {
        validators.push(AddressValidator.isValidAddress);
      }
      if (typeof field.validators.pattern !== 'undefined' && field.validators.pattern !== '') {
        validators.push(Validators.pattern(field.validators.pattern));
      }
    }
    return Validators.compose(validators);
  }

  public onSubmit() {

    if (this.formGroup.valid) {
      if (typeof this.submitMethod === 'function') {
        if (typeof this.httpErrorResponse !== 'undefined' || this.httpErrorResponse !== null) {
          this.httpErrorResponse = undefined;
        }

        const formGroupCleaned: FormGroup = this.cleanDataBeforeSubmit(this.formGroup);

        this.isSending = true;

        this.submitMethod(formGroupCleaned).pipe(
          takeUntil(this.unsubscribe$)
        )
        .subscribe(
          () => {
            this.isSending = false;
          },
          ((error: HttpErrorResponse) => {
            this.isSending = false;
            this.httpErrorResponse = error;
            this.changeDetectorRef.detectChanges();
          })
        );
      }
    } else {
      _.forEach(this.fields, (field: FormFieldInterface) => {
        this.formGroup.controls[field.name].markAsDirty();
      });
    }
  }

  public cleanDataBeforeSubmit(formGroup: FormGroup): FormGroup {
    const formGroupCopy: FormGroup = _.cloneDeep(formGroup);

    _.forEach(this.fields, (field: FormFieldInterface) => {

      // si le champs est un toggle, qu'il est required et que ça valeur est undefined, on le set à false
      // du coup, on peux supprimer la validation en front...
      if (field.type === 'boolean' && (formGroupCopy.value[field.name] === '' || formGroupCopy.value[field.name] === null) ) {
        formGroupCopy.value[field.name] = false;
      }

      if (field.type === 'number') {
        formGroupCopy.value[field.name] = parseFloat(formGroupCopy.value[field.name]) || 0;
      }

      if (field.type === 'date' && formGroupCopy.value[field.name] === '') {
        formGroupCopy.value[field.name] = null;
      }

      if (field.type === 'datetime' && formGroupCopy.value[field.name] === '') {
        formGroupCopy.value[field.name] = null;
      }

      if (field.type === 'json') {
        if (typeof formGroupCopy.value[field.name] !== 'undefined' && formGroupCopy.value[field.name] !== null && formGroupCopy.value[field.name] !== '') {
          Object.keys(formGroupCopy.value[field.name]).forEach((key: string) => {
            if (formGroupCopy.value[field.name][key] === '') {
              delete formGroupCopy.value[field.name][key];
            }
          });
        }
      }

      // we delete the conditionnal fields
      if (field.hideThisConditionField) {
        delete formGroupCopy.value[field.name];
      }

      if (field.type === 'maintenance-checklist-estimated-time') {
        delete formGroupCopy.value[field.name];
      }

    });
    return formGroupCopy;
  }

  public submitFromOutside(): void {
    _.forEach(this.formGroup.controls, (control: FormControl) => {
      control.markAsTouched();
    });
    this.onSubmit();
  }

  public reset(): void {
    this.formGroup.reset();
    _.forEach(this.fields, (field: FormFieldInterface) => {
      this.formGroup.controls[field.name].setErrors(null);
      this.formGroup.controls[field.name].markAsPristine();
    });
  }

  private onBlur($event: any, field: FormFieldInterface): void {
    this.blurChanged.emit(field);
  }

  private onFocus($event: any, field: FormFieldInterface): void {
    this.focusChanged.emit(field);
  }


  public disableAllControls(bool: boolean): void {
    _.forEach(this.formGroup.controls, (control: FormControl) => {
      bool ? control.disable() : control.enable();
    });
  }

  private displayConditionHandler(fields: Array<FormFieldInterface>): void {
    if (typeof fields === 'undefined' || fields === null) { return; }

    fields.forEach((field: FormFieldInterface) => {
      if (typeof field.displayConditions !== 'undefined' && _.isArray(field.displayConditions) && field.displayConditions.length > 0) {
        let hideThisConditionField: boolean = true;

        field.displayConditions.forEach((displayCondition) => {


          const value: any = typeof this.formGroup.controls[displayCondition.field] !== 'undefined' ? this.formGroup.controls[displayCondition.field].value : undefined;

          // just check if hasValue is true and formControl has value
          if (typeof displayCondition.hasValue !== 'undefined') {
            if (displayCondition.hasValue && typeof value !== 'undefined' && value !== '' && value !== null) {
              hideThisConditionField = false;
            }
          }

          // check if formControl has value contained in displayCondition.values
          if (typeof displayCondition.values !== 'undefined') {
            if (Array.isArray(displayCondition.values) && displayCondition.values.length > 0) {
              // check is formValue is array (select multiple or chips)
              if (Array.isArray(value)) {
                let found: boolean = false;
                for (const val of value) {
                  if (displayCondition.values.indexOf(val) !== -1) {
                    found = true;
                    break;
                  }
                }
                if (found) {
                  hideThisConditionField = false;
                }
              } else {
                if (displayCondition.values.indexOf(value) !== -1) {
                  hideThisConditionField = false;
                }
              }
            }
          }

        });

        // this.getValidators() avec une copie du fields ? (en supprimant le requiere si il existe et si on affiche pas le field)
        if (hideThisConditionField) {
          this.formGroup.controls[field.name].clearValidators();
          this.formGroup.controls[field.name].setErrors(null);
        } else {
          this.formGroup.controls[field.name].clearValidators();
          this.formGroup.controls[field.name].setValidators(this.getValidators(field));
        }

        field.hideThisConditionField = hideThisConditionField;
      }
    });

  }

  public deleteFromXJson($event: any) {
    this.deleteFromXJsonArray.emit($event);
  }

}
