<button *ngIf="dateArticle" class="datesSelectorButton whiteBorder smallPadding smallRadius" mat-stroked-button (click)="openReadingModal()">
  <mat-icon>event_available</mat-icon>
  <div class="fromTo" *ngIf="dateArticle.from && dateArticle.to">
    <div>
      <div>{{"FROM_DATE"}}</div>
      <div>{{dateArticle.from | date:'dd/MM/yyyy':'':locale}}</div>
    </div>
    <div>
      <div>{{"TO_DATE"}}</div>
      <div>{{dateArticle.to | date:'dd/MM/yyyy':'':locale}}</div>
    </div>
  </div>
<!--  <div class="period" *ngIf="dateArticle.period">-->
<!--    <div>-->
<!--      <div>{{"PERIOD" | translate}}</div>-->
<!--      <div>{{dateArticle.period | translate}}</div>-->
<!--    </div>-->
<!--  </div>-->
</button>
<button *ngIf="!dateArticle" class="datesSelectorButton whiteBorder smallPadding smallRadius" mat-stroked-button>
  <mat-icon>event_available</mat-icon>    
  <mat-progress-spinner class="whiteSpinner" mode="indeterminate" diameter="20"></mat-progress-spinner>
</button>

<div class="divManageDates"  [matMenuTriggerFor]="menu">
  <div style="display: flex;flex-direction: row; align-items: center">
    <mat-icon style="margin-left: 4%; color: #273c4e ">date_range</mat-icon>
    <div style="display: flex; justify-content: space-between; width: 100%;">
      <div style="display: flex;flex-direction: column; margin-left: 6%">
        <span style="color: #666E70 !important;">du</span>
        <span style=" color: #273c4e;font-size: large;font-weight: bold">{{dateArticle?.from | date:'dd/MM/yyyy':'':locale}}</span>
      </div>
      <div style="display: flex;flex-direction: column; margin-left: 20%;">
        <span style="color: #666E70 !important;">au</span>
        <span style=" color: #273c4e;font-size: large;font-weight: bold;">{{dateArticle?.to | date:'dd/MM/yyyy':'':locale}}</span>
      </div>
    </div>
  </div>
</div>

<mat-menu  #menu="matMenu"   (click)="$event.stopPropagation()">
  <mat-card style="display: flex;justify-content: space-between;" (click)="$event.stopPropagation()">
    <div>
      <mat-form-field appearance="legacy" floatLabel="never" style="margin-left: 8px;">
        <mat-label>Date de début</mat-label>
        <input matInput [matDatepicker]="pickerStartDate" >
        <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerStartDate></mat-datepicker >
      </mat-form-field>
      <mat-calendar #calendar [(selected)]="selectedDateStart" ></mat-calendar>
    </div>
    <div>
      <mat-form-field appearance="legacy" floatLabel="never" style="margin-right: 14px;margin-left: 22px;">
        <mat-label>Date de fin</mat-label>
        <input matInput [matDatepicker]="pickerEndDate">
        <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerEndDate></mat-datepicker>
      </mat-form-field>
      <mat-calendar [(selected)]="selectedDateEnd" [startAt]="selectedDateEnd"></mat-calendar>
    </div>
    <div style="display: flex; flex-direction: column; justify-content: space-between">
      <div style="display: flex; flex-direction: column;">
        <button class="dateSelectorBtn">Depuis une semaine</button>
        <button class="dateSelectorBtn">Depuis 1 mois</button>
        <button class="dateSelectorBtn">Depuis 3 mois</button>
      </div>
      <div>
        <button class="dateSelectorBtnValidate">Valider</button>
      </div>
    </div>
    <div>
      <button  mat-icon-button aria-label="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

  </mat-card>
</mat-menu>
