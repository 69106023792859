<div class="feFavoriteItem" [class.selectedFolder]="selectedFolder">
    <span class="feFavoriteItemLabel">{{favorisFolder?.libelle}}</span>
    <div class="feFavoriteItemCount">
        <span class="littleTextBold">{{favorisFolder.folderArticlesCount}}</span>
    </div>
    <fe-dots-menu class="feFavoriteItemDotsMenu" (click)="$event.stopPropagation()">
        <fe-menu-item text="Renommer le dossier" color="PRIMARY" [icon]="IconType.rename" (onClick)="onRenameClick.emit()"/>
        <fe-menu-item text="Supprimer le dossier" color="DANGER" [icon]="IconType.delete" (onClick)="onDeleteClick.emit()"/>
    </fe-dots-menu>
</div>
