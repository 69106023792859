<div class="previousArticleLine" [class.previousArticleLineDisable]="articleModalDisable">
    <div class="leftPart">
        <span class="articleDate moderateText">
             {{getArticleDate()}}
        </span>
        <span class="articleTitle moderateText">
            {{article.title}}
        </span>
    </div>
    <fe-button type="SECONDARY" text="Voir l'article" (click)="openArticle()" color="PRIMARY"/>
</div>

