import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ColorType, IconType} from "../fe-icon/fe-icon.component";

@Component({
  selector: 'fe-input',
  templateUrl: './fe-input.component.html',
  styleUrls: ['./fe-input.component.scss']
})
export class FeInputComponent {
  @Input() inputTitle: string = 'Titre de l\'input';
  @Input() placeHolder: string = '';
  @Input() width: string = '100px';
  @Input() name: string = '';
  @Input() type: string = 'text';
  @Input() required: boolean = false;
  @Input() email: boolean = false;
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() smallMode: boolean = false;
  @Input() cursor: string = 'auto';
  @Input() inputId: string = 'input_' + this.getRandomInt(10000);
  @Input() icon: IconType = null;
  @Input() color: ColorType = ColorType.lightBlack;
  protected inputValue: string = '';
  @ViewChild('inputElement') inputElement: ElementRef;

  @Input()
  get value() {
    return this.inputValue;
  }

  @Output()
  valueChange = new EventEmitter<string>();

  set value(val: string) {
    this.inputValue = val;
  }

  public getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  public setTheFocus(): void {
    setTimeout(()=>{
      this.inputElement.nativeElement.focus();
    },0);
  }

  protected readonly ColorType = ColorType;
}
