import {Component, Input} from '@angular/core';

@Component({
  selector: 'fe-pro-tips',
  templateUrl: './fe-pro-tips.component.html',
  styleUrls: ['./fe-pro-tips.component.scss']
})
export class FeProTipsComponent {

  @Input() dismissButton: boolean = false;
  @Input() color: string = 'DEFAULT';
  @Input() text: string = 'Texte par défaut';
  @Input() title: string = 'Titre par défaut';

  protected hidePt: boolean = false;
  constructor() {
    switch (this.color) {
      case 'DANGER':
        this.title = 'Attention :';
        break;
      default:
        this.title = 'Pro-tip :';
        break;
    }
  }

  public showProTips(): void {
    this.hidePt = false;
  }

}
