<div class="contacts-details-container" *ngIf="contacts && contacts.length > 0" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
  <div class="contacts-details-array" [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
    <div class="contact-details" *ngFor="let contact of contacts">
      <mat-icon class="greyColor contact-details-icon">business</mat-icon>
      <div class="contact-details-infos">
        <ng-container *ngIf="contact.organisationName">
          <span class="greyColor contact-details-organisation">{{contact.organisationName}}</span>
        </ng-container>
        <ng-container *ngIf="contact.tel">
          <span class="greyColor contact-details-tel"><a href="tel:{{contact.tel}}">{{contact.tel}}</a></span>
        </ng-container>
        <ng-container *ngIf="contact.contactName">
          <span class="greyColor contact-details-name">{{contact.contactName}}</span>
        </ng-container>
        <ng-container *ngIf="contact.email">
          <span class="greyColor contact-details-email"><a href="mailto:{{contact.email}}">{{contact.email}}</a></span>
        </ng-container>
        <ng-container *ngIf="contact.socialUrl">
          <span class="greyColor contact-details-social-url">{{contact.socialUrl}}</span>
        </ng-container>
        <ng-container *ngIf="contact.website">
          <span class="greyColor contact-details-website"><a href="{{contact.website}}" (click)="logWebsiteClick()" target="_blank">Site Web</a></span>
        </ng-container>
      </div>
      <button mat-icon-button (click)="exportArticleWebToLead(articleId, contact)">
        <mat-icon fontSet="material-icons-outlined" class="greyColor contact-details-icon">cloud_upload</mat-icon>
      </button>
    </div>
  </div>
</div>
