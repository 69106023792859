import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { ContactInterface } from 'src/app/interfaces/contact/contact.interface';
import { MediaQueryService } from 'src/app/services/media-query/media-query.service';
import {EntrepriseContactInterface} from '../../../interfaces/entreprise-contact/entreprise-contact.interface';

@Component({
  selector: 'article-contacts',
  templateUrl: 'article-contacts.component.html',
  styleUrls: ['article-contacts.component.scss'],
})
export class ArticleContactsComponent implements OnInit, OnDestroy {

  @Input() public contacts: Array<EntrepriseContactInterface>;
  @Input() public type: string = 'list'; // list or modal

  private unsubscribe$: Subject<any> = new Subject<any>();

  public numberOfContacts: number;

  constructor(
    public mediaQueryService: MediaQueryService
  ) {}

  public ngOnInit(): void {
    // if (Array.isArray(this.contacts) && this.contacts.length > 0) {
      // this.numberOfContacts = this.contacts.length;
      // iterate on contact to get subContact and count them.
      // this.contacts.forEach((contact: EntrepriseContactInterface) => {
      //   if (Array.isArray(contact.subContacts) && contact.subContacts.length > 0) {
      //     this.numberOfContacts += contact.subContacts.length;
      //   }
      // });
    // }
  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public openOrganisationWebsite() {
    // window.open(this.contacts[0].website, '_blank');
  }
}
