<div>
    <a href="https://www.firsteco.fr/revue/plaquette_contacts_enrichis.pdf" target="_blank">
        <img src="https://www.firsteco.fr/revue/images/contacts_enrichis_efficience.jpg" />
    </a>
    <span class="fe-divider"></span>
    <div class="contactCommercialContainer">
        <span class="bigTitleBold">Vous souhaitez plus de renseignements sur les contacts enrichis ?</span>
        <div class="contactCommercialBloc">
            <div class="commercialContainer">
                <span class="bigText">Rapprochez-vous de votre commercial dédié</span>
                <div class="commercialInfosContainer">
                    <span class="bigText" [innerText]="getCommercialName()"></span>
                    <a *ngIf="salesPersonDetails.salesPersonPhone" class="bigTextBold"
                       [href]="'tel:' + salesPersonDetails.salesPersonPhone" >
                        {{salesPersonDetails.salesPersonPhone}}</a>
                    <a *ngIf="salesPersonDetails.salesPersonEmail" class="bigTextBold"
                       [href]="'mailto:' + salesPersonDetails.salesPersonEmail">
                        {{salesPersonDetails.salesPersonEmail}}</a>
                </div>
            </div>
            <span class="veryBigTitleBold">OU</span>
            <fe-button text="Je souhaite être contacté" (click)="askForContact()"/>
        </div>
    </div>
</div>
