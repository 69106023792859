import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    ChangeDetectorRef,
    AfterContentChecked,
    Inject,
    LOCALE_ID
} from '@angular/core';
import {Subject} from 'rxjs';
import {ModalController, NavParams} from '@ionic/angular';
import {MediaQueryService} from 'src/app/services/media-query/media-query.service';
import {FormFieldInterface} from 'src/app/interfaces/form-field/form-field.interface';
import {XFormComponent} from '../../x-form/x-form.component';
import {DateArticleInterface} from 'src/app/interfaces/date-article/date-article.interface';
import {SessionInterface} from 'src/app/interfaces/session/session.interface';
import {takeUntil} from 'rxjs/operators';
import {UserService} from 'src/app/services/user/user.service';
import {formatDate} from '@angular/common';

@Component({
    selector: 'dates-selector',
    templateUrl: 'dates-selector.modal.html',
    styleUrls: ['dates-selector.modal.scss'],
})
export class DatesSelectorModal implements OnInit, AfterContentChecked, OnDestroy {

    protected unsubscribe$: Subject<any> = new Subject<any>();

    public dateArticle: DateArticleInterface;

    public activeCard: string;
    public isDatesActive: boolean = false;
    public isPeriodActive: boolean = false;

    // Dates précédentes
    public formerStartDate: Date;
    public formerEndDate: Date;

    @ViewChild('formPeriod', {static: true}) public formPeriod: XFormComponent;
    @ViewChild('formDates', {static: true}) public formDates: XFormComponent;

    public formSubmitMethod: Function;
    public fieldsDates: Array<FormFieldInterface> = [
        {
            type: 'date',
            template: 'default',
            placeholder: 'Date de début',
            label: 'Date de début',
            name: 'from',
            default: '',
            minDate: new Date(),
            maxDate: new Date(),
            validators: {},
            readonly: true
        },
        {
            type: 'date',
            template: 'default',
            placeholder: 'Date de fin',
            label: 'Date de fin',
            default: '',
            minDate: new Date(),
            maxDate: new Date(),
            name: 'to',
            validators: {},
            readonly: true
        }
    ];
    public fieldsPeriod: Array<FormFieldInterface> = [
        {
            type: 'select_values',
            template: 'default',
            placeholder: 'Définissez une période',
            label: 'Définissez une période',
            name: 'period',
            options: [
                {name: 'Depuis une semaine', value: 'LAST_WEEK'},
                {name: 'Depuis 1 mois', value: 'LAST_MONTH'},
                {name: 'Depuis 3 mois', value: 'LAST_3_MONTHS'}
            ],
            default: 'LAST_WEEK',
            validators: {},
            readonly: false
        }
    ];

    constructor(
        private modalController: ModalController
        , public mediaQueryService: MediaQueryService
        , private userService: UserService
        , private navParams: NavParams
        , private cdref: ChangeDetectorRef
        , @Inject(LOCALE_ID) public locale: string
    ) {
    }

    public ngOnInit(): void {
        if (typeof this.formPeriod === 'undefined'
            || typeof this.formPeriod.formGroup === 'undefined'
            || typeof this.formPeriod.formGroup.controls === 'undefined'
            || typeof this.formDates === 'undefined'
            || typeof this.formDates.formGroup === 'undefined'
            || typeof this.formDates.formGroup.controls === 'undefined') {
            setTimeout(() => {
                this.ngOnInit();
            }, 100);
        } else {
            this.init();
        }
    }

    public init(): void {
        this.formPeriod.disableAllControls(true);
        this.formDates.disableAllControls(true);

        this.userService.getSessionDatas().pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(
            (session: SessionInterface) => {
                if (session !== undefined) {
                    // if (session.publicationPeriodStart !== undefined) {
                    //   this.dateArticle.from = session.publicationPeriodStart;
                    // }
                    // if (session.publicationPeriodStart !== undefined) {
                    //   this.dateArticle.to = session.publicationPeriodEnd;
                    // }
                    if (session.publicationPeriodStartLimit !== undefined) {
                        this.fieldsDates[0].minDate = new Date(session.publicationPeriodStartLimit);
                        this.fieldsDates[1].minDate = new Date(session.publicationPeriodStartLimit);
                    }

                    if (session.productStatus === 'customer' || session.productStatus === 'forfree') {
                        this.fieldsPeriod[0].options.push({
                            name: 'Depuis 6 mois',
                            value: 'LAST_6_MONTHS'
                        });
                        this.fieldsPeriod[0].options.push({
                            name: 'Depuis 1 an',
                            value: 'LAST_12_MONTHS'
                        });
                        this.fieldsPeriod[0].options.push({
                            name: 'Depuis 2 ans',
                            value: 'LAST_24_MONTHS'
                        });
                        this.fieldsPeriod[0].options.push({
                            name: 'Depuis 3 ans',
                            value: 'LAST_36_MONTHS'
                        });
                    }

                }
            }
        );

        // get data from DatesSelectorButtonComponent (modal caller)
        this.dateArticle = this.navParams.get('dateArticle');
        // active date or period if define
        if (this.dateArticle.from && this.dateArticle.to) {
            this.active('dates');
        }
        if (this.dateArticle.period) {
            this.active('period');
        }

        // set default value for xForm fields
        this.fieldsDates = this.fieldsDates.map((field: FormFieldInterface) => {
            if (typeof this.dateArticle[field.name] !== 'undefined') {
                field.default = new Date(this.dateArticle[field.name]);
                if (field.name === 'from') this.formerStartDate = field.default;
                else this.formerEndDate = field.default;
            }
            return field;
        });
        this.fieldsPeriod = this.fieldsPeriod.map((field: FormFieldInterface) => {
            if (typeof this.dateArticle[field.name] !== 'undefined') {
                field.default = this.dateArticle[field.name];
                // field.default = 'LAST_WEEK';
            }
            return field;
        });
    }

    public close(params?: any): void {
        this.modalController.dismiss(params, null, undefined);
    }

    public isDateOrPeriodSelected(): boolean {

        if (this.formDates.formGroup.value.from > this.formDates.formGroup.value.to) {
            return false;
        }

        // check if period or dates are set.
        return ((this.formDates.formGroup.value.from && this.formDates.formGroup.value.to) || this.formPeriod.formGroup.value.period);
    }

    public save(): void {
        const dateArticle: DateArticleInterface = {};
        if (this.isDatesActive) {
            // get value from form and assign it to dates who will be returned in DatesSelectorButtonComponent through close method
            Object.keys(this.formDates.formGroup.value).forEach((key: string) => {
                {
                    // Date de début
                    if (key === 'from') {
                        if (this.formerStartDate === undefined || (this.formerStartDate.getTime() !== new Date(this.formDates.formGroup.value[key]).getTime())) {
                            // Sauvegarde log Modification de date de début
                            this.userService.log('DATE_START_EDIT');
                        }
                        this.formerStartDate = new Date(this.formDates.formGroup.value[key]);
                    } else  if (key === 'to') {
                        if (this.formerEndDate === undefined || (this.formerEndDate.getTime() !== new Date(this.formDates.formGroup.value[key]).getTime())) {
                            // Sauvegarde log Modification de date de début
                            this.userService.log('DATE_END_EDIT');
                        }
                        this.formerEndDate = new Date(this.formDates.formGroup.value[key]);
                    }
                }
                dateArticle[key] = formatDate(this.formDates.formGroup.value[key], 'yyyy-MM-dd', this.locale);
                // dateArticle[key] = new Date(this.formDates.formGroup.value[key]).toISOString();
            });
        }

        if (this.isPeriodActive) {
            // get value from form and assign it to dates who will be returned in DatesSelectorButtonComponent through close method
            Object.keys(this.formPeriod.formGroup.value).forEach((key: string) => {
                dateArticle[key] = this.formPeriod.formGroup.value[key];
            });
        }

        this.close(dateArticle);
    }

    public active(name: string): void {
        this.activeCard = name;
        if (this.activeCard === 'dates') {
            this.isDatesActive = true;
            this.isPeriodActive = false;
            this.formPeriod.disableAllControls(true);
            this.formDates.disableAllControls(false);
        } else if (this.activeCard === 'period') {
            this.isPeriodActive = true;
            this.isDatesActive = false;
            this.formPeriod.disableAllControls(false);
            this.formDates.disableAllControls(true);
        }
    }

    ngAfterContentChecked() {
        this.cdref.detectChanges();
    }

    public ngOnDestroy() {
        // this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.dateArticle = this.navParams.get('dateArticle');
        // this.close(this.dateArticle);
    }

}
