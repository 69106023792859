import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {MailHistoryInterface} from "../../../interfaces/mail-history/mail-history.interface";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-fe-sended-mail-modal',
  templateUrl: './fe-sended-mail-modal.component.html',
  styleUrls: ['./fe-sended-mail-modal.component.scss']
})
export class FeSendedMailModalComponent {
    mailHistory: MailHistoryInterface;
    
    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
        this.mailHistory = data.mail;
    }

    protected adaptTableWidth(html: string): string {
        let htmlToReturn: string = html;
        htmlToReturn = htmlToReturn.replace(/width="600"/g, 'width="100%;"');
        htmlToReturn = htmlToReturn.replace(/width="560"/g, 'width="95%;"');
        return htmlToReturn;
    }

    protected readonly formatDate = formatDate;
}
